import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-alert',
  styleUrls: ['./dialog-alert.component.scss'],
  templateUrl: './dialog-alert.component.html',
})
export class DialogComponent implements OnInit {
  error = 0;
  day = moment();
  date = {
    year: this.day.year(),
    month: this.day.month() + 1,
    day: this.day.date(),
  };

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data.radios) {
      if (data.radios[0].checked === true) {
        data.radios.value = 'self';
      } else {
        data.radios.value = 'fitbit';
      }
    }
  }

  ngOnInit() {
    if (this.data.date) {
      this.data.date.value = this.date;
    }
  }

  onNoClick(): void {
    if (this.data.closeButtonRedirect) {
      this.router.navigate([this.data.closeButtonRedirect]);
      this.dialogRef.close();
    } else {
      this.dialogRef.close();
    }
  }

  save(): void {
    // change date object to string
    if (this.data.date && this.data.date.value) {
      this.data.date.value = moment([
        this.data.date.value.year,
        this.data.date.value.month - 1,
        this.data.date.value.day,
      ]).format('YYYY-MM-DD');
    }
    if (!(this.data.value1 || this.data.date || this.data.radios)) {
      this.dialogRef.close(true);
    } else if (
      this.data.radios &&
      (this.data.radios[0].disabled || this.data.radios[1].disabled)
    ) {
      this.dialogRef.close(true);
    } else if (!this.data.radios) {
      this.error = 0;
      if (
        this.error === 0 &&
        this.data.value1 &&
        this.data.value1.required === true &&
        (this.data.value1.value === '' || this.data.value1.value === 0)
      ) {
        this.error = 1;
      }
      if (
        this.error === 0 &&
        this.data.value2 &&
        this.data.value2.required === true &&
        (this.data.value2.value === '' || this.data.value2.value === 0)
      ) {
        this.error = 2;
      }
      if (
        this.error === 0 &&
        this.data.date &&
        this.data.date.required === true &&
        !moment(this.data.date.value, 'YYYY-MM-DD', true).isValid()
      ) {
        this.error = 3;
      }
      if (this.error === 0) {
        this.dialogRef.close(this.data);
      }
    } else {
      this.dialogRef.close(this.data);
    }
  }
}
