import { FriendsContainerComponent } from './friends-container/friends-container.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { ShareComponent } from './share.component';
import { AuthGuard } from '../guards/auth.guard';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { GroupsComponent } from './groups/groups.component';
import { AddUserGroupComponent } from './add-user-group/add-user-group.component';
import { GroupDetailsComponent } from './group-details/group-details.component';
import { FEATURES } from '../_shared/enums';

const shareRoutes: Routes = [
  {
    path: '',
    component: ShareComponent,
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Share', featureId: FEATURES.SHARE },
    children: [
      {
        path: 'leaderboard',
        component: LeaderboardComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Leaderboard', featureId: FEATURES.LEADERBOARD }
      },
      {
        path: 'groups',
        component: GroupsComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Groups', featureId: FEATURES.GROUPS },
        children: [
          {
            path: 'new',
            component: AddUserGroupComponent,
            canActivate: [AuthGuard],
            data: { breadcrumb: 'New', featureId: FEATURES.CREATE_NEW_GROUP },
          },
          {
            path: ':id',
            component: GroupDetailsComponent,
            canActivate: [AuthGuard],
            data: { breadcrumb: 'Group' }
          },
        ]
      },
      {
        path: 'groups-new',
        component: GroupsComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Groups', featureId: FEATURES.GROUPS, isNew: true },
      },
      {
        path: 'friends',
        component: FriendsContainerComponent,
        canActivate: [AuthGuard],
      data: { breadcrumb: 'Friends', featureId: FEATURES.FRIENDS }
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(shareRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ShareRoutingModule {}
