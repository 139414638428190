import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { find, get, has, toUpper } from 'lodash';
import { UserService } from '../../../../services/user-service';
import { LanguageService } from '../../../../services/language-service';
import { ILanguage } from '../../../_models/language';
import { LANGUAGES } from '../../enums';

@Component({
  selector: 'app-language-toggle-button',
  templateUrl: './language-toggle-button.component.html',
  styleUrls: ['./language-toggle-button.component.scss'],
})
export class LanguageToggleButtonComponent implements OnInit {

  defaultLang: string = LANGUAGES.en;
  selectedLang: string;
  toggleLang: string;
  toggleOnLabel: { languageCode: string, labelCode: string } = { languageCode: '', labelCode: '' };
  toggleOffLabel: { languageCode: string, labelCode: string } = { languageCode: '', labelCode: '' };

  @Output() languagesToggleEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private languageService: LanguageService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.loadLanguagesData();
  }

  loadLanguagesData() {
    this.languageService.getAvailableLanguages()
      .subscribe((data: ILanguage[]) => {
        const userProfile = this.userService.profile || get(this.userService.user, 'userProfile');
        this.setToggleLabel(userProfile, data);
      });
  }

  setToggleLabel(userProfile, data) {
    get(userProfile, 'articleLanguagePreference');
    this.selectedLang = get(userProfile, 'lang');
    this.toggleLang = get(userProfile, 'articleLanguagePreference', this.defaultLang);
    if (this.toggleLang !== this.selectedLang && this.toggleLang !== this.defaultLang) {
      this.toggleLang = this.defaultLang;
    }
    const languageSelected = find(data, l => l.code === this.selectedLang);
    this.toggleOffLabel.languageCode = this.defaultLang;
    this.toggleOnLabel.languageCode = this.selectedLang;
    this.toggleOnLabel.labelCode = toUpper(this.selectedLang);
    this.toggleOffLabel.labelCode = toUpper(this.defaultLang);
    if (languageSelected && has(languageSelected, 'translationTexts')) {
      const translationTexts = get(languageSelected, 'translationTexts');
      this.toggleOffLabel.labelCode = get(translationTexts, 'languageToggleOff', toUpper(this.defaultLang));
      this.toggleOnLabel.labelCode = get(translationTexts, 'languageToggleOn', toUpper(this.selectedLang));
    }
  }

  onValueChange(value: string) {
    if (this.toggleLang === value) {
      return;
    }
    this.toggleLang = value;
    this.languagesToggleEvent.emit(value);
    this.userService.updateMyArticlePreferenceLanguage({
      articleLanguagePreference: value,
      userId: this.userService.userId,
    }).subscribe(() => {
    });
  }

  isSelected(languageCode) {
    return languageCode === this.toggleLang;
  }

  displayCss(languageCode) {
    return this.isSelected(languageCode) ? 'language-toggle-option' : 'language-toggle-option toggle-unchecked';
  }
}
