import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ILeaderboardItem } from '../../../_models/leaderboard-item';
import { BadgeService } from '../../../../services/badge-service';

@Component({
  selector: 'app-leaderboard-list',
  templateUrl: './leaderboard-list.component.html',
  styleUrls: ['./leaderboard-list.component.scss']
})
export class LeaderboardListComponent implements OnInit, OnChanges {
  @Input() leaderboardItems: ILeaderboardItem[] = [];
  @Input() canViewMore = false;
  @Output() viewMore = new EventEmitter<null>();
  @Output() viewItem = new EventEmitter<ILeaderboardItem>();

  isNoItems = false;
  goldBadgeSrc: string;
  silverBadgeSrc: string;
  bronzeBadgeSrc: string;

  constructor(private badgeService: BadgeService) { }

  ngOnInit() {
    this.setImagesSrc();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.isNoItems = (this.leaderboardItems.length === 0);
  }

  private setImagesSrc() {
    this.goldBadgeSrc = this.badgeService.getGoldBadgeSrc();
    this.silverBadgeSrc = this.badgeService.getSilverBadgeSrc();
    this.bronzeBadgeSrc = this.badgeService.getBronzeBadgeSrc();
  }

  onViewItem(item: ILeaderboardItem) {
    this.viewItem.emit(item);
  }

  onViewMore() {
    this.viewMore.emit();
  }
}
