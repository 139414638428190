<app-date-selector
  [inputCompanyId]='companyId'
  [inputStartDate]='startDate'
  [inputEndDate]='endDate'
  [canSelectCompany]='canSelectCompany'
  (dateQuery)='onDateRangeSelected($event)'>
</app-date-selector>

<div *ngIf='canShowTotalUsersChart' class='card-columns'>
  <div class='card w-100 text-center'>
    <div class='card-header'>
      <h5>Total User Reports</h5>
      <p>{{reportDescription}}</p>
    </div>
    <div class='card-body'>
      <div *ngIf='isShowChart'>
        <app-bar-graph
          [data]='chartData'
          [labels]='labels'
          [color]='color'
          [yStepSize]='1'>
        </app-bar-graph>
      </div>
      <div *ngIf='!isShowChart'>
        <div>
          <b>At this moment, there is no data for these parameters.</b>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf='canShowUserReports' class='card'>
    <div class='card-header text-center w-100'>
      <h5>One Signal Notification Users</h5>
    </div>

    <div *ngIf='isNotifyeesCountTotals' class='row totals m-4 p-3 text-center'>
      <div class='row w-100'>
        <p class='col-9'>
          <b>Total Mobile:</b>
        </p>
        <p class='col-3 text-right'>{{totalNotifyeesCount.oneSignalUsersCount | number:'1.0-0'}}</p>
      </div>
      <div class='row w-100'>
        <p class='col-9'>
          <b>Total Desktop:</b>
        </p>
        <p class='col-3 text-right'>{{totalNotifyeesCount.webOneSignalUsersCount | number:'1.0-0'}}</p>
      </div>
    </div>

    <div class='card-body'>
      <table class='table table-striped table-borderless table-hover'>
        <thead class='thead-light'>
        <tr>
          <th class='table-text' scope='col'>Company</th>
          <th class='table-number' scope='col'>Mobile</th>
          <th class='table-number' scope='col'>Desktop</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor='let item of notifyeesCounts'>
          <td class='table-text'>{{item.name}}</td>
          <td class='table-number'>{{item.oneSignalUsersCount | number:'1.0-0'}}</td>
          <td class='table-number'>{{item.webOneSignalUsersCount | number:'1.0-0'}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf='canShowUserReports' class='card'>
    <div class='card-header text-center w-100'>
      <h5>Users Updated Profiles</h5>
    </div>

    <div *ngIf='isProfilesUpdatedCountTotal'
         class='row totals m-4 p-3 text-center'>
      <div class='row w-100'>
        <p class='col-9'>
          <b>Total Users:</b>
        </p>
        <p class='col-3 text-right'>{{totalProfilesUpdatedCount | number:'1.0-0'}}</p>
      </div>
    </div>

    <div class='card-body'>
      <table class='table table-striped table-borderless table-hover'>
        <thead class='thead-light'>
        <tr>
          <th class='table-text' scope='col'>Company</th>
          <th class='table-number' scope='col'>Users</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor='let item of profilesUpdatedCounts'>
          <td class='table-text'>{{item.name}}</td>
          <td class='table-number'>{{item.count | number:'1.0-0'}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf='canShowUserReports' class='card'>
    <div class='card-header text-center w-100'>
      <h5>App Users</h5>
    </div>

    <div *ngIf='isPlatformsCountTotal' class='row totals m-4 p-3 text-center'>
      <div class='row w-100'>
        <p class='col-9'>
          <b>ios:</b>
        </p>
        <p class='col-3 text-right'>{{totalPlatformsCount.ios}}</p>
      </div>
      <div class='row w-100'>
        <p class='col-9'>
          <b>ipad:</b>
        </p>
        <p class='col-3 text-right'>{{totalPlatformsCount.ipad}}</p>
      </div>
      <div class='row w-100'>
        <p class='col-9'>
          <b>android:</b>
        </p>
        <p class='col-3 text-right'>{{totalPlatformsCount.android}}</p>
      </div>
      <div class='row w-100'>
        <p class='col-9'>
          <b>other:</b>
        </p>
        <p class='col-3 text-right'>{{totalPlatformsCount.other}}</p>
      </div>
    </div>

    <div class='card-body'>
      <table class='table table-striped table-borderless table-hover'>
        <thead class='thead-light'>
        <tr>
          <th class='table-text' scope='col'>Company</th>
          <th class='table-number' scope='col'>ios</th>
          <th class='table-number' scope='col'>ipad</th>
          <th class='table-number' scope='col'>android</th>
          <th class='table-number' scope='col'>other</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor='let item of platformCounts'>
          <td class='table-text'>{{item.name}}</td>
          <td class='table-number'>{{item.ios | number:'1.0-0'}}</td>
          <td class='table-number'>{{item.ipad | number:'1.0-0'}}</td>
          <td class='table-number'>{{item.android | number:'1.0-0'}}</td>
          <td class='table-number'>{{item.other | number:'1.0-0'}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
