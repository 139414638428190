import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Chart } from 'chart.js';
import * as moment from 'moment';

import { LifeActionService } from '../../../../../services/life-action-service';
import { BadgeService } from '../../../../../services/badge-service';
import { UtilService } from '../../../../../services/util-service';
import { UserService } from '../../../../../services/user-service';
import { QuestionnaireService } from '../../../../../services/questionnaire-service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-questionnaire-result',
  templateUrl: './questionnaire-result.component.html',
  styleUrls: ['./questionnaire-result.component.scss'],
})
export class QuestionnaireResultComponent implements OnInit, OnDestroy {
  latestResult: any;
  allResults = [];

  questionnaire: any;
  dataLoaded: Boolean = false;
  principalResults: any;
  lifeActions = [];
  // chart options
  chartColor = '#f2f2f3';
  dotColor = '#CCCCCC';
  chart: any;
  data: any;
  isProgram = true;

  hasMultipleResults: Boolean = false;

  constructor(
    private questionnaireService: QuestionnaireService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    private utilService: UtilService,
    private badgeService: BadgeService,
    private lifeActionService: LifeActionService,
    public domSanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    const questionnaireId = this.getQuestionnaireId();
    this.chartColor = this.userService.company.primaryColor;
    this.questionnaireService.getQuestionnaireById(questionnaireId).subscribe(questionnaire => {
      this.questionnaire = questionnaire;
      if (this.questionnaireService.fromSubmit) {
        const resultsFromService = this.questionnaireService.questionnaireResults;
        this.handleResultData(resultsFromService);
        this.chartData(this.questionnaire.liqResultTypeId);
      } else {
        this.questionnaireService.getResultSets(questionnaireId).subscribe(data => {
          this.handleResultData(data);
          this.chartData(this.questionnaire.liqResultTypeId);
        });
      }
    });
  }

  getQuestionnaireId() {
    const urlStrings = this.router.url
      .toString()
      .split('/');
    const select = urlStrings[urlStrings.length - 2];
    return this.utilService.getIdFromHyphenatedUrl(select);
  }

  handleResultData(data) {
    this.principalResults = data.principalResults;
    this.latestResult = data.userResults[0];
    if (this.latestResult && this.latestResult.length > 1) {
      this.hasMultipleResults = true;
    }
    this.allResults = data.userResults;
  }

  ngOnDestroy() {
    this.questionnaireService.fromSubmit = false;
  }

  chartData(questionnaireType) {
    const userResults = this.allResults.slice(0, 5);
    const resultsOutOfArrays = [];
    userResults.map(resultArray => {
      resultArray.map(result => {
        resultsOutOfArrays.push(result);
      });
    });

    this.principalResults.map((principalResult) => {

      let results;
      if (this.hasMultipleResults) {
        results = resultsOutOfArrays.filter(rc => rc.principalResultId === principalResult.id || rc.id === principalResult.id || principalResult.associatedLiqResultIds.includes(rc.id));
      } else {
        results = resultsOutOfArrays;
      }
      const values = [];
      const dates = [];
      const colors = [];
      const points = [];
      let maxValue = 0;

      results.map(re => {
        if (re.userAnswer !== 'Value unknown') {
          if (re.userAnswerLabel !== null && questionnaireType === 4) {
            values.push(re.userAnswerLabel);
            if (maxValue < Number(re.userAnswerLabel)) {
              maxValue = Number(re.userAnswerLabel);
            }
          } else {
            values.push(re.chartNumber);
            if (maxValue < Number(re.chartNumber)) {
              maxValue = Number(re.chartNumber);
            }
          }
          dates.push(moment(re.userAnswerSetDate).format('DD-MM'));
          colors.push(re.color);
          points.push(7);
        }
      });
      if (values.length < 5) {
        values.push('');
        dates.push('');
        colors.push('');
        points.push(0);
      }
      principalResult.chartValues = values.reverse();
      principalResult.chartDates = dates.reverse();
      principalResult.chartColors = colors.reverse();
      principalResult.chartPoints = points.reverse();
      if (principalResult.maxValueChart === null) {
        principalResult.maxValueChart = Number(maxValue.toFixed(0));
      } else {
        principalResult.maxValueChart = Number(principalResult.maxValueChart);
      }
      if (principalResult.minValueChart === null) {
        principalResult.minValueChart = 0;
      } else {
        principalResult.minValueChart = Number(principalResult.minValueChart);
      }
    });
    this.latestResult.map(res => {
      const matchingResult = this.principalResults.find(pr => res.principalResultId === pr.id || res.id === pr.id);
      if (res.hasHistoryChart) {
        res.chartData = matchingResult;
      } else {
        res.chartData = {
          articles: [],
        };
        res.chartData.articles = matchingResult.articles;

      }

      this.lifeActions.push(matchingResult.indicators);
      this.dataLoaded = true;
    });
  }

  generateGoToDetailLink(item) {
    item = this.getContentTypeName(item);
    const url = `${this.router.url}/${item.contentTypeName}`;
    // window.scroll(0, 0);
    return [url, item.id];
  }

  private getContentTypeName(content) {
    if (content.contentType === 1) {
      content.contentTypeName = 'article';
    }

    if (content.contentType === 2) {
      content.contentTypeName = 'recipe';
    }

    if (content.contentType === 3) {
      content.contentTypeName = 'video';
    }

    return content;
  }

  drawLineValues() {
    const ctx = this.chart.ctx;
    ctx.font = Chart.helpers.fontString(
      Chart.defaults.global.defaultFontSize,
      'normal',
      Chart.defaults.global.defaultFontFamily,
    );
    ctx.fillStyle = this.chart.config.options.defaultFontColor;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';
    this.data.datasets.forEach(function(dataset) {
      for (let i = 0; i < dataset.data.length; i++) {
        if (
          dataset.hidden === true &&
          dataset._meta[Object.keys(dataset._meta)[0]].hidden !== false
        ) {
          continue;
        }
        const model =
          dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
        if (dataset.data[i] !== null) {
          ctx.fillText(dataset.data[i], model.x - 1, model.y - 8);
        }
      }
    });
  }

  onDoLifeAction(indicatorBody) {
    this.lifeActions = this.lifeActions.map(resultArray => {
      return resultArray.map(individualAction => {
        if (individualAction.id === +indicatorBody.indicator.id) {
          individualAction = indicatorBody.indicator;
        }
        return individualAction;
      });
    });
  }

  onChangeActivationStatus(item) {

    if (item.active) {
      this.lifeActionService.activateLifeAction(item)
        .subscribe(data => {
          this.lifeActions = this.lifeActions.map(resultArray => {
            return resultArray.map(individualAction => {
              if (individualAction.id === +item.indicatorId) {
                individualAction = data;
              }
              return individualAction;
            });
          });
        });
    }
  }
}
