import { LocatorService } from '../../../services/locator-service';
import { ApplicantData, ChildData, SchoolReqData } from './school-formData.model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from '../../../services/util-service';
import { UserService } from '../../../services/user-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { PrivacyComponent } from '../../_shared/components/privacy/privacy.component';
import { PrivacyCollectionStatementComponent } from '../../_shared/components/privacy-collection-statement/privacy-collection-statement.component';

@Component({
  selector: 'app-school-locator',
  templateUrl: './school-locator.component.html',
  styleUrls: ['./school-locator.component.scss'],
})
export class SchoolLocatorComponent implements OnInit {
  applicantData = {};
  childData = {};
  schoolReqData = {};
  isSame = false;
  isOnAppicantDetails = true;
  isApplicantDetailsDone = false;
  isOnChildDetails: boolean;
  isChildDetailsDone = false;
  isOnService: boolean;
  isServiceDone = false;
  isOnReview: boolean;
  isReviewDone = false;
  years = [];

  constructor(
    private router: Router,
    private locatorService: LocatorService,
    private utilService: UtilService,
    private modalService: NgbModal,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    var date = new Date();
    var year = date.getFullYear();

    for (var i = 0; i < 10; i++) {
      this.years.push(year + i);
    }

    this.applicantData = new ApplicantData();
    this.childData = new ChildData();
    this.schoolReqData = new SchoolReqData();
  }

  applySelf() {
    if ('Yes') {
      this.childData['personal_details'] = this.applicantData[
        'personal_details'
        ];
    }
  }

  onReturn(page, elem) {
    switch (page) {
      case 'childData': {
        this.isOnAppicantDetails = true;
        this.isOnChildDetails = false;
        elem.scrollIntoView({ behavior: 'smooth' });
        break;
      }
      case 'schoolReqData': {
        this.isOnService = false;
        this.isOnChildDetails = true;
        elem.scrollIntoView({ behavior: 'smooth' });
        break;
      }
      case 'review': {
        this.isOnReview = false;
        this.isOnService = true;
        elem.scrollIntoView({ behavior: 'smooth' });
        break;
      }
    }
  }

  onNext(type, elem) {
    switch (type) {
      case 'applicantData': {
        this.isApplicantDetailsDone = true;
        this.isOnAppicantDetails = false;
        this.isOnChildDetails = true;
        elem.scrollIntoView({ behavior: 'smooth' });
        break;
      }
      case 'childData': {
        this.isChildDetailsDone = true;
        this.isOnChildDetails = false;
        this.isOnService = true;
        elem.scrollIntoView({ behavior: 'smooth' });
        break;
      }
      case 'schoolReqData': {
        this.isServiceDone = true;
        this.isOnService = false;
        this.isOnReview = true;
        elem.scrollIntoView({ behavior: 'smooth' });
        break;
      }
    }
  }

  clearData() {
    this.applicantData = new ApplicantData();
    this.childData = new ChildData();
    this.schoolReqData = new SchoolReqData();
  }

  onSubmit() {
    const formValue = {
      applicantData: this.applicantData,
      childData: this.childData,
      schoolReqData: this.schoolReqData,
      type: 'school',
      company: this.userService.company.id,
    };
    this.isReviewDone = true;
    this.locatorService
      .sendLocatorEnquiry('school', formValue)
      .subscribe(res => {
        if (res.success === true) {
          this.utilService.showInfo(
            'Thank you for your enquiry. Someone from the Seventeenhundred team will be in touch within 48 hours.',
            2,
            'check',
          );
          this.clearData();
          this.router.navigate(['/toolkit/locators']);
        } else {
          this.utilService.showInfo(
            'Something went wrong. Please try to submit your enquiry again later.',
            1,
            'exclamation',
          );
          this.clearData();
          this.router.navigate(['/toolkit/locators']);
        }
      });
  }

  openPolicy(policy) {
    if (policy === 'privacy') {
      this.modalService.open(PrivacyComponent);
    } else {
      this.modalService.open(PrivacyCollectionStatementComponent);
    }
  }

  formatDateDisplay(item) {
    return moment(item.date).format('DD/MM/YYYY');
  }
}
