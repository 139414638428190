import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { get } from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { interval } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { UserService } from '../../services/user-service';
import { UnSubscribeComponent } from '../_shared/un-subscribe.component';
import { CompanyRegisterService } from '../../services/company-register.service';
import { UtilService } from '../../services/util-service';
import { ICompanyRegisterInfo } from '../_models/company-resgister-info.interface';
import { FilestackService } from '../../services/filestack-service';
import { HomePreviewComponent } from './preview/home-preview/home-preview.component';
import { BOOKING_CODE_FORMAT, HEX_FORMAT, REGISTER_CODE_FORMAT } from '../_shared/formats';
import { DomainValidator } from '../../validators/domain-validator';
import { RegisterCodeValidator } from '../../validators/register-code-validator';

@Component({
  selector: 'app-company-register-info',
  templateUrl: './company-register-info.component.html',
  styleUrls: ['./company-register-info.component.scss'],
})
export class CompanyRegisterInfoComponent extends UnSubscribeComponent implements OnInit {
  title = 'Error';
  logo: string;
  infoMessage: string;
  iconClass = 'fa fa-exclamation-circle text-danger';
  companyRegisterInfo: ICompanyRegisterInfo;
  form: FormGroup;
  countDownTimes = 10;
  mainDomain: string;
  insightsDomain: string;
  bookingCodeInvalid: boolean = false;
  disableVerifyBookingCode: boolean = true;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
    private modalService: NgbModal,
    private userService: UserService,
    private utilService: UtilService,
    private companyRegisterService: CompanyRegisterService,
    private fileStackService: FilestackService,
    private domainValidator: DomainValidator,
    private registerCodeValidator: RegisterCodeValidator,
  ) {
    super();
    this.mainDomain = this.userService.envVariables.app.mainDomain;
    this.insightsDomain = this.userService.envVariables.app.insightsDomain;
  }

  ngOnInit() {
    const isMainDomain = location.href.indexOf(this.userService.envVariables.app.url) !== -1;
    if (!isMainDomain) {
      location.href = this.userService.envVariables.app.url;
    }

    this.activatedRoute.queryParamMap
      .pipe(take(1), takeUntil(this.unSubscribeOnDestroy))
      .subscribe((pm) => {
        if (pm.has('token') && pm.has('email')) {
          const token = pm.get('token');
          const email = pm.get('email');
          this.getRegisterInfo(email, token);
        } else {
          this.router.navigateByUrl('/');
        }
      });
  }

  openFilestack() {
    this.fileStackService.pickCompanyImage().then(
      (res) => {
        this.ngZone.run(() => {
          this.form.patchValue({ logo: res });
          this.setLogo(res);
        });
      },
      (err) => {
        this.utilService.showToastError('Error while uploading picture', err);
      },
    );
  }

  changeColor(field, color) {
    const patchValueObject = {};
    patchValueObject[field] = color;
    this.form.patchValue(patchValueObject);
  }

  onPreview() {
    this.markAllFieldsAsTouched();
    if (!this.form.invalid) {
      const modalRef = this.modalService.open(HomePreviewComponent, {
        backdrop: 'static',
        centered: true,
        keyboard: false,
        windowClass: 'home-preview-dialog',
      });
      const componentInstance = modalRef.componentInstance as HomePreviewComponent;
      componentInstance.logoSrc = this.logo;
      componentInstance.primaryColor = this.form.get('primaryColor').value;
      componentInstance.topTextBarColor = this.form.get('topTextBarColor').value;
      componentInstance.bodyTextColor = this.form.get('bodyTextColor').value;
    }
  }

  onSubmit() {
    this.markAllFieldsAsTouched();
    if (!this.form.invalid) {
      if (moment().isAfter(this.companyRegisterInfo.expiredAt)) {
        this.infoMessage = 'The request is expired';
        this.companyRegisterInfo = null;
        this.redirectToLandingPage();
      } else {
        const fullDomain = `${this.form.get('domainName').value}.${this.mainDomain}`;
        const bodyRequest = {
          ...this.form.value,
          domainName: fullDomain,
        };
        this.companyRegisterService
          .createNewCompanyForNewCustomer(bodyRequest)
          .pipe(takeUntil(this.unSubscribeOnDestroy))
          .subscribe((res) => {
            if (!res.success) {
              this.utilService.showToastError(res.message);
            } else {
              const domain = get(res, 'data.0.domain', fullDomain);
              this.utilService.showToastSuccess('Your request is sent successful!');
              this.infoMessage = `Your organisation's AltiusLife experience is being created.`;
              this.iconClass = 'fa fa-check-circle text-success';
              this.companyRegisterInfo = null;
              this.resetForm();
              this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: { token: null, email: null },
                queryParamsHandling: 'merge',
              });
              this.redirectToUrl(domain);
            }
          });
      }
    }
  }

  private redirectToUrl(domain: string) {
    const url = this.normalizeDomain(domain);
    interval(1000)
      .pipe(
        take(11),
        takeUntil(this.unSubscribeOnDestroy),
        finalize(() => {
          location.href = url;
        }),
      )
      .subscribe(() => {
        if (this.countDownTimes > 0) {
          this.countDownTimes = this.countDownTimes - 1;
        }
      });
  }

  private redirectToLandingPage() {
    interval(1000)
      .pipe(
        take(11),
        takeUntil(this.unSubscribeOnDestroy),
        finalize(() => {
          this.router.navigateByUrl('/');
        }),
      )
      .subscribe(() => {
        if (this.countDownTimes > 0) {
          this.countDownTimes = this.countDownTimes - 1;
        }
      });
  }

  private resetForm() {
    this.form.reset();
    this.logo = '';
  }

  private markAllFieldsAsTouched() {
    this.form.get('name').markAsTouched();
    this.form.get('registerCode').markAsTouched();
    this.form.get('bookingCode').markAsTouched();
    this.form.get('logo').markAsTouched();
    this.form.get('primaryColor').markAsTouched();
    this.form.get('topTextBarColor').markAsTouched();
    this.form.get('bodyTextColor').markAsTouched();
    this.form.get('enabledEAPFeature').markAsTouched();
  }

  private setLogo(image?) {
    if (!image || image.trim().length === 0) {
      return;
    }

    this.logo = this.userService.getLogoForCompany(image);
  }

  private getRegisterInfo(email: string, token: string) {
    this.companyRegisterService
      .getCompanyRegisterByEmailAndToken(email.toLowerCase(), token)
      .pipe(takeUntil(this.unSubscribeOnDestroy))
      .subscribe((res) => {
        if (!res.success) {
          this.infoMessage = res.message;
          this.redirectToLandingPage();
        } else {
          this.companyRegisterInfo = res.data;
          this.initForm(res.data);
          this.setLogo(res.data.logo);
          this.title = this.companyRegisterInfo.companyId
            ? 'Edit Customer'
            : 'Set up AltiusLife for your organisation';
        }
      });
  }

  bookingCodeValidationHandler(validation) {
    this.bookingCodeInvalid = validation.hasError && this.form.get('bookingCode').touched && this.form.get('bookingCode').invalid;
    this.disableVerifyBookingCode = !this.form.get('bookingCode').value || validation.hasError;
  }

  openBookingSite() {
    window.open(`https://www.peoplesense.au/${this.form.get('bookingCode').value.toUpperCase()}`, '_blank');
  }

  private initForm(data: ICompanyRegisterInfo) {
    const domain = data.domain ? data.domain.substring(0, data.domain.indexOf('.')) : '';
    this.form = this.fb.group({
      name: [data.companyName ? data.companyName : '', [Validators.required]],
      registerCode: [
        data.registerCode ? data.registerCode : '',
        [Validators.required, Validators.pattern(REGISTER_CODE_FORMAT), Validators.maxLength(22)],
        [this.registerCodeValidator.validateCode(this.companyRegisterInfo.companyId)],
      ],
      domainName: [
        domain,
        [Validators.required, Validators.pattern(REGISTER_CODE_FORMAT), Validators.maxLength(22)],
        [
          this.domainValidator.validateDomain(
            this.companyRegisterInfo.companyId,
            this.mainDomain,
          ),
        ],
      ],
      insightsDomain: [''],
      bookingCode: [data.bookingCode || '', Validators.pattern(BOOKING_CODE_FORMAT)],
      logo: [data.logo ? data.logo : '', [Validators.required]],
      primaryColor: [
        data.primaryColor ? data.primaryColor : '#8c8c8c',
        [Validators.required, Validators.pattern(HEX_FORMAT)],
      ],
      topTextBarColor: [
        data.topTextBarColor ? data.topTextBarColor : '#8c8c8c',
        [Validators.required, Validators.pattern(HEX_FORMAT)],
      ],
      bodyTextColor: [
        data.bodyColor ? data.bodyColor : '#8c8c8c',
        [Validators.required, Validators.pattern(HEX_FORMAT)],
      ],
      enabledEAPFeature: [data.enabledEAPFeature],
      requestedInfo: this.fb.group({
        email: [data.email, [Validators.required]],
        token: [data.token, [Validators.required]],
        companyId: [data.companyId],
      }),
    });

    if (domain) {
      this.form.patchValue({
        domainName: `${domain}`,
        insightsDomain: `${domain}.${this.insightsDomain}`,
      });
    } else {
      // this.form
      //   .get('registerCode')
      //   .valueChanges.pipe(takeUntil(this.unSubscribeOnDestroy))
      //   .subscribe((value) => {
      //     if (!this.form.get('domainName').dirty) {
      //       this.form.patchValue({
      //         domainName: `${value}`,
      //         insightsDomain: `${value}.${this.insightsDomain}`,
      //       });
      //     }
      //   });
    }

    this.form
      .get('domainName')
      .valueChanges.pipe(takeUntil(this.unSubscribeOnDestroy))
      .subscribe((value) => {
        this.form.patchValue({
          insightsDomain: `${value}.${this.insightsDomain}`,
        });
      });
  }

  private normalizeDomain(domain: string) {
    if (/^((http|https):\/\/)/.test(domain)) {
      return domain;
    }
    return `https://${domain}`;
  }
}
