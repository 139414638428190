import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '../../../services/util-service';

@Component({
  selector: 'app-cms-list-select',
  templateUrl: './cms-list-select.component.html',
  styleUrls: ['./cms-list-select.component.scss']
})
export class CmsListSelectComponent implements OnInit {

  alert = { type: null, message: null };
  search = '';
  config = {
    itemsPerPage: 10,
    currentPage: 1
  };
  selectedAll = false;
  // Titles = list of colunms
  @Input('columnList') columnList: any[];
  // table(s) array list according to colummlist
  @Input('dataList') dataList: any[];
  @Input('selectedItens') selectedItens: any[];
  @Input('title') title: string;

  constructor(public router: Router,
    public activeModal: NgbActiveModal,
    public utilService: UtilService) { }

  ngOnInit() {

    if (!this.isValidColumnList()) {
      this.alert.type = 'alert';
      this.alert.message = 'Number of columns invalid. Minumum value of columns is 1.';
      return;
    }

    if (!this.isDataRows()) {
      this.alert.type = 'info';
      this.alert.message = 'No records found.';
      return;
    }

    if (!this.isValidDataList()) {
      this.alert.type = 'alert';
      this.alert.message = 'Number of data columns invalid. The data list should have the same number of columns than the column list.';
      return;
    }
    this.selectedItens.forEach(si => {
      const itemList = this.dataList.find(item => +item[0] === +si[0]);
      if (itemList) {
        itemList.checked = true;
      }
    });
  }

  private isValidColumnList() {
    return this.columnList && this.columnList.length;
  }

  private isValidDataList() {
    return this.dataList && this.dataList.length && this.dataList[0].length === this.columnList.length;
  }

  private isDataRows() {
    return this.dataList.length > 1;
  }

  setCheckAllButton() {
    const start = (this.config.itemsPerPage * this.config.currentPage) - this.config.itemsPerPage;
    const end = start + this.config.itemsPerPage;
    const pageContent = this.dataList.slice(start, end);
    const pageContentChecked = pageContent.filter(c => c.checked);
    this.selectedAll = (pageContentChecked.length === pageContent.length);
    return;
  }

  selectAll() {
    const start = (this.config.itemsPerPage * this.config.currentPage) - this.config.itemsPerPage;
    const end = start + this.config.itemsPerPage;
    for (let i = start; i < end; i++) {
      this.dataList[i].checked = !this.selectedAll;
    }
  }


  save() {
    this.selectedItens = this.dataList.filter(item => item.checked);
    this.activeModal.close(this);
  }

  close() {
    this.activeModal.close();
  }
}
