<div class="container">
  <div class="row">
    <h2 class="mt-2">Teams</h2>
  </div>
  <div class="row" *ngIf="items && items.length === 0 && !loading">
    <p>You haven't joined a team yet</p>
  </div>
  <div class="row" *ngIf="loading">
    <div class="col-12 w-100">
      <h4>Loading...</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-6 col-lg-6 d-flex" *ngFor="let i of items">
      <!-- <div class="card" style="width: auto;">
        <a class="contentAnchorTag" (click)="goToDetailLink(i.id)">
          <img class="card-img-top" src="{{i.image}}" alt="Card image cap">
          <div class="card-body">
            <p class="card-text text-center">{{i.name}}</p>
          </div>
        </a>
        <button type="button" *ngIf="!i.isMember" style="font-size: 0.85rem; width: 75% !important; margin-left: 13%; margin-bottom: 13%" class="btn btn-primary w-100" (click)="joinTeam(i)" [disabled]="isLoading">
          <i class="fa fa-sign-in mr-2" aria-hidden="true"></i> Join Now
        </button>
        <button type="button" *ngIf="i.isMember" style="font-size: 0.85rem; width: 75% !important; margin-left: 13%; margin-bottom: 13%" class="btn btn-outline-primary w-100" (click)="leaveTeam(i)" [disabled]="isLoading">
            <i class="fa fa-sign-out mr-2" aria-hidden="true"></i> Leave Now
          </button>
      </div> -->

      <div class="content-card card-outline mr-0 ml-0 mt-4" ng-reflect-ng-class="content-card card-outline mr-0">
        <a class="contentAnchorTag" (click)="goToDetailLink(i.id)">
          <div class="img-size-div"><img class="card-img-top img-size" ng-reflect-ng-class="card-img-top img-size"
              src="{{i.image}}">
          </div>
          <div class="row m-0 p-2 mb-2">
            <div class="col-12 p-0">
            </div>
            <div class="content-card-title">{{i.fullName}}</div>
          </div>
        </a>
        <button type="button" *ngIf="!i.isMember"
          style="font-size: 0.85rem; width: 75% !important; margin-left: 13%; margin-bottom: 13%"
          class="btn btn-primary w-100" (click)="joinTeam(i)" [disabled]="isLoading">
          <i class="fa fa-sign-in mr-2" aria-hidden="true"></i> Join Now
        </button>
        <button type="button" *ngIf="i.isMember"
          style="font-size: 0.85rem; width: 75% !important; margin-left: 13%; margin-bottom: 13%"
          class="btn btn-outline-primary w-100" (click)="leaveTeam(i)" [disabled]="isLoading">
          <i class="fa fa-sign-out mr-2" aria-hidden="true"></i> Leave Now
        </button>
      </div>
    </div>
  </div>
</div>