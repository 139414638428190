import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth-service';
import { UserService } from '../../../services/user-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UnSubscribeComponent } from '../../_shared/un-subscribe.component';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AlertComponent } from '../../../components/alert-modal/alert-modal.component';
import { ConnectionService } from '../../../services/connection-service';
import * as _ from 'lodash';

@Component({
  selector: 'app-auth-close-account',
  templateUrl: './close-account.component.html',
  styleUrls: ['./close-account.component.scss'],
})
export class CloseAccountComponent extends UnSubscribeComponent implements OnInit {
  isLoading: boolean;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private connectionService: ConnectionService,
  ) {
    super();
  }

  ngOnInit() {
    this.isLoading = true;
    this.activatedRoute.queryParamMap
      .pipe(
        switchMap((params) => {
          if (params.has('code')) {
            const authorizationCode = params.get('code');
            const email = this.userService.user.email;
            const redirectUri = `${this.connectionService.getBaseRedirectUrl()}/auth/close-account`;
            return this.authService.closeAccount({authorizationCode, email, redirectUri});
          } else if (params.has('error') || params.has('error_description')) {
            this.isLoading = false;
            this.handleErrorResponse();
          }
        }),
        takeUntil(this.unSubscribeOnDestroy),
      )
      .subscribe((res) => {
        this.isLoading = false;
        this.handleResponse(res);
      });
  }

  handleErrorResponse() {
    let alertModal = this.modalService.open(AlertComponent, { beforeDismiss: () => false });
    alertModal.componentInstance.title = 'Error!';
    alertModal.componentInstance.message = 'Something went wrong. Please try again later.';
    alertModal.componentInstance.type = 1;
    alertModal.componentInstance.showCloseIcon = false;
    alertModal.result.then(() => {
      window.location.href = `${window.location.origin}/home`;
    });
  }

  handleResponse(res) {
    if (res && _.has(res, 'success') && !res.success) {
      const errorModal = this.modalService.open(AlertComponent, {
        beforeDismiss: () => false,
      });
      errorModal.componentInstance.title = 'Error!';
      errorModal.componentInstance.textButton = 'Close';
      errorModal.componentInstance.showCloseIcon = false;
      errorModal.componentInstance.message =
        res.err || 'Error when close AltiusLife Account!';
      errorModal.result.then(() => {
        window.location.href = `${window.location.origin}/home`;
      });
      return;
    }
    const modal = this.modalService.open(AlertComponent, {
      beforeDismiss: () => false,
    });
    modal.componentInstance.title = 'Close My AlitusLife Account';
    modal.componentInstance.textButton = 'Close';
    modal.componentInstance.showCloseIcon = false;
    modal.componentInstance.message =
      'Your account has been successfully closed';
    modal.result.then(() => {
      this.authService.logout();
      window.location.href = window.location.origin;
    });
  }
}
