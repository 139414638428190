<div class="container">
    <div class="row" *ngIf="loading">
        <div class="col-12 w-100">
          <h4>Loading...</h4>
        </div>
      </div>
  <div class="content-background p-5" *ngIf="!loading">
    <div class="row">
      <div class="col-12 w-100">
        <h4>{{challengeName}}</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <div class="card">
          <img class="card-img-top"
            src="{{imagePath}}" alt="Card image cap">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <h5>About the challenge</h5>
        <div class="fr-view description mt-4" *ngIf='description' [innerHTML]="description"></div>
        <br>
        <b>Start Date</b>&nbsp;&nbsp;{{startDate}} -
        <b>End Date</b>&nbsp;&nbsp;{{endDate}}
      </div>
    </div>
    <div class="row">
      <div class="col-12 w-100">
        <br>
        <div class="border"></div>
        <br>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <h5>Available team for challenge</h5>
      </div>
    </div>
    <!-- Challenges list -->
    <div class="row" *ngFor="let i of teams">
      <div class="col-xs-1 col-sm-2 mt-3">
        <div class="card" style="width: auto;">
          <img class="card-img-top"
            src="{{i.image}}"
            alt="Card image cap">
        </div>
      </div>
      <div class="col-xs-6 col-sm-8 mt-3">
          <div class="row">
            <div class="col-xs-4 col-sm-12">
                <h5>{{i.name}}</h5>
            </div>
          </div>
          <div class="row">
              <div class="col-xs-4 col-sm-6">
                  <div class="progress">
                      <div class="progress-bar" role="progressbar" [style.width]="i.stepsCompletedInPercentage + '%'" attr.aria-valuenow="{{i.stepsCompletedInPercentage}}"
                      aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <p class="font-size"><b>Steps Completed: </b>{{i.stepsCompleted}}/{{i.stepsGoal}}</p>
                </div>
          </div>
      </div>
      <div *ngIf="!i.isMember" class="col-sm-2 mt-3 margin-left">
        <button type="button" class="btn btn-primary mt-4 btn-width" [disabled]="buttonLoading || !allowJoinTeam" (click)="joinTeam(i.id)">
          Join Now</button>
      </div>
      <div *ngIf="i.isMember" class="col-sm-2 mt-3 margin-left">
        <button type="button" class="btn btn-primary mt-4 btn-width" [disabled]="buttonLoading" (click)="viewTeam(i.id)">
          View</button>
      </div>
    </div>
    <div *ngIf="teams.length == 0">
        <p>No teams available to join now</p>
      </div>
  </div>
</div>
