<app-cms-list-view *ngIf="isDisplayingList && isInitialised" [dataList]="items" [columnList]="columns" [isPreventListDelete] = "true">
</app-cms-list-view>
<div class="padding jumbotron" *ngIf="!isDisplayingList && isInitialised">
  <div class="accordion" id="accordion">
    <div class="card">
      <div class="card-header" id="headingOne">
        <h5 class="mb-0">
          Questionnaire details: {{item.name}}
          <button class="btn btn-link float-right text-toggle" type="button" data-toggle="collapse" [attr.data-target]="'#questionnaireAccordion' + item.id"
            aria-expanded="false" aria-controls="questionnaireAccordion" #questionnaireAccordionButton><span class="expand">
              <i class="fa fa-plus-circle pr-2"></i>Expand questionnaire</span>
            <!-- <span class="collapse">
              <i class="fa fa-minus-circle pr-2"></i>Collapse questionnaire</span> -->
          </button>

        </h5>
      </div>

      <div [id]="'questionnaireAccordion' + item.id" [class]="isAddingItem? 'collapse show' : 'collapse'"
        aria-labelledby="questionnaireAccordion" data-parent="#accordion">
        <div class="card-body">



          <form [formGroup]="itemForm" (ngSubmit)="onSaveClick()">

            <div class="row m-0">
              <div class="col-12">
                <!-- Name -->
                <div class="form-group">
                  <label for="name" class="control-label required">
                    Name
                  </label>
                  <input type="text" class="form-control" [ngClass]="displayFieldCss('nameControl')" formControlName="nameControl">
                  <span class="invalid-feedback">Name is required</span>
                </div>
              </div>

              <!-- Summary -->
              <div class="col-12">
                <div class="form-group">
                  <label for="description">Summary</label>
                  <div [froalaEditor]="options" formControlName="summaryControl"></div>
                </div>
              </div>

              <!-- Description -->
              <div class="col-12">
                <div class="form-group">
                  <label for="description">Description</label>
                  <div [froalaEditor]="options" formControlName="descriptionControl"></div>
                </div>
              </div>

              <!-- Active-->
              <div class="col-4">
                <div class="form-group">
                  <label for="active">Active</label>
                  <select class="form-control custom-select" formControlName="activeControl">
                    <option *ngFor="let item of yesNoItems" [value]="item.id">{{item.name}}</option>
                  </select>
                </div>
              </div>

              <!-- Multiple Answers-->
              <div class="col-4">
                <div class="form-group">
                  <label for="active">Can the user complete multiple times?</label>
                  <select class="form-control custom-select" formControlName="multipleAnswersControl">
                    <option *ngFor="let item of yesNoItems" [value]="item.id">{{item.name}}</option>
                  </select>
                </div>
              </div>

              <!-- Show History -->
              <div class="col-4">
                <div class="form-group">
                  <label for="active">Show History Graph</label>
                  <select class="form-control custom-select" formControlName="showHistoryControl">
                    <option *ngFor="let item of yesNoItems" [value]="item.id">{{item.name}}</option>
                  </select>
                </div>
              </div>

              <!-- Feature -->
              <div class="col-6">
                <div class="form-group">
                  <label for="feature" class="control-label required">Feature</label>
                  <select class="form-control custom-select" [ngClass]="displayFieldCss('featureIdControl')"
                    formControlName="featureIdControl">
                    <!-- <option *ngIf="!itemForm.get('featureIdControl').value" value="null" selected>Select a Feature</option> -->
                    <option *ngFor="let item of features" [value]="item.id">{{item.name | featureName}}</option>
                  </select>
                  <span class="invalid-feedback">Feature is required</span>
                </div>
              </div>

              <!-- Company -->
              <div class="col-6" *ngIf="canSelectCompany">
                <div class="form-group">
                  <app-select-company [allCompanies]="allCompanies" [selectedCompanyId]="itemForm.get('companyIdControl').value"
                    [label]="'Company'" [placeholder]="'All (default)'" (companyIdSelected)="onCompanySelected($event)"></app-select-company>
                </div>
              </div>

              <!-- Excluded Companies -->
              <div *ngIf="isAllCompanies()"
                class="col-6">
                <div class="form-group">
                  <app-select-companies [allCompanies]="allCompanies" [selectedCompanyIds]="excludedCompanyIds" [label]="'Excluded Companies'"
                    [placeholder]="'Select Company to exclude'" (companyIdsSelected)="excludedCompanyIdsSelected($event)"></app-select-companies>
                </div>
              </div>

              <!-- Start Date -->
              <div class="col-6">
                <div class="form-group">
                  <label for="startDate" class="control-label required">Start Date</label>
                  <div class="input-group">
                    <input placeholder="yyyy-mm-dd" class="form-control" [ngClass]="displayFieldCss('startTimeControl')"
                      formControlName="startTimeControl" (blur)="sd.close()" [showWeekdays]="false" #sd="ngbDatepicker"
                      ngbDatepicker />
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" (click)="sd.toggle();ed.close();" type="button">
                        <i class="fa fa-calendar"></i>
                      </button>
                    </div>
                    <div class="invalid-feedback">
                      Start Date invalid.
                    </div>
                  </div>
                </div>
              </div>

              <!-- End Date -->
              <div class="col-6">
                <div class="form-group">
                  <label for="startDate" class="control-label required">End Date</label>
                  <div class="input-group">
                    <input placeholder="yyyy-mm-dd" class="form-control" [ngClass]="displayFieldCss('endTimeControl')"
                      formControlName="endTimeControl" (blur)="ed.close()" [showWeekdays]="false" #ed="ngbDatepicker"
                      ngbDatepicker />
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" (click)="ed.toggle();sd.close()" type="button">
                        <i class="fa fa-calendar"></i>
                      </button>
                    </div>
                    <div class="invalid-feedback">
                      Invalid End Date (Must NOT be earlier than Start Date)
                    </div>
                  </div>
                </div>
              </div>

              <!-- Result Type -->
              <div class="col-12">
                <div class="form-group">
                  <label class="control-label required">Result Type
                    <!-- <small *ngIf="results.length > 0">( to change it, first delete the results)</small> -->
                  </label>
                  <div class="row">
                    <div *ngFor="let rt of resultTypes" class="col pt-2">
                      <div class="custom-control custom-radio pt-2">
                        <input type="radio" id="{{+rt.id}}" [value]="rt.id" formControlName="liqResultTypeIdControl"
                          class="custom-control-input mr-2">
                        <label class="custom-control-label" for="{{+rt.id}}"><b>{{rt.type}}</b></label>
                        <p><small>{{resultTypeDescriptions[rt.id]}} {{rt.id}}</small></p>
                      </div>
                      <img class="result-type" src="/assets/img/questionnaire-result-types/{{rt.id}}.png" />
                    </div>
                  </div>
                  <span class="invalid-feedback">Result Type is required</span>
                </div>
              </div>

              <!-- Banner -->
              <div class="col-12">
                <div class="form-group">
                  <label class="control-label required">
                    Banner
                  </label>
                  <div class="input-group">
                    <input type="text" class="form-control" [ngClass]="displayFieldCss('bannerControl')"
                      placeholder="Choose the banner image"
                      formControlName="bannerControl" readonly />
                    <div class="input-group-prepend">
                      <button type="button" class="input-group-text btn btn-primary"
                        (click)="onBannerClick()">
                        Browse
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Widget Image -->
              <div *ngIf="isCompetition()" class="col-12">
                <div class="form-group">
                  <label class="control-label required">
                    Widget Image
                  </label>
                  <div class="input-group">
                    <input type="text" class="form-control" [ngClass]="displayFieldCss('widgetImageControl')"
                      placeholder="Choose the widget image" formControlName="widgetImageControl" readonly />
                    <div class="input-group-prepend">
                      <button type="button" class="input-group-text btn btn-primary" (click)="onWidgetImageClick()">
                        Browse
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Terms and Conditions -->
              <div class="col-12">
                <div class="form-group">
                  <label class="control-label required">Terms and Conditions</label>
                  <div [froalaEditor]="options" formControlName="termConditionsControl"></div>
                </div>
              </div>
            </div>

            <!-- Buttons -->
            <br />
            <div class="py-2">
              <button type="submit" class="btn btn-primary float-right" [disabled]="!canRespondToButtons">
                <i class="fa fa-floppy-o mr-2" aria-hidden="true"></i>Save
              </button>
            </div>
            <br />
          </form>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="headingTwo">
        <h5 class="mb-0">
          Questions
          <button class="btn btn-link collapsed float-right" type="button" data-toggle="collapse" data-target="#questions"
            aria-expanded="false" aria-controls="questions" #questionsAccordionButton>
            <i class="fa fa-plus-circle pr-2"></i>Expand questions
          </button>
        </h5>
      </div>
      <div id="questions" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
        <div class="card-body">

          <!-- Questions list -->
          <div class="col-6">
            <label class="control-label required">Questions
            </label>
          </div>
          <div class="table-responsive shadow-lg mb-2" *ngIf="isEditingItem">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Question number</th>
                  <th>Question</th>
                  <th>Answer type</th>
                  <th>Dependency</th>
                  <th class="text-center">Answers</th>
                  <th class="text-center">Edit</th>
                  <th class="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of questions">
                  <td>{{item.order}}</td>
                  <td>{{item.question}}
                    <ul [id]="'answerToggle' + item.id" class="collapse">
                      <li *ngFor="let a of item.liqAnswers">{{a.label}}</li>
                    </ul>
                  </td>
                  <td>{{item.answerType}}</td>
                  <td *ngIf="item.dependencyParams">"{{item.dependencyParams.question}}"</td>
                  <td *ngIf="!item.dependencyParams"></td>
                  <td class="text-center">
                    <a class="btn btn-link" [href]="'#answerToggle' + item.id" data-toggle="collapse" *ngIf="item.liqAnswers.length > 1">
                      <i class="fa fa-eye mr-2" aria-hidden="true"></i> {{item.liqAnswers.length}}
                    </a>
                    <span *ngIf="item.liqAnswers.length === 1">1</span>
                  </td>

                  <td (click)="editQuestion(item.id)" class="text-center">
                    <i class="fa fa-pencil primaryColor" aria-hidden="true"></i>
                  </td>
                  <td (click)="deleteQuestion(item)" class="text-center">
                    <i class="fa fa-trash primaryColor" aria-hidden="true"></i>
                  </td>
                </tr>
                <tr *ngIf="!questions.length">
                  <th colspan="6" class="text-center">No questions added to the questionnaire</th>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row mt-3 mb-2">
            <div class="col-12">
              <button type="button" class="btn btn-primary float-right" (click)="addQuestion()" [hidden]="isAddingItem || isAddingQuestion">
                <i class="fa fa-plus mr-2" aria-hidden="true"></i> Add question
              </button>
            </div>
          </div>
          <app-question-management (finishedQuestionEdit)="finishedQuestionEdit($event)" [questionnaire]="item" [allCategories]='allCategories'
            [isAddingItem]="true" [isDependentItem]="true" [dependencyParams]="dependentQuestionParams" *ngIf="isCreatingDependentQuestion"></app-question-management>
          <app-question-management (finishedQuestionEdit)="finishedQuestionEdit($event)" (createDependentForm)="createDependentForm($event)" [allCategories]='allCategories'
            [questionnaire]="item" [isAddingItem]="true" *ngIf="isAddingQuestion"></app-question-management>
          <app-question-management (finishedQuestionEdit)="finishedQuestionEdit($event)" (createDependentForm)="createDependentForm($event)" [allCategories]='allCategories'
            [questionnaire]="item" [isEditingItem]="true" [questionId]="editableQuestionId" *ngIf="isEditingQuestion"></app-question-management>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="headingThree">
        <h5 class="mb-0">
          Results
          <button class="btn btn-link collapsed float-right" type="button" data-toggle="collapse" data-target="#results"
            aria-expanded="false" aria-controls="results" #resultsAccordionButton>
            <i class="fa fa-plus-circle pr-2"></i>Expand results </button>
        </h5>
      </div>
      <div id="results" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
        <div class="card-body">

          <!-- Result list -->
          <div class="row mt-3 pb-2">
            <div class="col-6">
              <label class="control-label required">Results
              </label>
              <small *ngIf="isAddingItem">
                <br /> To add the results, first save the questionnaire.</small>
              <small *ngIf="isEditingItem && !questions.length">
                <br /> To add the results, first add at least one question.</small>
            </div>
            <div class="col-6 text-right">
              <button type="button" class="btn btn-primary" (click)="addResult()" [hidden]="isAddingItem || isResultType1WnoDependency() || !questions.length">
                <i class="fa fa-plus mr-2" aria-hidden="true"></i> Add Result
              </button>
              <small *ngIf="isResultType1WnoDependency()">
                <i class="fa fa-info-circle mr-2 fa-1x" title="Add Result" aria-hidden="true"></i>ADD RESULT: The
                questionnaire result type is Message Alert With Redirect, therefore only one result is allowed</small>
            </div>
          </div>


          <div class="table-responsive shadow-lg mb-2" *ngIf="isEditingItem && questions.length">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Description</th>
                  <th class="text-center">Edit</th>
                  <th class="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of results">
                  <td>{{item.title}}</td>
                  <td>
                    <div innerHTML="{{item.description}}"></div>
                  </td>
                  <td (click)="editResult(item.id)" class="text-center">
                    <i class="fa fa-pencil primaryColor" aria-hidden="true"></i>
                  </td>
                  <td (click)="deleteResult(item)" class="text-center">
                    <i class="fa fa-trash primaryColor" aria-hidden="true"></i>
                  </td>
                </tr>
                <tr *ngIf="!questions.length">
                  <th colspan="4" class="text-center">No results added to the questionnaire</th>
                </tr>
              </tbody>
            </table>
          </div>

          <app-result-management [isAddingItem]="true" (finishedResultEdit)="finishedResultEdit($event, 'add')"
            [questionnaire]="item" *ngIf="isAddingResult"></app-result-management>
          <app-result-management (finishedResultEdit)="finishedResultEdit($event, 'edit')" [isEditingItem]="true"
            [questionnaire]="item" *ngIf="isEditingResult" [editableResult]="editableResult" [resultId]="editableResultId"></app-result-management>
        </div>

      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
      <button type="button" class="btn btn-outline-primary w-100" (click)="onBackClick()">
        <i class="fa fa-undo mr-2" aria-hidden="true"></i> BACK
      </button>
    </div>
  </div>
</div>
