import { FormControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateValidator {
  constructor() {}
  dateMoreThanNow(control: FormControl): any {
    const date = moment().format('YYYY-MM-DD');
    const controlValue = moment(control.value).format('YYYY-MM-DD');
    if (controlValue > date) {
      return {};
    }
    return {
      dates: 'Date should be after today'
    };
  }
}
