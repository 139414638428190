import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number, isCompleteWords = false, ellipsis = '...') {
    if (value.length <= limit) {
      return value;
    }

    let truncatedLength = limit - ellipsis.length;
    if (isCompleteWords) {
      truncatedLength = value.substr(0, truncatedLength).lastIndexOf(' ');
    }
    return `${value.substring(0, truncatedLength)}${ellipsis}`;
  }
}
