import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CompanyRegisterService } from '../services/company-register.service';
import { debounceTime, first, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RegisterCodeValidator {
  constructor(private companyRegisterService: CompanyRegisterService) {
  }

  validateCode(companyId: number): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (!control.valueChanges) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          debounceTime(1000),
          switchMap((value) =>
            this.companyRegisterService.checkRegisterCode(
              `${value}`,
              companyId ? String(companyId) : null,
            ),
          ),
          map((res) => (res.success && res.data && res.data.id ? { existed: true } : null)),
          first(),
        );
      }
    };
  }
}
