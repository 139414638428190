export class ApplicantData {
    personal_details = {
      firstname: '',
      surname: '',
      address: '',
      suburb: '',
      state: 'State*',
      post_code: '',
      phone: '',
      email: ''
    };
  }
  export class ChildData {
    personal_details = {
      firstname: '',
      surname: '',
      address: '',
      suburb: '',
      state: 'State*',
      post_code: ''
    };
    nationality = '';
    religion = '';
    entryYear = 'Select';
    gradeEntry = 'Select';
    currentSchoolAndGrade = '';
    prevSchool = '';
    studentBg = '';
    langSpoken = '';
  }

  export class SchoolReqData {
    location = '';
    distance = 'Select';
    educationLevel = 'Select';
    schoolType = 'Select';
    schoolReq = 'Select';
    otherSchoolReq = '';
    specialProvisions = '';
    schoolPreference = '';
    additionalSchoolReq = 'Select';
    otherAdditionalSchoolReq = '';
    terms_and_conditions = '';
  }
