<div *ngIf="carousels.length > 0" id="lifeiqCarousel" class="carousel slide carousel-fade" data-ride="carousel">
  <ol *ngIf="!landing" class="carousel-indicators">
    <li *ngFor="let carousel of carousels" [class]="!carousel.first ? 'carousel-menu' : 'carousel-menu active'" data-target="#lifeiqCarousel"
      data-slide-to="index"></li>
  </ol>
  <div class="carousel-inner">
    <div *ngFor="let carousel of carousels" (click)="landing ? '' : openCarousel(carousel)" [ngClass]="[!carousel.first ? 'carousel-item' : 'carousel-item active', landing ? '' : 'overlay']">
      <div class="img-carousel-div item">
        <img class="carousel-image" [src]="landing ? carousel.chosenImage : getCarouselImage(carousel.chosenImage)" alt="...">
      </div>
      <div *ngIf="carousel.title && carousel.description != ''" class="carousel-caption">
        <h1>{{ carousel.title | uppercase }}</h1>
        <p>{{ carousel.description }}</p>
      </div>
    </div>
  </div>
  <a *ngIf="!landing" class="carousel-control-prev" href="#lifeiqCarousel" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a *ngIf="!landing" class="carousel-control-next" href="#lifeiqCarousel" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
