import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../../services/util-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeatureService } from '../../../services/feature-service';
import { ConnectionService } from '../../../services/connection-service';
import { ILanguage } from '../../_models/language';
import { ILanguageResponse, LanguageService } from '../../../services/language-service';
import * as _ from 'lodash';

@Component({
  selector: 'app-language-management',
  templateUrl: './language-management.component.html',
  styleUrls: ['./language-management.component.scss'],
})
export class LanguageManagementComponent implements OnInit {
  itemType: string = 'language';
  items: ILanguage[];
  item: ILanguage;
  languages: ILanguage[];
  columns = [];
  itemForm: FormGroup;
  isInitialised: boolean;
  isPreventListDelete: boolean;
  isDisplayingList: boolean;
  isViewingItem: boolean;
  isAddingItem: boolean;
  canRespondToButtons: boolean;
  showLanguageExistedError: boolean = false;
  languageTranslationTextCodes = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private itemService: ConnectionService,
    private featureService: FeatureService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private modalService: NgbModal,
    private languageService: LanguageService,
  ) {
  }

  ngOnInit() {
    this.languageTranslationTextCodes = this.languageService.getLanguageTranslationTextCodes();
    this.getAllLanguages();
    this.activatedRoute.params.subscribe((route) => {
      this.analyseRoute(route);

      if (this.isDisplayingList) {
        this.getList();
      } else if (this.isViewingItem) {
        this.initialiseEditItem(route.id);
      } else if (this.isAddingItem) {
        this.initialiseAddItem();
      }
    });
  }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isViewingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isViewingItem = true;
    }
  }

  private getList() {
    this.languageService.getAvailableLanguages()
      .subscribe(data => {
        this.items = data.map(i => {
          return [i.id, i.name, i.code];
        });
        this.columns = ['id', 'name', 'code'];
        this.isInitialised = true;
        this.canRespondToButtons = true;
      });
  }

  private getAllLanguages() {
    this.languageService.getAllLanguages()
      .subscribe((data: ILanguage[]) => {
        this.languages = data;
        this.languages.unshift({ name: '-- Select a language --', code: '', translationTexts: null });
      });
  }

  private initialiseAddItem(): void {
    this.initialiseEditableFormGroup(null);
    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  private initialiseEditItem(id: number): void {
    this.languageService
      .getLanguageById(id)
      .subscribe((data) => {
        this.item = data;
        this.initialiseEditableFormGroup(data);
        this.isInitialised = true;
        this.canRespondToButtons = true;
      });
  }

  private initialiseLanguageTranslationTextForm(item) {
    const translationTexts = item && item.translationTexts || {};
    return _.reduce(this.languageTranslationTextCodes, (crr, c) => {
      crr[c] = [_.get(translationTexts, c, ''), [Validators.required, Validators.maxLength(64)]];
      return crr;
    }, {});
  }

  private initialiseEditableFormGroup(item) {
    let name = this.isViewingItem ? this.languages.find(o => o.code == item.code).name : '';
    let code = this.isViewingItem ? item.code : '';
    const languageTranslationTextForm = this.initialiseLanguageTranslationTextForm(item);
    this.itemForm = this.fb.group({
      nameControl: [name, Validators.required],
      codeControl: [code],
      ...languageTranslationTextForm,
    });
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach(field => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  private navigateToList() {
    this.router.navigate(['/cms/languages']);
  }

  private persistItem() {
    this.showLanguageExistedError = false;
    this.canRespondToButtons = false;
    let itemToPersist: ILanguage;
    const code = this.itemForm.get('codeControl').value;
    let langObj = this.languages.find(o => o.code === code);
    const translationTexts = _.reduce(this.languageTranslationTextCodes, (crr, c) => {
      crr[c] = this.itemForm.get(c).value;
      return crr;
    }, {});
    itemToPersist = {
      name: langObj.name,
      code: langObj.code,
      translationTexts: JSON.stringify(translationTexts),
    };

    if (this.isAddingItem) {
      this.addNewItem(itemToPersist);
      return;
    }
    this.updateItem(itemToPersist);
  }

  private addNewItem(itemToPersist: ILanguage) {
    this.languageService.addLanguage(itemToPersist)
      .subscribe((res: ILanguageResponse) => {
          this.canRespondToButtons = true;
          if (res.languageExistedError) {
            this.showLanguageExistedError = true;
          } else {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`Added new language - ${itemToPersist.name}`);
            this.navigateToList();
          }
        },
        error => {
          this.utilService.showToastError(`Error while adding item - ${error}`);
          this.canRespondToButtons = true;
        });
  }

  private updateItem(itemToPersist: ILanguage) {
    const selectedLanguageId = _.get(this.item, 'id');
    this.languageService.updateLanguageTranslation(selectedLanguageId, itemToPersist)
      .subscribe((res: ILanguageResponse) => {
          this.canRespondToButtons = true;
          if (res.languageExistedError) {
            this.showLanguageExistedError = true;
          } else {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`Language ${itemToPersist.name} updated`);
            this.navigateToList();
          }
        },
        error => {
          this.utilService.showToastError(`Error while update item - ${error}`);
          this.canRespondToButtons = true;
        });
  }

  onLanguageChange(code: any) {
    this.itemForm.get('codeControl').setValue(code);
  }

  onBackClick() {
    this.navigateToList();
  }

  getFormControlLabel(text) {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }

    this.persistItem();
  }
}
