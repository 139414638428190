<div class="widget-card">
  <div class="widget-card-header backPrimary">
    LifeAction suggestion
  </div>
  <div class="w-100 p-2 fs-80-rem">
    <div>
      <b>{{lifeAction.name}}</b>
    </div>
    <div class="badge badge-primary my-1">
      {{ lifeActionRecurrenceString | titlecase }}
    </div>
    <div class="mb-3 fs-80-rem">
      {{ lifeAction.description }}
    </div>

    <a
      class="btn btn-link w-100 widget-card-line fs-80-rem"
      (click)="onAddLifeActionClick()"
    >
      <i class="fa fa-plus-circle" aria-hidden="true"></i>
      Add
    </a>
  </div>
</div>
