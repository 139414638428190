import { AppRoutingModule } from './app-routing.module';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouteReuseStrategy } from '@angular/router';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { MatInputModule } from '@angular/material/input';
import { ClickOutsideModule } from 'ng-click-outside';
import { CookieService } from 'ngx-cookie-service';
import { NouisliderModule } from 'ng2-nouislider';
import { ToastrModule } from 'ngx-toastr';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxEditorModule } from 'ngx-editor';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { ToolkitModule } from './toolkit/toolkit.module';

// components
import { AppComponent } from './app.component';
import { FooterComponent } from '../components/footer/footer.component';
import { LandingPageComponent } from './landing/landing.component';
import { MarketingPageComponent } from './marketing/marketing.component';
import { MyHealthChartComponent } from './act/track-my-health-chart/track-my-health-chart.component';
import { AlertComponent } from '../components/alert-modal/alert-modal.component';
import { InfoAlertComponent } from '../components/info-alert/info-alert.component';
import { DialogComponent } from '../components/dialog-alert/dialog-alert.component';
import { ContactComponent } from '../components/footer/contact/contact.component';
import { AccountComponent } from './account/account.component';
import { PreferencesComponent } from '../components/preferences/preferences.component';
import { TutorialsComponent } from '../components/tutorials/tutorials.component';
import { WelcomeMessageComponent } from './welcome-message/welcome-message.component';
import { CarouselComponent } from './carousel/carousel.component';
import { BootstrapModalComponent } from '../components/bootstrap-modal/bootstrap-modal.component';
import { TrackMyHealthSetGoalComponent } from './act/track-my-health-set-goal/track-my-health-set-goal.component';
import { SettingsComponent } from './settings/settings.component';
import { SubscriptionComponent } from './settings/subscription/subscription.component';
import { NotificationsDropdownComponent } from './notifications-dropdown/notifications-dropdown.component';

// utils
import { CustomReuseStrategy } from './reuse-strategy';
import { CheckboxListValueAccessorDirective } from './checkbox-list-value-accessor';

import { ImageDialogComponent } from '../components/image-dialog/image-dialog.component';
import { DatasourceComponent } from './datasource/datasource.component';
import { FeatureTileComponent } from '../components/feature-tile/feature-tile.component';
import { TrackMyHealthIndicatorValuesComponent } from './act/track-my-health-indicator-values/track-my-health-indicator-values.component';
import { HoursMinutesEntryComponent } from '../components/hours-minutes-entry/hours-minutes-entry.component';

import { ProfileCreationBackgroundComponent } from '../components/profile-creation-background/profile-creation-background.component';
import { MobileAppDownloadCtaComponent } from '../components/mobile-app-download-cta/mobile-app-download-cta.component';
import { httpInterceptorProviders } from '../http-interceptors';
import { SharedModule } from './_shared/shared.module';
import { WidgetsComponent } from './widgets/widgets.component';
import { DownloadAppLinksComponent } from './widgets/download-app-links/download-app-links.component';
import { MyRewardsWidgetComponent } from './widgets/my-rewards-widget/my-rewards-widget.component';
import { CompetitionWidgetComponent } from './widgets/competition-widget/competition-widget.component';
import { LastBadgeEarnedComponent } from './widgets/last-badge-earned/last-badge-earned.component';
import { GroupsWidgetComponent } from './widgets/groups-widget/groups-widget.component';
import { LifeActionWidgetComponent } from './widgets/life-action-widget/life-action-widget.component';
import { LeaderboardWidgetComponent } from './widgets/leaderboard-widget/leaderboard-widget.component';
import { MyHealthWidgetComponent } from './widgets/my-health-widget/my-health-widget.component';
import { AuthModule } from './auth/auth.module';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { ProfileDropdownComponent } from './profile-dropdown/profile-dropdown.component';
import { EapBookingComponent } from './widgets/eap-booking-widget/eap-booking-widget.component';
import { HcfWidgetComponent } from './widgets/hcf-widget/hcf-widget.component';
import { NasdaqWidgetComponent } from './widgets/nasdaq-widget/nasdaq-widget.component';
import { TeamLeaveComponent } from './team-leave/team-leave.component';
import { PeerSupportWidgetComponent } from './widgets/peer-support-widget/peer-support-widget.component';
import { CompanyRegisterInfoComponent } from './company-register-info/company-register-info.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { WidgetPreviewComponent } from './company-register-info/preview/widget-preview/widget-preview.component';
import { NavigationPreviewComponent } from './company-register-info/preview/navigation-preview/navigation-preview.component';
import { HomePreviewComponent } from './company-register-info/preview/home-preview/home-preview.component';
import { LogoPreviewComponent } from './company-register-info/preview/logo-preview/logo-preview.component';
import { ArticlePreviewComponent } from './company-register-info/preview/article-preview/article-preview.component';
import { DeleteUserModalComponent } from '../components/delete-user-modal/delete-user-modal.component';
import { InsightsNavbarComponent } from '../components/navbar-insights/navbar-insights.component';
import { QRComponent } from './qr/qr.component';
import { EapBookingDetailComponent } from './toolkit/eap-booking-detail/eap-booking-detail.component';
import { ContentListComponent } from './_shared/components/content-list/content-list.component';
import { PartnerDetailComponent } from './toolkit/partner-detail/partner-detail.component';
import { LifeActionCardComponent } from './act/life-action/life-action.component';
import { ActModule } from './act/act.module';
import { ProgramComponent } from './act/program/program.component';
import { BadgesComponent } from './reward/badges/badges.component';
import { RewardModule } from './reward/reward.module';
import { QuestionnaireComponent } from './_shared/components/questionnaires/questionnaire/questionnaire.component';
import { GroupsComponent } from './share/groups/groups.component';
import { ShareModule } from './share/share.module';
import { CmsModule } from './cms/cms.module';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    AlertComponent,
    InfoAlertComponent,
    DialogComponent,
    LandingPageComponent,
    MarketingPageComponent,
    QRComponent,
    ContactComponent,
    AccountComponent,
    MyHealthChartComponent,
    PreferencesComponent,
    TutorialsComponent,
    WelcomeMessageComponent,
    CarouselComponent,
    CheckboxListValueAccessorDirective,
    ImageDialogComponent,
    DatasourceComponent,
    BootstrapModalComponent,
    TrackMyHealthSetGoalComponent,
    FeatureTileComponent,
    SettingsComponent,
    SubscriptionComponent,
    TrackMyHealthIndicatorValuesComponent,
    HoursMinutesEntryComponent,
    ProfileCreationBackgroundComponent,
    MobileAppDownloadCtaComponent,
    NotificationsDropdownComponent,
    ProfileDropdownComponent,
    WidgetsComponent,
    DownloadAppLinksComponent,
    MyRewardsWidgetComponent,
    CompetitionWidgetComponent,
    LastBadgeEarnedComponent,
    GroupsWidgetComponent,
    LifeActionWidgetComponent,
    LeaderboardWidgetComponent,
    MyHealthWidgetComponent,
    NavbarComponent,
    PeerSupportWidgetComponent,
    EapBookingComponent,
    HcfWidgetComponent,
    NasdaqWidgetComponent,
    TeamLeaveComponent,
    CompanyRegisterInfoComponent,
    WidgetPreviewComponent,
    NavigationPreviewComponent,
    HomePreviewComponent,
    LogoPreviewComponent,
    ArticlePreviewComponent,
    DeleteUserModalComponent,
    InsightsNavbarComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFontAwesomeModule,
    SharedModule,
    MatDialogModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    RoundProgressModule,
    MatInputModule,
    MatSliderModule,
    ClickOutsideModule,
    NouisliderModule,
    NgxEditorModule,
    TooltipModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      easing: 'ease-out',
      easeTime: 300,
      tapToDismiss: false,
      closeButton: true,
      timeOut: 6000,
      extendedTimeOut: 1000,
      maxOpened: 5,
    }),
    TextareaAutosizeModule,
    AuthModule,
    AppRoutingModule,
    MatDividerModule,
    ColorPickerModule,
    MatSelectModule,
    ToolkitModule,
    ActModule,
    RewardModule,
    ShareModule,
    CmsModule
  ],
  providers: [
    Title,
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    CookieService,
    NgbActiveModal,
    httpInterceptorProviders,
  ],
  entryComponents: [
    AccountComponent,
    AlertComponent,
    InfoAlertComponent,
    DialogComponent,
    ContactComponent,
    MyHealthChartComponent,
    PreferencesComponent,
    TutorialsComponent,
    WelcomeMessageComponent,
    ImageDialogComponent,
    DatasourceComponent,
    BootstrapModalComponent,
    TrackMyHealthSetGoalComponent,
    CarouselComponent,
    TrackMyHealthIndicatorValuesComponent,
    MobileAppDownloadCtaComponent,
    TeamLeaveComponent,
    HomePreviewComponent,
    DeleteUserModalComponent,
    EapBookingDetailComponent,
    ContentListComponent,
    PartnerDetailComponent,
    LifeActionCardComponent,
    ProgramComponent,
    BadgesComponent,
    QuestionnaireComponent,
    GroupsComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
