import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartingService } from '../../../services/charting.service';

@Component({
  selector: 'app-my-health-chart',
  styleUrls: ['./track-my-health-chart.component.scss'],
  templateUrl: './track-my-health-chart.component.html',
})
export class MyHealthChartComponent implements OnInit {
  @Input() data;
  hasData = false;
  noData: any;
  isInitialised = false;

  constructor(
    private activeModal: NgbActiveModal,
    public chartingService: ChartingService,
  ) {
  }

  ngOnInit() {
    this.hasData = this.isIndicatorTrackerValues();

    if (this.hasData) {
      this.setDataStyle();
    } else {
      this.setNoDataStyle();
    }

    this.isInitialised = true;
  }

  isIndicatorTrackerValues() {
    return this.data.trackers.length;
  }

  private setDataStyle() {
    const style = document.createElement('style');
    style.innerHTML += '.chart{ display: inline-block !important;}';
    document.getElementsByTagName('head')[0].appendChild(style);
  }

  private setNoDataStyle() {
    const style = document.createElement('style');
    style.innerHTML += '.chart { display: none !important;}';
    document.getElementsByTagName('head')[0].appendChild(style);

    this.noData = 'No data recorded. Start tracking now!';
  }

  close() {
    this.activeModal.dismiss();
  }
}
