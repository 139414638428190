import { CategoryService } from './category-service';
import { FeatureService } from './feature-service';
import { CATEGORIES } from '../app/_shared/enums';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IBreadcrumb } from '../app/_models/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  // TODO: Refactor so that breadcrumbs observable of IBreadcrumb[]
  // is HERE instead of in app.component.ts augmented by this observable
  // in various xxx-detail.component.ts that call setBreadcrumbExtraName()
  private breadcrumbExtraName = new BehaviorSubject<any>([]);
  breadcrumbExtraName$ = this.breadcrumbExtraName.asObservable();

  constructor(
    private featureService: FeatureService,
    private categoryService: CategoryService
  ) {}

  getBreadcrumbExtraName(): Observable<string> {
    return this.breadcrumbExtraName$;
  }

  setBreadcrumbExtraName(breadcrumbs: string) {
    this.breadcrumbExtraName.next(breadcrumbs);
  }

  clearBreadcrumbExtraName() {
    this.breadcrumbExtraName.next('');
  }

  setIsSelectableSegment(breadcrumbs: IBreadcrumb[]) {
    for (let i = 0; i < breadcrumbs.length; i++) {
      const breadcrumb = breadcrumbs[i];

      breadcrumb.isSelectableSegment = i < breadcrumbs.length - 1;
    }
  }

  initialiseParams(breadcrumbs: IBreadcrumb[]): any[] {
    const params = [];
    if (this.isPenultimateBreadcrumbContainParameter(breadcrumbs)) {
      breadcrumbs[breadcrumbs.length - 1].params.map(param => {
        params.push(param);
      });
    }

    return params;
  }

  isContentItemsRoute(route: ActivatedRoute): boolean {
    return (route.routeConfig.path === 'content/:featureId/:categoryId')
      && !route.firstChild;
  }

  isContentItemRoute(route: ActivatedRoute): boolean {
    return (route.routeConfig.path === 'content/:featureId/:categoryId')
      && route.firstChild != null;
  }

  getContentItemsBreadcrumbs(route: ActivatedRoute): IBreadcrumb[] {
    let featureId: number;
    let featureName: string;
    let categoryId: number;
    let url: string;
    let label: string;

    route.url.subscribe(segments => {
      url = 'discover/' + segments.map(item => item.path).join('/');
    });

    route.paramMap.subscribe(params => {
      featureId = +params.get('featureId');
      categoryId = +params.get('categoryId');
    });

    featureName = this.featureService.featureName(featureId);

    if (categoryId === CATEGORIES.ALL) {
      label = `All ${featureName}`;
    } else {
      label = `${featureName} - ${this.categoryService.categoryName(categoryId)}`;
    }

    const breadcrumb: IBreadcrumb = {
      label,
      url,
      isSelectableSegment: false,
      params: []
    };

    return [breadcrumb];
  }

  getContentItemBreadcrumbs(route: ActivatedRoute): IBreadcrumb[] {
    const emptyBreadcrumb: IBreadcrumb = {
      label: null,
      url: null,
      isSelectableSegment: false,
      params: []
    };
    const itemsBreadCrumbs = [...this.getContentItemsBreadcrumbs(route), emptyBreadcrumb];
    itemsBreadCrumbs[0].isSelectableSegment = true;

    return itemsBreadCrumbs;
  }

  isNullLabelSegmentPath(segmentPath: string): boolean {
    return (segmentPath === 'article'
      || segmentPath === 'recipe'
      || segmentPath === 'video'
      || /^\d+$/.test(segmentPath));
  }

  isPathValueParameter(route: ActivatedRoute): boolean {
    return route.routeConfig && route.routeConfig.path.startsWith(':');
  }

  isPathContainValueParameter(route: ActivatedRoute): boolean {
    return route.routeConfig && route.routeConfig.path.indexOf(':') > -1;
  }

  isRootRoute(route: ActivatedRoute): boolean {
    return !route.routeConfig;
  }

  isLazilyLoadableRoute(route: ActivatedRoute): boolean {
    return route.firstChild
      && route.firstChild.firstChild
      && route.firstChild.routeConfig
      && route.firstChild.routeConfig.loadChildren
      && route.firstChild.routeConfig.loadChildren.toString().indexOf('#') > -1;
  }

  private isPenultimateBreadcrumbContainParameter(breadcrumbs: IBreadcrumb[]): boolean {
    return breadcrumbs.length
      && breadcrumbs[breadcrumbs.length - 1].params
      && breadcrumbs[breadcrumbs.length - 1].params.length > 0;
    }
}
