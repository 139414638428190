import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user-service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupService } from '../../../../services/group-service';
import { IGroup } from '../../../_models/group';
import { FormControl, Validators } from '@angular/forms';
import { UtilService } from '../../../../services/util-service';
import { Router } from '@angular/router';

const PATH_POSTS = 'groups/posts/';

@Component({
  selector: 'app-share-content',
  templateUrl: './share-content.component.html',
  styleUrls: ['./share-content.component.scss'],
})
export class ShareContentComponent implements OnInit {
  @Input() contentType: string;
  @Input() contentImage: string;
  @Input() contentTitle: string;
  @Input() contentId: string;
  @Input() contentDescription: string;
  @Input() contentUrl: string;
  preview;
  postPlaceholder = 'Start a conversation...';
  postImagePath = PATH_POSTS;

  groupControl = new FormControl('', Validators.required);
  actionButtonText = 'Share';
  memberGroups: IGroup[];
  hasNoGroups;
  disableSharingButtons;

  constructor(private router: Router, private userService: UserService, private activeModal: NgbActiveModal, private groupService: GroupService, private utilService: UtilService) {
  }

  ngOnInit() {
    this.getUserGroups();
    this.preview = {
      url: this.contentUrl,
      description: this.contentDescription,
      title: this.contentTitle,
      image: this.getImagePath(),
    };
  }

  getImagePath(): string {
    if (this.contentType === 'article') {
      return this.userService.getArticleImage(this.contentImage);
    }

    if (this.contentType === 'recipe') {
      return this.userService.getRecipeImage(this.contentImage);
    }

    if (this.contentType === 'team') {
      return this.contentImage;
    }

    return this.contentImage;
  }

  getUserGroups() {
    this.groupService.getGroupsWhereMember().subscribe(res => {
      this.memberGroups = res;
      if (this.memberGroups.length) {
        this.groupControl.setValue(this.memberGroups[0].name);

      } else {
        this.hasNoGroups = true;
        this.disableSharingButtons = true;
      }
    });
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  onShareClick(data) {
    this.activeModal.dismiss();
    data.companyId = this.userService.company.id;
    data.preview.appParams = { page: 'ContentDetailPage', type: this.contentType, id: this.contentId };

    const selectedGroup = this.memberGroups.find(group => {
      return group.name === this.groupControl.value;
    });
    this.groupService.createPost(selectedGroup.id, data)
      .subscribe(() => {
          this.utilService.showToastSuccess(`Successfully created new post in ${this.groupControl.value}`);
        }, () => {
          this.utilService.showToastError(`Error creating post in ${this.groupControl.value}`);
        },
      );
  }

  goToGroups() {
    this.activeModal.close();
    this.router.navigate(['/share/groups']);
  }
}
