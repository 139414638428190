import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FeatureService } from './feature-service';
import { DateService } from './date.service';
import { UtilService } from './util-service';
import { IQuestionnaire } from '../app/_models/questionnaire';
import { IQuestion } from '../app/_models/question';
import { IAnswer } from '../app/_models/answer';
import { IResult } from '../app/_models/result';
import { IIdTypePair } from '../app/_models/id-type-pair';
import { FEATURES } from '../app/_shared/enums';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {
  questionnaireResults;
  fromSubmit: Boolean = false;

  private quesionnairesChangedSubject = new BehaviorSubject<Date>(new Date());
  questionnairesChanged$: Observable<Date> = this.quesionnairesChangedSubject.asObservable();

  constructor(
    private http: HttpClient,
    private dateService: DateService,
    private utilService: UtilService,
    private feature: FeatureService,
  ) { }

  private MessageAlertWithRedirectResultTypeId = 1;

  allStaticResultTypes(): IIdTypePair[] {
    return [
      { id: this.MessageAlertWithRedirectResultTypeId, type: 'Message Alert with Redirect' },
      { id: 2, type: 'Message Alert by Points' },
      // { id: 3, type: 'Card per Recommended Values' }, TODO: Revisit how this works and change the calculus in db to something that
      // admins can use in the cms and devs can read
      { id: 4, type: 'Unique Text' }
    ];
  }

  private setAdditionalProperties(questionnaire: IQuestionnaire) {
    this.setIsAnswered(questionnaire);
    this.setUrlSegments(questionnaire);
  }

  private setIsAnswered(questionnaire: IQuestionnaire) {
    if (!questionnaire.liqUserAnswers) {
      questionnaire.isAnswered = false;
      return;
    }

    const answer = questionnaire.liqUserAnswers.find(a => a.liqQuestionnaireId === questionnaire.id);

    questionnaire.isAnswered = !!answer;
    questionnaire.canSeePastResults = questionnaire.isAnswered && questionnaire.showHistory;
    questionnaire.canTakeTheQuiz = !questionnaire.isAnswered || questionnaire.multipleAnswers;
  }

  private setUrlSegments(questionnaire: IQuestionnaire) {
    const input = `${questionnaire.name} ${questionnaire.id}`;
    const kebabCased = this.utilService.toKebabCase(input.replace(/\?/g, ''));
    const penultimate = (questionnaire.featureId === FEATURES.CHANCE_TO_WIN)
      ? 'competition'
      : 'act/healthriskassessments';

    questionnaire.urlPath = `/${penultimate}/${kebabCased}`;
  }

  getQuestionnaires(featureId) {
    const filterByCompany = 0;
    const url = `/questionnaires/all/${featureId}/${filterByCompany}`;

    return this.http.get<any>(url)
      .pipe(tap((questionnaires: IQuestionnaire[]) => {
        questionnaires.forEach(q => this.setAdditionalProperties(q));
      }));
  }

  getAllQuestionnaires() {
    const url = '/questionnaires/all/0/0';

    return this.http.get<any>(url);
  }

  getAllcompetitions() {
    const featureId = this.feature.ChanceToWinFeatureId;
    const allCompaniesId = 0;
    const url = `/questionnaires/all/${featureId}/${allCompaniesId}`;

    return this.http.get<IQuestionnaire[]>(url)
      .pipe(tap((questionnaires: IQuestionnaire[]) => {
        questionnaires.forEach(q => this.setAdditionalProperties(q));
      }));
  }

  getQuestionnaireById(id) {
    const url = `/questionnaires/${id}`;
    return this.http.get<any>(url);
  }

  getQuestionnaireDetails(id) {
    const url = `/questionnaires/details/${id}`;
    return this.http.get<any>(url);
  }

  getNewQuestionnaire(): IQuestionnaire {
    return {
      id: 0,
      name: '',
      active: false,
      description: null,
      summary: null,
      startTime: this.dateService.getDate(),
      endTime: new Date(2030, 11, 31),
      featureId: null,
      companyId: null,
      multipleAnswers: false,
      showHistory: false,
      liqResultTypeId: this.MessageAlertWithRedirectResultTypeId,
      liqUserAnswers: [],
      banner: null,
      widgetImage: null,
      termsConditions: null,
      excludedCompanyIds: []
    };
  }

  getQuestionnaireResult(id) {
    const url = `/questionnaires/result/${id}`;
    return this.http.get<any>(url);
  }

  saveUserAnswers(answers) {
    const todaysDate = this.dateService.formatYYYY_MM_DD();
    const body = {
      answers: answers,
      date: todaysDate
    };
    const url = `/liq-user-answers/bulk`;

    return this.http.post<any>(url, body).pipe(tap(data => {
      this.quesionnairesChangedSubject.next(new Date());

      if (data.achievedBadges && data.achievedBadges.length) {
        data.achievedBadges.forEach((b) => {
          this.utilService.badgeAlert([b]);
        });
      }
    }));
  }

  getResultUrl(questionnaireUrl: string): string {
    return `${questionnaireUrl}/result`;
  }

  addQuestionnaire(questionnaire: IQuestionnaire) {
    const body = {
      name: questionnaire.name,
      banner: questionnaire.banner,
      widgetImage: questionnaire.widgetImage,
      description: questionnaire.description,
      summary: questionnaire.summary,
      termConditions: questionnaire.termsConditions,
      startTime: questionnaire.startTime,
      endTime: questionnaire.endTime,
      active: questionnaire.active,
      featureId: questionnaire.featureId,
      companyId: questionnaire.companyId,
      multipleAnswers: questionnaire.multipleAnswers,
      showHistory: questionnaire.showHistory,
      liqResultTypeId: questionnaire.liqResultTypeId,
      excludedCompanyIds: questionnaire.excludedCompanyIds
    };

    const url = `/cms/questionnaires`;

    return this.http.post<any>(url, body);
  }

  updateQuestionnaire(questionnaire: IQuestionnaire) {
    const body = {
      name: questionnaire.name,
      banner: questionnaire.banner,
      widgetImage: questionnaire.widgetImage,
      description: questionnaire.description,
      summary: questionnaire.summary,
      termsConditions: questionnaire.termsConditions,
      startTime: questionnaire.startTime,
      endTime: questionnaire.endTime,
      active: questionnaire.active,
      featureId: questionnaire.featureId,
      companyId: questionnaire.companyId,
      multipleAnswers: questionnaire.multipleAnswers,
      showHistory: questionnaire.showHistory,
      liqResultTypeId: questionnaire.liqResultTypeId,
      excludedCompanyIds: questionnaire.excludedCompanyIds
    };

    const url = `/cms/questionnaires/${questionnaire.id}`;

    return this.http.put<any>(url, body);
  }

  deleteQuestionnaire(id: number) {
    const url = `/cms/questionnaires/${id}`;

    return this.http.delete<any>(url);
  }

  updateQuestion(question) {
    const url = `/cms/liq-questions/${question.id}`;
    return this.http.put<any>(url, question);
  }

  deleteQuestion(questionId) {
    const url = `/cms/liq-questions/${questionId}`;
    return this.http.delete<any>(url);
  }

  getAnswersByQuestionId(questionId) {
    const url = `/cms/questionnaires/answers/${questionId}`;
    return this.http.get<any>(url);
  }

  getNewQuestion(questionnaireId: number, questionnaireQttAnswers: number = 0): IQuestion {
    return {
      id: 0,
      question: '',
      required: false,
      unknown: null,
      liqQuestionnaireId: questionnaireId,
      answerType: '',
      order: questionnaireQttAnswers + 1,
      answersDependency: null,
      questionDependencyId: null,
      liqAnswers: [],
      displayDependencyQuestion: null,
      showDependencyQuestion: false,
      categoryId: null,
    };
  }

  addQuestion(answer) {
    const url = `/cms/liq-questions`;
    return this.http.post<any>(url, answer);
  }

  addAnswer(question) {
    const url = `/cms/liq-answers`;
    return this.http.post<any>(url, question);
  }

  updateAnswer(answer) {
    const url = `/cms/liq-answers/${answer.id}`;
    return this.http.put<any>(url, answer);
  }

  updateAnswersBatch(answers) {
    const url = `/cms/questionnaires/answers-batch`;
    return this.http.put<any>(url, answers);
  }

  deleteAnswer(answerId) {
    const url = `/cms/liq-answers/${answerId}`;
    return this.http.delete<any>(url);
  }

  getNewAnswer(questionId: number): IAnswer {
    return {
      label: '',
      value: null,
      value2: null,
      points: null,
      icon: null,
      operand: null,
      liqQuestionId: questionId,
      answersDependency: null,
      questionDependencyId: null
    };
  }

  addResult(result) {
    const url = `/cms/liq-results`;
    return this.http.post<any>(url, result);
  }

  updateResult(result) {
    const url = `/cms/liq-results/${result.id}`;
    return this.http.put<any>(url, result);
  }

  deleteResult(resultId) {
    const url = `/cms/liq-results/${resultId}`;
    return this.http.delete<any>(url);
  }

  getNewResult(questionnaire: IQuestionnaire): IResult {
    let calculus = null;
    let questions = null;
    if (questionnaire.liqResultTypeId === 2 || questionnaire.liqResultTypeId === 4) { calculus = 'points'; }
    if (questionnaire.liqResultTypeId === 3) { calculus = 'value'; }
    if (questionnaire.liqResultTypeId > 1) {
      questions = questionnaire.liqQuestions.map(q => q.id);
      if (questions.length > 1) { questions = questions.toString(); }
    }

    return {
      title: '',
      liqQuestionnaireId: questionnaire.id,
      answerDependencyId: null,
      calculus: calculus,
      questions: questions
    };
  }

  getResultSets(questionnaireId, doCheckBadges?) {
    let url;
    if (doCheckBadges) {
      url = `/liq-results/sets-and-badges/${questionnaireId}`;
    } else {
      url = `/liq-results/sets/${questionnaireId}`;

    }
    return this.http.get<any>(url).pipe(tap(data => {

      if (data.achievedBadges && data.achievedBadges.length) {
        data.achievedBadges.forEach((b) => {
          this.utilService.badgeAlert([b]);
        });
      }
    }));
  }
}
