export enum QUESTIONNAIRE_RESULT_TYPES {
  'MESSAGE ALERT W REDIRECT' = 1,
  'MESSAGE ALERT BY POINTS' = 2,
  'CARD PER RECOMMENDED VALUES' = 3,
  'UNIQUE TEXT' = 4,
  'TEXT BY POINTS' = 5
}

export enum FEATURES {
  'HOME' = 1,
  'DISCOVER' = 2,
  'ACT' = 3,
  'SHARE' = 4,
  'REWARDS' = 5,
  'ARTICLES' = 7,
  'RECIPES' = 8,
  'VIDEOS' = 9,
  'LIFE_ACTIONS' = 12,
  'TRACK_MY_HEALTH' = 13,
  'DEVICES' = 14,
  'LEADERBOARD' = 15,
  'BADGES' = 16,
  'FRIENDS' = 17,
  'GROUPS' = 19,
  'MY_PROFILE' = 27,
  'PARTNERS' = 52,
  'CHANCE_TO_WIN' = 53,
  'TOOLKIT' = 56,
  'INFO_PACKS' = 58,
  'SUPPORT_AT_COMPANY' = 60,
  'LOCATOR' = 61,
  'MY_REWARDS' = 66,
  'HEALTH_RISK_ASSESSMENTS' = 67,
  'RESOURCES' = 68,
  'PROGRAMS' = 80,
  'PARENT_PLANNER' = 81,
  'STRIPE' = 83,
  'HEALTH' = 85,
  'HAPPINESS' = 86,
  'FINANCIAL_SECURITY' = 87,
  'SHARE_TO_GROUP' = 88,
  'EAP_BOOKING' = 89,
  'CHALLENGES' = 93,
  'TEAMS' = 94,
  'CREATE_NEW_GROUP' = 95,
  'PEER_SUPPORT' = 96,
  'SHOW_LANGUAGE_OPTION' = 97,
}

export enum COMPANIES_CMS_FEATURE_TYPES {
  'FUNCTIONAL' = 0,
  'HOME' = FEATURES.HOME,
  'DISCOVER' = FEATURES.DISCOVER,
  'SHARE' = FEATURES.SHARE,
  'ACT' = FEATURES.ACT,
  'REWARDS' = FEATURES.REWARDS,
  'TOOLKIT' = FEATURES.TOOLKIT
}

export enum BADGE_TYPES {
  TRACKED_INDICATOR = 'trackedIndicator',
  ACHIEVED_INDICATOR = 'achievedIndicator',
  INDICATOR_DRIVER = 'indicatorDriver',
  ARTICLE = 'article',
  RECIPE = 'recipe',
  VIDEO = 'video',
  FEATURE = 'feature',
  QUESTIONNAIRE = 'questionnaire',
  QUESTIONNAIRE_RESULT = 'questionnaireResult',
  PROGRAM = 'program'
}

export enum CONTENT_TYPES {
  ARTICLE = 'article',
  RECIPE = 'recipe',
  VIDEO = 'video',
  FEATURE = 'feature',
  QUESTIONNAIRE = 'questionnaire',
  QUESTIONNAIRE_RESULT = 'questionnaireResult',
  PROGRAM = 'program'
}

export enum AWS_PATHS {
  PROFILE_IMAGES = 'profile_images/'
}

export enum CONTENT_TYPE_IDS {
  ARTICLE_ID = 1,
  RECIPE_ID = 2,
  VIDEO_ID = 3
}

export enum FRIEND_STATUSES {
  PENDING = 0,
  CONFIRMED = 1,
  DECLINED = 3
}

export enum SYSTEM_ROLES {
  USER = 1,
  COORDINATOR = 4,
  SUPER_ADMIN = 5,
  ADMIN = 6,
}

export enum USER_STATUSES {
  GUEST = 1,
  USER = 2,
  STATUS4 = 4
}

export const MOBILE_IMAGE_MAX_SIZE: number = 768;

export enum LANGUAGES {
  en = 'en'
}

export enum CATEGORIES {
  'ALL' = 0,
  'SOUPS_SALADS_AND_SIDES' = 89,
  'VEGAN_AND_VEGETARIAN' = 90,
  'MEAT_AND_FISH' = 91,
  'SMOOTHIES_AND_DESSERTS' = 92,
  'FITNESS' = 93,
  'FUEL' = 94,
  'STAYING_HEALTHY' = 95,
  'FINDING_HAPPY' = 96,
  'BUILDING_RESILIENCE' = 97,
  'RELATIONSHIPS' = 98,
  'FINANCIAL_PLANNING' = 99,
  'CAREER_FOCUS' = 100,
  'WORK_LIFE' = 101,
  'PLANNING_AND_PREGNANCY' = 102,
  'BABIES_AND_KIDS' = 103,
  'BACK_TO_WORK' = 104,
  'FEEL' = 105,
  'NOURISH' = 107,
  'EXERCISE' = 108,
  'MINDSET' = 109,
  'HEALTH' = 110,
  'NUTRITION' = 111
}

export enum CMS_COMPANIES_MANAGEMENT_FUNCTIONAL_FEATURES_HCF_CHECKBOX_ID {
  HCF = 91 // This id and Features table name column HCF feature id same, if not same while user register HCF extra registration fields cannot display
}

export enum CMS_COMPANIES_MANAGEMENT_FUNCTIONAL_FEATURES_HCFWIDGET_CHECKBOX_ID {
  'HCF Widget' = 92 // This id and Features table name column HCF Widget feature id same, if not same Home page HCF Widget will not work proper
}

export enum PROGRAMS {
  SLEEP_PROGRAM_ID = 6
}

export enum MENU_TYPES {
  TEXT = 'text',
  FEATURE = 'feature',
  CATEGORY = 'category'
}

export enum INCLUDE_EXCLUDE_SPECIAL_OPTION {
  ALL = 0,
}
