<div *ngIf="is1700" class="p-3 ml-3 mr-3 content-background">
  <h1>Privacy Policy</h1>
  <p>Effective date: May 16, 2018</p>
  <p>Altius Group Limited and its wholly-owned subsidiary LifeIQ Pty Ltd ("us", "we", or "our")
    operates the <a href="">{{url}}</a> website and the {{brand}} mobile application
    (collectively the "{{brand}} Service").</p>
  <p>We are committed to protecting your rights and your privacy. This Privacy Policy (the "Policy")
    explains what data We collect about you and how We store, analyze and share the data We collect
    about you through the {{brand}} Service ({{url}}) and the
    {{brand}} mobile application. This Policy also informs you of Our policies regarding the
    collection, use, and disclosure of personal data when you use Our {{brand}} Service and
    the choices you have associated with that data.</p>
  <p>Please carefully read through this Policy. To join and enjoy the {{brand}} Service
    and Program benefits, you are required to accept all the terms in this Policy. If you choose
    to accept these terms and enroll in the {{brand}} Program, you will become a Member
    of the Program.</p>
  <p>You may withdraw your consent to this Policy or its updated terms at any time by contacting
    Us and notifying Us that you wish to terminate your Membership.</p>
  <p>We use your data to provide and improve the {{brand}} Service. By using the {{brand}}
    Service, you agree to the collection and use of information in accordance with this policy.
    Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the
    same meanings as in Our Terms and Conditions.</p>
  <h4>Definitions</h4>
  <ul>
    <li>
      <p>
        <strong> {{brand}} Service</strong>
      </p>
      <p>{{brand}} Service means the
        <a href="">{{url}}</a> website and the {{brand}} mobile application operated
        by Altius Group Limited.</p>
    </li>
    <li>
      <p>
        <strong>Personal Data</strong>
      </p>
      <p>Personal Data means data about a living individual who can be identified from those data (or from those and
        other information
        either in our possession or likely to come into our possession).</p>
    </li>
    <li>
      <p>
        <strong>Usage Data</strong>
      </p>
      <p>Usage Data is data collected automatically either generated by the use of the {{brand}}
        Service or from the {{brand}} Service infrastructure
        itself (for example, the duration of a page visit).</p>
    </li>
    <li>
      <p>
        <strong>Cookies</strong>
      </p>
      <p>Cookies are small pieces of data stored on your device (computer or mobile device).</p>
    </li>
    <li>
      <p>
        <strong>Data Controller</strong>
      </p>
      <p>Data Controller means the natural or legal person who (either alone or jointly or in common with other persons)
        determines
        the purposes for which and the manner in which any personal information are, or are to be, processed.</p>
      <p>For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.</p>
    </li>
    <li>
      <p>
        <strong>Data Processors (or Service Providers)</strong>
      </p>
      <p>Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the
        Data
        Controller.
      </p>
      <p>We may use the services of various Service Providers in order to process your data more effectively.</p>
    </li>
    <li>
      <p>
        <strong>Data Subject (or User)</strong>
      </p>
      <p>Data Subject is any living individual who is using Our {{brand}} Service and is the subject of Personal Data.
      </p>
    </li>
  </ul>

  <h4>Information Collection And Use</h4>
  <p>We collect several different types of information for various purposes to provide and improve our
    Service to you, your Employer and selected Third Party service providers they engage with to deliver
    additional services and benefits to you. Information from which you may be personally identifiable
    is only disclosed to selected third parties in accordance with this Privacy Policy.</p>
  <h5>Personal Data</h5>
  <p>Personal Information, as defined in the privacy legislation, is information, or an opinion, in any
    form, and whether true or not, about an individual whose identity is apparent or can reasonably be
    ascertained from the information or opinion. We will only use the information that We collect about
    you lawfully. Generally, We will only collect Personal Information where the information is necessary
    for Us to perform one or more of Our functions or activities. In this context, "collect" means gather,
    acquire or obtain by any means, information in circumstances where the individual is identifiable or
    identified.</p>
  <p>While using Our {{brand}} Service, We collect personal information from you. We verify your email
    address (as per your application form), when you register to become a member of this website.</p>
  <p>By opting in at the registration process through Our website or mobile application, you are
    providing your personal and any other information to Us and by virtue of this you consent to the
    collection, use and disclosure of that information in accordance with Our Terms and Conditions
    and this Privacy Policy. Personal Information includes information about an individual who is
    reasonably identifiable.</p>

  <h4>Types of Data Collected</h4>
  <p>We collect several different types of information for various purposes to provide and improve
    Our {{brand}} Service to you.</p>
  <h5>Personal Data</h5>
  <p>While using Our {{brand}} Service, We may ask you to provide us with certain personally
    identifiable information that can be used to contact or identify you ("Personal Data").
    Personally identifiable information may include, but is not limited to:</p>
  <ul>
    <li>Name</li>
    <li>Gender, Date of Birth and age</li>
    <li>Contact details such as mobile phone and email address used to sign in</li>
    <li>Postcode </li>
    <li>State </li>
    <li>Work company name</li>
    <li>Information about your participation and performance in challenges</li>
    <li>Rewards you may earn through Our program</li>
    <li>Comments and contributions you may make on the web based platform or mobile application</li>
    and
    <li>Additional information you may provide through the submission of any queries or requests to us</li>
  </ul>

  <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other
    information
    that may be of interest to you.</p>
  <p>You may opt out of receiving any, or all, of these communications from Us by following the
    unsubscribe link in any email marketing communications We send you; or by contacting Us using
    the contact details below and informing Us that you wish to opt-out.</p>

  <h4>How Personal Information Is Collected And Held</h4>
  <p>We collect Personal Information in a number of ways, including:</p>
  <ul>
    <li>Through Our websites;</li>
    <li>Through Our mobile application</li>
    <li>In person;</li>
    <li>From third party organisations, including Private Health Insurance Companies; </li>
    <li>Agents, employees, subcontractors, suppliers and service providers;</li>
  </ul>
  <p>Please note:</p>
  <ul>
    <li>Before We can provide any service, We will ask your permission to collect and use the
      information needed. This is done via Our online or in app registration forms, and other
      ways of collecting information;</li>
  </ul>
  <p>We may collect your personal information, and that of other persons using Our {{brand}}
    Service, from your interactions and transactions with Us including:</p>
  <ul>
    <li>When you request information about Our {{brand}} Services;</li>
    <li>When you register or subscribe to Our {{brand}} Services including Our
      website, mobile application and mailing list;</li>
    <li>When you participate in Our rewards programs;</li>
    <li>When you visit one of Our partners as a part of the services We provide;</li>
    <li>When you visit certain parts of Our website where you are invited to provide content,
      submissions and suggestions, visitorship data or participate in interactive online activities
      (i.e. surveys, rewards programs, my account );</li>
    <li>When you participate in contests or competitions;</li>
    <li>When you contact Our partners, suppliers, associates or others within Our group of companies;</li>
  </ul>
  <p>We may also collect information by other means and will take all reasonable steps to inform you if and when We do.
  </p>
  <h4>Why We Collect Personal Information</h4>
  <p>We collect, hold, use and disclose your personal information so that We may:</p>
  <ul>
    <li>Provide you with Our {{brand}} Services;</li>
    <li>Provide you with information about Our {{brand}} Services;</li>
    <li>Tell you about {{brand}} Services and provide information to Our third party organisations ( including Private Health Insurance companies), 
      subject to this Privacy Policy;</li>
    <li>Respond to your requests for information;</li>
    <li>Process survey results and other data you provide;</li>
    <li>Process contest entries;</li>
    <li>Internal management purposes;</li>
    <li>Facilitate Our marketing and promotional activities;</li>
    <li>Filter the {{brand}} Services and information We offer;</li>
    <li>Open, maintain and administer user accounts;</li>
    and
    <li>Any other purpose to which you have consented or which is required or permitted by law.</li>
  </ul>
  <p>We may also collect non-personal information such as your IP address, browser type, 
    the date and time of your visit, the particular pages you access and the information you download. 
    The information We obtain is not personal information about you. For more information, please 
    read the section on Data Usage and Cookies below.</p>
  <p>Where your information is no longer required for the purpose for which it was collected 
    (for example if Our agreement with your employer to provide the {{brand}} Service comes to 
    an end) and We have no legal obligation to retain the information, {{brand}} will use 
    reasonable endeavours to ensure that your information is deleted, destroyed or otherwise 
    de-identified.</p>
  <p>Third Party Providers<br>
    Except as set out in this Privacy Policy or as specifically agreed to by you, We will not disclose 
    any information We gather from you via the {{brand}} Service.</p>
  <p>We may provide your information to third party providers, including health insurers where you have 
    provided consent, for example to permit them to contact you with newsletters, marketing and 
    promotional materials and other information they may be of interest to you.</p>

  <h4>Usage Data</h4>
  <p>We may also collect information that your browser sends whenever you visit Our {{brand}} Service or 
    when you access the {{brand}} Service by or through a mobile device ("Usage Data").</p>
  <p>This Usage Data may include information such as your computer's Internet Protocol address 
    (e.g. IP address), browser type, browser version, the pages of Our {{brand}} Service that you visit, the time and date of your visit, the time spent on those
    pages, unique device identifiers and other diagnostic data.</p>
  <p>When you access the {{brand}} Service by or through a mobile device, this Usage Data may include information such as the type
    of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating
    system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>


  <h4>Tracking Cookies Data</h4>
  <p>We use cookies and similar tracking technologies to track the activity on Our {{brand}} Service and hold certain information.
  </p>
  <p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to
    your
    browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to
    collect
    and track information and to improve and analyze Our {{brand}} Service.</p>
  <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. 
    However, if you do not accept cookies, you may not be able to use some portions of Our {{brand}} Service.</p>
  <p>Examples of Cookies we use:</p>
  <ul>
    <li>
      <strong>Session Cookies.</strong> We use Session Cookies to operate Our {{brand}} Service.</li>
    <li>
      <strong>Preference Cookies.</strong> We use Preference Cookies to remember your preferences and various settings.
    </li>
    <li>
      <strong>Security Cookies.</strong> We use Security Cookies for security purposes.</li>
    <li>
      <strong>Advertising Cookies.</strong> Advertising Cookies are used to serve you with advertisements that may be
      relevant to you and your interests.</li>
  </ul>

  <h4>Use of Data</h4>
  <p>Altius Group Limited uses the collected data for various purposes:</p>
  <ul>
    <li>To provide and maintain Our {{brand}} Service</li>
    <li>To notify you about changes to Our {{brand}} Service</li>
    <li>To allow you to participate in interactive features of Our {{brand}} Service when you choose to do so</li>
    <li>To provide customer support</li>
    <li>To gather analysis or valuable information so that we can improve Our {{brand}} Service</li>
    <li>To monitor the usage of Our {{brand}} Service</li>
    <li>To detect, prevent and address technical issues</li>
    <li>To provide you with news, special offers and general information about other goods, services 
      and events which We offer that are similar to those that you have already purchased or enquired 
      about unless you have opted not to receive such information
    </li>
  </ul>


  <h4>Legal Basis for Processing Personal Data Under General Data Protection Regulation (GDPR)</h4>
  <p>If you are from the European Economic Area (EEA), Altius Group Limited legal basis for collecting and 
    using the personal information described in this Privacy Policy depends on the Personal Data We 
    collect and the specific context in which We collect it.</p>
  <p>Altius Group Limited may process your Personal Data because:</p>
  <ul>
    <li>We need to perform a contract with you</li>
    <li>You have given us permission to do so</li>
    <li>The processing is in Our legitimate interests and it's not overridden by your rights</li>
    <li>For payment processing purposes</li>
    <li>To comply with the law</li>
  </ul>


  <h4>Retention of Data</h4>
  <p>Altius Group Limited will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy
    Policy.
    We will retain and use your Personal Data to the extent necessary to comply with Our legal obligations (for example,
    if We are required to retain your data to comply with applicable laws), resolve disputes, and enforce Our legal
    agreements and policies.</p>
  <p>Altius Group Limited will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter
    period of time, except when this data is used to strengthen the security or to improve the functionality of Our {{brand}} Service, or
    We are legally obligated to retain this data for longer time periods.</p>

  <h4>Transfer Of Data</h4>
  <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of
    your state, province, country or other governmental jurisdiction where the data protection laws may differ than those
    from your jurisdiction.</p>
  <p>If you are located outside Australia and choose to provide information to Us, please note that We transfer the
    data, including Personal Data, to Australia and process it there.</p>
  <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to
    that transfer.</p>
  <p>Altius Group Limited will take all steps reasonably necessary to ensure that your data is treated securely and in accordance
    with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there
    are adequate controls in place including the security of your data and other personal information.</p>

  <h4>Disclosure Of Data</h4>
  <h6>Business Transaction</h6>
  <p>If Altius Group Limited is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will
    provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>

  <h5>Disclosure for Law Enforcement</h5>
  <p>Under certain circumstances, Altius Group Limited may be required to disclose your Personal Data if required to do so by law or
    in response to valid requests by public authorities (e.g. a court or a government agency).</p>

  <h5>Legal Requirements</h5>
  <p>Altius Group Limited may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
  <ul>
    <li>To comply with a legal obligation</li>
    <li>To protect and defend the rights or property of Altius Group Limited </li>
    <li>To prevent or investigate possible wrongdoing in connection with the {{brand}} Service</li>
    <li>To protect the personal safety of users of the {{brand}} Service or the public</li>
    <li>To protect against legal liability</li>
  </ul>

  <h4>Security Of Data</h4>
  <p>The security of your data is important to Us, but remember that no method of transmission over the Internet, or
    method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal
    Data, We cannot guarantee its absolute security.</p>

  <h4>"Do Not Track" Signals</h4>
  <p>We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform
    websites that you do not want to be tracked.</p>
  <p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>

  <h4>Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h4>
  <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. Altius Group Limited aims to
    take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
  <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from Our systems,
    please contact Us.</p>
  <p>In certain circumstances, you have the following data protection rights:</p>
  <ul>
    <li>
      <p>
        <strong>The right to access, update or to delete the information we have on you.</strong> Whenever made
        possible, you can access, update or request deletion of your Personal Data directly within your account settings section. 
        If you are unable to perform these actions yourself, please contact Us to assist you.</p>
    </li>
    <li>
      <p>
        <strong>The right of rectification.</strong> You have the right to have your information rectified if that
        information is inaccurate or incomplete.</p>
    </li>
    <li>
      <p>
        <strong>The right to object.</strong> You have the right to object to Our processing of your Personal Data.</p>
    </li>
    <li>
      <p>
        <strong>The right of restriction.</strong> You have the right to request that We restrict the processing of your
        personal information.
      </p>
    </li>
    <li>
      <p>
        <strong>The right to data portability.</strong> You have the right to be provided with a copy of the information
        we have on you in a structured, machine-readable and commonly used format.</p>
    </li>
    <li>
      <p>
        <strong>The right to withdraw consent.</strong> You also have the right to withdraw your consent at any time
        where Altius Group Limited relied on your consent to process your personal information.</p>
    </li>
  </ul>
  <p>Please note that We may ask you to verify your identity before responding to such requests.</p>

  <p>You have the right to complain to a Data Protection Authority about Our collection and use of your Personal Data.
    For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>

  <h4>Service Providers</h4>
  <p>We may employ third party companies and individuals to facilitate Our {{brand}} Service (" {{brand}} Service Providers"), to provide the
    {{brand}} Service on Our behalf, to perform {{brand}} Service-related {{brand}} Services or to assist Us in analyzing how Our 
    {{brand}} Service is used.</p>
  <p>These third parties have access to your Personal Data only to perform these tasks on Our behalf and are obligated
    not to disclose or use it for any other purpose.</p>

  <h5>Analytics</h5>
  <p>We may use third-party Service Providers to monitor and analyze the use of Our {{brand}} Service.</p>
  <ul>
    <li>
      <p>
        <strong>Google Analytics</strong>
      </p>
      <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google
        uses the data collected to track and monitor the use of Our {{brand}} Service. This data is shared with other Google services.
        Google may use the collected data to contextualize and personalize the ads of its own advertising network.</p>
      <p>For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page:
        <a href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a>
      </p>
    </li>
  </ul>

  <h5>Advertising</h5>
  <p>We may use third-party Service Providers to show advertisements to you to help support and maintain Our {{brand}} Service.
  </p>
  <ul>
    <li>
      <p>
        <strong>Google AdSense &amp; DoubleClick Cookie</strong>
      </p>
      <p>Google, as a third party vendor, uses cookies to serve ads on Our {{brand}} Service. Google's use of the DoubleClick
        cookie enables it and its partners to serve ads to Our users based on their visit to Our {{brand}} Service or other websites on the
        Internet.</p>
      <p>You may opt out of the use of the DoubleClick Cookie for interest-based advertising by visiting the Google Ads
        Settings web page:
        <a href="http://www.google.com/ads/preferences/">http://www.google.com/ads/preferences/</a>
      </p>
    </li>
  </ul>


  <h5>Behavioral Remarketing</h5>
  <p>Altius Group Limited uses remarketing services to advertise on third party websites to you after you visited Our {{brand}} Service. 
    We and Our third-party vendors use cookies to inform, optimize and serve ads based on your past visits to Our {{brand}} Service.</p>
  <ul>
    <li>
      <p>
        <strong>Google AdWords</strong>
      </p>
      <p>Google AdWords remarketing service is provided by Google Inc.</p>
      <p>You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by
        visiting the Google Ads Settings page:
        <a href="http://www.google.com/settings/ads">http://www.google.com/settings/ads</a>
      </p>
      <p>Google also recommends installing the Google Analytics Opt-out Browser Add-on -
        <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a> - for your web
        browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being 
        collected and used by Google Analytics.</p>
      <p>For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page:
        <a href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a>
      </p>
    </li>
  </ul>

  <h5>Payments</h5>
  <p>We may provide paid products and/or services within the {{brand}} Service. In that case, we use third-party services for
    payment processing (e.g. payment processors).</p>
  <p>We will not store or collect your payment card details. That information is provided directly to our third-party
    payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors
    adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands
    like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment
    information.</p>
  <p>The payment processors We work with are:</p>
  <ul>
    <li>
      <p>
        <strong>Apple Store In-App Payments</strong>
      </p>
      <p>Their Privacy Policy can be viewed at
        <a href="https://www.apple.com/legal/privacy/en-ww/">https://www.apple.com/legal/privacy/en-ww/</a>
      </p>
    </li>
    <li>
      <p>
        <strong>Google Play In-App Payments</strong>
      </p>
      <p>Their Privacy Policy can be viewed at
        <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>
      </p>
    </li>
    <li>
      <p>
        <strong>Stripe</strong>
      </p>
      <p>Their Privacy Policy can be viewed at
        <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>
      </p>
    </li>
    <li>
      <p>
        <strong>PayPal or Braintree</strong>
      </p>
      <p>Their Privacy Policy can be viewed at
        <a
          href="https://www.paypal.com/webapps/mpp/ua/privacy-full">https://www.paypal.com/webapps/mpp/ua/privacy-full</a>
      </p>
    </li>
  </ul>


  <h4>Links To Other Sites</h4>
  <p>Our {{brand}} Service may contain links to other sites that are not operated by Us. If you click on a third party link, you
    will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
  </p>
  <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third
    party sites or services.</p>


  <h4>Children's Privacy</h4>
  <p>Our {{brand}} Service does not address anyone under the age of 16 ("Children").</p>
  <p>We do not knowingly collect personally identifiable information from anyone under the age of 16. If you are a
    parent or guardian and you are aware that your Children has provided Us with Personal Data, please contact Us. If We become
    aware that We have collected Personal Data from children without verification of parental consent, we take steps to remove
    that information from Our servers.</p>


  <h4>Changes To This Privacy Policy</h4>
  <p>We may update Our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy
    Policy on this page.</p>
  <p>We will let you know via email and/or a prominent notice on Our {{brand}} Service, prior to the change becoming effective and
    update the "effective date" at the top of this Privacy Policy.</p>
  <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
    effective when they are posted on this page.</p>


  <h4>Contact Us</h4>
  <p>If you have any questions about this Privacy Policy, please contact Us:</p>
  <ul>
    <li>By email:
      <a [href]="'mailto:' + helpEmail">{{helpEmail}}</a>
    </li>
  </ul>
</div>

<div *ngIf="!is1700" class="p-3 ml-3 mr-3 content-background">
  <h1>Privacy Policy</h1>
  <p>Effective date: May 16, 2018</p>
  <p>Altius Group ("us", "we", or "our") operates the
    <a href="">{{url}}</a> website and the {{brand}} mobile application (the "Service").</p>
  <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service
    and the choices you have associated with that data.</p>
  <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information
    in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have
    the same meanings as in our Terms and Conditions.</p>
  <h4>Definitions</h4>
  <ul>
    <li>
      <p>
        <strong>Service</strong>
      </p>
      <p>Service means the
        <a href="">{{url}}</a> website and the {{brand}} mobile application operated
        by Altius Group</p>
    </li>
    <li>
      <p>
        <strong>Personal Data</strong>
      </p>
      <p>Personal Data means data about a living individual who can be identified from those data (or from those and other information
        either in our possession or likely to come into our possession).</p>
    </li>
    <li>
      <p>
        <strong>Usage Data</strong>
      </p>
      <p>Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure
        itself (for example, the duration of a page visit).</p>
    </li>
    <li>
      <p>
        <strong>Cookies</strong>
      </p>
      <p>Cookies are small pieces of data stored on your device (computer or mobile device).</p>
    </li>
    <li>
      <p>
        <strong>Data Controller</strong>
      </p>
      <p>Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines
        the purposes for which and the manner in which any personal information are, or are to be, processed.</p>
      <p>For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.</p>
    </li>
    <li>
      <p>
        <strong>Data Processors (or Service Providers)</strong>
      </p>
      <p>Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data
        Controller.
      </p>
      <p>We may use the services of various Service Providers in order to process your data more effectively.</p>
    </li>
    <li>
      <p>
        <strong>Data Subject (or User)</strong>
      </p>
      <p>Data Subject is any living individual who is using our Service and is the subject of Personal Data.</p>
    </li>
  </ul>

  <h4>Information Collection And Use</h4>
  <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

  <h4>Types of Data Collected</h4>

  <h5>Personal Data</h5>
  <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used
    to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>

  <ul>
    <li>Email address</li>
    <li>Cookies and Usage Data</li>
  </ul>

  <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information
    that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following
    the unsubscribe link or instructions provided in any email we send.</p>

  <h4>Usage Data</h4>

  <p>We may also collect information that your browser sends whenever you visit our Service or when you access the Service by
    or through a mobile device ("Usage Data").</p>
  <p>This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type,
    browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages,
    unique device identifiers and other diagnostic data.</p>
  <p>When you access the Service by or through a mobile device, this Usage Data may include information such as the type of
    mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system,
    the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>


  <h4>Tracking &amp; Cookies Data</h4>
  <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
  <p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your
    browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect
    and track information and to improve and analyze our Service.</p>
  <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not
    accept cookies, you may not be able to use some portions of our Service.</p>
  <p>Examples of Cookies we use:</p>
  <ul>
    <li>
      <strong>Session Cookies.</strong> We use Session Cookies to operate our Service.</li>
    <li>
      <strong>Preference Cookies.</strong> We use Preference Cookies to remember your preferences and various settings.</li>
    <li>
      <strong>Security Cookies.</strong> We use Security Cookies for security purposes.</li>
    <li>
      <strong>Advertising Cookies.</strong> Advertising Cookies are used to serve you with advertisements that may be relevant to
      you and your interests.</li>
  </ul>

  <h4>Use of Data</h4>
  <p>Altius Group uses the collected data for various purposes:</p>
  <ul>
    <li>To provide and maintain our Service</li>
    <li>To notify you about changes to our Service</li>
    <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
    <li>To provide customer support</li>
    <li>To gather analysis or valuable information so that we can improve our Service</li>
    <li>To monitor the usage of our Service</li>
    <li>To detect, prevent and address technical issues</li>
    <li>To provide you with news, special offers and general information about other goods, services and events which we offer
      that are similar to those that you have already purchased or enquired about unless you have opted not to receive such
      information
    </li>
  </ul>


  <h4>Legal Basis for Processing Personal Data Under General Data Protection Regulation (GDPR)</h4>
  <p>If you are from the European Economic Area (EEA), Altius Group legal basis for collecting and using the personal information
    described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect
    it.
  </p>
  <p>Altius Group may process your Personal Data because:</p>
  <ul>
    <li>We need to perform a contract with you</li>
    <li>You have given us permission to do so</li>
    <li>The processing is in our legitimate interests and it's not overridden by your rights</li>
    <li>For payment processing purposes</li>
    <li>To comply with the law</li>
  </ul>


  <h4>Retention of Data</h4>
  <p>Altius Group will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy.
    We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example,
    if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements
    and policies.</p>
  <p>Altius Group will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period
    of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or
    we are legally obligated to retain this data for longer time periods.</p>

  <h4>Transfer Of Data</h4>
  <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your
    state, province, country or other governmental jurisdiction where the data protection laws may differ than those from
    your jurisdiction.</p>
  <p>If you are located outside Australia and choose to provide information to us, please note that we transfer the data, including
    Personal Data, to Australia and process it there.</p>
  <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
  <p>Altius Group will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this
    Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are
    adequate controls in place including the security of your data and other personal information.</p>

  <h4>Disclosure Of Data</h4>
  <h6>Business Transaction</h6>
  <p>If Altius Group is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice
    before your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>

  <h4>Disclosure for Law Enforcement</h4>
  <p>Under certain circumstances, Altius Group may be required to disclose your Personal Data if required to do so by law or in response
    to valid requests by public authorities (e.g. a court or a government agency).</p>

  <h4>Legal Requirements</h4>
  <p>Altius Group may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
  <ul>
    <li>To comply with a legal obligation</li>
    <li>To protect and defend the rights or property of Altius Group</li>
    <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
    <li>To protect the personal safety of users of the Service or the public</li>
    <li>To protect against legal liability</li>
  </ul>

  <h4>Security Of Data</h4>
  <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method
    of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data,
    we cannot guarantee its absolute security.</p>

  <h4>"Do Not Track" Signals</h4>
  <p>We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform websites
    that you do not want to be tracked.</p>
  <p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>

  <h4>Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h4>
  <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. Altius Group aims to take
    reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
  <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please
    contact us.</p>
  <p>In certain circumstances, you have the following data protection rights:</p>
  <ul>
    <li>
      <p>
        <strong>The right to access, update or to delete the information we have on you.</strong> Whenever made possible, you can
        access, update or request deletion of your Personal Data directly within your account settings section. If you are
        unable to perform these actions yourself, please contact us to assist you.</p>
    </li>
    <li>
      <p>
        <strong>The right of rectification.</strong> You have the right to have your information rectified if that information is
        inaccurate or incomplete.</p>
    </li>
    <li>
      <p>
        <strong>The right to object.</strong> You have the right to object to our processing of your Personal Data.</p>
    </li>
    <li>
      <p>
        <strong>The right of restriction.</strong> You have the right to request that we restrict the processing of your personal
        information.
      </p>
    </li>
    <li>
      <p>
        <strong>The right to data portability.</strong> You have the right to be provided with a copy of the information we have
        on you in a structured, machine-readable and commonly used format.</p>
    </li>
    <li>
      <p>
        <strong>The right to withdraw consent.</strong> You also have the right to withdraw your consent at any time where Altius Group
        relied on your consent to process your personal information.</p>
    </li>
  </ul>
  <p>Please note that we may ask you to verify your identity before responding to such requests.</p>

  <p>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more
    information, please contact your local data protection authority in the European Economic Area (EEA).</p>

  <h4>Service Providers</h4>
  <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service
    on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
  <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to
    disclose or use it for any other purpose.</p>

  <h4>Analytics</h4>
  <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
  <ul>
    <li>
      <p>
        <strong>Google Analytics</strong>
      </p>
      <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses
        the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google
        may use the collected data to contextualize and personalize the ads of its own advertising network.</p>
      <p>For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page:
        <a href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a>
      </p>
    </li>
  </ul>

  <h4>Advertising</h4>
  <p>We may use third-party Service Providers to show advertisements to you to help support and maintain our Service.</p>
  <ul>
    <li>
      <p>
        <strong>Google AdSense &amp; DoubleClick Cookie</strong>
      </p>
      <p>Google, as a third party vendor, uses cookies to serve ads on our Service. Google's use of the DoubleClick cookie enables
        it and its partners to serve ads to our users based on their visit to our Service or other websites on the Internet.</p>
      <p>You may opt out of the use of the DoubleClick Cookie for interest-based advertising by visiting the Google Ads Settings
        web page:
        <a href="http://www.google.com/ads/preferences/">http://www.google.com/ads/preferences/</a>
      </p>
    </li>
  </ul>


  <h4>Behavioral Remarketing</h4>
  <p>Altius Group uses remarketing services to advertise on third party websites to you after you visited our Service. We and our
    third-party vendors use cookies to inform, optimize and serve ads based on your past visits to our Service.</p>
  <ul>
    <li>
      <p>
        <strong>Google AdWords</strong>
      </p>
      <p>Google AdWords remarketing service is provided by Google Inc.</p>
      <p>You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting
        the Google Ads Settings page:
        <a href="http://www.google.com/settings/ads">http://www.google.com/settings/ads</a>
      </p>
      <p>Google also recommends installing the Google Analytics Opt-out Browser Add-on -
        <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a> - for your web browser. Google Analytics Opt-out Browser Add-on provides
        visitors with the ability to prevent their data from being collected and used by Google Analytics.</p>
      <p>For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page:
        <a href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a>
      </p>
    </li>
  </ul>

  <h4>Payments</h4>
  <p>We may provide paid products and/or services within the Service. In that case, we use third-party services for payment
    processing (e.g. payment processors).</p>
  <p>We will not store or collect your payment card details. That information is provided directly to our third-party payment
    processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere
    to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like
    Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
  <p>The payment processors we work with are:</p>
  <ul>
    <li>
      <p>
        <strong>Apple Store In-App Payments</strong>
      </p>
      <p>Their Privacy Policy can be viewed at
        <a href="https://www.apple.com/legal/privacy/en-ww/">https://www.apple.com/legal/privacy/en-ww/</a>
      </p>
    </li>
    <li>
      <p>
        <strong>Google Play In-App Payments</strong>
      </p>
      <p>Their Privacy Policy can be viewed at
        <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>
      </p>
    </li>
    <li>
      <p>
        <strong>Stripe</strong>
      </p>
      <p>Their Privacy Policy can be viewed at
        <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>
      </p>
    </li>
    <li>
      <p>
        <strong>PayPal or Braintree</strong>
      </p>
      <p>Their Privacy Policy can be viewed at
        <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">https://www.paypal.com/webapps/mpp/ua/privacy-full</a>
      </p>
    </li>
  </ul>


  <h4>Links To Other Sites</h4>
  <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will
    be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
  <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party
    sites or services.</p>


  <h4>Children's Privacy</h4>
  <p>Our Service does not address anyone under the age of 16 ("Children").</p>
  <p>We do not knowingly collect personally identifiable information from anyone under the age of 16. If you are a parent or
    guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware
    that we have collected Personal Data from children without verification of parental consent, we take steps to remove
    that information from our servers.</p>


  <h4>Changes To This Privacy Policy</h4>
  <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy
    on this page.</p>
  <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update
    the "effective date" at the top of this Privacy Policy.</p>
  <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective
    when they are posted on this page.</p>


  <h4>Contact Us</h4>
  <p>If you have any questions about this Privacy Policy, please contact us:</p>
  <ul>
    <li>By email:
      <a [href]="'mailto:' + helpEmail" >{{helpEmail}}</a>
    </li>
  </ul>
</div> 