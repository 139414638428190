import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../services/util-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../services/user-service';
import { CompanyService } from '../../../services/company-service';
import { IUser } from '../../_models/user';
import { DateService } from '../../../services/date.service';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { ICompany } from '../../_models/company';
import { IIdNamePair } from '../../_models/id-name-pair';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
  items: IUser[];
  item: IUser;
  itemType = 'User';
  columns = [];
  itemForm: FormGroup;

  isDisplayingList: boolean;
  isInitialised: boolean;
  isEditingItem: boolean;
  isAddingItem: boolean;
  canRespondToButtons: boolean;

  isPreventListDelete: boolean;
  isPreventAdd: boolean;
  isUserList: boolean;

  userProfileMessage = '';
  canDelete = false;
  companies: ICompany[];
  allCompanies: IIdNamePair[] = [];
  companyId: number;
  statuses: IIdNamePair[] = [];
  systemRoles: IIdNamePair[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private dateService: DateService,
    private utilService: UtilService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.isPreventListDelete = false;
    this.isPreventAdd = true;
    this.isUserList = true;
    this.companyService.refreshCache();

    this.activatedRoute.params.subscribe((route) => {
      this.analyseRoute(route);

      if (this.isDisplayingList) {
        this.getList();
      } else if (this.isEditingItem) {
        this.initialiseEditItem(+route.id);
      }
    });
  }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isEditingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isEditingItem = true;
    }
  }

  private getList() {
    // this.companyId = this.userService.company.id;
    this.companyId = this.companyService.getCurrentCompanyId();
    this.getUsersList(this.companyId);

    this.companyService.getAllCompanies()
      .subscribe((data: ICompany[]) => {
        let item: any = { id: 0, name: 'All', isActive: true };
        data.push(item);
        this.companies = data;
        this.allCompanies = this.companies.map(c => {
          return { id: c.id, name: c.name + this.getCompanyNameSuffix(c) };
        });
      });
  }

  private getCompanyNameSuffix(company: ICompany): string {
    if (company.isActive) {
      return '';
    }

    return ' ---- (INACTIVE)';
  }

  private getUsersList(companyId: number) {
    this.userService
      .getCompanyUsers(companyId)
      .subscribe(data => {
          this.items = data.map((i: IUser) => {
            return [
              i.id,
              i.email || '',
              i.username,
              this.userService.statusName(i.status) || '',
              this.userService.systemRoleName(i.system_role) || '',
              this.companyService.companyName(i.companyId) || '',
              this.dateService.formatDD_MMM_YYYY(i.createdAt),
              this.dateService.formatDD_MMM_YYYY_Allow_Null(i.lastLogin),
              i.hasInsights ? 'Yes' : 'No',
            ];
          });

          this.columns = [
            'id',
            'email',
            'username',
            'status',
            'role',
            'company',
            'registered date',
            'last login date',
            'insights',
          ];
          this.isInitialised = true;
          this.canRespondToButtons = true;
        },

        (error) => this.utilService.showToastError('Error while loading categories - ' + error),
      );
  }

  private initialiseEditItem(id: number): void {
    this.userService
      .getUserById(id)
      .subscribe((data) => {
        this.item = data;
        this.initialiseEditableFormGroup(data);
        this.isInitialised = true;
        this.canRespondToButtons = true;
      });
  }

  private initialiseEditableFormGroup(item: IUser) {
    this.companies = this.companyService.getAllCachedCompanies();
    this.statuses = this.userService.allStaticStatuses();
    this.systemRoles = this.userService.allStaticSystemRoles();
    if (this.item.companyId !== this.userService.envVariables.companyId) {
      this.systemRoles = this.systemRoles.filter(r => r.id !== this.userService.roleAdminId() && r.id !== this.userService.roleSuperAdminId());
    }
    this.userProfileMessage = item.userProfile
      ? 'There IS an associated User Profile'
      : 'There is NO associated User Profile';
    this.canDelete = true; //!item.userProfile;

    this.itemForm = this.fb.group({
      createdAtControl: this.dateService.formatDD_MMM_YYYY(item.createdAt),
      lastLoginControl: this.dateService.formatDD_MMM_YYYY(item.lastLogin),
      usernameControl: this.item.userProfile ? this.item.userProfile.username : '',
      emailControl: this.item.email,
      msisdnControl: this.item.msisdn,
      latestAppVersionControl: this.item.userProfile ? this.item.userProfile.latestAppVersion : '',
      companyIdControl: [this.item.companyId, Validators.required],
      employeeIdControl: this.item.employeeId,
      statusControl: this.item.status,
      systemRoleControl: this.item.system_role,
      enableInsightsControl: this.item.hasInsights,
    });

    this.validateEmail();
  }

  private validateEmail() {
    const emailControl = this.itemForm.get('emailControl');

    emailControl.clearValidators();
    if (emailControl.value) {
      emailControl.setValidators(Validators.email);
    }

    emailControl.updateValueAndValidity();
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  username(): string {
    if (!this.item.userProfile) {
      return '';
    }

    return this.item.userProfile.username;
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  private navigateToList() {
    this.router.navigate(['/cms/users']);
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Delete ' + this.itemType;
    modal.componentInstance.message = 'Confirm deleting this ' + this.itemType;
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(
      (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }

        this.userService.deleteUser(this.item.id).subscribe(
          () => {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`${this.itemType}  - deleted`);
            this.navigateToList();
          },
          (error) => this.utilService.showToastError('Error while deleting item - ' + error),
        );
      },
      () => {
      },
    );
  }

  private persistItem() {
    this.canRespondToButtons = false;
    let itemToPersist: IUser;

    try {
      itemToPersist = {
        id: this.item.id,
        status: this.itemForm.get('statusControl').value,
        system_role: this.itemForm.get('systemRoleControl').value,
        email: this.itemForm.get('emailControl').value,
        msisdn: this.itemForm.get('msisdnControl').value,
        companyId: this.itemForm.get('companyIdControl').value,
        employeeId: this.itemForm.get('employeeIdControl').value,
        createdAt: this.item.createdAt,
        hasInsights: this.itemForm.get('enableInsightsControl').value,
      };
    } catch (error) {
      this.utilService.showToastError('Error persisting item - ' + error);
      this.canRespondToButtons = true;
      return;
    }

    if (this.isEditingItem) {
      this.updateItem(itemToPersist);
    }
  }

  private updateItem(itemToPersist: IUser) {
    this.userService.updateUser(itemToPersist)
      .subscribe(
        () => {
          this.itemForm.reset();
          this.canRespondToButtons = true;
          this.utilService.showToastSuccess(`Updated ${this.itemType} - ${this.username()}`);
          this.navigateToList();
        },
        (error) => {
          this.utilService.showToastError(`Error while updating item - ${error}`);
          this.canRespondToButtons = true;
        },
      );

    this.userService.updateUserProfileForCMS({
      userId: this.item.id,
      companyId: this.itemForm.get('companyIdControl').value,
    });
  }

  onCompanySelected(companyId: number) {
    this.companyId = companyId;
    this.getUsersList(companyId);
  }

  onCompanyChanged(companyId: string) {
    if (+companyId === this.userService.envVariables.companyId) {
      if (!this.systemRoles.some(r => r.id === this.userService.roleAdminId())) {
        this.systemRoles.push(this.userService.roleAdminPair());
      }
      if (!this.systemRoles.some(r => r.id === this.userService.roleSuperAdminId())) {
        this.systemRoles.push(this.userService.roleSuperAdminPair());
      }
    } else {
      this.systemRoles = this.systemRoles.filter(r => r.id !== this.userService.roleAdminId() && r.id !== this.userService.roleSuperAdminId());
      this.itemForm.get('systemRoleControl').setValue(this.userService.roleUserId());
    }
  }

  onEmailChange() {
    this.validateEmail();
  }

  onBackClick() {
    this.navigateToList();
  }

  onDeleteClick() {
    if (!this.isEditingItem) {
      return;
    }

    this.deleteItem();
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }

    this.persistItem();
  }
}
