import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ICompany } from '../../_models/company';
import { BOOKING_CODE_FORMAT } from '../../_shared/formats';

@Component({
  selector: 'app-generate-link-dialog',
  templateUrl: './generate-link-dialog.component.html',
  styleUrls: ['./generate-link-dialog.component.scss'],
})
export class GenerateLinkDialogComponent implements OnInit {
  // listCompanies: ICompany[][];
  isSuperAdmin: boolean;
  // handledListCompaniesData;
  generateLinkForm: FormGroup;
  bookingCodeInvalid: boolean = false;
  disableVerifyBookingCode: boolean = true;

  constructor(private activeModal: NgbActiveModal, private fb: FormBuilder) {}

  ngOnInit() {
    // this.handledListCompaniesData = this.handleListCompaniesData(this.listCompanies);
    this.initForm();
  }

  onCompanySelected($event) {
    const companyId = +$event.target.value;
    this.generateLinkForm.patchValue({ companyId: companyId ? companyId : null });
    if (companyId) {
      this.generateLinkForm.patchValue({ enabledEAPFeature: null });
    }
  }

  onClickButton(isCancel = true) {
    if (!isCancel) {
      this.generateLinkForm.get('email').markAsTouched();
      if (this.generateLinkForm.valid) {
        this.activeModal.close(this.generateLinkForm.value);
      }
    } else {
      this.activeModal.close(null);
    }
  }

  handleListCompaniesData(data: ICompany[][]) {
    return data.map((item) => {
      const [id, name] = item;
      return { id, name };
    });
  }

  private initForm() {
    this.generateLinkForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
          ),
        ],
      ],
      enabledEAPFeature: [true],
      companyId: [null],
      bookingCode: ['', Validators.pattern(BOOKING_CODE_FORMAT)]
    });

    // if (!this.isSuperAdmin) {
    //   this.generateLinkForm.disable();
    // }
  }

  openBookingSite() {
    window.open(`https://www.peoplesense.au/${this.generateLinkForm.get('bookingCode').value.toUpperCase()}`, '_blank');
  }

  bookingCodeValidationHandler(validation) {
    this.bookingCodeInvalid = validation.hasError && this.generateLinkForm.get('bookingCode').touched && this.generateLinkForm.get('bookingCode').invalid;
    this.disableVerifyBookingCode = !this.generateLinkForm.get('bookingCode').value || validation.hasError;
  }
}
