import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as rxjs from 'rxjs';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  navigationFromDropdown: Boolean = false;
  public numberUnread = new rxjs.BehaviorSubject<number>(0);
  currentNumberUnread$ = this.numberUnread.asObservable();
  constructor(private http: HttpClient) { }

  changeNumberUnread(unreadCount: number) {
    this.numberUnread.next(unreadCount);
  }

  getNumberUnread(): Observable<number> {
    return this.currentNumberUnread$;
  }

  readNotification(data) {
    const url = '/notifications/mark-read/me';
    return this.http.put<any>(url, data);
  }

  updateUserNotifications(data) {
    const body = { notifications: data };
    const url = '/notifications/user/settings';

    return this.http.post<any>(url, body);
  }

  getNotifications() {
    const url = `/notifications/user/settings`;
    return this.http.get<any>(url);
  }

  getUserNotifications(offset, limit) {
    const url = `/notifications/user/${offset}/${limit}`;
    return this.http.get<any>(url);
  }

  getUnreadNotifications(notifications): number {
    const unreadNotificationsArr = notifications.filter(
      notification => notification.read === false
    );

    if (unreadNotificationsArr !== null) {
      return unreadNotificationsArr.length;
    } else {
      return 0;
    }
  }

  getUnreadCount() {
    const url = '/notifications/unread/me';
    return this.http.get<any>(url);
  }

  refreshUnreadCount() {
    this.getUnreadCount()
      .pipe(first())
      .subscribe(count => {
        this.changeNumberUnread(count);
      });
  }

  markAllRead() {
    const url = '/notifications/mark-all-read/me';
    return this.http.get<any>(url);
  }

  sendNewGroupInvitation(friends, groupId, groupName) {
    const body = { friends, groupId, groupName };
    const url = '/notifications/user/group/invite';

    return this.http.post<any>(url, body);
  }
}
