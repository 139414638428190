import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-welcome-message',
  templateUrl: './welcome-message.component.html',
  styleUrls: ['./welcome-message.component.scss']
})
export class WelcomeMessageComponent implements OnInit {
  text: any;

  @Input() companyWelcomeText: string;
  @Input() companyWelcomeTextLink;

  genericHTML: any = '<p>Welcome to your hub - an online platform providing content, resources, support and services designed to help guide you through every stage of your working life.</p><p> Enjoy exploring anywhere, anytime and from any device!</p><p>Your hub has so much to offer so please let us give you the grand tour! We promise you’ll find everything you need to succeed in your health and wellness goals.</p>'
  companyWelcomeTextHtml: string;
  canShowWelcomeTextLink = false;

  constructor (
    public activeModal: NgbActiveModal,
    public domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.companyWelcomeTextHtml = this.companyWelcomeText
      ? this.companyWelcomeText
      : this.genericHTML;
    this.canShowWelcomeTextLink = this.companyWelcomeTextLink.url
      && this.companyWelcomeTextLink.label;
  }

  closeModal() {
    this.triggerTutorialAfterWelcome();
  }

  triggerTutorialAfterWelcome() {
    this.activeModal.close('showTutorial');
  }

  openWelcomeTextLink() {
    this.activeModal.close('goingToWelcomeTextLink');
    window.location.href = this.companyWelcomeTextLink.url;
  }
}
