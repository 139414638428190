import { Component, OnInit } from '@angular/core';
import { BadgeService } from '../../../services/badge-service';
import { UserService } from '../../../services/user-service';
import * as _ from 'lodash';

import { LogService } from '../../../services/log.service';

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss'],
})
export class BadgesComponent implements OnInit {
  userLevel: any = [];
  inProgessBadges: any = [];
  achievedBadges: any = [];
  allBadges: any = [];
  userBadgeLevel = this.userService.profile.level;

  constructor(
    private badgeService: BadgeService,
    public userService: UserService,
    private logService: LogService,
  ) {
  }

  ngOnInit() {
    this.getLevel();
    this.loadUserBadges();
    this.logService.logBadges();
  }

  getLevel() {
    this.badgeService.getLevel().subscribe(data => {
      if (data.level !== this.userBadgeLevel) {
        this.userBadgeLevel = data.level;
      }
      this.userLevel = data;
    });
  }

  loadUserBadges() {
    this.badgeService.getUserBadges().subscribe(data => {
      this.achievedBadges = data.filter(b => b.achieved);
      this.inProgessBadges = data.filter(b => !b.achieved);
      this.getAllBadges();
    });
  }

  getAllBadges() {
    this.badgeService.loadAllBadges().subscribe(data => {
      const achievedBadges = this.achievedBadges;
      this.allBadges = _.map(data, function(badge) {
        const foundBadge = _.find(achievedBadges, function(uBadge) {
          return badge.id === uBadge.badgeId;
        });
        if (foundBadge) {
          return _.extend(badge, (badge.badgeEarned = true));
        } else {
          return _.extend(badge, (badge.badgeEarned = false));
        }
      });
    });
  }

  getGrathColor(level) {
    if (level === 'bronze') {
      return '#B3835E';
    }
    if (level === 'silver') {
      return '#B3B3B3';
    }
    if (level === 'gold') {
      return '#F1C24B';
    }
  }

  getPercentage(total, value) {
    return Math.trunc((value * 100) / total);
  }
}
