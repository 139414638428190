<div *ngIf="activatedRoute?.children?.length">
  <router-outlet></router-outlet>
</div>

<div *ngIf="!activatedRoute?.children?.length" class="pb-3">
  <h2 class="mt-2">Programs</h2>
  <p *ngIf="(programs$ | async)?.length else noProgramsAvailable">
    Click on any of the programs below to kick-start your journey.
  </p>

  <ng-template #noProgramsAvailable>
    <p>No programs available</p>
  </ng-template>

  <div *ngFor="let program of (programs$ | async)" class="card w-100 p-3 mt-3 d-inline-block">
    <div *ngIf="program.isUserInProgram" class="cursorHand" (click)="openProgram(program)">
      <div class="progress-label backPrimary">
        {{program.statusName}}
      </div>
      <div class="row">
        <div class="col-12 col-sm-3 text-center">
          <img class="packImg" [src]="program.imageSource" />
        </div>
        <div class="col-12 col-sm-9">
          <div class="titleAndProgress">
            <h5 class="card-title primaryColor">{{program.title}}</h5>
            <ngb-progressbar type="success" [value]="program.progressPercent" [showValue]="true" height="20px" class="mt-3">
            </ngb-progressbar>
          </div>

          <p class="card-text" [innerHTML]="program.description"></p>
        </div>
      </div>
    </div>
    <div *ngIf="!program.isUserInProgram">
      <div class="row">
        <div class="col-12 col-sm-3 text-center">
          <img class="packImg" [src]="program.imageSource" />
        </div>
        <div class="col-12 col-sm-9">
          <div class="titleAndProgress">
            <h5 class="card-title primaryColor">{{program.title}}</h5>
          </div>
          <p class="card-text" [innerHTML]="program.description"></p>
          <button *ngIf="canJoinProgram(program)" type="button" class="btn btn-primary w-100" (click)="joinProgram(program, $event)">Join Program</button>
        </div>
      </div>
    </div>
  </div>
</div>
