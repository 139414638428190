import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ContentDetailComponent } from '../_shared/components/content-detail/content-detail.component';
import { ProgramDetailComponent } from './program-detail/program-detail.component';
import { ProgramComponent } from './program/program.component';
import { DevicesComponent } from './devices/devices.component';
import { TrackMyHealthComponent } from './track-my-health/track-my-health.component';
import { LifeActionCardComponent } from './life-action/life-action.component';
import { AuthGuard } from '../guards/auth.guard';
import { ActComponent } from './act.component';
import { SharedModule } from '../_shared/shared.module';
import { QuestionnaireComponent } from '../_shared/components/questionnaires/questionnaire/questionnaire.component';
import { QuestionnaireDetailComponent } from '../_shared/components/questionnaires/questionnaire-detail/questionnaire-detail.component';
import { QuestionnaireResultComponent } from '../_shared/components/questionnaires/questionnaire-result/questionnaire-result.component';
import { FEATURES } from '../_shared/enums';
import { ChallengesComponent } from '../challenges/challenges.component';
import { TeamsComponent } from '../teams/teams.component';
import { ChallengeDetailsComponent } from '../challenge-details/challenge-details.component';
import { TeamDetailsComponent } from '../team-details/team-details.component';

const actRoutes: Routes = [
  {
    path: '',
    component: ActComponent,
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Act' }, // , featureId: 3
    children: [
      {
        path: 'lifeactions',
        component: LifeActionCardComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'LifeActions', featureId: FEATURES.LIFE_ACTIONS }
      },
      {
        path: 'your-health',
        component: TrackMyHealthComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Track Your Health', featureId: FEATURES.TRACK_MY_HEALTH }
      },
      {
        path: 'devices',
        component: DevicesComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Devices', featureId: FEATURES.DEVICES }
      },
      {
        path: 'programs-mega/:id',
        component: ProgramComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Programs',
          featureId: FEATURES.PROGRAMS,
          isFromMegaMenu: true
         },
      },
      {
        path: 'programs',
        component: ProgramComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Programs', featureId: FEATURES.PROGRAMS },
        children: [
          {
            path: ':titleAndId',
            component: ProgramDetailComponent,
            data: { breadcrumb: 'withId' },
            children: [
              {
                path: ':type/:id',
                component: ContentDetailComponent,
                data: { breadcrumb: 'Type' }
              }
            ]
          }
        ]
      },
      {
        path: 'challenges',
        component: ChallengesComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Challenges', featureId: FEATURES.CHALLENGES }
      },
      {
        path: 'challenge-details/:id',
        component: ChallengeDetailsComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'ChallengeDetails', featureId: FEATURES.CHALLENGES }
      },
      {
        path: 'teams',
        component: TeamsComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Teams', featureId: FEATURES.TEAMS }
      },
      {
        path: 'team-details/:id',
        component: TeamDetailsComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Teams', featureId: FEATURES.TEAMS },
      },
      {
        path: 'healthriskassessments',
        component: QuestionnaireComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Health Risk Assessments', featureId: FEATURES.HEALTH_RISK_ASSESSMENTS },
        children: [
          {
            path: ':titleAndId',
            component: QuestionnaireDetailComponent,
            data: { breadcrumb: 'withId' },
            children: [

            ]
          }, {
            path: ':titleAndId/result',
            component: QuestionnaireResultComponent,
            canActivate: [AuthGuard],
            data: { breadcrumb: 'withId' },
            children: [
              {
                path: ':type/:id',
                component: ContentDetailComponent,
                data: { breadcrumb: 'Type' }
              }
            ]
          }
        ]
      },
    ]
  },
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(actRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ActRoutingModule {}
