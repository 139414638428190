import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CMSEditGuard implements CanDeactivate<any> {

    canDeactivate(component): boolean {
        if (!component.itemForm || !component.itemForm.dirty) { return true; }

        const message = `Navigate away and lose all changes for this ${component.itemType}?`;
        return confirm(message);
    }
}
