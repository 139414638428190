import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IArticle } from '../app/_models/article';
import { DateService } from './date.service';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  constructor(private http: HttpClient, private dateService: DateService) {
  }

  getAllArticles() {
    return this.http.get<any>('/cms/articles');
  }

  getArticleById(articleId) {
    return this.http.get<any>(`/cms/articles/${articleId}`);
  }

  save(id, body) {
    if (id !== 0) {
      return this.http.put<any>(`/cms/articles/${id}`, body);
    } else {
      return this.http.post<any>(`/cms/articles`, body);
    }
  }

  delete(id) {
    return this.http.delete<any>(`/cms/articles/${id}`);
  }

  getNewArticle(): IArticle {
    return {
      id: 0,
      title: '',
      shortText: '',
      longText: null,
      image: '',
      published: true,
      publishedDate: this.dateService.getDate(),
      author: null,
      source: null,
      sourceUrl: null,
      linkUrl: null,
      copyright: null,
      readingTime: null,
      companyId: null,
      excludedCompanyIds: [],
      includedCompanyIds: [0],
      languageVersion: '[]'
    };
  }

  getS3Hash() {
    return this.http.get<any>('/config/froala/articles');
  }
}
