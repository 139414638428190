<img class="full-image" src="../../assets/img/discounts.wide.png">

<span style="text-align: center;">&nbsp;</span>
<h2>Welcome to My Rewards</h2>
<hr />
<div class="span8 centered-content">
  <div *ngIf="hasCompanyRewardsText" [innerHTML]="domSanitizer.bypassSecurityTrustHtml(companyRewardsText)"></div>
  <div *ngIf="!hasCompanyRewardsText" [innerHTML]="genericHTML"></div>

  <div class="row">
    <div class="col-sm-12 text-center">
      <div class="form-group">
        <button (click)="logNavigation()" class="btn btn-primary">Click here to get started</button>
      </div>
    </div>
  </div>
