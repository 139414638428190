<div class="form-check" [formGroup]="parentForm">
    <input
    class="form-check-input"
    type="checkbox"
    value=""
    name="isAcceptTermsAndConditions"
    id="isAcceptTermsAndConditions"
    [formControlName]="'isAcceptTermsAndConditions'"
    />
    <small><label class="form-check-label" for="isAcceptTermsAndConditions">
        I agree to
        <a class="hyperlink-company primaryColor"
          (click)="onTermsOfServiceClicked()">Terms of Service</a> and
        <a class="hyperlink-company primaryColor"
        (click)="onPrivacyPolicyClicked()">Privacy Policy</a>
    </label></small>
  </div>
  <div *ngIf="showIsAcceptTermsAndConditionsRequiredError()" class="cross-validation-error-message alert alert-danger p-1">
    <small>You must agree to the Terms of Service</small>
  </div>
