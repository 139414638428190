<div>
  <div *ngIf="canShowLogWidget" class="m-0 mb-3 text-sm-90 w-100">
    <app-peer-support-widget></app-peer-support-widget>
  </div>

  <div *ngIf="canShowHCFwidget" class="m-0 mb-3 text-sm-90 w-100">
      <app-hcf-widget></app-hcf-widget>
  </div>
    
  <div *ngIf="canshowEapBooking" class="m-0 mb-3 text-sm-90 w-100">
      <app-eap-booking-widget></app-eap-booking-widget>
  </div>

  <div *ngIf="canShowDownloadTheApp" class="m-0 mb-3 text-sm-90 w-100">
    <app-download-app-links></app-download-app-links>
  </div>

  <div *ngIf="canshowNasdaqwidget" class="m-0 mb-3 text-sm-90 w-100">
      <app-nasdaq-widget></app-nasdaq-widget>
    </div>

  <div *ngIf="canShowMyRewards" class="m-0 mb-3 text-sm-90 w-100">
    <app-my-rewards-widget></app-my-rewards-widget>
  </div>

  <div *ngIf="canShowCompetition" class="m-0 mb-3 text-sm-90 w-100">
    <app-competition-widget [currentCompetition]="currentCompetition">
    </app-competition-widget>
  </div>

  <div *ngIf="canShowLastBadgeEarned" class="m-0 mb-3 text-sm-90 w-100">
    <app-last-badge-earned
      [lastBadgeEarned]="(lastBadgeEarned$ | async)"
    ></app-last-badge-earned>
  </div>

  <div *ngIf="canShowGroups" class="m-0 mb-3 text-sm-90 w-100">
    <app-groups-widget [activity]="latestGroupActivity$ | async"></app-groups-widget>
  </div>

  <div *ngIf="canShowSuggestedLifeAction" class="m-0 mb-3 text-sm-90 w-100">
    <app-life-action-widget
      [lifeAction]="suggestedLifeAction"
      (addLifeAction)="onAddLifeAction($event)"
    ></app-life-action-widget>
  </div>

  <div *ngIf="canShowLeaderboard" class="m-0 mb-3 text-sm-90 w-100">
    <app-leaderboard-widget [leaderboardItems]="shortLeaderboard$ | async"></app-leaderboard-widget>
  </div>

  <div *ngIf="canShowMyHealth" class="m-0 mb-3 text-sm-90 w-100">
    <app-my-health-widget [activeIndicators]="activeIndicators$ | async"></app-my-health-widget>
  </div>

</div>
