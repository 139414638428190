<div class="container">
  <div class="row" *ngIf="loading">
    <div class="col-12 w-100">
      <h4>Loading...</h4>
    </div>
  </div>
  <div *ngIf="!loading">
    <div class="row padding-top">
      <div class="col-12 col-sm-2 w-100">
        <div class="card" style="width: auto;">
          <img class="card-img-top" src="{{imagePath}}" alt="Card image cap">
        </div>
      </div>
      <div class="col-12 col-sm-4 my-auto w-100">
        <h5>{{teamName}}</h5>
      </div>
      <div class="col-12 col-sm-6 my-auto w-100">
        <!--- Buttons Block -->
        <div class="row">
          <div class="col-6">
              <button type="button" *ngIf="isMember" class="btn btn-outline-primary float-right w-100"
              [disabled]="loading" (click)="leaveTeam()">
              <i class="fa fa-sign-out mr-2" aria-hidden="true"></i> Leave Team</button>
            <button type="button" *ngIf="!isMember" class="btn btn-outline-primary float-right w-100"
              [disabled]="loading || isOtherTeamMember" (click)="joinTeam()">
              <i class="fa fa-sign-in mr-2" aria-hidden="true"></i> Join Team</button>          
          </div>
          <div class="col-6">
              <button type="button" class="btn btn-primary w-100" (click)="onDataSourceClick(1)">
                  <i class="fa fa-tablet mr-2" aria-hidden="true"></i> Devices
                </button>
          </div>
        </div>
      </div>
    </div><br>
    <div class="row">
      <div class="col-6 w-100">Top Performers</div>
      <div class="col-6 w-100">Members</div>
    </div>
    <div class="row">
      <!-- left -->
      <div class="col-6 w-100">
        <div class="p-2 content-background">
          <div class="row p-2" *ngFor="let i of topPerformers">
            <div class="col-xs-1 col-sm-3">
              <div style="width: auto;">
                <img class="w-100 float-left rounded-circle" src="{{i.photo}}" alt="Card image cap">
              </div>
            </div>
            <div class="col-xs-6 col-sm-9">
              <div class="row">
                <div class="col-xs-4 col-sm-12">
                  <p class="text-wrap">{{i.username}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-4 col-sm-12">
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" [style.width]="i.stepsCompletedInPercentage + '%'"
                      attr.aria-valuenow="{{i.stepsCompletedInPercentage}}" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <p class="font-size"><b>Steps Completed: </b>{{i.stepsCompleted}}/{{i.stepsGoal}}</p>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="topPerformers && topPerformers.length == 0">
            <p>No Performers</p>
          </div>
        </div>
      </div>
      <!-- Right -->
      <div class="col-6 w-100">
        <div class="p-2 content-background">
          <div class="row p-2" *ngFor="let i of members">
            <div class="col-xs-1 col-sm-3">
              <div style="width: auto;">
                <img class="w-100 float-left rounded-circle" src="{{i.photo}}" alt="Card image cap">
              </div>
            </div>
            <div class="col-xs-6 col-sm-9">
              <div class="row">
                <div class="col-xs-4 col-sm-12">
                  <p class="text-wrap">{{i.username}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-4 col-sm-12">
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" [style.width]="i.stepsCompletedInPercentage + '%'"
                      attr.aria-valuenow="{{i.stepsCompletedInPercentage}}" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <p class="font-size"><b>Steps Completed: </b>{{i.stepsCompleted}}/{{i.stepsGoal}}</p>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="members && members.length == 0">
            <p>No Members</p>
          </div>
        </div>
      </div>
    </div><br>
    <!-- Challenge Details -->
    <div class="row">
      <div class="content-background p-5">
        <div class="row">
          <div class="col-12 w-100">
            <h4>{{challengeName}}</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-3">
            <div class="card">
              <img class="card-img-top" src="{{challengeImagePath}}" alt="Card image cap">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 linegray pb-2 mb-3">
            <div class="float-left">
            </div>
            <div class="float-right">
              <!-- <app-bookmark-indicator [bigger]="true" [primaryColor]="true" [bookmarked]="false"
                (click)="onBookmarkClick($event,item)"></app-bookmark-indicator> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-3">
            <h5>About the challenge</h5>
            <h6>description</h6>
            <div class="fr-view mt-4" [innerHTML]="domSanitizer.bypassSecurityTrustHtml(description)"></div>
            <br>
            <b>Start Date</b>&nbsp;&nbsp;{{startDate}} -
            <b>End Date</b>&nbsp;&nbsp;{{endDate}}
          </div>
        </div><br>
        <div class="row">
          <div class="col-12 linegray mb-3">
            <div class="float-left">
              <a class="like" style="cursor: default;">
                <i class="fa fa-thumbs-up fa-lg top-icon" aria-hidden="true"></i>
              </a>&nbsp;
              <span>{{likesCount | pluralize: 'like':'likes':' '}}</span>
            </div>
            <div class="float-right">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 w-100">
            <br>
            <div class="border"></div>
            <br>
          </div>
        </div>
        <div class="row">
          <div class="col-4 border-right text-center">
            <a (click)="onLikeClick()">
              <i *ngIf="isLike == false" class="fa fa-thumbs-o-up fa-sm top-icon" aria-hidden="true"></i>
              <i *ngIf="isLike == true" class="fa fa-thumbs-up fa-sm top-icon" aria-hidden="true"></i>&nbsp;&nbsp;Like
            </a>
          </div>
          <div class="col-4 border-right text-center">
            <i class="fa fa-comment-o fa-sm top-icon" aria-hidden="true"></i>&nbsp;&nbsp;Post Comment
          </div>
          <div class="col-4 text-center">
            <a (click)="shareToGroup()">
              <i class="fa fa-share-square-o fa-sm top-icon" aria-hidden="true"></i>&nbsp;&nbsp;Share to a group
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-12 w-100">
            <br>
            <div class="border"></div>
            <br>
          </div>
        </div>
        <form [formGroup]="itemForm">
          <!-- <div class="row">
            <div class="col-2 w-75 text-center">
              <img class="w-75 rounded-circle" src="{{userImage}}" alt="Card image cap" />
            </div>
            <div class="col-10 w-100">
              <div class="form-group">
                <textarea type="text" class="form-control" [ngClass]="displayFieldCss('commentControl')"
                  formControlName="commentControl" maxlength="250" placeholder="Say something...."></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-1">
              <button type="button" class="btn btn-primary w-10 pull-right" (click)="onSaveClick()"
                [disabled]="!itemForm.valid">
                <i class="fa fa-commenting mr-2" aria-hidden="true"></i> Post
              </button>
            </div>
          </div> -->
          <div class="row">
            <div class="card w-100 mt-4 p-2">
              <div class="postRow">
                <div class="avatarDivPost px-2 pt-2">
                  <div class="avatarColPost" [ngClass]="getUserProfileLevelClass('normal-shadow')">
                    <img class="img-avatar" [src]="userImage" onError="this.src='assets/img/profile.png'">
                  </div>
                </div>
                <app-text-area [type]="'post'" [placeholder]="postPlaceholder" [actionButtonText]="actionButtonText" [path]="postImagePath"
                  (data)="createNewPost($event)" class="post-input p-2"></app-text-area>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div><br>
    <!-- Post Details -->
    <p><b>Comment Details</b></p>
    <!-- <div class="content-background pt-4 pb-4">
          <div *ngFor="let i of items; let last = last">
          <div class="row">
            <div class="col-2 w-100 text-center">
              <img class="w-75 rounded-circle" src="{{i.photo}}" alt="Card image cap" />
            </div>
            <div class="col-10 w-100">
              <p><b>{{i.username}}</b></p>
              <p>{{ i.createdAt | timeFromNow }}</p>
            </div>
            <p class="pl-4 pr-4">{{i.description}}</p>
          </div>   
          <div *ngIf="!last" class="row">
            <div class="col-12 w-100">
              <div class="border"></div>
              <br>
            </div>
          </div></div>          
          <div *ngIf="items && items.length == 0" class="row">
            <div class="col-12 w-100 ml-5">
              <p>No Comments</p>
              <br>
            </div>
          </div>
        </div> -->
    <!-- POSTS -->
    <div *ngFor="let post of items; let postIndex = index" class="card w-40 mt-4 p-2">
      <div class="postRow">
        <div class="avatarDivPost px-2 pt-2">
          <div class="avatarColPost pointer" (click)="onViewProfile(post.userProfileId)"
            [ngClass]="getPostLevelClass(post.level, 'normal-shadow')">
            <img class="img-avatar" [src]="post.photo" onError="this.src='assets/img/profile.png'">
          </div>
        </div>

        <app-text-area *ngIf="isEditingPost(post.id); else readOnlyPost" [type]="'team'" [placeholder]="postPlaceholder"
          [path]="postImagePath" [description]="post.description" [inputImage]="post.image"
          [actionButtonText]="actionButtonTextOnEdit" [inputPreview]="post.preview" (data)="editPost($event)"
          (editCancelled)="cancel('post')" [fromEdit]="true" class="post-input p-2"></app-text-area>
          <!-- <app-text-area *ngIf="isEditingPost(post.id); else readOnlyPost" [type]="'team'" [placeholder]="postPlaceholder"
          [fromShare]="true" [description]="post.description" [actionButtonText]="actionButtonTextOnEdit" (data)="editPost($event)"
          (editCancelled)="cancel('post')" [fromEdit]="true" class="post-input p-2"></app-text-area> -->
        <ng-template #readOnlyPost>
          <div class="flex-space-between">
            <div class="px-2 pt-2">
              <p>
                <b class="pointer" (click)="onViewProfile(post.userProfileId)">
                  {{post.username}}
                </b>
                <label class="time">
                  {{post.updatedAt | timeFromNow}}
                </label>
              </p>
            </div>

            <div class="mr-1">
              <app-flagging [teamId]="id" [postId]="post.id" [companyId]="userCompanyId" [itemType]="'Post'"
                [canUserEditItem]="post.isEditable" [canUserDeleteItem]="post.isDeletable" [itemId]="post.id"
                [userProfileId]="post.userProfileId" (deleteItem)="callDeletePost($event)"
                (editItem)="callEditPost($event)"></app-flagging>
            </div>
          </div>
        </ng-template>
      </div>

      <div *ngIf="!isEditingPost(post.id)">
        <p class="description pl-1 text-justify" style="white-space: pre-wrap;" [innerHtml]="post.description"></p>
        <img *ngIf="isActiveImage(post)" class="w-100 mb-3" [src]="post.imageWithSource">
        <div *ngIf="isActivePreview(post) && isPreviewUrlValid(post)" (click)="openUrl(post.preview.url)">
          <a href="{{post.preview.url}}">
            <img class="w-100 mb-3 hyperlinkImage" [src]="post.preview.image">

            <h4 class="hyperlink pl-1">{{post.preview.title}}</h4>
          </a>
          <p class="pl-1">{{post.preview.description}}</p>
        </div>
      </div>

      <div *ngIf="!isEditingPost(post.id)" class="flex-space-between px-1 pb-2">
        <app-like-indicator [bigger]="false" [liked]="post.liked == 1" [likes]="post.likes" [isLoggedIn]="isLoggedin"
          [primaryColor]="true" (click)="onLikeClicked($event, post, 'teamComment')"></app-like-indicator>
      </div>
      <div class="bottomLinePost"></div>

      <!-- comments -->

      <div *ngFor="let comment of post.displayComments; let i = index" class="mt-2">
        <div *ngIf="isDisplayNumberOfCommentsLabel(post) && i === 0" class="float-right flex">
          <p class="no-wrap mr-2">{{numberOfCommentsLabel(post)}}</p>
          <a *ngIf="isDisplayMoreComments(post)" (click)="displayMorePostComments(post)">
            <p class="no-wrap">View more</p>
          </a>

          <a *ngIf="isDisplayLessComments(post)" (click)="displayLessComments(post)">
            <p class="no-wrap">Show Less</p>
          </a>

        </div>
        <div class="postRow">
          <div class="avatarDivPost px-2 pt-2">
            <div class="avatarColPost pointer" (click)="onViewProfile(comment.userProfileId)"
              [ngClass]="comment.level ? comment.level :'normal-shadow'">
              <img [src]="comment.photo" class="img-avatar" onError="this.src='assets/img/profile.png'">
            </div>
          </div>

          <app-text-area *ngIf="isEditingComment(comment.id); else readOnlyComment" [type]="'comment'"
            [actionButtonText]="actionButtonTextOnEdit" [placeholder]="commentPlaceholder" [path]="postImagePath"
            [description]="comment.description" [inputImage]="comment.image" [fromEdit]="true"
            [inputPreview]="comment.preview" (data)="editComment($event, post)" (editCancelled)="cancel('comment')"
            class="post-input p-2"></app-text-area>

          <ng-template #readOnlyComment>
            <div class="flex-space-between">
              <div class="px-2 pt-2">
                <p>
                  <b class="pointer" (click)="onViewProfile(comment.userProfileId)">
                    {{comment.username}}
                  </b>
                  <label class="time">
                    {{comment.updatedAt | timeFromNow}}
                  </label>
                </p>
              </div>
            </div>

            <div class="mr-1 mb-2 column-bottom">
              <div class="flex">
                <div *ngIf="!isEditingComment(comment.id)" class="mr-3 pt-1 no-wrap">
                  <app-like-indicator [bigger]="false" [liked]="comment.liked == 1" [likes]="comment.likes"
                    [isLoggedIn]="isLoggedin" [primaryColor]="true" (click)="onLikeClicked($event, comment, 'teamComment')">
                  </app-like-indicator>
                </div>
                <app-flagging [teamId]="id" [commentId]="comment.id" [companyId]="userCompanyId" [itemType]="'Comment'"
                  [canUserEditItem]="comment.isEditable" [canUserDeleteItem]="comment.isDeletable" [itemId]="comment.id"
                  [userProfileId]="comment.userProfileId" [itemIndex]="postIndex"
                  (deleteItem)="callDeleteComment($event)" (editItem)="callEditComment($event)"></app-flagging>
              </div>
            </div>
          </ng-template>
        </div>
        <div *ngIf="!isEditingComment(comment.id)">
          <p class="description comment-color pl-1 text-justify" style="white-space: pre-wrap;" [innerHtml]="comment.description"></p>
          <!-- <img *ngIf="isActiveImage(comment)" class="w-100 mb-3" [src]="comment.imageSource">
          <div *ngIf="isActivePreview(comment)" (click)="openUrl(comment.preview.url)">
            <a href="{{comment.preview.url}}">
              <img class="w-100 mb-3 hyperlinkImage" [src]="comment.preview.image">
              <h4 class="hyperlink pl-1">{{comment.preview.title}}</h4>
            </a>
            <p class="pl-1">{{comment.preview.description}}</p>
          </div> -->
        </div>
      </div>

      <!-- NEW Comment -->
      <div class="postRow mt-2">
        <div class="avatarDivPost px-2 pt-2">
          <div class="avatarColPost" [ngClass]="getUserProfileLevelClass('normal-shadow')">
            <img class="img-avatar" [src]="userProfilePictureSource" onError="this.src='assets/img/profile.png'">
          </div>
        </div>
        <app-text-area [type]="'comment'" [placeholder]="commentPlaceholder" [actionButtonText]="actionButtonText"
          [path]="postImagePath" (data)="addComment($event, post, postIndex)" class="post-input p-2"></app-text-area>

        <div *ngIf="isEmptyCommentError" class="text-danger">
          Please enter a valid comment
        </div>
      </div>
    </div>
    <!-- <button *ngIf="showInfo!==1" [hidden]="isNoMorePosts" (click)="viewMore()" class="btn btn-primary center-button mx-auto mt-4 mb-4">
      View more
    </button> -->
    <div *ngIf="items && items.length === 0" class="col-12">
      <div class="w-40 card p-2 text-center">
        <p>
          There are currently no comments in this team. You can start a conversation!
        </p>
      </div>
    </div>
  </div>
</div>