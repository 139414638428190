import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../../../services/date.service';

@Pipe({
  name: 'timeFromNow'
})
export class TimeFromNowPipe implements PipeTransform {

  constructor(private dateService: DateService) {}

  transform(tszDateTime: string, isIncludeBeforeYesterday: boolean = false): any {
    if (!isIncludeBeforeYesterday
        && this.dateService.isBeforeYesterday(tszDateTime)) {
      return this.dateService.formatLongDatetime(tszDateTime);
    }

    return this.dateService.timeFromNow(tszDateTime);
  }
}
