<div [formGroup]='form'>
  <input
    [ngClass]="{'is-invalid' : isInvalid() }"
    class='form-control {{cssClass}}'
    formControlName='{{formControlName}}'
    type='text' placeholder='XXX-XXX'
    (keyup)='onKeyUp()'
    (blur)='onLostFocus()'
    maxlength='7'
  />
</div>
