<div *ngIf='isInitialised' class='padding jumbotron'>
  <div *ngIf='!valid'>
    <p class='form-input-error-message'>Max number of categories are 3!</p>
  </div>
  <div class='menu-header'>
    <div>Level 1</div>
    <div>Level 2</div>
    <div>Level 3</div>
  </div>
  <div class='menu-configuration'>
    <div class='level1-wrapper' *ngFor='let level1 of configuration; let i = index;'>
      <div class='col-33p'><span>{{level1.name}}</span></div>
      <div cdkDropList class='col-67p' (cdkDropListDropped)='onItemDropped(level1.children, $event)'>
        <div *ngFor='let level2 of level1.children; let i=index;' class='level2-wrapper' [class.invalid]='level2.valid !== undefined && !level2.valid' cdkDrag>
          <div class='col-50p'>
            {{level2.name}}<i class='fa fa-pencil' aria-hidden='true' (click)='onItemEdit(level2)'></i>
          </div>
          <div cdkDropList class='col-50p' (cdkDropListDropped)='onItemDropped(level2.children, $event)'>
            <div *ngFor='let level3 of level2.children; let i=index;' class='level3' cdkDrag>
              <div class='custom-control custom-checkbox'>
                <input id='{{level3.id}}' type='checkbox' class='custom-control-input' [checked]='level3.active' [(ngModel)]='level3.active'>
                <label class='custom-control-label' for='{{level3.id}}'>{{level3.name}}</label>
                <i class='fa fa-pencil' aria-hidden='true' (click)='onItemEdit(level3)'></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class='row mt-4'>
    <div class='col-4 offset-8'>
      <button type='submit' class='btn btn-primary float-right w-100' (click)='onSaveClicked()'>
        <i class='fa fa-floppy-o mr-2' aria-hidden='true'></i> SAVE
      </button>
    </div>
  </div>
</div>
