import { FEATURES, LANGUAGES } from '../_shared/enums';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user-service';
import { UtilService } from '../../services/util-service';
import { BadgeService } from '../../services/badge-service';
import { LogService } from '../../services/log.service';
import { NotificationsService } from '../../services/notifications-service';
import { FilestackService } from '../../services/filestack-service';
import { DateService } from '../../services/date.service';
import { IUserProfile } from '../_models/user-profile';
import { Observable } from 'rxjs';
import { IUser } from '../_models/user';
import { FeatureService } from '../../services/feature-service';
import { DeleteUserModalComponent } from '../../components/delete-user-modal/delete-user-modal.component';
import { InsightsService } from '../../services/insights-service';

@Component({
  selector: 'app-page-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  @Input() temporaryToken;

  itemForm: FormGroup;
  isLoading = false;
  isExistingUserProfile = false;
  title = '';
  user: IUser;
  userProfile: IUserProfile;
  profilePic$: Observable<string>;
  lang: string = LANGUAGES.en;
  showLanguageOption: boolean;
  isInsightsDomain: boolean;

  constructor(
    public userService: UserService,
    public utilService: UtilService,
    private activeModal: NgbActiveModal,
    private fileStackService: FilestackService,
    private logService: LogService,
    private badgeService: BadgeService,
    private dateService: DateService,
    private notificationService: NotificationsService,
    private fb: FormBuilder,
    private featureService: FeatureService,
    private modalService: NgbModal,
    private insightsService: InsightsService,
  ) {
  }

  ngOnInit() {
    this.initialiseUserProfile();
    this.title = this.isExistingUserProfile
      ? 'My Profile'
      : 'Tell us more about yourself';
    this.profilePic$ = this.userService.profilePic$;
    const userCompanyFeatures = this.userService.getUserCompanyFeatures();
    this.showLanguageOption = this.featureService.isIncludeShowLanguageOptionFeature(userCompanyFeatures);
    this.isInsightsDomain = this.insightsService.isInsightsDomain();
  }

  private initialiseUserProfile() {
    this.isExistingUserProfile = this.userService.hasProfile();

    if (this.isExistingUserProfile) {
      this.logService.logMyProfile();
      this.initialiseEditItem();
    } else {
      this.initialiseAddItem();
    }
  }

  private initialiseEditItem() {
    this.userService.getMe()
      .subscribe((userData: IUser) => {
        this.user = userData;

        this.userService.getProfile()
          .subscribe((userProfileData: IUserProfile) => {
            this.userProfile = userProfileData;

            this.initialiseEditableFormGroup();
          });
      });
  }

  private initialiseAddItem() {
    this.userService.getMe()
      .subscribe((userData: IUser) => {
        this.user = userData;
        this.userProfile = this.userService.getNewProfile(
          this.user.id,
          this.user.companyId,
        );

        this.initialiseEditableFormGroup();
      });
  }

  private initialiseEditableFormGroup() {
    this.itemForm = this.fb.group({
      photo: this.userProfile.photo,
      username: [{value: this.userProfile.username, disabled: !!this.isInsightsDomain }, [Validators.required, Validators.minLength(2)]],
      gender: [{value: this.user.genderString || '', disabled: !!this.isInsightsDomain }],
    });
    this.lang = this.userProfile.lang ? this.userProfile.lang : LANGUAGES.en;
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  private persistItem() {
    this.populateUserFromFormControls();
    this.populateUserProfileFromFormControls();

    this.disableButtonsWhileProcessing();
    try {
      if (this.isExistingUserProfile) {
        this.updateItem();
      } else {
        this.addNewItem();
      }

    } catch (error) {
      this.reEnableButtons();
      this.utilService.showToastError('Unable to save the user profile');
    }
  }

  private populateUserFromFormControls() {
    this.user.genderString = this.itemForm.controls['gender'].value;

    this.user.isModified = this.itemForm.controls['gender'].dirty;
  }

  private populateUserProfileFromFormControls() {
    this.userProfile.isModified = (this.itemForm.controls['photo'].dirty || this.itemForm.controls['username'].dirty || this.userProfile.lang != this.lang);
    if (this.userProfile.isModified) {
      this.userProfile.photo = this.itemForm.controls['photo'].value;
      this.userProfile.username = this.itemForm.controls['username'].value;
      this.userProfile.lang = this.lang;
    }
  }

  private navigateBack() {
    this.activeModal.close();
  }

  private updateItem() {
    if (this.user.isModified) {
      this.updateUser();
    } else if (this.userProfile.isModified) {
      this.updateUserProfile();
    } else {
      this.navigateBack();
    }
  }

  private updateUser() {
    this.userService.updateMe(this.user)
      .subscribe(() => {
          if (this.userProfile.isModified) {
            this.updateUserProfile();
          } else {
            this.userService.refreshProfile();
            this.navigateBack();
          }
        },
        err => console.log('UserService.updateUser() error:', err),
      );
  }

  private updateUserProfile() {
    this.userService.updateMyProfile(this.userProfile)
      .subscribe(() => {
          this.userService.refreshProfile();
          this.userService.updateOneSignalTagsWithUserProfileData();

          this.navigateBack();
        },
        err => console.log('UserService.updateProfile() error:', err),
      );
  }

  private addNewItem() {
    this.userService.updateMe(this.user)
      .subscribe(() => {
        this.addUserProfile();
      }, err => this.displayUserProfileError(err));
  }

  private addUserProfile() {
    this.userService.addProfile(this.userProfile)
      .subscribe(data => {
        if (data.err) {
          this.displayUserProfileError(data.err);
          return;
        }

        this.userService.updateOneSignalTagsWithUserProfileData();
        this.badgeService.updateUserBadges('feature', FEATURES.MY_PROFILE);
        this.userService.temporaryToken = null;
        this.notificationService.refreshUnreadCount();

        this.navigateBack();
      }, err => this.displayUserProfileError(err));
  }

  private displayUserProfileError(err) {
    this.utilService.showToastError(
      'Error while adding new profile',
      err,
    );
    this.reEnableButtons();
  }

  private disableButtonsWhileProcessing() {
    this.isLoading = true;
  }

  private reEnableButtons() {
    this.isLoading = false;
  }

  changeLanguage(code) {
    this.lang = code;
  }

  onFormSubmit() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }

    this.persistItem();
  }

  onChangeProfilePictureClick() {
    this.fileStackService.pickProfileImage().then(
      res => {
        this.userService.changeProfilePicObs(res);
        this.itemForm.controls['photo'].patchValue(res);

        if (this.userService.profile && res.trim() !== '') {
          this.userService.updateUserProfile({ photo: res });
        }
      },
      err => {
        this.utilService.showToastError('Error while uploading picture', err);
      },
    );
  }

  onClose() {
    this.userService.changeProfilePicObs();
    this.navigateBack();
  }

  closeAccount(event) {
    event.preventDefault();
    event.stopPropagation();
    this.onClose();
    this.modalService.open(DeleteUserModalComponent);
  }
}

