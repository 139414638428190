import { Component, OnInit, OnChanges, EventEmitter, SimpleChanges, Input, Output } from '@angular/core';
import { IIdNamePair } from '../../_models/id-name-pair';

@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss']
})
export class SelectCompanyComponent implements OnInit, OnChanges {
  @Input() allCompanies: IIdNamePair[];
  @Input() selectedCompanyId: number;
  @Input() label: string;
  @Input() placeholder: string;
  @Output() companyIdSelected: EventEmitter<number> = new EventEmitter();

  isSorted = false;

  constructor() { }

  ngOnInit() {
    if (this.allCompanies && this.allCompanies.length && !this.isSorted) {
      this.allCompanies.sort(this.sortAscendingNames);
      this.isSorted = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.allCompanies && this.allCompanies.length && !this.isSorted) {
      this.allCompanies.sort(this.sortAscendingNames);
      this.isSorted = true;
    }
  }

  private sortAscendingNames(a: IIdNamePair, b: IIdNamePair) {
    const valueA = a.name.toUpperCase();
    const valueB = b.name.toUpperCase();

    if (valueA > valueB) { return 1; }
    if (valueA < valueB) { return -1; }

    return 0;
  }

  onCompanySelected($event) {
    const companyId = ($event.target.value !== 'null')
      ? $event.target.value
      : null;
    this.companyIdSelected.emit(companyId);
  }
}
