import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user-service';

@Component({
  selector: 'app-download-app-links',
  templateUrl: './download-app-links.component.html',
})
export class DownloadAppLinksComponent implements OnInit {
  appleImageSrc: string;
  appleHref: string;
  androidImageSrc: string;
  androidHref: string;
  brand: string;
  is1700: Boolean = false;
  isAltiusLife: Boolean = false;
  isBFFLife: Boolean = false;

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.appleImageSrc = 'assets/img/applinks/appdownload.png';
    this.androidImageSrc = 'assets/img/applinks/googledownload.png';

    this.appleHref = this.userService.appDownloadLinks.apple;
    this.androidHref = this.userService.appDownloadLinks.google;

    this.brand = this.userService.brand;

    switch (this.brand) {
      case '1700':
        this.is1700 = true;
        break;
      case 'AltiusLife':
        this.isAltiusLife = true;
        break;
      case 'BFFLife':
        this.isBFFLife = true;
        break;
      default:
        this.is1700 = true;
        break;
    }
  }
}
