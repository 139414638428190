<app-cms-list-view
  *ngIf="isDisplayingList && isInitialised"
  [dataList]="items"
  [additionalNumericColumns]="additionalNumericColumns"
  [columnList]="columns">
</app-cms-list-view>

<div
  *ngIf="(isAddingItem || isEditingItem) && isInitialised"
  class="padding jumbotron">
  <form
    [formGroup]="itemForm"
    (ngSubmit)="onSaveClick()" >

    <div class="row m-0">
      <div class="col-12">
        <!-- Name -->
        <div class="form-group">
          <label for="name" class="control-label required">
            Name
          </label>
          <input
            type="text"
            class="form-control"
            [ngClass]="displayFieldCss('name')"
            formControlName="name">
          <span class="invalid-feedback">Name is required</span>
        </div>

        <!-- Description -->
        <div class="form-group">
          <label for="description">Description</label>
          <textarea
            name="description"
            id="description"
            class="form-control"
            formControlName="description"
            >
          </textarea>
        </div>

        <!-- Banner -->
        <div class="form-group">
          <label for="banner" class="control-label required">
            Banner
          </label>
          <div class="input-group">
            <input
              type="text"
              name="banner" id="banner"
              class="form-control"
              [ngClass]="displayFieldCss('banner')"
              placeholder="Choose the banner"
              formControlName="banner"
              readonly />
            <div class="input-group-prepend">
              <button
                type="button"
                class="input-group-text btn btn-primary"
                (click)="onBannerClick()">
                  Browse
              </button>
            </div>
            <span class="invalid-feedback">Banner is required</span>
          </div>
        </div>

        <!-- Logo -->
        <div class="form-group">
            <label for="logo" class="control-label required">
              Logo
            </label>
            <div class="input-group">
              <input
                type="text"
                name="logo" id="logo"
                class="form-control"
                [ngClass]="displayFieldCss('logo')"
                placeholder="Choose the logo"
                formControlName="logo"
                readonly />
              <div class="input-group-prepend">
                <button
                  type="button"
                  class="input-group-text btn btn-primary"
                  (click)="onLogoClick()">
                    Browse
                </button>
              </div>
              <span class="invalid-feedback">Logo is required</span>
            </div>
          </div>

        <div class="form-group" *ngIf="canSelectCompany">
            <app-select-company [allCompanies]="allCompanies" [selectedCompanyId]="itemForm.get('companyId').value"
              [label]="'Company'" [placeholder]="'All (default)'" (companyIdSelected)="onCompanySelected($event)"></app-select-company>
          </div>

        <!-- Excluded Companies -->
        <div *ngIf="isAllCompanies()" class="form-group">
          <app-select-companies [allCompanies]="allCompanies" [selectedCompanyIds]="excludedCompanyIds" [label]="'Excluded Companies'"
            [placeholder]="'Select Company to exclude'" (companyIdsSelected)="excludedCompanyIdsSelected($event)"></app-select-companies>
        </div>

        <!-- Status -->
        <div class="form-group">
          <label for="status" class="control-label required">
            Status
          </label>
          <select
            class="form-control custom-select"
            [ngClass]="displayFieldCss('status')"
            formControlName="status">
            <option
              *ngFor="let item of groupStatuses"
              [value]="item.id">{{item.name}}</option>
          </select>
          <span class="invalid-feedback">Status is required</span>
        </div>

      </div>
    </div>

    <!-- Buttons -->
    <br />
    <div class="row">
      <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
        <button
          type="button"
          class="btn btn-outline-primary w-100"
          (click)="onBackClick()">
          <i class="fa fa-undo mr-2" aria-hidden="true"></i> BACK
        </button>
      </div>

      <div *ngIf="isEditingItem"
        class="col-4">
        <button
          type="button"
          class="btn btn-outline-primary w-100"
          [disabled]="!canRespondToButtons"
          (click)="onDeleteClick()">
          <i class="fa fa-trash mr-2" aria-hidden="true"></i> DELETE
        </button>
      </div>

      <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
        <button
          type="submit"
          class="btn btn-primary float-right w-100"
          [disabled]="!canRespondToButtons">
          <i class="fa fa-floppy-o mr-2" aria-hidden="true"></i> SAVE
        </button>
      </div>
    </div>
  </form>
</div>
