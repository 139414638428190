import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../../services/menu-service';
import { IMenuConfiguration } from '../../_models/menu-configuration';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UtilService } from '../../../services/util-service';
import { MENU_TYPES } from '../../_shared/enums';

@Component({
  selector: 'app-menu-configuration-management',
  templateUrl: './menu-configuration-management.component.html',
  styleUrls: ['./menu-configuration-management.component.scss'],
})
export class MenuConfigurationManagementComponent implements OnInit {
  isInitialised: boolean;
  canRespondToButtons: boolean;
  configuration: IMenuConfiguration[];
  valid: boolean = true;

  constructor(
    private menuService: MenuService,
    private utilService: UtilService,
  ) {
  }

  ngOnInit() {
    this.isInitialised = false;
    this.menuService.getConfiguration().subscribe((data: IMenuConfiguration[]) => {
      this.configuration = data;
      this.isInitialised = true;
    });
  }

  // drag & drop doc: https://material.angular.io/cdk/drag-drop/overview
  // drag & drop examples: https://material.angular.io/cdk/drag-drop/examples
  onItemDropped(list, event: CdkDragDrop<IMenuConfiguration[]>) {
    moveItemInArray(list, event.previousIndex, event.currentIndex);
  }

  onItemEdit(item) {
    let url = '';
    switch (item.type) {
      case MENU_TYPES.CATEGORY:
        url = `/cms/category/${item.sourceId}`;
        break;
      case MENU_TYPES.FEATURE:
        url = `/cms/feature/${item.sourceId}`;
        break;
    }
    window.open(url, '_blank');
  }

  countActiveLevel3Items(array) {
    return array.filter(item => item.active).length;
  }

  onSaveClicked() {
    this.canRespondToButtons = false;
    this.valid = true;
    const level2InvalidNames = [];
    // re-calc order indices
    this.configuration.map(level1 => {
      let level2OrderIndex = 0;
      level1.children.map(level2 => {
        level2.orderIndex = level2OrderIndex;
        level2OrderIndex++;
        const activeLevel3Items = this.countActiveLevel3Items(level2.children);
        if (activeLevel3Items > 3) {
          this.valid = false;
          level2.valid = false;
          level2InvalidNames.push(level2.name);
        } else {
          level2.valid = true;
        }
        let level3OrderIndex = 0;
        level2.children.map(level3 => {
          level3.orderIndex = level3OrderIndex;
          level3OrderIndex++;
        });
      });
    });

    if (this.valid) {
      this.menuService.updateConfiguration(this.configuration).subscribe(() => {
          this.canRespondToButtons = true;
          this.utilService.showToastSuccess('Menu Configuration Updated', 'Success');
        },
        (error) => {
          const errorMsg = `Error while saving - ${error}`;
          this.utilService.showToastError(errorMsg);
          this.canRespondToButtons = true;
        });
    } else {
      this.canRespondToButtons = true;
      let errorMsg = '';
      level2InvalidNames.forEach(name => {
        errorMsg += `${name} should not have more than 3 categories.<br/>`;
      });
      this.utilService.showToastError(errorMsg, 'Error', true);
    }
  }
}
