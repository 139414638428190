import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-copy',
  templateUrl: './input-copy.component.html',
  styleUrls: ['./input-copy.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputCopyComponent),
    },
  ],
})
export class InputCopyComponent implements OnInit, ControlValueAccessor {
  @Input() form: FormGroup;
  @Input() formControlName: string;
  @Input() additionalClass: object;
  @Input() forceLowercase: boolean = false;
  @Input() prefixCopy: string = '';
  @Input() isDisabled: boolean = false;

  constructor() {}

  ngOnInit() {}

  // copyInputValueToClipboard(input, tooltip) {
  //   const originalValue = input.value;
  //   input.value = this.prefixCopy + originalValue;
  //   input.select();
  //   input.setSelectionRange(0, 99999);
  //   // document.execCommand('copy');
  //   navigator.clipboard.writeText(this.prefixCopy + originalValue);
  //   input.value = originalValue;
  //   tooltip.style.visibility = 'visible';
  //   setTimeout(() => {
  //     tooltip.style.visibility = 'hidden';
  //   }, 2000);
  // }

  fallbackCopyTextToClipboard(input) {
    const text = input.value;
    var textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.log('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }

  async copyInputValueToClipboard(input, tooltip) {
    input.select();
    input.setSelectionRange(0, 0);
    if (!window.navigator || !window.navigator['clipboard']) {
      this.fallbackCopyTextToClipboard(this.prefixCopy + input.value);
      tooltip.style.visibility = 'visible';
      setTimeout(() => {
        tooltip.style.visibility = 'hidden';
      }, 2000);
      return;
    }
    await window.navigator['clipboard'].writeText(this.prefixCopy + input.value).catch((err) => {
      console.log('Async: Could not copy text: ', err);
    });
    tooltip.style.visibility = 'visible';
    setTimeout(() => {
      tooltip.style.visibility = 'hidden';
    }, 2000);
  }

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {}
}
