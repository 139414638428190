<div class="row" *ngIf="canShowGroup">
  <div *ngIf="group" class="col-12">
    <img class="group-detail-image" [src]="getBannerPhoto(group.banner)" />
    <div class="group-detail-logo-wrapper">
      <img class="group-detail-logo" [src]="getLogoPhoto(group.logo)" />
    </div>
  </div>

  <div class="col-12 text-center mt-5">
    <h4 class="primaryColor mt-3 mb-0">{{group.name}}</h4>
    <p class="mb-0">
      <b>
        {{members.length | pluralize:'member':'members':'No members'}}
      </b>
    </p>
    <p>{{group.description}}</p>
    <p class="mt-1">
      <a *ngIf="showInfo!=1" class="btn btn-primary btn-sm mr-2" (click)="info()">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        Info
      </a>
      <a *ngIf="showInfo === 1" class="btn btn-primary btn-sm mr-2" (click)="showGroup()">
        Group
      </a>
      <a *ngIf="isMember" class="btn btn-primary btn-sm mr-2" (click)="leaveGroup()">
        <i class="fa fa-sign-out" aria-hidden="true"></i>
        Leave
      </a>
      <a *ngIf="!isMember" class="btn btn-outline-primary btn-sm" (click)="joinGroup($event, group)">
        Join
      </a>
    </p>
  </div>

  <!-- GROUP INFO -->
  <div *ngIf="showInfo==1" class="col-12 mt-2 mb-2">
    <h5>Members</h5>
  </div>
  <div *ngIf="showInfo==1" class="col-12 mt-2">
    <div *ngFor="let member of members" class="memberRow bottomLine m-0">
      <div class="left">
        <div class="avatarDivPost px-2 pt-2">
          <div class="avatarColPost" [ngClass]="getMemberLevelClass(member.level, 'normal-shadow')">
            <img *ngIf="member.active" class="img-avatar" [src]="getUserPicture(member.photo)" onError="this.src='assets/img/profile.png'">
          </div>
        </div>
        <div>
          <p *ngIf="member.active" class="mt-4 pl-3">{{member.username}}</p>
        </div>
      </div>
      <div class="">
        <a *ngIf="member.active" class="btn btn-primary mt-3 pointer" (click)="onViewProfile(member.id)">
          <i class="fa fa-eye mr-1" aria-hidden="true"></i>
          View
        </a>
      </div>
    </div>

  </div>
  <div *ngIf="!members.length" class="col-12 mt-2">
    No members
  </div>
  <div *ngIf="!posts.length && isMember && showInfo !== 1" class="col-12">
    <div class="w-40 card p-2 text-center">
      <p>
        There are currently no posts in this group. Start a conversation!
      </p>
    </div>
  </div>

  <!-- GROUP DETAILS -->
  <div *ngIf="showInfo!=1" class="col-12 mt-2 mb-2">
    <!-- NEW POST -->
    <div *ngIf="isMember" class="card w-40 mt-4 p-2">
      <div class="postRow">
        <div class="avatarDivPost px-2 pt-2">
          <div class="avatarColPost" [ngClass]="getUserProfileLevelClass('normal-shadow')">
            <img class="img-avatar" [src]="userProfilePictureSource" onError="this.src='assets/img/profile.png'">
          </div>
        </div>
        <app-text-area [type]="'post'" [placeholder]="postPlaceholder" [actionButtonText]="actionButtonText" [path]="postImagePath"
          (data)="createNewPost($event)" class="post-input p-2"></app-text-area>
      </div>
    </div>

    <!-- POSTS -->
    <div *ngFor="let post of posts; let postIndex = index" class="card w-40 mt-4 p-2">
      <div class="postRow">
        <div class="avatarDivPost px-2 pt-2">
          <div class="avatarColPost pointer" (click)="onViewProfile(post.userProfileId)" [ngClass]="getPostLevelClass(post.level, 'normal-shadow')">
            <img class="img-avatar" [src]="post.photoSource" onError="this.src='assets/img/profile.png'">
          </div>
        </div>

        <app-text-area *ngIf="isEditingPost(post.id); else readOnlyPost" [type]="'post'" [placeholder]="postPlaceholder"
          [path]="postImagePath" [description]="post.description" [inputImage]="post.image" [actionButtonText]="actionButtonTextOnEdit"
          [inputPreview]="post.preview" (data)="editPost($event)" (editCancelled)="cancel('post')" [fromEdit]="true"
          class="post-input p-2"></app-text-area>

        <ng-template #readOnlyPost>
          <div class="flex-space-between">
            <div class="px-2 pt-2">
              <p>
                <b *ngIf="post.active" class="pointer" (click)="onViewProfile(post.userProfileId)">
                  {{post.username}}
                </b>
                <b *ngIf="!post.active" class="mt-4 font-weight-bold">User</b>
                <label class="time">
                  {{post.updatedAt | timeFromNow}}
                </label>
              </p>
            </div>

            <div class="mr-1">
              <app-flagging [groupId]="groupId" [postId]="post.id" [companyId]="userCompanyId" [itemType]="'Post'"
                [canUserEditItem]="post.isEditable" [canUserDeleteItem]="post.isDeletable" [itemId]="post.id"
                [userProfileId]="post.userProfileId" (deleteItem)="callDeletePost($event)" (editItem)="callEditPost($event)"></app-flagging>
            </div>
          </div>
        </ng-template>
      </div>

      <div *ngIf="!isEditingPost(post.id)">
        <p class="description pl-1 text-justify" style="white-space: pre-wrap;" [innerHtml]="post.displayDescription"></p>
        <img *ngIf="isActiveImage(post)" class="w-100 mb-3" [src]="post.imageSource">
        <div *ngIf="isActivePreview(post) && isPreviewUrlValid(post)" (click)="openUrl(post.preview.url)">
          <a href="{{post.preview.url}}">
            <img class="w-100 mb-3 hyperlinkImage" [src]="post.preview.image">

            <h4 class="hyperlink pl-1">{{post.preview.title}}</h4>
          </a>
          <p class="pl-1 text-justify" style="white-space: pre-wrap;">{{post.preview.description}}</p>
        </div>
      </div>

      <div *ngIf="!isEditingPost(post.id)" class="flex-space-between px-1 pb-2">
        <app-like-indicator [bigger]="false" [liked]="post.liked == 1" [likes]="post.likes" [isLoggedIn]="isLoggedin"
          [primaryColor]="true" (click)="onLikeClicked($event, post, 'post')"></app-like-indicator>
      </div>
      <div class="bottomLinePost"></div>

      <!-- comments -->

      <div *ngFor="let comment of post.displayComments; let i = index" class="mt-2">
        <div *ngIf="isDisplayNumberOfCommentsLabel(post) && i === 0" class="float-right flex">
          <p class="no-wrap mr-2">{{numberOfCommentsLabel(post)}}</p>
          <a *ngIf="isDisplayMoreComments(post)" (click)="displayMorePostComments(post)">
            <p class="no-wrap">View more</p>
          </a>

          <a *ngIf="isDisplayLessComments(post)" (click)="displayLessComments(post)">
            <p class="no-wrap">Show Less</p>
          </a>

        </div>
        <div class="postRow">
          <div class="avatarDivPost px-2 pt-2">
            <div class="avatarColPost pointer" (click)="onViewProfile(comment.userProfileId)" [ngClass]="comment.level ? comment.level :'normal-shadow'">
              <img [src]="comment.photoSource" class="img-avatar" onError="this.src='assets/img/profile.png'">
            </div>
          </div>

          <app-text-area *ngIf="isEditingComment(comment.id); else readOnlyComment" [type]="'comment'"
            [actionButtonText]="actionButtonTextOnEdit" [placeholder]="commentPlaceholder" [path]="postImagePath"
            [description]="comment.description" [inputImage]="comment.image" [fromEdit]="true" [inputPreview]="comment.preview"
            (data)="editComment($event, post)" (editCancelled)="cancel('comment')" class="post-input p-2"></app-text-area>

          <ng-template #readOnlyComment>
            <div class="flex-space-between">
              <div class="px-2 pt-2">
                <p>
                  <b *ngIf="comment.active" class="pointer" (click)="onViewProfile(comment.userProfileId)">
                    {{comment.username}}
                  </b>
                  <b *ngIf="!comment.active" class="mt-4 font-weight-bold">User</b>
                  <label class="time">{{ comment.updatedAt | timeFromNow }}</label>
                </p>
              </div>
            </div>

            <div class="mr-1 mb-2 column-bottom">
              <div class="flex">
                <div *ngIf="!isEditingComment(comment.id)" class="mr-3 pt-1 no-wrap">
                  <app-like-indicator [bigger]="false" [liked]="comment.liked == 1" [likes]="comment.likes" [isLoggedIn]="isLoggedin"
                    [primaryColor]="true" (click)="onLikeClicked($event, comment, 'comment')"></app-like-indicator>
                </div>
                <app-flagging [groupId]="groupId" [commentId]="comment.id" [companyId]="userCompanyId" [itemType]="'Comment'"
                  [canUserEditItem]="comment.isEditable" [canUserDeleteItem]="comment.isDeletable" [itemId]="comment.id"
                  [userProfileId]="comment.userProfileId" [itemIndex]="postIndex" (deleteItem)="callDeleteComment($event)"
                  (editItem)="callEditComment($event)"></app-flagging>
              </div>
            </div>
          </ng-template>
        </div>
        <div *ngIf="!isEditingComment(comment.id)">
          <p class="description comment-color pl-1 text-justify" style="white-space: pre-wrap;" [innerHtml]="comment.displayDescription"></p>
          <img *ngIf="isActiveImage(comment)" class="w-100 mb-3" [src]="comment.imageSource">
          <div *ngIf="isActivePreview(comment) && isPreviewUrlValid(comment)" (click)="openUrl(comment.preview.url)">
            <a href="{{comment.preview.url}}">
              <img class="w-100 mb-3 hyperlinkImage" [src]="comment.preview.image">
              <h4 class="hyperlink pl-1">{{comment.preview.title}}</h4>
            </a>
            <p class="pl-1 text-justify" style="white-space: pre-wrap;">{{comment.preview.description}}</p>
          </div>
        </div>
      </div>

      <!-- NEW Comment -->
      <div *ngIf="isMember" class="postRow mt-2">
        <div class="avatarDivPost px-2 pt-2">
          <div class="avatarColPost" [ngClass]="getUserProfileLevelClass('normal-shadow')">
            <img class="img-avatar" [src]="userProfilePictureSource" onError="this.src='assets/img/profile.png'">
          </div>
        </div>
        <app-text-area [type]="'comment'" [placeholder]="commentPlaceholder" [actionButtonText]="actionButtonText"
          [path]="postImagePath" (data)="createComment($event, post, postIndex)" class="post-input p-2"></app-text-area>

        <div *ngIf="isEmptyCommentError" class="text-danger">
          Please enter a valid comment
        </div>
      </div>
    </div>
  </div>

  <button *ngIf="showInfo!==1" [hidden]="isNoMorePosts" (click)="viewMore()" class="btn btn-primary center-button mx-auto mt-4 mb-4">
    View more
  </button>
  <div *ngIf="!posts.length && !isMember && showInfo !== 1" class="col-12">
    <div class="w-40 card p-2 text-center">
      <p>
        There are currently no posts in this group. Join the group and start a conversation!
      </p>
    </div>
  </div>
</div>
