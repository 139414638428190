import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeatureService } from '../../services/feature-service';
import { CompanyService } from '../../services/company-service';
import { CategoryService } from '../../services/category-service';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {
  constructor(
    public activatedRoute: ActivatedRoute,
    private companyService: CompanyService,
    private categoryService: CategoryService,
    private featureService: FeatureService) { }

  ngOnInit() {
    this.refreshCaches();
   }

   private refreshCaches() {
     this.companyService.refreshCache();
     this.featureService.refreshCache();
     this.categoryService.refreshCache();
   }
}
