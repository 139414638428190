import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.scss']
})
export class EmailInputComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() inputcss: any;
  @Output() emailInputted = new EventEmitter<string>();

  errorMessage: string;

  constructor() { }

  ngOnInit() {
  }

  onInputEmail(value) {
    this.emailInputted.emit(value);
  }

  get email() { return this.parentForm.get('email'); }

  isEmailRegisteredError() {
    return this.email.errors &&
      this.email.errors.isEmailRegistered &&
      this.email.touched;
  }

  isEmailFormatInvalidError() {
    return this.email.errors &&
      this.email.errors.pattern &&
      this.email.touched;
  }

  isEmailRequiredError() {
    return this.email.errors &&
      this.email.errors.required &&
      this.email.touched;
  }

  isEmailInputError() {
    if (this.email.errors && this.email.touched) {
      if (this.isEmailRegisteredError()) {
        this.errorMessage = 'Email already registered.';
      } else if (this.isEmailFormatInvalidError()) {
        this.errorMessage = 'Email format invalid.';
      } else if (this.isEmailRequiredError()) {
        this.errorMessage = 'Email required.';
      }
      return true;
    }
  }

}
