<div *ngIf="activatedRoute.children.length === 0">
  <h2 class="mobile-heading" *ngIf="canAddGroup()">
    <a class="btn btn-link pr-0 pl-0" (click)="addGroup()">
      <i class="fa fa-plus-circle" aria-hidden="true"></i>
      NEW GROUP
    </a>
  </h2>
  <h4 class="mobile-heading">Groups</h4>
  <ngb-tabset #tabs>
    <ngb-tab title="My Groups" id="yours">
      <ng-template ngbTabContent>
        <div class="bg-tabset p-2">
          <div *ngIf="userGroups">
            <div class="row align-items-stretch mt-2 mb-2">
              <div *ngFor="let group of userGroups; let i = index" class="col-12 col-sm-6 d-flex align-items-stretch">
                <app-group-list-card class="groupCard" [newPostCount]="group.newPostCount" [isMember]="true" [group]="group"
                  (view)="goToGroup(group.id)"></app-group-list-card>
              </div>
            </div>
          </div>
          <div *ngIf="isJoinedNoGroups()">
            <p>You do not currently belong to any groups. Explore the groups tab to find a group and start posting! You
              will
              receive notifications for activity in the groups you join</p>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="Other Groups" id="all">
      <ng-template ngbTabContent>
        <div class="bg-tabset p-2">
          <div *ngIf="notUserGroups">
            <div class="row align-items-stretch mt-2 mb-2">
              <div *ngFor="let group of notUserGroups; let i = index" class="col-12 col-sm-6 d-flex align-items-stretch">
                <app-group-list-card class="groupCard" [newPostCount]="group.newPostCount" [isMember]="false" [group]="group"
                  (view)="goToGroup(group.id)" (join)="joinGroup(group, i)"></app-group-list-card>

              </div>
            </div>
            <div *ngIf="isJoinedAllGroups()" class="p-2">
              <p>There are currently no groups for you to join</p>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>
<router-outlet (deactivate)="loadGroups()"></router-outlet>
