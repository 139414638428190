import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateService } from '../../services/date.service';
import { UtilService } from '../../services/util-service';
import { ProgramService } from '../../services/program-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IUserProgram } from '../../app/_models/user-program';
import { UserService } from '../../services/user-service';

@Component({
  selector: 'app-bootstrap-modal',
  templateUrl: './bootstrap-modal.component.html',
  styleUrls: ['./bootstrap-modal.component.scss']
})
export class BootstrapModalComponent implements OnInit {
  @Input()
  title;
  @Input()
  message;
  @Input()
  value1;
  @Input()
  value2;
  @Input()
  confirmButtonLabel;
  @Input()
  closeButtonLabel;
  @Input()
  redirectPage;
  @Input()
  date;
  @Input()
  radios;
  @Input()
  messageWarning;
  @Input()
  endDate;
  @Input()
  singleButton;

  @Input()
  badgeName;
  @Input()
  badgeImage;
  @Input()
  badgeDescription;
  @Input()
  nextProgram;

  todayIsMax = false;

  @Input()
  item;
  isContent = false;

  dateNow;
  error = 0;

  // for programs
  @Input()
  toggles;
  @Input()
  toggleMessage;
  toggleForm: FormGroup;
  programDateInfoText;
  @Input()
  programData;
  fixedStartDayOfWeek = false;
  @Input()
  isProgram;

  // if it's zero there is no fixed start day (and therefore no [markDisabled] directive on html)
  startDayOfWeek = 0;

  @Input()
  existingSecondaryEmail;
  showSecondaryEmailInput = false;
  showSecondaryEmailButton = false;
  secondaryEmailFormControl = new FormControl('', Validators.email);
  secondaryEmail;
  secondaryEmailButtonText = 'Add secondary email';
  emailSelection = new FormControl('primary');

  constructor(
    private activeModal: NgbActiveModal,
    public router: Router,
    private fb: FormBuilder,
    private dateService: DateService,
    private utilService: UtilService,
    private modalService: NgbModal,
    private programService: ProgramService,
    private userService: UserService
  ) { }

  ngOnInit() {
    if (this.item) {
      this.isContent = true;
    }
    if (this.existingSecondaryEmail) {
      this.secondaryEmail = this.existingSecondaryEmail;
    } else {
      this.showSecondaryEmailButton = true;
    }

    if (this.date && this.date.value && this.date.todayIsMax) {
      // for TMH
      this.dateNow = this.date.value;
      this.todayIsMax = true;
    } else if (this.date && this.date.value && !this.date.todayIsMax) {
      // for joining program
      this.dateNow = this.date.value;
      if (this.date.fixedStartDayOfWeek) {
        this.fixedStartDayOfWeek = true;
        this.startDayOfWeek = this.date.startDayOfWeek;
      }
    }
    if (this.toggles) {
      this.populateToggleControls();
    }
  }

  // the [markDisabled] directive will not accept a dynamic function
  isDisabled1(date, day) {
    const d = new Date(date.year, date.month - 1, date.day);
    return date.day === 13 || d.getDay() !== 1;
  }
  isDisabled2(date, day) {
    const d = new Date(date.year, date.month - 1, date.day);
    return date.day === 13 || d.getDay() !== 2;
  }
  isDisabled3(date, day) {
    const d = new Date(date.year, date.month - 1, date.day);
    return date.day === 13 || d.getDay() !== 3;
  }
  isDisabled4(date, day) {
    const d = new Date(date.year, date.month - 1, date.day);
    return date.day === 13 || d.getDay() !== 4;
  }
  isDisabled5(date, day) {
    const d = new Date(date.year, date.month - 1, date.day);
    return date.day === 13 || d.getDay() !== 5;
  }
  isDisabled6(date, day) {
    const d = new Date(date.year, date.month - 1, date.day);
    return date.day === 13 || d.getDay() !== 6;
  }
  isDisabled7(date, day) {
    const d = new Date(date.year, date.month - 1, date.day);
    return date.day === 13 || d.getDay() !== 7;
  }

  populateToggleControls() {
    this.toggleForm = this.fb.group({
      togglesOn: this.createToggleForm(this.toggles)
    });
  }

  get togglesOn() {
    return this.toggleForm.get('togglesOn');
  }

  createToggleForm(toggles) {
    const arr = toggles.map(() => {
      return this.fb.control(true);
    });
    return this.fb.array(arr);
  }

  onNoClick(): void {
    this.activeModal.close();
  }

  save(): void {
    if (this.redirectPage === true) {
      this.activeModal.close(true);
    }
    // change date object to string
    if (this.date && this.date.value) {
      this.date.value = moment([
        this.date.value.year,
        this.date.value.month - 1,
        this.date.value.day
      ]).format('YYYY-MM-DD');
    }
    if (!(this.value1 || this.date || this.radios) && !this.toggleForm) {
      // ^ if there's a toggle form you don't need these other values bc it's for the program joining modal
      this.activeModal.close(true);
    } else if (this.radios && (this.radios[0].disabled || this.radios[1].disabled)) {
      this.activeModal.close(true);
    } else if (!this.radios) {
      this.error = 0;
      if (
        this.error === 0 &&
        this.value1 &&
        this.value1.required === true &&
        (this.value1.value === '' || this.value1.value === 0)
      ) {
        this.error = 1;
      }
      if (
        this.error === 0 &&
        this.value2 &&
        this.value2.required === true &&
        (this.value2.value === '' || this.value2.value === 0)
      ) {
        this.error = 2;
      }
      if (
        this.error === 0 &&
        this.date &&
        this.date.required === true &&
        !moment(this.date.value, 'YYYY-MM-DD', true).isValid()
      ) {
        this.error = 3;
      }
      if (this.error === 0) {
        this.activeModal.close(this);
      }
    } else {
      this.activeModal.close(this);
    }
  }

  close() {
    this.activeModal.dismiss();
  }

  changeProgramDateInfo(event) {
    const momentDate = new Date(event.year, event.month - 1, event.day);
    const dateNowConverted = this.dateService.convertNgbDateStruct(this.dateNow);
    let programStartDate;
    let daysDiff;
    let dayOrWeekNo;
    let unit;

    if (this.date.startDate) {
      programStartDate = momentDate;
    } else if (this.date.endDate) {
      programStartDate = moment(momentDate)
        .subtract(this.programData.programDuration, 'days')
        .format();
    }

    if (this.dateService.isToday(programStartDate)) {
      //  START DATE IS TODAY
      this.programDateInfoText = `You will start the ${this.programData.programDayOrWeekNo} ${
        this.programData.programUnit
        } program today`;
    } else if (this.dateService.isBeforeToday(programStartDate)) {
      // START DATE IS IN THE PAST
      daysDiff = this.dateService.daysDifference(programStartDate);
      if (daysDiff >= 7) {
        dayOrWeekNo = Math.floor(daysDiff / 7);
        unit = dayOrWeekNo > 1 ? 'weeks' : 'week';
      } else {
        dayOrWeekNo = daysDiff;
        unit = dayOrWeekNo > 1 ? 'days' : 'day';
      }
      this.programDateInfoText = `You will be ${dayOrWeekNo} ${unit} into a ${
        this.programData.programDayOrWeekNo
        } ${this.programData.programUnit} program`;
    } else if (this.dateService.isAfterToday(programStartDate)) {
      // START DATE IS IN THE FUTURE
      daysDiff = this.dateService.daysDifference(dateNowConverted, programStartDate);
      if (daysDiff >= 7) {
        dayOrWeekNo = Math.floor(daysDiff / 7);
        unit = dayOrWeekNo > 1 ? 'weeks' : 'week';
      } else {
        dayOrWeekNo = daysDiff;
        unit = dayOrWeekNo > 1 ? 'days' : 'day';
      }
      this.programDateInfoText = `You will start the ${this.programData.programDayOrWeekNo} ${
        this.programData.programUnit
        } program in ${dayOrWeekNo} ${unit}`;
    }
  }

  openProgram(program) {
    this.activeModal.close({ action: 'open', program: program });
  }

  joinProgram(program) {
    this.activeModal.close({ action: 'join', program: program });
  }

  secondaryEmailInput() {
    if (this.secondaryEmailButtonText === 'Add secondary email') {
      this.showSecondaryEmailInput = true;
      this.secondaryEmailButtonText = 'Add';
    } else {
      this.showSecondaryEmailButton = false;
      this.userService
        .updateUserProfile({ secondaryEmail: this.secondaryEmailFormControl.value });
          this.showSecondaryEmailInput = false;
          this.showSecondaryEmailButton = false;
          this.secondaryEmail = this.secondaryEmailFormControl.value;
    }
  }

  canJoinProgram(program: IUserProgram) {
    return this.programService.canUserJoinProgram(program);
  }
}
