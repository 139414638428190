<div [hidden]="activatedRoute.children.length > 0"
    class="row" >
  <div [class]="canToggleLanguagePreference() ? 'col-8' : 'col-12'">
    <app-search
      [data]="totalContent"
      [loading]="loading"
      [limit]="limit"
      [feature]="featureId"
      [category]="categoryId"
      (isSearch)="isSearch($event)"
      [preferenceLanguage]="preferenceLanguage"
      (newContent)="getContentSearch($event)"
      (noMoreResults)="toggleShowMore($event)">
    </app-search>
  </div>
  <div *ngIf="canToggleLanguagePreference()" class="col-4">
    <app-language-toggle-button (languagesToggleEvent)='displayArticleBySelectedlanguage($event)'></app-language-toggle-button>
  </div>
</div>
<div *ngIf="activatedRoute.children.length === 0"
  class="row">
  <div class="col-12">
    <div *ngIf="totalContent.length > 0"
      class="row">
      <div *ngFor="let c of totalContent"
        class="col-12 col-sm-6 col-md-6 col-lg-6 d-flex">
        <a class="contentAnchorTag"
          [routerLink]="generateGoToDetailLink(c)">
          <app-content-list-card
            class="card-width"
            [item]="c"
            [isLoggedIn]="isLoggedIn"
          ></app-content-list-card>
        </a>
      </div>
    </div>

    <a *ngIf="canViewMore()"
      (click)="viewMore()"
      class="btn btn-primary center-button col-12 col-sm-3  mx-auto mt-4 ml-2 mb-4">
      View more...
    </a>
  </div>

  <br />
  <div *ngIf="isNoResultsFound()">
    <p class="ml-3">No results found</p>
  </div>
  <div *ngIf="noMoreResults" class="center-button">
    <p class="pt-4">End of results</p>
  </div>
</div>
<router-outlet></router-outlet>
<br />
