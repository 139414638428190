import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../services/user-service';
import { UtilService } from '../../../services/util-service';
import { GroupService } from '../../../services/group-service';
import { LogService } from '../../../services/log.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { ProgramService } from '../../../services/program-service';
import * as _ from 'lodash';

import { PROGRAMS } from '../../_shared/enums';
import { IUserProgram } from '../../_models/user-program';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent implements OnInit {
  @ViewChild('tabs') tabs: NgbTabset;
  userProgramSubscription: Subscription;

  groups: any[] = [];
  notUserGroups: any[] = [];
  userGroups: any[] = [];
  userPrograms: any[] = [];

  private isTabSetInitiallyToOtherGroups = false;

  constructor(
    public userService: UserService,
    public utilService: UtilService,
    public groupService: GroupService,
    private logService: LogService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private programService: ProgramService,
  ) {
  }

  ngOnInit() {
    this.programService.getUserPrograms().subscribe();
    this.userProgramSubscription = this.programService.userPrograms$
      .subscribe((userPrograms: IUserProgram[]) => {
        this.userPrograms = userPrograms;
      });
    this.loadGroups();
    this.logService.logGroups();

    this.invokeAddGroupIfNewGroupRoute();
  }

  ngOnDestroy() {
    if (this.userProgramSubscription) {
      this.userProgramSubscription.unsubscribe();
    }
  }

  private invokeAddGroupIfNewGroupRoute() {
    this.activatedRoute.data.subscribe(data => {
      if (data.isNew) {
        this.addGroup();
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['other']) {
        this.isTabSetInitiallyToOtherGroups = true;
      }
    });
  }

  getGroupLogo(fileName) {
    return this.userService.getGroupsLogo(fileName);
  }

  loadGroups() {
    this.groupService.getUserGroups().subscribe((res) => {
      if (res.success === false) {
        const msge = 'You need to verify your account to access this section';
        this.utilService.showToastWarning(msge);
      } else {
        const JoinSleepProgramActive = _.findIndex(this.userPrograms, { id: PROGRAMS.SLEEP_PROGRAM_ID, activeUserProgram: true });
        const JoinSleepProgram = _.findIndex(this.userPrograms, { id: PROGRAMS.SLEEP_PROGRAM_ID });
        if (JoinSleepProgramActive != -1) {
          console.log(this.userPrograms[JoinSleepProgramActive].groupIds);
        }
        if (JoinSleepProgramActive == -1 && JoinSleepProgram != -1) {
          this.userPrograms[JoinSleepProgram].groupIds.map((group) => {
            const SleepPRogramGroup = _.findIndex(res, { id: group });
            res.splice(SleepPRogramGroup, 1);
          });
        }
        this.userGroups = res.filter(g => g.groupMember);
        this.notUserGroups = res.filter(g => !g.groupMember);
        this.setTab();
      }
    });
  }

  private setTab() {
    if (this.isTabSetInitiallyToOtherGroups
      || !this.userGroups.length) {
      this.selectOtherGroups();
    } else {
      this.selectMyGroups();
    }
  }

  private selectMyGroups() {
    if (!this.tabs) {
      return;
    }

    this.tabs.select('yours');
  }

  private selectOtherGroups() {
    if (!this.tabs) {
      return;
    }

    this.tabs.select('all');
  }

  goToGroup(groupId) {
    this.router.navigate([`/share/groups`, groupId]);
  }

  joinGroup(group, index) {
    this.groupService
      .joinGroup(group.id)
      .subscribe(resData => {
        group.membersCount++;
        group.isMember = true;
        this.userGroups.push(group);
        this.notUserGroups.splice(index, 1);
        this.utilService.showAchievedBadgesSequentially(resData.achievedBadges);
      });
  }

  addGroup() {
    this.router.navigateByUrl('/share/groups/new');
    // const addModal = this.modalService.open(AddGroupComponent);
    // addModal.componentInstance.name = '';
    // addModal.componentInstance.description = '';

    // addModal.result.then((result) => {
    //   this.groupService.createGroup(result).subscribe(() => {
    //     this.loadGroups();
    //   });
    // });
  }

  isJoinedAllGroups(): boolean {
    return this.notUserGroups.length === 0;
  }

  isJoinedNoGroups(): boolean {
    return this.userGroups.length === 0;
  }

  canAddGroup(): boolean {
    return this.userService.isCoordinatorUser()
      || this.userService.isSuperAdminUser();
  }
}
