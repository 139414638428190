<div *ngFor="let group of subFilterCards; let i = index">
  <div *ngIf="defaultColumns[i] === 3">
    <h3 *ngIf="drivers" class="primaryColor">{{drivers[i] | uppercase}}</h3>
    <div class="subFilterCards card-deck">
      <div *ngFor="let card of subFilterCards[i]" class="card card-3" #cardId (click)="openPage(card.route, features[i], card.category, card.feature)">
        <img *ngIf="card.img" class="card-img" [src]="card.img" alt="Card image" />
        <div *ngIf="card.img" class="black-overlay"></div>
        <div *ngIf="card.img" class="card-img-overlay d-flex flex-row align-items-center">
          <h1 class="card-title text-white" id="menu-card-title">{{card.title | uppercase}}</h1>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="defaultColumns[i] === 4">
    <h3 *ngIf="drivers" class="primaryColor">{{drivers[i] | uppercase}}</h3>
    <div class="subFilterCards card-deck">
      <div *ngFor="let card of subFilterCards[i]" class="card card-4" #cardId (click)="openPage(card.route, features[i], card.category)">
        <img class="card-img" [src]="card.img" alt="Card image" />
        <div class="black-overlay"></div>
        <div class="card-img-overlay d-flex flex-row align-items-center">
          <h1 class="card-title text-white" id="menu-card-title">{{card.title | uppercase}}</h1>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="defaultColumns[i] === 1">
    <hr *ngIf="defaultColumns[i] === 1 && discover" class="primaryColor mx-3 pb-3" />
    <div class="subFilterCards card-deck">
      <div *ngFor="let card of subFilterCards[i]" [class]="discover ? 'card card-12 mb-3' : 'card card-12 mb-3'" #cardId (click)="openPage(card.route, features[i],
        card.category)">
        <img class="card-img" [src]="card.img" alt="Card image" />
        <div class="black-overlay mb-3"></div>
        <div class="card-img-overlay d-flex flex-row align-items-center">
          <h1 class="card-title text-white" id="menu-card-title" style="font-size: 75%">{{card.title | uppercase}}</h1>
        </div>
      </div>
    </div>
  </div>
</div>
