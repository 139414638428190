<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="onCloseClick()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form
  [formGroup]="itemForm"
  (ngSubmit)="onSaveClick()">
  <div class="modal-body">
  <div class="row">
    <div class="col-12">
    <select
      class="form-control"
      size="10"
      formControlName="selectedItemControl"
    >
    <option
      *ngFor="let item of items"
      [ngValue]="item"
      [disabled]="isAddingItem || isEditingItem"
      (click)="onClickItem(item)">
      {{item.ingredientName}}
    </option>
    </select>
  </div>
  </div>
  <br/>
  <div class="row">
    <div class="col-12">
      <label class="control-label required">Ingredient Name</label>
      <input
        #editableFocus
        class="form-control"
        [ngClass]="displayFieldCss('ingredientNameControl')"
        type="text"
        [readonly]="isReadOnly()"
        formControlName="ingredientNameControl" />
        <span class="invalid-feedback">Ingredient Name is required</span>
    </div>
  </div>

  <br />
  <div class="custom-modal-footer">
  <div class="row">
    <button
      type="button"
      class="col-4 btn"
      [ngClass]="isAddingItem ? 'btn-primary' : 'btn-outline-primary'"
      (click)="onAddClick()"
      [disabled]="!canAdd()">Add</button>
    <button
      type="button"
      class="col-4 btn"
      [ngClass]="isEditingItem ? 'btn-primary' : 'btn-outline-primary'"
      (click)="onEditClick()"
      [disabled]="!canEdit()">Edit</button>
    <button
      type="button"
      class="col-4 btn btn-outline-primary"
      (click)="onDeleteClick()"
      [disabled]="!canDelete()">Delete</button>
  </div>

  <div class="row">
      <button type="button" class="col-4 btn btn-outline-primary" (click)="onCancelClick()" [disabled]="!canCancel()">Cancel</button>
      <button type="button" class="col-4 btn btn-outline-primary" (click)="onSaveClick()" [disabled]="!canSave()">Save</button>
      <button type="button" class="col-4 btn btn-primary" (click)="onCloseClick()" >Close</button>
    </div>
  </div>
</div>

</form>
