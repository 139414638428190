<h4 class="mobile-heading">Leaderboard</h4>

<div class="w-100 text-center">
  <p>Your rank:</p>
  <h2 *ngIf="getSelectedRangeItems().isItemsLoaded"
    class="primaryColor">
    {{ getSelectedRangeItems().myPosition | ordinal }} of {{ getSelectedRangeItems().itemsCount}}
  </h2>
</div>

<ngb-tabset #tabset class="col-12 mt-5">
    <ngb-tab title="Everyone" id="everyone">
      <ng-template ngbTabContent>
        <app-leaderboard-list *ngIf="allItems.isItemsLoaded"
          [leaderboardItems]="allItems.displayItems"
          [canViewMore]="allItems.canViewMore"
          (viewMore)="onViewMoreAllItems()"
          (viewItem)="onViewItem($event)"
        ></app-leaderboard-list>
      </ng-template>
    </ngb-tab>

    <ngb-tab title="Friends" id="friends">
      <ng-template ngbTabContent>
        <app-leaderboard-list *ngIf="friendsItems.isItemsLoaded"
          [leaderboardItems]="friendsItems.displayItems"
          [canViewMore]="friendsItems.canViewMore"
          (viewMore)="onViewMoreFriends()"
          (viewItem)="onViewItem($event)"
        ></app-leaderboard-list>
      </ng-template>
    </ngb-tab>
</ngb-tabset>
