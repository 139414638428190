<div *ngIf="isDisplayingList">
  <app-date-selector
    [inputStartDate]="startDate"
    [inputEndDate]="endDate"
    [inputChallengeId]="challengeId"
    [canSelectChallenge]="canSelectChallenge"
    (dateQuery)="onDateRangeSelected($event)">
  </app-date-selector>
</div>

<div *ngIf="isDisplayingList">
  <div class="table-responsive shadow-lg">
    <table class="table table-bordered table-striped table-hover">
      <thead>
        <tr>
          <th *ngFor="let col of columnList; let i = index;" (click)="onColClick(i)" class="text-uppercase hand">
            <i *ngIf="isAscendingSortColumn(i)" class="fa fa-caret-up" aria-hidden="true"></i>
            <i *ngIf="isDescendingSortColumn(i)" class="fa fa-caret-down" aria-hidden="true"></i>
            {{col}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of filteredList | paginate: { itemsPerPage: 5, currentPage: currentPage }; let i = index" class="hand">
          <td (click)="goToDetails(item.teamId)">
            {{item.teamId}}
          </td>
          <td (click)="goToDetails(item.teamId)">
            {{item.teamName}}
          </td>
          <td (click)="goToDetails(item.teamId)">
            {{item.noOfUsersInTeams}}
          </td>
          <td (click)="goToDetails(item.teamId)">
            {{item.totalStepsByTeams}}
          </td>
        </tr>       
      </tbody>
    </table>
    <div class="cms-pagination" *ngIf="filteredList.length==0">No records</div>
  </div>
  <div class="cms-pagination">
    <pagination-controls autoHide="true" (pageChange)="currentPage = $event"></pagination-controls>
  </div>
</div>

<div *ngIf="isDisplayingDetailsList">
  <div class="col-4 col-sm-3 text-left pr-0 mb-2">
    <button type="button" class="btn btn-primary mr-3"
      (click)="goToBack()">
      <i class="fa fa-arrow-left" aria-hidden="true"></i>
    </button>
  </div>
  <div class="table-responsive shadow-lg">
    <table class="table table-bordered table-striped table-hover">
      <thead>
        <tr>
          <th *ngFor="let col of detailsColumnList; let i = index;" (click)="onColClick(i)" class="text-uppercase hand">
            <i *ngIf="isAscendingSortColumn(i)" class="fa fa-caret-up" aria-hidden="true"></i>
            <i *ngIf="isDescendingSortColumn(i)" class="fa fa-caret-down" aria-hidden="true"></i>
            {{col}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of detailsFilteredList" class="hand">
          <td>
            <app-avatar
            [photoSrc]="item.photoSrc"
            [levelSrc]="item.levelSrc"
            (selectAvatar)="onViewProfile(item.userProfileId)"
          ></app-avatar>
          </td>
          <td>
            {{item.userName}}
          </td>
          <td>
            {{item.date | date:'dd MMM yyyy'}}
          </td>
          <td>
            {{item.noOfSteps}}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="cms-pagination" *ngIf="detailsFilteredList.length==0">No records</div>
  </div>
</div>

