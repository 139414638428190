import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AccountComponent } from '../account/account.component';
import { UserService } from '../../services/user-service';
import { PreferencesComponent } from '../../components/preferences/preferences.component';
import { TutorialsComponent } from '../../components/tutorials/tutorials.component';
import { AuthService } from '../../services/auth-service';
import { InsightsService } from '../../services/insights-service';

@Component({
  selector: 'app-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss'],
})
export class ProfileDropdownComponent implements OnInit {
  @Input() photoSrc: string;
  @Input() levelSrc: string;
  @Input() isHoverDropdown = false;
  @Input() canShowSettings = false;
  @Input() canShowNotifications = false;
  @Input() canShowPreferences = false;
  @Input() canShowTutorials = false;
  @Input() canShowBookmarks = false;
  @Input() canShowCms = false;
  @Input() canShowInsights = false;
  @ViewChild('profileDropdown') myDropdown: NgbDropdown;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private insightsService: InsightsService,
  ) {
  }

  ngOnInit() {
  }

  private closeDropdown() {
    this.myDropdown.close();
  }

  private navigateToUrl(url: string) {
    this.router.navigateByUrl(url);
    this.closeDropdown();
  }

  onMouseEnter(event: Event) {
    if (!this.isHoverDropdown) {
      return;
    }

    event.stopPropagation();
    this.myDropdown.open();
  }

  onMouseLeave(event: Event) {
    if (!this.isHoverDropdown) {
      return;
    }

    event.stopPropagation();
    this.myDropdown.close();
  }

  onMyProfileSelected() {
    this.modalService.open(AccountComponent)
      .result.then(() => {
    })
      .catch(() => this.userService.refreshCurrentProfileSrc());
  }

  onSettingsSelected() {
    if (!this.canShowSettings) {
      return;
    }

    const url = '/settings';
    this.navigateToUrl(url);
  }

  onNotificationsSelected() {
    const url = '/notifications';
    this.navigateToUrl(url);
  }

  onPreferencesSelected() {
    this.modalService.open(PreferencesComponent);
    this.closeDropdown();
  }

  onTutorialsSelected() {
    this.modalService.open(TutorialsComponent);
    this.closeDropdown();
  }

  onBookmarksSelected() {
    const url = '/bookmarks';
    this.navigateToUrl(url);
  }

  onCmsSelected() {
    if (!this.canShowCms) {
      return;
    }

    const url = '/cms/company-summary';
    this.navigateToUrl(url);
  }

  onInsightsSelected() {
    if (!this.canShowInsights) {
      return;
    }

    this.insightsService.getCrossDomainUrl().subscribe(url => {
      window.open(`${url}/insights`, '_blank').focus();
    });
  }

  onLogout() {
    this.closeDropdown();
    this.authService.logout();
  }
}
