import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-hours-minutes-entry',
  templateUrl: './hours-minutes-entry.component.html',
  styleUrls: ['./hours-minutes-entry.component.scss']
})
export class HoursMinutesEntryComponent implements OnInit {
  @Input() minutes: number;
  @Output() onTotalMinutesEntered = new EventEmitter<number>();
  hours: number;
  mins: number;

  ngOnInit() {
    this.setHoursMins();
  }

  onChangeHours() {
    if (this.hours < 0) {
      this.hours = 0;
    }

    this.emitTotalMinutes();
  }

  onChangeMins() {
    if (this.mins < 0 || this.mins >= 60) {
      this.mins = 0;
    }

    this.emitTotalMinutes();
  }

  private setHoursMins() {
    this.hours = Math.trunc(this.minutes / 60);
    this.mins = this.minutes % 60;
  }

  private emitTotalMinutes() {
    const totalMinutes = (this.hours * 60) + this.mins;

    this.onTotalMinutesEntered.emit(totalMinutes);
  }
}
