import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user-service';
import { ReportsService } from '../../../services/reports-service';

@Component({
  selector: 'app-content-reporting',
  templateUrl: './content-reporting.component.html',
  styleUrls: ['./content-reporting.component.scss']
})
export class ContentReportingComponent implements OnInit {
  chartColors: string[] = [];
  companyId: number;
  startDate: Date;
  endDate: Date;
  canSelectCompany = false;

  // isMostViewedArticlesLoading = false;
  // isMostViewedArticlesLoaded = false;
  isCategoriesArticlesViewsLoading = false;
  isCategoriesArticlesViewsLoaded = false;
  // lessThanFullListText = '';
  fullListCount = 0;

  articles: { contentId: number, title: string, views: number }[] = [];
  categories: { categoryId: number, name: string, views: number }[] = [];
  categoriesNames: string[] = [];
  categoriesViews: number[] = [];

  constructor(
    private userService: UserService,
    private reportService: ReportsService
  ) { }

  ngOnInit() {
    this.fullListCount = 10;
    this.chartColors = this.reportService.getChartColors();
    this.canSelectCompany = this.userService.isSuperAdminUser() || this.userService.isAdminUser();
    this.setInitialDefaults();
    this.populateAllReportsWithInitialDefaults();
  }

  setInitialDefaults() {
    this.companyId = this.reportService.cmsCompanyId;
    this.startDate = this.reportService.cmsStartDate;
    this.endDate = this.reportService.cmsEndDate;
  }

  private populateAllReportsWithInitialDefaults() {
    this.populateAllReports(
      this.companyId, this.startDate, this.endDate);
  }

  private populateAllReports(companyId: number, startDate: Date, endDate: Date) {
    this.populateCategoriesArticlesViews(companyId, startDate, endDate);
  }

  private populateCategoriesArticlesViews(companyId: number, startDate: Date, endDate: Date) {
    this.isCategoriesArticlesViewsLoaded = false;
    this.isCategoriesArticlesViewsLoading = true;
    this.categoriesNames = [];
    this.categoriesViews = [];
    this.reportService.getCategoriesArticlesViews(companyId, startDate, endDate)
      .subscribe((categories: { categoryId: number, name: string, views: number }[]) => {
        this.categories = categories;
        this.categoriesNames = categories.map(e => e.name);
        this.categoriesViews = categories.map(e => e.views);
        this.isCategoriesArticlesViewsLoaded = true;
        this.isCategoriesArticlesViewsLoading = false;
      }, () => {
        this.isCategoriesArticlesViewsLoaded = false;
        this.isCategoriesArticlesViewsLoading = false;
      });
  }

  onDateRangeSelected($event) {
    this.reportService.cmsCompanyId = $event.companyId;
    this.reportService.cmsStartDate = $event.startDate;
    this.reportService.cmsEndDate = $event.endDate;

    this.populateAllReports($event.companyId, $event.startDate, $event.endDate);
  }
}
