<div class="modal-header">
    <h4 class="modal-title">
        <i class="fa fa-exclamation-circle {{iconCss}}" aria-hidden="true"></i> {{title}}</h4>
    <button *ngIf="showCloseIcon" type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [innerHTML]="domSanitizer.bypassSecurityTrustHtml(message)"></div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">{{textButton}}</button>
</div>
