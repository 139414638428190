import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { Component, OnInit } from '@angular/core';
import { PartnerService } from '../../../services/partner-service';
import { UserService } from '../../../services/user-service';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from '../../../services/util-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as _ from 'lodash';
import { LogService } from '../../../services/log.service';
import { PrivacyComponent } from '../../_shared/components/privacy/privacy.component';
import { TermsComponent } from '../../_shared/components/terms/terms.component';

@Component({
  selector: 'app-partner-detail',
  templateUrl: 'partner-detail.component.html',
  styleUrls: ['./partner-detail.component.scss'],
})
export class PartnerDetailComponent implements OnInit {
  previewContent;

  partner: any;
  enquiryForm: FormGroup;
  objectKeys: any;
  checkBox: any;
  thankYouMessage = false;
  submitDisable = false;
  longHtml: SafeHtml;

  constructor(
    private partnerService: PartnerService,
    private utilService: UtilService,
    private breadcrumbService: BreadcrumbService,
    public activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public domSanitizer: DomSanitizer,
    private modal: NgbModal,
    private logService: LogService,
    private userService: UserService,
  ) {
    this.enquiryForm = this.formBuilder.group({
      checkBox: new FormControl(),
    });
    this.objectKeys = Object.keys;
    if (this.previewContent) {
      this.partner = this.previewContent;
      this.longHtml = domSanitizer.bypassSecurityTrustHtml(this.partner.longText);
    } else {
      this.activatedRoute.params.subscribe(data => {
        this.partnerService.getPartnerById(data.id).subscribe(partner => {
          this.partner = partner;
          this.longHtml = domSanitizer.bypassSecurityTrustHtml(this.partner.longText);
          this.breadcrumbService.setBreadcrumbExtraName(this.partner.name);
          if (this.partner.formFields && this.partner.formFields.length) {
            this.partner.formFields.split(',').map(field => {
              if (
                field === 'email' ||
                field === 'phone' ||
                field === 'firstName' ||
                field === 'lastName'
              ) {
                if (field === 'email') {
                  this.enquiryForm.addControl(
                    field,
                    new FormControl('', [Validators.required, Validators.email]),
                  );
                } else {
                  this.enquiryForm.addControl(
                    field,
                    new FormControl('', Validators.required),
                  );
                }
              } else {
                this.enquiryForm.addControl(field, new FormControl(''));
              }
            });
          }
          this.logService.logPartner(this.partner.id);
        });
      });
    }
  }

  ngOnInit() {
  }

  formatImagePath(filename) {
    return this.userService.getPartnerImage(filename);
  }

  submitEnquiry() {
    this.submitDisable = true;
    const body = {
      partnerDetails: this.partner,
      message: this.enquiryForm.value,
    };
    this.partnerService.sendPartnerEnquiry(body).subscribe(
      data => {
        if (data.success === true) {
          this.utilService.showInfo(
            'Thank you, your enquiry has been sent!',
            2,
            'check',
          );
          this.partner.formFields = null;
          this.thankYouMessage = true;
        } else {
          this.utilService.showInfo(
            'Something went wrong. Please try to submit your enquiry again later.',
            1,
            'exclamation',
          );
          this.partner.formFields = null;
          this.thankYouMessage = false;
        }
        this.submitDisable = false;
      },
      err => {
        console.log(err);
      },
    );
  }

  // Convert camel case to capital case for field names.
  fieldNamesReadability(fieldName: string) {
    return _.capitalize(fieldName.replace(/([A-Z])/g, ' $1'));
  }

  checkRequired(key) {
    return (
      key === 'email' ||
      key === 'phone' ||
      key === 'firstName' ||
      key === 'lastName' ||
      key === 'conditions'
    );
  }

  openPrivacyPage() {
    this.modal.open(PrivacyComponent);
  }

  openTermsPage() {
    this.modal.open(TermsComponent);
  }
}
