import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { CompanyService } from '../../../services/company-service';
import { UserService } from '../../../services/user-service';
import { EmailValidator } from '../../../validators/email-validator';
import { UtilService } from '../../../services/util-service';
import { AuthService } from '../../../services/auth-service';
import { DateService } from '../../../services/date.service';
import { FeatureService } from '../../../services/feature-service';
import { FilestackService } from '../../../services/filestack-service';
import { FroalaService } from '../froala-service';
import { ICompany } from '../../_models/company';
import {
  CMS_COMPANIES_MANAGEMENT_FUNCTIONAL_FEATURES_HCF_CHECKBOX_ID,
  CMS_COMPANIES_MANAGEMENT_FUNCTIONAL_FEATURES_HCFWIDGET_CHECKBOX_ID,
  COMPANIES_CMS_FEATURE_TYPES,
  FEATURES,
  SYSTEM_ROLES,
} from '../../_shared/enums';
import { ConnectionService } from '../../../services/connection-service';
import { UnSubscribeComponent } from '../../_shared/un-subscribe.component';
import { GenerateLinkDialogComponent } from '../generate-link-dialog/generate-link-dialog.component';
import { ProgramService } from '../../../services/program-service';
import { IProgram } from '../../_models/program';
import * as _ from 'lodash';
import { get } from 'lodash';
import { IIdNamePair } from '../../_models/id-name-pair';
import { InsightsService } from '../../../services/insights-service';
import { CompanyRegisterService } from '../../../services/company-register.service';
import { BOOKING_CODE_FORMAT, REGISTER_CODE_FORMAT } from '../../_shared/formats';

@Component({
  selector: 'app-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.scss'],
})
export class CompanyManagementComponent extends UnSubscribeComponent implements OnInit {
  items: ICompany[];
  item: ICompany;
  itemType = 'Company';
  columns = [];
  itemForm: FormGroup;
  additionalNumericColumns = [];
  froalaOptions: any;
  isDisplayingList: boolean;
  isInitialised: boolean;
  isEditingItem: boolean;
  isAddingItem: boolean;
  canRespondToButtons: boolean;
  existedDomainError: boolean = false;
  pendingDomainValidation: boolean = false;

  features: { id: number; name: string; type: number; checked: boolean }[] = [];
  companyFeatures: { id: number; name: string; type: number; checked: boolean }[] = [];
  categories: { id: number; name: string; featureId: number; checked: boolean }[] = [];
  shareMasterFeatures = [];
  shareSubFeatures = [];
  insightsVersion: IIdNamePair[] = [];
  logo = '';
  hasUsers = false;
  initControls: any;
  activeStateText: string;
  toggleActiveStateText: string;
  availablePrograms: IProgram[] = [];
  isSuperAdmin: boolean;
  mainDomain: string;
  bookingCodeInvalid: boolean = false;
  disableVerifyBookingCode: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private itemService: CompanyService,
    public userService: UserService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private ngZone: NgZone,
    private dateService: DateService,
    private fileStackService: FilestackService,
    private featureService: FeatureService,
    private utilService: UtilService,
    private authService: AuthService,
    private emailValidator: EmailValidator,
    private froalaService: FroalaService,
    private connectionService: ConnectionService,
    private programService: ProgramService,
    private insightsService: InsightsService,
    private companyRegisterService: CompanyRegisterService,
  ) {
    super();
    this.isSuperAdmin = get(this.userService.user, 'role', null) === SYSTEM_ROLES.SUPER_ADMIN;
    this.froalaOptions = this.froalaService.getEditorOptions();
    this.mainDomain = this.userService.envVariables.app.mainDomain;
    if (!this.isSuperAdmin) {
      this.froalaOptions = {
        ...this.froalaOptions,
        events: {
          'froalaEditor.initialized': (e, editor) => {
            editor.edit.off();
          },
        },
      };
    }
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((route) => {
      this.analyseRoute(route);

      if (this.isDisplayingList) {
        this.getList();
      } else {
        this.isInitialised = false;
        this.canRespondToButtons = false;
        this.getFeaturesAndInitialise(+route.id);
      }
    });
  }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isEditingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isEditingItem = true;
    }
  }

  private getList() {
    this.itemService.getAllCompanies().subscribe(
      (data: any) => {
        this.items = data.map((i) => {
          return [
            i.id,
            i.name,
            this.dateService.formatDD_MMM_YYYY(i.updatedAt),
            this.userService.getLogoForCompany(i.logo),
            i.bookingCode,
            this.utilService.formatAsYesNo(i.leapIntegration),
            this.utilService.formatAsYesNo(i.isActive),
          ];
        });
        this.columns = ['id', 'Name', 'Updated Date', 'Logo', "Booking Code", 'LEAP Integration', 'Active'];
        this.additionalNumericColumns = [3];
        this.isInitialised = true;
        this.canRespondToButtons = true;
      },
      (error) => this.utilService.showToastError('Error while loading items - ' + error),
    );
  }

  private initialiseEditItem(id: number): void {
    this.itemService.getCompanyById(id).subscribe((data: ICompany) => {
      this.item = data;
      this.hasUsers = data.users && +data.users > 0;
      this.initialiseEditableFormGroup(data);
      this.setActiveStateValues();
    });
  }

  private initialiseAddItem(): void {
    this.item = this.itemService.getNewCompany();
    this.initialiseEditableFormGroup(this.item);
  }

  private setActiveStateValues() {
    this.activeStateText = this.item.isActive ? 'Is Active Company' : 'Is NOT Active Company';

    this.toggleActiveStateText = this.item.isActive ? 'INactivate!!' : 'Activate';
  }

  private initialiseEditableFormGroup(item: ICompany) {
    this.setLogo(item.logo);
    this.checkValues(this.features, item.features);
    this.checkProgramValues(this.availablePrograms, item.programs);
    const welcomeTextLink = item.welcomeTextLink
      ? item.welcomeTextLink
      : { description: null, label: null, url: null };
    this.companyFeatures = this.generateCompanyFeatures(item, this.features);
    this.initShareFeatures();
    const programFeature = this.companyFeatures.find((feature) => feature.id === FEATURES.PROGRAMS);
    this.insightsVersion = this.insightsService.getInsightsVersions();
    this.disableVerifyBookingCode = item.bookingCode ? !BOOKING_CODE_FORMAT.test(item.bookingCode) : true;
    this.itemForm = this.fb.group({
      nameControl: [item.name, Validators.required],
      bookingCodeControl: [item.bookingCode || '', Validators.pattern(BOOKING_CODE_FORMAT)],
      enableInsightsControl: [item.hasInsights],
      logoControl: [item.logo, Validators.required],
      registerCodeControl: [
        item.registerCode,
        [Validators.required, Validators.maxLength(22), Validators.pattern(REGISTER_CODE_FORMAT)],
      ],
      discoverFeaturesControl: this.buildFeatures(COMPANIES_CMS_FEATURE_TYPES.DISCOVER),
      actFeaturesControl: this.buildFeatures(COMPANIES_CMS_FEATURE_TYPES.ACT, [FEATURES.PROGRAMS]),
      shareFeaturesControl: this.buildShareFeatures(),
      rewardsFeaturesControl: this.buildFeatures(COMPANIES_CMS_FEATURE_TYPES.REWARDS),
      toolkitFeaturesControl: this.buildFeatures(COMPANIES_CMS_FEATURE_TYPES.TOOLKIT),
      functionalFeaturesControl: this.buildFeatures(COMPANIES_CMS_FEATURE_TYPES.FUNCTIONAL),
      domainControl: [item.domain, [this.validateSubdomain]],
      hostnameControl: [item.hostname, [this.validateSubdomain]],
      primaryColorControl: [
        item.primaryColor,
        [Validators.required, this.setHexColorControlValidator()],
      ],
      topBarColorControl: [
        item.topBarColor,
        [Validators.required, this.setHexColorControlValidator()],
      ],
      topTextBarColorControl: [
        item.topTextBarColor,
        [Validators.required, this.setHexColorControlValidator()],
      ],
      backgroundColorControl: [
        item.backgroundColor,
        [Validators.required, this.setHexColorControlValidator()],
      ],
      bodyColorControl: [item.bodyColor, [Validators.required, this.setHexColorControlValidator()]],
      bottomBarColorControl: [
        item.bottomBarColor,
        [Validators.required, this.setHexColorControlValidator()],
      ],
      bottomTextBarColorControl: [
        item.bottomTextBarColor,
        [Validators.required, this.setHexColorControlValidator()],
      ],
      passWordControl: ['', [Validators.maxLength(16), Validators.minLength(8)]],
      mailchimpListIdControl: item.mailchimpListId,
      welcomeTextControl: item.welcomeText,
      welcomeTextLinkDescriptionControl: welcomeTextLink.description,
      welcomeTextLinkLabelControl: welcomeTextLink.label,
      welcomeTextLinkUrlControl: welcomeTextLink.url,
      rewardsTextControl: item.rewardsText,
      programsControl: this.buildFormArray([programFeature || {}, ...this.availablePrograms]),
      insightsDomainControl: [item.insightsDomain, [this.validateSubdomain]],
      powerBICustomerIdControl: [
        item.powerBICustomerId,
        item.hasInsights ? Validators.required : null,
      ],
      insightsVersionControl:
        item.insightsVersion || this.insightsService.getDefaultInsightsVersion(),
      companyDomainName: [
        this.isAddingItem ? '' : this.extractCompanyDomainName(item.hostname),
        [
          Validators.required,
          Validators.maxLength(22),
          Validators.pattern(this.itemService.companyDomainNameFormat),
        ],
        // [domainValidator(this.companyRegisterService, this.item.id, this.mainDomain)],
      ],
      enableIntegrationControl: [item.leapIntegration],
    });

    if (this.isAddingItem) {
      const emailControl = new FormControl(
        '',
        Validators.email,
        this.emailValidator.emailValidator(),
      );
      this.itemForm.addControl('emailControl', emailControl);
    }

    this.itemForm
      .get('companyDomainName')
      .valueChanges.pipe(takeUntil(this.unSubscribeOnDestroy))
      .subscribe((value) => {
        this.pendingDomainValidation = true;
        const mainDomain = this.userService.envVariables.app.mainDomain;
        const insightsDomain = this.userService.envVariables.app.insightsDomain;
        this.itemForm.patchValue({
          domainControl: `${value}.${mainDomain}`,
          hostnameControl: `${value}.${mainDomain}`,
          insightsDomainControl: `${value}.${insightsDomain}`,
        });
      });

    this.itemForm
      .get('companyDomainName')
      .valueChanges.pipe(debounceTime(1000), takeUntil(this.unSubscribeOnDestroy))
      .subscribe((value) => this.domainValidate(value));

    this.itemForm
      .get('registerCodeControl')
      .valueChanges.pipe(takeUntil(this.unSubscribeOnDestroy))
      .subscribe((value) => {
        if (!this.isAddingItem) {
          return;
        }
        this.itemForm.patchValue({
          companyDomainName: value,
        });
      });

    if (!this.isSuperAdmin) {
      this.itemForm.disable();
    }

    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  buildFeatures(type, excludeIds = []) {
    const arr = this.companyFeatures.filter(
      (feature) => feature.type === type && !excludeIds.includes(feature.id),
    );
    return this.buildFormArray(arr);
  }

  initShareFeatures() {
    this.shareMasterFeatures = this.companyFeatures.filter(
      (feature) =>
        feature.type === FEATURES.SHARE &&
        ![FEATURES.LEADERBOARD, FEATURES.FRIENDS, FEATURES.GROUPS].includes(feature.id),
    );
    this.shareSubFeatures = this.companyFeatures.filter(
      (feature) =>
        feature.type === FEATURES.SHARE &&
        [FEATURES.LEADERBOARD, FEATURES.FRIENDS, FEATURES.GROUPS].includes(feature.id),
    );
  }

  buildShareFeatures() {
    const items = [...this.shareMasterFeatures, ...this.shareSubFeatures];
    return this.buildFormArray(items);
  }

  setFeature(item) {
    item.checked = !item.checked;
    if (item.id === 4) {
      // SHARE master feature
      for (let i = 0; i < this.shareSubFeatures.length; i++) {
        this.shareSubFeatures[i].checked = item.checked;
        const control = this.itemForm.controls['shareFeaturesControl'].get(
          `${i + this.shareMasterFeatures.length}`,
        );
        control.setValue(item.checked);
        if (item.checked) {
          control.enable();
        } else {
          control.disable();
        }
      }
    }
    this.item.features = this.companyFeatures.filter((p) => p.checked).map((c) => c.id);
  }

  setProgram(item: IProgram) {
    item.checked = !item.checked;
    this.item.programs = this.availablePrograms.filter((p) => p.checked);
  }

  setLogo(image?) {
    if (!image || image.trim().length === 0) {
      return;
    }

    this.logo = this.userService.getLogoForCompany(image);
  }

  buildFormArray(items) {
    const returnValue = items.map((feature) => {
      return this.fb.control(feature.checked ? true : null);
    });
    return this.fb.array(returnValue);
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  private navigateToList() {
    this.router.navigate(['/cms/companies']);
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Delete ' + this.itemType;
    modal.componentInstance.message = 'Confirm deleting this ' + this.itemType;
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(
      (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }
        this.itemService.delete(this.item.id).subscribe(
          () => {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`${this.itemType} - ${this.item.name} - deleted`);
            this.navigateToList();
          },
          (error) => this.utilService.showToastError('Error while deleting item - ' + error),
        );
      },
      () => {
      },
    );
  }

  private persistItem() {
    this.canRespondToButtons = false;
    let itemToPersist: any;
    let newUser: any;
    try {
      let isChecked = this.features
        .filter((p) => p.id === CMS_COMPANIES_MANAGEMENT_FUNCTIONAL_FEATURES_HCF_CHECKBOX_ID['HCF'])
        .map((c) => c.checked);
      let isHcfWidgetChecked = this.features
        .filter(
          (p) =>
            p.id ===
            CMS_COMPANIES_MANAGEMENT_FUNCTIONAL_FEATURES_HCFWIDGET_CHECKBOX_ID['HCF Widget'],
        )
        .map((c) => c.checked);

      itemToPersist = {
        name: this.itemForm.get('nameControl').value,
        hasInsights: this.itemForm.get('enableInsightsControl').value,
        logo: this.itemForm.get('logoControl').value,
        registerCode: this.itemForm.get('registerCodeControl').value,
        features: this.item.features.toString(),
        hostname: this.itemForm.get('hostnameControl').value,
        domain: this.itemForm.get('domainControl').value,
        primaryColor: this.itemForm.get('primaryColorControl').value,
        topBarColor: this.itemForm.get('topBarColorControl').value,
        topTextBarColor: this.itemForm.get('topTextBarColorControl').value,
        backgroundColor: this.itemForm.get('backgroundColorControl').value,
        bodyColor: this.itemForm.get('bodyColorControl').value,
        bottomBarColor: this.itemForm.get('bottomBarColorControl').value,
        bottomTextBarColor: this.itemForm.get('bottomTextBarColorControl').value,
        mailchimpListId: this.itemForm.get('mailchimpListIdControl').value,
        welcomeText: this.itemForm.get('welcomeTextControl').value,
        welcomeTextLink: {
          description: this.itemForm.get('welcomeTextLinkDescriptionControl').value,
          label: this.itemForm.get('welcomeTextLinkLabelControl').value,
          url: this.itemForm.get('welcomeTextLinkUrlControl').value,
        },
        rewardsText: this.itemForm.get('rewardsTextControl').value,
        extraRegistrationFields: isChecked.toString() == '' ? false : isChecked.toString(),
        isHcfWidget: isHcfWidgetChecked.toString() == '' ? false : isHcfWidgetChecked.toString(),
        programs: this.item.programs,
        insightsDomain: this.itemForm.get('insightsDomainControl').value,
        powerBICustomerId: this.itemForm.get('powerBICustomerIdControl').value,
        insightsVersion: this.itemForm.get('insightsVersionControl').value,
        bookingCode: this.itemForm.get('bookingCodeControl').value,
        leapIntegration: this.itemForm.get('enableIntegrationControl').value
      };

      if (this.item.id !== 0) {
        itemToPersist.id = this.item.id;
      }

      itemToPersist.hostname = itemToPersist.hostname
        ? itemToPersist.hostname.toLowerCase()
        : itemToPersist.hostname;
      itemToPersist.domain = itemToPersist.domain
        ? itemToPersist.domain.toLowerCase()
        : itemToPersist.domain;
      itemToPersist.insightsDomain = itemToPersist.insightsDomain
        ? itemToPersist.insightsDomain.toLowerCase()
        : itemToPersist.insightsDomain;

      if (this.isAddingItem && this.itemForm.get('emailControl').value !== '') {
        newUser = {
          email: this.itemForm.get('emailControl').value,
          password: this.itemForm.get('passWordControl').value,
          status: 2,
        };
      }
    } catch (error) {
      this.utilService.showToastError('Error persisting item - ' + error);
      this.canRespondToButtons = true;
      return;
    }

    if (this.isEditingItem) {
      this.updateItem(itemToPersist);
    } else if (this.isAddingItem) {
      this.addNewItem(itemToPersist, newUser);
    }
  }

  private updateItem(item: ICompany) {
    this.itemService.save(item.id, item).subscribe(
      () => {
        this.itemForm.reset();
        this.canRespondToButtons = true;
        this.utilService.showToastSuccess(`Updated ${this.itemType} - ${item.name}`);
        this.navigateToList();
      },
      (error) => {
        this.utilService.showToastError(`Error while saving item - ${error}`);
        this.canRespondToButtons = true;
      },
    );
  }

  private addNewItem(item: ICompany, newUser: any) {
    this.itemService.save(0, item).subscribe(
      (i) => {
        this.itemForm.reset();
        this.canRespondToButtons = true;

        if (newUser && i.company[0]) {
          newUser.companyId = i.company[0];
          this.AddNewUser(newUser, item.name);
        } else {
          this.utilService.showToastSuccess(`Added new ${this.itemType} - ${item.name}`);
        }
        this.navigateToList();
      },
      (error) => {
        this.utilService.showToastError(`Error while adding item - ${error}`);
        this.canRespondToButtons = true;
      },
    );
  }

  AddNewUser(data, itemName) {
    this.authService.register(data).subscribe(
      () => {
        this.utilService.showToastSuccess(
          `Added new ${this.itemType} - ${itemName} and demo user: ${data.email}`,
        );
      },
      () => {
        this.utilService.showToastError(`Error while adding item new user`);
      },
    );
  }

  onBackClick() {
    this.navigateToList();
  }

  onDeleteClick() {
    if (!this.isEditingItem) {
      return;
    }
    if (this.hasUsers) {
      this.utilService.showToastError(
        'You cannot delete this company. First delete the users and try again.',
        'Company has users',
      );
      return;
    }
    this.deleteItem();
  }

  onSaveClick() {
    if (!this.itemForm.valid || this.existedDomainError) {
      this.markAllControlsAsTouched();
      return;
    }
    this.persistItem();
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  getFeaturesAndInitialise(id) {
    forkJoin([this.featureService.getAllFeatures(), this.programService.getAvailablePrograms()])
      .pipe(takeUntil(this.unSubscribeOnDestroy))
      .subscribe(([data, programs]) => {
        this.features = this.featureService.setCheckedPropertyOnFeatures(
          this.featureService.filterActiveFeatures(data),
        );
        this.availablePrograms = programs.map((item) => ({ ...item, checked: false }));
        if (this.isEditingItem) {
          this.initialiseEditItem(id);
        } else if (this.isAddingItem) {
          this.initialiseAddItem();
        }
      });
  }

  generateCompanyFeatures(item: ICompany, features) {
    const allowedCompanyFeatures = features.filter((f) => {
      const includedCompanyIds = f.includedCompanyIds || [];
      if (
        _.includes(includedCompanyIds, item.id) ||
        _.includes(includedCompanyIds, this.featureService.ALL_COMPANY_INCLUDED_OPTION_ID)
      ) {
        return true;
      }
      return false;
    });
    return allowedCompanyFeatures.map((feature) => ({
      ...feature,
      name: _.includes(feature.name, '${Company}')
        ? _.replace(feature.name, '${Company}', item.name)
        : feature.name,
    }));
  }

  checkValues(items, itemsSelected) {
    if (!Array.isArray(itemsSelected)) {
      itemsSelected = itemsSelected.split(',').map(Number);
    }
    items.forEach((f) => {
      f.checked = itemsSelected.indexOf(+f.id) > -1;
    });
  }

  checkProgramValues(programs: IProgram[], programSelected: IProgram[]) {
    programs.forEach((p) => {
      p.checked = programSelected.some((ps) => ps.id === p.id);
    });
  }

  isValidGroupCheckbox(qttMinSelected, control) {
    if (this.itemForm.get(control).value) {
      const formControls = this.itemForm.get(control);
      const totalControlValue = formControls.value;
      const controlValues = formControls.value.filter((i) => i);
      return (
        controlValues &&
        (controlValues.length > qttMinSelected || controlValues.length == totalControlValue.length)
      );
    } else {
      return false;
    }
  }

  isEnableForCurrentUser(feature) {
    const featureAccessRolesString = feature && feature.accessRoles;
    if (!featureAccessRolesString) {
      return true;
    }
    const featureAccessRoles = featureAccessRolesString.split(',');
    return this.userService.isUserRoleInList(featureAccessRoles);
  }

  openFilestack() {
    this.fileStackService.pickCompanyImage().then(
      (res) => {
        this.ngZone.run(() => {
          this.itemForm.patchValue({ logoControl: res });
          this.setLogo(res);
        });
      },
      (err) => {
        this.utilService.showToastError('Error while uploading picture', err);
      },
    );
  }

  changeColor(field, color) {
    let patchValueObject = {};
    patchValueObject[field] = color;
    return this.itemForm.patchValue(patchValueObject);
  }

  private setHexColorControlValidator() {
    return Validators.pattern(this.utilService.validateHexColor());
  }

  onToggleActiveState() {
    const isToActivate = !this.item.isActive;

    const title = isToActivate ? `Activate ${this.item.name}` : `INactivate ${this.item.name}`;

    const message = isToActivate
      ? 'Confirm reactivating the company'
      : 'All company users will be unable to access the app. Confirm?';

    const confirmButtonLabel = isToActivate ? 'Activate' : 'INactivate';

    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = title;
    modal.componentInstance.message = message;
    modal.componentInstance.confirmButtonLabel = confirmButtonLabel;

    modal.result.then(
      (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }

        if (isToActivate) {
          this.itemService.activate(this.item.id).subscribe((company: ICompany) => {
            this.doToggledStateResponse(company);
          });
        } else {
          this.itemService.inactivate(this.item.id).subscribe((company: ICompany) => {
            this.doToggledStateResponse(company);
          });
        }
      },
      () => {
      },
    );
  }

  private doToggledStateResponse(updatedCompany: ICompany) {
    this.item = updatedCompany;
    this.setActiveStateValues();
    const operation = this.item.isActive ? 'ACTIVATED' : 'INACTIVATED';
    const msg = `Successfully ${operation} ${this.item.name}`;
    this.utilService.showToastSuccess(msg);
  }

  handleClickOtherButton() {
    const dialogRef = this.modalService.open(GenerateLinkDialogComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
    // dialogRef.componentInstance.listCompanies = this.items;
    dialogRef.componentInstance.isSuperAdmin = this.isSuperAdmin;
    dialogRef.result.then((data) => {
      if (data) {
        this.itemService
          .generateLink(data)
          .pipe(takeUntil(this.unSubscribeOnDestroy))
          .subscribe((res) => {
            if (res.success) {
              this.utilService.showToastSuccess(`The email is sent to ${data.email}`);
            } else {
              this.utilService.showToastError(res.message);
            }
          });
      }
    });
  }

  toggleInsightsSetting(checked) {
    if (!checked) {
      this.itemForm.get('powerBICustomerIdControl').setErrors(null);
      this.itemForm.get('powerBICustomerIdControl').clearValidators();
      this.itemForm.get('powerBICustomerIdControl').reset();
    } else {
      this.itemForm.get('powerBICustomerIdControl').setValidators([Validators.required]);
    }
  }

  extractCompanyDomainName(hostname) {
    if (!hostname) {
      return '';
    }

    return hostname.replace(`.${this.mainDomain}`, '');
  }

  domainValidate(value) {
    this.pendingDomainValidation = true;
    this.companyRegisterService
      .checkDomainIsExistedOrNot(
        `${value}.${this.mainDomain}`,
        this.item.id ? String(this.item.id) : null,
      )
      .subscribe((res) => {
        this.pendingDomainValidation = false;
        if (res.success && res.data && res.data.id) {
          this.existedDomainError = true;
          return;
        }

        this.existedDomainError = false;
      });
  }

  validateSubdomain(control: AbstractControl): { [key: string]: any } | null {
    if (control.value) {
      const trimmedValue = control.value.trim();
      const split = trimmedValue.split('.');
      if (split.length >= 3) {
        const subDomain = split[0];
        if (subDomain.length > 22 || subDomain.length < 1) {
          return { invalidLength: true };
        } else {
          return null;
        }
      }
      return null;
    }
    return null;
  }

  openBookingSite() {
    window.open(`https://www.peoplesense.au/${this.itemForm.get('bookingCodeControl').value.toUpperCase()}`, '_blank');
  }

  bookingCodeValidationHandler(validation) {
    this.bookingCodeInvalid = validation.hasError && this.itemForm.get('bookingCodeControl').touched && this.itemForm.get('bookingCodeControl').invalid;
    this.disableVerifyBookingCode = !this.itemForm.get('bookingCodeControl').value || validation.hasError;
  }
}

