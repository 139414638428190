<h2 class="mt-4  w-100">
  <i class="fa fa-calendar mr-2 ml-1" aria-hidden="true"></i>
  Daily goals
</h2>
<div class="card mt-2 w-100">
  <div class="row align-items-stretch mt-2 mb-2">
    <div *ngFor="let item of dailyGoals"
      class="col-xs-12 col-sm-6 d-flex align-items-stretch">
      <div class="row lifeaction">
        <div class="col-md-6 col-lg-8 p-1">
          <div class="indicatorChart">
            <p>
              <b>{{item.name}}</b>
              <br />
              <span class="goal">Daily Goal - {{item.goalDisplay}}</span>
              <br />
              <span class="goal">Daily Total - <b>{{item.trackerDisplay}}</b></span>
            </p>
            <round-progress
              [current]="item.trackerValue || 0"
              [max]="item.goalValue || 0"
              [semicircle]="true" [responsive]="true" [stroke]=30
              [color]="item.strokeColor || '#fff'">
            </round-progress>
            <div class="chartLabel">
              <span>{{item.percent}} %</span>
            </div>
            <div class="datasource"> Datasource:
              <b>{{item.dataSource.inputName}}</b>
              </div>

          </div>
        </div>
        <div class="iconsMine col-md-6 col-lg-4 p-1 mt-4">
          <button type="button" class="btn btn-primary btn-sm"
            [disabled]="!item.dataSource.isSelf"
            (click)="onLogClick(item)">
            <i class="fa fa-plus-circle" aria-hidden="true"></i>
            Log
          </button>
          <button type="button" class="btn btn-primary btn-sm" (click)="onHistoryClick(item)">
            <i class="fa fa-area-chart" aria-hidden="true"></i>
            History
          </button>
          <button type="button" class="btn btn-primary btn-sm" (click)="onGoalClick(item)">
            <i class="fa fa-pencil" aria-hidden="true"></i>
            Goal
          </button>
          <button type="button" class="btn btn-primary btn-sm" (click)="onDataSourceClick(item)">
            <i class="fa fa-pencil" aria-hidden="true"></i>
            Datasource
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<h2 class="mt-5 w-100">
  <i class="fa fa-line-chart ml-1 mr-2" aria-hidden="true"></i> Long term goals</h2>
<div class="card mb-4 w-100">
  <div class="row align-items-stretch mt-2 mb-2">
    <div *ngFor="let item of longGoals" class="col-12 col-sm-6 d-flex align-items-stretch">
      <div class="row lifeaction">
        <div class="col-12 p-1">
          <div class="text-center mt-3">
            <h4>{{item.name}}</h4>
            <p>
              <i>
                <b>Goal:</b> {{item.goalDisplay}}
              </i>
            </p>

            <app-line-graph [data]="item.chartData"
              [labels]="item.chartLabels"
              [legend]="false"
              [color]="chartingService.chartColor || '#fff'"
              [pointRadius]="item.chartPointRadius"
              [pointColor]="item.chartPointBackgroundColors"
              [pointColor]="chartingService.dotColor"
              [maxValue]="item.chartMaxValue"
              [fill]="true" [minLabelRot]="30"
              [dataValue]="chartingService.drawLineGraphValues"
              [lineTension]="0">
            </app-line-graph>
            <div class="iconsAreaDown">
              <button type="button" class="btn btn-primary"
                [disabled]="!item.dataSource.isSelf"
                (click)="onLogClick(item)">
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                Log
              </button>
              <button type="button" class="btn btn-primary" (click)="onGoalClick(item)">
                <i class="fa fa-pencil" aria-hidden="true"></i>
                Goal
              </button>
              <button type="button" class="btn btn-primary" (click)="onDataSourceClick(item)">
                <i class="fa fa-pencil" aria-hidden="true"></i>
                Datasource
              </button>
            </div>
          </div>
          <div class="datasource mt-3"> Datasource:
              <b>{{item.dataSource.inputName}}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
