<div *ngIf='isDisplayingList && isInitialised'>
  <div class='mb-2 w-50'>
    <app-select-company
      [allCompanies]='allCompanies'
      [selectedCompanyId]='companyId'
      (companyIdSelected)='onCompanySelected($event)'
    ></app-select-company>
  </div>

  <app-cms-list-view
    [dataList]='items'
    [columnList]='columns'
    [isPreventListDelete]='isPreventListDelete'
    [isPreventAdd]='isPreventAdd'
    [isUserList]='isUserList'
    [selectedCompanyId]='companyId'
  ></app-cms-list-view>
</div>

<div *ngIf='(isAddingItem || isEditingItem) && isInitialised'
     class='padding jumbotron'>

  <form
    [formGroup]='itemForm'
    (ngSubmit)='onSaveClick()'>

    <div class='user-profile-message'>{{userProfileMessage}}</div>

    <div class='row m-0'>
      <div class='col-4'>
        <div class='form-group'>
          <label class='control-label'>Username</label>
          <input
            type='text'
            readonly
            formControlName='usernameControl'
            class='form-control' />
        </div>
      </div>

      <div class='col-4'>
        <div class='form-group'>
          <label class='control-label'>Created Date</label>
          <input
            type='text'
            readonly
            formControlName='createdAtControl'
            class='form-control' />
        </div>
      </div>

      <div class='col-4'>
        <div class='form-group'>
          <label class='control-label'>Last Login Date</label>
          <input
            type='text'
            readonly
            formControlName='lastLoginControl'
            class='form-control' />
        </div>
      </div>

      <div class='col-6'>
        <div class='form-group'>
          <label for='' class='control-label'>Email</label>
          <input
            type='text'
            class='form-control'
            [ngClass]="displayFieldCss('emailControl')"
            (change)='onEmailChange()'
            formControlName='emailControl'
          />
          <span class='invalid-feedback'>Email must be valid and unique</span>
        </div>
      </div>

      <div class='col-6'>
        <div class='form-group'>
          <label for='' class='control-label'>Mobile Station ISDN</label>
          <input
            type='text'
            class='form-control'
            [ngClass]="displayFieldCss('msisdnControl')"
            formControlName='msisdnControl'
          />
        </div>
      </div>

      <div class='col-10'>
        <div class='form-group'>
          <label for='' class='control-label required'>Company</label>
          <select #companyDropdown
            class='form-control custom-select'
            formControlName='companyIdControl'
            (change)='onCompanyChanged(companyDropdown.value)'>
            <option *ngIf='!item.id'
                    value='null' selected>Select Company IF applicable
            </option>
            <option
              *ngFor='let item of companies'
              [value]='item.id'>{{item.name}}
            </option>
          </select>
        </div>
      </div>

      <div class='col-6'>
        <div class='form-group'>
          <label for='' class='control-label'>Status</label>
          <select
            class='form-control custom-select'
            formControlName='statusControl'
          >
            <option *ngFor='let item of statuses'
                    [ngValue]='item.id'>{{item.name}}
            </option>
          </select>
        </div>
      </div>

      <div class='col-6'>
        <div class='form-group'>
          <label for='' class='control-label'>System Role</label>
          <select
            class='form-control custom-select'
            formControlName='systemRoleControl'
          >
            <option *ngFor='let item of systemRoles'
                    [ngValue]='item.id'>{{item.name}}
            </option>
          </select>
        </div>
      </div>

      <div class='col-4'>
        <div class='form-group'>
          <label class='control-label'>Latest Mobile App Version</label>
          <input
            type='text'
            readonly
            formControlName='latestAppVersionControl'
            class='form-control' />
        </div>
      </div>

      <div class='col-12'>
        <div class='form-group'>
          <label class='control-label'>Insights Setting</label>
          <div class='custom-control custom-checkbox'>
            <input id='cbEnableInsights' type='checkbox' formControlName='enableInsightsControl' class='custom-control-input'>
            <label class='custom-control-label' for='cbEnableInsights'>Enable Insights</label>
          </div>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <br />
    <div class='row'>
      <div [ngClass]="{ 'col-6': !canDelete, 'col-4': canDelete }">
        <button
          type='button'
          class='btn btn-outline-primary w-100'
          (click)='onBackClick()'>
          <i class='fa fa-undo mr-2' aria-hidden='true'></i> BACK
        </button>
      </div>

      <div *ngIf='canDelete'
           class='col-4'>
        <button
          type='button'
          class='btn btn-outline-primary w-100'
          [disabled]='!canRespondToButtons'
          (click)='onDeleteClick()'>
          <i class='fa fa-trash mr-2' aria-hidden='true'></i> DELETE
        </button>
      </div>

      <div [ngClass]="{ 'col-6': !canDelete, 'col-4': canDelete }">
        <button
          type='submit'
          class='btn btn-primary float-right w-100'
          [disabled]='!canRespondToButtons'>
          <i class='fa fa-floppy-o mr-2' aria-hidden='true'></i> SAVE
        </button>
      </div>
    </div>

  </form>
</div>
