import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IGroup } from '../../_models/group';
import { GroupService } from '../../../services/group-service';
import { UtilService } from '../../../services/util-service';
import { FilestackService } from '../../../services/filestack-service';
import { CompanyService } from '../../../services/company-service';
import { UserService } from '../../../services/user-service';
import { FriendsService } from '../../../services/friends-service';
import { NotificationsService } from '../../../services/notifications-service';
import { IIdNamePair } from '../../_models/id-name-pair';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-add-user-group',
  styleUrls: ['./add-user-group.component.scss'],
  templateUrl: './add-user-group.component.html',
})
export class AddUserGroupComponent implements OnInit {

  items: IGroup[] = [];
  item: IGroup;
  itemType = 'Group';
  columns = [];
  itemForm: FormGroup;
  companies = [];
  allCompanies: IIdNamePair[] = [];
  excludedCompanyIds: number[] = [];
  groupStatuses = [];
  additionalNumericColumns = [];

  canRespondToButtons: boolean;

  canSelectCompany: boolean;

  selectedFriend = [];

  userFriends = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private itemService: GroupService,
    private companyService: CompanyService,
    private utilService: UtilService,
    private fb: FormBuilder,
    private fileStackService: FilestackService,
    private ngZone: NgZone,
    private modalService: NgbModal,
    private userService: UserService,
    private friendsService: FriendsService,
    private groupService: GroupService,
    private notificationsService: NotificationsService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(() => {
      this.itemForm = this.fb.group({
        name: [null, Validators.required],
        description: null,
        banner: [null, Validators.required],
        logo: [null, Validators.required],
        companyId: null,
        friends: null,
      });

      this.setUserRoleAuthorisations();
      this.canSelectCompany = false;
      this.initialiseAddItem();
      this.friendsService.getAllMyActiveFriendsTypes().subscribe(friend => {
        this.userFriends = friend.friends.map(f => {
          f['value'] = f.username;
          return f;
        });
      });
    });
  }

  private setUserRoleAuthorisations() {
    this.canSelectCompany = this.userService.isUser();
  }

  private initialiseAddItem() {
    this.groupStatuses = this.itemService.getAllStatuses();
    this.getCompanies();
  }

  onBannerClick() {
    this.fileStackService
      .pickGroupBanner()
      .then((result) => this.ngZone.run(() => this.itemForm.patchValue({ banner: result })))
      .catch(() => this.utilService.showToastError('Error while uploading the banner'));
  }

  onLogoClick() {
    this.fileStackService
      .pickGroupLogo()
      .then((result) => this.ngZone.run(() => this.itemForm.patchValue({ logo: result })))
      .catch(() => this.utilService.showToastError('Error while uploading the logo'));
  }

  private getCompanies(id?: number) {
    this.getNewItem();

  }

  private getItem(id: number) {
    this.itemService.getGroupById(id)
      .subscribe(data => {
          this.item = data;
          this.canRespondToButtons = true;
        },
        (err) => {
          this.utilService.showToastError('Error while getting group for id: ' + id, err);
        },
      );
  }

  private getNewItem() {
    this.item = this.itemService.getNewGroup();
    this.canRespondToButtons = true;
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  isAllCompanies() {
    try {
      return this.userService.company.id
        && this.canSelectCompany;
    } catch (error) {
      return false;
    }
  }

  public asyncOnAdding(tag): Observable<any> {
    const confirm = true;
    return of(tag).pipe(filter(() => confirm));

  }

  onCompanySelected(companyId) {
    this.itemForm.patchValue({ companyId: companyId });
  }

  excludedCompanyIdsSelected(selectedCompanyIds: number[]) {
    this.excludedCompanyIds = selectedCompanyIds;
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  private persistItem() {
    this.canRespondToButtons = false;
    let itemToPersist: IGroup;

    try {
      itemToPersist = {
        id: this.item.id,
        posts: this.item.posts,
        groupMembers: this.item.groupMembers,
        name: this.itemForm.get('name').value,
        description: this.itemForm.get('description').value,
        companyId: this.userService.company.id,
        logo: this.itemForm.get('logo').value,
        banner: this.itemForm.get('banner').value,
        status: 1,
        excludedCompanyIds: this.isAllCompanies()
          ? this.excludedCompanyIds
          : [],
      };

    } catch (error) {
      this.utilService.showToastError('Error persisting - ' + error);
      this.canRespondToButtons = true;
      return;
    }

    this.addNewItem(itemToPersist);
  }

  private addNewItem(itemToPersist: IGroup) {
    this.itemService.addGroup(itemToPersist)
      .subscribe(newGroup => {
          this.itemForm.reset();
          this.canRespondToButtons = true;
          this.utilService.showToastSuccess(`Added new group - ${itemToPersist.name}`);
          this.groupService.joinGroup(newGroup.id)
            .subscribe(() => {
              this.notificationsService.sendNewGroupInvitation(this.userFriends, newGroup.id, newGroup.name).subscribe(() => {
              });
              this.router.navigateByUrl(`/share/groups/${newGroup.id}`);
            });
        },
        (error) => {
          this.utilService.showToastError(`Error while saving group - ${error}`);
          this.canRespondToButtons = true;
        },
      );
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }

    this.persistItem();
  }

}
