import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FlaggingService {
  ContentTypeSpam = 0;
  ContentTypeOffensive = 1;
  ContentTypeOther = 2;

  constructor(private http: HttpClient) { }

  postFlaggedContent(data) {
    const body = data;
    const url = `/flagging/flagContent`;
    return this.http.post<any>(url, body);
  }
}
