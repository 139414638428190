import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '../../../../services/util-service';
import { QuestionnaireService } from '../../../../services/questionnaire-service';
import { IQuestion, ISaveQuestion } from '../../../_models/question';
import { IQuestionnaire } from '../../../_models/questionnaire';
import { BootstrapModalComponent } from '../../../../components/bootstrap-modal/bootstrap-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICategory } from '../../../_models/category';

@Component({
  selector: 'app-question-management',
  templateUrl: './question-management.component.html',
  styleUrls: ['./question-management.component.scss'],
})
export class QuestionManagementComponent implements OnInit {
  items: IQuestion[];
  item: IQuestion;
  answersDependency = [];
  itemType = 'Question';
  columns = [];
  itemForm: FormGroup;
  newQuestion;
  doneButtonLabel = 'Cancel';

  isInitialised: Boolean = false;
  @Input() isEditingItem: Boolean = false;
  @Input() isAddingItem: Boolean = false;
  @Input() isDependentItem: Boolean = false;
  @Input() dependencyParams;
  @Input() allCategories: ICategory[];
  canRespondToButtons: Boolean = false;

  isQuestionInput = true;
  isQuestionDisplay = false;
  isAddingAnswer: Boolean = false;
  isEditingAnswer: Boolean = false;
  editableAnswer;

  isSelectRadio: Boolean = false;

  iDontKnowAnswer;
  @Input() questionId;
  @Input() questionnaire: IQuestionnaire;
  @Output() finishedQuestionEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() createDependentForm: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    private itemService: QuestionnaireService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.getQuestionnaireAndInitialise();
  }


  private getQuestionnaireAndInitialise(): void {
    this.items = this.questionnaire.liqQuestions;
    if (this.isEditingItem) {
      this.item = this.items.find(q => +q.id === +this.questionId);
      this.isQuestionDisplay = true;
      this.isQuestionInput = false;
    } else if (this.isAddingItem) {
      let noOfQuestions;
      if (this.questionnaire.liqQuestions) {
        noOfQuestions = this.questionnaire.liqQuestions.length;
      } else {
        noOfQuestions = 0;
      }
      this.item = this.itemService.getNewQuestion(this.questionnaire.id, noOfQuestions);
      this.isQuestionDisplay = false;
      this.isQuestionInput = true;
    }

    if (this.dependencyParams) {
      this.item.questionDependencyId = this.dependencyParams.id;
    } else {
      const foundDependencyParams = this.items.find(question => question.id === this.item.questionDependencyId);
      if (foundDependencyParams) {
        this.dependencyParams = foundDependencyParams;
      }
    }
    this.initialiseEditableFormGroup(this.item);

  }

  done() {
    if (this.newQuestion) {
      this.finishedQuestionEdit.emit(this.item);
    } else {
      this.finishedQuestionEdit.emit();
    }
  }

  createDependent() {
    this.createDependentForm.emit(this.item);
  }

  private initialiseEditableFormGroup(item: IQuestion) {
    let iDontKnowPoints = null;
    this.iDontKnowAnswer = item.liqAnswers.find(a => a.label === `I don't know`);
    if (this.iDontKnowAnswer) {
      iDontKnowPoints = this.iDontKnowAnswer.points;
    }
    console.log(item);
    this.itemForm = this.fb.group({
      dependencyLevel: this.item.answersDependency ? 'specific' : 'any',
      questionControl: [item.question, Validators.required],
      requiredControl: [item.required.toString(), Validators.required],
      unknownControl: [item.unknown ? 'true' : 'false', Validators.required],
      iDontKnowPointsControl: iDontKnowPoints,
      answerTypeControl: [item.answerType, Validators.required],
      orderControl: [item.order, Validators.required],
      questionDependencyControl: item.questionDependencyId,
      displayDependencyQuestionControl: item.displayDependencyQuestion == null ? null : item.displayDependencyQuestion.toString(),
      categoryIdControl: [item.categoryId, Validators.required],
    });
    if (item.questionDependencyId) {
      this.updateQuestionAnswers(item.questionDependencyId);
    }
    this.isInitialised = true;
    this.canRespondToButtons = true;
    this.isSelectRadio = item.answerType === 'radio';
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach(field => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  private navigateToList() {
    this.router.navigate(['/cms/questionnaires', this.questionnaire.id]);
  }

  private persistItem() {
    this.canRespondToButtons = false;
    let itemToPersist: ISaveQuestion;

    try {
      itemToPersist = {
        question: this.itemForm.get('questionControl').value,
        required: this.itemForm.get('requiredControl').value,
        unknown: this.itemForm.get('unknownControl').value === 'false' ? null : 1,
        liqQuestionnaireId: this.questionnaire.id,
        answerType: this.itemForm.get('answerTypeControl').value,
        order: this.itemForm.get('orderControl').value,
        answersDependency: this.answersDependency.filter(a => a.checked).map(a => a.id).toString(),
        questionDependencyId: this.dependencyParams ? this.dependencyParams.id : null,
        displayDependencyQuestion: this.itemForm.get('displayDependencyQuestionControl').value,
        categoryId: Number(this.itemForm.get('categoryIdControl').value),
      };

      if (this.item.id !== 0) {
        itemToPersist.id = this.item.id;
      }

    } catch (error) {
      this.utilService.showToastError('Error persisting item - ' + error);
      this.canRespondToButtons = true;
      return;
    }
    if (itemToPersist.unknown && !this.iDontKnowAnswer) {
      const newIDontKnowAnswer = {
        label: `I don't know`,
        points: +this.itemForm.get('iDontKnowPointsControl').value,
        liqQuestionId: itemToPersist.id,
      };
      this.finishedAnswerEdit(newIDontKnowAnswer, 'add');
    } else if (itemToPersist.unknown && this.iDontKnowAnswer) {
      this.finishedAnswerEdit({ points: +this.itemForm.get('iDontKnowPointsControl').value }, 'edit');
    } else if (!itemToPersist.unknown && this.iDontKnowAnswer) {
      this.deleteAnswer(this.iDontKnowAnswer);
    }

    if (this.isEditingItem) {
      this.updateItem(itemToPersist);
    } else if (this.isAddingItem) {
      this.addNewItem(itemToPersist);
    }
    this.doneButtonLabel = 'Done';
  }

  isSpecificDependency() {
    return this.itemForm.get('dependencyLevel').value === 'specific';
  }

  private updateItem(itemToPersist: ISaveQuestion) {
    this.itemService.updateQuestion(itemToPersist)
      .subscribe((res) => {
          this.itemForm.reset();
          this.canRespondToButtons = true;
          this.utilService.showToastSuccess(`Update ${this.itemType} - ${itemToPersist.question}`);
          this.matchQuestionRes(res);
          this.isQuestionInput = false;
          this.isQuestionDisplay = true;
          this.newQuestion = res;
        },
        error => {
          this.utilService.showToastError(`Error while updating item - ${error}`);
          this.canRespondToButtons = true;
        });
  }

  matchQuestionRes(res) {
    this.item.answerType = res.answerType;
    this.item.answersDependency = res.answersDependency;
    this.item.id = res.id;
    this.item.liqQuestionnaireId = res.liqQuestionnaireId;
    this.item.order = res.order;
    this.item.question = res.question;
    this.item.questionDependencyId = res.questionDependencyId;
    this.item.required = res.required;
    this.item.unknown = res.unknown;
    this.item.displayDependencyQuestion = res.displayDependencyQuestion;
    this.item.categoryId = res.categoryId;
  }

  private addNewItem(itemToPersist: ISaveQuestion) {
    this.itemService.addQuestion(itemToPersist)
      .subscribe((res) => {
          this.itemForm.reset();
          this.canRespondToButtons = true;
          this.utilService.showToastSuccess(`Added new ${this.itemType} - ${itemToPersist.question}`);
          this.item = res;
          this.item.liqAnswers = [];
          this.isQuestionInput = false;
          this.isQuestionDisplay = true;
          this.newQuestion = res;

        },
        error => {
          this.utilService.showToastError(`Error while saving item - ${error}`);
          this.canRespondToButtons = true;
        });
  }

  onBackClick() {
    this.navigateToList();
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }

    this.persistItem();
  }

  updateQuestionAnswers(questionId: Number) {
    const question = this.items.find(q => q.id === +questionId);
    if (question) {
      this.answersDependency = question.liqAnswers;
    }
    const selectedAnswers = this.item.answersDependency ? this.item.answersDependency.split(',').map(Number) : [];
    this.answersDependency.map(a => {
      a.checked = selectedAnswers.indexOf(a.id) > -1 ? true : null;
    });
  }

  selectAnswer(answerId: number) {
    const answer = this.answersDependency.find(c => c.id === answerId);
    if (answer) {
      answer.checked = answer.checked ? null : true;
    }
  }

  enableAnswerForm(item?) {
    this.openAnswerForm(item);
  }

  hasAnswers() {
    return !!this.item.liqAnswers.length;
  }


  openAnswerForm(item?) {
    if (item) {
      this.isEditingAnswer = true;
      this.editableAnswer = item;
      this.isAddingAnswer = false;
    } else {
      this.isAddingAnswer = true;

    }
  }

  finishedAnswerEdit(result, type) {
    this.doneButtonLabel = 'Done';
    if (!result) {
      return;
    }

    if (type === 'edit') {
      this.itemService.updateAnswer(result)
        .subscribe(() => {
            this.item.liqAnswers = this.item.liqAnswers.filter(i => result.id !== i.id);
            this.item.liqAnswers.push(result);
            this.utilService.showToastSuccess('Answer updated.');
            this.isEditingAnswer = false;
            this.isAddingAnswer = false;
          },
          error => this.utilService.showToastError('Error while updating answer - ' + error));
    } else {
      this.itemService.addAnswer(result)
        .subscribe(() => {
            this.item.liqAnswers.push(result);
            this.utilService.showToastSuccess('Answer added.');
            this.isAddingAnswer = false;
            this.isEditingAnswer = false;
          },
          error => this.utilService.showToastError('Error while adding answer - ' + error));
    }
  }

  isRangeAnswer() {
    return this.itemForm.get('answerTypeControl').value === 'range' || this.item.answerType === 'range';
  }

  isIDontKnow() {
    return (this.itemForm.get('unknownControl').value === 'true');
  }

  isTextAnswerLimit() {
    return (this.itemForm.get('answerTypeControl').value === 'text' || this.item.answerType === 'text') && this.item.liqAnswers.length > 0;
  }

  isNumberAnswerLimit() {
    return (this.itemForm.get('answerTypeControl').value === 'number' || this.item.answerType === 'number') && this.item.liqAnswers.length > 0;
  }

  finishedRangeEdit(rangeAnswers) {
    if (rangeAnswers) {
      this.item.liqAnswers = rangeAnswers;
    } else {
      this.item.liqAnswers = [];
    }
    this.doneButtonLabel = 'Done';

  }

  openModalDeleteAnswer(answer) {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = `Deleting answer`;
    modal.componentInstance.message = `Confirm deleting this answer '${answer.label}'`;
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(isConfirmed => {
      if (!isConfirmed) {
        return;
      }
      this.deleteAnswer(answer);
    }, () => {
    });
  }

  deleteAnswer(item) {
    if (item.id && item.id !== 0) {
      this.itemService.deleteAnswer(item.id)
        .subscribe(() => {
            this.item.liqAnswers = this.item.liqAnswers.filter(q => q.id !== item.id);
            this.utilService.showToastSuccess('Answer deleted.');
          },
          error => this.utilService.showToastError('Error while deleting answer - ' + error));
    } else {
      this.item.liqAnswers = this.item.liqAnswers.filter(q => !(q.label === item.label && q.value === item.value));
      this.utilService.showToastSuccess('Answer deleted.');
    }
  }

  editQuestion() {
    this.isEditingItem = true;
    this.isQuestionDisplay = false;
    this.isQuestionInput = true;
    this.initialiseEditableFormGroup(this.item);
  }

  onAnswerTypeSelected($event) {
    const selectedValue = ($event.target.value !== 'null')
      ? $event.target.value
      : null;
    this.isSelectRadio = selectedValue === 'radio';
    console.log(this.isSelectRadio);
  }
}
