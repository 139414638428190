<div class="col-12 card mb-3">
  <p class="pt-2"> The little things we do each day make the biggest difference. Done repeatedly, these become winning habits. Your habits
    define your future!</p>
</div>
<div>
  <ngb-tabset #tabs (tabChange)="changeLifeActionsTab($event)"><!-- Use [destroyOnHide]="false" if you want to preserve input values when switching tabs -->
    <ngb-tab title="Active" id="active">
      <ng-template ngbTabContent>
        <div class="bg-tabset">
          <div class="container">
            <div class="row">
              <div class="col-12 mb-3">
                <app-search-term
                  (searchTermEntered)="onSearchActive($event)"
                ></app-search-term>
              </div>
              <app-active-life-action (doLifeAction)="completeLifeAction($event)" *ngFor="let indicator of displayActiveActions; let i = index"
                class="col-12 col-md-6 mb-3" [indicator]="indicator" [index]="i"></app-active-life-action>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="All" id="all">
      <ng-template ngbTabContent>
        <div class="bg-tabset">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <app-search-term
                  (searchTermEntered)="onSearchAll($event)" [reset]='resetSearch'
                ></app-search-term>
              </div>
            </div>
            <app-life-action-driver-filter (filter)="setDriverFilters($event)" (inactive)="setInactiveFilter($event)" (clear)="clearAllFilters($event)"></app-life-action-driver-filter>
            <div class="row">
              <app-manage-life-action (changeActivationStatus)="changeActivationStatus($event)" *ngFor="let indicator of displayAllActions; let i = index"
                class="col-12 col-md-6 mb-3" [indicator]="indicator" [index]="i"></app-manage-life-action>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>
