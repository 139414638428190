import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '../../../../services/util-service';
import { NotificationsService } from '../../../../services/notifications-service';

@Component({
  selector: 'app-notification-manage',
  templateUrl: './notification-manage.component.html',
  styleUrls: ['./notification-manage.component.scss'],
})
export class NotificationManageComponent implements OnInit {
  notifications: any = [];

  settingsForm: FormGroup;

  constructor(
    public notificationsService: NotificationsService,
    private utilService: UtilService,
    private router: Router,
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
  ) {
    this.getNotifications();
  }

  ngOnInit() {
  }

  createForm() {
    this.settingsForm = this.fb.group({
      settings: this.buildNotifications(),
    });
  }

  get settings() {
    return this.settingsForm.get('settings');
  }

  buildNotifications() {
    const arr = this.notifications.map((notification) => {
      return this.fb.control(notification.activated);
    });

    return this.fb.array(arr);
  }

  submit(form) {
    const formValue = Object.assign({}, form, {
      settings: form.settings.map((selected, i) => {
        return {
          notificationCategoryId: this.notifications[i].id,
          active: selected,
        };
      }),
    });

    this.notificationsService.updateUserNotifications(formValue.settings)
      .subscribe(
        () => {
          this.router.navigate(['/notifications']);
          this.utilService.showToast('success', 'Settings saved');
          this.activeModal.close();
        },
        () => {
          this.router.navigate(['/notifications']);
          this.utilService.showToast('error', 'Save failed');
          this.activeModal.close();
        },
      );
  }

  getNotifications() {
    this.notificationsService.getNotifications()
      .subscribe((data) => {
        this.notifications = data;
        this.notifications.forEach(n => n.activated = n.active === true);

        this.notifications.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });

        this.createForm();
      });
  }

  closeModal() {
    this.activeModal.close();
  }
}
