import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-my-health-widget',
  templateUrl: './my-health-widget.component.html',
  styleUrls: ['./my-health-widget.component.scss'],
})
export class MyHealthWidgetComponent implements OnChanges {
  @Input() activeIndicators: any[];

  isActiveIndicators = false;
  anchorText: string;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isActiveIndicators = this.activeIndicators !== null
      && this.activeIndicators.length > 0;
    this.anchorText = (this.isActiveIndicators)
      ? 'Manage'
      : 'Add';
  }
}
