import { INutrition } from '../app/_models/nutrition';
import { IMethod } from '../app/_models/method';
import { IIngredient } from '../app/_models/ingredient';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IRecipe } from '../app/_models/recipe';

const IMAGE_PATH = 'recipes_images/';

@Injectable({
  providedIn: 'root'
})
export class RecipeService {
  DIFFICULTY_EASY = 'easy';

  constructor(private http: HttpClient) { }

  getImagePath() {
    return IMAGE_PATH;
  }

  getRecipes() {
    const url = '/cms/recipes/list';

    return this.http.get<any>(url);
  }

  getRecipeById(id: number) {
    const url = `/cms/recipes/${id}`;

    return this.http.get<any>(url);
  }

  getNewRecipe(): IRecipe {
    return {
      id: 0,
      title: null,
      shortDescription: null,
      description: null,
      difficulty: this.DIFFICULTY_EASY,
      published: true,
      image: null,
      thumbnail: null,
      servings: null,
      servingSize: null,
      ingredients: [],
      methods: [],
      nutrition: [],
      companyId: null,
      excludedCompanyIds: []
    };
  }

  updateRecipe(body) {

    const url = `/cms/recipes/${body.recipe.id}`;

    return this.http.put<any>(url, body);
  }

  addRecipe(body) {
    const url = `/cms/recipes`;

    return this.http.post<any>(url, body);
  }

  deleteRecipe(id: number) {
    const url = `/cms/recipes/${id}`;

    return this.http.delete<any>(url);
  }

  allDifficulties(): string[] {
    return [this.DIFFICULTY_EASY];
  }

  getNewRecipeIngredient(recipeId: number): IIngredient {
    return {
      id: 0,
      recipeId: recipeId,
      ingredientName: null
    };
  }

  addRecipeIngredient(ingredient: IIngredient) {
    const body = {
      recipeId: ingredient.recipeId,
      ingredientName: ingredient.ingredientName
    };

    const url = `/cms/recipes/ingredient`;

    return this.http.post<any>(url, body);
  }

  updateRecipientIngredient(ingredient: IIngredient) {
    const body = {
      recipeId: ingredient.recipeId,
      ingredientName: ingredient.ingredientName
    };
    const url = `/cms/recipes/${ingredient.recipeId}/ingredient/${ingredient.id}`;

    return this.http.put<any>(url, body);
  }

  deleteRecipeIngredient(recipeId: number, ingredientId: number) {
    const url = `/cms/recipes/${recipeId}/ingredient/${ingredientId}`;

    return this.http.delete<any>(url);
  }

  getNewRecipeMethod(recipeId: number, methodName: number): IMethod {
    return {
      id: 0,
      recipeId: recipeId,
      methodName: methodName,
      description: null
    };
  }

  addRecipeMethod(method: IMethod) {
    const body = {
      recipeId: method.recipeId,
      methodName: method.methodName,
      description: method.description
    };
    const url = `/cms/recipes/method`;

    return this.http.post<any>(url, body);
  }

  udpateRecipeMethod(method: IMethod) {
    const body = {
      recipeId: method.recipeId,
      methodName: method.methodName,
      description: method.description
    };
    const url = `/cms/recipes/${method.recipeId}/method/${method.id}`;

    return this.http.put<any>(url, body);
  }

  deleteRecipeMethod(recipeId: number, methodId: number) {
    const url = `/cms/recipes/${recipeId}/method/${methodId}`;

    return this.http.delete<any>(url);
  }

  getNewRecipeNutirition(recipeId: number): INutrition {
    return {
      id: 0,
      recipeId: recipeId,
      value: null,
      unit: null,
      name: null
    };
  }

  addRecipeNutrition(nutrition: INutrition) {
    const body = {
      recipeId: nutrition.recipeId,
      value: nutrition.value,
      unit: nutrition.unit,
      name: nutrition.name
    };
    const url = `/cms/recipes/nutrition`;

    return this.http.post<any>(url, body);
  }

  udpateRecipeNutrition(nutrition: INutrition) {
    const body = {
      recipeId: nutrition.recipeId,
      value: nutrition.value,
      unit: nutrition.unit,
      name: nutrition.name
    };
    const url = `/cms/recipes/${nutrition.recipeId}/nutrition/${nutrition.id}`;

    return this.http.put<any>(url, body);
  }

  deleteRecipeNutrition(recipeId: number, methodId: number) {
    const url = `/cms/recipes/${recipeId}/nutrition/${methodId}`;

    return this.http.delete<any>(url);
  }
}
