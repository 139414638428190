import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';

import { UserService } from '../../services/user-service';
import { AuthService } from '../../services/auth-service';
import { ConnectionService } from '../../services/connection-service';
import { AlertComponent } from '../alert-modal/alert-modal.component';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnSubscribeComponent } from '../../app/_shared/un-subscribe.component';
@Component({
  selector: 'app-delete-user-modal',
  templateUrl: './delete-user-modal.component.html',
  styleUrls: ['./delete-user-modal.component.scss'],
})
export class DeleteUserModalComponent extends UnSubscribeComponent implements OnInit {

  closeAccountForm: FormGroup;
  isLoading: boolean;
  showError: boolean;
  closeAccountError: string;
  usedSSO$: Observable<boolean>;
  authorizeUrl: string;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private connectionService: ConnectionService,
  ) {
    super();
  }

  ngOnInit() {
    this.usedSSO$ = this.connectionService.usedSSO;
    this.connectionService.usedSSO
      .pipe(takeUntil(this.unSubscribeOnDestroy))
      .subscribe(res => {
        if (res) {
          this.authorizeUrl = this.connectionService.getSSOLoginUrl(`${this.connectionService.getBaseRedirectUrl()}/auth/close-account`);
        } else {
          this.resetErrorMessage();
          this.closeAccountForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
          })
        }
      });
  }

  get password() { return this.closeAccountForm.get('password'); }

  ssoAuthorize() {
    window.location.href = this.authorizeUrl;
  }

  resetErrorMessage() {
    this.closeAccountError = null;
    this.showError = false;
  }

  isFormFieldValid(fieldName) {
    const formField = this.closeAccountForm.get(fieldName);
    return formField.valid;
  }

  setInputOutlineClass(ctrl: AbstractControl ) {
    return this.authService.setInputOutlineClass(ctrl);
  }

  onLoading() {
    this.closeAccountForm.disable();
    this.isLoading = true;
  }

  onDone() {
    this.closeAccountForm.enable();
    this.isLoading = false;
  }

  processCloseAccount() {
    this.resetErrorMessage();
    if (this.closeAccountForm.invalid) {
      this.showError = true;
      return;
    }
    this.showError = false;
    this.onLoading();
    this.userService.closeUserOwnAccount(this.closeAccountForm.value)
      .subscribe((res) => {
        if (res && _.has(res, 'success') && !res.success) {
          this.closeAccountError = res.err || 'Error when close AltiusLife Account!';
          return;
        }
        this.activeModal.close();
        const modal = this.modalService.open(AlertComponent, {
          beforeDismiss: () => false,
        });
        modal.componentInstance.title = 'Close My AlitusLife Account';
        modal.componentInstance.textButton = 'Close';
        modal.componentInstance.showCloseIcon = false;
        modal.componentInstance.message =
          'Your account has been successfully closed';
        modal.result.then(() => {
          this.authService.logout();
          window.location.href = window.location.origin;
        });
        },
        err => console.log('closeUserOwnAccount error:', err),
        () => this.onDone(),
      );
  }
}
