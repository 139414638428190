<div class="card h-100">
  <div class="card-body">
    <div>
      <h5>{{indicator.name}}</h5>
      <div class="flex mb-2">
        <div>
          <span class="badge badge-primary mr-1">{{recurrenceString | titlecase}}</span>
        </div>
        <div>
          <span class="badge badge-success">{{indicator.driverName | titlecase}}</span>
        </div>
      </div>
      <p>{{indicator.description}}</p>

    </div>
    <div *ngIf="indicator.active" class="mt-2">
      <button type="button" class="btn btn-primary btn-sm" (click)="deactivate()">Deactivate</button>
    </div>

    <div *ngIf="!indicator.active" class="mt-2">
      <p>
        <a role="button" data-toggle="collapse" [attr.data-target]="'#collapsibleInactive' + index" aria-expanded="false"
          [attr.aria-controls]="index">
          I
          would like to do this LifeAction...

          <i class="fa fa-caret-down"></i>
          <i class="fa fa-caret-up"></i>
        </a>
      </p>
      <app-select-life-action-recurrence [id]="'collapsibleInactive' + index" *ngIf="!indicator.userLifeActionsActivationId"
        [defaultRecurrence]="indicator.recurrence" (selection)="activate($event)"></app-select-life-action-recurrence>
    </div>
  </div>
</div>
