import { DomSanitizer } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IVideo } from '../app/_models/video';

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  public videos: any[] = [];
  public offset: number;

  constructor(
    private domSanitizer: DomSanitizer,
    private http: HttpClient
  ) { }

  getAllVideos() {
    const url = `/cms/videos`;
    return this.http.get<any>(url);
  }

  getVideoById(id: number) {
    const url = `/cms/videos/${id}`;
    return this.http.get<any>(url);
  }

  getNewVideo(): IVideo {
    return {
      id: 0,
      title: null,
      embedCode: null,
      image: null,
      published: true,
      shortText: null,
      longText: null,
      sourceUrl: null,
      companyId: null,
      excludedCompanyIds: [],
      includedCompanyIds: [0],
    };
  }

  updateVideo(id, body) {
    const url = `/cms/videos/${id}`;
    return this.http.put<any>(url, body);
  }

  addVideo(body) {
    const url = `/cms/videos`;
    return this.http.post<any>(url, body);
  }

  deleteVideo(id: number) {
    const url = `/cms/videos/${id}`;

    return this.http.delete<any>(url);
  }

  extractYoutubeId(video: any) {
    if (!video) { return video; }

    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?\"]*).*/;
    const match = video.embedCode.match(regExp);
    if (!match || match.length < 3 || match[2].length !== 11) { return 'error'; }

    video.image = 'https://img.youtube.com/vi/' + match[2] + '/hqdefault.jpg';
    video.sourceUrl = match[2];
    video.videoLink = this.domSanitizer.bypassSecurityTrustResourceUrl(
      'https://www.youtube-nocookie.com/embed/' +
      video.sourceUrl +
      '?rel=0&amp;autoplay=1',
    );
  }
}
