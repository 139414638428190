<div [ngClass]="isLargerAvatar ? 'img-avatar-lg' : 'img-avatar-sm'">
  <img
    [src]="photoSrc" alt="photo"
    onerror="this.onerror=null;this.src='../../../../assets/img/profile.png';"
    class="img-photo"
    (click)="onSelectAvatar()">
  <img *ngIf="isLevelSrc"
    [src]="levelSrc" alt="level"
    class="img-level">
</div>
