import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IFriend } from '../../../_models/friend';

@Component({
  selector: 'app-search-friends-result',
  templateUrl: './search-friends-result.component.html',
  styleUrls: ['./search-friends-result.component.scss'],
})
export class SearchFriendsResultComponent implements OnChanges {
  @Input() friends: IFriend[] = [];
  @Output() viewFriend = new EventEmitter<IFriend>();
  @Output() addFriend = new EventEmitter<IFriend>();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.friends = changes.friends.currentValue;
  }

  getFriendActionText(friend: IFriend): string {
    return (friend.requestActions.canSendFriendRequest)
      ? 'Add'
      : 'View';
  }

  onViewFriend(friend: IFriend) {
    this.viewFriend.emit(friend);
  }

  onAddFriend(friend: IFriend) {
    this.addFriend.emit(friend);
  }

  onAddOrViewFriend(friend: IFriend) {
    if (friend.requestActions.canSendFriendRequest) {
      this.onAddFriend(friend);
    } else {
      this.onViewFriend(friend);
    }
  }
}
