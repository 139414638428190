<div class="modal-header">
  <h4 class="modal-title">Would you like to do? </h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" style="padding: 3rem;"><br>
  <div class="footerButtons">
    <button type="button" style="height: 4rem; width: 45%;" class="btn btn-primary" (click)="joinAnotherTeam()">Join another team</button>
    <button type="button" style="height: 4rem; width: 45%;" class="btn btn-outline-primary" (click)="leaveChallenge()">Leave challenge</button>
  </div><br><br>
  <p><b>Note:</b> You're about to leave this team, you can either join another team or you can leave the challenge</p>
</div>