import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '../../../../services/util-service';
import { QuestionnaireService } from '../../../../services/questionnaire-service';
import { IResult } from '../../../_models/result';
import { IQuestionnaire } from '../../../_models/questionnaire';
import { BootstrapModalComponent } from '../../../../components/bootstrap-modal/bootstrap-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../../services/user-service';
import { IQuestion } from '../../../_models/question';

@Component({
  selector: 'app-result-management',
  templateUrl: './result-management.component.html',
  styleUrls: ['./result-management.component.scss'],
})
export class ResultManagementComponent implements OnInit {
  item: IResult;
  answersDependency = [];
  itemType = 'Result';
  columns = [];
  itemForm: FormGroup;
  questions: IQuestion[];

  isInitialised: boolean;
  @Input() isEditingItem: Boolean = false;
  @Input() isAddingItem: Boolean = false;
  canRespondToButtons: boolean;

  @Input() resultId;
  @Input() editableResult;
  @Input() questionnaire: IQuestionnaire;
  @Output() finishedResultEdit: EventEmitter<any> = new EventEmitter<any>();

  options: Object = {
    paragraphFormat: {
      N: 'Normal',
      H1: 'Heading 1',
      H2: 'Heading 2',
      H3: 'Heading 3',
      H4: 'Heading 4',
      H5: 'Heading 5',
      H6: 'Heading 6',
    },
    toolbarSticky: false,
    key: this.userService.envVariables.cms.froalaApiKey,
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private itemService: QuestionnaireService,
    private userService: UserService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {

    this.getDataAndInitialise();
    this.questions = this.questionnaire.liqQuestions;
    this.questions.map(question => {
      const foundDependencyParams = this.questions.find(q => q.id === question.questionDependencyId);
      if (foundDependencyParams) {
        question.dependencyParams = foundDependencyParams;
      }
    });
  }


  private getDataAndInitialise(): void {
    if (this.isEditingItem) {
      this.item = this.editableResult;
    } else if (this.isAddingItem) {
      this.item = this.itemService.getNewResult(this.questionnaire);
    }
    this.initialiseEditableFormGroup(this.item);
  }

  private initialiseEditableFormGroup(item: IResult) {
    let redirectContentType;
    let redirectId;
    if (this.isEditingItem && item.redirectParams) {
      const redirectParamsJson = JSON.parse(item.redirectParams);
      if (item.redirectPage === 'ContentDetailPage') {
        redirectContentType = redirectParamsJson.type;
        redirectId = redirectParamsJson.contentId;
      } else if (item.redirectPage === 'PartnerPage') {
        redirectId = redirectParamsJson.partnerId;
      } else {
        redirectId = redirectParamsJson ? redirectParamsJson.id : null;
      }

    }
    this.itemForm = this.fb.group({
      titleControl: [item.title, Validators.required],
      descriptionControl: [item.description, Validators.required],
      colorControl: item.color,
      unitControl: item.unit,
      operandControl: item.operand,
      value1Control: item.value1,
      value2Control: item.value2,
      userAnswerLabelControl: item.userAnswerLabel,
      redirectPageControl: item.redirectPage,
      redirectButtonNameControl: item.redirectButtonName,
      redirectContentType: redirectContentType,
      redirectId: redirectId,
      calculusControl: item.calculus,
      principalResultIdControl: item.principalResultId,
      answerDependencyIdControl: item.answerDependencyId ? item.answerDependencyId.toString() : null,
      minValueChartControl: item.minValueChart,
      maxValueChartControl: item.maxValueChart,
      questionDependencyIdControl: null,
    });
    if (item.answerDependencyId) {
      this.setQuestionDependencySelect(item.answerDependencyId);
    }
    if (item.questions) {
      this.selectCalculationQuestions(',' + item.questions + ',');
    }
    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  isActiveResultType() {
    return [1, 2, 4].includes(this.questionnaire.liqResultTypeId);
  }

  setQuestionDependencySelect(answerDependencyId) {
    let questionId = null;
    this.questionnaire.liqQuestions.forEach(q => {
      const answer = q.liqAnswers.find(a => a.id == answerDependencyId);
      if (answer) {
        questionId = answer.liqQuestionId;
      }
    });
    if (questionId) {
      this.itemForm.patchValue({ questionDependencyIdControl: questionId });
      this.setQuestionAnswers(questionId);
    }
  }

  setQuestionAnswers(questionId) {
    if (questionId !== 'null') {
      const question = this.questionnaire.liqQuestions.find(q => q.id === +questionId);
      if (question) {
        this.answersDependency = question.liqAnswers;
      }
    } else {
      this.answersDependency = [];
      this.itemForm.patchValue({ answerDependencyIdControl: null });
    }
  }

  selectCalculationQuestions(questions) {
    this.questionnaire.liqQuestions.forEach(q => {
      q.checked = questions.indexOf(',' + q.id + ',') > -1 ? true : null;
    });
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach(field => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  changeStatusQuestions(questionIndex) {
    this.questionnaire.liqQuestions[questionIndex].checked = this.questionnaire.liqQuestions[questionIndex].checked ? null : true;
    if (this.questionnaire.liqQuestions[questionIndex].dependencyParams && this.questionnaire.liqQuestions[questionIndex].checked) {
      this.questionnaire.liqQuestions.map(q => {
        if (q.id === this.questionnaire.liqQuestions[questionIndex].questionDependencyId) {
          q.checked = true;
        }
      });
    }
    if (!this.questionnaire.liqQuestions[questionIndex].checked) {
      const dependentQuestion = this.questionnaire.liqQuestions.find(question => question.questionDependencyId === this.questionnaire.liqQuestions[questionIndex].id);
      dependentQuestion.checked = null;
    }


  }

  private persistItem() {
    this.canRespondToButtons = false;
    let itemToPersist: IResult;
    try {
      itemToPersist = {
        title: this.itemForm.get('titleControl').value,
        liqQuestionnaireId: this.item.liqQuestionnaireId,
        description: this.itemForm.get('descriptionControl').value,
        color: this.itemForm.get('colorControl').value,
        unit: this.itemForm.get('unitControl').value,
        operand: this.itemForm.get('operandControl').value,
        value1: this.itemForm.get('value1Control').value,
        value2: this.itemForm.get('value2Control').value,
        questions: this.questionnaire.liqQuestions.filter(a => a.checked).map(a => a.id).toString(),
        userAnswerLabel: this.itemForm.get('userAnswerLabelControl').value,
        redirectPage: this.itemForm.get('redirectPageControl').value,
        redirectButtonName: this.itemForm.get('redirectButtonNameControl').value,
        redirectParams: this.formatRedirectParams(),
        calculus: this.itemForm.get('calculusControl').value,
        principalResultId: this.itemForm.get('principalResultIdControl').value,
        answerDependencyId: this.getAnswerDependencyIdValue(),
        minValueChart: this.itemForm.get('minValueChartControl').value,
        maxValueChart: this.itemForm.get('maxValueChartControl').value,
      };
      if (this.item.id !== 0) {
        itemToPersist.id = this.item.id;
      }

    } catch (error) {
      this.utilService.showToastError('Error persisting item - ' + error);
      this.canRespondToButtons = true;
      return;
    }

    if (this.isEditingItem) {
      this.updateItem(itemToPersist);
    } else if (this.isAddingItem) {
      this.addNewItem(itemToPersist);
    }
  }

  private updateItem(itemToPersist: IResult) {
    this.itemService.updateResult(itemToPersist)
      .subscribe((res) => {
          this.item = res[0];
          this.canRespondToButtons = true;
          this.utilService.showToastSuccess(`Update ${this.itemType} - ${itemToPersist.title}`);
          this.finishedResultEdit.emit(res[0]);
        },
        error => {
          this.utilService.showToastError(`Error while updating item - ${error}`);
          this.canRespondToButtons = true;
        });
  }

  private addNewItem(itemToPersist: IResult) {
    this.itemService.addResult(itemToPersist)
      .subscribe((res) => {
          this.canRespondToButtons = true;
          if (res[0] && res[0].id) {
            this.item = res[0];
            this.utilService.showToastSuccess(`Added new ${this.itemType} - ${itemToPersist.title}`);
            this.finishedResultEdit.emit(res[0]);
          } else {
            this.utilService.showToastError(`Error while saving item - ${res.description}`);
            this.canRespondToButtons = true;
          }

        },
        error => {
          this.utilService.showToastError(`Error while saving item - ${error}`);
          this.canRespondToButtons = true;
        });
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = `Deleting ${this.itemType}`;
    modal.componentInstance.message = `Confirm deleting this ${this.itemType}`;
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(isConfirmed => {
      if (!isConfirmed) {
        return;
      }

      this.itemService.deleteResult(this.item.id)
        .subscribe(() => {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`${this.itemType} - ${this.item.title} - deleted`);
          },
          error => this.utilService.showToastError('Error while deleting item - ' + error));
    }, () => {
    });
  }

  getAnswerDependencyIdValue() {
    if (!this.answersDependency.length) {
      return null;
    }
    if (this.answersDependency.length === 1) {
      return this.answersDependency[0].id;
    }
    if (this.answersDependency.length > 1) {
      return this.itemForm.get('answerDependencyIdControl').value;
    }
  }

  onDeleteClick() {
    if (!this.isEditingItem) {
      return;
    }

    this.deleteItem();
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }

    this.persistItem();
  }

  isVariableFieldsValid() {

    if (this.questionnaire.liqResultTypeId > 1) {

      this.markAllControlsAsTouched();

      if (this.questionnaire.liqResultTypeId > 1 && !this.itemForm.get('operandControl').value) {
        return false;
      }
      if (!this.isValidControl('value1Control', this.isValue1Required())) {
        return false;
      }
      if ((this.itemForm.get('operandControl').value === 'between' || this.itemForm.get('operandControl').value === 'noIn') &&
        !this.isValidControl('value2Control', true)) {
        return false;
      }
      if (!this.isValidControl('calculusControl', true)) {
        return false;
      }
      return this.isValidControl('colorControl', this.questionnaire.liqResultTypeId > 2);


    } else {
      return true;
    }

  }


  isValidControl(control, validate) {
    if (this.questionnaire.liqResultTypeId > 1 && validate) {
      if (!this.itemForm.get(control).touched) {
        return true;
      } else if (this.itemForm.get(control).value && this.itemForm.get(control).value !== '') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  isValue1Required() {
    return (this.questionnaire.liqResultTypeId > 1 && this.itemForm.get('operandControl').value !== 'null');
  }


  redirectParamsAreRequired() {
    return this.itemForm.get('redirectPageControl').value === 'ContentDetailPage' || this.itemForm.get('redirectPageControl').value === 'PartnerPage' || this.itemForm.get('redirectPageControl').value === 'QuestionnairePage';
  }

  isContentRedirect() {
    return this.itemForm.get('redirectPageControl').value === 'ContentDetailPage';
  }

  changeColor(color) {
    this.itemForm.patchValue({ colorControl: color });
  }

  formatRedirectParams() {
    let redirectParams;
    const id = this.itemForm.get('redirectId').value;
    if (this.itemForm.get('redirectPageControl').value === 'ContentDetailPage') {
      const contentType = this.itemForm.get('redirectContentType').value;
      redirectParams = `{"type": "${contentType}","contentId": ${id}}`;
    } else if (this.itemForm.get('redirectPageControl').value === 'PartnerPage') {
      redirectParams = `{"partnerId": ${id}}`;
    } else {
      redirectParams = `{"id": ${id}}`;
    }
    return redirectParams;
  }

}
