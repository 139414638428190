import { RecipeService } from '../../../../services/recipe.service';
import { UtilService } from '../../../../services/util-service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { INutrition } from '../../../_models/nutrition';
import { Component, OnInit, Input, Renderer, ViewChild, ElementRef } from '@angular/core';
import { BootstrapModalComponent } from '../../../../components/bootstrap-modal/bootstrap-modal.component';

@Component({
  selector: 'app-recipe-nutrition-management',
  templateUrl: './recipe-nutrition-management.component.html',
  styleUrls: ['./recipe-nutrition-management.component.scss']
})
export class RecipeNutritionManagementComponent implements OnInit {
  @Input() recipeId: number;
  @Input() items: INutrition[];
  @Input() isUpdated: boolean;

  @ViewChild('editableFocus') editableFocusElement: ElementRef;

  itemType: string;
  title: string;
  itemForm: FormGroup;
  selectedItem: INutrition;
  methodName: number;
  description: string;

  isAddingItem = false;
  isEditingItem = false;
  isItemsUpdated = false;
  canRespondToButtons: boolean;

  value: number;
  unit: string;
  name: string;

  constructor(
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private utilService: UtilService,
    private itemService: RecipeService,
    private modalService: NgbModal,
    private renderer: Renderer
  ) { }

  ngOnInit() {
    this.itemType = 'Nutrition item';
    this.refreshTitle();
    this.createFormGroup();
    this.canRespondToButtons = true;
  }

  private refreshTitle() {
    this.title = `Recipe ${this.itemType}s (${this.items.length})`;
  }

  private createFormGroup() {
    this.itemForm = this.fb.group({
      selectedItemControl: this.selectedItem,
      valueControl: [this.value, [Validators.required, Validators.min(1)]],
      unitControl: [this.unit, Validators.required],
      nameControl: [this.name, Validators.required]
    });
  }

  private selectItem(item: INutrition) {
    if (!this.items.length || this.isAddingItem || this.isEditingItem) { return; }

    this.selectedItem = item;
    this.patchItemForm(this.selectedItem);
  }

  private selectLastItem() {
    if (!this.items.length) { return; }

    this.selectedItem = this.items[this.items.length - 1];
    this.patchItemForm(this.selectedItem);
  }

  private removeFromItems(itemToRemove: INutrition) {
    const itemIndex = this.items.indexOf(itemToRemove);
    if (itemIndex < 0 ) { return; }

    this.items.splice(itemIndex, 1);
  }

  private patchItemForm(source: INutrition) {
    this.itemForm.patchValue({
      valueControl: source.value,
      unitControl: source.unit,
      nameControl: source.name
    });
  }

  private setFocusToFirstEditableField() {
    this.renderer.invokeElementMethod(this.editableFocusElement.nativeElement, 'focus');
  }

  itemSummary(item: INutrition): string {
    if (!item) { return ''; }

    return `${item.value} ${item.unit} - ${item.name}`;
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field)
    };
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach(field => {
      const control = this.itemForm.get(field);
      control.markAsTouched({onlySelf: true});
    });
  }

  private persistItem() {
    this.canRespondToButtons = false;
    let itemToPersist: INutrition;

    try {
      itemToPersist = {
        id: this.isEditingItem ? this.selectedItem.id : 0,
        recipeId: +this.recipeId,
        value: +this.itemForm.get('valueControl').value,
        unit: this.itemForm.get('unitControl').value,
        name: this.itemForm.get('nameControl').value
      };
    } catch (error) {
      this.utilService.showToastError('Error persisting item - ' + error);
      this.canRespondToButtons = true;
      return;
    }

    if (this.isAddingItem) {
      this.addNewItem(itemToPersist);
    } else if (this.isEditingItem) {
      this.updateItem(itemToPersist);
    }
  }

  private addNewItem(itemToPersist: INutrition) {
    this.itemService.addRecipeNutrition(itemToPersist)
      .subscribe(data => {
        this.items.push(data);
        this.utilService.showToastSuccess(`Added new ${this.itemType} - ${this.itemSummary(itemToPersist)}`);
        this.itemForm.reset();
        this.isItemsUpdated = true;
        this.canRespondToButtons = true;
        this.isAddingItem = false;
        this.refreshTitle();
        this.selectLastItem();
      },
    error => {
      this.utilService.showToastError(`Error while adding item - ${error}`);
      this.canRespondToButtons = true;
    });
  }

  private updateItem(itemToPersist: INutrition) {
    this.itemService.udpateRecipeNutrition(itemToPersist)
      .subscribe(data => {
        this.utilService.showToastSuccess(`Updated ${this.itemType} - ${this.itemSummary(itemToPersist)}`);
        this.isItemsUpdated = true;
        this.canRespondToButtons = true;
        this.isEditingItem = false;
        this.refreshTitle();
        this.itemForm.reset();
        this.updateItems(data);
        this.selectItem(itemToPersist);
      },
      error => {
        this.utilService.showToastError(`Error while updating item - ${error}`);
        this.canRespondToButtons = true;
      });
  }

  private updateItems(item: INutrition) {
    const itemIndex = this.items.findIndex(i => i.id === item.id);
    if (itemIndex < 0 ) { return; }

    this.items[itemIndex].value = item.value;
    this.items[itemIndex].unit = item.unit;
    this.items[itemIndex].name = item.name;
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Delete ' + this.itemType;
    modal.componentInstance.message = 'Delete - ' + this.itemSummary(this.selectedItem);
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(isConfirmed => {
      if (!isConfirmed) { return; }

      this.itemService.deleteRecipeNutrition(this.recipeId, this.selectedItem.id)
        .subscribe(() => {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`Deleted ${this.itemType} - ${this.itemSummary(this.selectedItem)}`);
            this.canRespondToButtons = true;
            this.removeFromItems(this.selectedItem);
            this.refreshTitle();
          },
          error => this.utilService.showToastError('Error while deleting item - ' + error));
      }, () => {});
  }

  isReadOnly() {
    return !this.isAddingItem && !this.isEditingItem;
  }

  canEdit() {
    return this.selectedItem && this.isReadOnly();
  }

  canDelete() {
    return this.selectedItem && this.isReadOnly();
  }

  canAdd() {
    return this.isReadOnly();
  }

  canCancel() {
    return (this.isAddingItem || this.isEditingItem) && this.canRespondToButtons;
  }

  canSave() {
    return (this.isAddingItem || this.isEditingItem) && this.canRespondToButtons;
  }

  onClickItem(item: INutrition) {
    if (this.isAddingItem || this.isEditingItem) { return; }

    this.selectItem(item);
  }

  onEditClick() {
    if (!this.selectedItem) { return; }

    this.isEditingItem = true;
    this.isAddingItem = false;
    this.setFocusToFirstEditableField();
  }

  onDeleteClick() {
    if (!this.selectedItem) { return; }

    this.deleteItem();
  }

  onAddClick() {
    this.itemForm.reset();
    this.selectedItem = null;
    const newItem = this.itemService.getNewRecipeNutirition(this.recipeId);
    this.patchItemForm(newItem);

    this.isAddingItem = true;
    this.isEditingItem = false;
    this.setFocusToFirstEditableField();
  }

  onCancelClick() {
    this.itemForm.reset();

    if (this.selectedItem && this.isEditingItem) {
      this.patchItemForm(this.selectedItem);
      } else {
        const newItem = this.itemService.getNewRecipeNutirition(this.recipeId);
        this.patchItemForm(newItem);
      }

    this.isAddingItem = false;
    this.isEditingItem = false;
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();

      return;
    }

    this.persistItem();
  }

  onCloseClick() {
    this.activeModal.close(this);
  }
}
