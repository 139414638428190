import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TutorialsComponent } from '../../components/tutorials/tutorials.component';
import { AuthRoutePath } from '../_models/auth';
import { DomainCheckerService } from '../../services/domain-checker.service';
import { ConnectionService } from '../../services/connection-service';
import { UnSubscribeComponent } from '../_shared/un-subscribe.component';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../services/auth-service';
import { PageService } from '../../services/page-service';
import { MarketingPageService } from '../../services/marketing-page-service';
import { InsightsService } from '../../services/insights-service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingPageComponent extends UnSubscribeComponent implements OnInit {
  config = this.userService.envVariables;
  features = [];
  landing = [];
  fiitrLanding = [
    { image: '../../assets/fiitrme/FiiTR_main_prelogin_desktop.jpg', mobileImage: '../../assets/fiitrme/FiiTR_main_prelogin_mobile.png' },
    {
      image: '../../assets/fiitrme/FiiTR_discover_prelogin_desktop.png',
      mobileImage: '../../assets/fiitrme/FiiTR_discover_prelogin_mobile.png',
    },
    { image: '../../assets/fiitrme/FiiTR_act_prelogin_desktop.png', mobileImage: '../../assets/fiitrme/FiiTR_act_prelogin_mobile.png' },
    { image: '../../assets/fiitrme/FiiTR_share_prelogin_desktop.png', mobileImage: '../../assets/fiitrme/FiiTR_share_prelogin_mobile.png' },
    {
      image: '../../assets/fiitrme/FiiTR_reward_prelogin_desktop.png',
      mobileImage: '../../assets/fiitrme/FiiTR_reward_prelogin_mobile.png',
    },
  ];
  SHLanding = [
    { image: '../../assets/1700/Landing.jpg', mobileImage: '../../assets/1700/Landing.jpg' },
    { image: '../../assets/1700/Discover.png', mobileImage: '../../assets/1700/Discover_mobile.png' },
    { image: '../../assets/1700/Act.png', mobileImage: '../../assets/1700/Act_mobile.png' },
    { image: '../../assets/1700/Share.png', mobileImage: '../../assets/1700/Share_mobile.png' },
    { image: '../../assets/1700/Reward.png', mobileImage: '../../assets/1700/Reward_mobile.png' },
    { image: '../../assets/1700/Toolkit.png', mobileImage: '../../assets/1700/Toolkit_mobile.png' },
  ];
  altiusLifeLanding = [
    { image: '../../assets/altiuslife/landing.jpg', mobileImage: '../../assets/altiuslife/landing.jpg' },
    { image: '../../assets/altiuslife/discover.jpg', mobileImage: '../../assets/altiuslife/discover_mobile.png' },
    { image: '../../assets/altiuslife/act.jpg', mobileImage: '../../assets/altiuslife/act_mobile.png' },
    { image: '../../assets/altiuslife/share.jpg', mobileImage: '../../assets/altiuslife/share_mobile.png' },
    { image: './../assets/altiuslife/reward.jpg', mobileImage: '../../assets/altiuslife/reward_mobile.png' },
  ];
  LIQLanding = [
    { image: '../../assets/1700/Landing.jpg', mobileImage: '../../assets/1700/Landing.jpg' },
    { image: '../../assets/1700/Discover.jpg', mobileImage: '../../assets/1700/Discover_900x600.jpg' },
    { image: '../../assets/1700/Act.jpg', mobileImage: '../../assets/1700/Act_900x600.jpg' },
    { image: '../../assets/1700/Share.jpg', mobileImage: '../../assets/1700/Share_900x600.jpg' },
    { image: './../assets/1700/Reward.jpg', mobileImage: '../../assets/1700/Reward_900x600.jpg' },
  ];

  // fiitr content cards (also used for LIQ)
  usedSSO: boolean = true;
  isMarketingPage: boolean = false;
  isInsightsDomain = false;
  marketingPageData: any;

  constructor(
    public userService: UserService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public modalService: NgbModal,
    private domainChecker: DomainCheckerService,
    private connectionService: ConnectionService,
    private authService: AuthService,
    private pageService: PageService,
    private marketingPageService: MarketingPageService,
    private insightsService: InsightsService,
  ) {
    super();
    if (this.userService.isLoggedIn()) {
      if (!this.insightsService.isInsightsDomain()) {
        this.router.navigate(['/home']);
        return;
      }

      if (this.userService.hasInsightsAccess()) {
        this.router.navigate(['/insights']);
      }
    }
  }

  ngOnInit() {
    this.isInsightsDomain = this.insightsService.isInsightsDomain();
    this.isMarketingPage = this.marketingPageService.isMarketingPage();
    this.setBrandParameters();
    if (this.isMarketingPage) {
      this.marketingPageService.data
        .pipe(takeUntil(this.unSubscribeOnDestroy))
        .subscribe(res => this.marketingPageData = res);
    } else {
      this.connectionService.hideIfUsedSSO
        .pipe(takeUntil(this.unSubscribeOnDestroy))
        .subscribe(res => {
          this.usedSSO = res;
        });
    }
  }

  openAuthModal(signup?) {
    if (!signup) {
      this.router.navigateByUrl(AuthRoutePath.login);
    } else {
      this.router.navigateByUrl(AuthRoutePath.register);
    }
  }

  setBrandParameters() {
    if (this.userService.isFiitr) {
      this.landing = this.fiitrLanding;
      this.features = [
        {
          name: 'Discover',
          text: 'Unlimited access to exciting new fitness and health tips, recipes and videos.',
          featureId: 6,
          icon: 'fa fa-info',
        },
        {
          name: 'Share',
          text: 'Inspire others by sharing content and ideas with friends and in groups.',
          featureId: 4,
          icon: 'fa fa-retweet',
        },
        {
          name: 'Act',
          text: 'Plan your daily LifeActions, set goals, and measure your progress.',
          featureId: 3,
          icon: 'fa fa-line-chart',
        },
        {
          name: 'Reward',
          text: 'Earn badges as you learn and track your progress.',
          featureId: 5,
          icon: 'fa fa-trophy',
        },
      ];
    } else if (this.userService.is1700 || this.userService.isBFFLife) {
      this.landing = this.SHLanding;
      this.features = [
        {
          name: 'DISCOVER',
          text: 'Browse hundreds of expert-written articles, videos and recipes.',
          featureId: 6,
          icon: 'fa fa-info',
        },
        {
          name: 'SHARE',
          text:
            'Inspire others by sharing content and ideas with friends and in groups.',
          featureId: 4,
          icon: 'fa fa-retweet',
        },
        {
          name: 'ACT',
          text: 'Plan your daily LifeActions, set goals, and measure your progress.',
          featureId: 3,
          icon: 'fa fa-line-chart',
        },
        {
          name: 'REWARD',
          text: 'Earn badges as you learn and track your progress and access My Rewards for amazing discounts and offers.',
          featureId: 5,
          icon: 'fa fa-trophy',
        },
        {
          name: 'TOOLKIT',
          text: 'Parenting tools, partner services, company policies, employee support information and more...',
          featureId: 56,
          icon: 'fa fa-briefcase',
        },
      ];
    } else if (this.userService.isAltiusLife) {
      this.landing = this.altiusLifeLanding;
      this.features = [
        {
          name: 'DISCOVER',
          text: 'Browse hundreds of expert-written articles, videos and recipes.',
          featureId: 6,
          icon: 'fa fa-info',
        },
        {
          name: 'SHARE',
          text:
            'Inspire others by sharing content and ideas with friends and in groups.',
          featureId: 4,
          icon: 'fa fa-retweet',
        },
        {
          name: 'ACT',
          text: 'Plan your daily LifeActions, set goals, and measure your progress.',
          featureId: 3,
          icon: 'fa fa-line-chart',
        },
        {
          name: 'REWARD',
          text: 'Earn badges as you learn and track your progress and access My Rewards for amazing discounts and offers.',
          featureId: 5,
          icon: 'fa fa-trophy',
        },
        {
          name: 'TOOLKIT',
          text: 'Parenting tools, partner services, company policies, employee support information and more...',
          featureId: 56,
          icon: 'fa fa-briefcase',
        },
      ];
    } else if (this.userService.isLifeIQ) {
      this.landing = this.LIQLanding;
      this.features = [
        {
          name: 'Discover',
          text: 'Browse hundreds of expert-written articles, videos and recipes.',
          featureId: 6,
          icon: 'fa fa-info',
        },
        {
          name: 'Act',
          text:
            'Plan your daily LifeActions, set long term goals, measure and view your progress.',
          featureId: 3,
          icon: 'fa fa-line-chart',
        },
        {
          name: 'Share',
          text: 'Connect and share with your friends and in groups.',
          featureId: 4,
          icon: 'fa fa-retweet',
        },
        {
          name: 'Reward',
          text: 'Earn badges and access exclusive rewards.',
          featureId: 5,
          icon: 'fa fa-trophy',
        },
      ];
    }
  }

  isShowLoginLink() {
    return this.userService.isFiitr || this.userService.is1700 || this.userService.isBFFLife;
  }

  click(event) {
    const href = event.target.getAttribute('href');
    if (href) {
      event.preventDefault();
      this.modalService.open(TutorialsComponent);
    }
  }

  isShowRegisterLink() {
    return this.userService.envVariables.app.hasHomePageRegisterButton;
  }

  onSignUpClick() {
    if (this.usedSSO) {
      this.connectionService.handleSSOLogin();
    } else {
      this.router.navigateByUrl(AuthRoutePath.register);
      this.authService.handleSignUpProcess();
    }
  }

  onLoginClick() {
    if (this.usedSSO) {
      this.connectionService.handleSSOLogin();
    } else {
      this.router.navigateByUrl(AuthRoutePath.login);
    }
  }

  getLandingImage() {
    return `url(${this.landing[0].image})`;
  }

  onContactButtonClick() {
    if (this.marketingPageData) {
      window.location.href = this.marketingPageData.contactUsURL;
    }
  }

  isUserLoggedId() {
    return this.userService.isLoggedIn();
  }
}
