import { Injectable } from '@angular/core';
import { UserService } from './user-service';
import { Chart } from 'chart.js';

@Injectable({
  providedIn: 'root'
})
export class ChartingService {
  pointRadiusValue = 7;
  goalAchievedColor = '#4caf50';
  goalMissedColor = '#eae8e8';
  chartColor = this.userService.company.primaryColor;
  dotColor = '#CCCCCC';
  chart: any;
  data: any;

  constructor(public userService: UserService) { }

  drawLineGraphValues() {
    const ctx = this.chart.ctx;
    ctx.font = Chart.helpers.fontString(
      Chart.defaults.global.defaultFontSize,
      'normal',
      Chart.defaults.global.defaultFontFamily
    );
    ctx.fillStyle = this.chart.config.options.defaultFontColor;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';
    this.data.datasets.forEach(function(dataset) {
      for (let i = 0; i < dataset.data.length; i++) {
        if (
          dataset.hidden === true &&
          dataset._meta[Object.keys(dataset._meta)[0]].hidden !== false
        ) {
          continue;
        }
        const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
        if (dataset.data[i] !== null) {
          ctx.fillText(dataset.data[i], model.x - 1, model.y - 8);
        }
      }
    });
  }

  getUserPrimaryRgb() {
    return this.userService.hexToRgbA(this.userService.company.primaryColor, false);
  }

  getUserPrimaryRgbAlphaColors(alphas: number[]) {
    const rgb = this.getUserPrimaryRgb();
    return alphas.map(a => {
      return `rgba(${rgb},${a})`;
    });
  }

}
