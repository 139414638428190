import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

import { UtilService } from '../../../../services/util-service';
import { AuthService, ILoginResponse } from '../../../../services/auth-service';
import { IStripePlan } from '../../../_models/stripe-plan';
import { IThirdPartyPaymentConfig } from '../../../_models/environment-config';
import { BootstrapModalComponent } from '../../../../components/bootstrap-modal/bootstrap-modal.component';
import { UserService } from '../../../../services/user-service';
import { environment } from '../../../../environments/_active-environment/environment';
import { LogService } from '../../../../services/log.service';
import { StripePlansInputComponent } from '../../inputs/stripe-plans-input/stripe-plans-input.component';
import { StripeService } from '../../../../services/stripe-service';
import { AuthRoutePath } from '../../../_models/auth';
import { ConnectionService } from '../../../../services/connection-service';
import { UnSubscribeComponent } from '../../../_shared/un-subscribe.component';
import { takeUntil } from 'rxjs/operators';
import { InsightsService } from '../../../../services/insights-service';

@AutoUnsubscribe()
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent extends UnSubscribeComponent implements OnInit {
  // Template display logic
  isLoading: boolean;
  isLoggedIn = false;
  isRegistered = false;
  isStripeSubscriptionRequired: boolean;
  isSubscriptionReactivationRequired: boolean;
  isThirdPartyRedirect: boolean;
  // parentForm
  form: FormGroup;

  // Local variables and observables
  selectedStripePlan$: Observable<IStripePlan>;
  selectedStripePlanSubscription: Subscription;

  customerStripeToken$: Observable<any>;
  customerStripeTokenSubscription: Subscription;

  thirdPartyPaymentEntity: IThirdPartyPaymentConfig;

  redirectUrl: string;

  usedSSO$: Observable<boolean>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private authService: AuthService,
    private utilService: UtilService,
    private userService: UserService,
    private logService: LogService,
    private stripeService: StripeService,
    private activatedRoute: ActivatedRoute,
    private connectionService: ConnectionService,
    private insightsService: InsightsService,
  ) {
    super();
  }

  get email() {
    return this.form.get('email');
  }

  get msisdn() {
    return this.form.get('msisdn');
  }

  get password() {
    return this.form.get('password');
  }

  initialiseVariables() {
    this.isLoading = false;
    this.isStripeSubscriptionRequired = false;
    this.isSubscriptionReactivationRequired = false;
    this.isThirdPartyRedirect = false;
  }

  ngOnInit() {
    this.usedSSO$ = this.connectionService.usedSSO;
    this.connectionService.usedSSO
      .pipe(takeUntil(this.unSubscribeOnDestroy))
      .subscribe(res => {
        if (res) {
          this.connectionService.handleSSOLogin();
        } else {
          this.activatedRoute.queryParams.subscribe(params => {
            if (params['redirectUrl']) {
              this.redirectUrl = params['redirectUrl'];
            }
          });
          this.initialiseVariables();
          this.initialiseLoginForm();
          this.handleRouteParams();
          this.setStripePlansObservable();
          this.setCustomerStripeTokenObservable();
          // this.connectionService.getCompanyConnectionsByHostname(
          //   this.domainChecker.hostname,
          // );
          // this.companyConnections$ = this.connectionService.currentConnectionsByHostname;
        }
      });
  }

  handleRouteParams() {
    this.route.queryParams.subscribe((p) => {
      if (p.email) {
        this.form.patchValue({
          email: p.email,
        });
      }
      this.isRegistered = !!p.isRegistered;
    });
  }

  setStripePlansObservable() {
    this.selectedStripePlan$ = this.stripeService.selectedStripePlan$;
    this.selectedStripePlanSubscription = this.selectedStripePlan$.subscribe(() => {
    });
  }

  setCustomerStripeTokenObservable() {
    this.customerStripeToken$ = this.stripeService.customerStripeToken$;
    this.customerStripeTokenSubscription = this.customerStripeToken$.subscribe(() => {
    });
  }

  disableFormAndEnableLoader() {
    this.form.disable();
    this.isLoading = true;
  }

  enableFormAndDisableLoader() {
    this.form.enable();
    this.isLoading = false;
  }

  isFormSubmitDisabled() {
    return this.form.invalid || this.isLoading;
  }

  setInputOutlineClass(ctrl: AbstractControl) {
    return this.authService.setInputOutlineClass(ctrl);
  }

  initialiseLoginForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      msisdn: [''],
      password: ['', [Validators.required]],
    });
  }

  onSubmitLoginForm() {
    if (this.form.invalid) {
      return;
    }

    this.disableFormAndEnableLoader();
    const _body = this.form.value;
    this.authService.login(_body)
      .subscribe((res) => {
        this.enableFormAndDisableLoader();
        this.handleLoginResponse(res);
      });
  }

  handleLoginErrorResponse(res: ILoginResponse) {
    if (res.isAccountNotVerifiedError) {
      this.showAccountNotVerifiedError();
    } else if (res.isNewSubscriptionRequired) {
      this.showSubscriptionRequiredError();
    } else if (this.isStripeReactivationRequired(res)) {
      this.isSubscriptionReactivationRequired = true;
    } else if (res.isUnsuccessfulThirdPartyPaymentSubscription) {
      this.showThirdPartySubscriptionError(res.data.mobileBillingPartner);
    } else if (res.isPasswordStrengthError) {
      this.showPasswordStrengthError();
    } else {
      this.utilService.showToastError('Unsuccessful', res.err);
    }
  }

  handleLoginSuccessResponse(res: ILoginResponse) {
    this.isLoggedIn = true;
    let { user } = res;
    const { token, refreshToken } = res;
    if (!user) {
      user = res;
    }

    if (this.insightsService.isInsightsDomain() && !user.hasInsights) {
      this.showAccountNotAuthorizeForAltiusInsights();
      return;
    }

    if (token) {
      this.userService.token = token;
    }
    if (refreshToken) {
      this.userService.refreshToken = refreshToken;
    }
    this.userService.userId = user.id;
    this.userService.user = user;
    this.userService.profile = user.userProfile;
    this.userService.envVariables = environment;
    this.userService.updateTimezoneAndLastlogin();
    this.userService.changeProfilePicObs();
    this.logService.logLogin();

    if (!this.userService.company) {
      this.userService.company = user.userProfile.company;
    }

    this.userService
      .sendTagsToOneSignal(
        user.userProfile.id,
        user.userProfile.username,
        user.userProfile.company.id,
      );

    // sso login redirect
    if (user.hasInsights) {
      this.insightsService.handleSSOLogin(token, refreshToken);
    } else {
      if (this.redirectUrl) {
        this.router.navigateByUrl(decodeURI(this.redirectUrl))
          .then(() => {
            location.reload();
          });
      } else {
        this.router.navigateByUrl('home')
          .then(() => {
            location.reload();
          });
      }
    }
  }

  refreshLoginState() {
    this.isLoggedIn = false;
    this.isLoading = false;
  }

  showAccountNotAuthorizeForAltiusInsights() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Error!';
    modal.componentInstance.message = 'Cannot access Altius Insights. Please contact administrator for more detail!';
    modal.componentInstance.singleButton = true;
    modal.result.then(() => this.refreshLoginState(), () => this.refreshLoginState());
  }

  handleLoginCreateUserProfileResponse(res: ILoginResponse) {
    let { token, refreshToken, user } = res;
    if (!user) {
      user = res;
    }
    this.userService.token = token;
    if (refreshToken) {
      this.userService.refreshToken = refreshToken;
    }
    this.userService.user = user;
    this.commenceNewUserProfileModalChain(user.id, user.company);
  }

  handleLoginResponse(res: ILoginResponse) {
    let { user } = res;
    if (!user) {
      user = res;
    }
    if (res.success === false) {
      this.handleLoginErrorResponse(res);
      return;
    }
    if (this.isUserProfileRequired(user)) {
      this.handleLoginCreateUserProfileResponse(res);
    } else {
      this.handleLoginSuccessResponse(res);
    }
  }

  showUnsuccessfulLoginError() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Unsuccessful Login';
    modal.componentInstance.message = 'Error';
  }

  showAccountNotVerifiedError() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Account Not Verified';
    modal.componentInstance.message = `Please check for confirmation email. Sometimes it takes a minute or two to arrive!`;
    modal.componentInstance.confirmButtonLabel = 'Send email again';
    modal.result.then((res) => {
      if (res === true) {
        this.authService.sendVerifyEmail(this.form.value.email).subscribe((data) => {
          if (data.success === true) {
            this.showResendVerifyEmailSuccess();
          }

        });
      }
    });
  }

  showPasswordStrengthError() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Password Strength';
    modal.componentInstance.message = `Unfortunately your current password does not meet the security requirements. Please choose a new password.`;
    modal.componentInstance.confirmButtonLabel = 'Change password';
    modal.result.then((res) => {
      if (res === true) {
        this.authService.generateChangePasswordToken(this.form.value.email).subscribe((data) => {
          if (data.success === true) {
            this.router.navigateByUrl(`${AuthRoutePath.resetPassword}?token=${data.token}`);
          }
        });
      }
    });
  }

  showThirdPartySubscriptionError(mobileBillingPartner: string) {
    this.thirdPartyPaymentEntity = this.userService.getThirdPartyPaymentEntity(mobileBillingPartner);
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Unsuccessful Login / Startup';
    modal.componentInstance.message = `
    <br />
    Your payment subscription with ${this.thirdPartyPaymentEntity.name}
    is NOT current.
    <br />
    If you would like to sign up to use Fiitr.me, please visit ${this.thirdPartyPaymentEntity.name}.
    <br />`;
    modal.componentInstance.confirmButtonLabel = 'Go to ' + this.thirdPartyPaymentEntity.name;
    modal.componentInstance.closeButtonLabel = 'Close';
    modal.result.then((res) => {
      if (res === true) {
        window.location.href = this.thirdPartyPaymentEntity.landingPageUrl;
      }
    });
  }

  commenceNewUserProfileModalChain(userId: string, company: any) {
    this.showBackgroundForUserProfileModal(company);
  }

  showCompanySubscriptionPlans() {
    this.stripeService.getUserEmailCompanyStripePlans(this.email.value).subscribe((res) => {
      if (res.success === false) {
        return;
      }
      this.stripeService.setCompanyStripePlans(res);
      const modal = this.modalService.open(StripePlansInputComponent);
      modal.result.then(() => {
        this.isLoading = true;
        this.selectedStripePlan$.subscribe((plan) => {
          this.stripeService.openStripe(this.userService.getStripeToken(), plan, this.email.value);
          this.isLoading = false;
        });

      }, () => {
        console.log('user made no selection.');
      });
    });
  }

  private isUserProfileRequired(user): boolean {
    return user.userProfile === null;
  }

  private showBackgroundForUserProfileModal(company) {
    this.router.navigateByUrl('/create');
    this.userService.loadTheming(company);
  }

  private showSubscriptionRequiredError() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Subscription required';
    modal.componentInstance.message = 'You must subscribe to continue to access the site';
    modal.componentInstance.confirmButtonLabel = 'See Plans';
    modal.componentInstance.closeButtonLabel = 'Close';
    modal.result.then((data) => {
      if (data) {
        this.showCompanySubscriptionPlans();
        return;
      }
    });
  }

  private showResendVerifyEmailSuccess() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Verify Email Resent!';
    modal.componentInstance.message = 'Please check your Email inbox or junk/spam folder for verifying your account.';
    modal.componentInstance.singleButton = true;
    modal.result.then(() => this.router.navigateByUrl(AuthRoutePath.login));
  }

  private isStripeReactivationRequired(response: any) {
    return response.isStripeCustomerRetrievalError
      || response.isStripeSubscriptionEnded
      || response.isStripeSubscriptionCancelled;
  }

  // googleOAuthSignIn() {
  //   this.authService.getGoogleAuthorization();
  // }

  isInsightsDomain() {
    return this.insightsService.isInsightsDomain();
  }
}
