<div *ngIf="activatedRoute.children.length === 0">
  <div class="jumbotron">
    <h3>Content Management System</h3>
    <p>Use this CMS to manage content displayed to users, see platform reporting metrics, and configure the underlying parameters of the platform.
    </p>
  </div>

  <hr />

</div>
<div *ngIf="activatedRoute.children.length > 0">
  <router-outlet></router-outlet>
</div>