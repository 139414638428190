import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrivacyComponent } from '../privacy/privacy.component';
import { UserService } from '../../../../services/user-service';

@Component({
  selector: 'app-privacy-collection-statement',
  templateUrl: './privacy-collection-statement.component.html',
  styleUrls: ['./privacy-collection-statement.component.scss'],
})
export class PrivacyCollectionStatementComponent implements OnInit {

  helpEmail: string;
  brand: string;
  address: string;
  abn: string;
  title: string;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.helpEmail = this.userService.envVariables.contactInfo.helpEmail;
    this.brand = this.userService.envVariables.brand;
    this.address = this.userService.envVariables.address;
    this.abn = this.userService.envVariables.abn;
    this.title = this.userService.envVariables.title;
  }

  openPrivacyPolicy() {
    this.activeModal.close();
    this.modalService.open(PrivacyComponent);
  }
}
