<app-cms-list-view *ngIf='isDisplayingList && isInitialised' imgColumn='4' [dataList]='items' [columnList]='columns'
                   [additionalNumericColumns]='additionalNumericColumns'>
</app-cms-list-view>

<div *ngIf='(isAddingItem || isEditingItem) && isInitialised' class='padding jumbotron'>
  <div class='row m-0'>
    <div *ngIf='errorMsg' class='col-12'>
      <p class='form-input-error-message' [innerHTML]='errorMsg'></p>
    </div>
    <div class='col-4'>
      <app-language-filter (languagesLoadedEvent)='loadAvailableLanguages($event)' (filterEvent)='changeLanguage($event)'></app-language-filter>
    </div>
  </div>
  <form [formGroup]='itemForm' (ngSubmit)='onSaveClick()'>
    <div class='row m-0'>
      <div class='col-12'>
        <!-- Title -->
        <div class='form-group'>
          <label class='control-label required'>Title</label>
          <input type='text' class='form-control' [ngClass]="displayFieldCss('title')" formControlName='title' />
          <span class='invalid-feedback'>Title is required</span>
        </div>

        <!-- image -->
        <div class='form-group'>
          <label for='image' class='control-label required'>
            Image
          </label>
          <div class='input-group'>
            <input type='text' name='logo' id='logo' class='form-control' [ngClass]="displayFieldCss('image')"
                   placeholder='Choose the image' formControlName='image' readonly />
            <div class='input-group-prepend'>
              <button type='button' class='input-group-text btn btn-primary' (click)='openfilestack()'>
                Browse
              </button>
            </div>
            <span class='invalid-feedback'>Image is required</span>
          </div>
        </div>

        <br />

        <!--- Feature -->
        <div class='row'>
          <div class='col-12 col-sm-6'>
            <div class='form-group'>
              <label class='control-label required'>Feature</label>
              <select id='featureId' class='form-control custom-select' (change)="onFeatureIdChange(itemForm.get('featureId').value)"
                      formControlName='featureId'>
                <option *ngIf="!itemForm.get('featureId').value" value='null' selected>Select a Feature</option>
                <option *ngFor='let item of features' [value]='item.id'>{{item.name | featureName}}
                </option>
              </select>
              <span class='invalid-feedback'>Feature is required</span>
            </div>
          </div>

          <!--- Visibility -->
          <div class='col-12 col-sm-6'>
            <div class='form-group'>
              <label class='control-label required'>Visibility</label>
              <select class='form-control' [ngClass]="displayFieldCss('visibility')" formControlName='visibility'>
                <option *ngFor='let item of visibilities' [value]='item.id'>{{item.name}}</option>
              </select>
              <span class='invalid-feedback'>a Visibility is required</span>
            </div>
          </div>
        </div>

        <!-- selectable categories -->
        <div class='form-group'>
          <label class='control-label'>Selectable Categories</label>
          <div class='row'>
            <div class='col-6 col-sm-4' *ngFor='let item of selectableCategories'>
              <div class='custom-control'>
                <input id='{{item.id}}' type='checkbox' (click)='selectSelectableCategory(item.id)' class='custom-control-input'>
                <label class='custom-control-label' for='{{item.id}}'>{{item.name}}</label>
              </div>
            </div>
          </div>
        </div>

        <!-- selected categories -->
        <div class='form-group'>
          <label for='selectedCategories' class='control-label required'>Selected Categories</label>
          <div class="row">
            <input id="categories" formControlName="categories" hidden>
          </div>
          <div class='row'>
            <div class='col-4' *ngFor='let item of selectedCategories'>
              <div class='custom-control custom-checkbox'>
                <input id='{{item.id}}' type='checkbox' checked='true' (click)='unselectSelectedCategory(item.id)'
                       [class]="isCategoriesInvalid() ? ' custom-control-input is-invalid' : 'custom-control-input' ">
                <label class='custom-control-label' for='{{item.id}}'>{{item.name}}</label>
              </div>
            </div>
            <span *ngIf='!selectedCategories.length' class='col-12 text-danger'>
              Select a category.
            </span>
          </div>
        </div>

        <br />

        <!-- Short Text -->
        <div class='form-group'>
          <label class='control-label required'>Short Text</label>
          <textarea class='form-control' [ngClass]="displayFieldCss('shortText')" formControlName='shortText'>
          </textarea>
          <span class='invalid-feedback'>Short Text is required</span>
        </div>

        <!--- longText -->
        <div class='form-group'>
          <label class='control-label required'>Content</label>
          <div [froalaEditor]='options' (froalaInit)='initialize($event)' formControlName='longText'></div>
          <span class='invalid-feedback'>Content is required.</span>
        </div>

        <!-- Author selection -->
        <div class='form-group'>
          <label class='control-label'>Author</label>
          <select type='text' class='form-control' formControlName='authorId'>
            <option *ngFor='let author of authorOptions' [value]='author.id'>
              {{author.name}}
            </option>
          </select>
        </div>

        <!-- Source  -->
        <div class='form-group'>
          <label class='control-label'>Source </label>
          <input type='text' class='form-control' formControlName='source' />
        </div>

        <!-- Source Url -->
        <div class='form-group'>
          <label class='control-label'>Source URL</label>
          <input type='text' class='form-control' formControlName='sourceUrl' />
        </div>

        <!-- Link Url -->
        <div class='form-group'>
          <label class='control-label'>Link URL</label>
          <input type='text' class='form-control' formControlName='linkUrl' />
        </div>

        <!-- Company -->
        <!--        <div class='form-group'>-->
        <!--          <app-select-company [allCompanies]='allCompanies' *ngIf='canSelectCompany' [selectedCompanyId]="itemForm.get('companyId').value"-->
        <!--                              [label]="'Company'" [placeholder]="'All (default)'" (companyIdSelected)='onCompanySelected($event)'></app-select-company>-->
        <!--        </div>-->

        <!-- Companies -->
        <div class='form-group' *ngIf='showIncludedCompanies()'>
          <!-- <app-select-companies [allCompanies]='allCompanies' *ngIf='canSelectCompany' [selectedCompanyIds]='includedCompanyIds'
                                [label]="'Companies'" [placeholder]="'-- Select company to include --'" (companyIdsSelected)='includedCompanyIdsSelected($event)'
                                [addAllOption]='true' [allOptionLabel]='"All (default)"'></app-select-companies> -->
          <multiple-select [allOptions]='allCompanies' *ngIf='canSelectCompany' [selectedOptionIds]='includedCompanyIds' [label]="'Companies'"
            [placeholder]="'-- Select company to include --'" (optionIdsSelected)='includedCompanyIdsSelected($event)'
            [addAllOption]='true' [allOptionLabel]='"All (default)"' [maxDisplayOption]="5">
          </multiple-select>
        </div>

        <!-- Excluded Companies -->
        <div *ngIf='showExcludedCompanies()' class='form-group'>
          <!-- <app-select-companies [allCompanies]='allCompanies' *ngIf='canSelectCompany' [selectedCompanyIds]='excludedCompanyIds' [label]="'Excluded Companies'"
                                [placeholder]="'-- Select company to exclude --'" (companyIdsSelected)='excludedCompanyIdsSelected($event)'></app-select-companies> -->
          <multiple-select [allOptions]='allCompanies' *ngIf='canSelectCompany' [selectedOptionIds]='excludedCompanyIds' [label]="'Excluded Companies'"
            [placeholder]="'-- Select company to exclude --'" (optionIdsSelected)='excludedCompanyIdsSelected($event)'  [maxDisplayOption]="5">
          </multiple-select>
        </div>

        <div class='row'>
          <!--- Published -->
          <div class='col-12 col-sm-12'>
            <div class='form-group'>
              <label class='control-label required'>Publish</label>
              <br />
              <div class='custom-control custom-radio custom-control-inline mt-2'>
                <input type='radio' id='published1' value='true' class='custom-control-input' formControlName='published' />
                <label class='custom-control-label' for='published1'>Yes</label>
              </div>
              <div class='custom-control custom-radio custom-control-inline'>
                <input type='radio' id='published2' value='false' class='custom-control-input' formControlName='published' />
                <label class='custom-control-label' for='published2'>No</label>
              </div>
              <span class='invalid-feedback'>
                <br /> Publish is required.
              </span>

            </div>
          </div>
          <!--- Publish Date  -->
          <div class='col-12 col-sm-6'>
            <div class='form-group'>
              <label for='publishedDate' class='control-label required'>Publish Date</label>
              <div class='input-group'>
                <input placeholder='yyyy-mm-dd' class='form-control' [ngClass]="displayFieldCss('publishedDate')"
                       formControlName='publishedDate' (blur)='pd.close()' [showWeekdays]='false' #pd='ngbDatepicker'
                       ngbDatepicker />
                <div class='input-group-append'>
                  <button class='btn btn-outline-secondary' (click)='pd.toggle();' type='button'>
                    <i class='fa fa-calendar'></i>
                  </button>
                </div>
                <div class='invalid-feedback'>
                  Publish date is required.
                </div>
              </div>
            </div>
          </div>

          <div class='col-12 col-sm-6 float-right'>
            <div class='form-group'>
              <label for='endDate' class='control-label'>End Date</label>
              <div class='input-group'>
                <input placeholder='yyyy-mm-dd' class='form-control' [ngClass]="displayFieldCss('endDate')"
                  formControlName='endDate' (blur)='pdb.close()' [showWeekdays]='false' #pdb='ngbDatepicker'
                  ngbDatepicker [minDate]="getMinEndDate()" />
                <div class='input-group-append'>
                  <button class='btn btn-outline-secondary' (click)='pdb.toggle();' type='button'>
                    <i class='fa fa-calendar'></i>
                  </button>
                </div>
                <div class='invalid-feedback'>
                  End date invalid.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class='row mt-2'>
          <!--- Reading Time -->
          <div class='col-12 col-sm-6'>
            <div class='form-group'>
              <label class='control-label'>Reading time (minutes)</label>
              <input type='number' class='form-control' formControlName='readingTime' />
            </div>
          </div>
          <!--- Copyright -->
          <div class='col-12 col-sm-6'>
            <div class='form-group'>
              <label class='control-label' for='copyright'>Copyright</label>
              <input type='text' name='copyright' class='form-control' formControlName='copyright' />
            </div>
          </div>
        </div>
        <div class='row mt-4 pb-2'>
          <div class='col-6'>
            <label class='control-label'>Life Actions </label>
          </div>
          <div class='col-6 text-right'>
            <button type='button' class='btn btn-primary' (click)='getLifeActions()'>
              <i class='fa fa-plus mr-2' aria-hidden='true'></i> Add Life Actions
            </button>
          </div>
        </div>

        <div class='table-responsive shadow-lg'>
          <table class='table table-bordered table-striped'>
            <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Category</th>
              <th class='text-center'>Delete</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor='let item of indicators'>
              <td>{{item[0]}}</td>
              <td>{{item[1]}}</td>
              <td>{{item[2]}}</td>
              <td (click)='removeIndicator(item)' class='text-center'>
                <i class='fa fa-trash' aria-hidden='true'></i>
              </td>
            </tr>
            <tr *ngIf='!indicators.length'>
              <th colspan='4' class='text-center'>No LifeActions added to the article</th>
            </tr>
            <!-- <tr>
              <th colspan="4" class="text-center">
                <button type="button" class="btn btn-primary w-48" (click)="getLifeActions()">
                  <i class="fa fa-plus mr-2" aria-hidden="true"></i> Add LifeActions
                </button>
              </th>
            </tr> -->
            </tbody>
          </table>

        </div>
        <div class='previewButton py-3'>
          <button type='button' data-toggle='tooltip' data-placement='top' title='Preview' class='btn btn-primary btn-circle' [disabled]='!itemForm.valid'
                  (click)='showPreview()'>
            <div class='text'>
              <i class='fa fa-eye fa-2x' aria-hidden='true'></i>
              <p>Preview</p>
            </div>
          </button>
        </div>
        <!-- Buttons -->
        <div class='row'>
          <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
            <button type='button' class='btn btn-outline-primary w-100' (click)='onBackClick()'>
              <i class='fa fa-undo mr-2' aria-hidden='true'></i> BACK
            </button>
          </div>

          <div *ngIf='isEditingItem' class='col-4'>
            <button type='button' class='btn btn-outline-primary w-100' [disabled]='!canRespondToButtons' (click)='onDeleteClick()'>
              <i class='fa fa-trash mr-2' aria-hidden='true'></i> DELETE
            </button>
          </div>

          <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
            <button type='submit' class='btn btn-primary float-right w-100' [disabled]='!canRespondToButtons || isFormInvalid()'>
              <i class='fa fa-floppy-o mr-2' aria-hidden='true'></i> SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>



