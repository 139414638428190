import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuestionnaireService } from '../../../../services/questionnaire-service';
import { UtilService } from '../../../../services/util-service';
import * as _ from 'lodash';

@Component({
  selector: 'app-range-answers-edit',
  templateUrl: './range-answers-edit.component.html',
  styleUrls: ['./range-answers-edit.component.scss'],
})
export class RangeAnswersEditComponent implements OnInit {
  @Input() questionId;
  @Input() hasAnswers;
  rangeAnswers = [];
  rangeStart;
  rangeEnd;
  rangeForm: FormGroup;
  showRangeForm = false;
  showPointsInputs = true;
  @Output() finishedRangeEdit: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fb: FormBuilder, private questionnaireService: QuestionnaireService, private utilService: UtilService) {
  }

  ngOnInit() {
    if (!this.hasAnswers) {
      this.showRangeForm = true;
      this.initialiseRangeForm();
    } else {
      this.questionnaireService.getAnswersByQuestionId(this.questionId).subscribe(answers => {
        this.rangeAnswers = answers;
        this.setRangeStartEnd(answers);
        this.showPointsInputs = false;
      });
    }
  }

  setRangeStartEnd(answers) {
    const answersWithoutIdontKnow = answers.filter(a => a.label !== `I don't know`);
    this.rangeStart = Math.min.apply(Math, answersWithoutIdontKnow.map(function(a) {
      return a.value;
    }));
    this.rangeEnd = Math.max.apply(Math, answersWithoutIdontKnow.map(function(a) {
      return a.value;
    }));
  }

  initialiseRangeForm() {
    this.rangeForm = this.fb.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
  }

  updateRange(startValue, endValue) {
    const currentStart = this.rangeStart;
    const currentEnd = this.rangeEnd;
    const currentRangeIntegers = _.range(currentStart, currentEnd + 1, 1);
    const newRangeIntegers = _.range(startValue, endValue + 1, 1);

    const differenceAfter = _.differenceWith(newRangeIntegers, currentRangeIntegers);
    const differenceBefore = _.differenceWith(currentRangeIntegers, newRangeIntegers);
    const totalDifference = [...differenceAfter, ...differenceBefore];
    const newValues = _.intersection(totalDifference, newRangeIntegers);
    const valuesToDelete = _.differenceWith(totalDifference, newRangeIntegers);

    // delete values first
    this.rangeAnswers.map(answer => {
      valuesToDelete.map(val => {
        if (answer.value === val) {
          this.questionnaireService.deleteAnswer(answer.id).subscribe(() => {
          });
        }
      });
    });

    newValues.map(val => {
      const answer = {
        label: val,
        value: val,
        value2: null,
        liqQuestionId: this.questionId,
        answersDependency: null,
        questionDependencyId: null,
        icon: null,
      };
      this.persistRangeAnswer(answer);
    });
    this.questionnaireService.getAnswersByQuestionId(this.questionId).subscribe(answers => {
      this.rangeAnswers = answers;
      this.setRangeStartEnd(this.rangeAnswers);
      this.showRangeForm = false;
      this.finishedRangeEdit.emit(answers);
    });
  }

  addRange(startValue, endValue) {
    let i;
    for (i = startValue; i < endValue + 1; i++) {
      const answer = {
        label: i,
        value: i,
        value2: null,
        liqQuestionId: this.questionId,
        answersDependency: null,
        questionDependencyId: null,
        icon: null,
        points: i,
      };

      this.persistRangeAnswer(answer);

    }
    this.showRangeForm = false;
    this.getNewAnswers();
  }

  isUnknownAnswer(answer) {
    return answer.label === `I don't know`;
  }

  deleteRange() {
    this.rangeAnswers.map(answer => {
      if (!this.isUnknownAnswer(answer)) {
        this.questionnaireService.deleteAnswer(answer.id).subscribe(() => {
        });
      }
    });
    this.rangeAnswers = [];
    this.finishedRangeEdit.emit(this.rangeAnswers);
    this.initialiseRangeForm();
    this.showRangeForm = true;
  }

  createAnswerRange() {
    const startValue = this.rangeForm.controls.start.value;
    const endValue = this.rangeForm.controls.end.value;
    if (this.rangeAnswers.length) {
      this.updateRange(startValue, endValue);
    } else {
      this.addRange(startValue, endValue);
    }

  }

  persistRangeAnswer(answer) {
    this.questionnaireService.addAnswer(answer)
      .subscribe(() => {
        },
        error => this.utilService.showToastError('Error while adding answer - ' + error));
  }

  getNewAnswers() {
    this.questionnaireService.getAnswersByQuestionId(this.questionId).subscribe(answers => {
      this.rangeAnswers = answers;
      this.setRangeStartEnd(answers);
      this.hasAnswers = true;
      this.finishedRangeEdit.emit(answers);
    });
  }

  editRange() {
    this.initialiseRangeForm();
    this.showRangeForm = true;
  }
}
