import { TermsComponent } from './_shared/components/terms/terms.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { LandingPageComponent } from './landing/landing.component';
import { AccountComponent } from './account/account.component';
import { AuthGuard } from './guards/auth.guard';
import { SettingsComponent } from './settings/settings.component';
import { SubscriptionComponent } from './settings/subscription/subscription.component';
import { ProfileCreationBackgroundComponent } from '../components/profile-creation-background/profile-creation-background.component';
import { RoleGuard } from './guards/role.guard';
import { SYSTEM_ROLES } from './_shared/enums';
import { CompanyRegisterInfoComponent } from './company-register-info/company-register-info.component';
import { AltiusLifeDomainGuard } from './guards/altiuslifeDomain.guard';
import { InsightsDomainGuard } from './guards/insightsDomain.guard';
import { QRComponent } from './qr/qr.component';

const appRoutes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'qr', component: QRComponent },
  {
    path: 'company-register-info',
    canActivate: [AltiusLifeDomainGuard],
    component: CompanyRegisterInfoComponent,
  },
  {
    path: 'home',
    canActivate: [AltiusLifeDomainGuard],
    loadChildren: './home/home.module#HomeModule',
  },
  {
    path: 'page',
    canActivate: [AltiusLifeDomainGuard],
    loadChildren: './page/page.module#PageModule',
  },
  {
    path: 'profile',
    component: AccountComponent,
    canActivate: [AuthGuard, AltiusLifeDomainGuard],
  },
  {
    path: 'bookmarks',
    loadChildren: './bookmarks/bookmarks.module#BookmarksModule',
    canActivate: [AuthGuard, AltiusLifeDomainGuard],
  },
  {
    path: 'notifications',
    canActivate: [AltiusLifeDomainGuard],
    loadChildren: './notifications/notifications.module#NotificationsModule',
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AltiusLifeDomainGuard],
    data: { breadcrumb: 'Settings', featureId: 83 },
    children: [
      {
        path: 'subscription',
        component: SubscriptionComponent,
        canActivate: [AuthGuard, AltiusLifeDomainGuard],
        data: { breadcrumb: 'Subscription', featureId: 15 },
      },
    ],
  },
  {
    path: 'share',
    canActivate: [AltiusLifeDomainGuard],
    loadChildren: './share/share.module#ShareModule',
  },
  {
    path: 'act',
    canActivate: [AltiusLifeDomainGuard],
    loadChildren: './act/act.module#ActModule',
  },
  {
    path: 'reward',
    canActivate: [AltiusLifeDomainGuard],
    loadChildren: './reward/reward.module#RewardModule',
  },
  {
    path: 'create',
    canActivate: [AltiusLifeDomainGuard],
    component: ProfileCreationBackgroundComponent,
  },
  {
    path: 'toolkit',
    canActivate: [AltiusLifeDomainGuard],
    loadChildren: './toolkit/toolkit.module#ToolkitModule',
  },
  {
    path: 'competition',
    loadChildren: './questionnaires/questionnaires.module#QuestionnairesModule',
    canActivate: [AuthGuard, AltiusLifeDomainGuard],
  },
  {
    path: 'discover',
    canActivate: [AltiusLifeDomainGuard],
    loadChildren: './discover/discover.module#DiscoverModule',
  },
  {
    path: 'learn',
    canActivate: [AltiusLifeDomainGuard],
    loadChildren: './learn/learn.module#LearnModule',
  },
  {
    path: 'cms',
    loadChildren: './cms/cms.module#CmsModule',
    canActivate: [RoleGuard, AltiusLifeDomainGuard],
    data: {
      breadcrumb: 'Content Management System',
      roles: [SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.COORDINATOR],
    },
  },
  // {
  //   path: 'privacy-policy',
  //   component: PrivacyComponent,
  //   data: { breadcrumb: 'Privacy policy' },
  // },
  {
    path: 'terms-conditions',
    component: TermsComponent,
    data: { breadcrumb: 'Terms' },
  },
  {
    path: 'insights',
    canActivate: [AuthGuard, InsightsDomainGuard],
    loadChildren: './insights/insights.module#InsightsModule',
  },
  // {
  //   path: 'challenges',
  //   component: ChallengesComponent,
  //   data: { breadcrumb: 'Available Challenges' },
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'challenge-details/:id',
  //   component: ChallengeDetailsComponent,
  //   data: { breadcrumb: 'Challenge Details' },
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'teams',
  //   component: TeamsComponent,
  //   data: { breadcrumb: 'Available Teams' },
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'team-details/:id',
  //   component: TeamDetailsComponent,
  //   data: { breadcrumb: 'Team Details' },
  //   canActivate: [AuthGuard]
  // },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule {
}
