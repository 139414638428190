import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UnSubscribeComponent } from '../../_shared/un-subscribe.component';
import { UserService } from '../../../services/user-service';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { ActivatedRoute } from '@angular/router';
import { MarketingTemplateService } from '../../../services/marketing-template.service';
import { DYNAMIC_VALUE_FORMAT } from '../../_shared/formats';
import * as moment from 'moment';

@Component({
  selector: 'app-marketing-template-detail',
  templateUrl: './marketing-template-detail.component.html',
  styleUrls: ['./marketing-template-detail.component.scss'],
})
export class MarketingTemplateDetailComponent extends UnSubscribeComponent implements OnInit {
  // ViewChildren will capture all divs with #pageRef
  @ViewChildren('pageRef') pageRefs!: QueryList<ElementRef>;
  data: any;
  templateId: number;
  companyId: number;
  isProcessing: boolean;
  isLoading: boolean;
  pageWidth: number;
  private jsPDF: jsPDF;

  constructor(
    private marketingTemplateService: MarketingTemplateService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((route) => {
      this.templateId = route.templateId;
      this.companyId = route.companyId ? +route.companyId : this.userService.company.id;
      this.getData();
    });
  }

  async onGeneratePDF() {
    this.isProcessing = true;
    const currentDate = new Date();
    const fileName = `${this.data.company.name}_${this.data.template.description}_${moment(currentDate).format('YYYY-MM-DD')}`;
    if (!this.jsPDF) {
      await this.createPDFDoc();
    }
    this.jsPDF.save(fileName);
    this.isProcessing = false;
  }

  private async createPDFDoc() {
    for (let i = 0; i < this.data.pages.length; i++) {
      const page = this.data.pages[i];

      if (i == 0) {
        this.jsPDF = new jsPDF(page.option.orientation, 'px', [page.option.width, page.option.height], true);
      } else {
        this.jsPDF.addPage([page.option.width, page.option.height], page.option.orientation);
      }

      const canvas = await html2canvas(this.pageRefs.toArray()[i].nativeElement, {
        scale: page.option.scale || 2,
        useCORS: true,
        allowTaint: true,
      });
      const image = canvas.toDataURL();
      this.jsPDF.addImage({
        imageData: image,
        x: 0,
        y: 0,
        width: page.option.width,
        height: page.option.height,
        compression: 'MEDIUM', // 'NONE', 'FAST', 'MEDIUM', 'SLOW'
      });
      // add links
      if (page.hasDynamicFields && page.dynamicLinks.length > 0) {
        page.dynamicLinks.map(link => {
          this.jsPDF.link(link.x, link.y, link.width, link.height, { url: link.value });
        });
      }
    }

    this.jsPDF.setProperties({
      title: `Marketing Template`,
      subject: this.data.template.name,
      author: 'Altius',
      creator: 'Altius',
    });
  }

  private processDynamicFields() {
    this.data.pages.map(p => {
      if (p.hasDynamicFields) {
        p.dynamicTexts.map(x => {
          this.replaceDynamicValueWithRealData(x);
        });
        p.dynamicQRs.map(x => {
          this.replaceDynamicValueWithRealData(x);
        });
        p.dynamicLinks.map(x => {
          this.replaceDynamicValueWithRealData(x);
        });
      }
    });
  }

  private replaceDynamicValueWithRealData(x) {
    let match;
    const fields = [];
    // Use exec in a loop to extract the captured content
    while ((match = DYNAMIC_VALUE_FORMAT.exec(x.value)) !== null) {
      fields.push(match[1]); // match[1] contains the content inside the curly braces
    }
    fields.forEach(f => {
      x.value = x.value.replace(`{${f}}`, this.data.company[`${f}`]);
    });
  }

  private getData() {
    this.isLoading = true;
    this.marketingTemplateService.getTemplateData(this.templateId, this.companyId)
      .subscribe((res) => {
        this.isLoading = false;
        this.data = res;
        this.pageWidth = this.data.pages[0].option.width;
        this.processDynamicFields();
      });
  }
}
