import { IContentDetail } from '../app/_models/content-detail';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { get, isArray, isEmpty, toNumber } from 'lodash';

import { FeatureService } from './feature-service';
import { SynchronousCacheService } from './synchronous-cache.service';
import { IEnvironment, IThirdPartyPaymentConfig } from '../app/_models/environment-config';
import { IUser } from '../app/_models/user';
import { IUserProfile } from '../app/_models/user-profile';
import { IIdNamePair } from '../app/_models/id-name-pair';
import { environment } from '../environments/_active-environment/environment';
import { DateService } from './date.service';
import { AWS_PATHS, FEATURES, SYSTEM_ROLES, USER_STATUSES } from '../app/_shared/enums';
import { ICompany } from '../app/_models/company';

declare var require: any;
const { version: appVersion } = require('../../package.json');

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  private static USER_ID = 'USER_ID';
  private static USER = 'USER';
  private static TOKEN = 'TOKEN';
  private static REFRESH_TOKEN = 'REFRESH_TOKEN';
  private static TEMPORARYTOKEN = 'TEMPORARYTOKEN';
  private static PIN = 'PIN';
  private static CATEGORY_ORDER = 'CATEGORY_ORDER';
  private static LAST_LOGIN_AT = 'LAST_LOGIN_AT';
  private static FITBIT_TOKEN = 'FITBIT_TOKEN';
  private static ONESIGNALUSERID = 'oneSignalUserId';
  private static ONESIGNALINDEX = 'oneSignalIndex';
  private static PLATFORM = 'PLATFORM';

  private envVariablesInMemoryStorage: any;
  private preRegistrationCompanyInMemoryStorage: any;

  private tokenStorageSubject = new BehaviorSubject(null);
  public tokenStorageObservable = this.tokenStorageSubject.asObservable();

  private userStorageSubject = new BehaviorSubject(null);
  public userStorageObservable = this.userStorageSubject.asObservable();

  isFiitr = false;
  is1700 = false;
  isLifeIQ = false;
  isAltiusLife = false;
  isBFFLife = false;

  private profilePic = new BehaviorSubject<string>(this.getDefaultProfileImage());
  profilePic$ = this.profilePic.asObservable();

  private SystemRoleUserId = 1;
  private SystemRoleCoordinatorId = 4;
  private SystemRoleSuperAdminId = 5;
  private SystemRoleAdminId = 6;

  private SystemRoleUserName = 'User';
  private SystemRoleCoordinatorName = 'Coordinator';
  private SystemRoleSuperAdminName = 'Super Admin';
  private SystemRoleAdminName = 'Admin';

  private StatusGuestId = 1;
  private StatusUserId = 2;

  private StatusGuestName = 'Guest';
  private StatusUserName = 'User';

  private allSystemRoles: IIdNamePair[] = [
    { id: this.SystemRoleUserId, name: this.SystemRoleUserName },
    { id: this.SystemRoleCoordinatorId, name: this.SystemRoleCoordinatorName },
    { id: this.SystemRoleAdminId, name: this.SystemRoleAdminName },
    { id: this.SystemRoleSuperAdminId, name: this.SystemRoleSuperAdminName },
  ];

  private allStatuses: IIdNamePair[] = [
    { id: this.StatusGuestId, name: this.StatusGuestName },
    { id: this.StatusUserId, name: this.StatusUserName },
  ];

  constructor(
    public http: HttpClient,
    private feature: FeatureService,
    private dateService: DateService,
    private cacheService: SynchronousCacheService,
  ) {
    this.envVariables = environment;
    this.tokenStorageSubject.next(this.token);
    this.userStorageSubject.next(this.user);
    window.addEventListener('storage', this.listenStorageChange.bind(this));
    window.addEventListener('storage', this.listenUserStorageChange.bind(this));
  }

  ngOnDestroy() {
    window.removeEventListener('storage', this.listenStorageChange.bind(this));
    window.removeEventListener('storage', this.listenUserStorageChange.bind(this));
  }

  listenStorageChange(event: StorageEvent) {
    this.tokenStorageSubject.next(this.token);
  }

  listenUserStorageChange(event: StorageEvent) {
    this.userStorageSubject.next(this.user);
  }

  allStaticSystemRoles(): IIdNamePair[] {
    return this.allSystemRoles;
  }

  roleAdminId() {
    return this.SystemRoleAdminId;
  }

  roleSuperAdminId() {
    return this.SystemRoleSuperAdminId;
  }

  roleUserId() {
    return this.SystemRoleUserId;
  }

  roleAdminPair(): IIdNamePair {
    return { id: this.SystemRoleAdminId, name: this.SystemRoleAdminName };
  }

  roleSuperAdminPair(): IIdNamePair {
    return { id: this.SystemRoleSuperAdminId, name: this.SystemRoleSuperAdminName };
  }

  allStaticStatuses(): IIdNamePair[] {
    return this.allStatuses;
  }

  systemRoleName(id: number): string {
    const item = this.allSystemRoles.find((i) => i.id === id);
    if (!item) {
      return null;
    }

    return item.name;
  }

  statusName(id: number): string {
    const item = this.allStatuses.find((i) => i.id === id);
    if (!item) {
      return null;
    }

    return item.name;
  }

  getUsers() {
    const url = `/cms/users`;

    return this.http.get<any>(url);
  }

  getCompanyUsers(companyId: number) {
    const url = `/cms/users/company/${companyId}`;

    return this.http.get<any>(url);
  }

  getUserById(id: number) {
    const url = `/cms/users/${id}`;

    return this.http.get<any>(url);
  }

  getNewUser(): IUser {
    return {
      id: 0,
      status: USER_STATUSES.GUEST,
      system_role: SYSTEM_ROLES.USER,
      dateOfBirth: null,
      gender: -1,
      createdAt: new Date(),
      isModified: false,
    };
  }

  updateMe(user: IUser) {
    const body = {
      status: user.status,
      system_role: user.system_role,
      email: user.email,
      msisdn: user.msisdn,
      companyId: user.companyId,
      employeeId: user.employeeId,
      genderString: user.genderString,
      dateOfBirth: this.dateService.formatYYYY_MM_DD(user.dateOfBirth),
    };
    const url = `/users/me`;

    return this.http.put<any>(url, body);
  }

  updateUser(user: IUser) {
    const body = {
      status: user.status,
      system_role: user.system_role,
      email: user.email,
      msisdn: user.msisdn,
      companyId: user.companyId,
      employeeId: user.employeeId,
      gender: user.gender,
      dateOfBirth: this.dateService.formatYYYY_MM_DD(user.dateOfBirth),
      hasInsights: user.hasInsights,
    };
    const url = `/cms/users/${user.id}`;

    return this.http.put<any>(url, body);
  }

  deleteUser(id: number) {
    const url = `/cms/users/${id}`;

    return this.http.delete<any>(url);
  }

  getMe() {
    const url = '/users/me';

    return this.http.get<IUser>(url)
      .pipe(tap((data: IUser) => {
        data.isModified = false;
      }));
  }

  getPreferences() {
    const url = '/users/preferences/me';

    return this.http.get<any>(url);
  }

  updatePreferences(ids: number[]) {
    const body = (ids.length)
      ? { preferences: ids.toString() }
      : {};
    const url = `/users/preferences/me`;

    return this.http.put<any>(url, body);
  }

  setUserPropertiesFromLocalMe() {
    this.getLocalStorageUser().subscribe();
  }

  getLocalStorageUser() {
    const url = '/users/localme';

    return this.http.get<any>(url);
  }

  refreshLocalStorageUser() {
    return this.getLocalStorageUser()
      .pipe(
        tap(user => {
          this.user = user;
        }),
      );
  }

  getLoggedInUserCompany() {
    const url = `/companies/user/me`;

    return this.http.get<any>(url);
  }

  getMyColleagues() {
    const url = '/users/colleagues/me';

    return this.http.get<any>(url);
  }

  getProfilePic(): Observable<string> {
    return this.profilePic$;
  }

  changeProfilePicObs(res?) {
    this.profilePic.next(this.getProfilePicture(res));
  }

  refreshCurrentProfileSrc() {
    const photoSrc = this.getMyPhotoSrc();
    this.profilePic.next(photoSrc);
  }

  getPlatformTitle() {
    if (!this.envVariables) {
      return null;
    }

    return this.envVariables.title;
  }

  getUserStorageAbsolutePath(path, filename) {
    if (path.slice(-1) !== '/') {
      path += '/';
    }
    return `${this.envVariables.aws.awsBucket}${path}${filename}`;
  }

  hasStripe() {
    return this.envVariables && this.envVariables.app.hasStripe;
  }

  getStripeToken(): string {
    if (!this.envVariables
      || !this.envVariables.payments.stripe.stripeToken) {
      return '';
    }

    return this.envVariables.payments.stripe.stripeToken;
  }

  getThirdPartyPaymentEntity(mobileBillingPartner: string): IThirdPartyPaymentConfig {
    if (!this.envVariables) {
      return null;
    }

    let entity: IThirdPartyPaymentConfig;
    const payments = this.envVariables.payments;
    const keys = Object.keys(this.envVariables.payments);
    keys.forEach(key => {
      if (!!payments[key].mobileBillingPartner && payments[key].mobileBillingPartner === mobileBillingPartner) {
        entity = payments[key];
        return;
      }
    });

    return entity;
  }

  getThirdPartyPaymentEntityByName(name: string): IThirdPartyPaymentConfig {
    if (!this.envVariables) {
      return null;
    }

    let entity: IThirdPartyPaymentConfig;
    const payments = this.envVariables.payments;
    const keys = Object.keys(this.envVariables.payments);
    keys.forEach(key => {
      if (!!payments[key].name && payments[key].name.toLowerCase() === name.toLowerCase()) {
        entity = payments[key];
        return;
      }
    });

    return entity;
  }

  getAppVersion(): string {
    return appVersion;
  }

  getFilestackApiKey(): string {
    return this.envVariables.cms.filestackApiKey;
  }

  getFroalaApiKey(): string {
    return this.envVariables.cms.froalaApiKey;
  }

  get envVariables(): IEnvironment {
    return this.envVariablesInMemoryStorage;
  }

  set envVariables(value: IEnvironment) {
    this.envVariablesInMemoryStorage = value;
    // this.setOneSignalIndex();
    this.setBrand();
  }

  set preRegistrationCompany(value: any) {
    this.preRegistrationCompanyInMemoryStorage = value;
  }

  get preRegistrationCompany(): any {
    return this.preRegistrationCompanyInMemoryStorage;
  }

  // private setOneSignalIndex() {
  //   this.oneSignalIndex = {
  //     title: environment.title,
  //     oneSignalAppId: environment.notifications.oneSignal.oneSignalAppId,
  //     oneSignalSafariWebId: environment.notifications.oneSignal.oneSignalSafariWebId,
  //   };
  // }

  private setBrand() {
    this.isFiitr = this.isBrand('Fiitr');
    this.is1700 = this.isBrand('1700');
    this.isLifeIQ = this.isBrand('LifeIQ');
    this.isAltiusLife = this.isBrand('AltiusLife');
    this.isBFFLife = this.isBrand('BFFLife');
  }

  get brand(): string {
    return this.envVariables.brand;
  }

  private isBrand(name: string): boolean {
    if (!this.envVariables.brand) {
      return false;
    }
    return this.envVariables.brand.toLocaleLowerCase() === name.toLocaleLowerCase();
  }

  get externalContacts(): any[] {
    return this.envVariables.contactInfo.externalContacts;
  }

  get oneSignalIndex(): any {
    return localStorage.getItem(UserService.ONESIGNALINDEX);
  }

  set oneSignalIndex(value: any) {
    if (!value) {
      localStorage.removeItem(UserService.ONESIGNALINDEX);
    } else {
      localStorage.setItem(UserService.ONESIGNALINDEX, JSON.stringify(value));
    }
  }

  get oneSignalUserId(): any {
    return localStorage.getItem(UserService.ONESIGNALUSERID);
  }

  set oneSignalUserId(value: any) {
    if (!value) {
      localStorage.removeItem(UserService.ONESIGNALUSERID);
    } else {
      localStorage.setItem(UserService.ONESIGNALUSERID, value);
    }
  }

  getPrimaryColor() {
    if (!this.envVariables) {
      return '#000';
    }

    return this.envVariables.theme.primaryColor;
  }

  loadHomePageTheming(variables) {
    const style = document.createElement('style');
    const primaryColor = variables.theme.primaryColor;
    const topBarColor = variables.theme.topBarColor;
    const topTextBarColor = variables.theme.topTextBarColor;
    style.type = 'text/css';
    if (variables.brand === 'Fiitr') {
      style.innerHTML +=
        'h1, h2, h3, h4, h5, h6, .mat-dialog-title { font-family: ' +
        variables.theme.headingFont +
        ', sans-serif; letter-spacing: 1px; font-weight: 200; text-transform: uppercase;}';
      style.innerHTML += 'body { font-family:' + variables.theme.bodyFont + ', sans-serif;}';
      style.innerHTML +=
        '.content-card-title { font-family: ' +
        variables.theme.bodyFont +
        '; font-weight: 600 !important;}';
      style.innerHTML +=
        '.headerSideCard {font-family: ' +
        variables.theme.headingFont +
        '; font-weight: 700; text-transform: uppercase !important;}';
      style.innerHTML +=
        '.navbar-light .navbar-nav .nav-link {font-family: ' +
        variables.theme.headingFont +
        ';font-weight: 700; text-transform: uppercase !important; }';
      style.innerHTML += '.tag {text-transform: uppercase !important; font-weight: 300 !important}';
      style.innerHTML += '.login {color: ' + variables.topTextBarColor + '!important;}';
      style.innerHTML +=
        '.profileMenu {font-family: ' + this.envVariables.theme.headingFont + ';font-weight: 700;}';
      style.innerHTML += '.bgBottomBar { background-color: white !important;}';
      style.innerHTML += '.tag {text-transform: uppercase !important; font-weight: 300 !important}';
      style.innerHTML += 'a, .register { color: white !important;}';
    } else {
      style.innerHTML +=
        'h1, h2, h3, h4, h5, h6, .mat-dialog-title { font-family: Lato, sans-serif; letter-spacing: 3px;}';
      style.innerHTML += 'body { font-family: Source Sans Pro, sans-serif;}';
      style.innerHTML += 'h1 {letter-spacing: 0.7rem; font-weight: 400;}';
    }
    style.innerHTML += '.homeTitle {color: ' + primaryColor + ';}';
    style.innerHTML +=
      ' a, .close, .hyperlink, .btn-link { color: ' +
      primaryColor +
      ' !important; background-color: transparent !important;}';
    style.innerHTML +=
      'app-navbar, .nav-link, .navbar, .profileMenu { background-color: ' +
      topBarColor +
      '!important; color: ' +
      topTextBarColor +
      ' !important;}';
    if (variables.brand === 'Fiitr') {
      style.innerHTML +=
        'app-footer, .footer { background-color: ' +
        topBarColor +
        '!important; color: ' +
        topTextBarColor +
        ' !important;}';
      // style.innerHTML += '.custom-footer .list-inline-item { color: ' + primaryColor + '; }';
    }
    // HEADER, LINKS and BUTTONS ******
    style.innerHTML +=
      '.button, button, .btn-primary, .fabTop, .backPrimary, #onesignal-popover-allow-button { background-color: ' +
      primaryColor +
      ' !important; color: #FFFFFF !important; ' +
      'border-color: ' +
      primaryColor +
      ' !important;}';
    style.innerHTML +=
      '.btn-outline-primary, #onesignal-popover-cancel-button { background-color: transparent !important; color: ' +
      primaryColor +
      ' !important; border-color: ' +
      primaryColor +
      ' !important;} ';
    style.innerHTML +=
      '#onesignal-popover-container #onesignal-popover-dialog .popover-button.primary' +
      ' { background-color: #4285f4 !important; color: #FFFFFF !important; border-color: #4285f4 !important;}';
    style.innerHTML +=
      '#onesignal-popover-container #onesignal-popover-dialog .popover-button.secondary ' +
      ' { background-color: #FFFFFF !important; color: #4285f4 !important; border-color: #4285f4 !important;}';
    style.innerHTML += '.tooltip-inner { background-color: ' + primaryColor + ' !important;} ';
    style.innerHTML += '.list-inline-item:hover { color: ' + primaryColor + ' !important;} ';
    style.innerHTML +=
      '.colorPrimary { color: ' +
      primaryColor +
      ' !important; background-color: transparent !important;}';
    style.innerHTML += '.primaryColor { color: ' + primaryColor + ' !important;}';
    style.innerHTML +=
      '.custom-control-input:checked ~ .custom-control-indicator, .tag, .custom-control-input:active~.custom-control-indicator ' +
      '{ background-color: ' +
      primaryColor +
      ' !important; color: #FFFFFF !important;}';
    style.innerHTML +=
      '.borderPrimary, .form-control:focus { border-color: ' +
      primaryColor +
      'box-shadow: ' +
      primaryColor +
      '}';
    style.innerHTML +=
      '.custom-radio .custom-control-input:checked ~ .custom-control-label::before { background-color:' +
      primaryColor +
      '!important }';
    style.innerHTML +=
      '.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after { background-color:' +
      primaryColor +
      '!important }';
    style.innerHTML +=
      '.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before { background-color:' +
      primaryColor +
      ' !important;}';
    // TEXTAREA ******
    // style.innerHTML +=
    //   'form textarea { background-color:' + primaryColor + ' !important;}';
    // END TEXTAREA ******
    if (this.envVariables.brand === 'Fiitr') {
      style.innerHTML +=
        '.nav-item.active a.nav-link, .nav-link:hover , .nav-link.menuActive, .divUser,' +
        '.nav-tabs .nav-link.active, dropdown-item:hover, .list-inline-item:hover { color: ' +
        this.envVariables.theme.topTextBarColor +
        ' !important;}';
    }
    document.getElementsByTagName('head')[0].appendChild(style);
  }

  isSuperAdminUser() {
    return this.user && this.user.role === SYSTEM_ROLES.SUPER_ADMIN;
  }

  isAdminUser() {
    return this.user && this.user.role === SYSTEM_ROLES.ADMIN;
  }

  isCoordinatorUser() {
    return this.user && this.user.role === SYSTEM_ROLES.COORDINATOR;
  }

  isReportsSuperAdminUser() {
    return this.isSuperAdminUser() || this.isAdminUser() || this.isCoordinatorUser();
  }

  isNormalUser() {
    return this.user && this.user.role === SYSTEM_ROLES.USER;
  }

  isUser() {
    return this.isSuperAdminUser() || this.isAdminUser() || this.isCoordinatorUser() || this.isNormalUser();
  }

  isUserRoleInList(roles) {
    if (isEmpty(roles) || !isArray(roles)) {
      // no role configuration => all role can access
      return true;
    }
    const systemRoleIds = this.allSystemRoles.map(sr => sr.id);
    const validRoleIds = roles.map(rs => toNumber(rs)).filter(r => systemRoleIds.includes(r));
    const userRole = get(this.user, 'role');
    return userRole && validRoleIds.includes(userRole);
  }

  canToggleArticleLanguage() {
    const userProfile = this.profile || _.get(this.user, 'userProfile') || {};
    const userCompanyFeatures = this.getUserCompanyFeatures();
    const showLanguageOption = this.feature.isIncludeShowLanguageOptionFeature(userCompanyFeatures);
    return userProfile.lang !== 'en' && showLanguageOption;
  }

  getUserLanguagePreference() {
    const userProfile = this.profile || _.get(this.user, 'userProfile') || {};
    return userProfile.articleLanguagePreference && userProfile.articleLanguagePreference === userProfile.lang ?
      userProfile.articleLanguagePreference : 'en';
  }

  isThisProfileUser(userProfileId: number) {
    return +userProfileId === this.profile.id;
  }

  get userId(): string {
    return localStorage.getItem(UserService.USER_ID);
  }

  set userId(value: string) {
    if (value == null) {
      localStorage.removeItem(UserService.USER_ID);
    } else {
      localStorage.setItem(UserService.USER_ID, value);
    }
  }

  get user(): any {
    return JSON.parse(localStorage.getItem(UserService.USER));
  }

  set user(value: any) {
    if (!value) {
      localStorage.removeItem(UserService.USER);
    } else {
      localStorage.setItem(UserService.USER, JSON.stringify(value));
      const features = value.company.features;
      this.cacheService.set(this.cacheService.FeaturesKey, features);
    }
  }

  get platform(): any {
    return JSON.parse(localStorage.getItem(UserService.PLATFORM));
  }

  set platform(value: any) {
    if (!value) {
      localStorage.removeItem(UserService.PLATFORM);
    } else {
      localStorage.setItem(UserService.PLATFORM, JSON.stringify(value));
    }
  }

  get profile(): any {
    if (!this.user) {
      return null;
    }

    return this.user.userProfile;
  }

  get hasInsights() {
    if (!this.user) {
      return null;
    }

    return this.user.hasInsights;
  }

  set profile(value: any) {
    if (!this.user) {
      return;
    }

    const isFirstSet = !!value && !this.profile;
    const localUser = this.user;
    localUser.userProfile = value;

    localStorage.setItem(UserService.USER, JSON.stringify(localUser));
    if (isFirstSet) {
      this.changeProfilePicObs();
    }
  }

  get company(): ICompany {
    if (!this.user) {
      return null;
    }

    return this.user.company;
  }

  set company(value: ICompany) {
    if (!this.user) {
      return;
    }

    const localUser = this.user;
    localUser.company = value;

    localStorage.setItem(UserService.USER, JSON.stringify(localUser));
  }

  get token(): string {
    return localStorage.getItem(UserService.TOKEN);
  }

  set token(value: string) {
    if (value == null) {
      localStorage.removeItem(UserService.TOKEN);
    } else {
      localStorage.setItem(UserService.TOKEN, value);
    }
  }

  get refreshToken(): string {
    return localStorage.getItem(UserService.REFRESH_TOKEN);
  }

  set refreshToken(refreshToken: string) {
    if (refreshToken == null) {
      localStorage.removeItem(UserService.REFRESH_TOKEN);
    } else {
      localStorage.setItem(UserService.REFRESH_TOKEN, refreshToken);
    }
  }

  get temporaryToken(): string {
    return localStorage.getItem(UserService.TEMPORARYTOKEN);
  }

  set temporaryToken(value: string) {
    if (value == null) {
      localStorage.removeItem(UserService.TEMPORARYTOKEN);
    } else {
      localStorage.setItem(UserService.TEMPORARYTOKEN, value);
    }
  }

  get fitbit_token(): string {
    return localStorage.getItem(UserService.FITBIT_TOKEN);
  }

  set fitbit_token(value: string) {
    if (value == null) {
      localStorage.removeItem(UserService.FITBIT_TOKEN);
    } else {
      localStorage.setItem(UserService.FITBIT_TOKEN, value);
    }
  }

  get lastLoginAt(): number {
    return Number(localStorage.getItem(UserService.LAST_LOGIN_AT));
  }

  set lastLoginAt(value: number) {
    if (value == null) {
      localStorage.removeItem(UserService.LAST_LOGIN_AT);
    } else {
      localStorage.setItem(UserService.LAST_LOGIN_AT, String(value));
    }
  }

  // Mobile Application Specific
  get pin(): string {
    return localStorage.getItem(UserService.PIN);
  }

  set pin(value: string) {
    if (value == null) {
      localStorage.removeItem(UserService.PIN);
    } else {
      localStorage.setItem(UserService.PIN, value);
    }
  }

  get categoryOrder(): string[] {
    const categoryOrder = localStorage.getItem(UserService.CATEGORY_ORDER);

    if (categoryOrder == null) {
      return null;
    } else {
      return JSON.parse(categoryOrder);
    }
  }

  set categoryOrder(value: string[]) {
    if (value == null) {
      localStorage.removeItem(UserService.CATEGORY_ORDER);
    } else {
      localStorage.setItem(UserService.CATEGORY_ORDER, JSON.stringify(value));
    }
  }

  public clearUser() {
    this.userId = null;
    this.user = null;
    this.profile = null;
    this.token = null;
    this.company = null;
  }

  loadProfile() {
    if (this.profile != null) {
      return this.profile;
    } else {
      this.getProfile();
    }
  }

  getProfile() {
    return this.http.get<any>('/profiles')
      .pipe(tap((res) => (this.profile = res)));
  }

  addProfile(data) {
    const url = '/profiles';
    return this.http.post<any>(url, data)
      .pipe(
        tap(res => {
          if (res.err || !res.createdProfile) {
            this.profile = null;
          } else {
            this.profile = res.createdProfile;
            this.token = res.token;
            this.temporaryToken = res.token;
          }
        }),
      );
  }

  updateUserProfile(data: any) {
    return this.http.put<any>('/profiles', data).pipe(
      tap((res) => (this.profile = res)),
      first(),
    ).subscribe();
  }

  updateUserProfileForCMS(data: any) {
    return this.http.put<any>('/profiles', data).pipe(
      tap(() => {
        return;
      }),
      first(),
    ).subscribe();
  }

  updateMyProfile(userProfile: IUserProfile) {
    const url = '/profiles';
    const body = {
      userId: userProfile.userId,
      username: userProfile.username,
      photo: userProfile.photo,
      oneSignalUserIds: userProfile.oneSignalUserIds,
      lang: userProfile.lang,
    };

    return this.http.put<any>(url, body)
      .pipe(
        tap(res => {
          this.profile = res;
        }),
      );
  }

  updateMyArticlePreferenceLanguage(payload) {
    const url = '/profiles';
    const body = {
      articleLanguagePreference: payload.articleLanguagePreference,
      userId: payload.userId,
    };
    return this.http.put<any>(url, body)
      .pipe(
        tap(res => {
          this.profile = res;
        }),
      );
  }

  getNewProfile(userId: number, companyId: number): IUserProfile {
    return {
      id: 0,
      userId,
      companyId,
      username: '',
      photo: 'profile.png',
      oneSignalUserIds: [this.oneSignalUserId],
      isModified: false,
    };
  }

  refreshProfile() {
    this.getProfile().pipe(first());
  }

  hasGuestContent(): boolean {
    return this.envVariables.app.hasGuestContent || false;
  }

  isLoggedIn() {
    return !!this.token;
  }

  isGuest() {
    return this.hasGuestContent() && !this.isLoggedIn();
  }

  hasProfile(): boolean {
    return !!this.profile;
  }

  hasInsightsAccess(): boolean {
    return this.user && this.user.hasInsights;
  }

  isProfileLevel(): boolean {
    return this.profile && this.isCompanyUsingUserlevels();
  }

  isCompanyUsingUserlevels(): boolean {
    return this.envVariables.app.hasUserLevels;
  }

  isAccessCodeCompany(): boolean {
    return this.envVariables && this.envVariables.app.isAccessCodeCompany;
  }

  get profileConfiguration() {
    return this.envVariables;
  }

  get appUrl() {
    return this.envVariables.app.url;
  }

  get hostname() {
    const url = new URL(this.appUrl);
    return url.hostname;
  }

  get homePageTitle() {
    return this.envVariables.landingPageText.homePageTitle;
  }

  get homePageSubText() {
    return this.envVariables.landingPageText.homePageSubText;
  }

  get homePageRegisterText() {
    return this.envVariables.landingPageText.homePageRegisterText;
  }

  get hasHomePageRegisterButton() {
    return this.envVariables.app.hasHomePageRegisterButton;
  }

  get hasApp(): boolean {
    return this.envVariables.app.hasApp;
  }

  get myRewardsAction(): string {
    return this.envVariables.rewards.myRewards.myRewardsAction;
  }

  get myRewardsDomainUrl(): string {
    return this.envVariables.rewards.myRewards.myRewardsDomainUrl;
  }

  get myRewardsDomainCode(): number {
    return this.envVariables.rewards.myRewards.myRewardsDomainCode;
  }

  get appDownloadLinks() {
    return this.envVariables.appDownloadLinks;
  }

  getMyRewardsSpinnerSrc() {
    return 'assets/img/my_rewards_spinner.gif';
  }

  getDefaultProfileImage() {
    return 'assets/img/profile.png';
  }

  getGoldBadgeSrc() {
    return 'assets/img/badges/badge_gold.svg';
  }

  getSilverBadgeSrc() {
    return 'assets/img/badges/badge_silver.svg';
  }

  getBronzeBadgeSrc() {
    return 'assets/img/badges/badge_bronze.svg';
  }

  getMyPhotoSrc(): string {
    if (!this.hasProfile) {
      return this.getDefaultProfileImage();
    }

    return this.getProfilePicture(this.profile.photo);
  }

  getMyLevelSrc(): string {
    if (!this.hasProfile()) {
      return '';
    }

    return this.getLevelSrc(this.profile.level);
  }

  getLevelSrc(level: string): string {
    if (!level || !this.isCompanyUsingUserlevels()) {
      return '';
    }

    return `assets/img/badges/${level.toLowerCase()}-notext.png`;
  }

  sendEnquiry(data: any) {

  }

  hasFitbit() {
    if (!this.profile || !this.profile.devices) {
      return false;
    }

    return this.profile.devices.hasFitbit;
  }

  hasGoogleFit() {
    if (!this.profile || !this.profile.devices) {
      return false;
    }

    return this.profile.devices.hasGoogleFit;
  }

  showFeature(feature, user) {
    return this.hasCompanyFeature(+feature);
  }

  private isExistingFeatureId(featureId: number): boolean {
    const features = this.feature.getAllCachedFeatures();

    return features.indexOf(featureId) > -1;
  }

  hasCompanyFeatureOrDefault(featureId, defaultValue = false): boolean {
    if (!this.company) {
      return defaultValue;
    }

    return this.hasCompanyFeature(+featureId);
  }

  hasCompanyFeature(featureId) {
    const featureIds = this.getUserCompanyFeatures();
    const index = featureIds.indexOf(featureId);

    return index > -1;
  }

  hasAppFeature(featureId) {
    if (!this.envVariables) {
      return true;
    }

    return this.isExistingFeatureId(featureId);
  }

  hasStripeAppFeature() {
    return this.hasAppFeature(FEATURES.STRIPE);
  }

  isSubscribedUser(): boolean {
    return this.user.stripeCustomerId
      && (this.isExistingFeatureId(FEATURES.STRIPE)
        || this.hasCompanyFeature(FEATURES.STRIPE));
  }

  getUserCompanyFeatures(): number[] {
    let features = '';

    const isCached = this.cacheService.has(this.cacheService.FeaturesKey);

    if (isCached) {
      features = this.cacheService.get(this.cacheService.FeaturesKey);
    }

    if (!isCached && this.user && this.user.company) {
      features = this.user.company.features;
      const expireNowAge = 0;
      this.cacheService.set(this.cacheService.FeaturesKey,
        features, expireNowAge);
    }

    if (this.isLoggedIn()
      && (!isCached
        || this.cacheService.isExpired(this.cacheService.FeaturesKey))) {
      this.refreshFeaturesCache();
    }

    if (features === '') {
      return [];
    }

    const userFeatures = features.split(',');
    const defaultFeatures = this.getDefaultCompanyFeature();
    const mergedFeatures = _.union(userFeatures, defaultFeatures);

    try {
      return mergedFeatures.map(e => +e);
    } catch (error) {
      return [];
    }
  }

  getDefaultCompanyFeature() {
    return ['58'];
  }

  refreshFeaturesCache() {
    if (this.cacheService.isRefreshingCache(this.cacheService.FeaturesKey)
      || !this.isLoggedIn()) {
      return;
    }

    this.cacheService.beginRefreshingCache(this.cacheService.FeaturesKey);
    this.getLoggedInUserCompany()
      .subscribe(companies => {
        if (!companies.length) {
          return;
        }

        let features = companies[0].features;
        if (this.isInpexCompanyUsers()) {
          features = features + ',' + FEATURES.PEER_SUPPORT;
        }
        this.cacheService.set(this.cacheService.FeaturesKey, features);
        this.cacheService.cacheRefreshed(this.cacheService.FeaturesKey);
        this.logFeaturesCachedRefreshed(features);
      });
  }

  logFeaturesCachedRefreshed(features: string) {

  }

  loadCompanyTheming() {
    this.loadTheming(this.company);
  }

  loadTheming(company) {
    const style = document.createElement('style');
    let primaryColor;
    if (company) {
      primaryColor = company.primaryColor;
    } else {
      primaryColor = this.envVariables.theme.primaryColor;
    }
    style.type = 'text/css';
    if (this.envVariables.brand === 'Fiitr') {
      style.innerHTML +=
        'h1, h2, h3, h4, h5, h6, .mat-dialog-title { font-family: ' +
        this.envVariables.theme.headingFont +
        ', sans-serif; letter-spacing: 1px; font-weight: 200; text-transform: uppercase;}';
      style.innerHTML += 'body { font-family:' + this.envVariables.theme.bodyFont + ', sans-serif;}';
      style.innerHTML += 'home-page-title { font-family: ' + this.envVariables.theme.headingFont + ', sans-serif; }';
      style.innerHTML +=
        '.content-card-title { font-family: ' +
        this.envVariables.theme.bodyFont +
        '; font-weight: 600 !important;}';
      style.innerHTML +=
        '.headerSideCard {font-family: ' +
        this.envVariables.theme.headingFont +
        '; font-weight: 700; text-transform: uppercase !important;}';
      style.innerHTML +=
        '.navbar-light .navbar-nav .nav-link {font-family: ' +
        this.envVariables.theme.headingFont +
        ';font-weight: 700; text-transform: uppercase !important;}';
      style.innerHTML +=
        '.profileMenu {font-family: ' + this.envVariables.theme.headingFont + ';font-weight: 700; }';
      style.innerHTML += '.bgBottomBar { background-color: white !important;}';
      style.innerHTML += '.tag {text-transform: uppercase !important; font-weight: 300 !important}';
      style.innerHTML +=
        '#white-back { background-color: white !important; color:' + primaryColor + '!important}';
      style.innerHTML += '.nav-tabs .nav-link.active {background-color: white !important;}';
      style.innerHTML += '#menu-card-title {font-weight: bold;}';

    } else {
      style.innerHTML +=
        'h1, h2, h3, h4, h5, h6, .mat-dialog-title { font-family: Lato, sans-serif; letter-spacing: 3px;}';
      style.innerHTML += 'body { font-family: Source Sans Pro, sans-serif;}';
      style.innerHTML += '.profileMenu {font-family: ' + this.envVariables.theme.headingFont + ';}';
      style.innerHTML += 'h1 {letter-spacing: 0.7rem; font-weight: 400;}';
      style.innerHTML += '#menu-card-title {margin-left: auto; margin-right: auto; text-align: center;}';
    }

    // HEADER, LINKS and BUTTONS ******
    style.innerHTML +=
      ' a, .close, .hyperlink, .btn-link  { color: ' +
      primaryColor +
      ' !important; background-color: transparent !important;}';
    style.innerHTML +=
      ' a.btn-link-lg { color: ' +
      primaryColor +
      ' !important; background-color: transparent !important; padding: 1px; outline: none;}';
    style.innerHTML += ' a.btn-link-lg:focus { box-shadow: none; }';
    style.innerHTML +=
      'button.btn-widget-line { color: ' +
      primaryColor +
      ' !important; background-color: transparent !important; font-size: 80%; border: none; outline: none;}';
    style.innerHTML += 'button.btn-widget-line:focus { box-shadow: none; }';
    style.innerHTML += 'button.card-line { border-top: 1px solid #d3d3d3 !important; }';

    // program toggle button
    style.innerHTML +=
      'input:checked + .slider { background-color: ' + primaryColor + '!important}';
    style.innerHTML +=
      'input:focus + .slider { box-shadow: 0 0 1px' + primaryColor + '!important;}';

    if (
      company.bottomTextBarColor !== '#ffffff' &&
      company.bottomTextBarColor !== '#FFFFFF'
    ) {
      style.innerHTML +=
        'app-navbar, .navbar, .nav-link { background-color: ' +
        company.topBarColor +
        '!important; color: ' +
        company.topTextBarColor +
        ' !important;}';
    } else {
      style.innerHTML +=
        'app-navbar, .navbar, .nav-link { background-color: ' +
        company.topBarColor +
        '!important;  color:' +
        company.topTextBarColor +
        '!important;}';
    }
    if (this.envVariables.brand === 'Fiitr') {
      style.innerHTML +=
        'app-footer, .footer { background-color: ' +
        company.topBarColor +
        '!important; color: ' +
        company.topTextBarColor +
        ' !important;}';
    }
    style.innerHTML += `:root { --primary-color: ${primaryColor}; }`;
    style.innerHTML +=
      '.bgBottomBar:hover { background-color: ' + company.bottomBarColor + ' !important;}';
    style.innerHTML +=
      '.nav-item.active a.nav-link, .nav-link:hover , .nav-link.menuActive, .divUser,' +
      '.nav-tabs .nav-link.active, dropdown-item:hover { color: ' +
      primaryColor +
      ' !important;}';
    style.innerHTML += '.nav-tabs .nav-link {border: 1px solid #dee2e6 ;}';
    style.innerHTML +=
      'a#all.nav-link.active, a#today.nav-link.active, a#history.nav-link.active, a#mine.nav-link.active, a#levelup.nav-link.active,' +
      ' a#friends.nav-link.active, a#requests.nav-link.active, a#outgoing.nav-link.active, a#everyone.nav-link.active, a#yours.nav-link.active { color: ' +
      primaryColor +
      ' !important;}';
    style.innerHTML +=
      '.button, button, .btn-primary, .fabTop, .backPrimary { background-color: ' +
      primaryColor +
      ' !important; color: #FFFFFF !important; ' +
      'border-color: ' +
      primaryColor +
      ' !important;}';
    style.innerHTML +=
      '.btn-outline-primary { background-color: transparent !important; color: ' +
      primaryColor +
      ' !important; border-color: ' +
      primaryColor +
      ' !important;} ';
    style.innerHTML += '.tooltip-inner { background-color: ' + primaryColor + ' !important;} ';
    style.innerHTML += '.list-inline-item:hover { color: ' + primaryColor + ' !important;} ';

    // CMS
    style.innerHTML +=
      ' .table-hover tbody tr:hover, .ngx-pagination .current { background-color: ' +
      primaryColor +
      ' !important; color: #ffffff !important; }';
    // for Progress Bar
    style.innerHTML += '.progressbar li:before {  border: 1px solid #ddd; }';
    style.innerHTML += '.progressbar li:before {  background-color: #fff; }';
    style.innerHTML += '.progressbar li.active { color: ' + primaryColor + '; }';
    style.innerHTML += '.progressbar li.active:before { border-color: #ddd; background-color: ' + primaryColor + ';  color: #fff; }';
    style.innerHTML += '.progressbar li.active + li:after { background-color: ' + primaryColor + '; }';
    // Team Challenge Page
    style.innerHTML += '.t-challenge { background-color: ' + primaryColor + '; color: #fff; }';

    // Body & Background******
    style.innerHTML += 'body{ background-color: ' + company.backgroundColor + ' !important;}';
    style.innerHTML +=
      'h5, h4, h3, h2, h1, .mat-dialog-title { color: ' + company.bodyColor + ' !important;}';
    style.innerHTML +=
      '.contentAnchorTag, .contentAnchorTag:hover { color: ' + company.bodyColor + ' !important; text-decoration: none; width: 100%;}';
    style.innerHTML +=
      '.colorPrimary { color: ' +
      primaryColor +
      ' !important; background-color: transparent !important;}';
    style.innerHTML += '.primaryColor { color: ' + primaryColor + ' !important;}';
    style.innerHTML +=
      '.borderPrimary, .form-control:focus { border-color: ' +
      primaryColor +
      'box-shadow: ' +
      primaryColor +
      '}';
    style.innerHTML +=
      '.custom-control-input:checked ~ .custom-control-indicator, .tag, .custom-control-input:active~.custom-control-indicator' +
      '{ background-color: ' +
      primaryColor +
      ' !important; color: #FFFFFF !important;}';
    style.innerHTML +=
      '.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after { background-color:' +
      primaryColor +
      '!important }';
    style.innerHTML +=
      '.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before { background-color:' +
      primaryColor +
      ' !important }';
    style.innerHTML +=
      '.custom-radio .custom-control-input:checked ~ .custom-control-label::before{ background-color:' +
      primaryColor +
      '!important }';
    style.innerHTML += '.progress-bar { background-color: ' + primaryColor + ';}';

    // TEXTAREA ******
    style.innerHTML +=
      'form textarea[name=comment]:focus { outline: 1px solid ' + primaryColor + ';}';
    // END TEXTAREA ******
    // MATERIAL SLIDER
    style.innerHTML += '.mat-accent .mat-slider-thumb { background-color: ' + primaryColor + ' !important; }';
    style.innerHTML += '.mat-accent .mat-slider-track-fill { background-color: ' + primaryColor + ' !important; }';
    style.innerHTML += '.mat-accent .mat-slider-thumb-label { background-color: ' + primaryColor + ' !important; }';
    // FOOTER ******
    style.innerHTML +=
      'footer { background-color: ' +
      company.bottomBarColor +
      ' !important; color: ' +
      company.bottomTextBarColor +
      ' !important;}';
    if (this.envVariables.brand === 'Fiitr') {
      style.innerHTML +=
        '.nav-item.active a.nav-link, .nav-link:hover , .nav-link.menuActive, .divUser,' +
        '.nav-tabs .nav-link.active, dropdown-item:hover { color: ' +
        this.envVariables.theme.topTextBarColor +
        ' !important;}';
      style.innerHTML +=
        '.list-inline-item:hover { color: ' + this.envVariables.theme.topTextBarColor + ' } ';
      style.innerHTML += '.custom-footer { background-color: #d4d4d4;}';
      style.innerHTML += '.custom-footer .list-inline-item { color: ' + primaryColor + '; }';
    }
    document.getElementsByTagName('head')[0].appendChild(style);
  }

  hexToRgbA(hex, withRgba) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');

      if (withRgba) {
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
      } else {
        return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
      }
    }
    throw new Error('Bad Hex');
  }

  checkEmailExists(email) {
    const url = '/users/byEmail';
    const body = {
      email,
    };
    return this.http.post<any>(url, body);
  }

  isEmailRegistered(email: string) {
    const encodedEmail = encodeURIComponent(email);
    const url = `/users/is-email-registered?email=${encodedEmail}`;

    return this.http.get(url);
  }

  checkPhoneExists(msisdn) {
    const url = '/users/byMsisdn';
    const body = {
      msisdn,
    };
    return this.http.post<any>(url, body);
  }

  getCompanyNotifyeesCountPerSystemRole(companyId: number) {
    if (this.isSuperAdminUser() || this.isAdminUser()) {
      return this.getCompanyNotifyeesCount(companyId);
    }

    return this.getMyCompanyNotifyeesCount();
  }

  getMyCompanyNotifyeesCount() {
    const url = '/cms/users/company-notifyees-count/me';

    return this.http.get<any>(url);
  }

  getCompanyNotifyeesCount(companyId: number) {
    const url = `/cms/users/company-notifyees-count/${companyId}`;

    return this.http.get<any>(url);
  }

  getCompanyProfilesUpdatedCountPerSystemRole(companyId: number) {
    if (this.isSuperAdminUser() || this.isAdminUser()) {
      return this.getCompanyProfilesUpdatedCount(companyId);
    }

    return this.getMyCompanyProfilesUpdatedCount();
  }

  getMyCompanyProfilesUpdatedCount() {
    const url = '/cms/users/company-profiles-updated-count/me';

    return this.http.get<any>(url);
  }

  getCompanyProfilesUpdatedCount(companyId: number) {
    const url = `/cms/users/company-profiles-updated-count/${companyId}`;

    return this.http.get<any>(url);
  }

  getCompanyPlatformsCountPerSystemRole(companyId: number) {
    if (this.isSuperAdminUser() || this.isAdminUser()) {
      return this.getCompanyPlatformsCount(companyId);
    }

    return this.getMyCompanyPlatformsCount();
  }

  getCompanyPlatformsCount(companyId: number) {
    const url = `/cms/users/company-platforms-count/${companyId}`;

    return this.http.get<any>(url);
  }

  getMyCompanyPlatformsCount() {
    const url = `/cms/users/company-platforms-count/me`;

    return this.http.get<any>(url);
  }

  sendTagsToOneSignal(userProfileId, username, companyId) {
    const oneSignal = window['OneSignal'];
    if (oneSignal) {
      oneSignal.sendTags({
        userProfileId,
        username,
        companyId,
      });
    }
  }

  updateOneSignalTagsWithUserProfileData() {
    if (!this.hasProfile()) {
      return;
    }

    this.sendTagsToOneSignal(
      this.profile.id,
      this.profile.username,
      this.profile.companyId);
  }

  imageExists(url) {
    const image = new Image();
    image.src = url;
    if (image.src) {
      return true;
    }
  }

  get companyImagesPathSegment(): string {
    return 'company_images/';
  }

  getCompanyLogo(): string {
    return this.getUserStorageAbsolutePath(this.companyImagesPathSegment, this.company.logo);
  }

  getLogoForCompany(logo: string): string {
    return this.getUserStorageAbsolutePath(this.companyImagesPathSegment, logo);
  }

  getLogoSrc(): string {
    return this.isLoggedIn() ? this.getLogoForCompany(this.company.logo) : this.getLogoForCompany(this.envVariables.theme.logoImg);
  }

  getGroupsLogo(fileName): string {
    return this.getUserStorageAbsolutePath('groups/logo/', fileName);
  }

  getEAPImage(fileName): string {
    return this.getUserStorageAbsolutePath('eap_images/', fileName);
  }

  isDefaultProfileImage(photo): boolean {
    return photo.toLowerCase() === this.getDefaultProfileImage().toLowerCase();
  }

  isInpexCompanyUsers(): boolean {
    return this.company && this.company.id === 127;
  }

  getUserCompanyDomainOrigin(isInsights = false) {
    const appConfig = this.envVariables.app;
    if (appConfig.url.includes(window.location.hostname)) {
      return window.location.origin;
    }
    const company = this.company || this.user.userProfile.company;
    if (isInsights) {
      return `https://${company.insightsDomain || appConfig.insightsDomain}`;
    }
    return `https://${company.hostname || appConfig.mainDomain}`;

  }

  getProfilePicture(img?) {
    let photo;
    photo = img ? img : (this.profile && this.profile.photo ? this.profile.photo : this.getDefaultProfileImage());
    if (photo === ''
      || this.isDefaultProfileImage(photo)
      || !this.company) {
      return this.getDefaultProfileImage();
    }

    const imageUrl = this.envVariables.aws.awsBucket + AWS_PATHS.PROFILE_IMAGES + photo;
    if (!this.imageExists(imageUrl)) {
      return this.getDefaultProfileImage();
    }

    return imageUrl;
  }

  getCarouselImage(image: string): string {
    return this.getUserStorageAbsolutePath('carousel/', image);
  }

  getQuestionnaireImage(image: string): string {
    return this.getUserStorageAbsolutePath('questionnaires/', image);
  }

  getPartnerImage(image: string): string {
    return this.getUserStorageAbsolutePath('partner_images', image);
  }

  getContentItemImage(item: IContentDetail): string {
    if (item.isArticle) {
      return this.getArticleImage(item.image);
    }

    if (item.isRecipe) {
      return this.getRecipeImage(item.image);
    }

    if (item.isVideo) {
      return this.getVideoImage(item.image);
    }

    return item.image;
  }

  getArticleImage(image: string): string {
    return this.envVariables.aws.awsArticleBucket + image;
  }

  getRecipeImage(image: string): string {
    return this.envVariables.aws.awsRecipeBucket + image;
  }

  getTeamImage(image: string): string {
    return this.envVariables.aws.awsBucket + '/challenges_images/' + image;
  }

  getVideoImage(image: string): string {
    return image;
  }

  getAuthorImage(image: string): string {
    return this.envVariables.aws.awsAuthorBucket + image;
  }

  getMarketingImage(image: string): string {
    return this.envVariables.aws.awsBucket + 'marketing_images/' + image;
  }

  getImagePath(path: string): string {
    return this.envVariables.aws.awsBucket + path;
  }

  getInfoPackFile(filename: string): string {
    return this.envVariables.aws.awsBucket + 'infopacks/files/' + filename;
  }

  getInfoPackImage(filename: string): string {
    return this.envVariables.aws.awsBucket + 'infopacks/images/' + filename;
  }

  updateTimezoneAndLastlogin() {
    const timezone = new Date().getTimezoneOffset();
    const url = `/users/timezone/${timezone}/me`;

    return this.http.put<any>(url, null)
      .pipe(first())
      .subscribe();
  }

  getAltiusLifeTitle() {
    return this.envVariables.title;
  }

  getAltiusInsightsTitle() {
    return this.envVariables.insightsTitle;
  }

  getUserDetailsForExport(data) {
    const url = '/cms/users/exportusers';
    const body = {
      users: data,
    };
    return this.http.post<any>(url, body);
  }

  closeUserOwnAccount(data) {
    return this.http.put<any>('/users/me/close', data);
  }
}
