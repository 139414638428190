<div [formGroup]="parentForm">
  <small><label for="companyAccessCode">Registration Code</label></small>
  <input
    #companyCode
    [formControlName]="'companyAccessCode'"
    placeholder="Enter code"
    type="text"
    name="companyAccessCode"
    class="form-control form-control-sm"
    [ngClass]="inputcss"
    (keyup)="onInputCompanyAccessCode(companyAccessCode.value)"
  />
  <small *ngIf="!isCompanyAccessCodeResolved()" id="companyAccessCodeHelp" class="form-text text-muted">
    Don't know your code? Try your company name or check your welcome email.
  </small>
</div>
<div *ngIf="isCompanyAccessCodeInvalidError()" class="cross-validation-error-message alert alert-danger p-1">
  <small>Code not valid.</small>
</div>
<div *ngIf="isCompanyAccessCodeRequiredError()" class="cross-validation-error-message alert alert-danger p-1">
  <small>Code required.</small>
</div>
