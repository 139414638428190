import { FeatureService } from '../../services/feature-service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../../services/user-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthRoutePath } from '../_models/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    public userService: UserService,
    private featureService: FeatureService,
    public modalService: NgbModal,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    let featureId = 1;

    if (route.data && route.data.featureId) {
      featureId = route.data.featureId;
    }
    if (this.userService.token !== null) {
      if (this.hasFeature(featureId)) {
        return true;
      } else {
        this.redirectHome();
      }
    } else if (state.url.indexOf('home') > -1) {
      if (state.url.indexOf('article') > -1) {
        this.router.navigateByUrl(`${AuthRoutePath.login}?redirectUrl=${encodeURI(state.url)}`);
      } else {
        this.router.navigate(['']);
      }
      return false;
    } else if (!this.userService.hasGuestContent()) {
      this.router.navigateByUrl(`${AuthRoutePath.login}?redirectUrl=${encodeURI(state.url)}`);

      // 1700 routes
    } else if (
      (state.url.indexOf('home') > -1 ||
        state.url.indexOf('discover/home-life') > -1 ||
        state.url.indexOf('discover/work-life') > -1 ||
        state.url.indexOf('discover/parent-planner') > -1) &&
      this.userService.hasGuestContent()
    ) {
      return true;
      // Fiitr routes
    } else if (
      (state.url.indexOf('discover/articles/7/all') > -1 ||
        state.url.indexOf('discover/recipes/8/all') > -1 ||
        state.url.indexOf('discover/videos/9/all') > -1) &&
      this.userService.hasGuestContent()
    ) {
      return true;
    } else {
      // not logged in so redirect to login page with the return url
      this.router.navigateByUrl(`${AuthRoutePath.login}?redirectUrl=${encodeURI(state.url)}`);
      return false;
    }
  }

  hasFeature(featureId) {
    if (this.userService.company) {
      return this.userService.showFeature(featureId, this.userService);
    }

    return this.featureService.isExistingFeatureId(featureId);
  }

  private redirectHome(): boolean {
    this.router.navigate(['home']);
    return false;
  }
}
