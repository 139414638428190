import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { UserService } from '../../services/user-service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const roles = _.get(next, 'data.roles', null);
    const userRole = _.get(this.userService, 'user.role', null);
    const hasAccess = roles && userRole && roles.some((r) => r === userRole);
    if (hasAccess) {
      return true;
    } else {
      this.router.navigate(['/home']).then();
      return false;
    }
  }
}
