import { Component, ViewChild, Input, OnInit } from '@angular/core';

import { Chart } from 'chart.js';

@Component({
  selector: 'app-doughnut-graph',
  templateUrl: 'doughnut-graph.html'
})

export class DoughnutGraphComponent implements OnInit {
  @Input('data') data: any;
  @Input('labels') labels: any;
  @Input('color') color: any;
  @Input('legendLabel') legendLabel: any;
  @Input('legend') legend: any;
  @Input('type') type: any;


  @ViewChild('doughnutCanvas') doughnutCanvas;
  doughnutChart: any;

  constructor() { }

  ngOnInit() {
    this.createDoughnutChart();
  }

  createDoughnutChart() {
      this.doughnutChart = new Chart(this.doughnutCanvas.nativeElement, {
          type: this.type,
          data: {
            labels: this.labels,
            datasets: [{
                label: this.legendLabel,
                data: this.data,
                backgroundColor: this.color,
                borderColor: this.color,
                borderWidth: 1
            }]
          },
          options: {
              legend: {
                  display: this.legend,
              },
          }
      });
  }

}
