<div *ngIf='isDisplayingList && isInitialised'>
  <div class='mb-2 w-50' *ngIf='isSuperAdmin()'>
    <app-select-company
      [allCompanies]='allCompanies'
      [selectedCompanyId]='companyId'
      (companyIdSelected)='onCompanySelected($event)'
    ></app-select-company>
  </div>

  <app-cms-list-view
    *ngIf='isInitialised'
    [dataList]='items'
    [columnList]='columns'>
  </app-cms-list-view>
</div>

<div *ngIf='(isAddingItem || isEditingItem) && isInitialised' class='padding jumbotron'>
  <div class='row'>
    <div *ngIf='errorMsg' class='col-12'>
      <p class='form-input-error-message' [innerHTML]='errorMsg'></p>
    </div>
    <div class='col-4'>
      <app-language-filter (languagesLoadedEvent)='loadAvailableLanguages($event)' (filterEvent)='changeLanguage($event)'></app-language-filter>
    </div>
  </div>
  <form [formGroup]='itemForm' (ngSubmit)='onSaveClick()'>
    <div class='row'>
      <div class='col-4'>
        <div class='form-group' *ngIf='isSuperAdmin()'>
          <label class='control-label required'>Company</label>
          <select *ngIf='isAddingItem'
                  class='form-control custom-select'
                  formControlName='companyIdControl'
                  [ngClass]="displayFieldCss('companyIdControl')"
                  (change)='onCompanyChange($event.target.value)'>
            <option value='null' selected>-- Select Company IF applicable --</option>
            <option
              *ngFor='let item of companies'
              [value]='item.id'>{{item.name}}
            </option>
          </select>
          <input *ngIf='isEditingItem'
                 type='text' readonly
                 formControlName='companyIdControl'
                 class='form-control' />
          <span class='invalid-feedback'>Company is required</span>
        </div>
      </div>
      <div class='col-12'>
        <!-- Title -->
        <div class='form-group'>
          <label class='control-label required'>Title</label>
          <input type='text' class='form-control' [ngClass]="displayFieldCss('title')" formControlName='title' />
          <span class='invalid-feedback'>Title is required.</span>
        </div>

        <!-- Description -->
        <div class='form-group'>
          <label class='control-label required'>Description</label>
          <textarea class='form-control' [ngClass]="displayFieldCss('description')" formControlName='description'>
          </textarea>
          <span class='invalid-feedback'>Description is required</span>
        </div>

        <!-- Image -->
        <div class='form-group'>
          <label class='control-label required'>Image</label>
          <div class='input-group'>
            <input type='text' class='form-control' [ngClass]="displayFieldCss('image')"
                   placeholder='Choose the image' formControlName='image' readonly />
            <div class='input-group-prepend'>
              <button type='button' class='input-group-text btn btn-primary' (click)='openImageUpload()'>
                Browse
              </button>
            </div>
            <span class='invalid-feedback'>Image is required</span>
          </div>
        </div>

        <!-- File -->
        <div class='form-group'>
          <label class='control-label required'>File</label>
          <div class='input-group'>
            <input type='text' name='file' id='file' class='form-control' [ngClass]="displayFieldCss('file')"
                   placeholder='Choose file to upload' formControlName='file' readonly />
            <div class='input-group-prepend'>
              <button type='button' class='input-group-text btn btn-primary' (click)='openFileUpload()'>
                Browse
              </button>
            </div>
            <span class='invalid-feedback'>File is required</span>
          </div>
        </div>

        <br />

        <!-- Buttons -->
        <div class='row'>
          <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
            <button type='button' class='btn btn-outline-primary w-100' (click)='onBackClick()'>
              <i class='fa fa-undo mr-2' aria-hidden='true'></i> BACK
            </button>
          </div>

          <div *ngIf='isEditingItem' class='col-4'>
            <button type='button' class='btn btn-outline-primary w-100' [disabled]='!canRespondToButtons' (click)='onDeleteClick()'>
              <i class='fa fa-trash mr-2' aria-hidden='true'></i> DELETE
            </button>
          </div>

          <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
            <button type='submit' class='btn btn-primary float-right w-100' [disabled]='!canRespondToButtons'>
              <i class='fa fa-floppy-o mr-2' aria-hidden='true'></i> SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>



