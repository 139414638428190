<app-cms-list-view *ngIf="isDisplayingList && isInitialised" [dataList]="items" [columnList]="columns" [isPreventListDelete]="isPreventListDelete">
</app-cms-list-view>

<div *ngIf="(isAddingItem || isEditingItem) && isInitialised" class="padding jumbotron">
  <form [formGroup]="itemForm" (ngSubmit)="onSaveClick()">

    <div class="row m-0">
      <div class="col-12">
        <!-- Name -->
        <div class="form-group">
          <label for="name" class="control-label required">
            Name
          </label>
          <input id="name" type="text" class="form-control" [ngClass]="displayFieldCss('nameControl')" formControlName="nameControl">
          <span class="invalid-feedback">Name is required</span>
        </div>
      </div>

      <!-- Description -->
      <div class="col-12">
        <div class="form-group">
          <label for="description" class="control-label required">Description</label>
          <textarea id="description" class="form-control" [ngClass]="displayFieldCss('descriptionControl')" formControlName="descriptionControl">
          </textarea>
          <span class="invalid-feedback">Description is required</span>
        </div>
      </div>

      <!-- Domain -->
      <div class="col-6">
        <div class="form-group">
          <label for="domain" class="control-label required">Domain</label>
          <select id="domain" class="form-control custom-select" [ngClass]="displayFieldCss('domainIdControl')" formControlName="domainIdControl">
            <option value="null" selected>Select a Domain</option>
            <option *ngFor="let item of domains" [value]="item.id">{{item.name}} -{{item.driver}}
            </option>
          </select>
          <span class="invalid-feedback">Domain is required</span>
        </div>
      </div>

      <!-- Recurrence -->
      <div class="col-6">
        <div class="form-group">
          <label for="recurrence" class="control-label required">Recurrence</label>
          <select id="recurrence" class="form-control custom-select" [ngClass]="displayFieldCss('recurrenceControl')" formControlName="recurrenceControl">
            <option *ngFor="let item of recurrences" [value]="item.value">{{item.name}}
            </option>
          </select>
          <span class="invalid-feedback">Recurrence is required</span>
        </div>
      </div>

      <!-- New User Default -->
      <div class="col-6">
        <div class="form-group">
          <label for="" class="control-label required">New User Default</label>
          <select class="form-control custom-select" [ngClass]="displayFieldCss('newUserDefaultControl')" formControlName="newUserDefaultControl">
            <option *ngFor="let item of yesNoItems" [value]="item.id">{{item.name}}</option>
          </select>
          <span class="invalid-feedback">New User Default is required</span>
        </div>
      </div>

      <!-- Life Action Complexity -->
      <div class="col-6">
        <div class="form-group">
          <label for="" class="control-label required">Complexity</label>
          <select id="complexity" class="form-control custom-select" [ngClass]="displayFieldCss('complexityControl')" formControlName="complexityControl">
            <option *ngFor="let item of complexities" [value]="item">{{item}}</option>
          </select>
          <span class="invalid-feedback">Complexity level is required</span>
        </div>
      </div>

      <!-- Exclusive Company -->
      <div class="col-6" *ngIf="canSelectCompany">
        <app-select-company
          [allCompanies]="allCompanies"
          [selectedCompanyId]="itemForm.get('companyId').value"
          [label]="'Company'"
          [placeholder]="'All (default)'"
          (companyIdSelected)="onCompanySelected($event)"
        ></app-select-company>
      </div>

      <!-- Excluded Companies -->
      <div *ngIf="isAllCompanies()"
        class="col-6">
        <app-select-companies
          [allCompanies]="allCompanies"
          [selectedCompanyIds]="excludedCompanyIds"
          [label]="'Excluded Companies'"
          [placeholder]="'Select Company to exclude'"
          (companyIdsSelected)="excludedCompanyIdsSelected($event)"
        ></app-select-companies>
      </div>
    </div>

    <!-- Buttons -->
    <br />
    <div class="row">
      <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
        <button type="button" class="btn btn-outline-primary w-100" (click)="onBackClick()">
          <i class="fa fa-undo mr-2" aria-hidden="true"></i> BACK
        </button>
      </div>

      <div *ngIf="isEditingItem" class="col-4">
        <button type="button" class="btn btn-outline-primary w-100" [disabled]="!canRespondToButtons" (click)="onDeleteClick()">
          <i class="fa fa-trash mr-2" aria-hidden="true"></i> DELETE
        </button>
      </div>

      <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
        <button type="submit" class="btn btn-primary float-right w-100" [disabled]="!canRespondToButtons">
          <i class="fa fa-floppy-o mr-2" aria-hidden="true"></i> SAVE
        </button>
      </div>
    </div>
  </form>
</div>
