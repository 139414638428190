<div class="widget-card">
  <div class="widget-card-header backPrimary">
    Leaderboard
  </div>
  <div class="w-100 text-center p-0">
    <div class="row m-0">
      <div class="offset-7 col-5 p-1">
        <div class="row m-0">
          <div class="col-4 p-0">
            <img [src]="goldBadgeSrc" alt="gold badge" class="w-100" />
          </div>
          <div class="col-4 p-0">
            <img [src]="silverBadgeSrc" alt="silver badge" class="w-100" />
          </div>
          <div class="col-4 p-0">
            <img [src]="bronzeBadgeSrc" alt="bronze badge" class="w-100" />
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let item of leaderboardItems; let i = index" class="fs-80-rem" [ngClass]="{ 'primaryColor font-weight-bold': item.isMe }">
      <div class="row mt-4 mx-0">
        <div class="col-7 p-1">
          <div class="row m-0">
            <div class="col-3 p-0">
              {{ item.position | ordinal }}
            </div>
            <div class="col-3 p-0 text-center">
              <img class="avatarL" [src]="item.photoSrc" alt="avatar" onError="this.src='assets/img/profile.png'" />
              <img *ngIf="item.levelSrc" class="userPlaque" [src]="item.levelSrc">
            </div>
            <div class="col-6 pr-0 pl-2" placement="top" [ngbTooltip]="item.username" id="names">
              {{ item.username | truncate: 10 }}
            </div>
          </div>
        </div>
        <div class="col-5 p-1">
          <div class="row m-0 text-center">
            <div class="col-4 p-0">
              {{ item.gold }}
            </div>
            <div class="col-4 p-0">
              {{ item.silver }}
            </div>
            <div class="col-4 p-0">
              {{ item.bronze }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <a class="btn btn-link w-100 widget-card-line fs-80-rem" [routerLink]="['/share/leaderboard']">
      <i class="fa fa-plus-circle" aria-hidden="true"></i>
      Add
    </a>
  </div>
</div>
