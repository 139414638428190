import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'featureName'
})
export class FeatureNamePipe implements PipeTransform {

    transform(name: String) {
      return name.replace(/[^\w\s-]/gi, '');
    }
}
