import { Component, OnInit } from '@angular/core';
import { UnSubscribeComponent } from '../../_shared/un-subscribe.component';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../services/user-service';
import { InsightsService } from '../../../services/insights-service';

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss'],
})
export class SsoLoginComponent extends UnSubscribeComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private insightsService: InsightsService,
  ) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['token']) {
        this.userService.token = params['token'];
      }
      if (params['refresh_token']) {
        this.userService.refreshToken = params['refresh_token'];
      }
      if (params['redirect_url']) {
        window.location.href = params['redirect_url'];
        return;
      }
      this.insightsService.getCrossDomainUrl().subscribe(redirectUrl => {
        window.location.href = redirectUrl;
      });
    });
  }
}
