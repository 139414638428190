import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramService } from '../../../services/program-service';
import { UserService } from '../../../services/user-service';
import { UtilService } from '../../../services/util-service';
import { LifeActionService } from '../../../services/life-action-service';
import { FormControl } from '@angular/forms';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupService } from '../../../services/group-service';
import { IUserProgram } from '../../_models/user-program';
import { IUserProgramDetail } from '../../_models/user-program-detail';
import { DateService } from '../../../services/date.service';
import { BadgeService } from '../../../services/badge-service';

@Component({
  selector: 'app-program-detail',
  templateUrl: './program-detail.component.html',
  styleUrls: ['./program-detail.component.scss'],
})
export class ProgramDetailComponent implements OnInit {
  program: IUserProgramDetail;
  groups;
  content;
  totalContent;
  lifeActions = [];
  isShowProgram = false;
  groupsWithCount;
  notificationsApp = new FormControl();
  notificationsWeb = new FormControl();
  notificationsEmail = new FormControl();

  completionDate;
  isLoggedIn = true;
  isProgram = true;

  constructor(
    public activatedRoute: ActivatedRoute,
    public programService: ProgramService,
    public userService: UserService,
    public modalService: NgbModal,
    public router: Router,
    public utilService: UtilService,
    public lifeActionService: LifeActionService,
    public groupService: GroupService,
    public dateService: DateService,
    public badgeService: BadgeService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((data) => {
      const id = this.utilService.getIdFromHyphenatedUrl(data.titleAndId);
      this.getProgram(id);
    });
  }

  getProgram(id) {
    this.programService.getProgramById(id).subscribe((data) => {
      this.populatePropertiesWithResponseData(data);
      this.checkIfCanBeCompleted();
      this.markProgramWithDisplayProperties();
      this.setNotificationsControlsValues(this.program);
      this.isShowProgram = true;
    });
  }

  private populatePropertiesWithResponseData(data) {
    this.program = data.program;
    this.groups = data.groups;
    if (data.lifeActions.length > 2) {
      this.lifeActions = data.lifeActions.slice(-2);
    } else {
      this.lifeActions = data.lifeActions;
    }
    this.totalContent = this.markContentWithDisplayProperties(data.content);
    this.content = this.totalContent.slice(0, 6);
    this.groupsWithCount = data.groupsCount;
  }

  private checkIfCanBeCompleted() {
    if (this.program.contentIdsUnsent && !this.program.contentIdsUnsent.length && !this.program.completed) {
      this.markProgramCompleted();
    }
  }

  viewMoreContent() {
    const newContent = this.totalContent.slice(6);
    newContent.map((content) => {
      this.content.push(content);
    });
  }

  showViewMoreButton() {
    return this.content.length === 6 && this.totalContent.length > 6;
  }

  private markProgramWithDisplayProperties() {
    this.program.isContent = this.programService.isProgramContent(this.program);
    this.program.isLifeActionsSent = this.programService.isProgramLifeActionsSent(this.program);
    this.program.isDisplayLifeActions = this.getIsDisplayLifeActions(this.program);
    this.program.isDisplayGroups = !!this.program.groupIds.length;
    this.program.displayContentFrequency = this.getContentFrequencyMessage(this.program);
    this.program.displayLifeActionsFrequency = this.getLifeActionFrequencyMessage(this.program);
  }

  private getContentFrequencyMessage(program: IUserProgram): string {
    const frequency = this.programService.getFrequencyText(program.contentNotPeriod);
    return `You will receive new content ${frequency}.`;
  }

  private getLifeActionFrequencyMessage(program: IUserProgram): string {
    this.programService.getFrequencyText(program.indicatorNotPeriod);
    return `Unlock new LifeActions as you progress through the program`;
  }

  private getIsDisplayLifeActions(program: IUserProgram): boolean {
    return (!this.programService.isUserInProgram(program) &&
        this.programService.isProgramLifeActions(program)) ||
      ((this.programService.isUserInProgram(program) &&
          this.programService.isProgramLifeActionsSent(program)) ||
        this.programService.isProgramLifeActionsUnsent(program));
  }

  private markContentWithDisplayProperties(content) {
    content.forEach((c) => {
      if (c.contentType === 1) {
        c.contentTypeName = 'article';
        c.displaySource = this.userService.getArticleImage(c.image);
        c.displayText = c.shortText;
      }

      if (c.contentType === 2) {
        c.contentTypeName = 'recipe';
        c.displaySource = this.userService.getRecipeImage(c.image);
        c.displayText = c.shortDescription;
      }

      if (c.contentType === 3) {
        c.contentTypeName = 'video';
        c.displaySource = c.image;
        c.displayText = c.shortText;
      }
    });
    return content;
  }

  canPauseProgram(): boolean {
    return this.programService.canUserPauseProgram(this.program);
  }

  canResumeProgram(): boolean {
    return this.programService.canUserResumeProgram(this.program);
  }

  canToggleNotifications(): boolean {
    return this.programService.isUserProgramInProgress(this.program);
  }

  setNotificationsControlsValues(program: IUserProgram) {
    this.notificationsApp.setValue(program.notificationsApp);
    this.notificationsWeb.setValue(program.notificationsWeb);
    this.notificationsEmail.setValue(program.notificationsEmail);
  }

  pauseProgram() {
    if (!this.programService.canUserPauseProgram(this.program)) {
      return;
    }

    this.programService.pauseUserProgram(this.program).subscribe((pausedProgram) => {
      this.updateProgramProperties(pausedProgram);
      this.markProgramWithDisplayProperties();
    });
  }

  resumeProgram() {
    if (!this.programService.canUserResumeProgram(this.program)) {
      return;
    }

    this.programService.resumeUserProgram(this.program).subscribe((resumedProgram) => {
      this.updateProgramProperties(resumedProgram);
      this.markProgramWithDisplayProperties();
    });
  }

  private updateProgramProperties(updatedProgram: IUserProgram) {
    this.program.activeUserProgram = updatedProgram.activeUserProgram;
    this.program.lastPausedDate = updatedProgram.lastPausedDate;
    this.program.lastResumedDate = updatedProgram.lastResumedDate;
    this.program.cumulativePrePausedDays = updatedProgram.cumulativePrePausedDays;
  }

  goToGroup(groupId) {
    this.router.navigate([`/share/groups`, groupId]);
  }

  goToGroupsPage() {
    this.router.navigateByUrl('/share/groups?other=true');
  }

  joinGroup(group) {
    this.groupService.joinGroup(group.id).subscribe(() => {
      group.groupMember = true;
    });
  }

  generateGoToDetailLink(item) {

    const url = `${this.router.url}/${item.contentTypeName}`;
    return [url, item.id];

  }

  markProgramCompleted() {
    const body = {
      completed: true,
      programId: this.program.id,
      nextProgramId: this.program.nextProgramId,
      completionDate: new Date(),
    };
    this.programService
      .completeUserProgram(this.program.userProgramId, body)
      .subscribe((result) => {
        let message;
        let preNextProgramText;
        let nextProgram;
        let badge;
        const completionModal = this.modalService.open(BootstrapModalComponent);
        completionModal.componentInstance.title = 'Congratulations!';
        if (result.achievedBadge) {
          badge = result.achievedBadge;
          completionModal.componentInstance.badgeName = badge.name;
          completionModal.componentInstance.badgeImage = 'assets/img/badges/'.concat(badge.image);
          completionModal.componentInstance.badgeDescription = badge.description;
          if (this.program.completionTextBadge) {
            message = this.program.completionTextBadge;
          } else {
            message = `You have successfully completed the ${
              this.program.title
            } program and achieved a badge`;
          }
        } else {
          message = `You have successfully completed the ${this.program.title} program`;
        }

        if (result.nextProgram) {
          nextProgram = result.nextProgram.program;
          completionModal.componentInstance.nextProgram = nextProgram;
          if (nextProgram.userProgramId) {
            preNextProgramText = `Looks like you've already got started with the next program, click through to see your progress`;
          } else if (this.program.completionTextNextProgram) {
            preNextProgramText = this.program.completionTextNextProgram;
          } else {
            preNextProgramText = `Here's another program we think you might want to join to continue your progress:`;
          }
        }

        completionModal.componentInstance.closeButtonLabel = 'Close';
        completionModal.componentInstance.singleButton = true;
        completionModal.componentInstance.message = message;
        completionModal.componentInstance.preNextProgramText = preNextProgramText;

        completionModal.result.then((data) => {
          if (data && data.action && data.action === 'open') {
            this.openProgram(data.program);
          } else if (data && data.action && data.action === 'join') {
            this.joinProgram(data.program);
          }
        });
      });
  }

  deleteLifeAction(lifeAction: any): void {
    const currentLASent = this.program.indicatorIdsSent;
    const newLASent = currentLASent.filter((id) => {
      return id !== lifeAction.id;
    });
    this.program.indicatorIdsSent = newLASent;
    this.programService
      .updateUserProgram(this.program.userProgramId, {
        indicatorIdsSent: newLASent,
      })
      .subscribe(() => {
        this.lifeActions.map((action, index) => {
          if (action.id === lifeAction.id) {
            this.lifeActions.splice(index, 1);
          }
        });
        this.utilService.showToastSuccess(`LifeAction deleted!`);
      });

    this.lifeActionService.deleteLifeAction(lifeAction.id).subscribe(() => {
    });
  }

  actionDue(action) {
    if (this.lifeActionService.getLifeActionsToday(action)) {
      return this.lifeActionService.checkDueAction(action);
    }
  }

  updateTracker(obj) {
    const matchingTracker = this.lifeActionService.findMatchingTracker(obj.action);
    const isAchieved = this.lifeActionService.isAchieved(obj.action, obj.count);
    if (matchingTracker) {
      this.lifeActionService
        .updateTrackLifeAction(matchingTracker['id'], obj.count, isAchieved, obj.action.category)
        .subscribe((tracker) => {
          this.lifeActionService.handleUpdateRes(tracker, isAchieved);
          this.getProgram(this.program.id);
        });
    } else {
      this.lifeActionService
        .addTrackLifeAction(obj.action, obj.count, isAchieved)
        .subscribe((tracker) => {
          this.lifeActionService.handleUpdateRes(tracker, isAchieved);
          this.getProgram(this.program.id);
        });
    }
  }

  notificationsAppToggle() {
    this.notificationsApp.setValue(!this.notificationsApp.value);
    this.programService
      .updateUserProgram(this.program.userProgramId, {
        notificationsApp: this.notificationsApp.value,
      })
      .subscribe(() => {
      });
  }

  notificationsWebToggle() {
    this.notificationsWeb.setValue(!this.notificationsWeb.value);
    this.programService
      .updateUserProgram(this.program.userProgramId, {
        notificationsWeb: this.notificationsWeb.value,
      })
      .subscribe(() => {
      });
  }

  notificationsEmailToggle() {
    this.notificationsEmail.setValue(!this.notificationsEmail.value);
    this.programService
      .updateUserProgram(this.program.userProgramId, {
        notificationsEmail: this.notificationsEmail.value,
      })
      .subscribe(() => {
      });
  }

  joinProgram(program) {
    if (!this.programService.canUserJoinProgram(program)) {
      return;
    }
    this.displayPersonalisationQuestionnaire(program);
  }

  createDateObj(program) {
    let dateLabel;
    let startDate;
    let endDate;
    let showDatepicker;
    if (!program.personalisationQuestionnaire) {
      dateLabel = null;
      startDate = true;
      endDate = false;
      showDatepicker = false;
    } else if (program.personalisationEndDate && !program.personalisationStartDate) {
      dateLabel = program.personalisationEndDateText;
      startDate = false;
      endDate = true;
      showDatepicker = true;
    } else if (program.personalisationStartDate && !program.personalisationEndDate) {
      dateLabel = program.personalisationStartDateText;
      startDate = true;
      endDate = false;
      showDatepicker = true;
    }
    const ymd = this.dateService.getYearMonthDayObject();
    return {
      showDatepicker: showDatepicker,
      label: dateLabel,
      type: 'date',
      value: ymd,
      startDate: startDate,
      endDate: endDate,
      fixedStartDayOfWeek: program.fixedStartDayOfWeek,
      startDayOfWeek: program.startDayOfWeek,
      todayIsMax: false,
    };
  }

  createProgramDataObj(program) {
    let programDayOrWeekNo;
    let programUnit;
    if (program.duration >= 7) {
      programDayOrWeekNo = Math.floor(program.duration / 7);
      programUnit = 'week';
    } else {
      programDayOrWeekNo = program.duration;
      programUnit = program.duration > 1 ? 'days' : 'day';
    }
    return {
      programUnit,
      programDayOrWeekNo,
      programDuration: program.duration,
    };
  }

  displayPersonalisationQuestionnaire(program) {
    const notificationSubtitle =
      'Please select which notifications you would like to receive for this program. You can change these settings at any time.';
    const date = this.createDateObj(program);
    const toggles = ['App', 'Web', 'Email'];

    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.confirmButtonLabel = date.showDatepicker
      ? 'Set preferences'
      : 'Join program';
    modal.componentInstance.title = program.title;
    if (program.personalisationQuestionnaire) {
      modal.componentInstance.date = date;
      modal.componentInstance.programData = this.createProgramDataObj(program);
    }

    if (this.userService.profile.secondaryEmail) {
      modal.componentInstance.existingSecondaryEmail = this.userService.profile.secondaryEmail;
    } else {
      modal.componentInstance.existingSecondaryEmail = null;
    }
    modal.componentInstance.isProgram = true;
    modal.componentInstance.closeButtonLabel = 'Cancel';
    modal.componentInstance.toggles = toggles;
    modal.componentInstance.toggleMessage = notificationSubtitle;

    modal.result
      .then((result) => {
        const toggleResult = {};
        Object.assign({}, result.toggleForm, {
          togglesOn: result.toggleForm.controls.togglesOn.value.map((selected, i) => {
            const toggleVar = toggles[i];
            toggleResult[toggleVar] = selected;
          }),
        });
        let useSecondaryEmail = false;
        if (result.emailSelection.value === 'secondary') {
          useSecondaryEmail = true;
        }
        let dateValue;
        if (result.dateResult) {
          // if the program lets the user set a date
          dateValue = this.dateService.convertNgbDateStruct(result.dateResult.date);
        } else {
          // otherwise the (start)date is today
          dateValue = this.dateService.getLocalDateTimeTzOffset();
        }
        const dateResult = {
          startDate: date.startDate,
          endDate: date.endDate,
          date: dateValue,
          dateNow: this.dateService.getLocalDateTimeTzOffset(),
        };
        const body = {
          toggleResult,
          dateResult,
          program,
          useSecondaryEmail,
        };
        this.programService.createUserProgram(body).subscribe((res) => {
          this.displayModalWelcome(res.program);
        });
      })
      .catch((error) => {
        // quit, so don't join program;
        console.log(error);
      });
  }

  private displayModalWelcome(program) {
    const welcomeMsg = this.programService.getProgramWelcomeMessage(program);

    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Congratulations!';
    modal.componentInstance.singleButton = true;
    modal.componentInstance.message = welcomeMsg;
    modal.componentInstance.closeButtonLabel = 'Close';

    this.openProgram(program);
  }

  openProgram(program) {
    const titleAndIdHyphenated = this.utilService.hyphenateUrl(program.title, program.id);
    this.router.navigate(['toolkit/programs', titleAndIdHyphenated]);
  }

  canJoinProgram(program: IUserProgram) {
    return this.programService.canUserJoinProgram(program);
  }

  onDoLifeAction(indicatorBody) {
    this.lifeActions[indicatorBody.index] = indicatorBody.indicator;
  }

  onChangeActivationStatus(item) {
    const lifeAction = this.lifeActions.find(i => i.id === +item.indicatorId);

    if (item.active) {
      this.lifeActionService.activateLifeAction(item)
        .subscribe(data => {
          this.lifeActions[item.index] = data;
        });
    } else {
      this.lifeActionService.deactivateLifeAction(item.userLifeActionsActivationId)
        .subscribe(() => {
          lifeAction.active = false;
          lifeAction.userLifeActionsActivationId = null;
        });
    }
  }

}
