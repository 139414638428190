import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home-preview',
  templateUrl: './home-preview.component.html',
  styleUrls: ['./home-preview.component.scss'],
})
export class HomePreviewComponent implements OnInit {
  logoSrc: string;
  primaryColor: string;
  topTextBarColor: string;
  bodyTextColor: string;

  appleImageSrc = 'assets/img/applinks/appdownload.png';
  androidImageSrc = 'assets/img/applinks/googledownload.png';

  sampleArticles = [
    {
      id: 1,
      title: '8 reasons you\'re waking up during the night and how to fix it',
      excerpt:
        'Spending the dark hours of 3-4am staring at the ceiling is likely to leave you feeling tired and anxious the next day.',
      coverImage:
        'https://s3-ap-southeast-2.amazonaws.com/assets.altiuslife.com.au/articles_images/WLtCZdBNTOyA0infmerI_8%20Reasons%20night%20waking.jpg',
      numberOfLikes: 16,
    },
    {
      id: 2,
      title: ' Subscribe to Les Mills workouts - stay fit at home ',
      excerpt:
        'Les Mills is offering these free at home workouts to keep you active during the COVID-19',
      coverImage:
        'https://s3-ap-southeast-2.amazonaws.com/assets.altiuslife.com.au/articles_images/cWYkeWSRlSMRIdzX6TDO_Normal%20Les%20MIlls.jpg',
      numberOfLikes: 21,
    }
  ];

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {}

  onClosePreview() {
    this.activeModal.close();
  }
}
