import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserService } from '../../../services/user-service';
import { IBadge } from '../../_models/badge';
import { Router } from '@angular/router';

@Component({
  selector: 'app-last-badge-earned',
  templateUrl: './last-badge-earned.component.html',
  styleUrls: ['./last-badge-earned.component.scss']
})
export class LastBadgeEarnedComponent implements OnChanges {
  @Input() lastBadgeEarned: IBadge;
  isCompanyUsingUserLevels = false;
  userLevelSrc: string;
  badgImageSrc: string;

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.isCompanyUsingUserLevels = this.userService.isCompanyUsingUserlevels();
    this.userLevelSrc = this.getUserLevelSrc();
    this.badgImageSrc = this.getBadgeImageSrc();
  }

  private getUserLevelSrc(): string {
    if (!this.lastBadgeEarned) { return ''; }

    const userLevel = this.userService.profile.level.toLowerCase();
    return `assets/img/badges/${userLevel}-plaque.png`;
  }

  private getBadgeImageSrc(): string {
    if (!this.lastBadgeEarned) { return ''; }

    const badgeImg = this.lastBadgeEarned.image;
    return `assets/img/badges/${badgeImg}`;
  }

  onUserLevelClick() {
    this.router.navigate(['/reward/badges']);
  }
}
