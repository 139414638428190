import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user-service';

@Component({
  selector: 'app-widget-preview',
  templateUrl: './widget-preview.component.html',
  styleUrls: ['./widget-preview.component.scss'],
})
export class WidgetPreviewComponent implements OnInit {
  @Input() primaryColor: string;
  appleImageSrc;
  androidImageSrc;
  appleHref;
  androidHref;

  constructor(private userService: UserService) {
    this.appleImageSrc = 'assets/img/applinks/appdownload.png';
    this.androidImageSrc = 'assets/img/applinks/googledownload.png';

    this.appleHref = this.userService.appDownloadLinks.apple;
    this.androidHref = this.userService.appDownloadLinks.google;
  }

  ngOnInit() {
  }
}
