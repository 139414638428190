import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICompany } from '../app/_models/company';

@Injectable({
  providedIn: 'root',
})
export class MarketingBrochureService {
  constructor(private http: HttpClient) {
  }

  getData(companyId: number) {
    const path = `/cms/marketing-brochure/company?id=${companyId}`;
    return this.http.get<ICompany>(path);
  }
}
