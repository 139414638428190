import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { IFriend } from '../../../_models/friend';
import { IFriendActions } from '../../../_models/friend-actions';

@Component({
  selector: 'app-friends-list',
  templateUrl: './friends-list.component.html',
  styleUrls: ['./friends-list.component.scss']
})
export class FriendsListComponent implements OnChanges {
  @Input() friends: IFriend[] = [];
  @Input() noFriendsText: string;
  @Output() viewFriend = new EventEmitter<IFriend>();
  @Output() selectAction = new EventEmitter<IFriendActions>();
  selectedAction: IFriendActions;

  canShowNoFriendsText = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedAction = {};
    this.friends = changes.friends.currentValue;
    this.canShowNoFriendsText = this.friends && !this.friends.length;
  }

  onViewFriend(friend: IFriend) {
    this.viewFriend.emit(friend);
  }

  onSendFriendRequest(friend: IFriend) {
    this.selectedAction.sendFriendRequest = true;
    this.emitSelectedAction(friend);
  }

  onCancelSentFriendRequest(friend: IFriend) {
    this.selectedAction.cancelSentFriendRequest = true;
    this.emitSelectedAction(friend);
  }

  onConfirmReceivedFriendRequest(friend: IFriend) {
    this.selectedAction.confirmReceivedFriendRequest = true;
    this.emitSelectedAction(friend);
  }

  onDeclineReceivedFriendRequest(friend: IFriend) {
    this.selectedAction.declineReceivedFriendRequest = true;
    this.emitSelectedAction(friend);
  }

  onRemoveConfirmedFriend(friend: IFriend) {
    this.selectedAction.removeConfirmedFriend = true;
    this.emitSelectedAction(friend);
  }

  emitSelectedAction(actionedFriend: IFriend) {
    this.selectedAction.actionedFriend = actionedFriend;
    this.selectAction.emit(this.selectedAction);
  }
}
