import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';

import * as _ from 'lodash';

import { GroupService } from '../../services/group-service';
import { BadgeService } from '../../services/badge-service';
import { QuestionnaireService } from '../../services/questionnaire-service';
import { FeatureService } from '../../services/feature-service';
import { UserService } from '../../services/user-service';
import { LifeActionService } from '../../services/life-action-service';
import { TMHService } from '../../services/track-my-health-service';
import { UtilService } from '../../services/util-service';
import { IQuestionnaire } from '../_models/questionnaire';
import { IBadge } from '../_models/badge';
import { ILatestGroupsActivity } from '../_models/latest-groups-activity';
import { ILifeAction } from '../_models/life-action';
import { ILeaderboardItem } from '../_models/leaderboard-item';
import { CompanyService } from '../../services/company-service';
import { ICompany } from '../_models/company';

@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.scss'],
})
export class WidgetsComponent implements OnInit, OnDestroy {
  canShowLogWidget = false;
  canShowHCFwidget = false;
  canShowDownloadTheApp = false;
  canShowMyRewards = false;
  canShowCompetition = false;
  canShowLastBadgeEarned = false;
  canShowGroups = false;
  canShowSuggestedLifeAction = false;
  canShowLeaderboard = false;
  canShowMyHealth = false;
  canshowEapBooking = false;
  canshowNasdaqwidget = false;

  currentCompetition: IQuestionnaire = null;
  suggestedLifeAction: ILifeAction = null;
  lastBadgeEarned$: Observable<IBadge>;
  latestGroupActivity$: Observable<ILatestGroupsActivity>;
  shortLeaderboard$: Observable<ILeaderboardItem[]>;
  activeIndicators$: Observable<any[]>;

  lastBadgeEarnedSubscription: Subscription;
  latestGroupActivitySubscription: Subscription;
  shortLeaderboardSubscription: Subscription;

  private companyFeatures: number[] = [];

  badgeSubscription: Subscription;

  constructor(
    private userService: UserService,
    private questionnaireService: QuestionnaireService,
    private badgeService: BadgeService,
    private groupService: GroupService,
    private lifeActionService: LifeActionService,
    private tmhService: TMHService,
    private utilService: UtilService,
    private featureService: FeatureService,
    private router: Router,
    private companyService: CompanyService,
  ) {
    this.badgeSubscription = this.utilService.getBadge().subscribe(badge => {
      // console.log('>>>>>?<<<', badge);
      this.getLeaderboard(badge);
    });
  }

  ngOnInit() {
    this.companyFeatures = this.userService.getUserCompanyFeatures();

    this.getCurrentCompetition();
    this.getLastBadgeEarned();
    this.getLatestGroupActivity();
    this.getSuggestedLifeAction();
    this.getLeaderboard(null);
    this.getMyHealth();

    this.canShowDownloadTheApp = this.userService.isLoggedIn() && this.userService.hasApp;
    this.canShowMyRewards = this.featureService.isIncludeMyRewardsFeature(this.companyFeatures);
    this.canShowLogWidget = this.featureService.isIncludePeerSupportFeature(this.companyFeatures) && this.userService.isInpexCompanyUsers();
    this.canshowEapBooking = this.featureService.isIncludeEapBookingFeature(this.companyFeatures);

    this.getcanShowHCFwidget();
    this.getcanShowNasdaqwidget();
  }

  ngOnDestroy(): void {
    if (this.lastBadgeEarnedSubscription) {
      this.lastBadgeEarnedSubscription.unsubscribe();
    }

    if (this.latestGroupActivitySubscription) {
      this.latestGroupActivitySubscription.unsubscribe();
    }

    if (this.shortLeaderboardSubscription) {
      this.shortLeaderboardSubscription.unsubscribe();
    }

    if (this.badgeSubscription) {
      this.badgeSubscription.unsubscribe();
    }
  }

  private getcanShowHCFwidget() {

    let cId = this.userService.company.id;
    this.companyService.getCompanyByIdForWidget(cId)
      .subscribe((data: ICompany) => {
        this.canShowHCFwidget = data.isHcfWidget;
      });
  }

  private getcanShowNasdaqwidget() {
    // This method should only work for Nasdaq company in 1700 platform
    if (this.userService.envVariables.title == '1700') {
      let cId = this.userService.company.id;
      this.canshowNasdaqwidget = cId == 83;
      this.canShowMyRewards = (cId == 83) ? false : this.canShowMyRewards;
    }
  }

  private getCurrentCompetition() {
    this.canShowCompetition = false;
    if (!this.featureService.isIncludeChanceToWinFeature(this.companyFeatures)) {
      return;
    }

    this.questionnaireService.getAllcompetitions()
      .subscribe((results: IQuestionnaire[]) => {
        if (!results.length) {
          this.currentCompetition = null;
        } else {
          this.currentCompetition = results[0];
          this.canShowCompetition = true;
        }
      });
  }

  public getLastBadgeEarned() {
    this.canShowLastBadgeEarned = false;
    if (!this.featureService.isIncludeBadgesFeature(this.companyFeatures)) {
      return;
    }

    this.lastBadgeEarned$ = this.badgeService.getCurrentBadge();
    this.lastBadgeEarnedSubscription = this.lastBadgeEarned$
      .subscribe(currentBadge => {
        this.canShowLastBadgeEarned = !!currentBadge
          && !this.utilService.isEmptyObject(currentBadge);
      });
  }

  getLatestGroupActivity() {
    this.canShowGroups = false;
    if (!this.featureService.isIncludeGroupsFeature(this.companyFeatures)) {
      return;
    }

    this.latestGroupActivity$ = this.groupService.getCurrentGroupActivity();
    this.latestGroupActivitySubscription = this.latestGroupActivity$
      .subscribe(val => {
        this.canShowGroups = !!val;
      });
  }

  private getSuggestedLifeAction() {
    this.canShowSuggestedLifeAction = false;
    if (!this.featureService.isIncludeLifeActionsFeature(this.companyFeatures)) {
      return;
    }

    this.lifeActionService.getSuggestedLifeAction()
      .subscribe((data: ILifeAction) => {
        if (!data) {
          this.suggestedLifeAction = null;
        } else {
          this.suggestedLifeAction = data;
          this.canShowSuggestedLifeAction = true;
        }
      });
  }

  private getLeaderboard(badge) {
    this.canShowLeaderboard = false;
    if (!this.featureService.isIncludeLeaderboardFeature(this.companyFeatures)) {
      return;
    }

    this.shortLeaderboard$ = this.badgeService.getCurrentLeaderboard();
    // console.log(this.shortLeaderboard$);

    this.shortLeaderboardSubscription = this.shortLeaderboard$
      .subscribe((data: ILeaderboardItem[]) => {
        // console.log("suuuuuuub");

        // console.log(data);
        // console.log(badge);
        if (badge) {
          // console.log(badge.url);
          badge.badgesToAward.map(b => {
            let index = _.findIndex(data, { id: b.userProfileId });
            if (index != -1) {
              if (b.badgeLevel == 'bronze') {
                data[index].bronze = Number.parseInt(data[index].bronze.toString()) + Number.parseInt('1');
              } else if (b.badgeLevel == 'silver') {
                data[index].silver = Number.parseInt(data[index].silver.toString()) + Number.parseInt('1');
              } else {
                data[index].gold = Number.parseInt(data[index].gold.toString()) + Number.parseInt('1');
              }
              data[index].total = Number.parseInt(data[index].total.toString()) + Number.parseInt('1');
            }
          });
        }

        this.canShowLeaderboard = !!data && !!data.length;
        // console.log(this.canShowLeaderboard);
      });
  }

  private getMyHealth() {
    this.canShowMyHealth = false;
    if (!this.featureService.isIncludeTrackMyHealthFeature(this.companyFeatures)) {
      return;
    }

    this.activeIndicators$ = this.tmhService.getCurrentActiveIndicators();
    this.canShowMyHealth = true;
  }

  onAddLifeAction(lifeActionId: number) {
    this.lifeActionService.activateLifeActionForId(lifeActionId)
      .subscribe(() => {
        this.utilService.showToastSuccess('LifeAction added!');
        this.getSuggestedLifeAction();
        this.router.navigateByUrl('act/lifeactions');
      });
  }
}
