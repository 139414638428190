<form *ngIf="showRangeForm" [formGroup]="rangeForm" class="form-group">
  <p>Please provide the beginning and the end of the numerical range</p>
  <div style="display: flex;">
    <div class="col-xs-1">
      <label class="control-label">Start value</label>
      <input type="number" class="form-control" formControlName="start" />
    </div>
    <div class="col-xs-1">

      <label class="control-label">End value</label>
      <input type="number" class="form-control" formControlName="end" />
    </div>
  </div>
  <button type="button" class="btn btn-primary btn-sm mt-3" (click)="createAnswerRange()">
    <i class="fa fa-plus mr-2" aria-hidden="true"></i> Add range
  </button>
</form>
<p *ngIf="hasAnswers">The current range is {{rangeStart}} - {{rangeEnd}}</p>
<button *ngIf="!showRangeForm" type="button" class="btn btn-primary btn-sm" (click)="editRange()">
  <i class="fa fa-pencil mr-2" aria-hidden="true"></i> Edit range
</button>
<button *ngIf="!showRangeForm" type="button" class="ml-2 btn btn-primary btn-sm" (click)="deleteRange()">
  <i class="fa fa-pencil mr-2" aria-hidden="true"></i> Delete range
</button>
