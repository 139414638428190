import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EAPService {
  constructor(private http: HttpClient) {
  }

  deleteEAP(id) {
    return this.http.delete<any>(`/cms/eap/${id}`);
  }

  getAllData() {
    return this.http.get<any>('/cms/eap/company-data');
  }

  bulkUrlUpdate(body) {
    return this.http.post<any>('/cms/eap/bulk-url-update', body);
  }
}
