import { mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserService } from './user-service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  constructor(
    private userService: UserService,
    private http: HttpClient  ) { }

  isFitbitDevice(device) {
    return device === 'fitbit';
  }

  isGoogleFitDevce(device) {
    return device === 'googlefit';
  }

  getDeviceSummary(device) {
    if (this.isFitbitDevice(device)) {
      return {
        name: 'Fitbit',
        connection: 'fitbitconnection',
        existing: 'Fitbit'
      };
    }

    if (this.isGoogleFitDevce(device
    )) {
      return {
        name: 'Google Fit',
        connection: 'googlefitconnection',
        existing: 'Google Acount'
      };
    }
  }

  // There are three potential urls that are handled:
  // 1: the auth uri: this address determines whether the user has already authorised the app on their fitbit profile.
  // 2: If not already authorised, the user is redirected to www.fitbit.com/login. On successful login, the scope
  // authorisation screen is shown (i.e. approve lifeiq to collect steps, activity, etc).
  // 3: If already authorised, or after authorisation, the user is redirected to the callback url, which is appended
  // with an auth code. This is extracted from the url, and post to the lifeiq server, which then exchanges it for
  // access and refresh tokens server side.

  getFitbitAuthorization(page, indicatorId) {
    const clientId = this.userService.envVariables.devices.fitbit.clientId;
    const callback = this.userService.envVariables.devices.fitbit.callbackUrl;
    // sends user ID to fitbit in 'state' in order to get userProfile when it hits API in callback
    const userId = this.userService.userId;
    let stateString;
    // if connection is initiated from TMH, it sends the indicator ID to fitbit in 'state' so that the dialog alert can be opened to change
    // that indicator's datasource upon successful redirect
    if (indicatorId) {
      stateString = `${userId}+${page}+${indicatorId}`;
    } else {
      stateString = `${userId}+${page}`;
    }
    window.location.href = `https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${callback}&scope=activity%20heartrate%20location%20nutrition%20profile%20settings%20sleep%20social%20weight&expires_in=604800&state=${stateString}`;
  }

  fitbitDisconnect() {
    const url = `/fitbit/auth/disconnect`;
    return this.http
      .post<any>(url, {}).pipe(
      mergeMap(() => this.userService.getProfile()));
  }

  getData(indicator) {
    if (indicator === 'Water') {
      return this.getWater();
    } else if (indicator === 'Steps') {
      return this.getSteps();
    } else if (indicator === 'Weight') {
      return this.getWeight();
    } else if (indicator === 'Calories Burnt') {
      return this.getCalories();
    }
  }

  getSteps() {
    const url = '/fitbit/steps';
    return this.http.get<any>(url).pipe(tap(() => { }));
  }

  getSleep() {
    const url = '/fitbit/sleep';
    return this.http.get<any>(url).pipe(tap(() => { }));
  }

  getCalories() {
    const url = '/fitbit/calories';
    return this.http.get<any>(url).pipe(tap(() => { }));
  }

  getWater() {
    const url = '/fitbit/water';
    return this.http.get<any>(url).pipe(tap(() => { }));
  }

  getWeight() {
    const url = '/fitbit/weight';
    return this.http.get<any>(url).pipe(tap(() => { }));
  }

  getGoogleFitAuthorization(page, indicatorId) {
    const authUri = this.userService.envVariables.devices.googleFit.authUri;
    const clientId = this.userService.envVariables.devices.googleFit.clientId;
    const callback = this.userService.envVariables.devices.googleFit.redirectUrl;
    const scopes =
      'https://www.googleapis.com/auth/fitness.activity.read+https://www.googleapis.com/auth/fitness.activity.write+https://www.googleapis.com/auth/fitness.body.read+https://www.googleapis.com/auth/fitness.body.write+https://www.googleapis.com/auth/fitness.nutrition.read+https://www.googleapis.com/auth/fitness.nutrition.write';
    // sends user ID to fitbit in 'state' in order to get userProfile when it hits API in callback

    const userId = this.userService.userId;
    let stateString;
    // if connection is initiated from TMH, it sends the indicator ID to fitbit in 'state' so that the dialog alert can be opened to change
    // that indicator's datasource upon successful redirect
    if (indicatorId) {
      stateString = `${userId}+${page}+${indicatorId}`;
    } else {
      stateString = `${userId}+${page}`;
    }
    // if connection is initiated from TMH, it sends the indicator ID to fitbit in 'state' so that the dialog alert can be opened to change
    // that indicator's datasource upon successful redirect

    window.location.href = `${authUri}?redirect_uri=${callback}&prompt=consent&response_type=code&access_type=offline&client_id=${clientId}&scope=${scopes}&state=${stateString}`;
  }

  revokeGoogleFitAuthorization() {
    const url = `/google/auth/revoke-access`;
    return this.http
      .post<any>(url, {}).pipe(
      mergeMap(() => this.userService.getProfile()));
  }
}
