import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UnSubscribeComponent } from '../../_shared/un-subscribe.component';
import { UserService } from '../../../services/user-service';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { MarketingPosterService } from '../../../services/marketing-poster.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-marketing-poster',
  templateUrl: './marketing-poster.component.html',
  styleUrls: ['./marketing-poster.component.scss'],
})
export class MarketingPosterComponent extends UnSubscribeComponent implements OnInit {
  @ViewChild('marketingPoster') marketingPoster: ElementRef;
  companyId: number;
  domain: string;
  registrationCode: string;
  appleHref: string;
  androidHref: string;
  isProcessing: boolean;
  previewMode = false;
  isLoading: boolean;
  private jsPDF: jsPDF;

  constructor(
    private marketingPosterService: MarketingPosterService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.appleHref = this.userService.appDownloadLinks.apple;
    this.androidHref = this.userService.appDownloadLinks.google;
    this.activatedRoute.params.subscribe((route) => {
      this.companyId = route.id ? +route.id : this.userService.company.id;
      this.getData();
    });
  }

  async onGeneratePDF() {
    this.isProcessing = true;
    const currentDate = new Date();
    const fileName = 'marketing_poster' + `_${+currentDate}`;
    if (!this.jsPDF) {
      await this.createPDFDoc();
    }
    this.jsPDF.save(fileName);
    this.isProcessing = false;
  }

  // async onPreviewPDF() {
  //   const previewModal = this.modalService.open(MarketingPosterComponent, {
  //     size: 'lg',
  //     centered: true,
  //     windowClass: 'marketing-poster-preview',
  //   });
  //   previewModal.componentInstance.previewMode = true;
  // }

  private async createPDFDoc() {
    if (this.domain && this.registrationCode) {
      const canvas = await html2canvas(this.marketingPoster.nativeElement, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
      });
      const width = 794;
      const height = 1123;
      this.jsPDF = new jsPDF('p', 'px', [width, height], true);
      const image = canvas.toDataURL();
      this.jsPDF.addImage({
        imageData: image,
        x: 0,
        y: 0,
        width: width,
        height: height,
        compression: 'MEDIUM',
      });
      this.jsPDF.link(56, 873, 704, 30, { url: this.domain });
      this.jsPDF.link(587, 995, 115, 37, { url: this.appleHref });
      this.jsPDF.link(587, 1036, 115, 34, { url: this.androidHref });
      this.jsPDF.setProperties({
        title: `Marketing Poster`,
        subject: 'Marketing Poster',
        author: 'Altius',
        creator: 'Altius',
      });
    }
  }

  private getData() {
    this.isLoading = true;
    this.marketingPosterService.getData(this.companyId)
      .subscribe((res) => {
        this.isLoading = false;
        this.domain = res.hostname.startsWith('http') ? res.hostname : `https://${res.hostname}`;
        this.registrationCode = res.registerCode;
      });
  }
}
