<div *ngIf="alert == ''">
  <div class='w-100 pb-2 d-inline-block'>
    <div class='row m-0'>
      <div class='col-8 col-sm-9 pl-0'>
        <div *ngIf='!hideSearch' class='search-list mb-2'>
          <i class='fa fa-search'></i>
          <input id='search' type='search' [(ngModel)]='search' (keyup)='onSearchKeyup()' (search)='onSearchKeyup()' placeholder='{{searchPlaceholder()}}'
                 class='form-control'>
        </div>
      </div>
      <div class='col-4 col-sm-3 text-right pr-0'>
        <button *ngIf='otherButtonName' type='button' class='btn btn-primary mr-3' (click)='onClickOtherButton()'>
          {{otherButtonName}}
        </button>
        <button *ngIf='isCSVExport' type='button' [disabled]="selectedIdsCsv == ''" class='btn btn-primary mr-3'
                (click)='exportData()'>
          <i class='fa fa-download' aria-hidden='true'></i>
        </button>
        <button *ngIf='isCarousel' type='button' class='btn btn-primary btn-circle mr-3' data-toggle='tooltip'
                data-placement='top' title='Preview' (click)='showPreview()'>
          <i class='fa fa-eye' aria-hidden='true'></i>
        </button>
        <button *ngIf='!isPreventListDelete' type='button' [disabled]="selectedIdsCsv == ''" class='btn btn-primary mr-3'
                (click)='delete()'>
          <i class='fa fa-trash' aria-hidden='true'></i>
        </button>
        <button *ngIf='!isPreventAdd' type='button' class='btn btn-primary' (click)="goToDetails('new')">
          <i class='fa fa-plus' aria-hidden='true'></i>
        </button>
      </div>
    </div>
  </div>
  <div class='table-responsive shadow-lg'>
    <table class='table table-bordered table-striped table-hover'>
      <thead>
      <tr>
        <th *ngIf='!isPreventListDelete || isCSVExport' class='idCol'>
          <button *ngIf='!isSelectAll' class='btn' (click)='unselectAll()'>
            <i class='fa fa-minus-circle' aria-hidden='true'></i>
          </button>
          <button *ngIf='isSelectAll' class='btn' (click)='selectAll()'>
            <i class='fa fa-check-circle' aria-hidden='true'></i>
          </button>
        </th>
        <th *ngFor='let col of columnList; let i = index;' (click)='onColClick(i)' class='text-uppercase hand'>
          <i *ngIf='isAscendingSortColumn(i)' class='fa fa-caret-up' aria-hidden='true'></i>
          <i *ngIf='isDescendingSortColumn(i)' class='fa fa-caret-down' aria-hidden='true'></i>
          {{col}}
        </th>
      </tr>
      </thead>
      <tbody *ngIf='filteredList.length'>
      <tr *ngFor='let item of filteredList | paginate: config' [class]="disableIds.includes(item[0]) ? 'hand bold': 'hand'">
        <td *ngIf='!isPreventListDelete || isCSVExport'>
          <div class='custom-control custom-checkbox'>
            <input type='checkbox' [id]='item[0]' class='custom-control-input' [(ngModel)]='item.checked' (click)='selectItem(item)'>
            <label [class]="disableIds.includes(item[0]) ? 'custom-control-label d-none' : 'custom-control-label'" for='{{item[0]}}'>&nbsp;</label>
          </div>
        </td>
        <td *ngFor='let col of item; let i = index' (click)='goToDetails(item[0])'>
          <img class='table-image' src='{{col}}' *ngIf='imgColumn && imgColumn == i && hasImage(col)' />
          <span *ngIf='imgColumn != i'>{{col}}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <!-- details: http://michaelbromley.github.io/ngx-pagination/#/-->
  <div class='cms-pagination' *ngIf='filteredList.length'>
    <pagination-controls autoHide='true' (pageChange)='config.currentPage = $event'></pagination-controls>
  </div>

</div>
<div class='alert alert-danger' *ngIf="alert != ''">
  <strong>{{alert}}</strong>
</div>
<div class='alert alert-info' *ngIf="info != ''">
  <strong>{{info}}</strong>
</div>

<div style='padding-bottom: 50px;'></div>
