<div *ngIf='isLoading' class='row min-vh-100 text-center align-items-center'>
  <div class='col-12'>
    <i class='fa fa-spinner fa-spin fa-3x' aria-hidden='true'></i>
  </div>
</div>
<div *ngIf='hasError && !isLoading' class='row min-vh-100 text-center align-items-center'>
  <div class='col-12'>
    <span class='form-input-error-message'>Company not found!</span>
  </div>
</div>
<div *ngIf='!hasError && !isLoading'>
<!--  <div class='row'>-->
<!--    <div class='col-12 d-flex justify-content-center'>-->
<!--      <div class='header-logo mt-4 mb-3'>-->
<!--        <a href='https://www.altius-group.com.au/' target='_blank'>-->
<!--          <img src='assets/logo/altiusgroup_blue_logo.png' alt=''>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <hr>-->
  <div class='row'>
    <div class='col-12 eap-container'>
      <div class='flex-container mt-4 mb-3'>
        <img class='company-logo' [src]='logo' alt=''>
      </div>
      <h1 class='text-center mt-1'>Counselling Services</h1>
      <a class='text-center mt-3 mb-3' [href]='bookingUrl' target='_self'>
      <span class='qr-button'>
        <i class='fa fa-calendar mr-2' aria-hidden='true'></i>Book an Appointment
      </span>
      </a>
    </div>
  </div>
  <hr>
  <div class='row'>
    <div class='col-12 company-container'>
      <a class='text-center' href='https://www.altius-group.com.au/resources/altius-life' target='_blank'>
        <img class='logo' src='assets/logo/altiuslife_logo.png' alt=''>
      </a>
      <a class='text-center' [href]='homeUrl' target='_self'>
      <span class='qr-button'>
        Access AltiusLife Wellbeing
      </span>
      </a>
      <h3 class='mt-3'>AltiusLife Registration Code:</h3>
      <h2 class='qr-register-code'>{{registerCode}}</h2>
    </div>
  </div>
  <div class='row app-container mt-3'>
    <div class='col-12'>
      <h2>Download the AltiusLife App:</h2>
    </div>
    <div class='download-container mt-3'>
      <a href='https://apps.apple.com/au/app/altius-life/id1440228163' target='_blank'>
        <img src='assets/logo/btn_app_store_v2.png' alt=''>
      </a>
      <a href='https://play.google.com/store/apps/details?id=io.lifeiq.altuis&amp;hl=en_AU&amp;gl=US' target='_blank'>
        <img src='assets/logo/btn_google_play.png' alt=''>
      </a>
    </div>
  </div>
  <div class='row justify-content-center footer mt-4'>
    <p class='mt-4 mb-4'>Copyright © 2023 Altius Group. All Rights Reserved.</p>
  </div>
</div>
