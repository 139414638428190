import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ICompany } from '../../../_models/company';

@Component({
  selector: 'app-company-access-code-input',
  templateUrl: './company-access-code-input.component.html',
  styleUrls: ['./company-access-code-input.component.scss'],
})
export class CompanyAccessCodeInputComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() inputcss: any;
  @Input() selectedCompany: ICompany;
  @Output() companyAccessCodeInputted = new EventEmitter<string>();
  @ViewChild('companyCode') companyAccessCodeElement: ElementRef;

  constructor() {
  }

  ngOnInit() {
    this.companyAccessCodeElement.nativeElement.focus();
  }

  get companyAccessCode() {
    return this.parentForm.get('companyAccessCode');
  }


  onInputCompanyAccessCode(value) {
    this.companyAccessCodeInputted.emit(value);
  }

  isCompanyAccessCodeInvalidError() {
    return this.companyAccessCode.errors &&
      (this.companyAccessCode.errors.isInvalidCompanyNameOrCode || this.companyAccessCode.errors.pattern) &&
      this.companyAccessCode.dirty;
  }

  isCompanyAccessCodeRequiredError() {
    return this.companyAccessCode.errors &&
      this.companyAccessCode.errors.required &&
      this.companyAccessCode.touched;
  }

  isCompanyAccessCodeResolved() {
    return !this.companyAccessCode.pending &&
      this.companyAccessCode.value &&
      !this.companyAccessCode.errors &&
      this.companyAccessCode.dirty;
  }
}
