import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '../environments/_active-environment/environment';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { IPage } from '../app/_models/page';
import { UserService } from './user-service';

@Injectable({
  providedIn: 'root',
})
export class MarketingPageService {
  private readonly httpWithoutInterceptor: HttpClient;
  private readonly apiUrl: string;
  private _isLoaded = false;
  private _isLoading = false;

  constructor(private httpBackend: HttpBackend, private userService: UserService) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
    this.apiUrl = environment.app.endpoint;
  }

  private _data = new BehaviorSubject<any>({
    appStoreURL: '',
    googlePlayURL: '',
    contactUsURL: '',
  });

  isMarketingPage() {
    const schema = environment.name === 'altiuslife-dev' ? 'http://' : 'https://';
    return window.location.href.indexOf(`${schema}${this.userService.envVariables.app.mainDomain}`) !== -1 || window.location.href.indexOf(`${schema}www.${this.userService.envVariables.app.mainDomain}`) !== -1;
  }

  get data(): Observable<any> {
    return this._data.asObservable();
  }

  getMarketingPageData() {
    if (this._isLoading) {
      return;
    }
    if (!this._isLoaded) {
      this._isLoading = true;
      const path = '/cms/pages/marketing-page/en/0';
      this.httpWithoutInterceptor
        .get(`${this.apiUrl}${path}`)
        .pipe(take(1))
        .subscribe((res: IPage) => {
          this._isLoaded = true;
          this._isLoading = false;
          this._data.next(JSON.parse(res.data));
        });
    }
  }
}
