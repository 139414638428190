<div class="bg-tabset">
  <div class="col-12 mt-2 p-0">
    <div *ngIf="canShowNoFriendsText"
      class="col-12 mt-2 p-2">
        {{ noFriendsText }}
    </div>
    <div *ngFor="let friend of friends"
      class="row friend-line m-0 pl-1">
      <div class="col-3 col-md-2 p-2 text-center">
        <app-avatar
          [photoSrc]="friend.photoSrc"
          [levelSrc]="friend.levelSrc"
          (selectAvatar)="onViewFriend(friend)"
        ></app-avatar>
      </div>
      <div class="col-4 col-md-6 text-left p-2"
        (click)="onViewFriend(friend)">
        <p class="name">{{ friend.username }}</p>
      </div>
      <div class="col-5 col-md-4 p-2 align-right text-right">
        <a *ngIf="friend.requestActions.canCancelSentFriendRequest"
          class="btn btn-outline-primary mt-2 mr-2"
          (click)="onCancelSentFriendRequest(friend)">
          <i class="fa mr-1"
            [ngClass]="{'fa-ban': true}"
          ></i>
          Cancel Request
        </a>

        <a *ngIf="friend.requestActions.canConfirmReceivedFriendRequest"
          class="btn btn-outline-primary mt-2 mr-2"
          (click)="onConfirmReceivedFriendRequest(friend)">
          <i class="fa mr-1"
            [ngClass]="{'fa-ban': true}"
          ></i>
          Confirm
        </a>

        <a *ngIf="friend.requestActions.canDeclineReceivedFriendRequest"
          class="btn btn-outline-primary mt-2 mr-2"
          (click)="onDeclineReceivedFriendRequest(friend)">
          <i class="fa mr-1"
            [ngClass]="{'fa-ban': true}"
          ></i>
          Decline
        </a>

        <a *ngIf="friend.requestActions.canRemoveConfirmedFriend"
          class="btn btn-outline-primary mt-2 mr-2"
          (click)="onRemoveConfirmedFriend(friend)">
          <i class="fa mr-1"
            [ngClass]="{'fa-ban': true}"
          ></i>
          Remove
        </a>
      </div>
    </div>
  </div>
</div>
