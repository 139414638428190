import { Component, OnInit } from '@angular/core';
import { IIdNamePair } from '../../_models/id-name-pair';
import { LifeActionService } from '../../../services/life-action-service';
import { CompanyService } from '../../../services/company-service';
import { UtilService } from '../../../services/util-service';
import { UserService } from '../../../services/user-service';
import { ReportsService } from '../../../services/reports-service';
import { DateService } from '../../../services/date.service';

@Component({
  selector: 'app-life-actions-reporting',
  templateUrl: './life-actions-reporting.component.html',
  styleUrls: ['./life-actions-reporting.component.scss'],
})
export class LifeActionsReportingComponent implements OnInit {
  startDate: Date;
  endDate: Date;
  items = [];
  columns = [];
  allCompanies: IIdNamePair[] = [];
  companyId: number;
  canSelectCompany = false;
  canViewDetail = false;
  isInitialised: boolean;

  constructor(
    private userService: UserService,
    private lifeActionService: LifeActionService,
    private companyService: CompanyService,
    private utilService: UtilService,
    private reportService: ReportsService,
    private dateService: DateService) {
  }

  ngOnInit() {
    this.canSelectCompany = this.userService.isSuperAdminUser();
    this.canViewDetail = !this.userService.isSuperAdminUser();
    this.companyId = (this.userService.isSuperAdminUser() || this.userService.isAdminUser()) ? this.reportService.cmsCompanyId
      : this.userService.company.id;
    this.startDate = this.reportService.cmsStartDate;
    this.endDate = this.reportService.cmsEndDate;

    this.getLifeActionsList(this.companyId, this.startDate, this.endDate);
  }

  onFilterSelected($event) {
    this.reportService.cmsCompanyId = $event.companyId || 0;
    this.reportService.cmsStartDate = $event.startDate;
    this.reportService.cmsEndDate = $event.endDate;

    this.getLifeActionsList($event.companyId || 0, $event.startDate, $event.endDate);
  }

  private getLifeActionsList(companyId: number, startDate: Date, endDate: Date) {
    const body = {
      startDate: this.dateService.formatYYYY_MM_DD(startDate),
      endDate: this.dateService.formatYYYY_MM_DD(endDate),
    };
    this.lifeActionService
      .getLifeActionsReporting(companyId, body)
      .subscribe(data => {
          this.items = data.map((i) => {
            return [
              i.id,
              i.name,
              i.usercount,
            ];
          });

          this.columns = [
            'id',
            'name',
            'activated users',
          ];
          this.isInitialised = true;
        },

        (error) => this.utilService.showToastError('Error while loading life actions reporting - ' + error),
      );
  }
}
