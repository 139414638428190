import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from './user-service';
import { ICompany } from '../app/_models/company';
import { InsightsService } from './insights-service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private selectedCompany = new BehaviorSubject<any>({});
  selectedCompany$ = this.selectedCompany.asObservable();

  public company: any[] = [];
  registerCodeFormat = /^[a-zA-Z0-9]+$/;
  companyDomainNameFormat = /^[-a-zA-Z0-9]+$/;
  private cachedCompanies: ICompany[] = [];
  private currentCompanyId: number;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private insightsService: InsightsService,
  ) {
  }

  setSelectedCompany(company: ICompany | {}) {
    this.selectedCompany.next(company);
  }

  setCurrentCompanyId(companyId: number) {
    this.currentCompanyId = companyId;
  }

  clearSelectedCompany() {
    this.selectedCompany.next({});
  }

  getSelectedCompany(): Observable<any> {
    return this.selectedCompany$;
  }

  getCurrentCompanyId(): number {
    return this.currentCompanyId || this.userService.company.id;
  }

  // getCompany(keyword) {
  //   const url = `/companies/companyCode/verifyCode`;
  //   const body = { type: 'keyword', value: keyword };
  //
  //   return this.http.post<any>(url, body).pipe(
  //     tap((res) => {
  //       this.company = res;
  //     }),
  //   );
  // }

  getCompanyById(id: number) {
    const url = `/cms/companies/${id}`;
    return this.http.get<any>(url);
  }

  getCompanyByIdForWidget(id: number) {
    const url = `/companies/${id}`;
    return this.http.get<any>(url);
  }

  getAllCompanies() {
    const url = `/cms/companies`;
    return this.http.get<any>(url);
  }

  getAllCachedCompanies(): ICompany[] {
    return this.cachedCompanies;
  }

  refreshCache() {
    if (this.cachedCompanies.length) {
      return this.cachedCompanies;
    }
    if (!this.userService.isSuperAdminUser()) {
      return;
    }

    this.getAllCompanies().subscribe((data) => (this.cachedCompanies = data));
  }

  refreshCacheWithCallback(callback: Function) {
    if (this.cachedCompanies.length) {
      callback();
    } else {
      this.getAllCompanies().subscribe((data) => {
        this.cachedCompanies = data;
        callback();
      });
    }
  }

  forceRefreshCache() {
    if (!this.userService.isSuperAdminUser()) {
      return;
    }

    this.getAllCompanies().subscribe((data) => (this.cachedCompanies = data));
  }

  companyName(id: number): string {
    const company = this.cachedCompanies.find((c) => c.id === id);
    if (!company) {
      return '';
    }

    return company.name;
  }

  getCompanyFromCache(id: number): ICompany {
    const company = this.cachedCompanies.find((c) => c.id == id);
    if (!company) {
      return null;
    }

    return company;
  }

  getNewCompany(): ICompany {
    return {
      isActive: true,
      name: '',
      hasInsights: false,
      logo: '',
      registerCode: '',
      companyContent: [],
      features: this.userService.getUserCompanyFeatures(),
      preferences: [],
      primaryColor: '#16b5c7',
      topBarColor: '#ffffff',
      topTextBarColor: '#8c8c8c',
      backgroundColor: '#f3f3f3',
      bodyColor: '#606060',
      bottomBarColor: '#ffffff',
      bottomTextBarColor: '#8c8c8c',
      mailchimpListId: '',
      welcomeText: '',
      welcomeTextLink: { description: '', label: '', url: '' },
      rewardsText: '',
      domain: this.userService.hostname,
      hostname: this.userService.hostname,
      users: 0,
      updatedAt: new Date(),
      programs: [],
      insightsDomain: '',
      insightsVersion: this.insightsService.getDefaultInsightsVersion(),
      bookingCode: '',
      leapIntegration: true
    };
  }

  save(id, body) {
    if (id !== 0) {
      return this.http.put<any>('/cms/companies/' + id, body);
    } else {
      return this.http.post<any>('/cms/companies', body);
    }
  }

  delete(id: number) {
    return this.http.delete<any>(`/cms/companies/${id}`);
  }

  activate(id: number) {
    const url = `/cms/companies/activate/${id}`;

    return this.http.put<any>(url, null);
  }

  inactivate(id: number) {
    const url = `/cms/companies/inactivate/${id}`;

    return this.http.put<any>(url, null);
  }

  isValidCompanyNameOrCode(code: string) {
    const url = `/companies/is-valid-company?code=${code}`;
    return this.http.get<ICompany>(url);
  }

  setCompanyId(id) {
    if (id) {
      localStorage.setItem('companyId', id);
    }
  }

  getCompanyId() {
    return localStorage.getItem('companyId');
  }

  removeCompanyId() {
    localStorage.removeItem('companyId');
  }

  generateLink(body) {
    return this.http.post<any>('/cms/companies/generate-link', body);
  }

  getCompanyInfoByDomain(domain: string) {
    const url = `/companies/company-info?domain=${domain}`;
    return this.http.get<ICompany>(url);
  }

  getSummary(id) {
    return this.http.get<any>(`/cms/companies/summary/${id}`);
  }
}
