import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { IDateYmd } from '../app/_models/date-ymd';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  getTimePartOfDay(inputDateTime?) {
    const dateTime = inputDateTime || new Date();
    const hour = dateTime.getHours();

    if (hour < 12) { return 'morning'; }
    if (hour < 17) { return 'afternoon'; }

    return 'evening';
  }

  getLocalDateTimeTzOffset(date?) {
    const localDateTime = date || new Date();

    return moment(localDateTime).format();
  }

  getYearMonthDayObject(date?) {
    const sourceDate = date || new Date();

    const year = moment(sourceDate).year();
    const month = moment(sourceDate).month() + 1;
    const day = moment(sourceDate).date();

    return { year, month, day };
  }

  isValidDateYmd(d: IDateYmd): boolean {
    return !!d.year && !!d.month && !!d.day;
  }

  isToday(date) {
    return (
      moment()
        .startOf('day')
        .diff(date, 'days') === 0 &&
      moment()
        .endOf('day')
        .diff(date, 'days') === 0
    );
  }

  isBeforeToday(date) {
    return (
      moment()
        .startOf('day')
        .diff(date) > 0
    );
  }

  isAfterToday(date) {
    return (
      moment()
        .startOf('day')
        .diff(date, 'days') < 0
    );
  }

  isYesterday(date) {
    return this.daysDifference(date) === 1;
  }

  isTomorrow(date) {
    return this.daysDifference(date) === -1;
  }

  isAFutureDate(date) {
    return this.daysDifference(date) < 0;
  }

  isSameDate(date1, date2) {
    if (!date1 || !date2) {
      return false;
    }

    return this.daysDifference(date1, date2) === 0;
  }

  // Used for date time equality in tests
  isSameDateTime(dateTime1, dateTime2) {
    return Math.abs(dateTime2.getTime() - dateTime1.getTime()) < 100;
  }

  hoursDifference(fromTime, toTime?) {
    const minuend = toTime || new Date();

    return moment(minuend).diff(fromTime, 'hours');
  }

  daysDifference(fromDate, toDate?) {
    const minuend = moment(toDate || new Date()).startOf('day');
    const subtrahend = moment(fromDate).startOf('day');

    return moment(minuend).diff(subtrahend, 'days');
  }


  fromUnixTimestamp(number): Date {
    return moment.unix(number).toDate();
  }
  // to convert the ngb Datepicker object back to a date;
  convertNgbDateStruct(ngbDate) {
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  }

  getMaxDate(dates: any[]): Date {
    if (dates.length === 0) {
      return null;
    }

    return new Date(Math.max(...dates));
  }

  getDate(daysOffset = 0): Date {
    const date = new Date();
    date.setDate(date.getDate() + daysOffset);

    return date;
  }

  addMonths(date: Date, increment: number): Date {
    return moment(date)
      .add(increment, 'month')
      .toDate();
  }

  addDays(date: Date, increment: number): Date {
    return moment(date)
      .add(increment, 'days')
      .toDate();
  }

  addMinutesFromNow(increment: number): Date {
    return moment()
      .add(increment, 'minutes')
      .toDate();
  }

  fromDD_MMM_YYYY(ddmmmyyyy): Date {
    return moment(ddmmmyyyy, 'DD MMM YYYY').toDate();
  }

  formatDDD_DD_MMM_YYYY(date?) {
    const sourceDate = date || new Date();

    return moment.parseZone(sourceDate).format('ddd DD MMM YYYY');
  }

  formatDD_MMM_YYYY(date?) {
    const sourceDate = date || new Date();

    return moment.parseZone(sourceDate).format('DD MMM YYYY');
  }

  formatDD_MMM_YYYY_HH_mm_ss_z(date?) {
    const sourceDate = date || new Date();

    return moment.parseZone(sourceDate).format('DD MMM YYYY - HH:mm:ss z');
  }

  formatDD_MMM_YYYY_Allow_Null(date?) {
    if (date != null) {
      return moment.parseZone(date).format('DD MMM YYYY');
    } else {
      return '';
    }
  }

  formatDD_MMM_YYYY_Allow_Null_With_Text(date?) {
    if (date != null) {
      return moment.parseZone(date).format('DD MMM YYYY');
    } else {
      return 'NULL';
    }
  }

  formatDDMMYYYSlash(date?) {
    const sourceDate = date || new Date();

    return moment.parseZone(sourceDate).format('DD/MM/YYYY');
  }

  formatDDMMYYDash(date?) {
    const sourceDate = date || new Date();

    return moment.parseZone(sourceDate).format('DD-MM-YY');
  }

  formatYYYY_MM_DD(date?) {
    const sourceDate = date || new Date();

    return moment.parseZone(sourceDate).format('YYYY-MM-DD');
  }

  formatLocalYYYY_MM_DD(date?) {
    const sourceDate = date || new Date();

    return moment.parseZone(sourceDate).local().format('YYYY-MM-DD');
  }

  formatMMM(date?) {
    const sourceDate = date || new Date();

    return moment.parseZone(sourceDate).format('MMM');
  }

  formatDay(date?) {
    const sourceDate = date || new Date();

    return moment.parseZone(sourceDate).format('dd');
  }

  formatDDMM(date?) {
    const sourceDate = date || new Date();

    return moment.parseZone(sourceDate).format('DD-MM');
  }

  formatLongDatetime(date?) {
    const sourceDate = date || new Date();

    return moment.parseZone(sourceDate).format('llll');
  }

  formatReadableDateDefaultingToToday(date = new Date()) {
    const readableDate = moment(date).fromNow();
    let dateString;
    this.isSameDate(date, new Date()) ? dateString = 'today' : dateString = readableDate;
    return dateString;
  }

  toYearMonthDayObject(date?) {
    const sourceDate = this.formatLocalYYYY_MM_DD(date);
    const momentDate = moment(sourceDate, 'YYYY-MM-DD');

    return {
      year: momentDate.year(),
      month: momentDate.month() + 1,
      day: momentDate.date()
    };
  }

  toYearMonthDayObjectOrNull(date?) {
    if (!date) { return null; }

    return this.toYearMonthDayObject(date);
  }

  fromYearMonthDayObject(ymd) {
    if (ymd) {
      return new Date(ymd.year, ymd.month - 1, ymd.day);
    }
  }

  fromYearMonthDayObjectToYmdFormat(ymd) {
    const date = this.fromYearMonthDayObject(ymd);
    return this.formatYYYY_MM_DD(date);
  }

  isBeforeYesterday(date): boolean {
    const yesterday = moment()
      .startOf('day')
      .subtract(1, 'days');

    return moment(date).isBefore(yesterday);
  }

  timeFromNow(dateTime): string {
    return moment(dateTime).fromNow();
  }

  validateDate(control) {
    if (control) {
      const value = control.replace('_', '');
      const arrValue = value.split('-');
      const arrMonthsDates = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (value === '') {
        return {
          'Date required': true
        };
      } else if (isNaN(arrValue[0]) || arrValue[0] < 1910 || arrValue[0] > 2010) {
        return {
          'Year invalid': true
        };
      } else if (isNaN(arrValue[1]) || arrValue[1] < 1 || arrValue[1] > 12) {
        return {
          'Month invalid': true
        };
      } else if (isNaN(arrValue[2]) || arrValue[2] < 1 || arrValue[2] > 32) {
        return {
          'Day invalid': true
        };
      } else if (arrMonthsDates[arrValue[1] - 1] < arrValue[2]) {
        return {
          'Out of date range': true
        };
      }
    }
    return null;
  }

  getStartOfDay(date?) {
    const referenceDate = date || new Date();
    return moment(referenceDate).startOf('day').toDate();
  }

  getEndOfDay(date?) {
    const referenceDate = date || new Date();
    return moment(referenceDate).endOf('day').toDate();
  }

  getStartOfWeek(date?) {
    const referenceDate = date || new Date();
    return moment(referenceDate).startOf('week').toDate();
  }

  getEndOfWeek(date?) {
    const referenceDate = date || new Date();
    return moment(referenceDate).endOf('week').toDate();
  }

  getStartOfMonth(date?) {
    const referenceDate = date || new Date();
    return moment(referenceDate).startOf('month').toDate();
  }

  getEndOfMonth(date?) {
    const referenceDate = date || new Date();
    return moment(referenceDate).endOf('month').toDate();
  }

  getStartOfQuarter(date?) {
    const referenceDate = date || new Date();
    return moment(referenceDate).startOf('quarter').toDate();
  }

  getEndOfQuarter(date?) {
    const referenceDate = date || new Date();
    return moment(referenceDate).endOf('quarter').toDate();
  }

  getStartOfPeriod(date = new Date(), period?) {
    const parsedPeriod: moment.unitOfTime.StartOf = period || 'day';
    return moment(date).startOf(parsedPeriod).toDate();
  }

  getEndOfPeriod(date = new Date(), period?) {
    const parsedPeriod: moment.unitOfTime.StartOf = period || 'day';
    return moment(date).endOf(parsedPeriod).toDate();
  }

  getStartAndEndOfPeriodPairs(date = new Date(), period?) {
    const startDate = this.getStartOfPeriod(date, period);
    const endDate = this.getEndOfPeriod(date, period);
    return {
      startDate,
      endDate
    }
  }

  getStartAndEndOfPeriodPairsTo(period?, date = new Date(), days = 7) {
    const dates = [];
    const endPair = this.getStartAndEndOfPeriodPairs(date, period);
    dates.push(endPair);
    while (dates.length < days) {
      dates.unshift(this.getStartAndEndOfPeriodPairs(moment(date).subtract(dates.length, period).toDate(), period));
    }
    return dates;
  }

  getStartAndEndOfDaysPairsTo(days, date = new Date(), pairs = 7) {
    const dates = [];
    const endPair = this.getStartAndEndOfDaysPairs(days, date);
    dates.push(endPair);
    while (dates.length < pairs) {
      const newStart = moment(date).subtract(days * dates.length, 'days').toDate();
      dates.unshift(this.getStartAndEndOfDaysPairs(days, newStart));
    }
    return dates;
  }

  getStartAndEndOfDaysPairs(days, date) {
    const startDate = this.getStartOfDaysPeriod(days, date);
    const endDate = this.getEndOfDaysPeriod(days, date);
    return {
      startDate,
      endDate
    };
  }

  getStartOfDaysPeriod(days, date) {
    return moment(date).subtract(days, 'days').startOf('day').toDate();
  }

  getEndOfDaysPeriod(days, date) {
    return moment(date).endOf('day').toDate();
  }

  getDaysTo(date = new Date(), days = 7) {
    const dates = [];
    dates.push(date);
    while (dates.length < days) {
      dates.unshift(this.getDate(-dates.length));
    }
    return dates;
  }

  getWeeksTo(date = new Date(), weeks = 7) {
    const dates = [];
    const endDate = this.getStartOfWeek(date);
    dates.push(endDate);
    while (dates.length < weeks) {
      dates.unshift(moment(endDate).subtract(dates.length, 'weeks').toDate());
    }
    return dates;
  }

  getMonthsTo(date = new Date(), months = 7) {
    const dates = [];
    const endDate = this.getStartOfMonth(date);
    dates.push(endDate);
    while (dates.length < months) {
      dates.unshift(moment(endDate).subtract(dates.length, 'months'))
    }
    return dates;
  }

  isBetweenTwoDatesInclusive(date, startDate, endDate) {
    if (!date) {
      return false;
    }
    return moment(date).isBetween(startDate, endDate, null, '[]');
  }

  getNextDate(date: Date, distance: number) {
    return moment(date).add(distance, 'days').toDate();
  }
}
