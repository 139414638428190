import { FlaggingService } from '../../../../services/flagging-service';
import { UserService } from '../../../../services/user-service';
import { Component, Input, Output, EventEmitter } from '@angular/core';

export class ItemDetails {
  constructor(
    public itemId: number,
    public userProfileId: number,
    public itemIndex: number,
  ) {
  }
}

export enum ItemTypes {
  post,
  comment
}

@Component({
  selector: 'app-flagging',
  templateUrl: './flagging.component.html',
  styleUrls: ['./flagging.component.scss'],
})

export class FlaggingComponent {
  @Input() teamId: any;
  @Input() contentId: any;
  @Input() contentType_: any;
  @Input() groupId: any;
  @Input() postId: any;
  @Input() commentId: any;
  @Input() companyId: any;
  @Input() itemType: string;
  @Input() canUserEditItem = false;
  @Input() canUserDeleteItem = false;
  @Input() itemId = 0;
  @Input() userProfileId = 0;
  @Input() itemIndex = 0;
  @Output() deleteItem: EventEmitter<ItemDetails>;
  @Output() editItem: EventEmitter<ItemDetails>;

  constructor(
    public userService: UserService,
    public flaggingService: FlaggingService,
  ) {
    this.deleteItem = new EventEmitter();
    this.editItem = new EventEmitter();
  }

  private reportContent(contentType) {

    let articleId;
    let recipeId;
    let videoId;

    if (this.contentType_ === 'article') {
      articleId = this.contentId;
    } else if (this.contentType_ === 'recipe') {
      recipeId = this.contentId;
    } else if (this.contentType_ === 'video') {
      videoId = this.contentId;
    }

    const body = {
      reason: contentType,
      groupId: +this.groupId,
      teamId: +this.teamId,
      articleId: +articleId,
      recipeId: +recipeId,
      videoId: +videoId,
      companyId: +this.companyId,
      postId: +this.postId,
      commentId: +this.commentId,
      userProfileId: +this.userService.profile.id,
    };

    this.flaggingService.postFlaggedContent(body).subscribe(() => {
    });
  }

  onReportSpamClick() {
    this.reportContent(this.flaggingService.ContentTypeSpam);
  }

  onReportOffensiveClick() {
    this.reportContent(this.flaggingService.ContentTypeOffensive);
  }

  onReportOtherClick() {
    this.reportContent(this.flaggingService.ContentTypeOther);
  }

  onEditItemClick() {
    const itemDetails = new ItemDetails(this.itemId, this.userProfileId, this.itemIndex);
    this.editItem.emit(itemDetails);
  }

  onDeleteItemClick() {
    const itemDetails = new ItemDetails(this.itemId, this.userProfileId, this.itemIndex);
    this.deleteItem.emit(itemDetails);
  }
}
