<nav *ngIf='!isLoggedIn' class='navbar navbar-expand-lg py-2 show-login'>
<!--  <img *ngIf='logoSrc' class='logo' [src]='logoSrc' alt='Logo'/>-->
  <button type='button' class='login' [disabled]='!(isConnectionLoaded() | async)' (click)='onLoginClick()'>
    Login
  </button>
</nav>

<nav *ngIf='isLoggedIn && logoSrc' class='navbar navbar-expand-lg'>
  <button #navbarToggler
          class='navbar-toggler navbar-dark' type='button'
          data-toggle='collapse' data-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent' aria-expanded='Toggle navigation'>
    <span class='navbar-toggler-icon mt-1 mb-1'></span>
  </button>
  <a class='navbar-brand mr-auto' (click)='onLogoSelected()'>
    <img *ngIf='logoSrc' [src]='logoSrc' alt='logo' class='brand-logo' />
  </a>

<!--  <div #navbarTarget id='navbarSupportedContent' class='collapse navbar-collapse'>-->
<!--    <ul class='navbar-nav ml-md-auto list-unstyled'>-->
<!--      <li class='nav-item dropdown discover-menu'>-->
<!--        <a class='nav-link dropdown-toggle'-->
<!--           href='#' id='navbarDropdown'-->
<!--           role='button' data-toggle='dropdown'-->
<!--           aria-haspopup='true' aria-expanded='false' #discoverMenu>-->
<!--          Dashboard-->
<!--        </a>-->
<!--      </li>-->

<!--      <li class='nav-item dropdown discover-menu'>-->
<!--        <a class='nav-link dropdown-toggle'-->
<!--           href='#' id='navbarDropdown'-->
<!--           role='button' data-toggle='dropdown'-->
<!--           aria-haspopup='true' aria-expanded='false' #discoverMenu>-->
<!--          Report-->
<!--        </a>-->
<!--      </li>-->
<!--    </ul>-->
<!--  </div>-->

  <app-profile-dropdown *ngIf='photoSrc'
    [isHoverDropdown]='true'
    [photoSrc]='photoSrc'
    [levelSrc]='levelSrc'
  ></app-profile-dropdown>
</nav>
