import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';

import { Observable, timer } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CompanyService } from '../services/company-service';
import { StripeService } from '../services/stripe-service';

@Injectable({ providedIn: 'root' })
export class CompanyValidator {
  constructor(
    public companyService: CompanyService,
    public stripeService: StripeService,
  ) {
  }

  checkIsValidCompanyNameOrCode(code: string) {

    return timer(1000).pipe(
      switchMap(() => {
        return this.companyService.isValidCompanyNameOrCode(code);
      }),
    );
  }

  companyValidator(): AsyncValidatorFn {
    return (ctrl: AbstractControl): Observable<{ [key: string]: any } | null> => {
      return this.checkIsValidCompanyNameOrCode(ctrl.value)
        .pipe(
          tap(res => {
            if (res) {
              this.companyService.setSelectedCompany(res);
              this.stripeService.getAllCompanyStripePlans(res.id).subscribe((plans) => {
                this.stripeService.setCompanyStripePlans(plans);
              });
            }
          }),
          map(res => {
            if (!res) {
              this.companyService.setSelectedCompany({});
              this.stripeService.clearCompanyStripePlans();
              if (ctrl.value && ctrl.value.trim().length) {
                return { isInvalidCompanyNameOrCode: true };
              }
              if (!res) {
                return { isInvalidCompanyNameOrCode: true };
              }
            }
          }),
        );
    };
  }

}
