import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationsService } from '../../services/notifications-service';
import { ContentService } from '../../services/content-service';
import { AccountComponent } from '../account/account.component';
import { INotification } from '../_models/notification';

@Component({
  selector: 'app-notifications-dropdown',
  templateUrl: './notifications-dropdown.component.html',
  styleUrls: ['./notifications-dropdown.component.scss'],
})
export class NotificationsDropdownComponent implements OnInit, OnDestroy {
  @Input() isHoverDropdown = false;
  @Output() dropdownClosed = new EventEmitter<null>();
  @ViewChild('myDropdown') myDropdown: NgbDropdown;

  notifications: INotification[] = [];
  offset = 0;
  limit = 10;
  numberUnread: number;
  unreadCountSubscription: Subscription;
  canShowCurrentUnreadCount = false;

  constructor(public contentService: ContentService,
              private notificationsService: NotificationsService,
              private router: Router,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.unreadCountSubscription = this.notificationsService.currentNumberUnread$
      .subscribe((number) => {
        this.numberUnread = number;
        this.canShowCurrentUnreadCount = number > 0;
      });
    this.loadNotifications();
  }

  ngOnDestroy(): void {
    if (this.unreadCountSubscription) {
      this.unreadCountSubscription.unsubscribe();
    }
  }

  private loadNotifications() {
    this.notificationsService.getUserNotifications(this.offset, this.limit)
      .subscribe((data) => {
        this.notifications = data;
      });
  }

  private closeDropdown() {
    this.myDropdown.close();
    this.dropdownClosed.next();
  }

  onMouseEnter(event: Event) {
    if (!this.isHoverDropdown) {
      return;
    }

    event.stopPropagation();
    this.myDropdown.open();
  }

  onMouseLeave(event: Event) {
    if (!this.isHoverDropdown) {
      return;
    }

    event.stopPropagation();
    this.myDropdown.close();
  }

  // markRead(event) {
  //   this.notificationsService.getUserNotifications(this.offset, this.limit).subscribe((latestNotifications) => {
  //     this.notifications = latestNotifications;
  //     // only mark notifications read on dropdown OPEN event ie, if event is true
  //     if (event) {
  //       const array = [];
  //       let numberMarkedRead = 0;
  //       latestNotifications.map((notification) => {
  //         if (!notification.read) {
  //           array.push({ id: notification.id, read: true });
  //           numberMarkedRead++;
  //         }
  //       });
  //       this.notificationsService.readNotification(array).subscribe(() => {
  //         const newObs = this.numberUnread - numberMarkedRead;
  //         this.notificationsService.changeNumberUnread(newObs);
  //       });
  //     }
  //   });
  // }

  markRead(notification) {
    this.notificationsService.readNotification([notification]).subscribe(() => {
      const newObs = this.numberUnread - 1;
      this.notificationsService.changeNumberUnread(newObs);
    });
  }

  openPage(notification) {
    if (!notification.read) {
      notification.read = true;
      this.markRead({ id: notification.id, read: true });
    }
    switch (notification.notificationCategory.page) {
      case 'ArticleDetailPage':
        this.contentService.changeDataType('article');
        this.router.navigate([`home/article/${notification.contentId}`]);
        break;
      case 'RecipeDetailPage':
        this.contentService.changeDataType('recipe');
        this.router.navigate([`discover/home-life/recipes/recipe/${notification.contentId}`]);
        break;
      case 'VideoDetailPage':
        this.contentService.changeDataType('video');
        this.router.navigate([`home/video/${notification.contentId}`]);
        break;
      case 'GroupDetailPage':
        this.router.navigate([`share/groups/${notification.contentId}`]);
        break;
      case 'FriendsPage':
        this.router.navigate([`share/friends`]);
        break;
      case 'BadgesPage':
        this.router.navigate([`reward/badges`]);
        break;
      case 'ActionsPage':
        this.router.navigate([`act/lifeactions`]);
        break;
      case 'TMHPage':
        this.router.navigate([`act/your-health`]);
        break;
      case 'AccountPage':
        this.modalService.open(AccountComponent);
        break;
      case 'ProfilePage':
        this.modalService.open(AccountComponent);
        break;
      case 'WeekSummaryPage':
        this.router.navigate([`notifications/weekly-summary`, notification.createdAt]);
        break;
      case 'ProgramDetailPage':
        this.router.navigate([`act/programs/${notification.webRedirectString}`]);
        break;
      case 'ProgramsPage':
        this.router.navigate([`act/programs/${notification.contentId}`]);
        break;
      case 'UserReportPage':
        this.router.navigate([`/cms/users-reporting`]);
        break;
      case 'RecipePage':
        this.router.navigate([notification.webRedirectString]);
        break;
      case 'ArticlePage':
        this.router.navigate([notification.webRedirectString]);
        break;
      case 'VideoPage':
        this.router.navigate([notification.webRedirectString]);
        break;
    }

    this.closeDropdown();
  }

  viewAll() {
    this.notificationsService.navigationFromDropdown = true;
    this.router.navigate(['/notifications']);
    this.closeDropdown();
  }

  markAllRead() {
    this.notificationsService.markAllRead()
      .subscribe(() => {
        this.notificationsService.changeNumberUnread(0);
        this.closeDropdown();
      });
  }
}
