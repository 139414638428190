export interface IRegister {
  email: string;
  password: string;
  company: number;
  mobileBillingPartner?: string;
  msisdn?: string;
  isThirdPartyRedirect?: boolean;
}

export interface ILogin {
  data: {
    email: string;
    password: string;
  };
}

export interface IAuthRouteData {
  isLogin: boolean;
  isRegister: boolean;
  isForgetPassword: boolean;
  isResetPassword: boolean;
  isSecureLogin: boolean;
}

export enum AuthRoutePath {
  login = '/auth/login',
  register = '/auth/register',
  registerSuccessfully = '/auth/login?isRegistered=true',
  forgetPassword = '/auth/forget-password',
  resetPassword = '/auth/reset-password'
}
