<div class="flex">
  <a *ngIf="isLoggedIn"
    [class]="css">
    <i class="fa top-icon" aria-hidden="true"
      [ngClass]="{
        'fa-thumbs-up': liked,
        'fa-thumbs-o-up': !liked
      }"
    ></i>
  </a>

  <a class="pt-1 {{css}}">
    <span>{{likes | pluralize: 'like':'likes':' '}}</span>
  </a>
</div>
