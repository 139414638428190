<div
  class="padding jumbotron">
  <form [formGroup]="itemForm"
    (ngSubmit)="onSaveClick()" >

    <div class="row m-0">
      <div class="col-12">
        <div>
          <h5>
          Create New Group
          </h5>
        </div>
        <!-- Name -->
        <div class="form-group">
          <label for="name" class="control-label required">
            Name
          </label>
          <input
            type="text"
            class="form-control"
            [ngClass]="displayFieldCss('name')"
            formControlName="name">
          <span class="invalid-feedback">Name is required</span>
        </div>

        <!-- Description -->
        <div class="form-group">
          <label for="description">Description</label>
          <textarea
            name="description"
            id="description"
            class="form-control"
            formControlName="description"
            >
          </textarea>
        </div>

        <!-- Banner -->
        <div class="form-group">
          <label for="banner" class="control-label required">
            Banner
          </label>
          <div class="input-group">
            <input
              type="text"
              name="banner" id="banner"
              class="form-control"
              [ngClass]="displayFieldCss('banner')"
              placeholder="Choose the banner"
              formControlName="banner"
              readonly />
            <div class="input-group-prepend">
              <button
                type="button"
                class="input-group-text btn btn-primary"
                (click)="onBannerClick()">
                  Browse
              </button>
            </div>
            <span class="invalid-feedback">Banner is required</span>
          </div>
        </div>

        <!-- Logo -->
        <div class="form-group">
          <label for="logo" class="control-label required">
            Logo
          </label>
          <div class="input-group">
            <input
              type="text"
              name="logo" id="logo"
              class="form-control"
              [ngClass]="displayFieldCss('logo')"
              placeholder="Choose the logo"
              formControlName="logo"
              readonly />
            <div class="input-group-prepend">
              <button
                type="button"
                class="input-group-text btn btn-primary"
                (click)="onLogoClick()">
                  Browse
              </button>
            </div>
            <span class="invalid-feedback">Logo is required</span>
          </div>
        </div>

        <div class="form-group">
          <label for="name" class="control-label">
            Invite Friends
          </label>
          <tag-input formControlName="friends" [(ngModel)]="selectedFriend" [onlyFromAutocomplete]="true" [onAdding]="asyncOnAdding" 
          placeholder="Select friends" secondaryPlaceholder="Select friends">
              <tag-input-dropdown 
                  [autocompleteItems]="userFriends"
                  [displayBy]="'value'"
                  [identifyBy]="'id'"
                  >
              </tag-input-dropdown>
          </tag-input>
        </div>
        <div class="col-6">
          <button
            type="submit"
            class="btn btn-primary float-right w-100"
            [disabled]="!canRespondToButtons">
            <i class="fa fa-floppy-o mr-2" aria-hidden="true"></i> SAVE
          </button>
        </div>
      </div>
    </div>

    

      
  </form>
</div>
