<div *ngIf='isDisplayingList && isInitialised'>
  <app-cms-list-view
    *ngIf='isInitialised'
    [dataList]='items'
    [isPreventListDelete]='true'
    [isPreventAdd]='false'
    [columnList]='columns'>
  </app-cms-list-view>
</div>

<div *ngIf='(isAddingItem || isViewingItem) && isInitialised'
     class='padding jumbotron'>
  <form
    [formGroup]='itemForm'
    (ngSubmit)='onSaveClick()'>
    <div class='row'>
      <div *ngIf='showLanguageExistedError' class='col-12'>
        <p class='form-input-error-message'>Language is existed!</p>
      </div>
    </div>
    <div class='row'>
      <div class='col-6'>
        <div class='form-group'>
          <label class='control-label required'>Language</label>
          <select *ngIf='isAddingItem'
            class='form-control custom-select'
            formControlName='nameControl'
            [ngClass]="displayFieldCss('nameControl')"
            (change)='onLanguageChange($event.target.value)'>
            <option
              *ngFor='let item of languages'
              [value]='item.code'>{{item.name}}
            </option>
          </select>
          <input *ngIf='isViewingItem'
            type='text' readonly
            formControlName='nameControl'
            class='form-control' />
          <span class='invalid-feedback'>Language is required</span>
        </div>
      </div>

      <div class='col-6'>
        <div class='form-group'>
          <label class='control-label required'>Language</label>
          <input
            type='text' readonly
            formControlName='codeControl'
            [ngClass]="displayFieldCss('codeControl')"
            class='form-control' />
        </div>
      </div>
    </div>

    <div class='row' *ngIf="item == null || item.code !== 'en'">
      <div class='col-6' *ngFor="let c of languageTranslationTextCodes">
        <div class='form-group'>
          <label class='control-label required'>{{getFormControlLabel(c)}}</label>
          <input
            type='text'
            maxlength="64"
            formControlName="{{c}}"
            [ngClass]="displayFieldCss(c)"
            class='form-control' />
          <span class='invalid-feedback'>{{getFormControlLabel(c)}} is required</span>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <br />
    <div class='row'>
      <div class='col-4'></div>
      <div class='col-4'>
        <button
          type='button'
          class='btn btn-outline-primary w-100'
          (click)='onBackClick()'>
          <i class='fa fa-undo mr-2' aria-hidden='true'></i> BACK
        </button>
      </div>

      <div class='col-4'>
        <button
          type='submit'
          class='btn btn-primary float-right w-100'
          [disabled]='!canRespondToButtons || !itemForm.valid'>
          <i class='fa fa-floppy-o mr-2' aria-hidden='true'></i> SAVE
        </button>
      </div>
    </div>
  </form>
</div>
