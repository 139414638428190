<div *ngIf='isCmsPreview'>
  <button type='button' class='close' aria-label='Close' (click)='closeModal()'>
    <span aria-hidden='true'>&times;</span>
  </button>
</div>
<div *ngIf='!isDataAvailable && item'>
  <h5>No content available!</h5>
</div>
<div class='content-background' *ngIf='isDataAvailable'>

  <div *ngIf='item.isArticle'>
    <div class='card-img-top-div img-size-div'>
      <img class='img-size' [src]='getImagePath(item)'>
    </div>
    <div class='main-content p-3'>
      <div class='headline'>
        <div class='row'>
          <div *ngIf='!isCmsPreview' class='col-12 linegray pb-2 mb-3'>
            <div class='float-left'>
              <app-like-indicator [bigger]='true' [primaryColor]='true' [liked]='item.isLiked' [likes]='item.likes'
                                  [isLoggedIn]='isLoggedIn' (click)='onLikeClick($event)'></app-like-indicator>
            </div>
            <div class='float-right'>
              <app-bookmark-indicator [bigger]='true' [primaryColor]='true' [bookmarked]='item.bookmarkId' (click)='onBookmarkClick($event, item)'></app-bookmark-indicator>
            </div>
          </div>
          <div *ngIf='isCmsPreview' class='col-12 linegray pb-2 mb-3'>
            <div class='float-left'>
              <a class='like'>
                <i class='fa fa-thumbs-up fa-lg top-icon' aria-hidden='true'></i>
              </a>
            </div>
            <div class='float-right'>
              <app-bookmark-indicator></app-bookmark-indicator>
            </div>
          </div>
          <div class='col-12'>
            <h3>{{item.title }}</h3>
            <h5>{{item.authorName}}</h5>
          </div>
        </div>
        <div class='fr-view longText mt-4' *ngIf='mainContent' [innerHTML]='mainContent'></div>
        <div *ngIf='item.linkUrl' class='mb-2'>
          <a href='#' (click)='openLink(item.linkUrl)'>{{item.linkUrl}}</a>
        </div>
        <div *ngIf='indicators.length' class='row align-items-stretch mt-2 mb-2'>
          <h5 class='pl-3'>
            <b>Apply this to your life today!
              <br> Suggested LifeActions:</b>
          </h5>
          <br>
          <div class='col-12 mb-2'>
          </div>
          <div *ngFor='let indicator of item.indicators; let i = index' class='col-12 col-md-6 mb-3 d-flex align-items-stretch card-white'>
            <app-active-life-action *ngIf=indicator.active (doLifeAction)='onDoLifeAction($event)' [indicator]='indicator'
                                    [index]='i'></app-active-life-action>

            <app-manage-life-action *ngIf='!indicator.active' (changeActivationStatus)='onChangeActivationStatus($event)'
                                    [indicator]='indicator' [index]='i'></app-manage-life-action>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div *ngIf='item.isRecipe'>
    <div>
      <div class='p-3 recipeHeader'>
        <div>
          <h2>{{item.title}}</h2>
          <h5>{{item.authorName}}</h5>

        </div>
        <div>
          <table class='table  text-center'>
            <tr>
              <td>Ingredients</td>
              <td>Servings</td>
              <td>Difficulty</td>
            </tr>
            <tr>
              <th> {{item.ingredients.length}} </th>
              <th> {{item.servings}} </th>
              <th> {{item.difficulty}} </th>
            </tr>
          </table>
        </div>
      </div>
      <div class='img-size-div'>
        <img class='img-size recipeImg' [src]='getImagePath(item)'>
      </div>
      <div class='row m-0 p-3 linegray mb-3'>
        <div *ngIf='!isCmsPreview' class='col-12 p-0'>
          <div class='float-left'>
            <app-like-indicator [bigger]='true' [liked]='item.isLiked' [likes]='item.likes' [isLoggedIn]='isLoggedIn'
                                (click)='onLikeClick($event)'></app-like-indicator>
          </div>
          <div class='float-right'>
            <app-bookmark-indicator [bookmarked]='item.bookmarkId' (click)='onBookmarkClick($event, item)'></app-bookmark-indicator>
          </div>
        </div>
        <div *ngIf='isCmsPreview' class='col-12 p-0'>
          <div class='float-left'>
            <a class='like'>
              <i class='fa fa-thumbs-up fa-lg top-icon' aria-hidden='true'></i>
            </a>
          </div>
          <div class='float-right'>
            <app-bookmark-indicator></app-bookmark-indicator>
          </div>
        </div>
      </div>
      <div class='recipeHeader'>
        <div class='px-3 pt-3' [innerHTML]='item.description'></div>
      </div>
      <div class='container px-3'>
        <div class='row '>
          <div class='col-md-3'>
            <h5>Ingredients</h5>
            <hr />
            <div *ngFor='let ingredient of item.ingredients'>
              <ul>
                <li>{{ingredient.ingredientName}}</li>
              </ul>
            </div>
          </div>
          <div class='col-md-9 pr-0 pl-3'>
            <h5 class='ml-3'>Directions</h5>
            <hr />
            <table class='recipeDirections'>
              <tr *ngFor='let methodItem of item.methods; let index = index; let last = last'>
                <td *ngIf='!last' class='stepNumber' align='center'> {{index + 1}} </td>
                <td *ngIf='!last' l>
                  <p> {{methodItem.description}} </p>
                </td>
                <td *ngIf='last' class='noBorder stepNumber' align='center'> {{index + 1}} </td>
                <td *ngIf='last' class='noBorder'>
                  <p> {{methodItem.description}} </p>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div *ngIf='nutritionItems.length' class='col-12 mt-3'>
          <h5>Nutrition per serving</h5>
          <div class='table-responsive'>
            <table class='table '>
              <tr>
                <th *ngFor='let item of nutritionItems'>
                  {{item.name}}
                </th>
              </tr>
              <tr>
                <td *ngFor='let item of nutritionItems'>
                  {{item.value}}{{item.unit}}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf='item.isVideo'>
    <div class='video-embed videoContainer'>
      <iframe class='full-image' class='video' [src]='item.videoLink'></iframe>
    </div>
    <div class='main-content p-3'>
      <div class='headline mt-4'>
        <div class='row'>
          <div *ngIf='!isCmsPreview' class='col-12 linegray pb-2 mb-3'>
            <div class='float-left'>
              <app-like-indicator [bigger]='true' [liked]='item.isLiked' [likes]='item.likes' [isLoggedIn]='isLoggedIn'
                                  (click)='onLikeClick($event)'></app-like-indicator>
            </div>
            <div class='float-right'>
              <app-bookmark-indicator [bookmarked]='item.bookmarkId' (click)='onBookmarkClick($event, item)'></app-bookmark-indicator>
            </div>
          </div>
          <div *ngIf='isCmsPreview' class='col-12 linegray pb-2 mb-3'>
            <div class='float-left'>
              <a class='like'>
                <i class='fa fa-thumbs-up fa-lg top-icon' aria-hidden='true'></i>
              </a>
            </div>
            <div class='float-right'>
              <app-bookmark-indicator></app-bookmark-indicator>
            </div>
          </div>
          <div class='col-12'>
            <h2>{{item.title}}</h2>
          </div>
        </div>
      </div>
    </div>
    <p class='p-3' *ngIf='item.longText' [innerHTML]='item.longText'></p>
    <p class='p-3' *ngIf='!item.longText' [innerHTML]='item.shortText'></p>

  </div>
  <div class='px-3 flex' *ngIf='hasAuthor(item)'>
    <img *ngIf='item.authorImage' class='round-image' [src]='getAuthorImage(item.authorImage)'>
    <div>
      <h2 class='authorName'><b>{{item.authorName | uppercase }}</b></h2>
      <small class='text-wrap'>{{item.authorBio}}</small>
    </div>
  </div>

  <div align='right' class='px-3' *ngIf='canShareToGroup'>
    <button class='btn btn-primary' (click)='shareToGroup()'>Share to group <i class='fa fa-share'></i></button>

  </div>
  <div class='p-3' *ngIf='isLoggedIn && !isCmsPreview'>
    <div class='border'></div>
    <div class='w-100 mt-2 mb-0'>
      <h5 class='mb-0'>Tell us what you thought</h5>
      <form (submit)='submit()'>
        <app-star-rating [(ratingvalue)]='rating' [(touched)]='touched' max='5' color='gold' fillcolor='gold'>
        </app-star-rating>
        <p [hidden]='rating >= 1 || !comment'>Please give a star rating</p>
        <div class='form-group'>
        <textarea type='text' class='form-control' name='comment' placeholder='Feedback...' [(ngModel)]='comment'>
        </textarea>
        </div>
      </form>
      <div align='right'>
        <button [disabled]='isCmsPreview || !rating' class='btn btn-primary' (click)='submit()'>Submit</button>
      </div>
<!--      <form [formGroup]='itemForm'>-->
<!--        <div class='row mt-3'>-->
<!--          &lt;!&ndash; <div class="col-2 w-75 text-center">-->
<!--            <img class="w-75 rounded-circle" src="{{userImage}}" alt="Card image cap" />-->
<!--          </div> &ndash;&gt;-->
<!--          <div class='col-12 w-100'>-->
<!--            <div class='form-group'>-->
<!--              <textarea type='text' class='form-control' [ngClass]="displayFieldCss('commentControl')"-->
<!--                        formControlName='commentControl' maxlength='250' placeholder='Say something....'></textarea>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class='row'>-->
<!--          <div class='col-12'>-->
<!--            <button type='button' class='btn btn-primary w-10 pull-right' (click)='onSaveClick()'-->
<!--                    [disabled]='!itemForm.valid'>-->
<!--              <i class='fa fa-commenting mr-2' aria-hidden='true'></i> Post-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </form>-->
    </div>
<!--    <p><b>Comment Details</b></p>-->
<!--    &lt;!&ndash; POSTS &ndash;&gt;-->
<!--    <div *ngFor='let post of commentItems; let postIndex = index' class='card w-40 mt-4 p-2'>-->
<!--      <div class='postRow'>-->
<!--        <div class='avatarDivPost px-2 pt-2'>-->
<!--          <div class='avatarColPost pointer' (click)='onViewProfile(post.userProfileId)'-->
<!--               [ngClass]="getPostLevelClass(post.level, 'normal-shadow')">-->
<!--            <img class='img-avatar' [src]='post.photo' onError="this.src='assets/img/profile.png'">-->
<!--          </div>-->
<!--        </div>-->

<!--        &lt;!&ndash; <app-text-area *ngIf="isEditingPost(post.id); else readOnlyPost" [type]="'content'" [placeholder]="postPlaceholder"-->
<!--          [path]="postImagePath" [description]="post.description" [inputImage]="post.photo"-->
<!--          [actionButtonText]="actionButtonTextOnEdit" [inputPreview]="post.preview" (data)="editPost($event)"-->
<!--          (editCancelled)="cancel('post')" [fromEdit]="true" class="post-input p-2"></app-text-area> &ndash;&gt;-->
<!--        <app-text-area *ngIf='isEditingPost(post.id); else readOnlyPost' [type]="'content'" [placeholder]='postPlaceholder'-->
<!--                       [fromShare]='true' [description]='post.description' [actionButtonText]='actionButtonTextOnEdit' (data)='editPost($event)'-->
<!--                       (editCancelled)="cancel('post')" [fromEdit]='true' class='post-input p-2'></app-text-area>-->
<!--        <ng-template #readOnlyPost>-->
<!--          <div class='flex-space-between'>-->
<!--            <div class='px-2 pt-2'>-->
<!--              <p>-->
<!--                <b class='pointer' (click)='onViewProfile(post.userProfileId)'>-->
<!--                  {{post.username}}-->
<!--                </b>-->
<!--                <label class='time'>-->
<!--                  {{post.updatedAt | timeFromNow}}-->
<!--                </label>-->
<!--              </p>-->
<!--            </div>-->

<!--            <div class='mr-1'>-->
<!--              <app-flagging [contentId]='item.id' [postId]='post.id' [companyId]='userCompanyId' [itemType]="'Post'"-->
<!--                            [canUserEditItem]='post.isEditable' [canUserDeleteItem]='post.isDeletable' [itemId]='post.id'-->
<!--                            [userProfileId]='post.userProfileId' (deleteItem)='callDeletePost($event)'-->
<!--                            (editItem)='callEditPost($event)' [contentType_]='contentType'></app-flagging>-->
<!--            </div>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--      </div>-->

<!--      <div *ngIf='!isEditingPost(post.id)'>-->
<!--        <p class='description pl-1 text-justify' style='white-space: pre-wrap;' [innerHtml]='post.description'></p>-->
<!--        &lt;!&ndash; <img *ngIf="isActiveImage(post)" class="w-100 mb-3" [src]="post.photo">-->
<!--    <div *ngIf="isActivePreview(post)" (click)="openUrl(post.preview.url)">-->
<!--      <a href="{{post.preview.url}}">-->
<!--        <img class="w-100 mb-3 hyperlinkImage" [src]="post.preview.image">-->

<!--        <h4 class="hyperlink pl-1">{{post.preview.title}}</h4>-->
<!--      </a>-->
<!--      <p class="pl-1">{{post.preview.description}}</p>-->
<!--    </div> &ndash;&gt;-->
<!--      </div>-->

<!--      <div *ngIf='!isEditingPost(post.id)' class='flex-space-between px-1 pb-2'>-->
<!--        <app-like-indicator [bigger]='false' [liked]='post.liked == 1' [likes]='post.likes' [isLoggedIn]='isLoggedIn'-->
<!--                            [primaryColor]='true' (click)="onLikeClicked($event, post, 'contentComment')"></app-like-indicator>-->
<!--      </div>-->
<!--      <div class='bottomLinePost'></div>-->

<!--      &lt;!&ndash; comments &ndash;&gt;-->

<!--      <div *ngFor='let comment of post.displayComments; let i = index' class='mt-2'>-->
<!--        <div *ngIf='isDisplayNumberOfCommentsLabel(post) && i === 0' class='float-right flex'>-->
<!--          <p class='no-wrap mr-2'>{{numberOfCommentsLabel(post)}}</p>-->
<!--          <a *ngIf='isDisplayMoreComments(post)' (click)='displayMorePostComments(post)'>-->
<!--            <p class='no-wrap'>View more</p>-->
<!--          </a>-->

<!--          <a *ngIf='isDisplayLessComments(post)' (click)='displayLessComments(post)'>-->
<!--            <p class='no-wrap'>Show Less</p>-->
<!--          </a>-->

<!--        </div>-->
<!--        <div class='postRow'>-->
<!--          <div class='avatarDivPost px-2 pt-2'>-->
<!--            <div class='avatarColPost pointer' (click)='onViewProfile(comment.userProfileId)'-->
<!--                 [ngClass]="comment.level ? comment.level :'normal-shadow'">-->
<!--              <img [src]='comment.photo' class='img-avatar' onError="this.src='assets/img/profile.png'">-->
<!--            </div>-->
<!--          </div>-->

<!--          <app-text-area *ngIf='isEditingComment(comment.id); else readOnlyComment' [type]="'comment'"-->
<!--                         [actionButtonText]='actionButtonTextOnEdit' [placeholder]='commentPlaceholder' [path]='postImagePath'-->
<!--                         [description]='comment.description' [inputImage]='comment.image' [fromEdit]='true'-->
<!--                         [inputPreview]='comment.preview' (data)='editComment($event, post)' (editCancelled)="cancel('comment')"-->
<!--                         class='post-input p-2'></app-text-area>-->

<!--          <ng-template #readOnlyComment>-->
<!--            <div class='flex-space-between'>-->
<!--              <div class='px-2 pt-2'>-->
<!--                <p>-->
<!--                  <b class='pointer' (click)='onViewProfile(comment.userProfileId)'>-->
<!--                    {{comment.username}}-->
<!--                  </b>-->
<!--                  <label class='time'>-->
<!--                    {{comment.updatedAt | timeFromNow}}-->
<!--                  </label>-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class='mr-1 mb-2 column-bottom'>-->
<!--              <div class='flex'>-->
<!--                <div *ngIf='!isEditingComment(comment.id)' class='mr-3 pt-1 no-wrap'>-->
<!--                  <app-like-indicator [bigger]='false' [liked]='comment.liked == 1' [likes]='comment.likes'-->
<!--                                      [isLoggedIn]='isLoggedIn' [primaryColor]='true' (click)="onLikeClicked($event, comment, 'contentComment')">-->
<!--                  </app-like-indicator>-->
<!--                </div>-->
<!--                <app-flagging [contentId]='item.id' [commentId]='comment.id' [companyId]='userCompanyId' [itemType]="'Comment'"-->
<!--                              [canUserEditItem]='comment.isEditable' [canUserDeleteItem]='comment.isDeletable' [itemId]='comment.id'-->
<!--                              [userProfileId]='comment.userProfileId' [itemIndex]='postIndex'-->
<!--                              (deleteItem)='callDeleteComment($event)' (editItem)='callEditComment($event)' [contentType_]='contentType'></app-flagging>-->
<!--              </div>-->
<!--            </div>-->
<!--          </ng-template>-->
<!--        </div>-->
<!--        <div *ngIf='!isEditingComment(comment.id)'>-->
<!--          <p class='description comment-color pl-1 text-justify' style='white-space: pre-wrap;' [innerHtml]='comment.description'></p>-->
<!--          &lt;!&ndash; <img *ngIf="isActiveImage(comment)" class="w-100 mb-3" [src]="comment.imageSource">-->
<!--          <div *ngIf="isActivePreview(comment)" (click)="openUrl(comment.preview.url)">-->
<!--            <a href="{{comment.preview.url}}">-->
<!--              <img class="w-100 mb-3 hyperlinkImage" [src]="comment.preview.image">-->
<!--              <h4 class="hyperlink pl-1">{{comment.preview.title}}</h4>-->
<!--            </a>-->
<!--            <p class="pl-1">{{comment.preview.description}}</p>-->
<!--          </div> &ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->

<!--      &lt;!&ndash; NEW Comment &ndash;&gt;-->
<!--      <div class='postRow mt-2'>-->
<!--        <div class='avatarDivPost px-2 pt-2'>-->
<!--          <div class='avatarColPost' [ngClass]="getUserProfileLevelClass('normal-shadow')">-->
<!--            <img class='img-avatar' [src]='userImage' onError="this.src='assets/img/profile.png'">-->
<!--          </div>-->
<!--        </div>-->
<!--        <app-text-area [type]="'comment'" [placeholder]='commentPlaceholder' [actionButtonText]='actionButtonText'-->
<!--                       [path]='postImagePath' (data)='addComment($event, post, postIndex)' class='post-input p-2'></app-text-area>-->

<!--        <div *ngIf='isEmptyCommentError' class='text-danger'>-->
<!--          Please enter a valid comment-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!-- <button *ngIf="showInfo!==1" [hidden]="isNoMorePosts" (click)="viewMore()" class="btn btn-primary center-button mx-auto mt-4 mb-4">
     View more
   </button> -->
<!--    <div *ngIf='commentItems && commentItems.length === 0' class='col-12'>-->
<!--      <div class='w-40 card p-2 text-center'>-->
<!--        <p>-->
<!--          There are currently no comments in this content. You can start a conversation!-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
