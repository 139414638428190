<div class="p-3 ml-3 mr-3 content-background">
  <h1>Terms and Conditions</h1>
  <p>Last updated: 2nd September 2022</p>
  <p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the
    <a href="">{{url}}</a> website (the "Service") operated by Altius Group ("us", "we", or "our").</p>
  <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms
    apply to all visitors, users and others who access or use the Service. You warrant that you are at least 16-years-old
    and you are legally capable of entering into binding contracts.</p>
  <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then
    you may not access the Service.</p>
  <h4>Availability, Errors and Inaccuracies</h4>
  <p>We are constantly updating our offerings of products and services on the Service. The products or services available on
    our Service may be mispriced, described inaccurately, or unavailable, and we may experience delays in updating information
    on the Service and in our advertising on other web sites.</p>
  <p>We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications,
    availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies,
    or omissions at any time without prior notice.</p>
  <p>The content is provided without warranties or conditions of any kind either express or implied, including but not limited
    to implied warranties and conditions of merchantability, fitness for a particular purpose or non-infringement.</p>
  <h4>Contests, Sweepstakes and Promotions</h4>
  <p>Any contests, sweepstakes or other promotions (collectively, "Promotions") made available through the Service may be governed
    by rules that are separate from these Terms. If you participate in any Promotions, please review the applicable rules
    as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.</p>
  <h4>Content</h4>
  <p>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos,
    or other material ("Content"). You are responsible for the Content that you post to the Service, including its legality,
    reliability, and appropriateness.</p>
  <p>By posting Content to the Service, you grant us the right and license to use, modify, publicly perform, publicly display,
    reproduce, and distribute such Content on and through the Service. You retain any and all of your rights to any Content
    you submit, post or display on or through the Service and you are responsible for protecting those rights. You agree
    that this license includes the right for us to make your Content available to other users of the Service, who may also
    use your Content subject to these Terms.</p>
  <p>You represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and grant us the
    rights and license as provided in these Terms, and (ii) the posting of your Content on or through the Service does not
    violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person. Further,
    you warrant that: (i) the Content will not cause you or us to breach any law, regulation, rule, code or other legal obligation;
    (ii) the Content will not or could not be reasonably considered to be obscene, inappropriate, defamatory, disparaging,
    indecent, seditious, offensive, pornographic, threatening, abusive, liable to incite racial hatred, discriminatory, blasphemous,
    in breach of confidence or in breach of privacy; (iii) the Content will not be unsolicited, undisclosed or unauthorised
    advertising; (iv) the Content does not contain software viruses or any other computer code, files, or programs designed
    to interrupt, destroy, or limit the functionality of any computer software, hardware or telecommunications equipment;
    and (v): the Content does not bring us or the Service into disrepute.</p>
  <p>You agree to keep all records necessary to establish that your Content does not violate any of the requirements this clause
    and make such records available upon our reasonable request.</p>
  <p>We are under no obligation to regularly monitor the accuracy or reliability of your Content incorporated into the Service.
    We reserve the right to modify or remove any Content at any time.</p>
  <p>You acknowledge and agree that all Content you provide on the Service will be publicly available information and you bear
    the risks involved with such public disclosures.</p>
  <h4>Accounts</h4>
  <p>When you create an account with us, you must provide us information that is accurate, complete, and current at all times.
    Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our
    Service.
  </p>
  <p>You are responsible for safeguarding the password that you use to access the Service and for any activities or actions
    under your password, whether your password is with our Service or a third-party service.</p>
  <p>You agree not to disclose your password to any third party. You agree to be fully responsible for activities that relate
    to your account or your password. You must notify us immediately upon becoming aware of any breach of security or unauthorized
    use of your account.</p>
  <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or
    trade mark that is subject to any rights of another person or entity other than you without appropriate authorization,
    or a name that is otherwise offensive, vulgar or obscene.</p>
  <h4>Intellectual Property</h4>
  <p>The Service and its original content (excluding Content provided by users), features and functionality are and will remain
    the exclusive property of Altius Group and its licensors. The Service is protected by copyright, trademark, and other laws
    of both the Australia and foreign countries. Our trademarks and trade dress may not be used in connection with any product
    or service without the prior written consent of Altius Group. Nothing in these Terms constitutes a transfer of any Intellectual
    Property rights from us to you.</p>
  <p>You are permitted to use the Service only as authorised by us. As a user, you are granted a limited, non-exclusive, revocable,
    non-transferable right to use the Service to create, display, use, play, and download Content subject to these Terms.</p>
  <p>Our Intellectual Property must not be used in connection with a product or service that is not affiliated with us or in
    any way brings us in disrepute.</p>
  <p>You must not modify the physical or digital copies of any Content you print off or download in any way, and you must not
    use any illustrations, photographs, video or audio, or any graphics separately from any accompanying text.</p>
  <p>Any opinions, advice, statements, services, offers, or other information or content expressed or made available by any
    other users are those of the respective authors or distributors and not of us.</p>
  <h4>Links To Other Web Sites</h4>
  <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by Altius Group.</p>
  <p>Altius Group has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third
    party web sites or services. You further acknowledge and agree that Altius Group shall not be responsible or liable, directly
    or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any
    such content, goods or services available on or through any such web sites or services.</p>
  <p>We only provide links to external websites as a convenience, and the inclusion of such a link to external websites do not
    imply our endorsement of those websites. You acknowledge and agree that when you access other websites on the Internet,
    you do so at your own risk.</p>
  <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that
    you visit.</p>
  <h4>Termination</h4>
  <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including
    without limitation if you breach the Terms.</p>
  <p>Upon termination, your right to use the Service will immediately cease. If you wish to close your account, you may simply
    close your account by clicking ‘Close your account’ and follow the prompts. Your account will be closed, however due to
    record keeping laws your details will be maintained within the database. If you wish to reactivate your account, you will
    need to contact AltiusLife support team.</p>
  <p>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without
    limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
  <p>We shall not be liable to you or any third party for any claims or damages arising out of any termination or suspension
    or any other actions taken by us in connection therewith.</p>
  <p>If applicable law requires us to provide notice of termination or cancellation, we may give prior or subsequent notice
    by posting it on the Service or by sending a communication to any address (email or otherwise) that we have for you in
    our records.</p>
  <h4>Indemnification</h4>
  <p>As a condition of your access to and use of the Service, you agree to indemnify us and our successors and assigns for all
    damages, costs, expenses and other liabilities, including but not limited to legal fees and expenses, relating to any
    claim arising out of or related to your access to and use of the Service or your breach of these Terms and any applicable
    law or the rights of another person or party.</p>
  <p>This indemnification section survives the expiration of your registration, and applies to claims arising both before and
    after the registration ends.</p>
  <h4>Limitation Of Liability</h4>
  <p>You agree that we shall not be liable for any damages suffered as a result of using the Service, copying, distributing,
    or downloading Content from the Service.</p>
  <p>In no event shall we be liable for any indirect, punitive, special, incidental or consequential damage (including loss
    of business, revenue, profits, use, privacy, data, goodwill or other economic advantage) however it arises, whether for
    breach of contract or in tort, even if it has been previously advised of the possibility of such damage.</p>
  <p>In no event shall we be liable for any claims by a third party in tort or contract, or pursuant to the Competition and
    Consumer Act 2010 (Cth) including, but not limited, to any misleading statements made and/or incorporated into any Content
    provided by third parties, such as manufacturers and wholesalers, and users of the Service. It is your sole responsibility
    to ensure the accuracy of the data inputted.</p>
  <p>You have sole responsibility for adequate security protection and backup of data and/or equipment used in connection with
    your usage of the Service and will not make a claim against for lost data, re-run time, inaccurate instruction, work
    delays or lost profits resulting from the use of the Service. You must not assign or otherwise dispose of your account
    to any other person.</p>
  <p>Without limiting the foregoing, in no event will our aggregate liability to you exceed, in total, the amounts paid by you
    to us.</p>
  <h4>Disclaimer</h4>
  <p>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service
    is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties
    of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>
  <p>Altius Group and its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted,
    secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is
    free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</p>
  <p>This disclaimer of liability applies to any damages or injury caused by any failure of performance, error, omission, interruption,
    deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft, or destruction
    or unauthorized access or, alteration of or use of record in connection with the use or operation of the Service, whether
    for breach of contract, tortious behaviour, negligence or any other cause of action.</p>
  <p>We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability,
    suitability or availability with respect to the content contained on the Service for any purpose. Any reliance you place
    on such information is therefore strictly at your own risk. We disclaim any express or implied warranty representation
    or guarantee as to the effectiveness or profitability of the Service or that the operation of our Service will be uninterrupted
    or error-free. We are not liable for the consequences of any interruptions or error in the Service.</p>
  <h4>Exclusions</h4>
  <p>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential
    or incidental damages, so the limitations above may not apply to you.</p>
  <h4>Governing Law</h4>
  <p>These Terms shall be governed and construed in accordance with the laws of New South Wales, Australia, without regard to
    its conflict of law provisions.</p>
  <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision
    of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain
    in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any
    prior agreements we might have between us regarding the Service.</p>
  <h4>Changes</h4>
  <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we
    will try to provide at least 30 days notice prior to any new terms taking effect.</p>
  <p>It is your sole responsibility to periodically check these Terms for any changes. If you do not agree with any of the changes
    to these Terms, it is your sole responsibility to stop using the Service. Your continued use of the Service will be deemed
    as your acceptance thereof.</p>
  <h4>Contact Us</h4>
  <p>If you have any questions about these Terms, please contact us.</p>
  <p>The AltiusLife platform also adheres to the  <a href="https://altius-group.com.au/privacy-policy" target="_blank"> Altius Group Privacy and Records Management Policy and Procedures.</a></p>
</div>
