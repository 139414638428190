import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BookmarkService } from '../../../../services/bookmark-service';
import { UtilService } from '../../../../services/util-service';
import { UserService } from '../../../../services/user-service';
import { ContentService } from '../../../../services/content-service';
import { IContentDetail } from '../../../_models/content-detail';

@Component({
  selector: 'app-content-list-card',
  templateUrl: './content-list-card.component.html',
  styleUrls: ['./content-list-card.component.scss'],
})
export class ContentListCardComponent implements OnInit {
  @Input() item: IContentDetail;
  @Input() isLoggedIn;
  @Input() isProgram;
  @Input() isLocked;

  @Output() bookmarkRemove: EventEmitter<any> = new EventEmitter<any>();

  itemImageSrc: string;

  cardClass = 'content-card card-outline mr-0 ml-0 mt-4';

  constructor(
    public userService: UserService,
    public utilService: UtilService,
    public bookmarkService: BookmarkService,
    private contentService: ContentService,
  ) {
  }

  ngOnInit() {
    this.contentService.initialiseContentItemPerContentType(this.item);
    this.itemImageSrc = this.userService.getContentItemImage(this.item);

    if (this.isProgram) {
      if (this.item.locked) {
        this.cardClass = 'content-card-3-locked overlay card-outline mr-0 ml-0 mb-4';
      } else {
        this.cardClass = 'content-card-3 card-outline mr-0 ml-0 mb-4';
      }
    }
  }

  getItemText(item: IContentDetail): string {
    if (item.isRecipe) {
      return item.shortDescription;
    }

    return item.shortText;
  }

  onBookmarkClick(event, item: IContentDetail) {
    event.preventDefault();
    event.stopPropagation();

    if (item.isBookmarked) {
      this.deleteBookmark(item.bookmarkId);
    } else {
      this.addBookmark(item);
    }
  }

  private addBookmark(item: IContentDetail) {
    this.bookmarkService.addBookmark(item.id, item.contentType)
      .subscribe(data => {
          const body = {
            contentId: item.id,
            contentType: item.contentType,
            newBookmarkId: data.id,
          };
          this.item.bookmarkId = data.id;
          this.item.isBookmarked = true;
        },
        (err) => {
          console.log(err);
        },
      );
  }

  private deleteBookmark(bookmarkId: number) {
    this.bookmarkService.deleteBookmark(bookmarkId)
      .subscribe(data => {
          this.item.bookmarkId = null;
          this.item.isBookmarked = false;
          this.bookmarkRemove.emit({ bookmarkId: bookmarkId, contentId: data.contentId, contentType: data.type });
        },
        (err) => {
        },
      );
  }

  onLikeClick(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.item.isLiked) {
      this.contentService.unlikeItem(this.item);
    } else {
      this.contentService
        .likeItem(this.item, this.userService.profile.id);
    }
  }
}
