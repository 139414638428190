<app-date-selector
  [inputStartDate]="startDate"
  [inputEndDate]="endDate"
  [inputCompanyId]="companyId"
  [canSelectCompany]="canSelectCompany"
  (dateQuery)="onDateRangeSelected($event)">
</app-date-selector>

<div class="card-columns">
  <div *ngIf='canShowDailySiteVisits'
    class="card w-100 text-center">
    <div class="card-header">
      <h5>Daily Site Visits</h5>
    </div>
    <div class="card-body">
      <div *ngIf="totalUsersDataLoading">
        <i class="fa fa-spinner fa-spin fa-lg" aria-hidden="true"></i>
      </div>
      <div *ngIf="!totalUsersDataLoading && !totalUsersDataAvailable">
        <p>No data available</p>
      </div>
      <div *ngIf="totalUsersDataAvailable">
        <app-line-graph [data]="totalUsersData" [labels]="betterDates" [autoSkip]="true" [color]="color[2]" [legend]="true" [legendLabel]="'users'"></app-line-graph>
      </div>
    </div>
  </div>

  <div *ngIf="canShowTopArticles"
    class="card w-100 text-center">
    <div class="card-header">
      <h5>Top Articles</h5>
    </div>
    <div *ngIf="topTenArticlesDataLoading">
      <i class="fa fa-spinner fa-spin fa-lg" aria-hidden="true"></i>
    </div>
    <div *ngIf="!topTenArticlesDataLoading && !topTenArticlesDataAvailable">
      <p>No data available</p>
    </div>
    <div *ngIf="topTenArticlesDataAvailable">
      <table class="table table-borderless table-hover">
        <thead class="thead-light">
          <tr>
            <th class="table-text" scope="col">#</th>
            <th class="table-text" scope="col">Article</th>
            <th class="table-count" scope="col">Count</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let article of topTenArticles; let i = index">
            <td class="table-text">{{i + 1}}</td>
            <td class="table-text" (click)="goToArticle(article.route)">{{article.title}}</td>
            <td class="table-count">{{article.count}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="canShowTopPages"
    class="card w-100 text-center">
    <div class="card-header">
      <h5>Top Pages</h5>
    </div>
    <div *ngIf="popularPagesDataLoading">
      <i class="fa fa-spinner fa-spin fa-lg" aria-hidden="true"></i>
    </div>
    <div *ngIf="!popularPagesDataLoading && !popularPagesDataAvailable">
      <p>No data available</p>
    </div>
    <div *ngIf="popularPagesDataAvailable">
      <table class="table table-borderless table-hover">
        <thead class="thead-light">
          <tr>
            <th class="table-text" scope="col">#</th>
            <th class="table-text" scope="col">Page</th>
            <th class="table-count" scope="col">Count</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let page of popularPagesData; let i = index">
            <td class="table-text">{{i + 1}}</td>
            <td class="table-text">{{page[0]}}</td>
            <td class="table-count">{{page[1]}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="canShowDeviceSummary"
    class="card w-100 text-center card-devices">
    <div class="card-header">
      <h5>Device Summary</h5>
    </div>
    <div class="card-body">
      <div *ngIf="deviceSessionsDataLoading">
        <i class="fa fa-spinner fa-spin fa-lg" aria-hidden="true"></i>
      </div>
      <div *ngIf="!deviceSessionsDataLoading && !deviceSessionsDataAvailable">
        <p>No data available</p>
      </div>
      <div *ngIf="deviceSessionsDataAvailable">
        <app-doughnut-graph [data]="deviceSessionsData" [labels]="deviceSessionsLabels" [color]="color" [legend]="true" [type]="'pie'"></app-doughnut-graph>
      </div>
    </div>
  </div>

  <div *ngIf="canShowPageViewsAndTimes"
    class="card w-100 text-center card-carousel">
    <div class="card-header">
      <h5>Page Views & Times</h5>
    </div>
    <div *ngIf="pageViewsAndTimeDataLoading">
      <i class="fa fa-spinner fa-spin fa-lg" aria-hidden="true"></i>
    </div>
    <div *ngIf="!pageViewsAndTimeDataLoading && !pageViewsAndTimeDataAvailable">
      <p>No data available</p>
    </div>
    <div *ngIf="pageViewsAndTimeDataAvailable">
      <div id="pageDataCarousel" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <h4>{{totalPageViewsData}}</h4>
            <h5>Total Page Views</h5>
          </div>
          <div class="carousel-item">
            <h4>{{uniquePageViewsData}}</h4>
            <h5>Unique Page Views</h5>
          </div>
          <div class="carousel-item">
            <h4>{{averageTimeData}}</h4>
            <h5>Average Time On Site</h5>
          </div>
        </div>
        <a class="carousel-control-prev" href="#pageDataCarousel" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#pageDataCarousel" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
      </div>
    </div>
  </div>
</div>
