import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TMHService } from '../../../services/track-my-health-service';
import { AbstractControl, FormGroup, FormBuilder, FormControl, ValidatorFn, Validators } from '@angular/forms';

const RangeValidator: ValidatorFn = (fg: FormGroup) => {
  const isBetweenOperand = fg.get('isBetweenOperandControl').value;
  const lowerValue = fg.get('lowerValueControl').value;
  const upperValue = fg.get('upperValueControl').value;

  return !isBetweenOperand || (lowerValue !== null && upperValue !== null
            && lowerValue <= upperValue)
    ? null
    : { range: true };
};

const MinutesValidator: ValidatorFn = (control: AbstractControl) => {
  if (control.value >= 0 && control.value < 60) { return null; }

  return {minutes: true };
};

@Component({
  selector: 'app-track-my-health-set-goal',
  templateUrl: './track-my-health-set-goal.component.html',
  styleUrls: ['./track-my-health-set-goal.component.scss']
})
export class TrackMyHealthSetGoalComponent implements OnInit {
  @Input() goalTitle: string;
  @Input() goalDisplay: string;
  @Input() isSleepIndicator: boolean;
  @Input() unit: string;
  @Input() operand: string;
  @Input() lowerValue: number;
  @Input() upperValue: number;

  operands: string[];
  goalForm: FormGroup;
  newValueTitle: string;

  constructor(
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    public tmhService: TMHService) { }

  ngOnInit() {
    this.newValueTitle = 'New Value:';
    this.operands = this.tmhService.getAllOperands();

    this.goalForm = this.fb.group({
      operandControl: [this.operand, Validators.required],
      isBetweenOperandControl: this.isBetweenOperand(this.operand),
      lowerValueControl: [this.lowerValue, Validators.required],
      upperValueControl: [this.upperValue, Validators.required]
    }, {validator: RangeValidator});

    if (this.isSleepIndicator) {
      this.appendSleepIndicatorControls();
    }
  }

  private appendSleepIndicatorControls() {
    this.newValueTitle += ' hh:mm';

    const lowerHrsMins = this.tmhService.getHoursMinutesFromMinutes(this.lowerValue);
    this.goalForm.addControl('lowerValueHoursControl',
        new FormControl(lowerHrsMins[0], Validators.min(0)));
    this.goalForm.addControl('lowerValueMinutesControl',
        new FormControl(lowerHrsMins[1], [MinutesValidator]));

    const upperHrsMins = this.tmhService.getHoursMinutesFromMinutes(this.upperValue);
    this.goalForm.addControl('upperValueHoursControl',
          new FormControl(upperHrsMins[0], Validators.min(0)));
    this.goalForm.addControl('upperValueMinutesControl',
          new FormControl(upperHrsMins[1], [MinutesValidator]));
  }

  isBetweenOperand(operand): boolean {
    return this.tmhService.isBetweenGoalOperand(operand);
  }

  onOperandClick() {
    const isBetweenOperand =  this.isBetweenOperand(this.goalForm.controls.operandControl.value);
    this.goalForm.patchValue({isBetweenOperandControl: isBetweenOperand});

    if (!isBetweenOperand) {
      this.setUpperValueToLowerValue();
    }
  }

  private setUpperValueToLowerValue() {
    if (this.isSleepIndicator) {
      this.goalForm.patchValue({
        upperValueHoursControl: this.goalForm.controls.lowerValueHoursControl.value,
        upperValueMinutesControl: this.goalForm.controls.lowerValueMinutesControl.value});
    }

    this.goalForm.patchValue({upperValueControl: this.goalForm.controls.lowerValueControl.value});
    this.goalForm.updateValueAndValidity();
  }

  onLowerValueHoursControlKeyUp() {
    const minutes = this.calculateMinutes(this.goalForm.controls.lowerValueHoursControl.value,
      this.goalForm.controls.lowerValueMinutesControl.value);
      this.goalForm.patchValue({lowerValueControl: minutes});
      this.goalForm.updateValueAndValidity();
  }

  onLowerValueMinutesControlKeyUp() {
    if (!this.goalForm.controls.lowerValueMinutesControl.valid) {
      this.goalForm.patchValue({lowerValueMinutesControl: 0});
    }

    const minutes = this.calculateMinutes(this.goalForm.controls.lowerValueHoursControl.value,
    this.goalForm.controls.lowerValueMinutesControl.value);
    this.goalForm.patchValue({lowerValueControl: minutes});
    this.goalForm.updateValueAndValidity();
  }

  onUpperValueHoursControlKeyUp() {
    const minutes = this.calculateMinutes(this.goalForm.controls.upperValueHoursControl.value,
    this.goalForm.controls.upperValueMinutesControl.value);
    this.goalForm.patchValue({upperValueControl: minutes});
    this.goalForm.updateValueAndValidity();
  }

  onUpperValueMinutesControlKeyUp() {
    if (!this.goalForm.controls.upperValueMinutesControl.valid) {
      this.goalForm.patchValue({upperValueMinutesControl: 0});
    }

    const minutes = this.calculateMinutes(this.goalForm.controls.upperValueHoursControl.value,
    this.goalForm.controls.upperValueMinutesControl.value);
    this.goalForm.patchValue({upperValueControl: minutes});
    this.goalForm.updateValueAndValidity();
  }

  private calculateMinutes(hours: number, minutes: number) {
    return hours * 60 + minutes;
  }

  save() {
    if (!this.goalForm.valid) {
      return;
    }

    this.operand = this.goalForm.controls.operandControl.value;

    if (this.isBetweenOperand(this.operand)) {
      this.lowerValue = this.goalForm.controls.lowerValueControl.value;
      this.upperValue = this.goalForm.controls.upperValueControl.value;
    } else {
      this.lowerValue = this.goalForm.controls.lowerValueControl.value;
      this.upperValue = this.goalForm.controls.lowerValueControl.value;
    }

    this.activeModal.close(this);
  }

  close() {
    this.activeModal.dismiss();
  }
}
