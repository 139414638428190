import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataSourceService {
  constructor(private http: HttpClient) {}

  changeDataSource(source, id) {
    const url = `/datasources/${id}`;
    const body = {
      type: source
    };

    return this.http.put<any>(url, body);
  }

  changeDataSourceToSelf(id) {
    return this.changeDataSource('self', id);
  }

  changeAllDataSourcesToSelf() {
    return this.getDataSources().pipe(
      tap(dataSources => {
        dataSources.map(dataSource =>
          this.changeDataSourceToSelf(dataSource.id)
        );
      })
    );
  }

  getDataSources() {
    const url = '/datasources/sources/all';
    return this.http.get<any>(url);
  }

  getFitbitDataSource() {
    return this.getDataSources().pipe(
      map(sources => sources.find(source => source === 'fitbit'))
    );
  }
}
