import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { IPageResponse, PageService } from '../../../services/page-service';
import { ArticleService } from '../../../services/article-service';
import { CompanyService } from '../../../services/company-service';
import { UtilService } from '../../../services/util-service';
import { UserService } from '../../../services/user-service';
import { DateService } from '../../../services/date.service';
import { FilestackService } from '../../../services/filestack-service';
import { IPage } from '../../_models/page';
import { ILanguageData } from '../../_models/language-data';
import { ILanguage } from '../../_models/language';
import { LANGUAGES } from '../../_shared/enums';
import { IIdNamePair } from '../../_models/id-name-pair';
import { ICompany } from '../../_models/company';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { EAPService } from '../../../services/eap-service';

@Component({
  selector: 'eap-management',
  templateUrl: './eap-management.component.html',
  styleUrls: ['./eap-management.component.scss'],
})
export class EAPManagementComponent implements OnInit {
  item: IPage;
  itemType = 'EAP';
  pageType = 'eap';

  lastCode: string = LANGUAGES.en;
  languageData: ILanguageData[] = [];
  availableLanguages: ILanguage[] = [];
  title: string;// used for en version
  longText: string;// used for en version
  image: string;// used for en version
  errorMsg: string = '';// show language errors
  isDisplayingList: boolean;
  isEditingItem: boolean;
  isAddingItem: boolean;
  allCompanies: IIdNamePair[] = [];
  companies: ICompany[];
  companyId: number;
  items: IPage[];
  columns = [];
  pageId: number;

  itemForm: FormGroup;
  options: Object = {
    events: {
      'froalaEditor.link.beforeInsert': function(e, editor, link, text, attrs) {
        if (link.indexOf('://') < 0) {
          attrs['data-contenttype'] = link.split('/').slice(-2)[0];
          attrs['data-internallink'] = true;
          attrs['data-contentid'] = link.split('/').slice(-1)[0];
        }
      },
    },
    placeholderText: 'Edit Content Here',
    paragraphFormat: {
      N: 'Normal',
      H1: 'Heading 1',
      H2: 'Heading 2',
      H3: 'Heading 3',
      H4: 'Heading 4',
      H5: 'Heading 5',
      H6: 'Heading 6',
    },
    toolbarSticky: false,
    key: this.userService.getFroalaApiKey(),
    toolbarButtons: ['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|',
      'fontFamily', 'fontSize', 'color', 'inlineClass', 'inlineStyle', 'paragraphStyle', 'lineHeight', '|',
      'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '|',
      'insertLink', 'insertImage', 'insertFile', 'insertTable', '|',
      'emoticons', 'specialCharacters', 'insertHR', 'selectAll', 'clearFormatting', '|',
      'print', 'getPDF', 'help', 'html', '|',
      'undo', 'redo'],
    pastePlain: true,
  };

  isInitialised: boolean;
  canRespondToButtons: boolean;
  public initControls: any;
  bulkUrlUpdateButton: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private pageService: PageService,
    private eapService: EAPService,
    private itemService: ArticleService,
    public userService: UserService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private modalService: NgbModal,
    private companyService: CompanyService,
    private ngZone: NgZone,
    private dateService: DateService,
    private fileStackService: FilestackService,
  ) {
  }

  ngOnInit() {
    this.companyService.refreshCache();

    if (this.userService.isSuperAdminUser()) {
      this.activatedRoute.params.subscribe((route) => {
        this.analyseRoute(route);

        if (this.isDisplayingList) {
          this.getList();
          this.bulkUrlUpdateButton = 'Bulk URL Update';
        } else if (this.isEditingItem) {
          this.initialiseEditItem(+route.id);
        } else if (this.isAddingItem) {
          this.initialiseAddItem();
        }
      });
    }
  }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isEditingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isEditingItem = true;
    }
  }

  private getList() {
    this.pageService.getAllPages(this.pageType)
      .subscribe(data => {
          this.items = data.map(i => {
            return [
              i.companyId,
              this.companyService.companyName(i.companyId) || 'DEFAULT',
              i.title,
              this.userService.getEAPImage(i.image),
            ];
          });

          this.columns = ['id', 'company', 'title', 'image'];
          this.isInitialised = true;
          this.canRespondToButtons = true;
        },
        error => this.utilService.showToastError('Error while loading EAP list - ' + error));

    this.companyService.getAllCompanies()
      .subscribe((data: ICompany[]) => {
        this.companies = data;
      });
  }

  public initialize(initControls) {
    this.itemService.getS3Hash()
      .subscribe(hash => {
        this.options['imageUploadToS3'] = hash;
        this.initControls = initControls;
        this.initControls.initialize();
      }, error => this.utilService.showToastError('Error while uploading items - ' + error));
  }

  private initialiseEditItem(id: number): void {
    this.pageService.getPage(this.pageType, LANGUAGES.en, id).subscribe((data) => {
      this.item = data;
      this.pageId = data.id;
      if (this.item.languageVersion) {
        try {
          this.languageData = JSON.parse(this.item.languageVersion);
        } catch (e) {
          console.log(e);
        }
      }
      this.initialiseEditableFormGroup(this.item);
    });
  }

  private initialiseAddItem(): void {
    this.pageService.getPage(this.pageType, LANGUAGES.en, 0).subscribe((data) => {
      this.item = data;
      this.item.companyId = 1;
      if (this.item.languageVersion) {
        try {
          this.languageData = JSON.parse(this.item.languageVersion);
        } catch (e) {
          console.log(e);
        }
      }
      this.initialiseEditableFormGroup(this.item);
      this.isInitialised = true;
      this.canRespondToButtons = true;
    });
  }

  private initialiseEditableFormGroup(item: IPage) {
    this.companies = this.companyService.getAllCachedCompanies();
    if (!this.companies.length) {
      this.companyService.getAllCompanies().subscribe(data => this.companies = data);
    }
    const companyId = this.isEditingItem ? (item.companyId === 0 ? 'DEFAULT' : this.companyService.companyName(this.item.companyId)) : this.item.companyId;
    const companyIdControlConfigs = [companyId, Validators.required];
    const data = JSON.parse(item.data);
    this.itemForm = this.fb.group({
      companyIdControl: companyIdControlConfigs,
      widgetTitle: [data.widgetTitle, Validators.required],
      title: [item.title, Validators.required],
      longText: [item.longText, Validators.required],
      image: [item.image, Validators.required],
      url: [data.url, Validators.required],
    });

    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  onCompanyChange(companyId: any) {
    if (companyId == 'null') {
      this.itemForm.get('companyIdControl').setErrors({ invalidCompanySelected: true });
    }
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  private markAllControlsAsUntouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsUntouched({ onlySelf: true });
    });
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  isDEFAULT() {
    return this.item && this.item.companyId === 0;
  }

  private persistItem() {
    this.errorMsg = '';
    this.canRespondToButtons = false;
    this.buildLanguageData();
    let valid = this.validateLanguageData();
    if (!valid) {
      this.canRespondToButtons = true;
      return;
    }
    let data = {
      widgetTitle: this.itemForm.get('widgetTitle').value,
      url: this.itemForm.get('url').value,
    };
    let page: IPage = {
      title: this.title,
      longText: this.longText,
      image: this.image,
      type: this.pageType,
      languageVersion: JSON.stringify(this.languageData),
      companyId: this.itemForm.get('companyIdControl').value,
      data: JSON.stringify(data),
    };

    if (this.isEditingItem) {
      page.id = this.pageId;
      delete page.companyId;
      this.updateItem(page);
    } else if (this.isAddingItem) {
      this.addNewItem(page);
    }
  }

  private addNewItem(page: IPage) {
    this.pageService.addPage(page)
      .subscribe((res: IPageResponse) => {
          this.canRespondToButtons = true;
          const companyName = this.companyService.companyName(+page.companyId);
          if (res.pageExistedError) {
            this.errorMsg = `EAP for ${companyName} is existed!`;
          } else {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`Added new EAP for company - ${companyName}`);
            this.navigateToList();
          }
        },
        error => {
          this.utilService.showToastError(`Error while adding item - ${error}`);
          this.canRespondToButtons = true;
        });
  }

  private updateItem(page: IPage) {
    this.pageService.updatePage(page)
      .subscribe(() => {
          this.itemForm.reset();
          this.canRespondToButtons = true;
          const msg = `Updated ${this.itemType}`;
          this.utilService.showToastSuccess(msg);
          this.navigateToList();
        },
        (error) => {
          const errorMsg = `Error while saving item - ${error}`;
          this.utilService.showToastError(errorMsg);
          this.canRespondToButtons = true;
        },
      );
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Delete ' + this.itemType;
    modal.componentInstance.message = 'Confirm deleting this ' + this.itemType;
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(
      (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }

        this.eapService.deleteEAP(this.item.companyId).subscribe(
          () => {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`${this.itemType} for ${this.companyService.companyName(this.item.companyId)} - deleted`);
            this.navigateToList();
          },
          (error) => this.utilService.showToastError('Error while deleting item - ' + error),
        );
      },
      () => {
      },
    );
  }

  private navigateToList() {
    this.router.navigate(['/cms/eap']);
  }

  onBackClick() {
    this.navigateToList();
  }

  onDeleteClick() {
    if (!this.isEditingItem) {
      return;
    }

    this.deleteItem();
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }
    this.persistItem();
  }

  openFileStack() {
    this.fileStackService.pickEAPImage().then(
      (res) => {
        this.ngZone.run(() => this.itemForm.patchValue({ image: res }));
      },
      (err) => {
        this.utilService.showToastError('Error while uploading picture', err);
      },
    );
  }

  validateLanguageData(): boolean {
    this.errorMsg = '';
    let valid = true;
    let languagesToRemove = [];
    for (const lang of this.languageData) {
      const obj = lang.obj;
      if (obj.title === '' && obj.image === this.image && obj.longText === '') {
        languagesToRemove.push(lang.code);
      } else if (obj.title === '' || obj.longText === '') {
        const name = this.availableLanguages.find(o => o.code === lang.code).name;
        this.errorMsg += `${name} language has errors.<br/>`;
        valid = false;
      }
    }
    for (const code of languagesToRemove) {
      this.languageData.splice(this.languageData.findIndex(o => o.code === code), 1);
    }
    return valid;
  }

  buildLanguageData() {
    if (this.lastCode != LANGUAGES.en) {
      let languageObj: ILanguageData = this.languageData.find(o => o.code == this.lastCode);
      if (!languageObj) {
        languageObj = { code: this.lastCode, obj: {} };
        this.languageData.push(languageObj);
      }
      languageObj.code = this.lastCode;
      languageObj.obj = {
        title: this.itemForm.get('title').value,
        longText: this.itemForm.get('longText').value,
        image: this.itemForm.get('image').value,
      };
    } else {
      this.title = this.itemForm.get('title').value;
      this.longText = this.itemForm.get('longText').value;
      this.image = this.itemForm.get('image').value;
    }
  }

  loadAvailableLanguages(languages) {
    this.availableLanguages = languages;
  }

  changeLanguage(code) {
    // build languageVersion data
    this.buildLanguageData();
    // fill data
    this.lastCode = code;
    if (code != LANGUAGES.en) {
      let languageObj: ILanguageData = this.languageData.find(o => o.code == code);
      if (!languageObj) {
        this.itemForm.get('title').setValue('');
        this.itemForm.get('longText').setValue('');
        this.itemForm.get('image').setValue(this.image);
      } else {
        this.itemForm.get('title').setValue(languageObj.obj.title);
        this.itemForm.get('longText').setValue(languageObj.obj.longText);
        this.itemForm.get('image').setValue(languageObj.obj.image);
      }
    } else {
      this.itemForm.get('title').setValue(this.title);
      this.itemForm.get('longText').setValue(this.longText);
      this.itemForm.get('image').setValue(this.image);
    }
    // mark untouched to hide errors
    this.markAllControlsAsUntouched();
    // validate languageData
    this.validateLanguageData();
  }

  onBulkURLUpdateClicked() {
    this.router.navigate(['/cms/eap-bulk-update']);
  }
}
