import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private http: HttpClient) {
  }

  getConfiguration() {
    return this.http.get<any>(`/menu/config`);
  }

  updateConfiguration(body) {
    return this.http.put<any>(`/menu/config`, body);
  }

  getMenu() {
    return this.http.get<any>(`/menu`);
  }
}
