import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { Router } from '@angular/router';
import { CmsService } from '../../../services/cms-service';
import { UtilService } from '../../../services/util-service';
import { CmsListViewService } from './cms-list-view.service';
import { DateService } from '../../../services/date.service';
import { UserService } from '../../../services/user-service';
import { CarouselComponent } from '../../carousel/carousel.component';
import { saveAs } from 'file-saver';
import { PartnerService } from '../../../services/partner-service';
import { CompanyService } from '../../../services/company-service';

const SORT_NONE = 0;
const SORT_ASC = 1;
const SORT_DESC = 2;

const ID_COL = 0;
const SEARCH_COL = 1;
const USERNAME_COL = 2;

@Component({
  selector: 'app-cms-list-view',
  templateUrl: './cms-list-view.component.html',
  styleUrls: ['./cms-list-view.component.scss'],
})
export class CmsListViewComponent implements OnInit, OnChanges {
  @Input() columnList: string[];
  @Input() dataList: any[];
  @Input() imgColumn: number;
  @Input() additionalNumericColumns: number[] = [];
  @Input() isPreventListDelete = false;
  @Input() isPreventAdd = false;
  @Input() isPreventViewDetail = false;
  @Input() isUserList: boolean = false;
  @Input() otherButtonName = '';
  @Input() disableIds: number[] = [];
  @Input() hideSearch: boolean = false;
  @Input() selectedCompanyId: number;

  @Output() emitClickOtherButtonEvent = new EventEmitter<boolean>();

  noItemSelected = true;
  msg = '';
  alert = '';
  info = '';
  selectedIdsCsv = '';
  search = '';
  config = {
    itemsPerPage: 10,
    currentPage: 1,
  };
  url = '';
  isCarousel = false;
  isCSVExport = false;
  isSelectAll = false;

  sortColumnIndex = -1;
  sortColumnType = SORT_NONE;
  filteredList: any[] = [];

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private cmsService: CmsService,
    private utilService: UtilService,
    private dateService: DateService,
    public cmsListViewService: CmsListViewService,
    private userService: UserService,
    private partnerService: PartnerService,
    private companyService: CompanyService,
  ) {
  }

  ngOnInit() {
    if (this.router.url.indexOf('users') > -1) {
      this.isCSVExport = true;
    }
    if (this.router.url.indexOf('carousel') > -1) {
      this.isCarousel = true;
    }
    if (!this.columnList.length) {
      this.alert = 'Number of columns invalid. Minimum value of columns is 1.';
      return;
    }

    if (this.dataList && this.dataList.length && this.columnList && this.columnList.length !== this.dataList[0].length) {
      this.alert = 'Number of data columns invalid. The data list should have the same number of columns than the column list.';
      return;
    }
    this.isSelectAll = true;
  }

  ngOnChanges(): void {
    this.clearSearch();
    // clear old data
    this.info = '';
    this.filteredList = [];

    if (!this.dataList || !this.dataList.length) {
      this.info = 'No records found.';
      return;
    }

    this.url = this.router.url.replace('/cms/', '');
    this.dataList.forEach((d) => (d.checked = false));
    this.applyUserSetValues();
  }

  private clearSearch() {
    if (!this.search) {
      return;
    }

    this.search = '';
    this.onSearchKeyup();
  }

  private applyUserSetValues() {
    this.cmsListViewService.setCurrentUrl(this.url);
    this.companyService.setCurrentCompanyId(this.userService.company.id);
    this.search = this.cmsListViewService.getSearch();
    this.applySearchFilter();

    this.config.currentPage = this.cmsListViewService.getCurrentPage();

    this.sortColumnIndex = this.cmsListViewService.getSortColumnIndex();
    this.sortColumnType = this.cmsListViewService.getSortColumnType();
    if (this.isColumnSort()) {
      this.applyColumnSort(this.sortColumnIndex);
    }
  }

  private isColumnSort() {
    return this.sortColumnIndex >= 0 && this.sortColumnType !== SORT_NONE;
  }

  private sortByColumn(index: number) {
    this.setColumnSort(index);
    this.setServiceSortValues();
    this.applyColumnSort(index);
  }

  private setColumnSort(index: number) {
    const currentSortColumnIndex = this.sortColumnIndex;
    const currentSortColumnType = this.sortColumnType;
    this.sortColumnIndex = index;

    if (currentSortColumnIndex !== this.sortColumnIndex) {
      this.sortColumnType = SORT_ASC;
    } else {
      this.sortColumnType = currentSortColumnType === SORT_ASC ? SORT_DESC : SORT_ASC;
    }
  }

  private applySearchFilter() {
    if (!this.search) {
      this.filteredList = this.dataList.slice();

      return;
    }

    const idColResults = this.dataList.filter(
      (r) => String(r[ID_COL]).toLowerCase().indexOf(this.search.toLowerCase()) >= 0,
    );
    const nextColResults = this.dataList.filter(
      (r) => String(r[SEARCH_COL]).toLowerCase().indexOf(this.search.toLowerCase()) >= 0,
    );

    let nextUserNameClResult: any;
    if (this.isUserList) {
      nextUserNameClResult = this.dataList.filter(
        (r) => String(r[USERNAME_COL]).toLowerCase().indexOf(this.search.toLowerCase()) >= 0,
      );
    }

    if (this.isUserList) {
      const idResult = idColResults.concat(nextColResults);

      this.filteredList = nextColResults.reduce((acc, eachArr2Elem) => {
        if (idResult.findIndex((eachArr1Elem) => eachArr1Elem.id === eachArr2Elem.id) === -1) {
          acc.push(eachArr2Elem);
        }
        return acc;
      }, [...idResult]);
    } else {
      this.filteredList = idColResults.concat(nextColResults);
    }
  }

  private setServiceSortValues() {
    this.cmsListViewService.setSortColumnIndex(this.sortColumnIndex);
    this.cmsListViewService.setSortColumnType(this.sortColumnType);
  }

  private applyColumnSort(index: number) {
    const sortMultiplier = this.sortColumnType === SORT_ASC ? 1 : -1;
    if (this.isNumericColumn(index)) {
      this.filteredList.sort((a, b) => {
        if (+a[index] === +b[index]) {
          return 0;
        } else {
          return (+a[index] < +b[index] ? -1 : 1) * sortMultiplier;
        }
      });
    } else if (this.isDateColumn(index)) {
      this.filteredList.sort((a, b) => {
        if (a[index] === b[index]) {
          return 0;
        } else {
          return (
            (this.dateService.fromDD_MMM_YYYY(a[index]) < this.dateService.fromDD_MMM_YYYY(b[index])
              ? -1
              : 1) * sortMultiplier
          );
        }
      });
    } else {
      this.filteredList.sort((a, b) => {
        if (a[index] === b[index]) {
          return 0;
        } else {
          return (a[index] < b[index] ? -1 : 1) * sortMultiplier;
        }
      });
    }
  }

  private isNumericColumn(index: number): boolean {
    return index === ID_COL || this.additionalNumericColumns.indexOf(index) >= 0;
  }

  private isDateColumn(index: number): boolean {
    return this.columnList[index].toLocaleLowerCase().indexOf(' date') >= 0;
  }

  isAscendingSortColumn(index: number): boolean {
    return this.sortColumnIndex === index && this.sortColumnType === SORT_ASC;
  }

  isDescendingSortColumn(index: number): boolean {
    return this.sortColumnIndex === index && this.sortColumnType === SORT_DESC;
  }

  searchPlaceholder(): string {
    let placeholder = `Search ${this.columnList[ID_COL].toUpperCase()} or ${this.columnList[
      SEARCH_COL
      ].toUpperCase()}`;
    if (this.isUserList) {
      placeholder += ` or ${this.columnList[USERNAME_COL].toUpperCase()}`;
    }
    return placeholder;
  }

  onSearchKeyup() {
    this.applySearchFilter();
    if (this.isColumnSort()) {
      this.applyColumnSort(this.sortColumnIndex);
    }
    this.cmsListViewService.setSearch(this.search);
  }

  onColClick(index: number) {
    this.sortByColumn(index);
  }

  showPreview() {
    const item = this.dataList;
    const previewModal = this.modalService.open(CarouselComponent, { size: 'lg' });
    previewModal.componentInstance.carousels = item;
  }

  delete() {
    if (this.isPreventListDelete) {
      return;
    }

    const idsArray = this.selectedIdsCsv.split(',').filter(id => {
      if (!this.disableIds.includes(+id)) {
        return id;
      }
    });
    const speltNumber = this.utilService.spellNumber(idsArray.length).toUpperCase();
    const deleteModal = this.modalService.open(BootstrapModalComponent);
    const title = `Delete ${speltNumber} item(s)`;
    const message = `Confirm deleting ${speltNumber} item(s)`;

    deleteModal.componentInstance.title = title;
    deleteModal.componentInstance.message = message;
    deleteModal.componentInstance.confirmButtonLabel = 'Delete';

    deleteModal.result.then(res => {
      if (!res) {
        return;
      }
      let lastId = idsArray[idsArray.length - 1];
      idsArray.forEach(id => this.deleteItemForId(+id, lastId));
    });
  }

  exportData() {
    if (!this.isCSVExport) {
      return;
    }

    const idsArray = this.selectedIdsCsv.split(',').filter(id => id);
    this.userService.getUserDetailsForExport(idsArray)
      .subscribe(data => {
          // const replacer = (key, value) => value === null ? 'N/A' : value; // specify how you want to handle null values here
          let headerTitle = ['ID', 'Email', 'DateOfBirth', 'FirstName', 'LastName', 'LastLoginDate', 'CompanyName', 'Phone', 'State', 'RegisterDate', 'IsAcceptMarketing', 'Status', 'Role', 'Insights']; // CSV file Heading
          const header = ['id', 'email', 'dateOfBirth', 'firstName', 'lastName', 'lastlogindate', 'companyname', 'phone', 'state', 'registerdate', 'isacceptmarketing', 'status', 'system_role', 'hasInsights']; // API Heading
          let csv = data.map(row => header.map(fieldName => {
              if (fieldName == 'registerdate' || fieldName == 'lastlogindate' || fieldName == 'dateOfBirth') {
                return this.dateService.formatDD_MMM_YYYY_Allow_Null_With_Text(row[fieldName]);
              } else if (fieldName == 'status') {
                return this.userService.statusName(row[fieldName]) || '';
              } else if (fieldName == 'system_role') {
                return this.userService.systemRoleName(row[fieldName]) || '';
              } else if (fieldName == 'hasInsights') {
                return row[fieldName] ? 'Yes' : 'No';
              } else if (row[fieldName] != null) {
                return row[fieldName];
              } else {
                return 'NULL';
              }
            },
          ).join(','));
          csv.unshift(headerTitle.join(','));
          let csvArray = csv.join('\r\n');
          const blob = new Blob([csvArray], { type: 'text/csv' });
          let fileName = 'UsersList_' + new Date().toISOString();
          fileName = fileName + '.csv';
          saveAs(blob, fileName);
        },
        err => this.utilService.showToastError('Download Failed ', err),
      );
    this.unselectAll();
  }

  private deleteItemForId(id: number, lastId: string) {
    this.cmsService.deleteObject(this.url, id)
      .subscribe(() => {
          if (this.url == 'eap') {
            const item = this.dataList.find(item => +item[0] === id);
            this.utilService.showToastSuccess(`Deleted ${item[1]} - ${item[2]}`);
          } else {
            this.utilService.showToastSuccess('Deleted item id ' + id);
          }
          if (id === Number(lastId)) {
            if (this.url == 'partners') {
              this.partnerService.notifyPartnersChanged();
            }
          }
          this.removeIdFromLists(id);
        },
        err => this.utilService.showToastError('Error while deleting item id ' + id, err),
      );
  }

  private removeIdFromLists(id: number) {
    this.filteredList = this.filteredList.filter(item => +item[0] !== id);
    this.dataList = this.dataList.filter(item => +item[0] !== id);

    let selectedIds = this.selectedIdsCsv.split(',');
    selectedIds = selectedIds.filter(i => +i !== id);
    this.selectedIdsCsv = selectedIds.join(',');
  }

  unselectAll() {
    this.isSelectAll = true;
    this.selectedIdsCsv = '';
    this.dataList.forEach(i => i.checked = false);
    this.filteredList.forEach(i => i.checked = false);
  }

  selectAll() {
    this.isSelectAll = false;
    this.dataList.forEach(i => i.checked = true);
    this.selectedIdsCsv = '';
    this.filteredList.forEach(i => {
      this.selectedIdsCsv += i[0] + ',';
      i.checked = true;
    });
  }

  selectItem(item) {
    if (!item.checked) {
      this.selectedIdsCsv += item[0] + ',';
      this.isSelectAll = false;
    } else {
      this.selectedIdsCsv = this.selectedIdsCsv.replace(item[0] + ',', '');
      const idsArray = this.selectedIdsCsv.split(',').filter(id => id);
      if (idsArray.length == 0) {
        this.isSelectAll = true;
      }
    }
  }

  goToDetails(param) {
    if ((this.isPreventAdd && isNaN(param)) || this.isPreventViewDetail) {
      return;
    }

    this.cmsListViewService.setCurrentPage(this.config.currentPage);
    this.companyService.setCurrentCompanyId(this.selectedCompanyId);

    if (this.router.url.indexOf('companies') > -1 && param !== 'new') {
      this.router.navigate(['/cms/company-summary', param]);
    } else if (this.router.url.indexOf('life-actions-reporting') > -1) {
      this.router.navigate(['/cms/lifeactions', param]);
    } else {
      this.router.navigate([this.router.url, param]);
    }
  }

  hasImage(imgColumn) {
    return imgColumn !== '' && imgColumn !== null;
  }

  onClickOtherButton() {
    this.emitClickOtherButtonEvent.emit(true);
  }
}
