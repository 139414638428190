import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';

import { Observable, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UserService } from '../services/user-service';

@Injectable({ providedIn: 'root' })
export class EmailValidator {
  constructor(
    public userService: UserService,
    ) {}

  checkIsEmailRegistered(email: string) {
    return timer(1000)
      .pipe(
        switchMap(() => {
          return this.userService.isEmailRegistered(email)
        })
      );
  }

  emailValidator(): AsyncValidatorFn {
    return (ctrl: AbstractControl): Observable<{ [key: string]: any } | null> => {
      return this.checkIsEmailRegistered(ctrl.value)
        .pipe(
          map(res => {
            if (res) {
              return { isEmailRegistered: true };
            }
          })
        );
    };
  }

}
