import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IIndicator } from '../../_models/indicator';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IIndicatorTrackerLogValue } from '../../_models/indicator-tracker-log-value';
import { DateService } from '../../../services/date.service';
import * as _ from 'lodash';
import { TMHService } from '../../../services/track-my-health-service';
import { IDateYmd } from '../../_models/date-ymd';
import { TrackMyHealthIndicatorValuesService } from './track-my-health-indicator-values.service';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';

@Component({
  selector: 'app-track-my-health-indicator-values',
  templateUrl: './track-my-health-indicator-values.component.html',
  styleUrls: ['./track-my-health-indicator-values.component.scss'],
  providers: [TrackMyHealthIndicatorValuesService],
})
export class TrackMyHealthIndicatorValuesComponent implements OnInit {
  @Input() indicator: IIndicator;
  @ViewChild('addValueInput') addValueInputElement: ElementRef;


  valuesTitle = 'Value';
  addDateYmd: IDateYmd;
  addValue = 0;
  addDateIndicator = '';
  maxCalendarDateYmd: IDateYmd;
  invalidAddEntryMessage = '';
  isDisplayHrsMins = false;
  isAddValueDirty = false;
  editInputs = [];

  constructor(
    private activeModal: NgbActiveModal,
    public service: TrackMyHealthIndicatorValuesService,
    private tmhService: TMHService,
    private dateService: DateService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.isDisplayHrsMins = this.tmhService.isSleepIndicator(this.indicator);
    this.maxCalendarDateYmd = this.dateService.toYearMonthDayObject();
    this.initialiseAddValue(this.dateService.getDate());
    this.setValueTitle();
    this.initialiseService();
    this.service.displayLastEnteredValue();
    this.editInputs = [];
  }

  private initialiseService() {
    for (let i = this.indicator.trackers.length - 1; i >= 0; i--) {
      const value = this.indicator.trackers[i];
      this.service.addPreviouslyLoggedValue(
        value.id, value.date, value.value, value.achievedThisInterval);
    }
  }

  private initialiseAddValue(date: Date) {
    this.addDateYmd = this.dateService.getYearMonthDayObject(date);
    this.addValue = 0;
    this.isAddValueDirty = false;
    this.setAddDateIndicator();
  }

  private setAddDateIndicator() {
    const addDate = this.dateService.fromYearMonthDayObject(this.addDateYmd);
    const dateValue = this.service.getNonDeletedValueForDate(addDate);
    if (this.service.isNonDeletedValueForDate(addDate)) {
      this.addDateIndicator = 'Edit ';
      this.addValue = dateValue.value;
    } else {
      this.addDateIndicator = 'Add ';
      this.addValue = 0;
    }

    if (this.dateService.isToday(addDate)) {
      this.addDateIndicator += '(Today)';
    } else if (this.dateService.isYesterday(addDate)) {
      this.addDateIndicator += '(Yesterday)';
    }
  }

  private setValueTitle() {
    if (this.indicator.unit) {
      this.valuesTitle = this.indicator.unit;
      return;
    }

    if (this.indicator.logTitle.startsWith('Add your ')) {
      this.valuesTitle = this.indicator.logTitle.slice(9);
      return;
    }
  }

  display(value: number) {
    if (this.isDisplayHrsMins) {
      return this.tmhService.getMinutesFormattedAsHMM(value);
    }

    return value;
  }

  onChangeInput(event, loggedValue) {
    var index = _.findIndex(this.editInputs, { id: loggedValue.id });
    if (!_.find(this.editInputs, { id: loggedValue.id })) {
      this.editInputs.push(loggedValue);
    } else {
      this.editInputs[index].value = loggedValue.value;
    }
  }

  onClickBeginEditing(loggedValue: IIndicatorTrackerLogValue) {
    loggedValue.isEditing = true;
  }

  onClickSaveEditing(loggedValue: IIndicatorTrackerLogValue) {
    if (loggedValue.value < 0) {
      this.onClickUndoEditing(loggedValue);
      return;
    }
    var index = _.findIndex(this.editInputs, { id: loggedValue.id });
    this.editInputs.splice(index, 1);
    const isGoalAchievedValue = this.tmhService.isGoalAchievedValue(
      this.indicator.activeIndicatorRule, loggedValue.value);
    this.service.editValue(loggedValue.id, loggedValue.value, isGoalAchievedValue, this.editInputs);
  }

  onClickUndoEditing(loggedValue: IIndicatorTrackerLogValue) {
    const persistValue = this.service.getPersistValue(loggedValue.id);
    if (persistValue) {
      loggedValue.value = persistValue.value;
    }

    loggedValue.isEditing = false;
  }

  onClickDelete(loggedValue: IIndicatorTrackerLogValue) {
    this.service.deleteValue(loggedValue.id);
  }

  onChangeDate() {
    this.setAddDateIndicator();
  }

  onChangeValue() {
    this.isAddValueDirty = true;
  }

  onTotalMinutesEntered(event, loggedValue) {
    if (loggedValue) {
      loggedValue.value = event;
    } else {
      this.addValue = event;
    }
  }

  private setFocusToAddValue() {
    if (this.isDisplayHrsMins) {
      return;
    }

    this.addValueInputElement.nativeElement.focus();
  }

  onClickAddNextDay() {
    const addDate = this.dateService.fromYearMonthDayObject(this.addDateYmd);
    const nextDay = this.service.getNextDay();
    if (!this.dateService.isSameDate(addDate, nextDay)) {
      this.initialiseAddValue(nextDay);
    }

    this.setFocusToAddValue();
  }

  onClickAddYesterday() {
    const date = this.dateService.fromYearMonthDayObject(this.addDateYmd);
    if (!this.dateService.isYesterday(date)) {
      const yesterday = this.dateService.getDate(-1);
      this.initialiseAddValue(yesterday);
    }

    this.setFocusToAddValue();
  }

  onClickAddToday() {
    const date = this.dateService.fromYearMonthDayObject(this.addDateYmd);
    if (!this.dateService.isToday(date)) {
      const today = this.dateService.getDate();
      this.initialiseAddValue(today);
    }

    this.setFocusToAddValue();
  }

  onClickAddValue() {
    // Validate
    this.invalidAddEntryMessage = this.getInvalidAddEntryMessage();
    if (this.invalidAddEntryMessage) {
      return;
    }

    this.invalidAddEntryMessage = '';
    const isGoalAchievedValue = this.tmhService.isGoalAchievedValue(
      this.indicator.activeIndicatorRule, this.addValue);
    const addDate = this.dateService.fromYearMonthDayObject(this.addDateYmd);

    this.service.addValue(addDate, this.addValue, isGoalAchievedValue, this.editInputs);
    this.setAddDateIndicator();
    this.isAddValueDirty = false;
  }

  private getInvalidAddEntryMessage(): string {
    const addDate = this.dateService.fromYearMonthDayObject(this.addDateYmd);
    const invalidMessages: string[] = [];

    if (!this.dateService.isValidDateYmd(this.addDateYmd)) {
      invalidMessages.push('a valid date');
    } else if (this.dateService.isAFutureDate(addDate)) {
      invalidMessages.push('today or past date');
    }

    if (this.addValue < 0) {
      invalidMessages.push('a value not less than zero');
    }

    if (!invalidMessages.length) {
      return '';
    }

    return 'Please enter ' + invalidMessages.join(' and ');
  }

  onClickClose() {
    this.confirmCloseDialogue();
  }

  private confirmCloseDialogue() {
    if (!this.isAddValueDirty
      && !this.service.isAnyChangedValues()) {
      this.activeModal.dismiss();
      return;
    }

    const msg = 'Save all your changes or undo all your changes?';
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Close';
    modal.componentInstance.message = msg;
    modal.componentInstance.confirmButtonLabel = 'Save All';
    modal.componentInstance.closeButtonLabel = 'Undo All';

    modal.result.then(isSaveAll => {
      if (isSaveAll) {
        this.onClickSaveAll();
      }

      this.activeModal.dismiss();
    })
      .catch(() => this.activeModal.dismiss());
  }

  onClickUndoAll() {
    this.confirmUnsavedChangesPreClose();
  }

  private confirmUnsavedChangesPreClose() {
    if (!this.isAddValueDirty
      && !this.service.isAnyChangedValues()) {
      this.activeModal.dismiss();
      return;
    }

    const title = 'Undo All';
    const msg = 'Confirm closing without saving changes?';
    const confirmButtonLabel = 'Undo changes';
    const closeButtonLabel = 'No';
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = title;
    modal.componentInstance.message = msg;
    modal.componentInstance.confirmButtonLabel = confirmButtonLabel;
    modal.componentInstance.closeButtonLabel = closeButtonLabel;

    modal.result.then(isConfirmed => {
      if (isConfirmed) {
        this.activeModal.dismiss();
      }
    })
      .catch(() => {
      });
  }

  onClickSaveAll() {
    if (this.isAddValueDirty) {
      this.onClickAddValue();
    } else if (this.isDisplayHrsMins) {
      if (this.addValue) {
        this.onClickAddValue();
      } else {
        this.service.displayValues.forEach(loggedValue => {
          if (loggedValue.isEditing && loggedValue.value) {
            this.onClickSaveEditing(loggedValue);
          }
        });
      }
    }

    if (this.editInputs.length > 0) {
      this.service.updateAllValues(this.editInputs);
    }
    const allChangedValues = this.service.getAllChangedValues();
    this.activeModal.close(allChangedValues);
  }
}
