import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ConnectionService } from '../../services/connection-service';
import { DomainCheckerService } from '../../services/domain-checker.service';
import { AuthRoutePath } from '../_models/auth';

@Injectable({
  providedIn: 'root',
})
export class SSOGuard implements CanActivate {
  constructor(
    private router: Router,
    private connectionService: ConnectionService,
    private domainChecker: DomainCheckerService,
  ) {
    connectionService.getCompanyConnectionByHostname(domainChecker.hostname);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.connectionService.usedSSO.subscribe((res) => {
      if (res) {
        this.router.navigateByUrl(AuthRoutePath.login);
        return false;
      }
      return true;
    });
    return true;
  }
}
