import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user-service';
import { InsightsService } from '../../services/insights-service';

@Injectable({
  providedIn: 'root',
})
export class InsightsDomainGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService, private insightsService: InsightsService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.insightsService.isInsightsDomain() || !this.userService.hasInsightsAccess()) {
      this.router.navigate(['']);
      return false;
    }
    return true;
  }
}
