<div class="m-3">
  <form [formGroup]="filterForm">
    <div class="row justify-content-center">
      <div class="col" *ngFor="let filter of filterFormIndices; let i = index">
        <div class="custom-control custom-checkbox" *ngIf="!isInputTypeInactive(filter)">
          <input class="custom-control-input" type="checkbox" [formControlName]="filter" id="{{filter}}" (change)="emitDriverFilters($event)">
          <label class="custom-control-label" for="{{filter}}">{{filter}}</label>
        </div>
        <div class="custom-control custom-checkbox" *ngIf="isInputTypeInactive(filter)">
          <input class="custom-control-input" type="checkbox" [formControlName]="filter" id="{{filter}}" (change)="emitInactiveFilter($event)">
          <label class="custom-control-label" for="{{filter}}">{{filter}}</label>
        </div>
      </div>
      <div class="col">
        <a (click)="clearFilterSelections($event)">Clear Filters</a>
      </div>
    </div>
  </form>
