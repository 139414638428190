<div class="row">
  <div class="col-4 pt-2 pl-3">
    <img *ngIf="is1700" class="screenshot" src="assets/img/widgets/download.png" />
    <img *ngIf="isAltiusLife" class="screenshot" src="assets/img/widgets/download_altiuslife.png" />
    <img *ngIf="isBFFLife" class="screenshot" src="assets/img/widgets/download.png" />
  </div>
  <div class="col-8">
    <div class="row pr-3">
      <h4 class="col-11 pt-2 no-padding-left">Download our app for the full interactive experience!</h4>
      <button type="button" class="close col-1" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="row pr-3 pb-2">
      <a *ngFor="let link of applinksObj" class="btn btn-link-lg col-6" target="_blank" [href]="link.appLink">
        <img class="linkImg" [src]="link.imgSrc" />
      </a>
    </div>
  </div>
</div>
