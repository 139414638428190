import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ShareComponent } from './share.component';
import { ShareRoutingModule } from './share-routing.module';
import { GroupDetailsComponent } from './group-details/group-details.component';
import { GroupsComponent } from './groups/groups.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { SharedModule } from '../_shared/shared.module';
import { AddGroupComponent } from './add-group/add-group.component';
import { FriendsContainerComponent } from './friends-container/friends-container.component';
import { SuggestedFriendsComponent } from './friends-container/suggested-friends/suggested-friends.component';
import { SearchFriendsResultComponent } from './friends-container/search-friends-result/search-friends-result.component';
import { FriendsListComponent } from './friends-container/friends-list/friends-list.component';
import { FriendPresentationComponent } from './friends-container/friend-presentation/friend-presentation.component';
import { LeaderboardListComponent } from './leaderboard/leaderboard-list/leaderboard-list.component';
import { AddUserGroupComponent } from './add-user-group/add-user-group.component';
import { TagInputModule } from 'ngx-chips';

@NgModule({
  declarations: [
    ShareComponent,
    AddGroupComponent,
    AddUserGroupComponent,
    GroupDetailsComponent,
    GroupsComponent,
    LeaderboardComponent,
    FriendsContainerComponent,
    SuggestedFriendsComponent,
    SearchFriendsResultComponent,
    FriendsListComponent,
    FriendPresentationComponent,
    LeaderboardListComponent,
  ],
  imports: [
    TagInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CommonModule,
    SharedModule,
    ShareRoutingModule
  ],
  entryComponents: [
    FriendPresentationComponent,
    AddGroupComponent,
    AddUserGroupComponent
  ]
})
export class ShareModule { }
