import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UserService } from '../../../services/user-service';
import { ILeaderboardItem } from '../../_models/leaderboard-item';

@Component({
  selector: 'app-leaderboard-widget',
  templateUrl: './leaderboard-widget.component.html',
  styleUrls: ['./leaderboard-widget.component.scss'],
})
export class LeaderboardWidgetComponent implements OnInit, OnChanges {
  @Input() leaderboardItems: ILeaderboardItem[];

  goldBadgeSrc: string;
  silverBadgeSrc: string;
  bronzeBadgeSrc: string;

  constructor(private userService: UserService) {
  }


  ngOnInit() {
    this.goldBadgeSrc = this.userService.getGoldBadgeSrc();
    this.silverBadgeSrc = this.userService.getSilverBadgeSrc();
    this.bronzeBadgeSrc = this.userService.getBronzeBadgeSrc();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(">>>>>>qqq", changes.leaderboardItems.previousValue);
    // console.log(this.leaderboardItems);
    // if(changes.leaderboardItems.previousValue){
    //   this.leaderboardItems = [...changes.leaderboardItems.previousValue];
    // } else {
    this.leaderboardItems = [...this.leaderboardItems];
    // }
    this.leaderboardItems.forEach((item: ILeaderboardItem) => {
      item.photoSrc = (item.photo)
        ? this.userService.getProfilePicture(item.photo)
        : this.userService.getDefaultProfileImage();
      item.levelSrc = this.userService.getLevelSrc(item.level);
    });
  }
}
