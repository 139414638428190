import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserService } from '../../../services/user-service';
import { ILatestGroupsActivity } from '../../_models/latest-groups-activity';

@Component({
  selector: 'app-groups-widget',
  templateUrl: './groups-widget.component.html',
  styleUrls: ['./groups-widget.component.scss']
})
export class GroupsWidgetComponent implements OnChanges {
  @Input() activity: ILatestGroupsActivity;

  isRecentActvity = false;
  avatarSrc: string;
  bodyText: string;

  constructor(private userService: UserService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.isRecentActvity = this.activity && this.activity.groupActivity.count > 0;
    this.setAvatarSrc();
    this.setBodyText();
  }

  private setAvatarSrc() {
    this.avatarSrc = (this.isAGroupAvatar())
      ? this.userService.getGroupsLogo(this.activity.groupAvatars[0])
      : this.userService.getDefaultProfileImage();
  }

  private isAGroupAvatar(): boolean {
    return this.isAGroupAvatar
      && this.activity.groupAvatars.length > 0;
  }

  private setBodyText() {
    if (this.activity.userGroupsCount === 0) {
      this.bodyText = `You do not belong to any groups.
        Explore the groups and start a conversation!`;
    } else if (this.activity.groupActivity.count > 0) {
      this.bodyText =  `There has been ${this.activity.groupActivity.count} recent posts
      in groups that you have subscribed to.
      Continue the conversation!`;
    } else {
      this.bodyText = `There has been no recent activity in groups you are subscribed to.
      Why don't you start a conversation or explore other groups?`;
    }
  }
}
