import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

// if (EnvVariables.production) {
//   enableProdMode();
// }
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
