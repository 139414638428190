<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="onCloseClick()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form
  [formGroup]="itemForm"
  (ngSubmit)="onSaveClick()">

  <div class="modal-body">
    <div class="row">
      <div class="col-12">
         <select
          size="10"
          formControlName="selectedItemControl"
          class="form-control">
          <option
            *ngFor="let item of items"
            [ngValue]="item"
            [disabled]="isAddingItem || isEditingItem"
            (click)="onClickItem(item)">
            {{itemSummary(item)}}
          </option>
        </select>
      </div>
    </div>

    <br />
    <div class="row">
      <div class="col-3">
        <div class="form-group">
          <label class="control-label required">Value</label>
          <input
          #editableFocus
          class="form-control"
          [ngClass]="displayFieldCss('valueControl')"
          type="number"
          [readonly]="isReadOnly()"
          formControlName="valueControl" />
          <span class="invalid-feedback">Value of 1 or greater is required</span>
        </div>
      </div>

      <div class="col-3">
        <div class="form-group">
          <label class="control-label required">Unit</label>
          <input
          class="form-control"
          [ngClass]="displayFieldCss('unitControl')"
          type="text"
          [readonly]="isReadOnly()"
          formControlName="unitControl" />
          <span class="invalid-feedback">Unit is required</span>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label class="control-label required">Name</label>
          <input
          class="form-control"
          [ngClass]="displayFieldCss('nameControl')"
          type="text"
          [readonly]="isReadOnly()"
          formControlName="nameControl" />
          <span class="invalid-feedback">Name is required</span>
        </div>
      </div>

    </div>

    <br />
    <div class="custom-modal-footer">
    <div class="row">
      <button
        type="button"
        class="col-4 btn"
        [ngClass]="isAddingItem ? 'btn-primary' : 'btn-outline-primary'"
        (click)="onAddClick()"
        [disabled]="!canAdd()">Add</button>
      <button
        type="button"
        class="col-4 btn"
        [ngClass]="isEditingItem ? 'btn-primary' : 'btn-outline-primary'"
        (click)="onEditClick()"
        [disabled]="!canEdit()">Edit</button>
      <button
        type="button"
        class="col-4 btn btn-outline-primary"
        (click)="onDeleteClick()"
        [disabled]="!canDelete()">Delete</button>
    </div>

    <div class="row">
        <button type="button" class="col-4 btn btn-outline-primary" (click)="onCancelClick()" [disabled]="!canCancel()">Cancel</button>
        <button type="button" class="col-4 btn btn-outline-primary" (click)="onSaveClick()" [disabled]="!canSave()">Save</button>
        <button type="button" class="col-4 btn btn-primary" (click)="onCloseClick()" >Close</button>
      </div>
    </div>

  </div>
</form>
