<div class="container">
  <h2>Sign Up</h2>
  <form [formGroup]="form" (ngSubmit)="onSubmitRegisterForm()">
    <div class="form-group">
      <app-company-access-code-input [parentForm]="form" [inputcss]="setInputOutlineClass(companyAccessCode)"
        [selectedCompany]="company"></app-company-access-code-input>
    </div>

    <div *ngIf="customerStripeTokenReceived">
      <p>Payment details received!</p>
      <p>Plan: <b>{{ (selectedStripePlan$ | async)?.priceDetails }}</b></p>
    </div>

    <div class="form-group">
      <app-email-input [parentForm]="form" [inputcss]="setInputOutlineClass(email)"></app-email-input>
    </div>

    <div class="form-group">
      <small><label>Date Of Birth</label></small>
      <!-- <input type="date" class="form-control form-control-sm" [formControlName]="'dateOfBirth'" name="dateOfBirth" placeholder="Date Of Birth"> -->
      <div class="input-group">
        <input placeholder="yyyy-mm-dd"
          class="form-control form-control-sm" formControlName="dateOfBirth"
          (blur)="dp.close()"
          [showWeekdays]="false" [minDate]="{year: 1930, month: 1, day: 1}"
          #dp="ngbDatepicker" ngbDatepicker />
        <div class="input-group-append">
          <button class="btn btn-primary btn-sm" tabindex="-1"
            (click)="dp.toggle();" type="button">
            <i class="fa fa-calendar"></i>
          </button>
        </div>
      </div>
      <div *ngIf="isDateOfBirthInputError()">
        <div class="alert alert-danger p-1"><small>{{DOBError}}</small></div>
      </div>
    </div>

    <div class="form-group">
      <app-password-input [parentForm]="form" [inputcss]="setInputOutlineClass(confirmPassword)"
        [showConfirmPassword]="true"></app-password-input>
    </div>

    <div *ngIf="canShowExtraRegistrationFields" class="form-group">
      <form [formGroup]="extraRegistrationFieldsForm">
        <div class="form-row">
          <div class="col-12 col-sm-6 mt-1">
            <small><label for="firstName">First Name</label></small>
            <input id="firstName" type="text" class="form-control form-control-sm" [formControlName]="'firstName'" name="firstName" placeholder="First name">
          </div>
          <div class="col-12 col-sm-6 mt-1">
            <small><label for="lastName">Last Name</label></small>
            <input id="lastName" type="text" class="form-control form-control-sm" [formControlName]="'lastName'" name="lastName" placeholder="Last name">
          </div>
        </div>
        <div class="form-row">
          <div class="col-12 col-sm-4 mt-1">
            <small><label>State</label></small>
            <input type="text" class="form-control form-control-sm" [formControlName]="'state'" name="state" placeholder="State">
          </div>
          <div class="col-12 col-sm-4 mt-1">
            <small><label>Phone</label></small>
            <input type="text" class="form-control form-control-sm" [formControlName]="'phone'" name="phone" placeholder="Phone">
          </div>
        </div>
        <div class="form-row">
          <div class="col-12 mt-1">
              <div class="form-check">
                <input
                  class="form-check-input"
                  [formControlName]="'isAcceptMarketing'"
                  type="checkbox"
                  value=""
                  id="isAcceptMarketing"
                  checked />
                <small><label class="form-check-label" for="isAcceptMarketing">
                  Yes, I consent to SeventeenHundred sending my details to HCF so that HCF can contact me about their products and services
                </label></small>
              </div>
          </div>
        </div>
      </form>
    </div>

    <div class="form-group">
      <app-terms-privacy-input [parentForm]="form" (termsOfServiceRequested)="onOpenTermsOfServiceRequested()"
        (privacyPolicyRequested)="onOpenPrivacyPolicyRequested()">
      </app-terms-privacy-input>
    </div>
    <div>
      <button type="submit" class="btn btn-primary btn-block oauth-button" [disabled]="isFormSubmitDisabled()">Sign Up</button>
    </div>
  </form>
</div>

<div *ngIf="isLoading" class="row justify-content-center mb-2">
  <i class="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
</div>
