import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from './user-service';
import { IUserProgram } from '../app/_models/user-program';
import { DateService } from './date.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IProgram } from '../app/_models/program';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {
  InProgressStateName = 'In Progress';
  PausedStateName = 'Paused';
  CompletedStateName = 'Completed on';
  ScheduledStateName = 'Scheduled to start on';

  private subject = new BehaviorSubject<IUserProgram[]>([]);
  userPrograms$: Observable<IUserProgram[]> = this.subject.asObservable();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private dateService: DateService
  ) { }

  getUserPrograms() {
    const url = `/programs`;

    return this.http
      .get<any>(url).pipe(
      tap((data) =>
        data.map((userProgram) => {
          this.setProgramListProperties(userProgram);
        })
      ),
      tap((data) => this.subject.next(data)), );
  }

  private setProgramListProperties(userProgram: IUserProgram) {
    userProgram.imageSource = this.getProgramImage(userProgram.image);
    userProgram.isUserInProgram = this.isUserInProgram(userProgram);
    userProgram.statusName = this.getProgramStatus(userProgram);
    userProgram.progressPercent = this.calculateUserProgramProgress(userProgram);
  }

  getProgramById(id) {
    const url = `/programs/${id}`;

    return this.http.get<any>(url).pipe(tap((data) => {
      this.setProgramListProperties(data.program);
    }));
  }

  createUserProgram(program) {
    const url = `/programs`;
    return this.http.post<any>(url, program).pipe(tap((data) => {
      this.setProgramListProperties(data.program);
    }));
  }

  updateUserProgram(id, update) {
    const url = `/programs/${id}`;

    return this.http.put<any>(url, update).pipe(map((data) => data[0]));
  }

  completeUserProgram(id, update) {
    const url = `/programs/complete/${id}`;

    return this.http.put<any>(url, update).pipe(tap((data) => {
      this.setProgramListProperties(data.nextProgram.program);
    }));
  }

  pauseUserProgram(program: IUserProgram) {
    const url = `/programs/${program.userProgramId}`;
    const body = this.getPauseUserProgramBody(program);

    return this.http
      .put(url, body).pipe(
      map((data) => data[0]),
      tap((userProgram) => {
        this.setProgramListProperties(userProgram);
      }), );
  }

  resumeUserProgram(program: IUserProgram) {
    const url = `/programs/${program.userProgramId}`;
    const body = this.getResumeUserProgramBody(program);

    return this.http
      .put(url, body).pipe(
      map((data) => data[0]),
      tap((userProgram) => {
        this.setProgramListProperties(userProgram);
      }), );
  }

  getPauseUserProgramBody(program: IUserProgram) {
    let prePausedDays = this.dateService.daysDifference(program.userProgramStartDate);
    if (program.lastResumedDate) {
      prePausedDays =
        program.cumulativePrePausedDays + this.dateService.daysDifference(program.lastResumedDate);
    }

    return {
      activeUserProgram: false,
      lastPausedDate: new Date(),
      cumulativePrePausedDays: prePausedDays
    };
  }

  getResumeUserProgramBody(program: IUserProgram) {
    return {
      activeUserProgram: true,
      lastResumedDate: new Date()
    };
  }

  getProgramImage(image) {
    return this.userService.getUserStorageAbsolutePath('programs/images/', image);
  }

  getProgramStatus(program) {
    if (this.isUserProgramInProgress(program)) {
      return this.InProgressStateName;
    }

    if (this.isUserProgramPaused(program)) {
      return this.PausedStateName;
    }

    if (this.isUserProgramCompleted(program)) {
      return (
        this.CompletedStateName + ' ' + this.dateService.formatDD_MMM_YYYY(program.completionDate)
      );
    }
    if (this.isUserProgramScheduled(program)) {
      return (
        this.ScheduledStateName +
        ' ' +
        this.dateService.formatDD_MMM_YYYY(program.userProgramStartDate)
      );
    }

    return '';
  }

  isUserProgramScheduled(program: IUserProgram): boolean {
    if (this.isUserInProgram && this.dateService.isAfterToday(program.userProgramStartDate)) {
      return true;
    }
  }

  isUserInProgram(program: IUserProgram): boolean {
    if (!program) {
      return false;
    }

    return !!program.userProgramId;
  }

  isUserProgramInProgress(program: IUserProgram): boolean {
    if (!program) {
      return false;
    }
    if (!this.isUserInProgram(program)) {
      return false;
    }
    if (this.isUserProgramPaused(program)) {
      return false;
    }
    if (this.isUserProgramCompleted(program)) {
      return false;
    }
    return !this.isStartDateInFuture(program);
  }

  isStartDateInFuture(program: IUserProgram): boolean {
    if (!program) {
      return false;
    }
    return this.dateService.isAfterToday(program.userProgramStartDate);

  }

  isUserProgramPaused(program: IUserProgram): boolean {
    if (!program) {
      return false;
    }
    if (!this.isUserInProgram(program)) {
      return false;
    }
    if (this.isUserProgramCompleted(program)) {
      return false;
    }

    return !program.activeUserProgram;
  }

  isUserProgramCompleted(program: IUserProgram): boolean {
    if (!program) {
      return false;
    }
    if (!this.isUserInProgram(program)) {
      return false;
    }

    return !!program.completed;
  }

  isProgramContent(program: IUserProgram): boolean {
    if (!program) {
      return false;
    }

    return !!program.contentIds && !!program.contentIds.length;
  }

  isProgramContentSent(program: IUserProgram): boolean {
    if (!program) {
      return false;
    }

    return !!program.contentIdsSent && !!program.contentIdsSent.length;
  }

  isProgramContentUnsent(program: IUserProgram): boolean {
    if (!program) {
      return false;
    }

    return !!program.contentIdsUnsent && !!program.contentIdsUnsent.length;
  }

  isProgramLifeActions(program: IUserProgram): boolean {
    if (!program) {
      return false;
    }

    return !!program.indicatorIds && !!program.indicatorIds.length;
  }

  isProgramLifeActionsSent(program: IUserProgram): boolean {
    if (!program) {
      return false;
    }

    return !!program.indicatorIdsSent && !!program.indicatorIdsSent.length;
  }

  isProgramLifeActionsUnsent(program: IUserProgram): boolean {
    if (!program) {
      return false;
    }

    return !!program.indicatorIdsUnsent && !!program.indicatorIdsUnsent.length;
  }

  canUserJoinProgram(program: IUserProgram): boolean {
    if (!program) {
      return false;
    }

    return !this.isUserInProgram(program);
  }

  canUserPauseProgram(program: IUserProgram): boolean {
    if (!program) {
      return false;
    }

    return this.isUserProgramInProgress(program);
  }

  canUserResumeProgram(program: IUserProgram): boolean {
    if (!program) {
      return false;
    }

    return this.isUserProgramPaused(program);
  }

  calculateUserProgramProgress(program: IUserProgram): number {
    if (!program || !program.duration) {
      return 0;
    }
    if (program.completed) {
      return 100;
    }

    let numberDays: number;

    if (this.isUserProgramPaused(program)) {
      numberDays = program.cumulativePrePausedDays;
    } else {
      numberDays = this.dateService.daysDifference(program.userProgramStartDate);
      if (program.lastResumedDate) {
        numberDays =
          program.cumulativePrePausedDays +
          this.dateService.daysDifference(program.lastResumedDate);
      }
    }
    numberDays = Math.min(numberDays, program.duration);
    if (this.dateService.isAfterToday(program.userProgramStartDate)) {
      return Math.round((0 / program.duration) * 100);
    } else {
      numberDays = numberDays >= 1 ? numberDays : 1;
      return Math.round((numberDays / program.duration) * 100);
    }
  }

  getFrequencyText(numberDays: number): string {
    if (!numberDays) {
      return '';
    }

    if (numberDays === 1) {
      return 'every day';
    }

    return `every ${numberDays} days`;
  }

  getProgramWelcomeMessage(program: IUserProgram): string {
    if (!program) {
      return '';
    }
    const contentFrequency = this.getFrequencyText(program.contentNotPeriod);
    const lifeActionsFrequency = this.getFrequencyText(program.indicatorNotPeriod);
    const middleSentence = program.indicatorIds.length ? `We will send you exclusive new content ${contentFrequency} and ` +
      `new LifeActions to incorporate into your routine ${lifeActionsFrequency}. ` : `We will send you exclusive new content ${contentFrequency}. `

    return `You have joined the ${program.title} program. ` +
      middleSentence +
      `If you would like to stop these notifications at any time, ` +
      `please uncheck notications at the bottom of the program page.`;
  }

  getAvailablePrograms(): Observable<IProgram[]> {
    const path = '/cms/programs/available';
    return this.http.get<IProgram[]>(path);
  }

}
