import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { PageService } from '../../../services/page-service';
import { ArticleService } from '../../../services/article-service';
import { UtilService } from '../../../services/util-service';
import { UserService } from '../../../services/user-service';
import { IPage } from '../../_models/page';
import { LANGUAGES } from '../../_shared/enums';

@Component({
  selector: 'marketing-page-management',
  templateUrl: './marketing-page-management.component.html',
  styleUrls: ['./marketing-page-management.component.scss'],
})
export class MarketingPageManagementComponent implements OnInit {
  item: IPage;
  itemType = 'App Marketing Links';
  pageType = 'marketing-page';
  itemForm: FormGroup;
  isInitialised: boolean;
  canRespondToButtons: boolean;
  preview = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private pageService: PageService,
    private itemService: ArticleService,
    public userService: UserService,
    private fb: FormBuilder,
    private utilService: UtilService,
  ) {}

  ngOnInit() {
    this.isInitialised = false;
    this.canRespondToButtons = false;
    this.initialiseEditItem();
  }

  private initialiseEditItem(): void {
    this.pageService.getPage(this.pageType, LANGUAGES.en, 0).subscribe((data) => {
      if (data.code && data.code === 404) {
        this.item = this.getNewPage();
      } else {
        this.item = data;
      }
      this.initialiseEditableFormGroup(this.item);
    });
  }

  private initialiseEditableFormGroup(item: IPage) {
    const data = JSON.parse(item.data);
    this.itemForm = this.fb.group({
      googlePlayURL: [data.googlePlayURL, Validators.required],
      appStoreURL: [data.appStoreURL, Validators.required],
      contactUsURL: [data.contactUsURL, Validators.required],
    });

    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  // showPreview() {
  //   if (this.itemForm.valid) {
  //     const item = this.itemForm.value;
  //     item.isArticle = true;
  //     const previewModal = this.modalService.open(ContentDetailComponent, { size: 'lg' });
  //     previewModal.componentInstance.previewContent = item;
  //   } else {
  //     this.validateAllFormFields(this.itemForm);
  //   }
  // }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  private updateItem() {
    this.canRespondToButtons = false;
    let data = {
      googlePlayURL: this.itemForm.get('googlePlayURL').value,
      appStoreURL: this.itemForm.get('appStoreURL').value,
      contactUsURL: this.itemForm.get('contactUsURL').value,
    };
    let page: IPage = {
      title: '',
      image: '',
      longText: '',
      type: this.pageType,
      data: JSON.stringify(data),
    };

    this.pageService.updatePage(page).subscribe(
      () => {
        this.canRespondToButtons = true;
        const msg = `Updated ${this.itemType}`;
        this.utilService.showToastSuccess(msg);
      },
      (error) => {
        const errorMsg = `Error while saving item - ${error}`;
        this.utilService.showToastError(errorMsg);
        this.canRespondToButtons = true;
      },
    );
  }

  getNewPage(): IPage {
    return {
      id: 0,
      title: '',
      longText: null,
      image: '',
      type: '',
      data: JSON.stringify({
        googlePlayURL: '',
        appStoreURL: '',
        contactUsURL: '',
      }),
    };
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }
    this.updateItem();
  }
}
