import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactComponent } from './contact/contact.component';
import { Router } from '@angular/router';
import { PrivacyComponent } from '../../app/_shared/components/privacy/privacy.component';
import { TermsComponent } from '../../app/_shared/components/terms/terms.component';
import { PageDetailComponent } from '../../app/_shared/components/page-detail/page-detail.component';
import { takeUntil } from 'rxjs/operators';
import { MarketingPageService } from '../../services/marketing-page-service';
import { UnSubscribeComponent } from '../../app/_shared/un-subscribe.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends UnSubscribeComponent implements OnInit {
  footerItems = [
    { title: 'Acknowledgement of Country', component: PageDetailComponent, url: ['page/acknowledgement-of-country'] },
    { title: 'Privacy Policy', component: PrivacyComponent, url: 'https://altius-group.com.au/privacy-policy/' },
    { title: 'Terms & Conditions', component: TermsComponent, url: ['terms-conditions'] },
    { title: 'Contact Us', component: ContactComponent, isOpensModal: true },
    { title: `v${this.userService.getAppVersion()}` },
  ];
  appVersion: string;

  constructor(
    private userService: UserService,
    private modalService: NgbModal,
    private router: Router,
    private marketingPageService: MarketingPageService,
  ) {
    super();
  }

  ngOnInit() {
    this.appVersion = this.userService.getAppVersion();
    const isMarketingPage = this.marketingPageService.isMarketingPage();
    if (isMarketingPage) {
      this.marketingPageService.data
        .pipe(takeUntil(this.unSubscribeOnDestroy))
        .subscribe(res => {
            if (res.contactUsURL) {
              let contactUsItem = this.footerItems.find(item => item.title === 'Contact Us');
              contactUsItem.isOpensModal = false;
              contactUsItem.url = res.contactUsURL;
            }
          },
        );
    }
  }

  getNavClasses(): string {
    const classes: string[] = [];

    if (this.userService.isFiitr) {
      classes.push('custom-footer');
    } else {
      classes.push('navbar');
      classes.push('bg-light');
    }

    return classes.join(' ');
  }

  getUlClasses(): string {
    const classes: string[] = [];

    if (this.userService.isFiitr) {
      classes.push('fiitr-footer-list');
    }

    return classes.join(' ');
  }

  open(item: { title: string, component, url?: string[], isOpensModal?: boolean }) {
    if (!item) {
      return;
    }

    if (item.isOpensModal) {
      this.modalService.open(item.component);
      return;
    }

    if (item.url.indexOf('http') !== -1) {
      // @ts-ignore
      window.location.href = item.url;
    } else {
      this.router.navigate(item.url);
    }
  }
}
