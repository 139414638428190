import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DateValidator } from '../../../validators/date-validator';
import { Router } from '@angular/router';
import {
  FormArray,
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocatorService } from '../../../services/locator-service';
import { UserService } from '../../../services/user-service';
import { UtilService } from '../../../services/util-service';
import { PrivacyComponent } from '../../_shared/components/privacy/privacy.component';
import {
  PrivacyCollectionStatementComponent } from '../../_shared/components/privacy-collection-statement/privacy-collection-statement.component';

@Component({
  selector: 'app-child-care-locator',
  templateUrl: './child-care-locator.component.html',
  styleUrls: ['./child-care-locator.component.scss']
})
export class ChildCareLocatorComponent implements OnInit {
  form: FormGroup;
  days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  noOfKids: any;

  constructor(
    private formBuilder: FormBuilder,
    private locatorService: LocatorService,
    private utilService: UtilService,
    public dateValidator: DateValidator,
    private router: Router,
    private modalService: NgbModal,
    private userService: UserService,
  ) {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      address: ['', Validators.required],
      suburb: ['', Validators.required],
      state: ['', Validators.required],
      postcode: ['', Validators.compose([Validators.required])],
      contactNo: ['', Validators.required],
      email: ['', Validators.required],
      noOfChildren: ['', Validators.required],
      child1DOB: [''],
      child2DOB: [''],
      child3DOB: [''],
      child4DOB: [''],
      child5DOB: [''],
      child6DOB: [''],
      location: [''],
      distance: [''],
      homeOrWork: [''],
      otherOptions: [''],
      additInfo: [''],
      enrolmentDate: ['', [dateValidator.dateMoreThanNow.bind(dateValidator)]],
      contactedCentres: [''],
      preferredCentres: [''],
      vetoCentres: [''],
      days: this.formBuilder.array([]),
      flexibility: [''],
      hours: [''],
      cook: [''],
      termsAndCondits: [false, Validators.requiredTrue],
      company: this.userService.company.id,
    });
  }

  ngOnInit() {}

  submit() {
    const formValue = this.form.value;
    formValue.type = 'childcare';
    if (formValue.child1DOB) {
      formValue.child1DOB = moment([
        formValue.child1DOB.year,
        formValue.child1DOB.month - 1,
        formValue.child1DOB.day
      ]).format('YYYY-MM-DD');
    }
    if (formValue.child2DOB) {
      formValue.child2DOB = moment([
        formValue.child2DOB.year,
        formValue.child2DOB.month - 1,
        formValue.child2DOB.day
      ]).format('YYYY-MM-DD');
    }
    if (formValue.child3DOB) {
      formValue.child3DOB = moment([
        formValue.child3DOB.year,
        formValue.child3DOB.month - 1,
        formValue.child3DOB.day
      ]).format('YYYY-MM-DD');
    }
    if (formValue.child4DOB) {
      formValue.child4DOB = moment([
        formValue.child4DOB.year,
        formValue.child4DOB.month - 1,
        formValue.child4DOB.day
      ]).format('YYYY-MM-DD');
    }
    if (formValue.child5DOB) {
      formValue.child5DOB = moment([
        formValue.child5DOB.year,
        formValue.child5DOB.month - 1,
        formValue.child5DOB.day
      ]).format('YYYY-MM-DD');
    }
    if (formValue.child6DOB) {
      formValue.child6DOB = moment([
        formValue.child6DOB.year,
        formValue.child6DOB.month - 1,
        formValue.child6DOB.day
      ]).format('YYYY-MM-DD');
    }
    formValue.enrolmentDate = moment([
      formValue.enrolmentDate.year,
      formValue.enrolmentDate.month - 1,
      formValue.enrolmentDate.day
    ]).format('YYYY-MM-DD');
    this.locatorService
      .sendLocatorEnquiry('childcare', formValue)
      .subscribe(res => {
        if (res.success === true) {
          this.utilService.showInfo(
            'Thank you for your enquiry. Someone from the Seventeenhundred team will be in touch within 48 hours.',
            2,
            'check'
          );
          this.form.reset();
          this.router.navigate(['/toolkit/locators']);
        } else {
          this.utilService.showInfo(
            'Something went wrong. Please try to submit your enquiry again later.',
            1,
            'exclamation'
          );
          this.form.reset();
          this.router.navigate(['/toolkit/locators']);
        }
      });
  }

  onChange(day: string, isChecked: boolean) {
    const dayArray = <FormArray>this.form.controls.days;
    if (isChecked) {
      dayArray.push(new FormControl(day));
    } else {
      let index = dayArray.controls.findIndex(x => x.value === day);
      dayArray.removeAt(index);
    }
  }

  validateDate() {
    const now = Date.now();
    let dateString;
    let day =
      this.form.controls.dayOfEnrolment.value < 10
        ? '0' + this.form.controls.dayOfEnrolment.value
        : this.form.controls.dayOfEnrolment.value;
    const month =
      this.form.controls.monthOfEnrolment.value < 10
        ? '0' + this.form.controls.monthOfEnrolment.value
        : this.form.controls.monthOfEnrolment.value;
    const year = this.form.controls.yearOfEnrolment.value;
    dateString = day + '/' + month + '/' + year;
    const asDate = Date.parse(dateString);
    if (asDate >= now) {
    } else {
    }
  }

  dateMoreThanNow(enrolmentDate: string) {
    return (): { [key: string]: any } => {
      if (this.form) {
        const f = this.form.controls[enrolmentDate];
        const date = moment().format('YYYY-MM-DD');
        if (f.value > date) {
          return {};
        }
        return {
          dates: 'Date should be after today'
        };
      }
    };
  }

  openPolicy(policy) {
    if (policy === 'privacy') {
      this.modalService.open(PrivacyComponent);
    } else {
      this.modalService.open(PrivacyCollectionStatementComponent);
    }
  }
}
