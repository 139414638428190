<div class="modal-header">
  <h3>Welcome</h3>

  <button type="button" class="close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="p-3">
  <div [innerHTML]="domSanitizer.bypassSecurityTrustHtml(companyWelcomeTextHtml)">
  </div>

  <a class="hyperlink primaryColor"
    (click)="triggerTutorialAfterWelcome()">Let’s get started.</a>

  <div class="mt-5" *ngIf="companyWelcomeTextLink">
    <p *ngIf="companyWelcomeTextLink.description"  [innerHTML]="domSanitizer.bypassSecurityTrustHtml(companyWelcomeTextLink.description)">
    </p>
    <a *ngIf="canShowWelcomeTextLink"
      class="hyperlink primaryColor"
      (click)="openWelcomeTextLink()">{{companyWelcomeTextLink.label}}</a>
  </div>
</div>
