import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BOOKING_CODE_FORMAT } from '../../formats';

const MAX_LENGTH = 7;

@Component({
  selector: 'app-booking-code',
  templateUrl: './booking-code.component.html',
  styleUrls: ['./booking-code.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => BookingCodeComponent),
    },
  ],
})
export class BookingCodeComponent implements OnInit, ControlValueAccessor {
  @Input() form: FormGroup;
  @Input() formControlName: string;
  @Input() cssClass: object;
  @Output() keyUpEvent = new EventEmitter<any>();
  @Output() lostFocusEvent = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {

  }

  onKeyUp() {
    const control = this.form.get(this.formControlName);
    if (control.value.length >= MAX_LENGTH - 1 && control.value.indexOf('-') === -1) {
      control.setValue(control.value.substr(0, 3) + '-' + control.value.substr(3, 3));
    }
    this.keyUpEvent.emit({
      hasError: this.isInvalid(),
    });
  }

  onLostFocus() {
    this.lostFocusEvent.emit({
      hasError: this.isInvalid(),
    });
  }

  isInvalid() {
    return !BOOKING_CODE_FORMAT.test(this.form.get(this.formControlName).value);
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
  }
}
