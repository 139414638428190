<div class="pb-3">
  <h2 class="mt-2">Resources</h2>
  <p *ngIf="resources.length > 0">Click on any of the print-friendly resources below for information and support.</p>
  <p *ngIf="resources.length === 0">No resources</p>
  <div class="card w-100 p-3 mt-3 cursorHand d-inline-block" *ngFor="let item of resources" (click)="openFile(item.file)">
    <div class="row">
      <div class="col-12 col-sm-3 text-center">
        <img class="packImg" [src]="getPath(item.image)" />
      </div>
      <div class="col-12 col-sm-9">
        <h5 class="card-title primaryColor">{{item.title}}</h5>
        <p class="card-text" [innerHTML]="item.description"></p>
      </div>
    </div>
  </div>
</div>