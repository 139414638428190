import { UserService } from '../../../../services/user-service';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-menu-card',
  templateUrl: './menu-card.component.html',
  styleUrls: ['./menu-card.component.scss']
})
export class MenuCardComponent implements OnInit {
  @Input('features') features;
  @Input('discover') discover = false;
  @Input('defaultColumns') defaultColumns: any;
  @Input('drivers') drivers: any;
  @Input('subFilterCards') subFilterCards;

  constructor(
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit() {
    if (this.features) {
      const indexesToRemove = [];
      this.features.filter((feature, index) => {
        if (!this.hasFeature(feature)) {
          indexesToRemove.push(index);
        }
      });
      _.pullAt(this.features, indexesToRemove);
      _.pullAt(this.subFilterCards, indexesToRemove);
      _.pullAt(this.drivers, indexesToRemove);
      _.pullAt(this.defaultColumns, indexesToRemove);

    } else {
      // when it's pre-login, the features are different per CARD, not per subFilterCard group
      this.features = [];
    }
  }

  openPage(route, featureId?, category?, cardFeature?) {
    if (category && (featureId || cardFeature)) {
      const feature = featureId ? featureId : cardFeature;
      this.router.navigate([route, feature, category]);
    } else {
      this.router.navigate([route]);
    }
  }

  hasFeature(num) {
    if (this.userService.company) {
      return this.userService.showFeature(num, this.userService);
    } else {
      return true;
    }
  }
}
