import { Component, OnInit } from '@angular/core';
import { InfoPacksService } from '../../../services/infopacks-service';
import { LogService } from '../../../services/log.service';
import { UserService } from '../../../services/user-service';
import { IInfoPack } from '../../_models/info-pack';

@Component({
  selector: 'app-infopacks',
  templateUrl: './infopacks.component.html',
  styleUrls: ['./infopacks.component.scss']
})
export class InfoPacksComponent implements OnInit {
  infopacks: IInfoPack[] = [];
  companyName: string;

  constructor(
    private infoPacksService: InfoPacksService,
    private userService: UserService,
    private logService: LogService
  ) { 
    this.companyName = this.userService.company.name;
  }

  ngOnInit() {
    this.getInfoPacks();
    this.logService.logToolkit();
  }

  getInfoPacks() {
    this.infoPacksService.getInfoPacksForMyCompany(this.userService.profile.lang)
      .subscribe((data: IInfoPack[]) => {
          this.infopacks = data;
        },
        err => {
          console.log(err);
        }
      );
  }

  openFile(file) {
    const url = this.userService.getInfoPackFile(file);
    window.open(url, '_blank');
  }

  getPath(image) {
    return encodeURI(this.userService.getInfoPackImage(image));
  }
}
