import { Component, OnInit } from '@angular/core';
import { UnSubscribeComponent } from '../../_shared/un-subscribe.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompanyService } from '../../../services/company-service';
import { UserService } from '../../../services/user-service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-company-summary',
  templateUrl: './company-summary.component.html',
  styleUrls: ['./company-summary.component.scss'],
})
export class CompanySummaryComponent extends UnSubscribeComponent implements OnInit {
  isInitialised: boolean;
  itemForm: FormGroup;
  logo: string;
  companyId: number;
  hasInsights: boolean;
  marketingBrochureUrl: string;
  marketingPosterUrl: string;
  bookingPosterUrl: string;
  leapIntegration: boolean;
  postersAndBrochuresUrl: string;

  constructor(
    private companyService: CompanyService,
    private userService: UserService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isInitialised = false;

    this.activatedRoute.params.subscribe((route) => {
      this.companyId = route.id ? +route.id : this.userService.company.id;
      const urlParam = route.id ? '/' + route.id : '';
      this.marketingBrochureUrl = `/cms/marketing-brochure${urlParam}`;
      this.marketingPosterUrl = `/cms/marketing-poster${urlParam}`;
      this.bookingPosterUrl = `/cms/booking-poster${urlParam}`;
      this.postersAndBrochuresUrl = `/cms/posters-brochures${urlParam}`;
      this.companyService.getSummary(this.companyId).subscribe((res) => {
        this.logo = this.userService.getLogoForCompany(res.logo);
        this.hasInsights = res.hasInsights;
        this.leapIntegration = res.leapIntegration;
        this.itemForm = this.fb.group({
          nameControl: [res.name],
          domainControl: [res.hostname],
          registrationCodeControl: [res.registerCode],
          insightsDomainControl: [res.insightsDomain],
          bookingLinkControl: [res.data ? JSON.parse(res.data).url : ''],
        });
        this.isInitialised = true;
      });
    });
  }

  onEditClick() {
    this.router.navigate(['/cms/companies/', this.companyId]);
  }

  canShowEditButton() {
    return this.userService.isSuperAdminUser() || this.userService.isAdminUser();
  }

  getEditButtonText() {
    return this.userService.isSuperAdminUser() ? 'EDIT COMPANY DETAILS' : 'VIEW COMPANY DETAILS';
  }

  onOpenPostersAndBrochuresLibrary() {
    this.router.navigate([this.postersAndBrochuresUrl]);
  }
}
