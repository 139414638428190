<div class='content-background' *ngIf='isDataAvailable'>
  <div>
    <div class='card-img-top-div img-size-div' *ngIf='item.image'>
      <img class='img-size' [src]='getImagePath(item)' alt=''>
    </div>
    <div class='main-content p-3'>
      <div class='headline'>
        <div class='row'>
          <div class='col-12' *ngIf='item.title'>
            <h1>{{item.title }}</h1>
          </div>
        </div>
        <div class='fr-view longText mt-4' [innerHTML]='domSanitizer.bypassSecurityTrustHtml(item.longText)'></div>
      </div>
    </div>
  </div>
</div>
