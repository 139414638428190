import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GroupService } from '../../services/group-service';
import { BadgeService } from '../../services/badge-service';
import { UserService } from '../../services/user-service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {

  defaultColumns = [];
  subFilterCards = [];
  features = [15, 17, 19, 95];

  constructor(
    public activatedRoute: ActivatedRoute,
    private badgeService: BadgeService,
    private groupService: GroupService,
    public userService: UserService,
  ) {
  }

  ngOnInit() {
    this.defaultColumns = [1, 1, 1];
    this.subFilterCards = [
      [{
        title: 'Leaderboard',
        img: '../../assets/img/leaderboard.wide.png',
        route: '/share/leaderboard',
      }],
      [{
        title: 'Friends',
        img: '../../assets/img/friends.wide.png',
        route: '/share/friends',
      }], [{
        title: 'Groups',
        img: '../../assets/img/groups.wide.png',
        route: '/share/groups',
      }]];
    this.groupService.refreshLatestGroupActivity();
    this.badgeService.refreshShortLeaderboard(
      this.userService.company.id,
      this.userService.user.userProfile.id,
    );
    // console.log(this.activatedRoute);
  }
}
