import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPage } from '../app/_models/page';

export interface IPageResponse {
  success?: boolean;
  pageExistedError?: boolean;
  err?: any;
}

@Injectable({
  providedIn: 'root',
})
export class PageService {
  constructor(private http: HttpClient) {
  }

  getAllPages(type: string) {
    return this.http.get<any>(`/cms/pages/${type}`);
  }

  getPage(type: string, lang: string, companyId: number) {
    return this.http.get<any>(`/cms/pages/${type}/${lang}/${companyId}`);
  }

  addPage(body) {
    return this.http.post<any>(`/cms/pages/${body.type}`, body);
  }

  updatePage(body) {
    return this.http.put<any>(`/cms/pages/${body.type}`, body);
  }

  deletePage(id) {
    return this.http.delete<any>(`/cms/pages/${id}`);
  }

  getNewPage(): IPage {
    return {
      id: 0,
      title: '',
      longText: null,
      image: '',
      type: '',
      languageVersion: '[]',
    };
  }
}
