<div class="row">
  <div *ngFor="let plan of plans; let i = index"
    class="col-12 col-plan">
    <div class="card"
      (click)="selectedPlanOnClick ? deselectPlan($event, plan) : onSelectPlan($event,plan)"
      [style.color]="getColor(plan)">
      <div
        class="card-header"
        [style.backgroundColor]="getHeaderBackgroundColor(plan)">
        <div class="plan-type">{{plan.type}}</div>
        <div class="price-details">{{plan.priceDetails}}</div>
        <div *ngIf="!plan.isSelected"
          class="select-plan">Select</div>
      </div>
      <div *ngIf="plan.benefits"
        class="card-body"
        [style.backgroundColor]="getBodyBackgroundColor(plan)">
        <div *ngFor="let benefit of plan.benefits">
          + {{benefit}}
        </div>
      </div>
    </div>
  </div>
</div>
