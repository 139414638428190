<div class="input-group">
  <input
    id="hours-input"
    type="number"
    class="form-control"
    (change)="onChangeHours()"
    [(ngModel)]="hours">
  <div class="input-group-append">
    <label class="mx-2" >:</label>
  </div>
  <input
    id="mins-input"
    type="number"
    class="form-control"
    (change)="onChangeMins()"
    [(ngModel)]="mins">
  </div>
