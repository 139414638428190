import { LifeActionService } from '../../../../../services/life-action-service';
import { IIndicator } from '../../../../_models/indicator';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-manage-life-action',
  templateUrl: './manage-life-action.component.html',
  styleUrls: ['./manage-life-action.component.scss'],
})
export class ManageLifeActionComponent implements OnInit {
  @Input('indicator') indicator: IIndicator;
  @Input('index') index;
  recurrenceString: string;
  testId = 'new11';
  @Output()
  changeActivationStatus: EventEmitter<any> = new EventEmitter<{}>();

  constructor(private lifeActionService: LifeActionService) {
  }

  ngOnInit() {
    this.formatRecurrenceIntegerToString(this.indicator.recurrence);
  }

  formatRecurrenceIntegerToString(recurrence) {
    this.recurrenceString = this.lifeActionService.formatRecurrenceIntegersAsStrings(recurrence);
  }

  activate(body) {
    body.indicatorId = this.indicator.id;
    body.index = this.index;
    body.active = true;
    this.changeActivationStatus.emit(body);
  }

  deactivate() {
    const body = {
      indicatorId: this.indicator.id,
      index: this.index,
      active: false,
      userLifeActionsActivationId: this.indicator.userLifeActionsActivationId,
    };
    this.changeActivationStatus.emit(body);
  }
}
