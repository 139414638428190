import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Location, LocationStrategy } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material';
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { UtilService } from '../../../../services/util-service';
import { UserService } from '../../../../services/user-service';
import { IPage } from '../../../_models/page';
import { PageService } from '../../../../services/page-service';
import { LANGUAGES } from '../../enums';

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

@Component({
  selector: 'app-page-detail',
  templateUrl: './page-detail.component.html',
  styleUrls: ['./page-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_SELECT_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
  ],
})
export class PageDetailComponent implements OnInit {
  dataType: string;
  item: IPage;
  isDataAvailable = false;
  contentType;

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    public domSanitizer: DomSanitizer,
    private utilService: UtilService,
    private breadcrumbService: BreadcrumbService,
    private locationStrategy: LocationStrategy,
    private location: Location,
    private pageService: PageService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params
      .subscribe(route => {
        if (route.type === 'acknowledgement-of-country') {
          this.pageService.getPage('acknowledgement_of_country', LANGUAGES.en, 0).subscribe((data) => {
              if (data && data.code !== 404) {
                this.item = data;
                this.isDataAvailable = true;
              }
            },
          );
        }
      });
  }

  getImagePath(item: IPage): string {
    return this.userService.getArticleImage(item.image);
  }
}
