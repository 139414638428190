import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../../services/util-service';
import { UserService } from '../../../services/user-service';
import { DeviceService } from '../../../services/device-service';
import { ActivatedRoute, Router } from '@angular/router';
import { LogService } from '../../../services/log.service';

@Component({
  selector: 'app-device-page',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {
  isLoggedIn = false;

  constructor(
    private router: Router,
    public utilService: UtilService,
    public userService: UserService,
    private deviceService: DeviceService,
    private logService: LogService,
    public activatedRoute: ActivatedRoute
  ) {
    this.logService.logDevices();

    // check if it's callback from fitbit
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.success === 'true' && params.device === 'fitbit') {
        setTimeout(() => {
          this.utilService.showToast(
            'success',
            'Your account is now connected to Fitbit.'
          );
        }, 100);
        this.userService.getProfile().subscribe(() => {});
        this.changeUrl();
      } else if (
        params.success === 'false' &&
        params.error === 'failed' &&
        params.device === 'fitbit'
      ) {
        setTimeout(() => {
          this.utilService.showToast(
            'error',
            'We were unable to connect to Fitbit. Please try again later.'
          );
        });
        this.changeUrl();
      } else if (
        params.success === 'false' &&
        params.error === 'exists' &&
        params.device === 'fitbit'
      ) {
        setTimeout(() => {
          this.utilService.showToast(
            'warning',
            `Unable to connect: this Fitbit is already linked to another ${
              this.userService.company.name
            } account`
          );
        });
        this.changeUrl();
      }
      if (params.success === 'true' && params.device === 'googlefit') {
        setTimeout(() => {
          this.utilService.showToast(
            'success',
            'Your account is now connected to Google Fit.'
          );
        }, 100);
        this.userService.getProfile().subscribe(() => {});
        this.changeUrl();
      } else if (
        params.success === 'false' &&
        params.error === 'failed' &&
        params.device === 'googlefit'
      ) {
        setTimeout(() => {
          this.utilService.showToast(
            'error',
            'We were unable to connect to Google Fit. Please try again later.'
          );
        });
        this.changeUrl();
      } else if (
        params.success === 'false' &&
        params.error === 'exists' &&
        params.device === 'googlefit'
      ) {
        setTimeout(() => {
          this.utilService.showToast(
            'warning',
            `Unable to connect: this Google Acount is already linked to another ${
              this.userService.company.name
            } account`
          );
        });
        this.changeUrl();
      } else if (
        params.success === 'false' &&
        params.error === 'cancel' &&
        params.device === 'googlefit'
      ) {
        this.changeUrl();
      }
    });
  }
  ngOnInit() {
    this.isLoggedIn = this.userService.isLoggedIn();
  }

  changeUrl() {
    const currentUrl = window.location.href;
    const newUrl = currentUrl.substring(0, currentUrl.indexOf('?'));
    window.history.replaceState({}, 'devices', newUrl);
  }
  connectToFitbit() {
    this.deviceService.getFitbitAuthorization('/act/devices', null);
  }

  disconnectToFitbit() {
    this.deviceService.fitbitDisconnect().subscribe(() => {
      this.utilService.showToast(
        'success',
        'Your account is now disconnected from Fitbit'
      );
      this.router.navigateByUrl('act/devices');
    });
  }

  connectToGoogleFit() {
    this.deviceService.getGoogleFitAuthorization('/act/devices', null);
  }

  disconnectFromGoogleFit() {
    this.deviceService.revokeGoogleFitAuthorization().subscribe(() => {
      this.utilService.showToast(
        'success',
        'Your account is now disconnected from Google Fit'
      );
      this.router.navigateByUrl('act/devices');
    });
  }

  hasFeature(num) {
    if (this.userService.company) {
      return this.userService.showFeature(num, this.userService);
    } else {
      return true;
    }
  }
}
