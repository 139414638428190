import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocatorService {
  constructor(private http: HttpClient) { }

  sendLocatorEnquiry(type, data) {
    const url = `/locators/${type}`;
    return this.http.post<any>(url, data);
  }
}
