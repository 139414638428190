import { Component, Input, OnInit } from '@angular/core';
import { CarouselService } from '../../services/carousel-service';
import { Router } from '@angular/router';
import { LogService } from '../../services/log.service';
import { UserService } from '../../services/user-service';
import { UtilService } from '../../services/util-service';
import { AuthRoutePath } from '../_models/auth';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  carousels: any = [];
  @Input() landing;
  @Input() previewItem;

  constructor(
    private carouselService: CarouselService,
    private router: Router,
    private logService: LogService,
    private userService: UserService,
    private utilService: UtilService,
  ) {
  }

  ngOnInit() {
    let visibility = 'guest';
    if (this.previewItem) {
      this.carousels = this.previewItem;
      this.carousels[0].first = true;
      this.carousels = this.setImageAccordingToScreenSize(this.carousels);
    } else if (this.landing) {
      this.carousels = this.landing;
      this.carousels[0].first = true;
      this.carousels = this.setImageAccordingToScreenSize(this.carousels);
    } else {
      if (this.userService.isLoggedIn()) {
        visibility = 'user';
      }
      this.carouselService.loadCarousel(visibility).subscribe(
        (data) => {
          this.carousels = data;
          if (this.carousels.length > 0) {
            this.carousels = this.setImageAccordingToScreenSize(this.carousels);
            this.carousels[0].first = true;
          }
        },
        (err) => {
          console.log(err);
        },
      );
    }
  }

  openCarousel(obj) {
    if (obj.visibility === 0) {
      this.router.navigateByUrl(AuthRoutePath.login);
    } else {
      if (obj.contentType === 0 && this.utilService.isValidURL(obj.linkUrl)) {
        window.open(obj.linkUrl, '_blank');
      } else {
        this.router.navigate(['/' + obj.linkUrl]);
      }
    }

    if (this.userService.isLoggedIn()) {
      this.logService.logCarousel(obj.id);
    }
  }

  setImageAccordingToScreenSize(carousels) {
    carousels.forEach(c => {
      c.chosenImage = c.image;
      if (this.utilService.isMobileDeviceScreenWidth(screen.width)
        && c.mobileImage) {
        c.chosenImage = c.mobileImage;
      }
    });

    return carousels;
  }

  getCarouselImage(image: string) {
    return this.userService.getCarouselImage(image);
  }
}
