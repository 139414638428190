import { Injectable } from '@angular/core';

interface CacheContent {
  expiry: number;
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export class SynchronousCacheService {
  public readonly FeaturesKey = 'features';

  private cache: Map<string, CacheContent> = new Map<string, CacheContent>();
  private refreshingCache: string[] = [];

  readonly DEFAULT_MAX_AGE: number = 300000;

  get (key: string): any {
    if (!this.has(key)) { return null; }

    return this.cache.get(key).value;
  }

  set (key: string, value: any, maxAge: number = this.DEFAULT_MAX_AGE): void {
    this.cache.set(key, { value, expiry: Date.now() + maxAge });
  }

  has (key: string): boolean {
    return this.cache.has(key);
  }

  isExpired(key: string): boolean {
    return (this.cache.get(key).expiry < Date.now());
  }

  isRefreshingCache(key: string) {
    return this.refreshingCache.indexOf(key) > -1;
  }

  beginRefreshingCache(key: string) {
    if (this.isRefreshingCache(key)) { return; }

    this.refreshingCache.push(key);
  }

  cacheRefreshed(key: string) {
    const index = this.refreshingCache.indexOf(key);
    if (index < 0) { return; }

    this.refreshingCache.splice(index, 1);
  }
}
