<div class="modal-header">
  <h4 class="modal-title">{{indicator.logTitle}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="onClickClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="md-container">
    <div class="mb-2">
      <b>Current goal: {{indicator.goalDisplay}}</b>
    </div>
    <div *ngIf="service.isLoggedValues(); else noLoggedValues">
      <div class="row">
        <b class="col-md-6">{{service.lastEnteredNValuesLabel}}</b>
        <div *ngIf="service.canToggleDisplayedValues()" class="col-md-6">
          <a class="show-n-values float-right" (click)="service.toggleDisplayedValues()">
            {{service.showLastEnteredValuesText}}
          </a>
        </div>
      </div>

      <div class="row my-1">
        <table class="table table-bordered table-striped w-100 m-3">
          <thead>
            <tr>
              <th>Date</th>
              <th class="text-right">{{valuesTitle}}</th>
              <th><i
                  placement="left"
                  ngbTooltip="Achieved your goal!"
                  class="fa fa-check"></i></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let loggedValue of service.displayValues">
              <td>{{loggedValue.displayDate}}</td>
              <td *ngIf="!loggedValue.isEditing" class="text-right">{{display(loggedValue.value)}}</td>

              <td *ngIf="loggedValue.isEditing">
                <input *ngIf="!isDisplayHrsMins" class="form-control min-width-input" type="number" [(ngModel)]="loggedValue.value"  (ngModelChange)="onChangeInput($event, loggedValue)"
                  min="0">

                <app-hours-minutes-entry *ngIf="isDisplayHrsMins" class="col-5" [minutes]="loggedValue.value"
                  (onTotalMinutesEntered)="onTotalMinutesEntered($event, loggedValue)"></app-hours-minutes-entry>
              </td>
              <td>
                <i *ngIf="loggedValue.achievedThisInterval"
                  placement="left"
                  ngbTooltip="Achieved your goal!"
                  class="fa fa-check"></i>
              </td>
              <td>
                <button *ngIf="!loggedValue.isEditing" (click)="onClickBeginEditing(loggedValue)" class="btn">
                  <i class="fa fa-edit"></i>
                </button>

                <button *ngIf="loggedValue.isEditing" (click)="onClickSaveEditing(loggedValue)" class="btn">
                  <i class="fa fa-save"></i>
                </button>
              </td>
              <td>
                <button *ngIf="loggedValue.isEditing" class="btn" (click)="onClickUndoEditing(loggedValue)">
                  <i class="fa fa-undo"></i>
                </button>

                <button *ngIf="!loggedValue.isEditing" class="btn" (click)="onClickDelete(loggedValue)">
                  <i class="fa fa-remove"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <ng-template #noLoggedValues>
      <div class="pb-4">
        <b>No previously entered values</b>
      </div>
    </ng-template>

    <div class="row can-add-day-buttons d-none d-sm-block">
      <div class="col-12">
        <button *ngIf="service.canAddNextDay()" type="button" class="btn btn-sm btn-outline-primary" (click)="onClickAddNextDay()">Next
          Day</button>
        <button *ngIf="service.canAddYesterday()" type="button" class="btn btn-sm btn-outline-primary" (click)="onClickAddYesterday()">Yesterday</button>
        <button *ngIf="service.canAddToday()" type="button" class="btn btn-sm btn-outline-primary" (click)="onClickAddToday()">Today</button>
      </div>
    </div>

    <!-- Add/ Edit Row-->
    <div class="my-3 row">
      <label class="col-12">
        <b>{{addDateIndicator}}</b>
      </label>
      <div class="input-group col-6">
        <input
          class="form-control"
          [(ngModel)]="addDateYmd"
          (ngModelChange)="onChangeDate()"
          navigation="arrows"
          [maxDate]="maxCalendarDateYmd"
          placement="['right', 'top']"
          [showWeekdays]="true"
          ngbDatepicker #d="ngbDatepicker">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
            <i class="fa fa-calendar"></i>
          </button>
        </div>
      </div>

      <input *ngIf="!isDisplayHrsMins"
        type="number"
        class="form-control col-4"
        min="0"
        #addValueInput
        (ngModelChange)="onChangeValue()"
        [(ngModel)]="addValue">

      <app-hours-minutes-entry *ngIf="isDisplayHrsMins"
        class="col-4"
        [minutes]="addValue"
        (onTotalMinutesEntered)="onTotalMinutesEntered($event)"
      ></app-hours-minutes-entry>

      <button (click)="onClickAddValue()" class="form-control btn col-sm-1 col-2">
        <i class="fa fa-save"></i>
      </button>
      <div *ngIf="invalidAddEntryMessage" class="col-12 invalid-add-value">
        {{invalidAddEntryMessage}}
      </div>
    </div>

  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-outline-primary" (click)="onClickUndoAll()">Undo All</button>
  <button class="btn btn-primary" (click)="onClickSaveAll()">Save All</button>
</div>
