import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { first, map, tap } from 'rxjs/operators';
import * as rxjs from 'rxjs';
import { Observable } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { UtilService } from './util-service';
import { IBadge } from '../app/_models/badge';
import { ILeaderboardItem } from '../app/_models/leaderboard-item';
import { IBadgeCount } from '../app/_models/badge-count';

@Injectable({
  providedIn: 'root'
})
export class BadgeService {

  private currentLatestBadge = new rxjs.BehaviorSubject<any>({});
  currentBadge$ = this.currentLatestBadge.asObservable();

  private leaderboard = new rxjs.BehaviorSubject<any>([]);
  currentLeaderboard = this.leaderboard.asObservable();
  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    public router: Router,
    public activeModal: NgbActiveModal  ) { }

    getGoldBadgeSrc() {
      return'assets/img/badges/badge_gold.svg';
    }

    getSilverBadgeSrc() {
      return'assets/img/badges/badge_silver.svg';
    }

    getBronzeBadgeSrc() {
      return'assets/img/badges/badge_bronze.svg';
    }

  changeLatestBadge(badge: IBadge) {
    this.currentLatestBadge.next(badge);
  }

  getCurrentBadge(): Observable<any> {
    return this.currentBadge$;
  }

  changeCurrentLeaderboard(leaderboard) {
    this.leaderboard.next(leaderboard);
  }

  getCurrentLeaderboard(): Observable<any> {
    return this.currentLeaderboard;
  }

  // TODO: Remove
  getLeaderboard(companyId) {
    const url = `/badges/leaderboard/me`;
    return this.http.get<ILeaderboardItem[]>(url);
  }

  getMyCompanyLeaderboard() {
    const url =  '/badges/leaderboard/me';

    return this.http.get<ILeaderboardItem[]>(url);
  }

  getMyFriendsLeaderboard() {
    const url =  '/badges/leaderboard/friends/me';

    return this.http.get<ILeaderboardItem[]>(url);
  }

  getShortLeaderboard(companyId: number, userProfileId: number)
      : Observable<ILeaderboardItem[]> {
    return this.getLeaderboard(companyId)
      .pipe(
        map((data: ILeaderboardItem[]) => this.toShortLeaderboard(userProfileId, data)),
        tap((wlb: ILeaderboardItem[]) => this.changeCurrentLeaderboard(wlb))
      );
  }

  private toShortLeaderboard(userProfileId: number, lbItems: ILeaderboardItem[]) {
    const MaxItems = 5;
    let position = 1;
    lbItems.forEach((item: ILeaderboardItem) => {
      item.position = position++;
      item.isMe = (item.id === userProfileId);
    });

    const userItem = lbItems.find(i => i.isMe);
    const userPosition = (userItem)
      ? userItem.position
      : -1;

    if (lbItems.length <= MaxItems) {
      return [...lbItems];
    } else if (userPosition <= MaxItems) {
      return [...lbItems.slice(0, MaxItems)];
    } else {
      return  [...lbItems.slice(0, MaxItems - 1), userItem];
    }
  }

  refreshShortLeaderboard(companyId: number, userProfileId: number) {
    this.getShortLeaderboard(companyId, userProfileId)
      .pipe(first())
      .subscribe();
  }

  getUserBadges() {
    const url = `/badges/progress`;
    return this.http.get<any>(url);
  }

  getUserBadgesSummary(userProfileId) {
    const url = `/badges/user-summary/${userProfileId}`;

    return this.http.get<IBadgeCount[]>(url)
    .pipe(tap((counts: IBadgeCount[]) => {
      let gold: IBadgeCount = counts.find(count => count.badgeLevel.toLowerCase() === 'gold');
      if (!gold) {
        gold = {count: 0, badgeLevel: 'gold', image: '' };
        counts.push(gold);
      }
      gold.sortOrder = 1;
      gold.imageSrc = this.getGoldBadgeSrc();

      let silver: IBadgeCount = counts.find(count => count.badgeLevel.toLowerCase() === 'silver');
      if (!silver) {
        silver = {count: 0, badgeLevel: 'silver', image: '' };
        counts.push(silver);
      }
      silver.sortOrder = 2;
      silver.imageSrc = this.getSilverBadgeSrc();

      let bronze: IBadgeCount = counts.find(count => count.badgeLevel.toLowerCase() === 'bronze');
      if (!bronze) {
        bronze = { count: 0, badgeLevel: 'bronze', image: '' };
        counts.push(bronze);
      }
      bronze.sortOrder = 3;
      bronze.imageSrc = this.getBronzeBadgeSrc();

      counts.sort((c1, c2) => c1.sortOrder - c2.sortOrder);
    }));
  }

  loadAllBadges() {
    const url = `/badges`;
    return this.http.get<any>(url);
  }

  getBadgeById(id) {
    const url = `/cms/badges/${id}`;
    return this.http.get<any>(url);
  }

  getAchievedBadges(userProfileId) {
    const url = `/badges/${userProfileId}`;

    return this.http.get<IBadge[]>(url);
  }

  // TODO: Remove redundant method as filtering and sorting in API
  loadAllAchievedUserBadges(userProfileId) {
    const url = `/badges/${userProfileId}`;

    return this.http.get<any>(url).pipe(map((res) => {
      return res
        .filter((item) => {
          return item.achieved === true;
        })
        .sort((item1, item2) => {
          item1 = new Date(item1.achievedDate);
          item2 = new Date(item2.achievedDate);
          return item1 > item2 ? -1 : item1 < item2 ? 1 : 0;
        });
    }));
  }

  deleteBadge(id) {
    return this.http.delete<any>(`/cms/badges/${id}`);
  }

  getLevel() {
    const url = `/badges/all`;

    return this.http.get<any>(url).pipe(map((data) => {
      return this.filterBadgeLevels(data);
    }));
  }

  filterBadgeLevels(data) {
    if (data && (data.badgeProgresses || data.length > 0)) {
      let badgeList: any = [];
      if (data.badgeProgresses) {
        badgeList = data.badgeProgresses;
      } else {
        badgeList = data;
      }
      const gold = _.filter(badgeList, function (i) {
        return i.badge.badgeLevel === 'gold';
      });
      const silver = _.filter(badgeList, function (i) {
        return i.badge.badgeLevel === 'silver';
      });
      const bronze = _.filter(badgeList, function (i) {
        return i.badge.badgeLevel === 'bronze';
      });
      return this.calcLevel(gold.length, silver.length, bronze.length);
    } else {
      return this.calcLevel(0, 0, 0);
    }
  }

  calcLevel(qtdeGold, qtdeSilver, qtdeBronze) {
    const levelInfo: any = {};
    levelInfo.gold = qtdeGold;
    levelInfo.silver = qtdeSilver;
    levelInfo.bronze = qtdeBronze;
    if (qtdeGold > 14 && qtdeSilver > 14 && qtdeBronze > 19) {
      levelInfo.level = 'Master';
    } else if (qtdeSilver > 9 && qtdeBronze > 19) {
      levelInfo.level = 'Expert';
      levelInfo.bronzeNeeded = 20;
      levelInfo.silverNeeded = 15;
      levelInfo.goldNeeded = 15;
    } else if (qtdeSilver > 1 && qtdeBronze > 9) {
      levelInfo.level = 'Apprentice';
      levelInfo.bronzeNeeded = 20;
      levelInfo.silverNeeded = 10;
      levelInfo.goldNeeded = 0;
    } else {
      levelInfo.level = 'Newbie';
      levelInfo.bronzeNeeded = 10;
      levelInfo.silverNeeded = 2;
      levelInfo.goldNeeded = 0;
    }

    return levelInfo;
  }

  getLatestAchievedBadge() {
    const path = `/badges/latest-achieved`;
    return this.http.get<any>(path);
  }

  refreshLatestAchievedBadge() {
    this.getLatestAchievedBadge()
      .pipe(first())
      .subscribe((data: any) => {
        const badge: IBadge = (!!data.length && data[0].badge !== null)
          ? data[0].badge
          : null;
        this.changeLatestBadge(badge);
      });
  }

  save(id, body) {
    if (id !== 0) {
      return this.http.put<any>('/cms/badges/' + id, body);
    } else {
      return this.http.post<any>('/cms/badges', body);
    }
  }

  getNewBadge(): IBadge {
    return {
      id: 0,
      name: '',
      description: '',
      badgeLevel: null,
      feature: null,
      goal: null,
      image: null,
      type: null,
      typeId: null,
      userEdit: true,
      category: null
    };
  }

  updateUserBadges(type: string, typeId?: number) {
    this.checkBadges(type, typeId).subscribe((data) => {
      if (data.achievedBadges && data.achievedBadges.length) {
        data.achievedBadges.forEach((b) => {
          this.utilService.badgeAlert([b]);
        });
      }
    });
  }

  checkBadges(type: string, typeId?: number) {
    const path = `/badges/check-achieved`;
    const body = { type: type, typeId: typeId };
    return this.http.post<any>(path, body);
  }

  getTypeIdOptions(type: string) {
    const url = `/cms/badges/types/${type}`;
    return this.http.get<any>(url);
  }
}
