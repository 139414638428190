import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '../environments/_active-environment/environment';
import { Observable } from 'rxjs';
import { IResponse } from '../app/_models/response.interface';
import {
  ICompanyDto,
  ICompanyRegisterInfo,
  IIsExistedDomain,
} from '../app/_models/company-resgister-info.interface';
import { ICompany } from '../app/_models/company';

@Injectable({
  providedIn: 'root',
})
export class CompanyRegisterService {
  private readonly httpWithoutInterceptor: HttpClient;
  private readonly apiUrl: string;

  constructor(private http: HttpClient, private httpBackend: HttpBackend) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
    this.apiUrl = environment.app.endpoint;
  }

  getCompanyRegisterByEmailAndToken(
    email: string,
    token: string,
  ): Observable<Partial<IResponse<ICompanyRegisterInfo>>> {
    const path = '/company-register';
    return this.httpWithoutInterceptor.get<Partial<IResponse<ICompanyRegisterInfo>>>(
      `${this.apiUrl}${path}`,
      {
        params: { email, token },
      },
    );
  }

  checkDomainIsExistedOrNot(
    domainName: string,
    companyId: string,
  ): Observable<Partial<IResponse<IIsExistedDomain>>> {
    const path = '/companies/is-existed-domain';
    const params = { domainName } as { [key: string]: string };
    if (companyId) {
      params.companyId = companyId;
    }
    return this.httpWithoutInterceptor.get<Partial<IResponse<IIsExistedDomain>>>(
      `${this.apiUrl}${path}`,
      {
        params,
      },
    );
  }

  checkRegisterCode(
    registerCode: string,
    companyId: string,
  ): Observable<Partial<IResponse<IIsExistedDomain>>> {
    const path = '/companies/is-existed-register-code';
    const params = { registerCode } as { [key: string]: string };
    if (companyId) {
      params.companyId = companyId;
    }
    return this.httpWithoutInterceptor.get<Partial<IResponse<IIsExistedDomain>>>(
      `${this.apiUrl}${path}`,
      {
        params,
      },
    );
  }

  createNewCompanyForNewCustomer(data: ICompanyDto) {
    const path = '/company-register';
    return this.httpWithoutInterceptor.post<Partial<IResponse<ICompany>>>(
      `${this.apiUrl}${path}`,
      data,
    );
  }
}
