import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportsService } from '../../../services/reports-service';
import { UserService } from '../../../services/user-service';
import { UtilService } from '../../../services/util-service';
import { TeamService } from '../../../services/team.service';
import { DateService } from '../../../services/date.service';
import { CmsListViewService } from '../cms-list-view/cms-list-view.service';

const SORT_NONE = 0;
const SORT_ASC = 1;
const SORT_DESC = 2;

@Component({
  selector: 'app-teams-reporting',
  templateUrl: './teams-reporting.component.html',
  styleUrls: ['./teams-reporting.component.scss'],
})
export class TeamsReportingComponent implements OnInit {

  isDisplayingList: boolean;
  isDisplayingDetailsList: boolean = false;
  isInitialised: boolean;
  canSelectChallenge: boolean = true;

  startDate: Date;
  endDate: Date;
  canSelectCompany = false;
  challengeId: number;

  // API query variables
  user: any;

  columnList = ['team Id', 'team Name', 'no Of Users', 'total Steps'];
  filteredList: any[] = [];

  detailsColumnList = ['', 'user Name', 'date', 'no Of Steps'];
  detailsFilteredList: any[] = [];

  currentPage = 1;
  sortColumnIndex = -1;
  sortColumnType = SORT_NONE;

  constructor(
    private activatedRoute: ActivatedRoute,
    private reportService: ReportsService,
    private userService: UserService,
    private teamService: TeamService,
    private utilService: UtilService,
    private dateService: DateService,
    private cmsListViewService: CmsListViewService,
  ) {
  }

  ngOnInit() {

    this.setInitialComponentState();
    this.isDisplayingList = true;
    this.applyUserSetValues();
  }

  private setInitialComponentState() {
    this.user = this.userService.user;
    this.startDate = this.reportService.cmsStartDate;
    this.endDate = this.reportService.cmsEndDate;
  }

  onColClick(index: number) {
    this.sortByColumn(index);
  }

  private applyUserSetValues() {

    this.currentPage = this.cmsListViewService.getCurrentPage();

    this.sortColumnIndex = this.cmsListViewService.getSortColumnIndex();
    this.sortColumnType = this.cmsListViewService.getSortColumnType();
    if (this.isColumnSort()) {
      this.applyColumnSort(this.sortColumnIndex);
    }
  }

  private isColumnSort() {
    return this.sortColumnIndex >= 0 && this.sortColumnType !== SORT_NONE;
  }

  private sortByColumn(index: number) {
    //this.setColumnSort(index);
    this.setServiceSortValues();
    this.applyColumnSort(index);
  }

  private setServiceSortValues() {
    this.cmsListViewService.setSortColumnIndex(this.sortColumnIndex);
    this.cmsListViewService.setSortColumnType(this.sortColumnType);
  }

  private applyColumnSort(index: number) {
    const sortMultiplier = this.sortColumnType === SORT_ASC ? 1 : -1;
    if (index == 0 || index == 2 || index == 3) {
      this.filteredList.sort((a, b) => {
        if (+a[index] === +b[index]) {
          return 0;
        } else {
          return (+a[index] < +b[index] ? -1 : 1) * sortMultiplier;
        }
      });
    } else {
      this.filteredList.sort((a, b) => {
        // if (a[index] === b[index]) {
        //   return 0;
        // } else {
        //   return (a[index] < b[index] ? -1 : 1) * sortMultiplier;
        // }
        if (a[index].teamName > b[index].teamName) {
          return 1;
        }

        if (a[index].teamName < b[index].teamName) {
          return -1;
        }

        return 0;
      });
    }
  }

  private setColumnSort(index: number) {
    const currentSortColumnIndex = this.sortColumnIndex;
    const currentSortColumnType = this.sortColumnType;
    this.sortColumnIndex = index;

    if (currentSortColumnIndex !== this.sortColumnIndex) {
      this.sortColumnType = SORT_ASC;
    } else {
      this.sortColumnType = currentSortColumnType === SORT_ASC ? SORT_DESC : SORT_ASC;
    }
  }


  isAscendingSortColumn(index: number): boolean {
    return this.sortColumnIndex === index && this.sortColumnType === SORT_ASC;
  }

  isDescendingSortColumn(index: number): boolean {
    return this.sortColumnIndex === index && this.sortColumnType === SORT_DESC;
  }

  getList() {
    let sDate = this.dateService.formatYYYY_MM_DD(this.startDate);
    let eDate = this.dateService.formatYYYY_MM_DD(this.endDate);
    this.teamService.getChallengeReports(sDate, eDate, this.challengeId, 1).subscribe(
      (data) => {
        this.filteredList = data;
        this.filteredList.map((item) => {
          item.totalStepsByTeams = item.totalStepsByTeams != null ? item.totalStepsByTeams : 0;
        });
      },
      (error) => this.utilService.showToastError('Error while loading items - ' + error),
    );
  }

  goToDetails(teamId) {
    let sDate = this.dateService.formatYYYY_MM_DD(this.startDate);
    let eDate = this.dateService.formatYYYY_MM_DD(this.endDate);
    this.teamService.getTeamsReports(sDate, eDate, teamId, 1).subscribe(
      (data) => {
        this.detailsFilteredList = data;
        this.detailsFilteredList.map((item) => {
          item.photoSrc = this.userService.getProfilePicture(item.photo);
          item.levelSrc = this.userService.getLevelSrc(item.level);
        });
        this.isDisplayingList = false;
        this.isDisplayingDetailsList = true;
      },
      (error) => this.utilService.showToastError('Error while loading items - ' + error),
    );
  }

  goToBack() {
    this.isDisplayingList = true;
    this.isDisplayingDetailsList = false;
  }

  onDateRangeSelected($event) {
    this.challengeId = $event.challengeId;
    this.startDate = $event.startDate;
    this.endDate = $event.endDate;
    this.getList();
  }

  onViewProfile(userProfileId) {

  }
}
