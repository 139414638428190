import { DateService } from '../../../services/date.service';
import { UtilService } from '../../../services/util-service';
import { CompanyService } from '../../../services/company-service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChallengeService } from '../../../services/challenge-service';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
})
export class DateSelectorComponent implements OnInit {
  @Input() inputStartDate: Date;
  @Input() inputEndDate: Date;
  @Input() inputCompanyId: number;
  @Input() inputChallengeId: number;
  @Input() canSelectCompany = false;
  @Input() canSelectChallenge = false;
  @Output() dateQuery: EventEmitter<any> = new EventEmitter();

  startDate: any;
  endDate: any;
  companies: any;
  allCompanies: any;
  selectedCompanyId: number;
  challenges: any;
  allChallenges: any;
  selectedChallengeId: number;
  isValidDateRange = true;

  constructor(
    private companyService: CompanyService,
    private utilService: UtilService,
    private dateService: DateService,
    private challengeService: ChallengeService,
  ) {
  }

  ngOnInit() {
    this.initialiseInputValues();
    if (this.canSelectCompany) {
      this.getCompanies();
    }
    if (this.canSelectChallenge) {
      this.getChallenges();
    }
  }

  initialiseInputValues() {
    this.selectedCompanyId = this.inputCompanyId;
    this.startDate = (this.isDate(this.inputStartDate))
      ? this.dateService.toYearMonthDayObject(this.inputStartDate)
      : null;
    this.endDate = (this.isDate(this.inputEndDate))
      ? this.dateService.toYearMonthDayObject(this.inputEndDate)
      : null;

    if (this.isDate(this.inputStartDate) && this.isDate(this.inputEndDate)) {
      this.validateDateRange();
    }
  }

  isDate(value) {
    return value;
  }

  getCompanies() {
    this.companyService.getAllCompanies()
      .subscribe(
        (data) => {
          this.companies = data;
          this.allCompanies = this.companies.map(c => {
            return { id: c.id, name: c.name };
          });
        },
        (err) => {
          this.utilService.showToastError('Error while loading companies', err);
        },
      );
  }

  getChallenges() {
    this.challengeService.getAllChallengesForReport()
      .subscribe(
        (data) => {
          this.challenges = data;
          this.allChallenges = this.challenges.map((c, index) => {
            if (index == 0) {
              this.selectedChallengeId = c.id;
            }
            return { id: c.id, name: c.name };
          });
          this.dateQuery.emit({
            challengeId: this.selectedChallengeId,
            startDate: this.dateService.formatYYYY_MM_DD(this.dateService.fromYearMonthDayObject(this.startDate)),
            endDate: this.dateService.formatYYYY_MM_DD(this.dateService.fromYearMonthDayObject(this.endDate)),
          });
        },
        (err) => {
          this.utilService.showToastError('Error while loading companies', err);
        },
      );
  }

  validateDateRange() {
    this.isValidDateRange = (this.isDate(this.startDate) && this.isDate(this.endDate)
      && (this.dateService.fromYearMonthDayObject(this.startDate)
        <= this.dateService.fromYearMonthDayObject(this.endDate)));
  }

  onCompanySelected(companyId) {
    this.selectedCompanyId = companyId;
  }

  onChallengeSelected(event) {
    this.selectedChallengeId = event.target.value;
  }

  onDateRangeSelected($event) {
    this.validateDateRange();
    if (!this.isValidDateRange) {
      return;
    }

    this.dateQuery.emit({
      companyId: this.selectedCompanyId,
      challengeId: this.selectedChallengeId,
      startDate: this.dateService.formatYYYY_MM_DD(this.dateService.fromYearMonthDayObject(this.startDate)),
      endDate: this.dateService.formatYYYY_MM_DD(this.dateService.fromYearMonthDayObject(this.endDate)),
    });
  }
}
