<div *ngIf="dataLoaded && showQuestionnaire" class="list-group list-group-flush pb-5">
  <div class="content-background">
    <div class="img-size-div" *ngIf="questionnaire.banner">
      <img class="img-size" [src]="getImage('questionnaires/',questionnaire.banner)" />
    </div>
    <div class="p-3">
      <h2>
        {{questionnaire.name}}
      </h2>
      <p innerHTML="{{questionnaire.description}}"></p>
      <small *ngIf="questionnaire.termsConditions">
        To read the competition Terms and condition
        <a class="btn-link" data-toggle="modal" data-target="#termsCondModal">
          click here
        </a>.
      </small>
    </div>
    <hr class="m-0" />
  </div>
  <form class="list-group-item" [formGroup]="group" (ngSubmit)="submit()">
    <div *ngFor="let key of objectKeys(group.controls)">
      <div class="mt-2" [hidden]="!questionnaire.liqQuestions[key].showDependencyQuestion" *ngIf="isQuestionDependencyFulfilled(questionnaire.liqQuestions[key])">
        <label class="mt-3">
          <b>{{questionnaire.liqQuestions[key].question}}</b>
          <span *ngIf="isCheckboxAnswerType(questionnaire.liqQuestions[key])">
            <em> (You may select all that apply)</em>
          </span>
          <span *ngIf="questionnaire.liqQuestions[key].required" class="text-danger">*</span>
        </label>
        <div [ngSwitch]="questionnaire.liqQuestions[key].answerType" *ngIf="questionnaire.liqQuestions[key].showAnswers">
          <div *ngSwitchCase=" 'radio' ">
            <div *ngIf="answers[key] && !answers[key][0].icon">
              <div *ngFor="let answer of answers[key]" [hidden]="isAnswerDependencyUnfulfilled(answer)">
                <div class="custom-control custom-radio">
                  <input type="radio" id="{{answer.id}}" value="{{answer.id}}" formControlName="{{key}}" class="custom-control-input mr-2"
                    (click)="checkDependency(answer.id, answer.liqQuestionId, 'radio')">
                  <label class="custom-control-label" for="{{answer.id}}">{{answer.label}}</label>
                </div>
              </div>
            </div>
            <div *ngIf="answers[key][0].icon">
              <div class="card-deck row mb-3">
                <div class="card col-md-4 px-0" *ngFor="let answer of answers[key]" [hidden]="isAnswerDependencyUnfulfilled(answer)">
                  <input class="radio-button" type="radio" value="{{answer.id}}" formControlName="{{key}}" (click)="checkDependency(answer.id, answer.liqQuestionId)">
                  <div class="card-content">
                    <img class="card-img-top" [src]="getImage('questionnaires/',answer.icon)">
                    <div class="card-body">
                      <h5 class="card-title">{{answer.label}}
                        <i class="fa fa-check-circle"></i>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3" *ngSwitchCase="'checkbox'" formGroupName="{{key}}">
            <div *ngIf="!answers[key][0].icon">
              <div *ngFor="let answer of answers[key]" [hidden]="isAnswerDependencyUnfulfilled(answer)">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" id="{{answer.id}}" [formControlName]="answer.id" class="custom-control-input mr-2"
                    (change)="checkDependency(answer.id, answer.liqQuestionId, 'checkbox', $event, key)">
                  <label class="custom-control-label" for="{{answer.id}}">{{answer.label}}</label>
                  <input required *ngIf="isSelectOtherCheckBox && answer.label.toLowerCase() =='other'" #otherCheckBoxAnswerValue type="text" class="form-control"  
                  (keydown)="OnInput(otherCheckBoxAnswerValue.value)" placeholder="Type Your Own Text" />
                </div>
              </div>               
            </div>

            <div *ngIf="answers[key][0].icon">
              <div class="card-deck row">
                <div class="card col-md-4 px-0" *ngFor="let answer of answers[key]" [hidden]="isAnswerDependencyUnfulfilled(answer)">
                  <input class="checkbox" type="checkbox" [formControlName]="answer.id" (click)="checkDependency(answer.id, answer.liqQuestionId, 'checkbox')">
                  <div class="card-content">
                    <img class="card-img-top" [src]="getImage('questionnaires/',answer.icon)">
                    <div class="card-body">
                      <h5 class="card-title">{{answer.label}}
                        <i class="fa fa-check-square"></i>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>           
          </div>         
          <div class="mb-3" *ngSwitchCase="'dropdown'">
            <select class="custom-select form-control" formControlName="{{key}}" #dropdown (change)="checkDependency(dropdown.value, questionnaire.liqQuestions[key].id, 'dropdown')">
              <option value="" selected disabled>Select</option>
              <option *ngFor="let answer of answers[key]" [hidden]="isAnswerDependencyUnfulfilled(answer)" value="{{answer.id}}">{{answer.label}}</option>
            </select>
          </div>
          <div class="mb-3" *ngSwitchCase="'text'">
            <div *ngFor="let answer of answers[key]" class="text" [hidden]="isAnswerDependencyUnfulfilled(answer)">
              <input id="answer.id" type="text" class="form-control" formControlName="{{key}}" (keydown)="checkDependency(answer.id, answer.liqQuestionId, 'text', $event, key)"
                placeholder="{{answer.label}}" />
            </div>
          </div>
          <div class="mb-3" *ngSwitchCase="'number'">
            <div *ngFor="let answer of answers[key]" class="text" [hidden]="isAnswerDependencyUnfulfilled(answer)">
              <input id="answer.id" type="number" class="form-control" formControlName="{{key}}" (keydown)="checkDependency(answer.id, answer.liqQuestionId, 'number', $event, key)"
                placeholder="{{answer.label}}" />
            </div>
          </div>
          <div class="mb-3" *ngSwitchCase="'range'" formGroupName={{key}}>
            <div *ngFor="let answer of ranges[key]" class="slider">
              <div style="display: flex;">
                <label class="pt-3 mh-2 pr-2">{{rangesStartEnd[key][0]}}</label>
                <mat-slider class="w-100" thumbLabel="true" [min]="rangesStartEnd[key][0]" [max]="rangesStartEnd[key][1]"
                  step="1" displayValue="true" [formControl]="setFormControl(key, answer)" (change)="checkDependency(setFormControl(key, answer), answers[key][0][0].liqQuestionId, 'range', $event, key)"></mat-slider>
                <label class="pt-3 mh-2 pl-2">{{rangesStartEnd[key][1]}}</label>
              </div>
            </div>
          </div>
        </div>
        <!-- I don't know checkbox-->
        <div class="custom-control custom-checkbox" *ngIf="showIDontKnowCheckbox(questionnaire.liqQuestions[key])">
          <input type="checkbox" formGroupName="{{key}}" id="{{key}}" class="custom-control-input mr-2" (click)="checkDependency(iDontKnows[key], questionnaire.liqQuestions[key].id, 'unknown', $event, key)">
          <label class="custom-control-label" for="{{key}}">I don't know</label>
        </div>

      </div>
    </div>

    <button class="btn mt-3" [disabled]="!group.valid" (click)="submit($event)">Submit</button>
    <div *ngIf="!group.valid" class="text-danger mt-2 mb-2 text-right"> * Please answer all the required questions.</div>
  </form>
</div>

<div *ngIf="dataLoaded && !showQuestionnaire" class="list-group list-group-flush">
  <h3>Thanks for entering</h3>
  <div class="list-group-item">
    <p>You have already submitted an entry.</p>
  </div>
</div>

<!-- Terms and Conditions Modal -->
<div class="modal fade" id="termsCondModal" tabindex="-1" role="dialog" aria-labelledby="termsCondLabel" *ngIf="dataLoaded && showQuestionnaire">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100" id="termsCondLabel">Terms and Conditions</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body w-100" [innerHtml]="questionnaire.termsConditions">
      </div>
      <div class="modal-footer pb-5">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
