import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UnSubscribeComponent } from '../../_shared/un-subscribe.component';
import { UserService } from '../../../services/user-service';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { ActivatedRoute } from '@angular/router';
import { BookingPosterService } from '../../../services/booking-poster.service';

@Component({
  selector: 'app-booking-poster',
  templateUrl: './booking-poster.component.html',
  styleUrls: ['./booking-poster.component.scss'],
})
export class BookingPosterComponent extends UnSubscribeComponent implements OnInit {
  @ViewChild('bookingPoster') bookingPoster: ElementRef;
  companyId: number;
  companyName: string;
  bookingCode: string;
  bookingUrl: string;
  isProcessing: boolean;
  previewMode = false;
  isLoading: boolean;
  private jsPDF: jsPDF;

  constructor(
    private bookingPosterService: BookingPosterService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((route) => {
      this.companyId = route.id ? +route.id : this.userService.company.id;
      this.getData();
    });
  }

  async onGeneratePDF() {
    this.isProcessing = true;
    const currentDate = new Date();
    const fileName = 'booking_poster' + `_${+currentDate}`;
    if (!this.jsPDF) {
      await this.createPDFDoc();
    }
    this.jsPDF.save(fileName);
    this.isProcessing = false;
  }

  // async onPreviewPDF() {
  //   const previewModal = this.modalService.open(BookingPosterComponent, {
  //     size: 'lg',
  //     centered: true,
  //     windowClass: 'marketing-poster-preview',
  //   });
  //   previewModal.componentInstance.previewMode = true;
  // }

  private async createPDFDoc() {
    const canvas = await html2canvas(this.bookingPoster.nativeElement, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
    });
    const width = 794;
    const height = 1123;
    this.jsPDF = new jsPDF('p', 'px', [width, height], true);
    const image = canvas.toDataURL();
    this.jsPDF.addImage({
      imageData: image,
      x: 0,
      y: 0,
      width: width,
      height: height,
      compression: 'MEDIUM',
    });
    this.jsPDF.link(388, 993, 357, 34, { url: this.bookingUrl });
    this.jsPDF.setProperties({
      title: `Booking Poster`,
      subject: 'Booking Poster',
      author: 'Altius',
      creator: 'Altius',
    });
  }

  private getData() {
    this.isLoading = true;
    this.bookingPosterService.getData(this.companyId)
      .subscribe((res) => {
        this.isLoading = false;
        this.companyName = res.name;
        this.bookingCode = res.bookingCode;
        this.bookingUrl = `https://www.peoplesense.au/${this.bookingCode}`;
      });
  }
}
