import { Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { UserService } from '../../../services/user-service';
import { UtilService } from '../../../services/util-service';
import { GroupService } from '../../../services/group-service';
import { LikeService } from '../../../services/like-service';
import { FriendsService } from '../../../services/friends-service';
import { LogService } from '../../../services/log.service';
import { FilestackService } from '../../../services/filestack-service';
import { DateService } from '../../../services/date.service';
import { ItemDetails } from '../../_shared/components/flagging/flagging.component';
import { IFriend } from '../../_models/friend';
import { BadgeService } from '../../../services/badge-service';
import { IBadgeCount } from '../../_models/badge-count';
import { FriendPresentationComponent } from '../friends-container/friend-presentation/friend-presentation.component';
import { IFriendActions } from '../../_models/friend-actions';
import { BreadcrumbService } from '../../../services/breadcrumb.service';

const POSTS_PER_PAGE = 10;
const PATH_BANNER = 'groups/banner/';
const PATH_LOGO = 'groups/logo/';
const PATH_POSTS = 'groups/posts/';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss'],
})
export class GroupDetailsComponent implements OnInit {
  canShowGroup = false;
  group: any = [];
  posts: any = [];
  members: any = [];
  showInfo = 1;
  comment = [];
  isEmptyCommentError = false;
  lastImage: string = null;
  isMember = false;
  isLoggedin = false;
  userProfilePictureSource: string;
  postPlaceholder = 'Start a conversation...';
  commentPlaceholder = 'Write a comment...';
  actionButtonText = 'Post';
  actionButtonTextOnEdit = 'Save';
  groupId;
  isNoMorePosts: boolean = false;
  postImagePath = PATH_POSTS;
  userCompanyId: any;
  editingCommentId = 0;
  editingPostId = 0;
  initialNumberOfCommentsShown = 2;
  numberCommentsIncrements = 10;

  @Output() deactivate: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private userService: UserService,
    private utilService: UtilService,
    private breadcrumbService: BreadcrumbService,
    private groupService: GroupService,
    private friendsService: FriendsService,
    private badgeService: BadgeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private logService: LogService,
    private modalCtrl: NgbModal,
    private filestackService: FilestackService,
    private likeService: LikeService,
    private _zone: NgZone,
    private dateService: DateService,
  ) {
  }

  ngOnInit() {
    this.isLoggedin = this.userService.isLoggedIn();
    this.userProfilePictureSource = this.userService.getProfilePicture();
    this.userCompanyId = this.userService.company.id;
    this.activatedRoute.params.subscribe((params) => {
      this.showInfo = params.info;
      this.groupId = params.id;
      if (this.groupId == 'new') {
        this.getGroupData(params.id);
      } else {
        this.getGroupData(params.id);
        let reset = this.activatedRoute.snapshot.queryParamMap.get('reset');
        if (reset) {
          this.posts = [];
        }
        this.getGroupsPosts();
        this.logService.logGroup(this.groupId)
          .subscribe(() => {
            this.groupService.notifyGroupsChanged();
          });
      }
    });
  }

  getGroupData(id) {
    if (id == 'new') {
      this.router.navigateByUrl('share/groups/new');
    } else {
      this.groupService.getGroupById(id)
        .subscribe((data) => {
          // group status validate
          if (data && data.status === 0) {
            this.router.navigateByUrl('share/groups');
          }
          // group company validate
          if (data && data.companyId != null && this.userCompanyId != data.companyId) {
            this.router.navigateByUrl('share/groups');
          }
          // group excludedCompany vaildate
          if (data && data.companyId == null) {
            if (data.excludedCompanyIds.indexOf(this.userCompanyId) > -1) {
              this.router.navigateByUrl('share/groups');
            }
          }
          this.breadcrumbService.setBreadcrumbExtraName(data.name);
          this.group = data;
          this.members = data.groupMembers;
          this.isMember = (this.members.length)
            ? (this.members
              .find(m => this.userService.isThisProfileUser(+m.id)) !== undefined)
            : false;
          this.canShowGroup = true;
        });
    }
  }

  getGroupsPosts() {
    const offset = this.posts ? this.posts.length : 0;
    this.groupService.getPaginatedPosts(this.groupId, offset, POSTS_PER_PAGE)
      .subscribe(result => {
        this.isNoMorePosts = result.length < POSTS_PER_PAGE;
        this.markItemUpdatability(result);
        this.markItemUserPhotoSource(result);
        this.markItemDisplayDescription(result);
        this.initialisePostsDisplayComments(result);

        result.forEach((post) => {
          this.posts.push(post);
        });
      });
  }

  urlify(text) {
    return this.utilService.urlify(text);
  }

  private initialisePostsDisplayComments(posts) {
    const nrCommentsToShow = this.initialNumberOfCommentsShown;

    posts.forEach(post => {
      post.displayComments = post.comments.slice(-nrCommentsToShow);
    });
  }

  private markItemUpdatability(posts) {
    posts.forEach(post => {
      post.isEditable = this.canUserEditItem(post.userProfileId);
      post.isDeletable = this.canUserDeleteItem(post.userProfileId);
      post.comments.forEach((comment) => {
        comment.isEditable = this.canUserEditItem(comment.userProfileId);
        comment.isDeletable = this.canUserDeleteItem(comment.userProfileId);
      });
    });
  }

  private markItemUserPhotoSource(posts) {
    posts.forEach(post => {
      post.photoSource = this.getItemUserPhotoSource(post);
      post.imageSource = this.getItemPhoto(post.image);
      post.comments.forEach((comment) => {
        comment.photoSource = this.getItemUserPhotoSource(comment);
        comment.imageSource = this.getItemPhoto(comment.image);
      });
    });
  }

  private markItemDisplayDescription(posts) {
    posts.forEach(post => {
      post.displayDescription = this.urlify(post.description);
      post.comments.forEach((comment) => {
        comment.displayDescription = this.urlify(comment.description);
      });
    });
  }

  private canUserEditItem(userProfileId: number) {
    return this.userService.isThisProfileUser(userProfileId);
  }

  private canUserDeleteItem(userProfileId: number) {
    return this.userService.isSuperAdminUser() || this.userService.isThisProfileUser(userProfileId);
  }

  private getItemUserPhotoSource(item) {
    return item.active
      ? this.userService.getProfilePicture(item.photo)
      : this.userService.getDefaultProfileImage();
  }

  numberOfCommentsLabel(post) {
    return `${post.displayComments.length} of ${post.comments.length} comments`;
  }

  isDisplayNumberOfCommentsLabel(post) {
    return post.displayComments
      && post.displayComments.length > 0;
  }

  isDisplayMoreComments(post) {
    return post.displayComments
      && post.displayComments.length < post.comments.length;
  }

  displayMorePostComments(post) {
    const nrCommentsToShow = (post.displayComments.length < this.numberCommentsIncrements)
      ? this.numberCommentsIncrements
      : (post.displayComments.length + this.numberCommentsIncrements);

    post.displayComments = post.comments.slice(-nrCommentsToShow);
  }

  isDisplayLessComments(post) {
    return post.displayComments.length > this.initialNumberOfCommentsShown
      && post.displayComments.length >= post.comments.length;
  }

  displayLessComments(post) {
    const nrCommentsToSlice = this.initialNumberOfCommentsShown;
    post.displayComments = post.comments.slice(-nrCommentsToSlice);
  }

  viewMore() {
    this.getGroupsPosts();
  }

  getMemberLevelClass(memberLevel: string, alternateClass: string): string {
    if (this.userService.isCompanyUsingUserlevels()) {
      return memberLevel;
    }

    return alternateClass;
  }

  getUserProfileLevelClass(alternateClass: string): string {
    if (this.userService.isProfileLevel()) {
      return this.userService.profile.level;
    }

    return alternateClass;
  }

  getPostLevelClass(postLevel: string, alternateClass: string): string {
    if (this.userService.isProfileLevel()) {
      return postLevel;
    }

    return alternateClass;
  }

  getUserPicture(photo) {
    return this.userService.getProfilePicture(photo);
  }

  getBannerPhoto(image) {
    return this.getPhoto(PATH_BANNER, image);
  }

  getLogoPhoto(image) {
    return this.getPhoto(PATH_LOGO, image);
  }

  getItemPhoto(image) {
    if (!image) {
      return image;
    }

    return this.getPhoto(PATH_POSTS, image);
  }

  getPhoto(path, photo) {
    return this.userService.getUserStorageAbsolutePath(path, photo);
  }

  info() {
    this.showInfo = 1;
  }

  showGroup() {
    this.showInfo = 0;
  }

  showPicker() {
    this.filestackService.pickPostImage().then(
      (res) => {
        this._zone.run(() => (this.lastImage = res));
      },
      (err) => {
        this.utilService.showAlert('Error while uploading picture', err);
      },
    );
  }

  removeImage() {
    this.lastImage = null;
  }

  editPost(post) {
    if (!post.description) {
      this.resetToPreviousPost(post);
      this.utilService.showToastError('Please include a description in the post');

      return;
    }

    const payload = {
      description: post.description,
      image: post.image ? post.image : null,
      preview: post.preview ? post.preview : null,
    };

    this.groupService.updatetPost(this.editingPostId, payload).subscribe(
      () => {
        this.updateReadOnlyPost(post);
        post.imageSource = this.getItemPhoto(post.image);
        this.editingPostId = 0;
      },
      (error) => console.log(error),
    );
  }

  cancel(type) {
    if (type === 'post') {
      this.editingPostId = 0;
    } else {
      this.editingCommentId = 0;
    }
  }

  resetToPreviousPost(post) {
    const editedPost = this.posts.find((p) => p.id === this.editingPostId);
    if (editedPost) {
      post.description = editedPost.description;
      post.image = editedPost.image;
      post.preview = editedPost.preview;
      post.displayDescription = this.urlify(editedPost.description);
    }
  }

  updateReadOnlyPost(post) {
    const editedPost = this.posts.find((p) => p.id === this.editingPostId);
    if (!editedPost) {
      return;
    }

    editedPost.description = post.description;
    editedPost.displayDescription = this.urlify(editedPost.description);
    editedPost.image = post.image ? post.image : null;
    editedPost.preview = post.preview ? post.preview : null;

    editedPost.imageSource = this.getItemPhoto(editedPost.image);
  }

  createNewPost(post) {
    if (post.image && !post.description) {
      this.utilService.showInfo('Please add a description for your image', 1, 'exclamation');
      return;
    }

    if (!post.description) {
      this.utilService.showInfo('Post must include a description', 1, 'exclamation');
      return;
    }

    const data = {
      companyId: this.userService.company.id,
      description: post.description,
      image: post.image,
      preview: post.preview,
    };

    this.groupService.createPost(this.group.id, data)
      .subscribe(result => {
        const newPost = this.getNewItem(result);
        if (result.image) {
          newPost.imageSource = this.getItemPhoto(result.image);
        }
        newPost.comments = [];
        newPost.displayComments = [];

        this.posts.unshift(newPost);
      });
  }

  isEmptyComment(comment): boolean {
    return !comment.description && !comment.preview && !comment.image;
  }


  editComment(comment, post) {
    if (this.isEmptyComment(comment)) {
      // editingCommandId NOT reset to 0 to permit user to continue to edit.
      // Underlying values reset in case user navigates away to edit another comment
      this.resetToPreviousComment(comment, post);
      this.editingCommentId = 0;
      this.utilService.showToastError('Can not update an empty comment');

      return;
    }

    const payload = {
      description: comment.description,
      image: comment.image ? comment.image : null,
      preview: comment.preview ? comment.preview : null,
    };

    this.groupService.updateComment(this.editingCommentId, payload)
      .subscribe(
        () => {
          this.updateReadOnlyComment(comment, post);
          this.moveUpdatedCommentToDateOrder(post, this.editingCommentId);
          this.refreshPostDisplayComments(post);
          this.editingCommentId = 0;
        },
        (error) => console.log(error),
      );
  }

  resetToPreviousComment(comment, post) {
    const editedComment = post.comments.find(
      (c) => c.id === this.editingCommentId,
    );
    comment.description = editedComment.description;
    comment.displayDescription = this.urlify(editedComment.description);
    comment.image = editedComment.image;
    comment.preview = editedComment.preview;
  }

  updateReadOnlyComment(comment, post) {
    const updatedComment = post.comments.find(
      (c) => c.id === this.editingCommentId,
    );
    if (!updatedComment) {
      return;
    }

    updatedComment.updatedAt = this.dateService.getDate();
    updatedComment.description = comment.description;
    updatedComment.image = comment.image ? comment.image : null;
    updatedComment.preview = comment.preview ? comment.preview : null;
    updatedComment.displayDescription = this.urlify(comment.description);

    updatedComment.photoSource = this.getItemUserPhotoSource(updatedComment);
    updatedComment.imageSource = this.getItemPhoto(updatedComment.image);
  }

  private moveUpdatedCommentToDateOrder(post: any, updatedCommentId: number) {
    const index = post.comments.findIndex(c => c.id === updatedCommentId);
    const updatedComment = post.comments.find(c => c.id === updatedCommentId);
    if (index < 0 || !updatedComment || post.comments.length === 1) {
      return;
    }

    post.comments.splice(index, 1);
    post.comments.push(updatedComment);
  }

  createComment(comment, post, postIndex) {
    this.isEmptyCommentError = this.isEmptyComment(comment);
    if (this.isEmptyCommentError) {
      return;
    }

    const userIdsForNotifications = [];
    if (!this.userService.isThisProfileUser(post.userProfileId)) {
      userIdsForNotifications.push(post.userProfileId);
    }

    post.comments.forEach((postedComment) => {
      if (this.userService.isThisProfileUser(postedComment.userProfileId)) {
        userIdsForNotifications.push(postedComment.userProfileId);
      }
    });

    const notificationUserIds = userIdsForNotifications.filter(function(user, pos) {
      return userIdsForNotifications.indexOf(user) === pos;
    });

    this.groupService
      .createComment(this.group.id, notificationUserIds, {
        description: comment.description,
        preview: comment.preview,
        postId: post.id,
        image: comment.image,
      })
      .subscribe(result => {
        const newComment = this.getNewItem(result);
        this.posts[postIndex].comments.push(newComment);
        this.refreshPostDisplayComments(post);
      });
  }

  private refreshPostDisplayComments(post) {
    const nrCommentsToShow = Math.max(
      post.displayComments.length, this.initialNumberOfCommentsShown);
    post.displayComments = post.comments.slice(-nrCommentsToShow);
  }

  getNewItem(serverItem) {
    const newItem = {
      ...this.userService.profile,
      ...serverItem,
      liked: '0',
      likes: '0',
      isEditable: this.canUserEditItem(serverItem.userProfileId),
      isDeletable: this.canUserDeleteItem(serverItem.userProfileId),
      displayDescription: this.urlify(serverItem.description),
    };
    newItem.photoSource = this.getItemUserPhotoSource(newItem);

    return newItem;
  }

  leaveGroup() {
    if (!this.isMember) {
      return;
    }

    const leaveGroupModal = this.modalCtrl.open(BootstrapModalComponent);
    leaveGroupModal.componentInstance.title = 'Groups';
    leaveGroupModal.componentInstance.message = `Are you sure that you want to leave <b>${
      this.group.name
    }</b> ?`;
    leaveGroupModal.componentInstance.confirmButtonLabel = 'Leave';
    leaveGroupModal.result.then((res) => {
      if (res === true) {
        this.groupService.leaveGroup(this.group.id)
          .subscribe(() => {
            this.deactivate.emit();
            this.router.navigateByUrl('share/groups');
          });
      }
    });
  }

  joinGroup(e, group) {
    e.preventDefault();
    e.stopPropagation();

    if (this.isMember) {
      return;
    }

    this.groupService.joinGroup(group.id)
      .subscribe(() => {
        this.members.push(this.userService.profile);
        this.isMember = true;
        this.deactivate.emit();
      });
  }

  isActiveImage(item) {
    return item.active && item.image !== null;
  }

  isActivePreview(item) {
    return item.active && item.image === null && item.preview !== null;
  }

  isPreviewUrlValid(item) {
    const itemPreviewUrl = item.preview && item.preview.url;
    const appBaseUrls = this.userService.envVariables.app.internalLinkBaseDomains;
    return itemPreviewUrl && (item.preview.appParams || !appBaseUrls.find(appUrl => itemPreviewUrl && itemPreviewUrl.toLowerCase().includes(appUrl)));
  }

  isEditingPost(postId: number): boolean {
    return postId === this.editingPostId;
  }

  isEditingComment(commentId: number): boolean {
    return commentId === this.editingCommentId;
  }

  callEditPost(itemDetails: ItemDetails) {
    if (!this.canUserEditItem(itemDetails.userProfileId)) {
      return;
    }

    this.editingPostId = itemDetails.itemId;
  }

  callDeletePost(itemDetails: ItemDetails) {
    this.deletePost(itemDetails.itemId, itemDetails.userProfileId);
  }

  private deletePost(postId, userProfileId) {
    if (!this.canUserDeleteItem(userProfileId)) {
      return;
    }

    const deleteModal = this.modalCtrl.open(BootstrapModalComponent);
    deleteModal.componentInstance.title = 'Delete Group Post';
    deleteModal.componentInstance.message = 'Are you sure you want to delete this post?';
    deleteModal.componentInstance.confirmButtonLabel = 'Delete';

    deleteModal.result.then((isConfirmed) => {
      if (isConfirmed) {
        this.groupService.deletePost(postId).subscribe(() => {
          this.posts = this.posts.filter((post) => {
            return post.id !== postId;
          });
          this.utilService.showToastSuccess('Post deleted');
        });
      }
    });
  }

  callEditComment(itemDetails: ItemDetails) {
    if (!this.canUserEditItem(itemDetails.userProfileId)) {
      return;
    }

    this.editingCommentId = itemDetails.itemId;
  }

  callDeleteComment(itemDetails: ItemDetails) {
    this.deleteComment(itemDetails.itemId, itemDetails.itemIndex, itemDetails.userProfileId);
  }

  private deleteComment(commentId, postIndex, userProfileId) {
    if (!this.canUserDeleteItem(userProfileId)) {
      return;
    }

    const deleteModal = this.modalCtrl.open(BootstrapModalComponent);
    deleteModal.componentInstance.title = 'Delete Comment';
    deleteModal.componentInstance.message = 'Are you sure you want to delete this comment?';
    deleteModal.componentInstance.confirmButtonLabel = 'Delete';

    deleteModal.result.then((isConfirmed) => {
      if (isConfirmed) {
        this.groupService.deleteComment(commentId).subscribe(() => {
          const post = this.posts[postIndex];
          post.comments = this.posts[postIndex].comments.filter(
            (comment) => comment.id !== commentId,
          );
          this.refreshPostDisplayComments(post);
          this.utilService.showToastSuccess('Comment deleted');
        });
      }
    });
  }

  onViewProfile(userProfileId) {
    this.friendsService.getFriendByUserProfileId(userProfileId)
      .subscribe((friend: IFriend) => {
        this.setAdditionalFriendProperties(friend);
        this.badgeService.getUserBadgesSummary(userProfileId)
          .subscribe((badgeCounts: IBadgeCount[]) => {
            this.openFriendPresentationModal(friend, badgeCounts);
          }, err => {
            console.log('friendsService.getFriendByUserProfileId(item.id)', err);
          });
      });
  }

  private setAdditionalFriendProperties(friend: IFriend) {
    friend.photoSrc = this.userService.getProfilePicture(friend.photo);
    friend.levelSrc = this.userService.getLevelSrc(friend.level);
    friend.username ?
      friend.usernameTruncated = this.utilService.truncateForXS(friend.username, screen.width) :
      friend.usernameTruncated = friend.username;
  }

  private openFriendPresentationModal(friend: IFriend, badgeCounts: IBadgeCount[]) {
    const modal = this.modalCtrl.open(FriendPresentationComponent);
    modal.componentInstance.friend = friend;
    modal.componentInstance.badgeCounts = badgeCounts;

    modal.result.then((selectedAction: IFriendActions) => {
      this.doSelectedAction(selectedAction);
    }, () => {
    });
  }

  private doSelectedAction(selectedAction: IFriendActions) {
    if (selectedAction.sendFriendRequest) {
      this.sendFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.cancelSentFriendRequest) {
      this.cancelSentFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.confirmReceivedFriendRequest) {
      this.confirmReceivedFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.declineReceivedFriendRequest) {
      this.declineReceivedFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.removeConfirmedFriend) {
      this.removeConfirmedFriend(selectedAction.actionedFriend);
    }
  }

  private sendFriendRequest(friend: IFriend) {
    this.friendsService.sendFriendRequest(friend.id)
      .subscribe((requestedFriend: IFriend) => {
        const msg = `Successfully sent a friend request for ${requestedFriend.username}`;
        this.utilService.showToastSuccess('Friend Request Sent', msg);
      }, (err) => {
        console.log('sendFriendRequest error', err);
        const msg = `Unable to send a friend request for ${friend.username}`;
        this.utilService.showToastError('Friend Request Error', msg);
      });
  }

  private cancelSentFriendRequest(friend: IFriend) {
    this.friendsService.cancelSentFriendRequest(friend.id)
      .subscribe((cancelledFriend: IFriend) => {
        const msg = `Cancelled your sent friend request to ${cancelledFriend.username}`;
        this.utilService.showToastSuccess('Cancel sent friend request', msg);
      }, (err) => {
        console.log('cancelSentFriendRequest error', err);
        const msg = `Unable to cancel the friend request for ${friend.username}`;
        this.utilService.showToastError('Cancel Friend Request Error', msg);
      });
  }

  private confirmReceivedFriendRequest(friend: IFriend) {
    this.friendsService.confirmReceivedFriendRequest(friend.id)
      .subscribe((confirmedFriend: IFriend) => {
        const msg = `Successfully confirmed friend ${confirmedFriend.username}`;
        this.utilService.showToastSuccess('Confirm Friend', msg);
      }, (err) => {
        console.log('confirmReceivedFriendRequest error', err);
        const msg = `Unable to confirm the friend request from ${friend.username}`;
        this.utilService.showToastError('Confirm Friend Request Error', msg);
      });
  }

  private declineReceivedFriendRequest(friend: IFriend) {
    this.friendsService.declineReceivedFriendRequest(friend.id)
      .subscribe((declinedFriend: IFriend) => {
        const msg = `Declined the friend rerequest received from ${declinedFriend.username}`;
        this.utilService.showToastSuccess('Decline Friend Request', msg);
      }, (err) => {
        console.log('declineReceivedFriendRequest error', err);
        const msg = `Unable to decline the friend request from ${friend.username}`;
        this.utilService.showToastError('Decline Friend Request Error', msg);
      });
  }

  private removeConfirmedFriend(friend: IFriend) {
    this.friendsService.removeConfirmedFriend(friend.id)
      .subscribe((removedFriend: IFriend) => {
        const msg = `Removed friend ${removedFriend.username}`;
        this.utilService.showToastSuccess('Remove Friend', msg);
      }, (err) => {
        console.log('removeConfirmedFriend error', err);
        const msg = `Unable to remove the friend ${friend.username}`;
        this.utilService.showToastError('Remove Friend Error', msg);
      });
  }

  onLikeClicked(event, obj, type) {
    event.preventDefault();
    event.stopPropagation();

    if (+obj.liked === 0) {
      this.like(obj, type);
    } else {
      this.unlike(obj, type);
    }
  }

  private like(obj, type) {
    this.likeService.like(+obj.id, type, this.userService.profile.id).subscribe(
      (data) => {
        if (data[0] && data[0].contentId) {
          obj.liked = 1;
          obj.likes = +obj.likes + 1;
        }
      },
      (err) => {
        console.log(err);
      },
    );
  }

  private unlike(obj, type) {
    this.likeService.unlike(Number.parseInt(obj.id), type).subscribe((data) => {
      if (data === 1) {
        obj.liked = 0;
        obj.likes = Number.parseInt(obj.likes) - 1;
      }
    });
  }

  openUrl(url) {
    window.open(`${url}`, '_blank');
    return false;
  }
}
