import { CompanyService } from '../../../services/company-service';
import { FeatureService } from '../../../services/feature-service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifeActionService } from '../../../services/life-action-service';
import { CategoryService } from '../../../services/category-service';
import { UtilService } from '../../../services/util-service';
import { ILifeAction } from '../../_models/life-action';
import { IIdNamePair } from '../../_models/id-name-pair';
import { IValueNamePair } from '../../_models/value-name-pair';
import { IIdBooleanNamePair } from '../../_models/id-boolean-name-pair';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { UserService } from '../../../services/user-service';

@Component({
  selector: 'app-life-action-management',
  templateUrl: './life-action-management.component.html',
  styleUrls: ['./life-action-management.component.scss']
})
export class LifeActionManagementComponent implements OnInit {
  items: ILifeAction[];
  item: ILifeAction;
  itemType = 'Life Action';
  columns = [];
  itemForm: FormGroup;
  companies = [];
  allCompanies: IIdNamePair[] = [];
  excludedCompanyIds: number[] = [];

  isDisplayingList: boolean;
  isInitialised: boolean;
  isEditingItem: boolean;
  isAddingItem: boolean;
  canRespondToButtons: boolean;

  isPreventListDelete: boolean;
  domains = [];
  complexities = [];
  recurrences: IValueNamePair[] = [];
  yesNoItems: IIdBooleanNamePair[] = [];

  private canSelectCompany = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private itemService: LifeActionService,
    private categoryService: CategoryService,
    private companyService: CompanyService,
    private utilService: UtilService,
    private feature: FeatureService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.isPreventListDelete = true;
    this.activatedRoute.params.subscribe(route => {
      this.analyseRoute(route);

      if (this.isDisplayingList) {
        this.getList();
      } else {
        this.getDomains();
        this.setUserRoleAuthorisations();
        if (this.isEditingItem) {
          this.initialiseEditItem(+route.id);
        } else if (this.isAddingItem) {
          this.initialiseAddItem();
        }
      }
    });
  }

  private setUserRoleAuthorisations() {
    this.canSelectCompany = this.userService.isSuperAdminUser();
  }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isEditingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isEditingItem = true;
    }
  }

  private getList() {
    this.itemService.getAllLifeActions()
      .subscribe(data => {
        this.items = data.map(i => {
          return [i.id, i.name,
          this.itemService.recurrenceName(i.recurrence) || i.recurrence];
        });

        this.columns = ['id', 'name', 'recurrence'];
        this.isInitialised = true;
        this.canRespondToButtons = true;
      },
      error => this.utilService.showToastError('Error while loading life actions - ' + error));
  }

  private getDomains() {
    this.categoryService.getDomains().subscribe((data) => {
      this.domains = data;
    });
  }

  private initialiseEditItem(id: number): void {
    this.itemService.getLifeActionById(id)
      .subscribe(data => {
        this.getCompanies(id);
        this.item = data;
        this.initialiseEditableFormGroup(data);
      });
  }

  private initialiseAddItem() {
    this.item = this.itemService.getNewLifeAction();
    this.getCompanies();
    this.initialiseEditableFormGroup(this.item);
  }

  private getCompanies(id?: number) {
    this.companyService.getAllCompanies()
      .subscribe(data => {
          this.companies = data;
          this.allCompanies = this.companies.map(c => {
            return { id: c.id, name: c.name };
          });

          this.isInitialised = true;
          this.canRespondToButtons = true;
          },
        (err) => {
          this.companies = [];
          this.utilService.showToastError('Error while loading companies', err);
        }
      );
    }

  private initialiseEditableFormGroup(item: ILifeAction) {
    this.recurrences = this.itemService.allStaticRecurrences();
    this.complexities = this.itemService.allStaticLifeActionComplexityLevels();
    this.yesNoItems = this.utilService.yesNoItems();
    this.itemForm = this.fb.group({
      nameControl: [item.name, Validators.required],
      descriptionControl: [item.description, Validators.required],
      domainIdControl: [item.domainId, Validators.required],
      recurrenceControl: [item.recurrence, Validators.required],
      complexityControl: [item.complexity, Validators.required],
      newUserDefaultControl: item.newUserDefault || false,
      companyId: item.companyId
    });

    this.excludedCompanyIds = this.isAllCompanies()
      ? item.excludedCompanyIds
      : [];
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  private navigateToList() {
    this.router.navigate(['/cms/lifeactions']);
  }

  private persistItem() {
    this.canRespondToButtons = false;
    let itemToPersist: ILifeAction;

    try {
      this.domains.find(c => +c.id === +this.itemForm.get('domainIdControl').value);
      itemToPersist = {
        id: this.item.id,
        name: this.itemForm.get('nameControl').value,
        description: this.itemForm.get('descriptionControl').value,
        category: null,
        recurrence: this.itemForm.get('recurrenceControl').value,
        complexity: this.itemForm.get('complexityControl').value,
        newUserDefault: this.itemForm.get('newUserDefaultControl').value,
        domainId: this.itemForm.get('domainIdControl').value,
        featureId: this.feature.LifeActionsFeatureId,
        companyId: this.canSelectCompany ? this.itemForm.get('companyId').value : this.userService.company.id,
        excludedCompanyIds: this.isAllCompanies()
          ? this.excludedCompanyIds
          : []
      };
    } catch (error) {
      this.utilService.showToastError('Error persisting - ' + error);
      this.canRespondToButtons = true;
      return;
    }

    if (this.isEditingItem) {
      this.updateItem(itemToPersist);
    } else if (this.isAddingItem) {
      this.addNewItem(itemToPersist);
    }
  }

  private updateItem(itemToPersist: ILifeAction) {
    this.itemService.updateLifeAction(itemToPersist).subscribe(
      () => {
        this.itemForm.reset();
        this.canRespondToButtons = true;
        this.utilService.showToastSuccess(`Updated ${this.itemType} - ${itemToPersist.name}`);
        this.navigateToList();
      },
      (error) => {
        this.utilService.showToastError(`Error while saving ${this.itemType} - ${error}`);
        this.canRespondToButtons = true;
      }
    );
  }

  private addNewItem(itemToPersist: ILifeAction) {
    this.itemService.addLifeAction(itemToPersist).subscribe(
      () => {
        this.itemForm.reset();
        this.canRespondToButtons = true;
        this.utilService.showToastSuccess(`Added new ${this.itemType} - ${itemToPersist.name}`);
        this.navigateToList();
      },
      (error) => {
        this.utilService.showToastError(`Error while saving ${this.itemType} - ${error}`);
        this.canRespondToButtons = true;
      }
    );
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = `Delete ${this.itemType}`;
    modal.componentInstance.message = `Confirm deleting ${this.itemType} - ${this.item.name}`;
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(
      (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }

        this.itemService.deleteLifeAction(this.item.id).subscribe(
          () => {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`${this.itemType} - ${this.item.name} - deleted`);
            this.navigateToList();
          },
          (error) => this.utilService.showToastError('Error while deleting item - ' + error)
        );
      },
      () => { }
    );
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field)
    };
  }

  isAllCompanies() {
    try {
      return this.itemForm.get('companyId')
        && !this.itemForm.get('companyId').value
        && this.canSelectCompany;
    } catch (error) {
      return false;
    }
  }

  onCompanySelected(companyId) {
    this.itemForm.patchValue({ companyId: companyId });
  }

  excludedCompanyIdsSelected(selectedCompanyIds: number[]) {
    this.excludedCompanyIds = selectedCompanyIds;
  }

  onBackClick() {
    this.navigateToList();
  }

  onDeleteClick() {
    if (!this.isEditingItem) {
      return;
    }

    this.deleteItem();
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }

    this.persistItem();
  }

}
