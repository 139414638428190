<div>
  <form name="form" [formGroup]="form" (ngSubmit)="submit()" #f="ngForm">
    <ul class="list-group list-group-flush">
      <h1>Childcare Locator Form</h1>
      <li class="list-group-item">
        <p>Both your employer and SeventeenHundred understand the effort and time taken to find childcare that meets your individual
          requirements and has availability.
        </p>
        <br>
        <p>

          With your company's support, SeventeenHundred can assist you finding the right care for your child - we will research and
          find the best matched childcare environments for your family. All costs will be covered by your employer.</p>
        <br>
        <p> Complete the form below, and you will receive a detailed report on childcare available in your chosen area within
          48 hours. Whilst we will endeavour to find you suitable childcare available in your required timeframe, it is not
          always possible to do so, as availability of spaces are dependent upon the individual providers.
        </p>
        <br>
        <p>
          The report will include the following information:
        </p>
        <p class="ml-4">
          1. Name and contact details of the centre.
          <br>2. A summary of the centres / care including availability of places and waitlist process.
          <br>3. Schedule of fees including information on government tax benefits and returns.
          <br>4. An overview of the curriculum and an explanation of the national framework within which the childcare centres
          operate.
        </p>
      </li>
      <br>
      <h3>Parent or Guardian Requesting Care - Personal Details</h3>
      <div class="card">
        <div class="row">
          <div class="col-md-6">
            <li class="list-group-item">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-user"></i>
                  </span>
                </div>
                <input type="text" name="firstName" class="form-control" tabindex="1" formControlName="firstName" placeholder="First name*"
                  required />
              </div>
            </li>
            <li class="list-group-item">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-map-marker"></i>
                  </span>
                </div>
                <input type="text" name="address" class="form-control" tabindex="3" formControlName="address" placeholder="Address*" required
                />
              </div>
            </li>
            <li class="list-group-item">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-map-marker"></i>
                  </span>
                </div>
                <select name="state" class="custom-select form-control" tabindex="5" formControlName="state" required>
                  <option value="" disabled selected>State*</option>
                  <option value="ACT">Australian Capital Territory</option>
                  <option value="NSW">New South Wales</option>
                  <option value="NT">Northern Territory</option>
                  <option value="QLD">Queensland</option>
                  <option value="SA">South Australia</option>
                  <option value="TAS">Tasmania</option>
                  <option value="VIC">Victoria</option>
                  <option value="WA">Western Australia</option>
                </select>
              </div>
            </li>
            <li class="list-group-item">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-phone"></i>
                  </span>
                </div>
                <input id="number" type="tel" name="contactNo" tabindex="7" class="form-control no-spinners" formControlName="contactNo"
                  placeholder="Contact number*" required/>
              </div>
            </li>
          </div>
          <div class="col-md-6">
            <li class="list-group-item">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-user"></i>
                  </span>
                </div>
                <input type="text" name="lastName" class="form-control" tabindex="2" formControlName="lastName" placeholder="Last name*"
                  required />
              </div>
            </li>
            <li class="list-group-item">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-map-marker"></i>
                  </span>
                </div>
                <input type="text" name="suburb" class="form-control" formControlName="suburb" tabindex="4" placeholder="Suburb*" required
                />
              </div>
            </li>
            <li class="list-group-item">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-map-marker"></i>
                  </span>
                </div>
                <input type="number" id="number" maxlength="4" name="postcode" tabindex="6" class="form-control no-spinners" placeholder="Postcode*"
                  formControlName="postcode" required />
              </div>
            </li>
            <li class="list-group-item">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-user"></i>
                  </span>
                </div>
                <input id="email" type="text" name="email" class="form-control" tabindex="8" formControlName="email" placeholder="Email*" required
                />
              </div>
            </li>
          </div>
          <div class="col-md-12">
            <li class="list-group-item">
              <label for="noOfChildren">Number of children*</label>
              <select name="noOfChildren" class="custom-select form-control" tabindex="9" formControlName="noOfChildren" [(ngModel)]="noOfKids" required>
                <option value="" disabled selected>Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
            </li>
            <li *ngIf="noOfKids !==''" class="list-group-item">
              <label for="childDOB">Child 1 DOB</label>
              <div class="input-group">
                <div class="input-group">
                  <input class="form-control" name="child1DOB" formControlName="child1DOB" placeholder="yyyy-mm-dd"
                    ngbDatepicker #b="ngbDatepicker"/>
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="b.toggle()" type="button">
                      <i class="fa fa-calendar"></i>
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li *ngIf="noOfKids > '1'" class="list-group-item">
              <label for="childDOB">Child 2 DOB</label>
              <div class="input-group">
                <div class="input-group">
                  <input class="form-control" name="child2DOB" formControlName="child2DOB" placeholder="yyyy-mm-dd"
                    ngbDatepicker #c="ngbDatepicker"/>
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="c.toggle()" type="button">
                      <i class="fa fa-calendar"></i>
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li *ngIf="noOfKids > '2'" class="list-group-item">
              <label for="childDOB">Child 3 DOB</label>
              <div class="input-group">
                <div class="input-group">
                  <input class="form-control" name="child3DOB" formControlName="child3DOB" placeholder="yyyy-mm-dd"
                    ngbDatepicker #e="ngbDatepicker"/>
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="e.toggle()" type="button">
                      <i class="fa fa-calendar"></i>
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li *ngIf="noOfKids > '3'" class="list-group-item">
              <label for="childDOB">Child 4 DOB</label>
              <div class="input-group">
                <div class="input-group">
                  <input class="form-control" name="child4DOB" formControlName="child4DOB" placeholder="yyyy-mm-dd"
                    ngbDatepicker #x="ngbDatepicker"/>
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="x.toggle()" type="button">
                      <i class="fa fa-calendar"></i>
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li *ngIf="noOfKids > '4'" class="list-group-item">
              <label for="childDOB">Child 5 DOB</label>
              <div class="input-group">
                <div class="input-group">
                  <input class="form-control" name="child5DOB" formControlName="child5DOB" placeholder="yyyy-mm-dd"
                    ngbDatepicker #h="ngbDatepicker"/>
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="h.toggle()" type="button">
                      <i class="fa fa-calendar"></i>
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li *ngIf="noOfKids > '5'" class="list-group-item">
              <label for="childDOB">Child 6 DOB</label>
              <div class="input-group">
                <div class="input-group">
                  <input class="form-control" name="child6DOB" formControlName="child6DOB" placeholder="yyyy-mm-dd"
                    ngbDatepicker #g="ngbDatepicker"/>
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="g.toggle()" type="button">
                      <i class="fa fa-calendar"></i>
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </div>
        </div>
      </div>
      <br>
      <h3>Care Requirements</h3>
      <div class="card">
        <li class="list-group-item">
          <label for="location">Preferred location of care (suburb/town or postcode)</label>
          <input type="text" tabindex="10" name="location" class="form-control" formControlName="location" />
        </li>
        <li class="list-group-item">
          <label for="distance">Acceptable distance from preferred location</label>
          <select tabindex="11" name="distance" class="custom-select form-control" formControlName="distance">
            <option value="" disabled selected>Select</option>
            <option value="1">2km</option>
            <option value="2">5km</option>
            <option value="3">10km</option>
            <option value="4">Greater than 10km</option>
          </select>
        </li>
        <li class="list-group-item">
          <label for="homeOrWork">Is your preferred location near home or work?</label>
          <div class="row pl-3">
            <div class="custom-control custom-radio pr-3">
              <input id="home" type="radio" tabindex="12" value="Home" class="custom-control-input" name="homeOrWork" formControlName="homeOrWork"
              />
              <label for="home" class="custom-control-label">Home
              </label>
            </div>
            <div class="custom-control custom-radio">
              <input id="work" type="radio" value="Work" name="homeOrWork" class="custom-control-input" formControlName="homeOrWork" />
              <label for="work" class="custom-control-label">Work
              </label>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <label for="otherOptions">Would you consider options along your journey to work?</label>
          <input tabindex="13" type="text" name="otherOptions" class="form-control" formControlName="otherOptions" />
        </li>
        <li class="list-group-item">
          <label for="additInfo">Additional information on your preferred location</label>
          <input tabindex="14" type="text" name="additInfo" class="form-control" formControlName="additInfo" />
        </li>
        <li class="list-group-item">
          <label for="enrolmentDate">Preferred enrolment date</label>
          <small *ngIf="!form.controls.enrolmentDate.valid && form.dirty" class="warning">Please select an enrolment date in the future</small>
          <div class="input-group">
            <input tabindex="15" class="form-control" name="enrolmentDate" formControlName="enrolmentDate" placeholder="yyyy-mm-dd" navigation="arrows"
              [showWeekdays]="false" ngbDatepicker #d="ngbDatepicker" />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                <i class="fa fa-calendar"></i>
              </button>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <label>Have you put your child's name down at any centres already? If so, please list them.</label>
          <input tabindex="16" type="text" name="contactedCentres" class="form-control" formControlName="contactedCentres" />
        </li>
        <li class="list-group-item">
          <label>Do you have any preferred centres in your local area? If so, please list them</label>
          <input tabindex="17" type="text" name="preferredCentres" class="form-control" formControlName="preferredCentres" />
        </li>
        <li class="list-group-item">
          <label>Are there any centres you do not want to send your child to? If so, please list them.</label>
          <input tabindex="18" type="text" name="vetoCentres" class="form-control" formControlName="vetoCentres" />
        </li>
        <li class="list-group-item">
          <label>Which days does your child require care?</label>
          <div *ngFor="let day of days">
            <div class="custom-control custom-checkbox">
              <input tabindex="19" type="checkbox" class="custom-control-input" id="{{day}}" (change)="onChange(day, $event.target.checked)">
              <label class="custom-control-label" for="{{day}}">
                {{day}}</label>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <label>Can you be flexible on the days required?</label>
          <input tabindex="20" type="text" name="flexibility" class="form-control" formControlName="flexibility" />
        </li>
        <li class="list-group-item">
          <label>What hours do you require care?</label>
          <select tabindex="21" name="hours" class="custom-select form-control" formControlName="hours">
            <option value="" disabled selected>Select</option>
            <option value="1">Long day care (7:30am-6pm)</option>
            <option value="2">Preschool hours (9am-3pm)</option>
            <option value="3">Before school (7am-9am)</option>
            <option value="4">After school (3pm-6pm)</option>
            <option value="5">Flexible hours for pickup and drop off</option>
          </select>
        </li>
        <li class="list-group-item">
          <label>Do you prefer there to be a cook onsite?</label>
          <select tabindex="22" name="cook" class="custom-select form-control" formControlName="cook">
            <option value="" disabled selected>Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </li>
      </div>
      <div class="card">
        <p class="mt-5 mb-0 mx-3">
          <strong> The following terms and conditions apply when providing assistance with locating childcare:
          </strong>
        </p>
        <ol class="mb-0 mx-3">
          <br>
          <li>SeventeenHundred has no agreements with any childcare providers and at all times acts as an objective party.</li>
          <li>The final report will include a minimum of three (3) childcare providers in your chosen areas.</li>
          <li>The final report will be provided within 7 days of receipt of all required information.</li>
          <li>It will be your responsibility to waitlist your child if required. SeventeenHundred will not be able to place any
            children on an individual waiting list as part of this service.</li>
          <li>SeventeenHundred cannot guarantee an available place for your child at a childcare centre. 6. SeventeenHundred
            takes no responsibility if circumstances or ownership changes after the report has been provided and before you
            may enrol your child/children.</li>
        </ol>
        <div class="custom-control custom-checkbox mt-3 mb-2 mx-3">
          <input type="checkbox" id="checkbox" class="custom-control-input" formControlName="termsAndCondits" required/>
          <label class="custom-control-label" for="checkbox">Please click to accept the terms and conditions</label>
          <span class="text-danger">*</span>
        </div>
      </div>
    </ul>
    <div class="row px-3">
      <button class="btn p-2 col-md-2 offset-md-10 offset-6 col-6 my-2" [disabled]="!form.valid" type="submit">Submit</button>
    </div>
  </form>
</div>
<div class="mt-3">
  <p>To read our Privacy Policy
    <a class="hyperlink" (click)="openPolicy('privacy')">click here</a>
  </p>
  <p>To read our Privacy Collection statement
    <a class="hyperlink" (click)="openPolicy('collection')">click here</a>
  </p>
</div>
