import { Injectable } from '@angular/core';
import { IIndicatorTrackerLogValue } from '../../_models/indicator-tracker-log-value';
import { DateService } from '../../../services/date.service';
import * as _ from 'lodash';

@Injectable()
export class TrackMyHealthIndicatorValuesService {
  persistValues: IIndicatorTrackerLogValue[] = [];
  displayValues: IIndicatorTrackerLogValue[] = [];
  lastEnteredNValuesLabel = '';
  showLastEnteredValuesText = '';

  constructor(private dateService: DateService) {}

  addPreviouslyLoggedValue(id: number, date: Date,
      value: number, achievedThisInterval: boolean) {

    const loggedValue: IIndicatorTrackerLogValue = {
      id,
      date,
      value,
      achievedThisInterval,
      displayDate: this.dateService.formatDDD_DD_MMM_YYYY(date),
      isEditing: false,
      isEdited: false,
      isDeleted: false,
      isAdded: false
    };

    this.persistValues.push(loggedValue);
  }

  isLoggedValues(): boolean {
    return this.nonDeletedCount() > 0;
  }

  canToggleDisplayedValues(): boolean {
    return this.nonDeletedCount() > 1;
  }

  toggleDisplayedValues() {
    if (!this.canToggleDisplayedValues) { return; }

    if (this.displayValues.length === 1) {
      this.displayAllEnteredValues();
    } else {
      this.displayLastEnteredValue();
    }
  }

  displayLastEnteredValue() {
    const nonDeleted = this.cloneNonDeletedPersistValues();
    if (!nonDeleted.length) { return; }

    this.displayValues = nonDeleted.slice(-1);
    this.lastEnteredNValuesLabel = this.getLastNEnteredValuesLabel();
    this.showLastEnteredValuesText = this.getShowLastEnteredValuesText();
  }

  displayAllEnteredValues() {
    this.displayValues = this.cloneNonDeletedPersistValues();
    this.lastEnteredNValuesLabel = this.getLastNEnteredValuesLabel();
    this.showLastEnteredValuesText = this.getShowLastEnteredValuesText();
  }

  getPersistValue(id: number): IIndicatorTrackerLogValue {
    return this.persistValues.find(v => v.id === id);
  }

  getNonDeletedValueForDate(date: Date): IIndicatorTrackerLogValue | null {
    const nonDeleted = this.cloneNonDeletedPersistValues();
    return nonDeleted.find(v => this.dateService.isSameDate(v.date, date));
  }

  isNonDeletedValueForDate(date: Date): boolean {
    const nonDeleted = this.cloneNonDeletedPersistValues();

    return nonDeleted
      .filter(v => this.dateService.isSameDate(v.date, date))
      .length > 0;
  }

  deleteValue(id: number) {
    const valueToDelete = this.getPersistValue(id);
    if (!valueToDelete) { return; }

    if (valueToDelete.isAdded) {
      this.persistValues = this.persistValues.filter(pv => pv.id !== id);
    } else {
      valueToDelete.isDeleted = true;
      valueToDelete.isEdited = false;
      }

    this.refreshDisplayValues();
  }

  editValue(id: number, value: number, achievedThisInterval: boolean, editValues) {
    const persistValue = this.getPersistValue(id);
    if (!persistValue) { return; }

    persistValue.value = value;
    persistValue.achievedThisInterval = achievedThisInterval;
    if (!persistValue.isAdded) {
      persistValue.isEdited = true;
      persistValue.isEditing = false;
    }

    if( editValues.length > 0 ) {
      editValues.map(editValue => {
        let editPersistValue = this.getPersistValue(editValue.id);
        if (editPersistValue) {
          if(editValue.value >= 0) {
            editPersistValue.value = editValue.value
          }
          editPersistValue.isEdited = true;
        }
      });
    }

    this.refreshDisplayValues();
  }

  updateAllValues(editValues) {
    if(editValues.length > 0) {
      editValues.map(editValue => {
        let editPersistValue = this.getPersistValue(editValue.id);
        if (editPersistValue) {
          if(editValue.value >= 0) {
            editPersistValue.value = editValue.value
          }
          editPersistValue.isEdited = !editPersistValue.isAdded;
        }
      });
    }
  }

  addValue(date: Date, value: number, achievedThisInterval: boolean, editValues) {
    const existing = this.persistValues
      .find(v => this.dateService.isSameDate(v.date, date));

      if (existing) {
        existing.value = value;
        existing.achievedThisInterval = achievedThisInterval;
        if (!existing.isAdded) {
          existing.isEdited = true;
          existing.isDeleted = false;
        }
      } else {
        const maxId = this.getMaxId();
        const newValue: IIndicatorTrackerLogValue = {
          id: maxId + 1,
          date,
          value,
          achievedThisInterval,
          displayDate: this.dateService.formatDDD_DD_MMM_YYYY(date),
          isAdded: true,
          isEditing: false,
          isEdited: false,
          isDeleted: false
        };

        this.persistValues.push(newValue);
      }

      if( editValues.length > 0 ) {
        editValues.map(editValue => {
          let editPersistValue = this.getPersistValue(editValue.id);
          if (editPersistValue) {
            if(editValue.value >= 0) {
              editPersistValue.value = editValue.value
            }
            editPersistValue.isEdited = true;
          }
        });
      }

      this.refreshDisplayValues();
  }

  canAddNextDay(): boolean {
    if (this.nonDeletedCount() === 0) { return false; }

    const nonDeleted = this.cloneNonDeletedPersistValues();
    // NB If canAddYesterday then set to 2 or less days so will not coincide with canAddYesterday()
    const mostRecent = nonDeleted.filter(v => this.dateService.daysDifference(v.date) <= 2);

    return mostRecent.length === 0;
  }

  canAddYesterday(): boolean {
    const yesterday = this.dateService.getDate(-1);
    const yesterdayValue = this.getNonDeletedValueForDate(yesterday);

    return !yesterdayValue;
  }

  canAddToday(): boolean {
    const today = this.dateService.getDate();
    const todayValue = this.getNonDeletedValueForDate(today);

    return !todayValue;
  }

  getNextDay(): Date {
    if (this.nonDeletedCount() === 0) { return null; }

    const nonDeleted = this.cloneNonDeletedPersistValues();
    const allDates = nonDeleted.map(v => new Date(v.date));
    const maxDate = this.dateService.getMaxDate(allDates);

    return this.dateService.addDays(maxDate, 1);
  }

  isAnyChangedValues(): boolean {
    return this.getAllChangedValues().length > 0;
  }

  getAllChangedValues(): IIndicatorTrackerLogValue[] {
    const allChanged = this.persistValues
    .filter(v => v.isEdited || v.isDeleted || v.isAdded);

    return allChanged.slice();
  }

  private getMaxId(): number {
    const ids: number[] = this.persistValues.map(v => v.id);
    if (!ids.length) { return 0; }

    return Math.max(...ids);
  }

  private refreshDisplayValues() {
    if (this.displayValues.length === 1) {
      this.displayLastEnteredValue();
    } else {
      this.displayAllEnteredValues();
    }
  }

  private getLastNEnteredValuesLabel() {
    if (this.displayValues.length === 0) {
      return '';
    }

    if (this.displayValues.length === 1) {
      return 'Last entered value';
    }

    return `Last ${this.displayValues.length} entered values`;
  }

  private getShowLastEnteredValuesText() {
    if (!this.canToggleDisplayedValues()) {
      return '';
    }

    if (this.displayValues.length === 1 && this.persistValues.length > 1) {
      return `Show last ${this.persistValues.length} values`;
    }

    if (this.displayValues.length > 1) {
      return 'Show last entered value';
    }
  }

  private nonDeletedCount(): number {
    return this.persistValues.filter(lv => !lv.isDeleted).length;
  }

  private cloneNonDeletedPersistValues(): IIndicatorTrackerLogValue[] {
    const nonDeleted = this.persistValues.filter(v => !v.isDeleted);
    return _.cloneDeep(nonDeleted);
  }
}
