import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICarousel } from '../app/_models/carousel';
import { DateService } from './date.service';

@Injectable({
  providedIn: 'root'
})
export class CarouselService {
  constructor(private http: HttpClient, private dateService: DateService) { }

  getAll() {
    const url = '/cms/carousels/all';

    return this.http.get<any>(url);
  }

  loadCarousel(visiability) {
    let url;
    if (visiability === 'guest') {
      url = `/carousels/guest`;
    } else {
      url = `/carousels`;
    }

    return this.http.get<any>(url);
  }

  getById(id) {
    const url = `/cms/carousels/${id}`;

    return this.http.get<any>(url);
  }

  persistCarousel(carousel: ICarousel) {
    const body = {
      title: carousel.title,
      description: carousel.description,
      linkUrl: carousel.linkUrl,
      companyId: carousel.companyId,
      visibility: carousel.visibility,
      image: carousel.image,
      mobileImage: carousel.mobileImage,
      startDate: this.dateService.getLocalDateTimeTzOffset(carousel.startDate),
      endDate: this.dateService.getLocalDateTimeTzOffset(carousel.endDate),
      order: carousel.order,
      active: carousel.active,
      contentType: carousel.contentType,
      excludedCompanyIds: carousel.excludedCompanyIds,
      includedCompanyIds: carousel.includedCompanyIds,
    };

    if (carousel.id === 0) {
      return this.http.post<any>('/cms/carousels', body);
    } else {
      return this.http.put<any>('/cms/carousels/' + carousel.id, body);
    }
  }

  deleteCarousel(id) {
    const url = `/cms/carousels/${id}`;

    return this.http.delete<any>(url);
}

  showVisibility(visibility) {
    const visibilityArray = ['Guest', 'User', 'Both'];
    return visibilityArray[visibility];
  }
}
