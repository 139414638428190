import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../../services/user-service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  helpEmail: string;
  brand: string;
  url: string;
  is1700: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public userService: UserService
  ) { }

  ngOnInit() {
    this.brand = this.userService.envVariables.brand;
    this.is1700 = this.brand == '1700';
    this.brand = this.brand == '1700' ? 'SeventeenHundred' : this.brand;
    this.helpEmail = this.userService.envVariables.contactInfo.helpEmail;
    this.url = this.userService.envVariables.app.url;
  }
}
