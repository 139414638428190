import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'pluralize' })
export class PluralizePipe implements PipeTransform {
  transform(
    nr: number,
    singular: string,
    plural: string,
    none: string = null
  ) {
    if (+nr === 1) {
      return `${nr} ${singular}`;
    }

    if (!+nr && none) {
      return none;
    }

    return `${nr} ${plural}`;
  }
}
