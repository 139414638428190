import { BreadcrumbService } from '../../../../../services/breadcrumb.service';
import { BootstrapModalComponent } from '../../../../../components/bootstrap-modal/bootstrap-modal.component';
import { UserService } from '../../../../../services/user-service';
import { UtilService } from '../../../../../services/util-service';
import { QuestionnaireService } from '../../../../../services/questionnaire-service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { QUESTIONNAIRE_RESULT_TYPES } from '../../../enums';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-questionnaire-detail',
  templateUrl: './questionnaire-detail.component.html',
  styleUrls: ['./questionnaire-detail.component.scss'],
})
export class QuestionnaireDetailComponent implements OnInit {
  questionnaire: any;
  form: FormGroup;
  dataLoaded: Boolean = false;
  objectKeys: any;
  answers = [];
  group: FormGroup;
  ranges = [];
  rangesStartEnd = [];
  iDontKnows = [];
  showQuestionnaire = false;
  path: any;
  latestResult;

  isSelectOtherCheckBox: Boolean = false;
  otherCheckBoxAnswerValue: string;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private questionnaireService: QuestionnaireService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private breadcrumbService: BreadcrumbService,
    private userService: UserService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
    this.objectKeys = Object.keys;
    this.path = this.router.url;
    this.activatedRoute.params.subscribe((data) => {
      const id = this.utilService.getIdFromHyphenatedUrl(data.titleAndId);
      this.questionnaireService.getQuestionnaireById(id).subscribe((questionnaire) => {
        this.questionnaire = questionnaire;
        this.breadcrumbService.setBreadcrumbExtraName(questionnaire.name);

        this.showQuestionnaire = questionnaire.liqUserAnswers.length === 0 || questionnaire.multipleAnswers === true;

        this.createForm(this.questionnaire.liqQuestions);
        this.dataLoaded = true;
      });
    });
  }

  validateCheckbox(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (control.value) {
          return null;
        }
      }
    }

    return {
      validateCheckbox: {
        valid: false,
      },
    };
  }

  createForm(questionnaire) {
    this.answers = [];
    this.group = this.formBuilder.group({});
    questionnaire.map((question, index) => {
      question.liqAnswers.map((answer) => {
        answer.hasAnswerDependency = (answer.answersDependency !== null && answer.answersDependency !== '');
        answer.hasQuestionDependency = !!answer.questionDependencyId;
        if (answer.hasAnswerDependency) {
          answer.answersDependency = ',' + answer.answersDependency + ',';
        }
        answer.isAnswerDependencyFulfilled = !answer.hasAnswerDependency;
        answer.isQuestionDependencyFulfilled = !answer.hasQuestionDependency;
      });
      question.hasQuestionDependency = !!question.questionDependencyId;
      question.hasAnswerDependency = (question.answersDependency && question.answersDependency !== '');
      question.isQuestionDependencyFulfilled = !question.hasQuestionDependency;
      question.isAnswerDependencyFulfilled = !question.hasAnswerDependency;
      question.showDependencyQuestion = question.questionDependencyId == null;

      if (question.answerType === 'range') {
        const rangeAnswers = [];
        question.liqAnswers.map((answer) => {
          if (!this.isIDontKnowAnswer(answer)) {
            answer.value = +answer.value;
            answer.value2 = +answer.value2;
            rangeAnswers.push([answer]);
          }
        });
        this.answers.push(rangeAnswers);
      } else {
        this.answers.push(question.liqAnswers);
      }
      if (question.answerType === 'checkbox') {
        this.ranges.push([index]);
        this.rangesStartEnd.push([]);
        let object;
        if (question.required) {
          object = new FormGroup({}, (formGroup: FormGroup) => {
            return this.validateCheckbox(formGroup);
          });
        } else {
          object = new FormGroup({}, () => null);
        }
        question.liqAnswers.map((answer) => {
          object.addControl(answer.id, new FormControl(false));
        });
        this.group.addControl(index, object);
      } else if (question.answerType === 'range') {
        const valueArray = [];
        let answerId;

        this.answers[index].map((answer) => {
          if (this.isRangeStart(answer[0].value, this.answers[index])) {
            valueArray.push(parseInt(answer[0].value));
          }
          if (this.isRangeEnd(answer[0].value, this.answers[index])) {
            valueArray.push(parseInt(answer[0].value));
          }
          answerId = answer[0].id;

        });
        const startingValue = valueArray[0];
        const object = new FormGroup({});
        if (question.required) {
          object.addControl(answerId, new FormControl(startingValue, Validators.required));
        } else {
          object.addControl(answerId, new FormControl(startingValue));
        }
        const newRangeArray = [answerId];
        const rangeStartEnd = [valueArray[0], valueArray[1]];
        this.rangesStartEnd.push(rangeStartEnd);
        this.ranges.push(newRangeArray);
        this.group.addControl(index, object);
      } else {
        this.ranges.push([index]);
        this.rangesStartEnd.push([]);
        question.required
          ? this.group.addControl(index, new FormControl('', Validators.required))
          : this.group.addControl(index, new FormControl(''));
      }
      if (question.hasAnswerDependency) {
        question.answersDependency = ',' + question.answersDependency + ',';
        this.group.controls[index].validator = null;
      }
      const iDontKnowAnswer = question.liqAnswers.find(a => a.label === `I don't know`);
      if (iDontKnowAnswer) {
        this.iDontKnows.push(iDontKnowAnswer.id);
      } else {
        this.iDontKnows.push('');
      }
      question.showAnswers = true;
    });
  }


  isRangeStart(value, allAnswers) {
    const answersWithoutIdontKnow = allAnswers.filter(a => a.label !== `I don't know`);
    return value === Math.min.apply(Math, answersWithoutIdontKnow.map(function(a) {
      return a[0].value;
    }));
  }

  isRangeEnd(value, allAnswers) {
    const answersWithoutIdontKnow = allAnswers.filter(a => a.label !== `I don't know`);
    return value === Math.max.apply(Math, answersWithoutIdontKnow.map(function(a) {
      return a[0].value;
    }));
  }


  isIDontKnowAnswer(answer) {
    return answer.label === `I don't know`;
  }

  submit(e) {
    e.preventDefault();

    const formValues = this.group.value;

    const objects = [];
    const allAnswers = [].concat(...this.answers);
    Object.keys(formValues).map((index) => {
      if (this.isQuestionDependencyFulfilled(this.questionnaire.liqQuestions[index])) {

        if (!this.questionnaire.liqQuestions[index].showAnswers) {
          // answer is 'I don't know'
          const iDontKnowAnswer = this.questionnaire.liqQuestions[index].liqAnswers.find(answer => answer.label === `I don't know`);
          if (iDontKnowAnswer) {
            const value = 0;
            const liqQuestionId = this.questionnaire.liqQuestions[index].id;
            const liqAnswerId = iDontKnowAnswer.id;
            const points = iDontKnowAnswer.points;
            const userAnswer = {
              value,
              points,
              liqQuestionId,
              liqQuestionnaireId: this.questionnaire.id,
              liqAnswerId,
              other: null,
            };
            objects.push(userAnswer);
          }
        } else if (this.questionnaire.liqQuestions[index].answerType === 'checkbox') {
          Object.keys(formValues[index]).map((answerLabel) => {
            if (formValues[index][answerLabel] === true) {
              let points;
              let value;
              let liqAnswerId;
              let other;
              const liqQuestionId = this.questionnaire.liqQuestions[index].id;
              this.answers[index].map((answer) => {
                console.log(answer);
                if (Number.parseInt(answer.id) === Number.parseInt(answerLabel)) {
                  liqAnswerId = answer.id;
                  points = answer.points;
                  if (answer.value && answer.value2) {
                    value = answer.operand + answer.value + 'and' + answer.value2;
                  } else {
                    value = answer.value;
                  }
                }
                if (answer.label.toLowerCase() === 'other' && this.isSelectOtherCheckBox) {
                  other = this.otherCheckBoxAnswerValue;
                }
              });
              const userAnswer = {
                value,
                points,
                liqQuestionId,
                liqQuestionnaireId: this.questionnaire.id,
                liqAnswerId,
                other,
              };
              objects.push(userAnswer);
            }
          });
        } else if (this.questionnaire.liqQuestions[index].answerType === 'range') {
          const liqQuestionId = this.questionnaire.liqQuestions[index].id;
          const getValue = Object.values(formValues[index]);
          const value = getValue[0];
          let points;
          let liqAnswerId;
          this.questionnaire.liqQuestions[index].liqAnswers.map((answer) => {
            if (parseInt(answer.value) === value) {
              points = answer.points;
              liqAnswerId = answer.id;
            }
          });
          const userAnswer = {
            value,
            points,
            liqQuestionId,
            liqQuestionnaireId: this.questionnaire.id,
            liqAnswerId,
            other: null,
          };
          objects.push(userAnswer);
        } else {
          let value;
          let points;
          let liqQuestionId;
          let liqAnswerId;
          if (
            (this.questionnaire.liqQuestions[index].answerType === 'text' ||
              this.questionnaire.liqQuestions[index].answerType === 'number')
          ) {
            // there will only be one liqAnswer for a question where the answerType is text
            // therefore find variables by index
            value = formValues[index];
            liqQuestionId = this.questionnaire.liqQuestions[index].id;
            liqAnswerId = this.answers[index][0].id;
            points = this.answers[index][0].points;
          } else {
            liqAnswerId = Number.parseInt(formValues[index]);
            allAnswers.map((indAnswer) => {
              if (indAnswer.id === liqAnswerId) {
                if (indAnswer.value && indAnswer.value2) {
                  value = indAnswer.operand + indAnswer.value + 'and' + indAnswer.value2;
                } else {
                  value = indAnswer.value;
                }
                points = indAnswer.points;
                liqQuestionId = indAnswer.liqQuestionId;
              }
            });
          }
          const userAnswer = {
            value,
            points,
            liqQuestionId,
            liqQuestionnaireId: this.questionnaire.id,
            liqAnswerId,
            other: null,
          };
          if (
            userAnswer.liqQuestionId
          ) {
            objects.push(userAnswer);
          }
        }

      }
    });

    if (objects.length > 0) {
      this.questionnaireService.saveUserAnswers(objects).subscribe(() => {
        this.questionnaireService.getResultSets(this.questionnaire.id, 'doCheckBadges').subscribe(res => {
          this.latestResult = res.userResults[0];
          this.questionnaireService.questionnaireResults = res;
          if (this.latestResult) {
            switch (this.questionnaire.liqResultTypeId) {
              // alert message
              case QUESTIONNAIRE_RESULT_TYPES['MESSAGE ALERT W REDIRECT']:
                if (this.latestResult.length) {
                  this.showAlertMessage(this.latestResult[0]);
                }
                break;
              // alert message by points/value
              case QUESTIONNAIRE_RESULT_TYPES['MESSAGE ALERT BY POINTS']:
                if (this.latestResult.length === 0) {
                  this.utilService.showToast(
                    'error',
                    'Something went wrong',
                    'Please try again later.',
                  );
                  this.group.reset();
                  this.router.navigate([this.path, this.questionnaire.featureId]);
                  window.scrollTo(0, 0);
                } else {
                  this.showAlertMessage(this.latestResult[0]);
                }
                break;
              case QUESTIONNAIRE_RESULT_TYPES['TEXT BY POINTS']:
                this.questionnaireService.fromSubmit = true;
                this.router.navigate([this.path + '/result']);
                window.scroll(0, 0);
                break;
              case QUESTIONNAIRE_RESULT_TYPES['CARD PER RECOMMENDED VALUES']:
                this.questionnaireService.fromSubmit = true;
                this.router.navigate([this.path + '/result']);
                window.scroll(0, 0);
                break;
              case QUESTIONNAIRE_RESULT_TYPES['UNIQUE TEXT']:
                this.questionnaireService.fromSubmit = true;
                this.router.navigate([this.path + '/result']);
                window.scroll(0, 0);
                break;
              default:
                this.utilService.showToast('success', 'We have received your form, thank you!');
                this.router.navigate(['/home']);
            }
          } else {
            this.utilService.showToast('error', 'Something went wrong', 'Please try again later.');
            this.group.reset();
            window.scrollTo(0, 0);
            this.router.navigate([this.path]);
          }
        });
      });
    } else {
      this.utilService.showToast(
        'error',
        'We can not submit your entry without any answer. Please check your answers.',
        'Something went wrong',
      );
      window.scrollTo(0, 0);
    }
  }

  setFormControl(index, answer: string) {
    return this.group.controls[index].get(answer.toString());
  }

  getImage(path: string, image: string) {
    return this.userService.envVariables.aws.awsBucket + path + image;
  }

  checkDependency(answerId, questionId, answerType, e?, key?) {
    let dependencyOn = true;
    let isUnknownChecked;
    if (answerType === 'unknown') {
      this.unknownCheck(key);
      isUnknownChecked = e.target.checked;
    }
    if (answerType === 'range') {
      const currentRangeValue = answerId.value;
      const rangeAnswer = this.questionnaire.liqQuestions[key].liqAnswers.find(r => {
        return r.value === currentRangeValue;
      });
      answerId = rangeAnswer.id;
    }
    const checkedAnswerIds = [];
    if (answerType === 'checkbox') {
      Object.keys(this.group.value[key]).map(answerLabel => {
        if (this.group.value[key][answerLabel] === true) {
          checkedAnswerIds.push(+answerLabel);
          console.log(answerLabel, 'Checked');
        } else {
          console.log(answerLabel, 'UnChecked');
        }
      });
      if (e.target.checked) {
        checkedAnswerIds.push(answerId);
      } else {
        checkedAnswerIds.filter(id => id !== answerId);
      }
    }
    if (answerType === 'number' || answerType === 'text') {
      const inputField = this.group.get(key).value;
      const backspaceKeycode = 8;
      if (inputField.length <= 1 && e.keyCode === backspaceKeycode) {
        dependencyOn = false;
      }
    }
    this.questionnaire.liqQuestions.map((question, index) => {

      if (
        question.hasAnswerDependency && question.hasQuestionDependency &&
        this.questionDependencyIsFulfilled(question.questionDependencyId, questionId)
      ) {
        if (isUnknownChecked || (answerType !== 'checkbox' && question.answersDependency.indexOf(',' + answerId + ',') > -1)) {
          question.isQuestionDependencyFulfilled = dependencyOn;
          question.isAnswerDependencyFulfilled = dependencyOn;
          if (dependencyOn && question.required) {
            this.group.controls[index].validator = Validators.required;
          }
        } else if (answerType === 'checkbox' && this.checkedAnswersIncludeDependency(checkedAnswerIds, question.answersDependency)) {
          question.isQuestionDependencyFulfilled = true;
          question.isAnswerDependencyFulfilled = true;
          if (question.required) {
            this.group.controls[index].validator = Validators.required;
          }
        } else {
          question.isAnswerDependencyFulfilled = false;
          question.isQuestionDependencyFulfilled = false;
          question.showAnswers = true;
          this.group.controls[index].validator = null;
        }
      } else if (!question.hasAnswerDependency && question.hasQuestionDependency && this.questionDependencyIsFulfilled(question.questionDependencyId, questionId)) {

        question.isQuestionDependencyFulfilled = dependencyOn;
      }

      this.answers[index].map((a) => {
        if (
          a.hasAnswerDependency && a.hasQuestionDependency &&
          this.questionDependencyIsFulfilled(a.questionDependencyId, questionId)
        ) {
          const answers = ',' + a.answersDependency + ',';
          if (isUnknownChecked || (answerType !== 'checkbox' && answers.indexOf(',' + answerId + ',') > -1)) {
            a.isAnswerDependencyFulfilled = dependencyOn;
            a.isQuestionDependencyFulfilled = dependencyOn;
          } else if (answerType === 'checkbox' && this.checkedAnswersIncludeDependency(checkedAnswerIds, answers)) {
            a.isAnswerDependencyFulfilled = true;
            a.isQuestionDependencyFulfilled = true;
          } else {
            a.isAnswerDependencyFulfilled = false;
            a.isQuestionDependencyFulfilled = false;
          }
        } else if (!a.hasAnswerDependency && a.hasQuestionDependency && this.questionDependencyIsFulfilled(a.questionDependencyId, questionId)) {
          a.isQuestionDependencyFulfilled = dependencyOn;
        }
      });

      let answer = question.liqAnswers.find(a => a.id === answerId);
      if (answer != undefined && answer.label.toLowerCase() === 'no'
        && answerType === 'radio' && question.displayDependencyQuestion === false) {
        console.log('No Checked');
        this.questionnaire.liqQuestions.map((question_) => {
          if (question_.questionDependencyId === questionId) {
            question_.showDependencyQuestion = false;
          }
        });
      } else if (answer != undefined && (answer.label.toLowerCase() === 'yes' || question.displayDependencyQuestion === true)
        && answerType === 'radio') {
        console.log('Yes Checked');
        this.questionnaire.liqQuestions.map((question_) => {
          if (question_.questionDependencyId === questionId) {
            question_.showDependencyQuestion = true;
          }
        });
      }

      if (answer != undefined && answer.label.toLowerCase() === 'other'
        && answerType === 'checkbox' && question.id === questionId) {
        this.isSelectOtherCheckBox = this.group.value[key][answer.id];
      }
    });
  }

  checkedAnswersIncludeDependency(checkedAnswerIds, answersDependency) {
    return checkedAnswerIds.find(checkedId => {
      return answersDependency.indexOf(',' + checkedId + ',') > -1;
    });
  }

  questionDependencyIsFulfilled(questionDependencyId, questionId) {
    return +questionDependencyId === +questionId;
  }


  unknownCheck(key) {
    this.questionnaire.liqQuestions[key].showAnswers = !this.questionnaire.liqQuestions[key]
      .showAnswers;

  }

  isQuestionDependencyFulfilled(question) {
    return question.isQuestionDependencyFulfilled;
  }

  isAnswerDependencyUnfulfilled(answer) {
    return !answer.isAnswerDependencyFulfilled || !answer.isQuestionDependencyFulfilled;
  }

  isCheckboxAnswerType(question) {
    return question.answerType === 'checkbox';
  }

  showIDontKnowCheckbox(question) {
    return question.unknown !== null;
  }

  showAlertMessage(objResult) {
    if (!objResult) {
      return;
    }

    if (objResult.redirectButtonName === null) {
      this.utilService.showAlert(objResult.title, objResult.description, 2);
      this.goToRedirectPage(
        objResult.redirectPage,
        objResult.redirectParams);
    } else {
      const homeUrl = '/home';
      const alertModal = this.modalService.open(BootstrapModalComponent);
      alertModal.componentInstance.title = objResult.title;
      alertModal.componentInstance.message = objResult.description;
      alertModal.componentInstance.date = objResult.userAnswerSetDate;
      alertModal.componentInstance.confirmButtonLabel = objResult.redirectButtonName;
      alertModal.componentInstance.closeButtonLabel = 'Cancel';
      alertModal.result.then((re) => {
        if (re === false || re === undefined) {
          this.router.navigate([homeUrl]);
          location.reload();
          window.scroll(0, 0);
        } else {
          this.goToRedirectPage(
            objResult.redirectPage,
            objResult.redirectParams);
        }
      });
    }
  }

  goToRedirectPage(pageName, redirectParams) {
    if (redirectParams) {
      redirectParams = JSON.parse(redirectParams);
    }
    if (pageName !== null) {
      switch (pageName) {
        case 'QuestionnairesPage':
          this.router.navigate([this.path]);
          window.scroll(0, 0);
          break;
        case 'QuestionnairePage':
          this.router.navigate([this.path + '/', redirectParams.id]);
          window.scroll(0, 0);
          break;
        case 'PartnersPage':
          this.router.navigate(['/toolkit/partners']);
          window.scroll(0, 0);
          break;
        case 'PartnerPage':
          this.router.navigate(['/toolkit/partners', redirectParams.partnerId]);
          window.scroll(0, 0);
          break;
        case 'ContentListPage':
          // TODO: needs more params
          this.router.navigate(['/home']);
          window.scroll(0, 0);
          break;
        case 'ContentDetailPage':
          this.router.navigate(['/home/' + redirectParams.type + '/' + redirectParams.contentId]);
          window.scroll(0, 0);
          break;
        default:
          this.router.navigate([this.path + '/', pageName]);
          window.scroll(0, 0);
      }
    } else {
      this.router.navigate(['/home']);
      window.scroll(0, 0);
    }
  }

  OnInput(value) {
    this.otherCheckBoxAnswerValue = value;
  }
}
