import { DateService } from '../../services/date.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user-service';

@Component({
  selector: 'app-settings',
  templateUrl: 'settings.component.html',
  styleUrls: ['settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  user: any;
  hours: any;
  message: any;

  constructor(
    private userService: UserService,
    public dateService: DateService,
    public activatedRoute: ActivatedRoute,
  ) {
    this.user = this.userService.profile;
  }

  ngOnInit() {
    this.user = this.userService.profile;
    this.message = `Good ${this.dateService.getTimePartOfDay()}`;
  }

}
