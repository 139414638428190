<div class="widget-card">
  <div class="widget-card-header backPrimary">
    My Rewards
  </div>
  <div class="w-100 text-center">
    <div>
      <img [src]="showMyRewardsSrc" alt="Show my rewards" class="w-100">
    </div>
    <div class="text-left backPrimary">
      <p class="p-2" *ngIf="hasCompanyRewardsWidgetText" [innerHtml]="companyRewardsWidgetText"></p>
      <p class="p-2" *ngIf="!hasCompanyRewardsWidgetText" [innerHtml]="genericHTML"></p>
      <div>
        <button
          class="btn btn-link btn-line"
          type="submit"
          (click)="onViewRewardsClick()">
          <i class="fa fa-eye" aria-hidden="true"></i>
          View rewards
        </button>
      </div>
    </div>
  </div>
</div>
