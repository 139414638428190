<h2 class="mb-4 mobile-heading">Friends</h2>

<app-suggested-friends *ngIf="canShowSuggestedFriends"
  [suggestedFriends]="suggestedFriends"
  (viewFriend)="onViewFriend($event)"
  (addFriend)="onAddFriend($event)"
></app-suggested-friends>

<app-search-term
  (searchTermEntered)="onSearchTermEntered($event)"
></app-search-term>

<app-search-friends-result *ngIf="canShowSearchResults"
  [friends]="searchedFriends"
  (viewFriend)="onViewFriend($event)"
  (addFriend)="onAddFriend($event)"
></app-search-friends-result>

<div *ngIf="canShowEmptySearchResult"
  class="card search-card">
  <p class="p-1 mt-3">No results found...</p>
</div>

<div class="mb-4" id="components-separator"></div>

<ngb-tabset #tabset class="w-100">
  <ngb-tab title="Connected" id="connected">
    <ng-template ngbTabContent>
      <app-friends-list
        [friends]="friends"
        noFriendsText="No friends added yet"
        (viewFriend)="onViewFriend($event)"
        (selectAction)="onActionSelected($event)"
      ></app-friends-list>
    </ng-template>
  </ngb-tab>

  <ngb-tab title="Requests" id="requests">
    <ng-template ngbTabContent>
      <app-friends-list
        [friends]="requestsReceived"
        noFriendsText="No new friend requests"
        (viewFriend)="onViewFriend($event)"
        (selectAction)="onActionSelected($event)"
      ></app-friends-list>
    </ng-template>
  </ngb-tab>

  <ngb-tab title="Outgoing" id="outgoing">
    <ng-template ngbTabContent>
      <app-friends-list
        [friends]="requestsSent"
        noFriendsText="No outgoing requests"
        (viewFriend)="onViewFriend($event)"
        (selectAction)="onActionSelected($event)"
      ></app-friends-list>
    </ng-template>
  </ngb-tab>
</ngb-tabset>
