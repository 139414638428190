<section class='container company-register-info-section'>
  <div class='flex-column card'>
    <div class='card-body company-register-info-section__body'>
      <h5 class='card-title'>{{title}}</h5>
      <mat-divider></mat-divider>
      <form
        *ngIf='companyRegisterInfo && form'
        [formGroup]='form'
        class='mt-3'>
        <div class='form-group'>
          <label class='control-label required font-weight-bold'>Your Organisation’s Display Name</label>
          <span class='label-desc'>(Used as the name of your organisation in AltiusLife)</span>
          <input
            [ngClass]="{'is-invalid' : form.get('name').touched && form.get('name').invalid }"
            class='form-control'
            formControlName='name'
            type='text' />
          <span class='invalid-feedback'>Name is required</span>
        </div>
        <div class='form-group'>
          <label class='control-label required font-weight-bold'>Your Organisation’s Registration Code</label>
          <span class='label-desc'>(Used to register as a user on the mobile app, and will be used to generate the start of your AltiusLife URL as default e.g., <i>yourorgcode</i>.{{mainDomain}})</span>
          <input
            [ngClass]="{'is-invalid' : form.get('registerCode').touched && form.get('registerCode').invalid }"
            class='form-control'
            formControlName='registerCode'
            type='text' />
          <span class='invalid-feedback' *ngIf="form.get('registerCode').errors?.required">Register code is required</span>
          <span class='invalid-feedback' *ngIf="form.get('registerCode').errors?.maxlength">Max 22 Characters</span>
          <span class='invalid-feedback' *ngIf="form.get('registerCode').errors?.pattern">Register code is invalid format (Only A-Z, a-z, space or 0-9 are accept)</span>
          <span class='invalid-feedback' *ngIf="form.get('registerCode').errors?.existed">Your registration code <i>{{form.get('registerCode').value}}</i> is already existed, Please create another register code!</span>
        </div>

        <div class='form-group'>
          <label class='control-label required font-weight-bold'>Your Organisation’s Domain Name</label>
          <span class='label-desc'>(You can set a different domain name for your organization instead of using the default value which is same of registration code)</span>
          <div class='input-group'>
            <input
              [ngClass]="{'is-invalid' : form.get('domainName').touched && form.get('domainName').invalid }"
              class='form-control'
              formControlName='domainName'
              type='text' />
            <div class='input-group-prepend'>
              <button class='input-group-text btn btn-primary' type='button' disabled>
                {{this.mainDomain}}
              </button>
            </div>
            <span class='invalid-feedback' *ngIf="form.get('domainName').errors?.required">Domain name is required</span>
            <span class='invalid-feedback' *ngIf="form.get('domainName').errors?.maxlength">Max 22 Characters</span>
            <span class='invalid-feedback' *ngIf="form.get('domainName').errors?.pattern">Domain name is invalid format (Only A-Z, a-z or 0-9 are accept)</span>
            <span class='invalid-feedback' *ngIf="form.get('domainName').errors?.existed">Your Altiuslife URL: <i>{{form.get('domainName').value}}.{{mainDomain}}</i> is existed, Please choose another domain name!</span>
          </div>
        </div>

        <div class='booking-container border d-inline-block w-100 mt-2'>
          <p class='font-weight-bold'>Online Booking Integration</p>
          <p>Please refer to your onboarding documentation for your organisation's booking code</p>
          <div class='code-container mt-1' [class.mb-0]='bookingCodeInvalid' [class.mb-3]='!bookingCodeInvalid'>
            <span>Booking Code</span>
            <app-booking-code [form]='form' [formControlName]='"bookingCode"' [cssClass]='"booking-code"' (lostFocusEvent)='bookingCodeValidationHandler($event)' (keyUpEvent)='bookingCodeValidationHandler($event)'></app-booking-code>
            <button (click)='openBookingSite()' class='btn btn-primary' type='button' [disabled]='disableVerifyBookingCode'>
              Visit Booking Site to Verity Booking Code
            </button>
          </div>
          <span *ngIf='bookingCodeInvalid' class='booking-code-invalid mb-3'>Booking Code is invalid</span>
        </div>

        <div class='form-group w-100 d-inline-block position-relative'>
          <ng-template #logoPreview>
            <app-logo-preview [logoSrc]='logo'></app-logo-preview>
          </ng-template>
          <div class='logoInput'>
            <label [disableTooltip]='!logo' [ngbTooltip]='logoPreview' class='control-label required font-weight-bold'
                   for='logo'
                   placement='top'>
              Upload your logo
            </label>
            <span class='label-desc'>(Your logo will appear in AltiusLife and in your Online Booking Site)</span>
          </div>
          <span class='logoImg d-flex align-items-center justify-content-center'>
            <img class='align-middle' src='{{logo}}' />
          </span>
          <div class='input-group logoInput'>
            <input class='form-control' formControlName='logo' id='logo' name='logo'
                   placeholder='Choose the logo' readonly type='text'
                   [ngClass]="{'is-invalid' : form.get('logo').touched && form.get('logo').invalid }" />
            <div class='input-group-prepend'>
              <button (click)='openFilestack()' class='input-group-text btn btn-primary' type='button'>
                Browse
              </button>
            </div>
            <span class='invalid-feedback'>Logo is required</span>
          </div>
        </div>

        <!--        <div class="custom-control custom-checkbox mb-1" >-->
        <!--          <input id="company__eap-checkbox" type="checkbox"-->
        <!--                 formControlName="enabledEAPFeature"-->
        <!--                  class="custom-control-input pointer-event">-->
        <!--          <label class="custom-control-label font-weight-bold" for="company__eap-checkbox">Enable EAP Booking</label>-->
        <!--        </div>-->

        <div class='px-3 py-4 border d-inline-block w-100 mt-2'>
          <div class='text-center font-weight-bold'>Set up the colour Scheme of your organisation AltiusLife
            experience
          </div>
          <span class='label-desc text-center'>Click the <i class='fa fa-search'></i> icon to preview each colour in context</span>
          <div class='form-group my-3'>

            <div class='w-100 '>
              <ng-template #primaryColor>
                <app-widget-preview [primaryColor]='form.value.primaryColor'></app-widget-preview>
              </ng-template>
              <label
                class='control-label required'>Primary Colour (Buttons
                and Widgets)</label>
              <i
                [ngbTooltip]='primaryColor'
                [autoClose]="'outside'"
                [disableTooltip]="form.get('primaryColor').invalid"
                placement='right'
                triggers='click'
                class='fa fa-search ml-2 pointer-cursor'
              ></i>
            </div>
            <span (colorPickerChange)="changeColor('primaryColor', $event)"
                  [colorPicker]='form.value.primaryColor'
                  [cpOKButton]='true' [cpOutputFormat]="'hex'"
                  [style.backgroundColor]='form.value.primaryColor'
                  class='colorDiv'></span>
            <input
              [ngClass]="{'is-invalid' : form.get('primaryColor').touched && form.get('primaryColor').invalid }"
              class='form-control colorTextBox' formControlName='primaryColor'
              maxlength='9'
              type='text' />
            <span class='invalid-feedback'>This field is required or invalid hex format</span>
          </div>

          <div class='form-group my-3'>
            <div class='w-100'>
              <ng-template #topTextBarColor>
                <app-navigation-preview
                  [topTextBarColor]='form.value.topTextBarColor'></app-navigation-preview>
              </ng-template>
              <label class='control-label required'>Colour of Text on Navigation Menu and Footer</label>
              <i
                [ngbTooltip]='topTextBarColor'
                [autoClose]="'outside'"
                [disableTooltip]="form.get('topTextBarColor').invalid"
                placement='right'
                triggers='click'
                class='fa fa-search ml-2 pointer-cursor'>
              </i>
            </div>
            <span (colorPickerChange)="changeColor('topTextBarColor', $event)"
                  [colorPicker]='form.value.topTextBarColor'
                  [cpOKButton]='true' [cpOutputFormat]="'hex'"
                  [style.backgroundColor]='form.value.topTextBarColor'
                  class='colorDiv'></span>
            <input
              [ngClass]="{'is-invalid' : form.get('topTextBarColor').touched && form.get('topTextBarColor').invalid }"
              class='form-control colorTextBox' formControlName='topTextBarColor'
              maxlength='9'
              type='text' />
            <span class='invalid-feedback'>This field is required or invalid hex format</span>
          </div>

          <div class='form-group my-3'>
            <div class='w-100'>
              <ng-template #bodyTextColor>
                <app-article-preview [bodyTextColor]='form.value.bodyTextColor'></app-article-preview>
              </ng-template>
              <label class='control-label required'>Colour of text within
                AltiusLife (article/recipe text)</label>
              <i [ngbTooltip]='bodyTextColor'
                 [autoClose]="'outside'"
                 [disableTooltip]="form.get('bodyTextColor').invalid"
                 placement='right'
                 triggers='click'
                 class='fa fa-search ml-2 pointer-cursor'></i>
            </div>
            <span (colorPickerChange)="changeColor('bodyTextColor', $event)"
                  [colorPicker]='form.value.bodyTextColor'
                  [cpOKButton]='true' [cpOutputFormat]="'hex'"
                  [style.backgroundColor]='form.value.bodyTextColor'
                  class='colorDiv'></span>
            <input
              [ngClass]="{'is-invalid' : form.get('bodyTextColor').touched && form.get('bodyTextColor').invalid }"
              class='form-control colorTextBox' formControlName='bodyTextColor'
              maxlength='9'
              type='text' />
            <span class='invalid-feedback'>This field is required or invalid hex format</span>
          </div>
        </div>

        <div class='d-flex justify-content-around align-items-center mt-5'>
          <button (click)='onPreview()' class='btn btn-outline-primary w-25' type='button'>
            Preview your AltiusLife
          </button>

          <button (click)='onSubmit()' class='btn btn-primary w-25' type='button'>
            Submit
          </button>
        </div>

      </form>
      <div *ngIf='infoMessage'
           class='company-register-info-section__error d-flex flex-column justify-content-center align-items-center'>
        <div><i class='icon-3rem' [ngClass]='iconClass' aria-hidden='true'></i></div>
        <h5 class='mt-2'>{{infoMessage}}</h5>
        <h5 class='mt-2'>You will be redirected to your site in {{countDownTimes < 10 ? '0' + countDownTimes : countDownTimes}} seconds</h5>
      </div>
    </div>
  </div>
</section>
