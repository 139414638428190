import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user-service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mobile-app-download-cta',
  templateUrl: './mobile-app-download-cta.component.html',
  styleUrls: ['./mobile-app-download-cta.component.scss']
})
export class MobileAppDownloadCtaComponent implements OnInit {
  brand: string;
  is1700: Boolean = false;
  isAltiusLife: Boolean = false;
  isBFFLife: Boolean = false;

  applinksObj: Array<any> = [
    {
      appLink: '',
      imgSrc: 'assets/img/applinks/appdownload.png'
    },
    {
      appLink: '',
      imgSrc: 'assets/img/applinks/googledownload.png'
    }
  ];
  constructor(private userService: UserService, private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.applinksObj[0].appLink = this.userService.envVariables.appDownloadLinks.apple;
    this.applinksObj[1].appLink = this.userService.envVariables.appDownloadLinks.google;

    this.brand = this.userService.brand;

    switch(this.brand){
      case '1700':
        this.is1700 = true
        break;
      case 'AltiusLife':
        this.isAltiusLife = true
        break;
      case 'BFFLife':
        this.isBFFLife = true
        break;
      default:
        this.is1700 = true
        break;
    }
  }

  close() {
    this.activeModal.dismiss();
  }
}
