<div ngbDropdown class='d-inline-block'
     #profileDropdown='ngbDropdown'
     placement='bottom-right'
     [autoClose]="'outside'"
     (mouseenter)='onMouseEnter($event)'
     (mouseleave)='onMouseLeave($event)'
>

  <a class='nav-link nav-menu' ngbDropdownToggle>
    <app-avatar
      [photoSrc]='photoSrc'
      [levelSrc]='levelSrc'>
    </app-avatar>
  </a>

  <div ngbDropdownMenu>
    <li class='nav-item'>
      <a class='nav-link' (click)='onMyProfileSelected()'>
        My profile
      </a>
    </li>
    <li *ngIf='canShowSettings'
        class='nav-item'>
      <a class='nav-link' (click)='onSettingsSelected()'>
        Settings
      </a>
    </li>
    <li class='nav-item' *ngIf='canShowNotifications'>
      <a class='nav-link' (click)='onNotificationsSelected()'>
        Notifications
      </a>
    </li>
    <li class='nav-item' *ngIf='canShowPreferences'>
      <a class='nav-link' (click)='onPreferencesSelected()'>
        Preferences
      </a>
    </li>
    <li class='nav-item' *ngIf='canShowTutorials'>
      <a class='nav-link' (click)='onTutorialsSelected()'>
        Tutorials
      </a>
    </li>
    <li class='nav-item' *ngIf='canShowBookmarks'>
      <a class='nav-link' (click)='onBookmarksSelected()'>
        Bookmarks
      </a>
    </li>
    <li *ngIf='canShowCms'
        class='nav-item'>
      <a class='nav-link' (click)='onCmsSelected()'>
        Admin (Settings)
      </a>
    </li>
    <li *ngIf='canShowInsights'
        class='nav-item'>
      <a class='nav-link' (click)='onInsightsSelected()'>
        EAP Dashboard
      </a>
    </li>
    <li class='nav-item'>
      <a class='nav-link' (click)='onLogout()'>
        Logout
      </a>
    </li>
  </div>
</div>
