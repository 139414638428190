<section *ngIf='!isLoading' class='marketing-poster-section d-flex align-items-center flex-column'>
  <div *ngIf='!previewMode' class='d-flex justify-content-end my-2 marketing-poster-section__actions '>
<!--    <button (click)='onPreviewPDF()' [disabled]='isProcessing' class='btn btn-primary mx-2'-->
<!--            data-placement='top' data-toggle='tooltip' title='Preview' type='button'>-->
<!--      <i aria-hidden='true' class='fa fa-eye'></i>-->
<!--    </button>-->
    <button (click)='onGeneratePDF()' [disabled]='isProcessing' class='btn btn-primary' title='Download' type='button'>
      <i aria-hidden='true' class='fa fa-download'></i>
    </button>
  </div>
  <div #marketingPoster class='marketing-poster-section__content' (click)='onGeneratePDF()'>
    <div class='marketing-poster-section__content__design'>
      <img alt='poster' src='assets/img/marketing-poster/design.jpg'>
    </div>
    <div class='marketing-poster-section__content__domain'>
      <span>{{domain}}</span>
    </div>
    <div class='marketing-poster-section__content__code'>
      <span>{{registrationCode}}</span>
    </div>
    <div class='marketing-poster-section__content__ios'>
    </div>
    <div class='marketing-poster-section__content__android'>
    </div>
  </div>
</section>
