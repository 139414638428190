<div class="card" *ngIf="partner">
  <div class="img-size-div">
    <img class="card-img-top img-size" [src]="formatImagePath(partner.image)" alt="Card image cap">
  </div>
  <div class="card-body">
    <h5 class="card-title">{{partner.title}}</h5>
    <p class="card-text" [innerHtml]="longHtml"></p>
    <a [href]="partner.website" class="card-link" target="_blank">{{partner.website}}</a>
  </div>
  <div class="container" *ngIf="partner.formFields">
    <h5 class="card-title">Enquire Now!</h5>
    <form [formGroup]="enquiryForm" novalidate>
      <div class="form-group" *ngFor="let key of objectKeys(enquiryForm.controls)">
        <div *ngIf="key !== 'checkBox'">
          <label>{{fieldNamesReadability(key)}}
            <sup *ngIf="enquiryForm.get(key).invalid" class="control-label required">*</sup>
          </label>
          <input *ngIf="key !== 'phone'" type="text" class="form-control" [formControlName]="key" [id]="key">
          <input *ngIf="key == 'phone'" type="number" class="form-control" [formControlName]="key" [id]="key">
        </div>
      </div>
      <div class="form-group">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" id="checkBox" formControlName="checkBox" class="custom-control-input">
          <label class="custom-control-label" for="checkBox"></label>
          I agree to
          <a (click)="openTermsPage()">Terms of Service</a> and
          <a (click)="openPrivacyPage()">Privacy Policy</a>
        </div>
      </div>
      <button [disabled]="!enquiryForm.valid || submitDisable" type="submit" class="btn btn-primary" (click)="submitEnquiry()">Submit</button>
    </form>
  </div>
  <div *ngIf="thankYouMessage" class="container">
    <h5 class="text-center"> Thank you for your enquiry</h5>
  </div>
</div>
