
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LikeService {
    constructor(
        private http: HttpClient,
    ) {
    }

    like(contentId, contentType, userProfileId) {
        let xcontentType;

        switch (contentType) {
            case 1:
                xcontentType = 'article';
                break;
            case 2:
                xcontentType = 'recipe';
                break;
            case 3:
                xcontentType = 'video';
                break;
            default:
                xcontentType = contentType;
        }
        const url = `/likes`;
        const body = {
            contentId: contentId,
            contentType: xcontentType,
            userProfileId: userProfileId
        };
        return this.http.post<any>(url, body);
    }

    unlike(contentId, contentType) {
        let xcontentType;
        switch (contentType) {
            case 1:
                xcontentType = 'article';
                break;
            case 2:
                xcontentType = 'recipe';
                break;
            case 3:
                xcontentType = 'video';
                break;
            default:
                xcontentType = contentType;
        }
        const url = `/likes/${xcontentType}/${contentId}`;
        return this.http.delete<any>(url);
    }

}
