import { Component, ViewChild, Input, OnInit } from '@angular/core';

import { Chart } from 'chart.js';

@Component({
  selector: 'app-line-graph',
  templateUrl: './line-graph.html',
})

export class LineGraphComponent implements OnInit {
  @Input('data') data: any[];
  @Input('labels') labels: any;
  @Input('legend') legend: any;
  @Input('color') color: any;
  @Input('fill') fill: any;
  @Input('legendLabel') legendLabel: any;
  @Input('legendLabelTwo') legendLabelTwo: any;
  @Input('pointColor') pointColor: any;
  @Input('pointRadius') pointRadius: any;
  @Input('maxValue') maxValue: any;
  @Input('minValue') minValue: any;
  @Input('maxLabelRot') maxLabelRot: any;
  @Input('minLabelRot') minLabelRot: any;
  @Input('dataValue') dataValue: any;
  @Input('lineTension') lineTension: any;
  @Input('dataTwo') dataTwo: any;
  @Input('colorTwo') colorTwo: any;
  @Input('autoSkip') autoSkip: any;
  @Input('hideGrids') hideGrids: boolean = false;
  @Input('title') title: string = '';
  @Input('xTitle') xTitle: string = '';
  @Input('yTitle') yTitle: string = '';

  @ViewChild('lineCanvas') lineCanvas;
  lineChart: any;

  constructor() {
  }

  ngOnInit() {
    this.createLineChart();
  }

  createLineChart() {
    this.lineChart = new Chart(this.lineCanvas.nativeElement, {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: [
          {
            fill: this.fill,
            label: this.legendLabel,
            lineTension: this.lineTension,
            backgroundColor: this.color,
            borderColor: this.color,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#FFFFFF',
            pointBackgroundColor: this.pointColor,
            pointRadius: this.pointRadius,
            pointHoverBackgroundColor: this.pointColor,
            pointHoverBorderColor: this.pointColor,
            data: this.data,
            spanGaps: false,
          },
        ],
      },
      options: {
        title: {
          display: !!this.title,
          text: this.title,
        },
        animation: {
          onProgress: this.dataValue,
          onComplete: this.dataValue,
        },
        layout: {
          padding: {
            top: 30,
            right: 30,
          },
        },
        hover: { animationDuration: 1 },
        legend: {
          display: this.legend,
          position: 'top',
          fullWidth: true,
        },
        scaleOverride: true,
        scaleSteps: 10,
        scaleStepWidth: 20,
        scaleStartValue: 0,
        scales: {
          yAxes: [{
            ticks: {
              max: this.maxValue,
              min: this.minValue,
              // maxTicksLimit: 4,
              beginAtZero: true,
              // stepWidth: 2
            },
            gridLines: {
              drawOnChartArea: !this.hideGrids,
            },
            scaleLabel: {
              display: !!this.yTitle,
              labelString: this.yTitle
            }
          }],
          xAxes: [{
            ticks: {
              min: 0,
              maxTicksLimit: 5,
              autoSkip: this.autoSkip,
              beginAtZero: true,
              maxRotation: this.maxLabelRot,
              minRotation: this.minLabelRot,
            },
            gridLines: {
              drawOnChartArea: !this.hideGrids,
            },
            scaleLabel: {
              display: !!this.xTitle,
              labelString: this.xTitle
            }
          }],
        },
      },
      plugins: [{
        beforeInit: function(chart, options) {
          chart.legend.afterFit = function() {
            this.height = this.height + 30;// increase spacing between legend and chart
          };
        },
      }],
    });
  }
}
