import { Pipe, PipeTransform } from '@angular/core';

/**
 * A simple string filter, since Angular does not yet have a filter pipe built in.
 */
@Pipe({
    name: 'stringFilter'
})
export class StringFilterPipe implements PipeTransform {

    transform(value: any[], q: string, col: number) {
        if (!q || q === '') {
            return value;
        }
        return value.filter(item => -1 < item[col].toLowerCase().indexOf(q.toLowerCase()));
    }
}
