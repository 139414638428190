import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { DateService } from '../../../../../services/date.service';
import { LifeActionService } from '../../../../../services/life-action-service';
import { IIndicatorTracker } from '../../../../_models/indicator-tracker';

@Component({
  selector: 'app-active-life-action-history',
  templateUrl: './active-life-action-history.component.html',
  styleUrls: ['./active-life-action-history.component.scss'],

})
export class ActiveLifeActionHistoryComponent implements OnChanges, OnInit {
  @Input('recurrenceValue') recurrenceValue: number;
  @Input('trackers') trackers: IIndicatorTracker[];
  @Input('id') id: string;
  dates: any;
  lastCompletedDateLabel: string;

  constructor(
    private lifeActionService: LifeActionService,
    private dateService: DateService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.trackers = changes.trackers.currentValue;
    this.getDisplayProperties(this.recurrenceValue);
  }

  ngOnInit() {
    this.getDisplayProperties(this.recurrenceValue);
  }

  getDisplayProperties(recurrenceValue) {
    this.getDateLabels(recurrenceValue);
    if (this.trackers.length) {
      this.formatReadableDateDefaultingToToday(this.trackers[0].date);
    }
    this.mapUserValuesToHistoryDateRange(this.trackers, this.dates);
  }

  getDateLabels(recurrenceValue) {
    this.dates = this.lifeActionService.getDatesByRecurrencePeriod(recurrenceValue);
  }

  mapUserValuesToHistoryDateRange(trackers, dates) {
    this.lifeActionService.mapUserValuesToHistoryDateRange(trackers, dates);
  }

  formatReadableDateDefaultingToToday(date) {
    this.lastCompletedDateLabel = this.dateService.formatReadableDateDefaultingToToday(date);
  }

  formatDisplayDate(recurrenceValue, date) {
    let displayString = '';
    switch (recurrenceValue) {
      case 30:
        displayString = this.dateService.formatMMM(date);
        break;
      case 7:
        displayString = this.dateService.formatDDMM(date);
        break;
      case 1:
        displayString = this.dateService.formatDay(date);
        break;
      default:
        displayString = this.dateService.formatDay(date);
        break;
    }
    return displayString;
  }

  setDisplayValue(achieved) {
    let displayString = '';
    switch (achieved) {
      case true:
        displayString = 'fa fa-circle primaryColor';
        break;
      case false:
        displayString = 'fa fa-circle-o primaryColor';
        break;
      default:
        displayString = 'fa fa-circle-o primaryColor';
        break;
    }
    return displayString;
  }
}
