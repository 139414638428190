import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '../../../../services/auth-service';
import { BootstrapModalComponent } from '../../../../components/bootstrap-modal/bootstrap-modal.component';
import { AuthRoutePath } from '../../../_models/auth';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent implements OnInit {
  // Template display logic
  isLoading: boolean;
  isStripeSubscriptionRequired: boolean;
  isSubscriptionReactivationRequired: boolean;
  isThirdPartyRedirect: boolean;

  // parentForm
  form: FormGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private authService: AuthService,
  ) {

  }

  initialiseVariables() {
    this.isLoading = false;
    this.isStripeSubscriptionRequired = false;
    this.isSubscriptionReactivationRequired = false;
    this.isThirdPartyRedirect = false;
  }

  ngOnInit() {
    this.initialiseVariables();
    this.initialiseResetPasswordForm();
    this.handleRouteParams();
  }

  handleRouteParams() {
    this.route.queryParams.subscribe((p) => {
      if (p.token) {
        this.form.patchValue({
          resetPasswordToken: p.token
        });
      }
    });
  }

  disableFormAndEnableLoader() {
    this.form.disable();
    this.isLoading = true;
  }

  enableFormAndDisableLoader() {
    this.form.enable();
    this.isLoading = false;
  }

  isFormSubmitDisabled() {
    return this.form.invalid || this.isLoading;
  }

  get password() { return this.form.get('password'); }

  get confirmPassword() { return this.form.get('confirmPassword'); }

  get resetPasswordToken() { return this.form.get('resetPasswordToken'); }

  setInputOutlineClass(ctrl: AbstractControl) {
    return this.authService.setInputOutlineClass(ctrl);
  }

  initialiseResetPasswordForm() {
    this.form = this.fb.group({
      resetPasswordToken: [''],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    }, {
      validators: [
        this.authService.passwordMatchesValidator,
        this.authService.passwordPatternValidator
      ]
    });
  }

  onSubmitResetPasswordForm() {
    if (this.form.invalid) { return; }
    this.disableFormAndEnableLoader();
    const _body = {
      token: this.resetPasswordToken.value,
      password: this.password.value
    };
    this.authService.changePassword(_body).subscribe((res) => {
      this.enableFormAndDisableLoader();
      this.handleResetPasswordResponse(res);
    });
  }

  private handleResetPasswordResponse(res) {
    if (res.isTokenNotFoundError) {
      this.showResetPasswordTokenNotFoundError();
    } else {
      this.showResetPasswordSuccess();
    }
  }

  private showResetPasswordTokenNotFoundError() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Could not reset password';
    modal.componentInstance.message = `We could not reset your password at this time. Please request a new reset password link.`;
    modal.componentInstance.singleButton = true;
    modal.result.then(() => this.router.navigateByUrl(AuthRoutePath.forgetPassword));
  }

  private showResetPasswordSuccess() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Password reset!';
    modal.componentInstance.message = `Please login with your new password.`;
    modal.componentInstance.confirmButtonLabel = 'Take me to login';
    modal.result.then(() => this.router.navigateByUrl(AuthRoutePath.login));
  }

}
