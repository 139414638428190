<div *ngIf="isDisplayingList && isInitialised">
  <div class="row mr-5 mb-2">
    <div class="col-10 col-sm-6">
        &nbsp;
    </div>
    <div class="col-2 col-sm-6 text-right pr-0 w-25">
        <button  type="button" class="btn btn-primary" (click)="onMultiDeleteClick()" [disabled]="selectItem.length == 0">
            <i class="fa fa-trash" aria-hidden="true"></i><br>
        </button>
      &nbsp;
      <button type="button" class="btn btn-primary" (click)="goToDetailLink('new')">
        <i class="fa fa-plus" aria-hidden="true"></i><br>
      </button>      
    </div>   
  </div>
  <div class="row">
    <div class="col-sm-3 mb-2" *ngFor="let i of items">
      <div class="card" style="width: auto;">
          <div class="custom-control custom-checkbox" style="position: absolute; left: 5px;">
              <input type="checkbox" class="custom-control-input" id="i{{i.id}}" (click)="onCheckClick(i.id)">
              <label class="custom-control-label" for="i{{i.id}}"></label>
            </div>
        <a class="contentAnchorTag" (click)="goToDetailLink(i.id)">
          <img class="card-img-top" src="{{i.image}}" alt="Card image cap">
          <div class="card-body">
            <p class="card-text text-center">{{i.name}}</p>
          </div>
        </a>
      </div>

    </div>
  </div>
</div>
<div *ngIf="(isAddingItem || isEditingItem) && isInitialised" class="padding">
  <form [formGroup]="itemForm">
    <!-- Section One -->
    <div *ngIf="createSectionOne" class="row m-0">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Team Name -->
            <div class="form-group">
              <label class="control-label required">Name of the team</label>
              <input type="text" class="form-control" [ngClass]="displayFieldCss('nameControl')"
                formControlName="nameControl" />
              <span class="invalid-feedback">Team Name is required</span>
            </div>
            <!-- Challenge Sellection -->
            <div class="form-group">
              <label class="control-label required">Challenge</label>
              <select type="text" class="form-control custom-control" [ngClass]="displayFieldCss('challengeId')"
                formControlName="challengeId">
                <option *ngFor="let item of challenges" [value]="item.id">
                  {{item.name}}
                </option>
              </select>
              <span class="invalid-feedback">a Challenge is required</span>
            </div>
            <!-- image -->
            <div class="form-group">
              <label for="image" class="control-label required">
                Upload image for the team
              </label>
              <div class="input-group">
                <input type="text" name="logo" id="logo" class="form-control" [ngClass]="displayFieldCss('image')"
                  placeholder="Choose the image" formControlName="image" readonly />
                <div class="input-group-prepend">
                  <button type="button" class="input-group-text btn btn-primary" (click)="openfilestack()">
                    Browse
                  </button>
                </div>
                <span class="invalid-feedback">Image is required</span>
              </div>
            </div>
            <!--- Buttons Block -->
            <div class="row">
              <div class="col-12 col-sm-4">
                <button type="button" class="btn btn-outline-primary w-100" (click)="navigateToList()">
                  <i class="fa fa-ban mr-2" aria-hidden="true"></i> CANCEL
                </button>
              </div>
              <div class="col-12 col-sm-4">
                <button type="button" class="btn btn-outline-primary w-100" (click)="onDeleteClick()"
                  [disabled]="isAddingItem">
                  <i class="fa fa-trash-o mr-2" aria-hidden="true"></i> DELETE
                </button>
              </div>
              <div class="col-12 col-sm-4">
                <button type="button" class="btn btn-primary float-right w-100"
                  [disabled]="!canRespondToButtons || !itemForm.valid" (click)="onSaveClick()">
                  <i class="fa fa-floppy-o mr-2" aria-hidden="true"></i> SAVE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Section Two -->
    <div *ngIf="createSectionTwo" class="row m-0">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <h4>Great Job</h4>
              <div class="card-body">
                <p class="card-text text-center">Your team <b>{{teamName}}</b> is {{action}} successfully</p>
              </div>
            </div>
            <!--- Buttons Block -->
            <div class="row">
              <div class="col-12 col-sm-6">
                <button type="button" class="btn btn-primary w-100" (click)="navigateToList()" [disabled]="loading">
                  <i class="fa fa-list mr-2" aria-hidden="true"></i> Navigate to Teams
                </button>
              </div>
              <div class="col-12 col-sm-6">
                <button type="button" class="btn btn-outline-primary float-right w-100" [disabled]="loading"
                  (click)="goToSectionOne()">
                  <i class="fa fa-arrow-right mr-2" aria-hidden="true"></i> Create another team</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>