<div *ngIf="isInitialised" class="padding jumbotron">

  <form [formGroup]="itemForm" (ngSubmit)="onSaveClick()">

    <div class="row m-0">
      <div class="col-12">
        <!-- Title -->
        <div class="form-group">
          <label class="control-label">Title</label>
          <input type="text" class="form-control" [ngClass]="displayFieldCss('title')" formControlName="title" />
        </div>

        <!-- image -->
<!--        <div class="form-group">-->
<!--          <label class="control-label">-->
<!--            Image-->
<!--          </label>-->
<!--          <div class="input-group">-->
<!--            <input type="text" name="logo" id="logo" class="form-control" [ngClass]="displayFieldCss('image')"-->
<!--              placeholder="Choose the image" formControlName="image" readonly />-->
<!--            <div class="input-group-prepend">-->
<!--              <button type="button" class="input-group-text btn btn-primary" (click)="openFileStack()">-->
<!--                Browse-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <br />-->

        <!--- longText -->
        <div class="form-group">
          <label class="control-label required">Content</label>
          <div [froalaEditor]="options" (froalaInit)="initialize($event)" formControlName="longText" [ngClass]="displayFieldCss('longText')"></div>
          <span class="invalid-feedback">Content is required.</span>
        </div>

<!--        <div class="previewButton py-3">-->
<!--          <button type="button" data-toggle="tooltip" data-placement="top" title="Preview" class="btn btn-primary btn-circle" [disabled]="!itemForm.valid"-->
<!--            (click)="showPreview()">-->
<!--            <div class="text">-->
<!--              <i class="fa fa-eye fa-2x" aria-hidden="true"></i>-->
<!--              <p>Preview</p>-->
<!--            </div>-->
<!--          </button>-->
<!--        </div>-->

        <!-- Buttons -->
        <div class="row">
          <div class='col-4'>
          </div>

          <div class='col-4'>
          </div>

          <div class='col-4'>
            <button type="submit" class="btn btn-primary float-right w-100" [disabled]="!canRespondToButtons">
              <i class="fa fa-floppy-o mr-2" aria-hidden="true"></i> SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>



