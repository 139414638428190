import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user-service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent implements OnInit {
  @Input() title;
  @Input() message;
  @Input() url;
  @Input() contentId;
  @Input() contentImage;
  @Input() contentTitle;
  @Input() contentLink;
  @Input() closeButtonRedirect;

  constructor(
    public router: Router,
    private activeModal: NgbActiveModal,
    private userService: UserService
  ) {}

  onNoClick(): void {
    if (this.closeButtonRedirect) {
      this.router.navigate([this.closeButtonRedirect]);
      this.activeModal.close();
    } else {
      this.activeModal.dismiss();
    }
  }
  openLink() {
    window.open(this.url, '_blank');
  }
  goToContent() {
    this.router.navigate(['home/article', this.contentId]);
  }

  getImage(path: string, image: string) {
    return this.userService.envVariables.aws.awsBucket + path + image;
  }
  ngOnInit() {}

  close() {
    this.activeModal.dismiss();
  }
}
