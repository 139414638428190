<app-date-selector [inputStartDate]="startDate" [inputEndDate]="endDate" [inputCompanyId]="companyId" [canSelectCompany]="canSelectCompany"
  (dateQuery)="onDateRangeSelected($event)">
</app-date-selector>

<div class="card-columns">
  <div *ngFor="let indicator of service.indicators; let i = index;" class="card w-100 text-center">
    <div class="card-header">
      <h5>{{indicator.name}}</h5>

    </div>
    <div *ngIf="indicator.hasEntries" class="row totals m-4 p-3 text-center">
      <div class="row w-100">
        <p class="col-9">
          <b>Total no. times tracked:</b>
        </p>
        <p class="col-3 text-right">{{indicator.trackedTotal}}</p>
      </div>

      <div class="row  w-100">
        <p class="col-9">
          <b>Total no. times users achieved their goal:</b>
        </p>
        <p class="col-3 text-right">{{indicator.achievedTotal}}</p>
      </div>
      <div class="row  w-100">
        <p class="col-9">
          <b>Total value of trackers:</b>
        </p>
        <p class="col-3 text-right">{{indicator.displayValueTotal}}</p>
      </div>

    </div>
    <div class="card-body">
      <table class="table table-striped table-borderless table-hover">
        <thead class="thead-light">
          <tr>
            <th class="table-text" scope="col">Date</th>
            <th class="table-number" scope="col">Tracked</th>
            <th class="table-number" scope="col">Achieved</th>
            <th class="table-number" scope="col">{{indicator.displayUnit}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let entry of indicator.displayDailySummaries">
            <td class="table-text">{{entry.date_ymd | date: 'E d MMM yyyy'}}</td>
            <td class="table-number">{{entry.count_value | number}}</td>
            <td class="table-number">{{entry.count_achieved | number}}</td>
            <td class="text-right">{{entry.displaySumValue}}</td>
          </tr>

          <td colspan="5">
            <button *ngIf="indicator.canDisplayMore"
              (click)="service.incrementIndicatorDisplayedDailySummaries(indicator)"
              class="btn btn-primary">View more</button>
          </td>
        </tbody>
      </table>

      <div *ngIf="!indicator.hasEntries">
        <p>No {{indicator.name}} entries for this date range</p>
      </div>
    </div>
  </div>
</div>
