<div class="container">
  <h5>Home Screen Preferences</h5>
  <div class="modal-footer">
    <button
      (click)="updatePreferences()"
      type="button"
      class="btn btn-md col-6"
    >
      Update
    </button>
    <button (click)="closeModal()" type="button" class="btn btn-md col-6">
      Cancel
    </button>
  </div>
  <div *ngFor="let heading of preferenceHeadings"
    class="pt-1">
    <h6 class="primaryColor">{{heading}}</h6>
    <ul class="list-group">
      <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let preference of preferences[heading]">
        <div class="custom-control custom-checkbox">
          <input id="{{preference.categoryName}}" type="checkbox" class="custom-control-input" [(ngModel)]="preference.activated">
          <label class="custom-control-label" for="{{preference.categoryName}}">{{preference.categoryName}}</label>
        </div>
      </li>

    </ul>
  </div>
    <div class="modal-footer">
      <button (click)="updatePreferences()" type="button" class="btn btn-md col-6">Update</button>
      <button (click)="closeModal()" type="button" class="btn btn-md col-6">Cancel</button>
    </div>
</div>
