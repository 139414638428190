<div class="container">
  <h3>Manage what type of notifications you receive</h3>
  <form *ngIf="settingsForm" [formGroup]="settingsForm" (submit)="submit(settingsForm.value)">
    <ul class="list-group">
      <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let notification of settings.controls; let i = index">

        <div class="custom-control custom-checkbox">
          <input id="{{notifications[i].name}}" type="checkbox" class="custom-control-input" [formControl]="notification" />
          <label class="custom-control-label" for="{{notifications[i].name}}">{{notifications[i].name}}</label>
        </div>
      </li>
    </ul>
    <div class="modal-footer">
      <button center type="submit" class="btn btn-primary col-6 mt-2 mb-2">Update</button>
      <button center (click)="closeModal()" type="button" class="btn btn-primary col-6 mt-2 mb-2">Cancel</button>
    </div>
  </form>
</div>
