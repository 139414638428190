<div *ngIf='isInitialised'>
  <app-date-selector
    [inputCompanyId]='companyId'
    [inputStartDate]='startDate'
    [inputEndDate]='endDate'
    [canSelectCompany]='canSelectCompany'
    (dateQuery)='onFilterSelected($event)'>
  </app-date-selector>

  <app-cms-list-view
    [dataList]='items'
    [columnList]='columns'
    [isPreventListDelete]='true'
    [isPreventAdd]='true'
    [isPreventViewDetail]='canViewDetail'
    [isUserList]='false'
    [selectedCompanyId]='companyId'
    [additionalNumericColumns] = '[2]'
  ></app-cms-list-view>
</div>
