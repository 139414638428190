import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../_shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PasswordInputComponent } from './inputs/password-input/password-input.component';
import { StripePlansComponent } from './stripe-plans/stripe-plans.component';
import { ThirdPartyLoginComponent } from './thirdPartyLogin/thirdPartyLogin.component';
import { TermsPrivacyInputComponent } from './inputs/terms-privacy-input/terms-privacy-input.component';
import { SecureLoginComponent } from './secure-login/secure-login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { EmailInputComponent } from './inputs/email-input/email-input.component';
import { CompanyAccessCodeInputComponent } from './inputs/company-access-code-input/company-access-code-input.component';
import { MsisdnInputComponent } from './inputs/msisdn-input/msisdn-input.component';
import { ResetPasswordTokenComponent } from './inputs/reset-password-token-input/reset-password-token-input.component';
import { RegisterFormComponent } from './forms/register-form/register-form.component';
import { LoginFormComponent } from './forms/login-form/login-form.component';
import { ForgetPasswordFormComponent } from './forms/forget-password-form/forget-password-form.component';
import { ResetPasswordFormComponent } from './forms/reset-password-form/reset-password-form.component';
import { StripePlansInputComponent } from './inputs/stripe-plans-input/stripe-plans-input.component';
import { AuthorizeComponent } from './authorize/authorize.component';
import { SsoLoginComponent } from './sso-login/sso-login.component';
import { SsoLogoutComponent } from './sso-logout/sso-logout.component';
import { CloseAccountComponent } from './close-account/close-account.component';

@NgModule({
  declarations: [
    PasswordInputComponent,
    StripePlansComponent,
    ThirdPartyLoginComponent,
    TermsPrivacyInputComponent,
    SecureLoginComponent,
    EmailInputComponent,
    MsisdnInputComponent,
    CompanyAccessCodeInputComponent,
    ResetPasswordTokenComponent,
    RegisterFormComponent,
    LoginFormComponent,
    ForgetPasswordFormComponent,
    ResetPasswordFormComponent,
    StripePlansInputComponent,
    AuthorizeComponent,
    SsoLoginComponent,
    SsoLogoutComponent,
    CloseAccountComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    NgbModule
  ],
  entryComponents: [
    StripePlansComponent,
    StripePlansInputComponent
  ],
  exports: [
    PasswordInputComponent,
  ]
})
export class AuthModule {}
