import { Component, NgZone, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IBadge, IBadgeType } from '../../_models/badge';

import { BadgeService } from '../../../services/badge-service';
import { CategoryService } from '../../../services/category-service';
import { UtilService } from '../../../services/util-service';
import { UserService } from '../../../services/user-service';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { DateService } from '../../../services/date.service';
import { BADGE_TYPES } from '../../_shared/enums';
@Component({
  selector: 'app-badge-management',
  templateUrl: './badge-management.component.html',
  styleUrls: ['./badge-management.component.scss']
})
export class BadgeManagementComponent implements OnInit {
  items: IBadge[];
  item: IBadge;
  itemType = 'Badge';
  columns = [];
  fields: any = {};
  minDate: Object;
  loading = false;
  itemForm: FormGroup;
  id: number;
  error = '';
  badgeLevels = [];
  domains = [];
  types: IBadgeType[] = [
    { value: BADGE_TYPES.TRACKED_INDICATOR, label: 'Tracked Indicator' },
    { value: BADGE_TYPES.ACHIEVED_INDICATOR, label: 'Achieved Indicator' },
    { value: 'indicatorDriver', label: 'Indicator Driver' },
    { value: 'article', label: 'Article' },
    { value: 'recipe', label: 'Recipe' },
    { value: 'video', label: 'Video' },
    { value: 'feature', label: 'Feature' },
    { value: 'questionnaire', label: 'Questionnaire' },
    { value: 'questionnaireResult', label: 'Questionnaire Result' },
    { value: 'program', label: 'Program' }
  ];
  typeId: any;
  type: string;

  isDisplayingList: boolean;
  isInitialised: boolean;
  isEditingItem: boolean;
  isAddingItem: boolean;
  canRespondToButtons: boolean;

  constructor(
    private badgeService: BadgeService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private utilService: UtilService,
    private dateService: DateService,
    private modalService: NgbModal,
    private userService: UserService,
    private domainService: CategoryService
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((route) => {
      this.analyseRoute(route);

      if (this.isDisplayingList) {
        this.getList();
      } else {
        this.isInitialised = false;
        this.canRespondToButtons = false;
        this.domains = this.domainService.allStaticDomains();
        this.getBadgeLevels();
        if (this.isEditingItem) {
          this.initialiseEditItem(+route.id);
        } else if (this.isAddingItem) {
          this.initialiseAddItem();
        }
      }
    });
  }

  typeSelected(type) {
    if (type) {
      this.type = type;
    } else {
      this.type = this.itemForm.get('typeControl').value;
    }
    if (this.type !== 'article' && this.type !== 'recipe' && this.type !== 'video') {
      this.badgeService.getTypeIdOptions(this.type).subscribe((data) => {
        if (this.type === 'achievedIndicator' || this.type === 'trackedIndicator') {
          this.typeId = data.filter(typeOption => {
            return typeOption.featureId === 13;
          });
        } else {
          this.typeId = data;
        }
      });
    }
  }

  getList() {
    this.badgeService.loadAllBadges().subscribe(
      (data) => {
        this.items = data.map((c) => {
          return [c.id, c.name, c.badgeLevel, this.dateService.formatDD_MMM_YYYY(c.createdAt)];
        });
        this.columns = ['ID', 'Name', 'Level', 'Created Date'];
      },
      (error) => this.utilService.showToastError('Error while loading items - ' + error)
    );
  }

  getBadgeLevels() {
    this.badgeLevels = [
      {
        name: 'Bronze',
        value: 'bronze'
      },
      {
        name: 'Silver',
        value: 'silver'
      },
      {
        name: 'Gold',
        value: 'gold'
      }
    ];
  }

  initialiseEditItem(id: number): void {
    this.badgeService.getBadgeById(id).subscribe((data) => {
      this.item = data;
      this.type = data.type;
      this.initialiseEditableFormGroup(data);
      this.typeSelected(this.type);
    });
  }

  private initialiseAddItem(): void {
    this.item = this.badgeService.getNewBadge();
    this.initialiseEditableFormGroup(this.item);
  }

  private initialiseEditableFormGroup(item: IBadge) {
    this.itemForm = this.fb.group({
      nameControl: [{ value: item.name, disabled: !item.userEdit }, Validators.required],
      descriptionControl: [
        { value: item.description, disabled: !item.userEdit },
        Validators.required
      ],
      badgeLevelControl: [
        { value: item.badgeLevel, disabled: !item.userEdit },
        Validators.required
      ],
      // domainIdControl: { value: item.domainId, disabled: !item.userEdit },
      goalControl: [{ value: item.goal, disabled: !item.userEdit }, Validators.required],
      typeControl: [{ value: item.type, disabled: !item.userEdit }, Validators.required],
      typeIdControl: { value: item.typeId, disabled: !item.userEdit }
    });
    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }
    this.persistItem();
  }

  private persistItem() {
    this.canRespondToButtons = false;
    let badge: IBadge;
    let body: any;

    try {
      badge = {
        name: this.itemForm.get('nameControl').value,
        description: this.itemForm.get('descriptionControl').value,
        badgeLevel: this.itemForm.get('badgeLevelControl').value,
        // domainId: this.itemForm.get('domainIdControl').value
        //   ? this.itemForm.get('domainIdControl').value
        //   : null,
        type: this.itemForm.get('typeControl').value,
        category: this.itemForm.get('typeControl').value,
        typeId: this.itemForm.get('typeIdControl').value,
        goal: this.itemForm.get('goalControl').value,
        image: 'badge_' + this.itemForm.get('badgeLevelControl').value + '.svg',
        userEdit: this.item.userEdit,
        feature: 0
      };

      if (this.item.id !== 0) {
        badge.id = this.item.id;
      }
      body = {
        badge: badge
      };
    } catch (error) {
      this.utilService.showToastError('Error persisting item - ' + error);
      this.canRespondToButtons = true;
      return;
    }
    if (this.isEditingItem) {
      this.updateItem(body);
    } else if (this.isAddingItem) {
      this.addNewItem(body);
    }
  }

  private updateItem(item) {
    this.badgeService.save(item.badge.id, item.badge).subscribe(
      () => {
        this.itemForm.reset();
        this.canRespondToButtons = true;
        this.utilService.showToastSuccess(`Updated ${this.itemType} - ${item.badge.name}`);
        this.navigateToList();
      },
      (error) => {
        this.utilService.showToastError(`Error while saving item - ${error}`);
        this.canRespondToButtons = true;
      }
    );
  }

  private addNewItem(item) {
    this.badgeService.save(0, item.badge).subscribe(
      () => {
        this.itemForm.reset();
        this.canRespondToButtons = true;
        this.utilService.showToastSuccess(`Added new ${this.itemType} - ${item.badge.name}`);
        this.navigateToList();
      },
      (error) => {
        this.utilService.showToastError(`Error while adding item - ${error}`);
        this.canRespondToButtons = true;
      }
    );
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Delete ' + this.itemType;
    modal.componentInstance.message = 'Confirm deleting this ' + this.itemType;
    modal.componentInstance.confirmButtonLabel = 'Delete';
    modal.result.then(
      (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }

        this.badgeService.deleteBadge(this.item.id).subscribe(
          () => {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`${this.itemType} - ${this.item.name} - deleted`);
            this.navigateToList();
          },
          (error) => this.utilService.showToastError('Error while deleting item - ' + error)
        );
      },
      () => { }
    );
  }

  goToList() {
    this.router.navigate(['/cms/badges']);
  }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isEditingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isEditingItem = true;
    }
  }

  onBackClick() {
    this.navigateToList();
  }

  onDeleteClick() {
    if (!this.isEditingItem) {
      return;
    }

    this.deleteItem();
  }

  private navigateToList() {
    this.router.navigate(['/cms/badges']);
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field)
    };
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  isTypeIdRequired() {
    return !(
      this.itemForm.get('typeControl').value === 'article' ||
      this.itemForm.get('typeControl').value === 'recipe' ||
      this.itemForm.get('typeControl').value === 'video' ||
      this.itemForm.get('typeControl').value === 'questionnaire'
    );
  }
}
