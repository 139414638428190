import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonToggleModule } from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuestionnaireComponent } from './components/questionnaires/questionnaire/questionnaire.component';
import { QuestionnaireDetailComponent } from './components/questionnaires/questionnaire-detail/questionnaire-detail.component';
import { QuestionnaireResultComponent } from './components/questionnaires/questionnaire-result/questionnaire-result.component';
import { BadgeModalComponent } from './components/badge-modal/badge-modal.component';
import { FeatureFilterPipe } from './pipes/featureFilter.pipe';
import { StringFilterPipe } from './pipes/stringFilter.pipe';
import { PluralizePipe } from './pipes/pluralize.pipe';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { FeatureNamePipe } from './pipes/featureName.pipe';
import { LineGraphComponent } from './components/graphs/line-graph/line-graph';
import { PolarGraphComponent } from './components/graphs/polar-graph/polar-graph';
import { BarGraphComponent } from './components/graphs/bar-graph/bar-graph';
import { DoughnutGraphComponent } from './components/graphs/doughnut-graph/doughnut-graph';
import { ContentItemsComponent } from './components/content-items/content-items.component';
import { ContentListComponent } from './components/content-list/content-list.component';
import { ContentDetailComponent } from './components/content-detail/content-detail.component';
import { SearchComponent } from './components/search/search.component';
import { ContentListCardComponent } from './components/content-list-card/content-list-card.component';
import { LikeIndicatorComponent } from './components/like-indicator/like-indicator';
import { BookmarkIndicatorComponent } from './components/bookmark-indicator/bookmark-indicator';
import { ActiveLifeActionComponent } from './components/life-actions/active-life-action/active-life-action.component';
import { ManageLifeActionComponent } from './components/life-actions/manage-life-action/manage-life-action.component';
import { StarRatingComponent } from './components/rating/rating.component';
import { ActiveLifeActionHistoryComponent } from './components/life-actions/active-life-action-history/active-life-action-history.component';
import { SelectLifeActionRecurrenceComponent } from './components/life-actions/select-life-action-recurrence/select-life-action-recurrence.component';
import { MenuCardComponent } from './components/menu-card/menu-card.component';
import { GroupListCardComponent } from './components/group-list-card/group-list-card.component';
import { FlaggingComponent } from './components/flagging/flagging.component';
import { DiscoverComponent } from './components/discover/discover.component';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { PrivacyCollectionStatementComponent } from './components/privacy-collection-statement/privacy-collection-statement.component';
import { ShareContentComponent } from './components/share-content/share-content.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { NotificationManageComponent } from './components/notification-manage/notification-manage.component';
import { PageDetailComponent } from './components/page-detail/page-detail.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { StartCasePipe } from './pipes/startCase.pipe';
import { TimeFromNowPipe } from './pipes/time-from-now.pipe';
import { SearchTermComponent } from './components/search-term/search-term.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { SafeStylePipe } from './pipes/safe-style.pipe';
import { LanguageFilterComponent } from '../language-filter/language-filter.component';
import { LanguageToggleButtonComponent } from './components/language-toggle-button/language-toggle-button.component';
import { InputCopyComponent } from './components/input-copy/input-copy.component';
import { BookingCodeComponent } from './components/booking-code/booking-code.component';

@NgModule({
  declarations: [
    StringFilterPipe,
    FeatureFilterPipe,
    PluralizePipe,
    OrdinalPipe,
    TruncatePipe,
    StartCasePipe,
    FeatureNamePipe,
    BarGraphComponent,
    LineGraphComponent,
    PolarGraphComponent,
    DoughnutGraphComponent,
    ContentItemsComponent,
    ContentListComponent,
    ContentDetailComponent,
    ContentListCardComponent,
    SearchComponent,
    LikeIndicatorComponent,
    BookmarkIndicatorComponent,
    ActiveLifeActionComponent,
    ManageLifeActionComponent,
    ActiveLifeActionHistoryComponent,
    SelectLifeActionRecurrenceComponent,
    StarRatingComponent,
    MenuCardComponent,
    BadgeModalComponent,
    QuestionnaireComponent,
    QuestionnaireDetailComponent,
    QuestionnaireResultComponent,
    GroupListCardComponent,
    FlaggingComponent,
    DiscoverComponent,
    TermsComponent,
    PageDetailComponent,
    PrivacyComponent,
    PrivacyCollectionStatementComponent,
    ShareContentComponent,
    TextareaComponent,
    NotificationManageComponent,
    SearchTermComponent,
    AvatarComponent,
    TimeFromNowPipe,
    SafeStylePipe,
    LanguageFilterComponent,
    LanguageToggleButtonComponent,
    InputCopyComponent,
    BookingCodeComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatButtonToggleModule,
    MatSelectModule,
    NgbModule,
  ],
  exports: [
    StringFilterPipe,
    FeatureFilterPipe,
    PluralizePipe,
    OrdinalPipe,
    TruncatePipe,
    StartCasePipe,
    TimeFromNowPipe,
    FeatureNamePipe,
    BarGraphComponent,
    LineGraphComponent,
    PolarGraphComponent,
    DoughnutGraphComponent,
    ContentItemsComponent,
    ContentListComponent,
    ContentDetailComponent,
    ContentListCardComponent,
    SearchComponent,
    LikeIndicatorComponent,
    BookmarkIndicatorComponent,
    ActiveLifeActionComponent,
    ManageLifeActionComponent,
    ActiveLifeActionHistoryComponent,
    SelectLifeActionRecurrenceComponent,
    StarRatingComponent,
    MenuCardComponent,
    BadgeModalComponent,
    QuestionnaireComponent,
    QuestionnaireDetailComponent,
    QuestionnaireResultComponent,
    GroupListCardComponent,
    FlaggingComponent,
    DiscoverComponent,
    TermsComponent,
    PageDetailComponent,
    PrivacyComponent,
    PrivacyCollectionStatementComponent,
    TextareaComponent,
    SearchTermComponent,
    AvatarComponent,
    SafeStylePipe,
    LanguageFilterComponent,
    LanguageToggleButtonComponent,
    InputCopyComponent,
    BookingCodeComponent
  ],
  entryComponents: [
    BadgeModalComponent,
    TermsComponent,
    PageDetailComponent,
    PrivacyComponent,
    ContentDetailComponent,
    ShareContentComponent,
    NotificationManageComponent,
  ],
})
export class SharedModule {
}
