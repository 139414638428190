<div class="bg-color p-2">
  <div class="w-100 text-center">
    <p *ngIf="isNoItems"
      class="message">
      There are no other members of the leaderboard yet. Check
      back soon!
    </p>

    <table><div class="w-100">
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td class="gold round-top">
          <img [src]="goldBadgeSrc" alt="gold badge"
            class="badge-small">
        </td>
        <td class="silver round-top">
          <img [src]="silverBadgeSrc" alt="silver badge"
            class="badge-small">
        </td>
        <td class="bronze round-top">
          <img [src]="bronzeBadgeSrc" alt="bronze badge"
            class="badge-small">
        </td>
      </tr>
      <tr *ngFor="let item of leaderboardItems"
        class="leaderboard-line"
        [ngClass]="item.css">
        <td class="badgeCol p-2">
          <span>{{ item.position | ordinal }}</span>
        </td>
        <td class="avatar-col">
          <app-avatar
            [photoSrc]="item.photoSrc"
            [levelSrc]="item.levelSrc"
            (selectAvatar)="onViewItem(item)"
          ></app-avatar>
        </td>
        <td class="p-2 text-left username-col"
          (click)="onViewItem(item)">
          {{ item.usernameTruncated }}
        </td>
        <td class="badgeCol gold p-1">{{item.gold}}</td>
        <td class="badgeCol silver p-1">{{item.silver}}</td>
        <td class="badgeCol bronze p-1">{{item.bronze}}</td>
      </tr>
    </div>
  </table>
  </div>
</div>
<a *ngIf="canViewMore"
  (click)="onViewMore()"
  class="btn btn-primary center-button col-12 col-sm-3  mx-auto mt-4 ml-2 mb-4">
View more...
</a>
