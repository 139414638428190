<div class="widget-card">
  <div class="widget-card-header backPrimary">
    HCF Thank You
  </div>
  <div class="w-100 text-center">
      <div>
        <img src="../../../assets/img/widgets/hcf_thankyou.jpg" alt="Show my rewards" class="w-100">
      </div>
      <div class="text-left backPrimary">
        <p class="p-2">As an HCF member, you’ll be rewarded with a growing range of special offers and benefits. The longer you stay with us, the more ways we can say thank you.</p>
        <div>
            <a class="btn btn-line btn-link" href="https://www.hcf.com.au/thankyou" target="_blank"> <i class="fa fa-eye" aria-hidden="true"></i> View rewards</a> 
        </div>
      </div>
    </div>
</div>