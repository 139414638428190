import { Injectable } from '@angular/core';
import { FeatureService } from '../../services/feature-service';
import { UserService } from '../../services/user-service';
import { ITutorialPage } from '../../app/_models/tutorial-page';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {
  tutorialPages: ITutorialPage[] = [];
  discoverPage: ITutorialPage;
  sharePage: ITutorialPage;
  actPage: ITutorialPage;
  rewardPage: ITutorialPage;
  toolkitPage: ITutorialPage;
  profilePage: ITutorialPage;

  constructor(
    private userService: UserService,
    private feature: FeatureService
  ) {}

  getTutorialPages(): ITutorialPage[] {
    if (this.userService.isFiitr) {
      this.defineFiitrPages();
    } else if (this.userService.isAltiusLife) {
      this.defineAltiusPages();
    } else {
      this.defineOtherPlatformPages();
    }

    this.populateTutorialPages();

    return this.tutorialPages;
  }

  private defineFiitrPages() {
    this.discoverPage = {
      title: 'DISCOVER',
      imgPath: '../../assets/img/tutorial/FiiTR_tutorial_discover.png',
      intro:  `Searching for that elusive work life balance? Do you need parenting
      tips that don’t involve wine? Or maybe your finances need a re-juggle
      and you need a place to start? No matter what you're after, you'll
      find real life, actionable solutions and suggestions in our Discover tab on:`,
      items: [
        'Work and Life Balance',
        'Family and Relationships',
        'Physical and Mental Health',
        'And so much more'
      ]
    };

    this.sharePage = {
      title: 'SHARE',
      imgPath: '../../assets/img/tutorial/FiiTR_tutorial_share.png',
      intro: 'Read something interesting?',
      items: [
        'Share it with your peers in special interest groups and help each other make healthy changes',
        'Create a leaderboard to earn badges and see how you compare'
      ]
    };

    this.actPage = {
      title: 'ACT',
      imgPath: '../../assets/img/tutorial/FiiTR_tutorial_act.png',
      intro: `Striving to make meaningful changes in your health and
      mental wellbeing? Do you want to exercise your heart to healthy
      and melt that muffin top away? Or are you tired of nagging stress
      and yearn to gain a little more peace of mind? Visit our Act tab
      to get started.`,
      items: [
        'Track your health with goal setting',
        'Connect your Fitbit and Google Fit to measure progress',
        'Use the LifeActions to prompt you to take action',
        'View weekly summaries to track progress'
      ]
    };

    this.rewardPage = {
      title: 'REWARD',
      imgPath: '../../assets/img/tutorial/FiiTR_tutorial_reward.png',
      intro: 'Fancy earning a PHD? Get reading!',
      items: [
        'Work your way through our degree badges to gain the knowledge for a healthier life'
      ]
    };

    this.toolkitPage = {
      title: 'SUPPORT & RESOURCES',
      imgPath: '../../assets/img/tutorial/Toolkit.jpg',
      intro: 'Support & Resources is where to find the nuts and bolts for day to day living. You’ll find:',
      items: [
        'Access your workplace resources and support',
        'Information Packs',
        'Company policies and employee support information',
      ]
    };

    this.profilePage = {
      title: 'YOUR PROFILE',
      imgPath: '../../assets/img/tutorial/FiiTR_tutorial_profile.png',
      intro: 'Don’t want to see irrelevant articles on your homepage? Tailor your user experience in your profile by:',
      items: [
        'Adding an image',
        'Refining content preferences',
        'Re-discovering your bookmarked favourites',
        'Setting and viewing notifications'
      ],
      final: 'We want you to succeed on your health and wellness journey, let us guide you there.'
    };
  }

  private defineAltiusPages() {
    this.discoverPage = {
      title: 'DISCOVER',
      imgPath: '../../assets/img/tutorial/Discover.jpg',
      intro:  `Searching for that elusive work life balance? Do you need parenting
      tips that don’t involve wine? Or maybe your finances need a
      re-juggle and you need a place to start? No matter what you're after,
      you'll find real life, actionable solutions and suggestions in our
      Discover tab on:`,
      items: [
        'Work and Life Balance',
        'Family and Relationships',
        'Physical and Mental Health',
        'And so much more'
      ]
    };

    this.sharePage = {
      title: 'SHARE',
      imgPath: '../../assets/img/tutorial/Share.jpg',
      intro: 'Read something interesting?',
      items: [
        'Share it with your peers in special interest groups and help each other make healthy changes',
        'Create a leaderboard to earn badges and see how you compare'
      ]
    };

    this.actPage = {
      title: 'ACT',
      imgPath: '../../assets/img/tutorial/Act.jpg',
      intro: `Striving to make meaningful changes in your health and mental
      wellbeing? Do you want to exercise your heart to healthy and melt
      that muffin top away? Or are you tired of nagging stress and yearn
      to gain a little more peace of mind? Visit our Act tab to get started.`,
      items: [
        'Track your health with goal setting',
        'Connect your Fitbit and Google Fit to measure progress',
        'Use the LifeActions to prompt you to take action',
        'View weekly summaries to track progress'
      ]
    };

    this.rewardPage = {
      title: 'REWARD',
      imgPath: '../../assets/img/tutorial/Reward.jpg',
      intro: 'Fancy earning some badges? Get reading!',
      items: [
        'Work your way through our degree badges to gain the knowledge for a healthier life'
      ]
    };

    this.toolkitPage = {
      title: 'SUPPORT & RESOURCES',
      imgPath: '../../assets/img/tutorial/Toolkit.jpg',
      intro: 'Support & Resources is where to find the nuts and bolts for day to day living. You’ll find:',
      items: [
        'Access your workplace resources and support',
        'Information Packs',
        'Company policies and employee support information',
      ]
    };

    this.profilePage = {
      title: 'YOUR PROFILE',
      imgPath: '../../assets/img/tutorial/Profile.jpg',
      intro: 'Don’t want to see irrelevant articles on your homepage? Tailor your user experience in your profile by:',
      items: [
        'Adding an image',
        'Refining content preferences',
        'Re-discovering your bookmarked favourites',
        'Setting and viewing notifications'
      ],
      final: 'We want you to succeed on your health and wellness journey, let us guide you there.'
    };
  }

  private defineOtherPlatformPages() {
    this.discoverPage = {
      title: 'DISCOVER',
      imgPath: '../../assets/img/tutorial/Discover.jpg',
      intro:  `Searching for that elusive work life balance? Do you need parenting
      tips that don’t involve wine? Or maybe your finances need a re-juggle
      and you need a place to start? No matter what you're after, you'll
      find real life, actionable solutions and suggestions in our Discover tab on:`,
      items: [
        'Work and Life Balance',
        'Family and Relationships',
        'Physical and Mental Health',
        'And so much more'
      ]
    };

    this.sharePage = {
      title: 'SHARE',
      imgPath: '../../assets/img/tutorial/Share.jpg',
      intro: 'Read something interesting?',
      items: [
        'Share it with your peers in special interest groups and help each other make healthy changes',
        'Create a leaderboard to earn badges and see how you compare'
      ]
    };

    this.actPage = {
      title: 'ACT',
      imgPath: '../../assets/img/tutorial/Act.jpg',
      intro: `Striving to make meaningful changes in your health and
      mental wellbeing? Do you want to exercise your heart to healthy
      and melt that muffin top away? Or are you tired of nagging stress
      and yearn to gain a little more peace of mind? Visit our Act tab
      to get started.`,
      items: [
        'Track your health with goal setting',
        'Connect your Fitbit and Google Fit to measure progress',
        'Use the LifeActions to prompt you to take action',
        'View weekly summaries to track progress'
      ]
    };

    this.rewardPage = {
      title: 'REWARD',
      imgPath: '../../assets/img/tutorial/Reward.jpg',
      intro: 'Fancy earning a PHD? Get reading!',
      items: [
        'Work your way through our degree badges to gain the knowledge for a healthier life'
      ]
    };

    this.toolkitPage = {
      title: 'TOOLKIT',
      imgPath: '../../assets/img/tutorial/Toolkit.jpg',
      intro: 'Toolkit is where to find the nuts and bolts for day to day living. You’ll find:',
      items: [
        'Partner services that can help you find antenatal classes or babysitters, dog sitters and house cleaners, they can sort your finances or sort your head space.',
        'Information Packs',
        'Company policies and employee support information',
        'Resources'
      ]
    };

    this.profilePage = {
      title: 'YOUR PROFILE',
      imgPath: '../../assets/img/tutorial/Profile.jpg',
      intro: 'Don’t want to see irrelevant articles on your homepage? Tailor your user experience in your profile by:',
      items: [
        'Adding an image',
        'Refining content preferences',
        'Re-discovering your bookmarked favourites',
        'Setting and viewing notifications'
      ],
      final: 'We want you to succeed on your health and wellness journey, let us guide you there.'
    };
  }

  private populateTutorialPages() {
    this.tutorialPages = [];

    if (this.userService.hasCompanyFeature(this.feature.DiscoverFeatureId)) {
      this.tutorialPages.push(this.discoverPage);
    }

    if (this.userService.hasCompanyFeature(this.feature.ShareFeatureId)) {
      this.tutorialPages.push(this.sharePage);
    }

    if (this.userService.hasCompanyFeature(this.feature.ActFeatureId)) {
      this.tutorialPages.push(this.actPage);
    }

    if (this.userService.hasCompanyFeature(this.feature.RewardsFeatureId)) {
      this.tutorialPages.push(this.rewardPage);
    }

    if (this.userService.hasCompanyFeature(this.feature.ToolkitFeatureId)) {
      this.tutorialPages.push(this.toolkitPage);
    }

    this.tutorialPages.push(this.profilePage);
  }
}
