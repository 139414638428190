<section class='p-3 generate-link-section'>
  <h5>Generate Link</h5>
  <form [formGroup]='generateLinkForm' ngbAutofocus>
    <div class='form-group'>
      <label class='control-label required'>Email</label>
      <input type='text' class='form-control'
             [ngClass]="{'is-invalid' : generateLinkForm.get('email').touched && generateLinkForm.get('email').invalid }"
             formControlName='email' />
      <span class='invalid-feedback'>Email is required field and must be right format</span>
    </div>
    <div class='row'>
      <div class='col-6'>
        <div class='custom-control custom-checkbox mb-1' *ngIf="!generateLinkForm.get('companyId').value">
          <input class='custom-control-input pointer-event' formControlName='enabledEAPFeature' id='company__eap-checkbox' type='checkbox'>
          <label class='custom-control-label' for='company__eap-checkbox'>Enable EAP Booking</label>
        </div>
      </div>
      <div class='col-6'>
        <div class='row form-group'>
          <div class='col-12'>
            <label class='control-label'>LEAP Booking Code</label>
          </div>
          <div class='col-8'>
            <app-booking-code [form]='generateLinkForm' [formControlName]='"bookingCode"' [cssClass]='"booking-code-full"' (lostFocusEvent)='bookingCodeValidationHandler($event)' (keyUpEvent)='bookingCodeValidationHandler($event)'></app-booking-code>
            <span *ngIf='bookingCodeInvalid' class='invalid-feedback d-block'>Booking Code is invalid</span>
          </div>
          <div class='col-4 pl-0'>
            <button (click)='openBookingSite()' class='btn btn-primary w-100' type='button' [disabled]='disableVerifyBookingCode'>
              VERIFY
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class='form-group'>-->
    <!--      <label>Company</label>-->
    <!--      <select class='custom-select form-control'-->
    <!--              (change)='onCompanySelected($event)' [disabled]="!isSuperAdmin">-->
    <!--        <option [value]='0' selected>Select company</option>-->
    <!--        <option *ngFor='let company of handledListCompaniesData'-->
    <!--                [value]='company.id'>-->
    <!--          {{company.name}}-->
    <!--        </option>-->
    <!--      </select>-->
    <!--    </div>-->
  </form>
  <div class='modal-footer flex-row justify-content-around mt-3'>
    <button (click)='onClickButton(false)' [disabled]='!isSuperAdmin' class='btn btn-md w-48' type='button'>Send
    </button>
    <button (click)='onClickButton()' class='btn btn-md w-48' type='button'>Cancel</button>
  </div>
</section>
