<section *ngIf='isLoading' class='template-section d-flex align-items-center flex-column'>
  <h2 class='loading'>Loading...</h2>
</section>

<section *ngIf='!isLoading' class='template-section d-flex align-items-center flex-column'>
  <div class='d-flex justify-content-end align-items-center my-2' [style.width]='pageWidth + "px"'>
    <h3 *ngIf='isProcessing' class='processing'>Generating file...</h3>
    <button (click)='onGeneratePDF()' [disabled]='isProcessing' class='btn btn-primary' title='Download' type='button'>
      <i aria-hidden='true' class='fa fa-download'></i>
    </button>
  </div>
  <div *ngFor='let page of data.pages; let i = index' #pageRef class='template-section__content' [ngStyle]='{"width": page.option.width + "px", "height": page.option.height + "px"}' (click)='onGeneratePDF()'>
    <div class='template-section__content__background'>
      <img alt='background' [src]='"data:image/jpg;base64," + page.background' />
    </div>
    <div *ngIf='page.hasDynamicFields'>
      <div *ngFor='let text of page.dynamicTexts' [ngStyle]='text.css'>
        <span>{{text.value}}</span>
      </div>
      <qrcode *ngFor='let qr of page.dynamicQRs' [qrdata]='qr.value' [size]='qr.size' [level]="'M'" [ngStyle]='qr.css'>
      </qrcode>
    </div>
  </div>
</section>
