<div class="tutorial-container">
  <ngb-carousel>
    <ng-template ngbSlide *ngFor="let page of tutorialPages;let i = index">
      <div class="box">
        <div class="img-box">
          <img src={{page.imgPath}} alt="tutorial_{{i}}" />
        </div>
        <div class="container">
          <h3 class="primaryColor tutorial-title">{{page.title}}</h3>
          <p>{{page.intro}}</p>
          <ul>
            <li *ngFor="let item of page.items">{{item}}</li>
          </ul>
          <div *ngIf="page.final">{{page.final}}</div>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
  <div class="modal-footer">
    <div class="text-center w-100">
      <button (click)="closeModal()" type="button" class="btn btn-md col-6">Close</button>
    </div>
  </div>
</div>
