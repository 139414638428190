import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ILanguage } from '../app/_models/language';

export interface ILanguageResponse {
  success?: boolean;
  languageExistedError?: boolean;
  err?: any;
}

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    private http: HttpClient,
  ) {
  }

  private languageTranslationTextCodes = [
    'languageToggleOn',
    'languageToggleOff',
  ];

  getLanguageTranslationTextCodes() {
    return this.languageTranslationTextCodes;
  }

  getAllLanguages() {
    const url = `/cms/languages/all`;
    return this.http.get<any>(url);
  }

  getAvailableLanguages() {
    const url = `/cms/languages`;
    return this.http.get<any>(url);
  }

  getLanguageById(id: number) {
    const url = `/cms/languages/${id}`;

    return this.http.get<any>(url);
  }

  addLanguage(language: ILanguage) {
    const body = {
      name: language.name,
      code: language.code,
      translationTexts: language.translationTexts,
    };
    const url = '/cms/languages';

    return this.http.post<any>(url, body);
  }

  updateLanguageTranslation(id: number, language: ILanguage) {
    const body = {
      translationTexts: language.translationTexts,
    };
    const url = `/cms/languages/${id}`;

    return this.http.put<any>(url, body);
  }
}
