import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../../services/user-service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  brand: any;
  url: any;
  helpEmail: any;

  constructor(
    public activeModal: NgbActiveModal,
    public userService: UserService
  ) { }

  ngOnInit() {
    this.brand = this.userService.envVariables.brand;
    this.helpEmail = this.userService.envVariables.contactInfo.helpEmail;
    this.url = this.userService.envVariables.app.url;
  }
}
