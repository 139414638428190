<div class="modal-header">
  <h4 class="modal-title">Contact Us</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngFor="let contact of externalContacts; let i = index" class="pl-3 p-1 contactBox">
    <h5 [innerHTML]="contact.title"></h5>
    <!-- <p *ngIf="isEmailContact(contact)">
      <a [href]="'mailto:' + contact.paragraph" >{{contact.paragraph}}</a>
    </p> -->
    <p  *ngIf="isNormalContact(contact)" [innerHTML]="contact.paragraph"></p>
    <p  *ngIf="isLinkContact(contact)" style="cursor: pointer" (click)="openLink(contact.link)" [innerHTML]="contact.paragraph"></p>
  </div>