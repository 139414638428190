<div class="container">
  <div class="row">
    <h2 class="mt-2">Challenges</h2>
  </div>
  <div class="row" *ngIf="items && items.length === 0 && !loading">
    <p>No challenges available</p>
  </div>
  <div class="row" *ngIf="loading">
    <div class="col-12 w-100">
      <h4>Loading...</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex" *ngFor="let i of items">
      <!-- <a class="contentAnchorTag"
          (click)="goToDetailLink(i.id)">
          <div class="card" style="width: auto;">
            <img class="card-img-top" src="{{i.image}}" alt="Card image cap">
            <div class="card-body">
              <p class="card-text text-center">{{i.name}}</p>
            </div>
          </div>
        </a> -->
      <div class="content-card card-outline mr-0 ml-0 mt-4 card p-3 mt-3"
        ng-reflect-ng-class="content-card card-outline mr-0">
        <a class="contentAnchorTag" (click)="goToDetailLink(i.id)">

          <div class="row">
            <div class="col-12">
              <h5 class="card-title primaryColor">{{i.name}}</h5>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 text-center">
              <img class="packImg" [src]="i.image" />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <!-- <p class="card-text" style="text-align: justify; text-justify: inter-word;" [innerHTML]="i.description"></p> -->
              <div class="fr-view card-text mt-4" *ngIf='i.descHtml' [innerHTML]="i.descHtml"></div>
            </div>
          </div>
        </a></div>
    </div>
  </div>
</div>
