<app-cms-list-view *ngIf="isDisplayingList && isInitialised" imgColumn="3" 
[isPreventListDelete] = "true" [dataList]="authors" [columnList]="columns"
  [additionalNumericColumns]="additionalNumericColumns">
</app-cms-list-view>

<div *ngIf="(isAddingItem || isEditingItem) && isInitialised" class="padding jumbotron">

  <form [formGroup]="authorForm" (ngSubmit)="onSaveClick()">

    <div class="row m-0">
      <div class="col-12">
        <!-- Title -->
        <div class="form-group">
          <label class="control-label required">Name</label>
          <input type="text" class="form-control" [ngClass]="displayFieldCss('name')" formControlName="name" />
          <span class="invalid-feedback">Name is required</span>
        </div>

        <!-- image -->
        <div class="form-group">
          <label for="image" class="control-label">
            Image
          </label>
          <div class="input-group">
            <input type="text" name="logo" id="logo" class="form-control" [ngClass]="displayFieldCss('image')"
              placeholder="Choose the image" formControlName="image" readonly />
            <div class="input-group-prepend">
              <button type="button" class="input-group-text btn btn-primary" (click)="openfilestack()">
                Browse
              </button>
            </div>
            <span class="invalid-feedback">Image is required</span>
          </div>
        </div>

        <br />
        <div class="form-group">
          <label class="control-label">Bio</label>
          <textarea type="text" class="form-control" maxLength="maxBioLength" [ngClass]="displayFieldCss('bio')"
            formControlName="bio"></textarea>
          <span class="pt-1" *ngIf="hasReachedBioLimit()">Bio has max 320 characters</span>

        </div>

        <!-- Buttons -->
        <div class="row">
          <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
            <button type="button" class="btn btn-outline-primary w-100" (click)="onBackClick()">
              <i class="fa fa-undo mr-2" aria-hidden="true"></i> BACK
            </button>
          </div>

          <div *ngIf="isEditingItem" class="col-4">
            <button type="button" class="btn btn-outline-primary w-100" [disabled]="!canRespondToButtons" (click)="onDeleteClick()">
              <i class="fa fa-trash mr-2" aria-hidden="true"></i> DELETE
            </button>
          </div>

          <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
            <button type="submit" class="btn btn-primary float-right w-100" [disabled]="!canRespondToButtons || !authorForm.valid">
              <i class="fa fa-floppy-o mr-2" aria-hidden="true"></i> SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
