import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { IArticle } from '../../_models/article';
import { IAuthor } from '../../_models/author';
import { ICompany } from '../../_models/company';
import { ArticleService } from '../../../services/article-service';
import { LifeActionService } from '../../../services/life-action-service';
import { CategoryService } from '../../../services/category-service';
import { CompanyService } from '../../../services/company-service';
import { UtilService } from '../../../services/util-service';
import { UserService } from '../../../services/user-service';
import { DateService } from '../../../services/date.service';
import { FeatureService } from '../../../services/feature-service';
import { FilestackService } from '../../../services/filestack-service';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { CmsListSelectComponent } from '../cms-list-select/cms-list-select.component';
import { ContentDetailComponent } from '../../_shared/components/content-detail/content-detail.component';
import { IIdNameSelected } from '../../_models/id-name-selected';
import { IIdNamePair } from '../../_models/id-name-pair';
import { AuthorService } from '../../../services/author-service';
import { FEATURES, LANGUAGES } from '../../_shared/enums';
import { ILanguageData } from '../../_models/language-data';
import { ILanguage } from '../../_models/language';
import { CmsListViewService } from '../cms-list-view/cms-list-view.service';

@Component({
  selector: 'app-article-management',
  templateUrl: './article-management.component.html',
  styleUrls: ['./article-management.component.scss'],
})
export class ArticleManagementComponent implements OnInit {
  items: IArticle[];
  item: IArticle;
  itemType = 'Article';

  lastCode: string = 'en';
  languageData: ILanguageData[] = [];
  availableLanguages: ILanguage[] = [];
  title: string;// used for en version
  shortText: string;// used for en version
  longText: string;// used for en version
  image: string;// used for en version
  errorMsg: string = '';// show language errors

  columns = [];
  itemForm: FormGroup;
  features: any;
  lifeActions = [];
  indicators = [];
  categories: IIdNameSelected[] = [];
  companies: ICompany[] = [];
  allCompanies: IIdNamePair[] = [];
  includedCompanyIds: number[] = [];
  excludedCompanyIds: number[] = [];
  selectedCategories: IIdNameSelected[] = [];
  selectableCategories: IIdNameSelected[] = [];
  selectedFeatureId = null;
  selectedVisibility = 2;
  additionalNumericColumns = [];
  visibilities: IIdNamePair[] = [];
  authorOptions: IAuthor[];
  public canSelectCompany = false;
  editor: any;
  public options: Object = {
    events: {
      'froalaEditor.link.beforeInsert': function(e, editor, link, text, attrs) {
        if (link.indexOf('://') < 0) {
          attrs['data-contenttype'] = link.split('/').slice(-2)[0];
          attrs['data-internallink'] = true;
          attrs['data-contentid'] = link.split('/').slice(-1)[0];
        }
      },
      'froalaEditor.initialized': (e, editor) => {
        this.editor = editor;
      },
    },
    htmlRemoveTags: ['style'],
    placeholderText: 'Edit Content Here',
    paragraphFormat: {
      N: 'Normal',
      H1: 'Heading 1',
      H2: 'Heading 2',
      H3: 'Heading 3',
      H4: 'Heading 4',
      H5: 'Heading 5',
      H6: 'Heading 6',
    },
    linkList: [
      {
        text: 'Article Link (update)',
        href: `/home/article/:id`,
      },
      {
        text: 'Recipe Link (update)',
        href: `/home/recipe/:id`,
      },
      {
        text: 'Video Link (update)',
        href: `/home/video/:id`,
      },
      {
        text: 'External Link',
        href: 'https://',
      },
    ],
    toolbarSticky: false,
    key: this.userService.getFroalaApiKey(),
    pastePlain: true,
  };

  isDisplayingList: boolean;
  isInitialised: boolean;
  isEditingItem: boolean;
  isAddingItem: boolean;
  canRespondToButtons: boolean;
  public initControls: any;

  preview = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private itemService: ArticleService,
    public userService: UserService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private modalService: NgbModal,
    private companyService: CompanyService,
    private ngZone: NgZone,
    private dateService: DateService,
    private filestackService: FilestackService,
    private lifeActionService: LifeActionService,
    private featureService: FeatureService,
    private categoryService: CategoryService,
    private authorService: AuthorService,
    private cmsListViewService: CmsListViewService,
  ) {
  }

  ngOnInit() {
    this.categoryService.refreshCache(true);
    this.activatedRoute.params
      .subscribe(route => {
        this.analyseRoute(route);

        if (this.isDisplayingList) {
          this.getList();
        } else {
          this.getFeatures();
          this.setUserRoleAuthorisations();
          this.isInitialised = false;
          this.canRespondToButtons = false;
          this.getCompanies(route);
        }
      });
  }

  private setUserRoleAuthorisations() {
    this.canSelectCompany = this.userService.isSuperAdminUser();
  }

  public initialize(initControls) {
    this.itemService.getS3Hash()
      .subscribe(hash => {
        this.options['imageUploadToS3'] = hash;
        this.initControls = initControls;
        this.initControls.initialize();
      }, error => this.utilService.showToastError('Error while uploading items - ' + error));
  }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isEditingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isEditingItem = true;
    }
  }

  private getList() {
    this.itemService.getAllArticles().subscribe(
      (data) => {
        this.items = data.map((i) => {
          return [
            i.id,
            this.cmsListViewService.getTitleColumn(i),
            i.author,
            this.utilService.formatAsYesNo(i.published),
            this.userService.getArticleImage(i.image),
            this.cmsListViewService.getLanguagesColumn(i),
          ];
        });
        this.columns = ['id', 'title', 'author', 'Published', 'Image', 'Languages'];
        this.additionalNumericColumns = [4];
        this.isInitialised = true;
        this.canRespondToButtons = true;
      },
      (error) => this.utilService.showToastError('Error while loading items - ' + error),
    );
  }

  private initialiseEditItem(id: number): void {
    this.itemService.getArticleById(id).subscribe((data) => {
      this.item = data;
      if (this.item.languageVersion) {
        try {
          this.languageData = JSON.parse(this.item.languageVersion);
        } catch (e) {
          console.log(e);
        }
      }
      if (data.categories.length) {
        this.selectedVisibility = data.categories[0].visibility;
        this.selectedFeatureId = data.categories[0].featureId;
        this.setSelectedCategories(data.categories);
        this.setSelectableCategories(this.selectedFeatureId);
      }
      this.indicators = data.indicators.map((l) => {
        return [l.id, l.name, l.category];
      });
      this.initialiseEditableFormGroup(data);
    });
  }

  private initialiseAddItem(): void {
    this.item = this.itemService.getNewArticle();
    if (!this.canSelectCompany) {
      this.item.includedCompanyIds = [this.userService.company.id];
    }
    this.initialiseEditableFormGroup(this.item);
  }

  private initialiseEditableFormGroup(item: IArticle) {
    this.visibilities = this.utilService.visibilities();
    this.getAuthors();
    this.itemForm = this.fb.group({
      title: [item.title, Validators.required],
      authorId: item.authorId,
      shortText: [item.shortText, Validators.required],
      longText: [item.longText, Validators.required],
      image: [item.image, Validators.required],
      source: item.source,
      sourceUrl: item.sourceUrl,
      linkUrl: item.linkUrl,
      readingTime: item.readingTime,
      copyright: item.copyright,
      // companyId: item.companyId,
      featureId: [this.selectedFeatureId, Validators.required],
      visibility: [this.selectedVisibility, Validators.required],
      publishedDate: [this.dateService.toYearMonthDayObject(this.item.publishedDate), Validators.required],
      endDate: this.item.endDate ? this.dateService.toYearMonthDayObject(this.item.endDate) : null,
      published: [this.item.published.toString(), Validators.required],
      categories: [this.selectedCategories.join(','), Validators.required],
    });

    // if (!item.companyId) {
    //   this.excludedCompanyIds = item.excludedCompanyIds;
    //   const excludedSize = this.excludedCompanyIds.length;
    //   const includedSize = this.allCompanies.length - excludedSize;
    //   if (excludedSize > includedSize) {// recreate arrays for smarter showing
    //     this.excludedCompanyIds = [];
    //     const allCompanyIds = this.allCompanies.map(c => c.id);
    //     // this.includedCompanyIds = allCompanyIds.filter(id => !item.excludedCompanyIds.includes(id));
    //     this.includedCompanyIds = allCompanyIds.filter(id => !item.includedCompanyIds.includes(id));
    //   }
    // } else {
    //   this.includedCompanyIds.push(item.companyId);
    // }

    this.includedCompanyIds = item.includedCompanyIds || [];
    this.excludedCompanyIds = item.excludedCompanyIds || [];

    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  private markAllControlsAsUntouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsUntouched({ onlySelf: true });
    });
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  showPreview() {
    if (this.itemForm.valid) {
      const item = this.itemForm.value;
      item.isArticle = true;
      const previewModal = this.modalService.open(ContentDetailComponent, { size: 'lg' });
      previewModal.componentInstance.previewContent = item;
    } else {
      this.validateAllFormFields(this.itemForm);
    }
  }

  isCategoriesInvalid() {
    return (
      this.itemForm.get('featureId').valid
      && this.itemForm.get('featureId').touched
      && !this.selectedCategories.length
    );
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  private navigateToList() {
    this.router.navigate(['/cms/articles']);
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Delete ' + this.itemType;
    modal.componentInstance.message = 'Confirm deleting this ' + this.itemType;
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(
      (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }

        this.itemService.delete(this.item.id).subscribe(
          () => {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`${this.itemType} - ${this.item.title} - deleted`);
            this.navigateToList();
          },
          (error) => this.utilService.showToastError('Error while deleting item - ' + error),
        );
      },
      () => {
      },
    );
  }

  private persistItem() {
    this.canRespondToButtons = false;
    this.buildLanguageData();
    let valid = this.validateLanguageData();
    if (!valid) {
      this.canRespondToButtons = true;
      return;
    }
    let article: IArticle;
    let body: any;

    // calc excluded companies
    // let _companyId;
    // let _excludedCompanyIds;
    // if (!this.canSelectCompany) {
    //   _companyId = this.userService.company.id;
    // } else {
    //   if (this.includedCompanyIds.length > 1) {
    //     _companyId = null;// equals to select all in previous implementation
    //     // calc excluded companies by included companies
    //     const allCompanyIds = this.allCompanies.map(c => c.id);
    //     _excludedCompanyIds = allCompanyIds.filter(id => !this.includedCompanyIds.includes(id));
    //   } else if (this.includedCompanyIds.length == 1) {
    //     _companyId = this.includedCompanyIds[0];
    //     _excludedCompanyIds = [];
    //   } else {
    //     _companyId = null;// equals to select all in previous implementation
    //     _excludedCompanyIds = this.excludedCompanyIds;
    //   }
    // }

    try {
      const endDate = this.itemForm.get('endDate').value;
      const html = this.editor.codeView.isActive() ? this.editor.codeView.get() : this.longText;
      article = {
        title: this.title,
        shortText: this.shortText,
        longText: html,
        image: this.image,
        source: this.itemForm.get('source').value,
        sourceUrl: this.itemForm.get('sourceUrl').value,
        linkUrl: this.itemForm.get('linkUrl').value,
        published: this.itemForm.get('published').value,
        publishedDate: this.dateService.fromYearMonthDayObject(
          this.itemForm.get('publishedDate').value,
        ),
        endDate: endDate ? this.dateService.fromYearMonthDayObject(
          endDate) : null,
        authorId: this.itemForm.get('authorId').value,
        copyright: this.itemForm.get('copyright').value,
        readingTime: this.itemForm.get('readingTime').value,
        // companyId: this.userService.company.id,
        excludedCompanyIds: this.excludedCompanyIds,
        includedCompanyIds: this.includedCompanyIds,
        languageVersion: JSON.stringify(this.languageData),
      };
      if (this.item.id !== 0) {
        article.id = this.item.id;
      }
      body = {
        article: article,
        categories: this.selectedCategories.map((c) => c.id),
        indicators: this.indicators.map((i) => i[0]),
        visibility: this.itemForm.get('visibility').value,
      };
    } catch (error) {
      const errorMsg = 'Error persisting item - ' + error;
      this.utilService.showToastError(errorMsg);
      this.canRespondToButtons = true;
      return;
    }

    if (this.isEditingItem) {
      this.updateItem(body);
    } else if (this.isAddingItem) {
      this.addNewItem(body);
    }
  }

  private updateItem(item) {
    this.itemService.save(item.article.id, item)
      .subscribe(() => {
          this.itemForm.reset();
          this.canRespondToButtons = true;
          const msg = `Updated ${this.itemType} - ${item.article.title}`;
          this.utilService.showToastSuccess(msg);
          this.navigateToList();
        },
        (error) => {
          const errorMsg = `Error while saving item - ${error}`;
          this.utilService.showToastError(errorMsg);
          this.canRespondToButtons = true;
        },
      );
  }

  private addNewItem(item) {
    this.itemService.save(0, item)
      .subscribe(() => {
          this.itemForm.reset();
          this.canRespondToButtons = true;
          const msg = `Added new ${this.itemType} - ${item.article.title}`;
          this.utilService.showToastSuccess(msg);
          this.navigateToList();
        },
        (error) => {
          const errorMsg = `Error while adding item - ${error}`;
          this.utilService.showToastError(errorMsg);
          this.canRespondToButtons = true;
        },
      );
  }

  validateLanguageData(): boolean {
    this.errorMsg = '';
    let valid = true;
    let languagesToRemove = [];
    for (const lang of this.languageData) {
      const obj = lang.obj;
      if (obj.title === '' && obj.image === this.image && obj.longText === '' && obj.shortText === '') {
        languagesToRemove.push(lang.code);
      } else if (obj.title === '' || obj.longText === '' || obj.shortText === '') {
        const name = this.availableLanguages.find(o => o.code === lang.code).name;
        this.errorMsg += `${name} language has errors.<br/>`;
        valid = false;
      }
    }
    for (const code of languagesToRemove) {
      this.languageData.splice(this.languageData.findIndex(o => o.code === code), 1);
    }
    return valid;
  }

  buildLanguageData() {
    if (this.lastCode != LANGUAGES.en) {
      let languageObj: ILanguageData = this.languageData.find(o => o.code == this.lastCode);
      if (!languageObj) {
        languageObj = { code: this.lastCode, obj: {} };
        this.languageData.push(languageObj);
      }
      languageObj.code = this.lastCode;
      languageObj.obj = {
        title: this.itemForm.get('title').value,
        shortText: this.itemForm.get('shortText').value,
        longText: this.itemForm.get('longText').value,
        image: this.itemForm.get('image').value,
      };
    } else {
      this.title = this.itemForm.get('title').value;
      this.shortText = this.itemForm.get('shortText').value;
      this.longText = this.itemForm.get('longText').value;
      this.image = this.itemForm.get('image').value;
    }
  }

  loadAvailableLanguages(languages) {
    this.availableLanguages = languages;
  }

  changeLanguage(code) {
    // build languageVersion data
    this.buildLanguageData();
    // fill data
    this.lastCode = code;
    if (code != LANGUAGES.en) {
      let languageObj: ILanguageData = this.languageData.find(o => o.code == code);
      if (!languageObj) {
        this.itemForm.get('title').setValue('');
        this.itemForm.get('shortText').setValue('');
        this.itemForm.get('longText').setValue('');
        this.itemForm.get('image').setValue(this.image);
      } else {
        this.itemForm.get('title').setValue(languageObj.obj.title);
        this.itemForm.get('shortText').setValue(languageObj.obj.shortText);
        this.itemForm.get('longText').setValue(languageObj.obj.longText);
        this.itemForm.get('image').setValue(languageObj.obj.image);
      }
    } else {
      this.itemForm.get('title').setValue(this.title);
      this.itemForm.get('shortText').setValue(this.shortText);
      this.itemForm.get('longText').setValue(this.longText);
      this.itemForm.get('image').setValue(this.image);
    }
    // mark untouched to hide errors
    this.markAllControlsAsUntouched();
    // validate languageData
    this.validateLanguageData();
  }

  getAuthors() {
    this.authorService.getAllAuthors().subscribe(authors => {
      this.authorOptions = authors;
    });
  }

  onBackClick() {
    this.navigateToList();
  }

  onDeleteClick() {
    if (!this.isEditingItem) {
      return;
    }

    this.deleteItem();
  }

  isFormInvalid() {
    return !this.itemForm.valid || !this.selectedCategories.length;
  }

  onSaveClick() {
    if (this.isFormInvalid()) {
      this.markAllControlsAsTouched();
      return;
    }
    this.persistItem();
  }

  getCompanies(route) {
    this.companyService.getAllCompanies()
      .subscribe((data) => {
        this.companies = data;
        this.allCompanies = this.companies.map(c => {
          return { id: c.id, name: c.name };
        });
        if (this.isEditingItem) {
          this.initialiseEditItem(+route.id);
        } else if (this.isAddingItem) {
          this.initialiseAddItem();
        }
      }, () => (this.companies = []));
  }

  getFeatures() {
    this.featureService
      .getAllFeaturesByType(FEATURES.DISCOVER)
      .subscribe((data) => {
        this.features = (data || []).filter(
          (f) => this.categoryService.getCachedCategoriesForFeatureId(f.id).length > 0,
        );
      }, () => (this.features = []));
  }

  getLifeActions() {
    if (this.lifeActions.length === 0) {
      this.lifeActionService.getAllLifeActions()
        .subscribe(
          (data) => {
            data.forEach((l) => {
              this.lifeActions.push([l.id, l.name, l.category]);
            });
            this.openLifeActionsList();
          },
          (err) => {
            const errorTitle = 'Error while loading the lifeactions';
            this.utilService.showToastError(errorTitle, err);
          },
        );
    } else {
      this.openLifeActionsList();
    }
  }

  setSelectedCategories(categories) {
    this.selectedCategories = categories.map((c) => {
      c.name = this.categoryService.getCategoriesName(c.name);
      return { id: c.id, name: c.name, selected: true };
    });
  }

  setSelectableCategories(featureId: number) {
    const featureCategories = this.categoryService
      .getCachedCategoriesForFeatureId(+featureId);
    const selectedCategoryIds = this.selectedCategories
      .map((c) => c.id);

    this.selectableCategories = featureCategories
      .filter((c) => selectedCategoryIds.indexOf(c.id) < 0)
      .map((c) => {
        c.name = this.categoryService.getCategoriesName(c.name);
        return { id: c.id, name: c.name, selected: false };
      });
  }

  unselectSelectedCategory(categoryId: number) {
    this.selectedCategories = this.selectedCategories
      .filter((c) => c.id !== +categoryId);

    const featureId = this.itemForm.get('featureId').value;
    this.updateSelectedCategoriesFormValue();
    this.setSelectableCategories(featureId);
  }

  selectSelectableCategory(categoryId: number) {
    const selectedCategory = this.selectableCategories
      .find((c) => c.id === +categoryId);
    if (!selectedCategory) {
      return;
    }

    this.selectedCategories.push(selectedCategory);
    this.updateSelectedCategoriesFormValue();
    this.selectableCategories = this.selectableCategories
      .filter((c) => c.id !== +categoryId);
  }

  onFeatureIdChange(featureId: number) {
    this.setSelectableCategories(+featureId);
  }

  showExcludedCompanies() {
    return this.includedCompanyIds.length == 0;
  }

  showIncludedCompanies() {
    return this.excludedCompanyIds.length == 0;
  }

  // onCompanySelected(companyId) {
  //   this.itemForm.patchValue({ companyId: companyId });
  // }

  excludedCompanyIdsSelected(selectedCompanyIds: number[]) {
    this.excludedCompanyIds = selectedCompanyIds;
    if (selectedCompanyIds.length > 0) {
      this.includedCompanyIds = [];
    }
  }

  includedCompanyIdsSelected(selectedCompanyIds: number[]) {
    this.includedCompanyIds = selectedCompanyIds;
    if (selectedCompanyIds.length > 0) {
      this.excludedCompanyIds = [];
    }
  }

  openLifeActionsList() {
    const selectListModal = this.modalService
      .open(CmsListSelectComponent, { size: 'lg' });
    selectListModal.componentInstance.title =
      'Select the lifeactions do you want to link to this article';
    selectListModal.componentInstance.dataList = this.lifeActions;
    selectListModal.componentInstance.selectedItens = this.indicators;
    selectListModal.componentInstance.columnList = ['id', 'name', 'category'];
    selectListModal.result.then((res) => {
      if (res.selectedItens) {
        this.indicators = res.selectedItens;
      }
    });
  }

  removeIndicator(item) {
    this.indicators = this.indicators.filter((ind) => ind[0] !== item[0]);
    this.utilService.showToastSuccess('LifeAction deleted.');
  }

  openfilestack() {
    this.filestackService.pickArticleImg().then(
      (res) => {
        this.ngZone.run(() => this.itemForm.patchValue({ image: res }));
      },
      (err) => {
        this.utilService.showToastError('Error while uploading picture', err);
      },
    );
  }

  getMinEndDate() {
    const publishedDate = this.dateService.fromYearMonthDayObject(this.itemForm.get('publishedDate').value);
    const nextDay = this.dateService.getNextDate(publishedDate, 1);
    return this.dateService.toYearMonthDayObject(nextDay);
  }

  updateSelectedCategoriesFormValue() {
    const categoriesFormField = this.itemForm && this.itemForm.get('categories');
    if (!categoriesFormField) {
      return;
    }
    categoriesFormField.setValue(this.selectedCategories.join(','));
  }
}
