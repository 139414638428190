<div class="pl-3 pr-3">
  <div *ngIf="canShow()">

    <div class="mt-4 mb-4">
      <h5> Update your card details:</h5>
      <a
        *ngIf="canViewCurrentCardDetails()"
        (click)="viewCard()"
        class="link">View current card details</a>
      <br />
      <br />
      <button
        type="button"
        class="btn btn-primary"
        (click)="updatePaymentDetailsModal()">Update card </button>
    </div>
    <hr class="my-4 mt-4">

    <div *ngIf="customerInvoices" class="mt-4 mb-4 table-responsive">
      <h5>Payment history:</h5>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Invoice</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of customerInvoices">
            <td>{{formattedDmy(item.date)}}</td>
            <td>{{item.currency | uppercase}} ${{(item.amount_paid) / 100}}</td>
            <td><a (click)="openInvoice(item.invoice_pdf)">Download</a></td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="loading" class="row justify-content-center mb-2">
        <i class="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
      </div>
    </div>
    <div *ngIf="!customerInvoices && !loading" >
      <p>No payment history was found.</p>
    </div>
    <hr class="my-4 mt-4">

    <div class="mt-3 mb-4">
      <h5> Cancelling your subcription:</h5>
      <p>By cancelling your subscription you will no longer have access to the platform or the information you entered. </p>
      <button *ngIf="!isCancelledSubscription"
        type="button"
        class="btn btn-primary"
        (click)="cancelStripeSubscription()">Cancel Subscription</button>
      <p *ngIf="isCancelledSubscription"
        class="end-date">Your subscription ends on {{endDate}}</p>
    </div>
  </div>
</div>
