import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IIdNamePair } from '../app/_models/id-name-pair';
import { IFeature } from '../app/_models/feature';
import { COMPANIES_CMS_FEATURE_TYPES, FEATURES } from '../app/_shared/enums';
import { environment } from '../environments/_active-environment/environment';
import { IEnvironment } from '../app/_models/environment-config';
import { tap } from 'rxjs/operators';
import { has } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private cachedFeatures: any[] = [];
  private envVariablesInMemoryStorage: any;
  public readonly ALL_COMPANY_INCLUDED_OPTION_ID = 0;

  HomeFeatureId = 1;
  DiscoverFeatureId = 2;
  ActFeatureId = 3;
  ShareFeatureId = 4;
  RewardsFeatureId = 5;
  ArticlesFeatureId = 7;
  RecipesFeatureId = 8;
  VideosHealthFeatureId = 9;
  BookmarksFeatureId = 11;
  LifeActionsFeatureId = 12;
  TrackMyHealthFeatureId = 13;
  DevicesFeatureId = 14;
  LeaderboardFeatureId = 15;
  BadgesFeatureId = 16;
  FriendsFeatureId = 17;
  ConveyFeatureId = 18;
  GroupsFeatureId = 19;
  QuizzesFeatureId = 22;
  FitbitFeatureId = 26;
  MyProfileFeatureId = 27;
  NotificationsFeatureId = 28;
  LevelUpFeatureId = 29;
  ContentFeedbackFeatureId = 36;
  PartnersFeatureId = 52;
  ChanceToWinFeatureId = 53;
  ToolkitFeatureId = 56;
  PreferencesFeatureId = 57;
  InformationPacksFeatureId = 58;
  CarouselFeatureId = 59;
  SupportAtCompanyFeatureId = 60;
  LocatorFeatureId = 61;
  RandgoRewardsFeatureId = 62;
  MyRewardsFeatureId = 66;
  HealthRiskAssessmentsFeatureId = 67;
  ResourcesFeatureId = 68;
  GoogleFitFeatureId = 77;
  ProgramsFeatureId = 80;
  ParentPlannerFeatureId = 81;
  SettingsFeatureId = 83;
  StripeFeatureId = 84;
  HealthFeatureId = 85;
  HappinessFeatureId = 86;
  FinancialSecurityFeatureId = 87;
  EapBookingFeatureId = 89;
  GoogleOauthFeatureId = 90;
  ChallengesFeatureId = 93;
  TeamsFeatureId = 94;
  ShowLanguageOptionFeatureId = 97;

  HomeFeatureName = 'Home';
  DiscoverFeatureName = 'Discover';
  ActFeatureName = 'Act';
  ShareFeatureName = 'Share';
  RewardsFeatureName = 'Rewards';
  RecipesFeatureName = 'Recipes';
  BookmarksFeatureName = 'Bookmarks';
  LifeActionsFeatureName = 'LifeActions';
  TrackMyHealthFeatureName = 'Track My Health';
  DevicesFeatureName = 'Devices';
  LeaderboardFeatureName = 'Leaderboard';
  BadgesFeatureName = 'Badges';
  FriendsFeatureName = 'Friends';
  ConveyFeatureName = 'Convey Payments';
  GroupsFeatureName = 'Groups';
  QuizzesFeatureName = 'Quizzes';
  FITBITFeatureName = 'Fitbit';
  MyProfileFeatureName = 'My Profile';
  NotificationsFeatureName = 'Notifications';
  LevelUpFeatureName = 'Level Up';
  PartnersFeatureName = 'Partners';
  ChanceToWinFeatureName = 'Chance To Win';
  ToolkitFeatureName = 'Toolkit';
  PreferencesFeatureName = 'Preferences';
  InformationPacksFeatureName = 'Information Packs';
  CarouselFeatureName = 'Carousel';
  SupportatCompanyFeatureName = 'Support at Company';
  LocatorFeatureName = 'Locator';
  RandgoRewardsFeatureName = 'Randgo Rewards';
  MyRewardsFeatureName = 'My Rewards';
  HealthRiskAssessmentsFeatureName = 'Health Risk Assessments';
  ResourcesFeatureName = 'Resources';
  GoogleFitFeatureName = 'Google Fit';
  ProgramsFeatureName = 'Programs';
  ParentPlannerFeatureName = 'Well-Home';
  SettingsFeatureName = 'Settings';
  StripeFeatureName = 'Stripe';
  HealthFeatureName = 'Well-Body';
  HappinessFeatureName = 'Well-Mind';
  FinancialSecurityFeatureName = 'Financial Security';
  EapBookingFeatureName = 'EAP Booking';
  GoogleOauthFeatureName = 'Google Oauth';
  ChallengesFeatureName = 'Challenges';
  TeamsFeatureName = 'Teams';
  ShowLanguageOptionFeatureName = 'Show language option';


  allFeatures: IIdNamePair[] = [
    { id: this.HomeFeatureId, name: this.HomeFeatureName },
    { id: this.DiscoverFeatureId, name: this.DiscoverFeatureName },
    { id: this.ActFeatureId, name: this.ActFeatureName },
    { id: this.ShareFeatureId, name: this.ShareFeatureName },
    { id: this.RewardsFeatureId, name: this.RewardsFeatureName },
    { id: this.RecipesFeatureId, name: this.RecipesFeatureName },
    { id: this.BookmarksFeatureId, name: this.BookmarksFeatureName },
    { id: this.LifeActionsFeatureId, name: this.LifeActionsFeatureName },
    { id: this.TrackMyHealthFeatureId, name: this.TrackMyHealthFeatureName },
    { id: this.DevicesFeatureId, name: this.DevicesFeatureName },
    { id: this.LeaderboardFeatureId, name: this.LeaderboardFeatureName },
    { id: this.BadgesFeatureId, name: this.BadgesFeatureName },
    { id: this.FriendsFeatureId, name: this.FriendsFeatureName },
    { id: this.ConveyFeatureId, name: this.ConveyFeatureName },
    { id: this.GroupsFeatureId, name: this.GroupsFeatureName },
    { id: this.QuizzesFeatureId, name: this.QuizzesFeatureName },
    { id: this.FitbitFeatureId, name: this.FITBITFeatureName },
    { id: this.MyProfileFeatureId, name: this.MyProfileFeatureName },
    { id: this.NotificationsFeatureId, name: this.NotificationsFeatureName },
    { id: this.LevelUpFeatureId, name: this.LevelUpFeatureName },
    { id: this.PartnersFeatureId, name: this.PartnersFeatureName },
    { id: this.ChanceToWinFeatureId, name: this.ChanceToWinFeatureName },
    { id: this.ToolkitFeatureId, name: this.ToolkitFeatureName },
    { id: this.PreferencesFeatureId, name: this.PreferencesFeatureName },
    { id: this.InformationPacksFeatureId, name: this.InformationPacksFeatureName },
    { id: this.CarouselFeatureId, name: this.CarouselFeatureName },
    { id: this.SupportAtCompanyFeatureId, name: this.SupportatCompanyFeatureName },
    { id: this.LocatorFeatureId, name: this.LocatorFeatureName },
    { id: this.RandgoRewardsFeatureId, name: this.RandgoRewardsFeatureName },
    { id: this.MyRewardsFeatureId, name: this.MyRewardsFeatureName },
    { id: this.HealthRiskAssessmentsFeatureId, name: this.HealthRiskAssessmentsFeatureName },
    { id: this.ResourcesFeatureId, name: this.ResourcesFeatureName },
    { id: this.GoogleFitFeatureId, name: this.GoogleFitFeatureName },
    { id: this.ProgramsFeatureId, name: this.ProgramsFeatureName },
    { id: this.ParentPlannerFeatureId, name: this.ParentPlannerFeatureName },
    { id: this.SettingsFeatureId, name: this.SettingsFeatureName },
    { id: this.StripeFeatureId, name: this.StripeFeatureName },
    { id: this.HealthFeatureId, name: this.HealthFeatureName },
    { id: this.HappinessFeatureId, name: this.HappinessFeatureName },
    { id: this.FinancialSecurityFeatureId, name: this.FinancialSecurityFeatureName },
    { id: this.EapBookingFeatureId, name: this.EapBookingFeatureName },
    { id: this.GoogleOauthFeatureId, name: this.GoogleOauthFeatureName },
    { id: this.ChallengesFeatureId, name: this.ChallengesFeatureName },
    { id: this.TeamsFeatureId, name: this.TeamsFeatureName },
    { id: this.ShowLanguageOptionFeatureId, name: this.ShowLanguageOptionFeatureName },
  ];

  constructor(private http: HttpClient) {
    this.envVariables = environment;
  }

  get featureImagesPathSegment(): string {
    return 'category_images/';
  }

  get envVariables(): IEnvironment {
    return this.envVariablesInMemoryStorage;
  }

  set envVariables(value: IEnvironment) {
    this.envVariablesInMemoryStorage = value;
  }

  getAllFeatures() {
    const url = '/features';

    return this.http.get<any>(url).pipe(
      tap(data => this.updateCacheFeatureData(data)),
    );
  }

  getAllFeaturesByType(type) {
    const url = `/cms/features/type/${type}`;

    return this.http.get<any>(url);
  }

  getAllCachedFeatures() {
    return this.cachedFeatures;
  }

  isExistingFeatureId(featureId: number): boolean {
    return this.cachedFeatures.indexOf(featureId) > -1;
  }

  isIncludeRewardsFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.REWARDS) > -1;
  }

  isIncludeStripeFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.STRIPE) > -1;
  }

  isIncludeInfoPackFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.INFO_PACKS) > -1;
  }

  isIncludeLocatorFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.LOCATOR) > -1;
  }

  isIncludePartnersFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.PARTNERS) > -1;
  }

  isIncludeMyRewardsFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.MY_REWARDS) > -1;
  }

  isIncludeChanceToWinFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.CHANCE_TO_WIN) > -1;
  }

  isIncludeBadgesFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.BADGES) > -1;
  }

  isIncludeChallengesFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.CHALLENGES) > -1;
  }

  isIncludeTeamsFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.TEAMS) > -1;
  }

  isIncludeFriendsFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.FRIENDS) > -1;
  }

  isIncludeGroupsFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.GROUPS) > -1;
  }

  isIncludeLifeActionsFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.LIFE_ACTIONS) > -1;
  }

  isIncludeLeaderboardFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.LEADERBOARD) > -1;
  }

  isIncludeCreateNewGroup(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.CREATE_NEW_GROUP) > -1;
  }

  isIncludeTrackMyHealthFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.TRACK_MY_HEALTH) > -1;
  }

  isIncludeProgramsFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.PROGRAMS) > -1;
  }

  isIncludeHealthRiskAssessmentsFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.HEALTH_RISK_ASSESSMENTS) > -1;
  }

  isIncludePeerSupportFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.PEER_SUPPORT) > -1;
  }

  isIncludeEapBookingFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.EAP_BOOKING) > -1;
  }

  isIncludeSupportAtCompanyFeature(companyFeatureIds: number[]): boolean {
    return companyFeatureIds.indexOf(FEATURES.SUPPORT_AT_COMPANY) > -1;
  }

  isIncludeShowLanguageOptionFeature(companyFeaturesIds: number[]): boolean {
    return companyFeaturesIds.indexOf(FEATURES.SHOW_LANGUAGE_OPTION) > -1;
  }

  allStaticFeatures(): IIdNamePair[] {
    return this.allFeatures
      .slice()
      .sort(this.ascendingNameCompare);
  }

  allFeaturesForDropdownSelection(): IIdNamePair[] {
    return this.cachedFeatures.filter(f => f.type !== FEATURES.HOME)
      .map(f => ({ id: f.id, name: f.name }))
      .slice()
      .sort(this.ascendingNameCompare);
  }

  private ascendingNameCompare(a, b) {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA > nameB) {
      return 1;
    }
    if (nameA < nameB) {
      return -1;
    }

    return 0;
  }

  filterActiveFeatures(data) {
    return data.filter(d => d.active === true);
  }

  setCheckedPropertyOnFeatures(data) {
    return data.map(d => {
      d.checked = false;
      return d;
    });
  }

  featureName(featureId: number): string {
    const feature = this.allStaticFeatures()
      .find(e => e.id === featureId);

    if (!feature) {
      return 'Unknown Feature';
    }

    return feature.name;
  }

  updateCacheFeatureData(data) {
    if (!data || !data.length) {
      return;
    }
    this.cachedFeatures = data;
    this.allFeatures = data.map(f => ({ id: f.id, name: f.name }));
  }

  refreshCache() {
    if (this.cachedFeatures.length) {
      return this.cachedFeatures;
    }

    return this.getAllFeatures()
      .subscribe(data => {
          this.updateCacheFeatureData(data);
        },
        () => this.cachedFeatures = []);
  }

  getFeatureTypeNameById(id: number) {
    return Object.keys(COMPANIES_CMS_FEATURE_TYPES).find(key => COMPANIES_CMS_FEATURE_TYPES[key] === id);
  }

  getFeatureById(id: number) {
    const url = '/cms/features/' + id;

    return this.http.get<any>(url);
  }

  getNewFeature(): IFeature {
    return {
      id: 0,
      name: '',
      type: null,
      menuImage: '',
      includedCompanyIds: [],
    };
  }

  updateFeature(feature: IFeature) {
    const body: any = {
      name: feature.name,
      type: feature.type,
      menuImage: feature.menuImage,
    };

    if (has(feature, 'includedCompanyIds')) {
      body.includedCompanyIds = feature.includedCompanyIds;
    }

    const url = `/cms/features/${feature.id}`;

    return this.http.put<any>(url, body);
  }

  addFeature(feature: IFeature) {
    const body: any = {
      name: feature.name,
      type: feature.type,
      menuImage: feature.menuImage,
    };

    if (has(feature, 'includedCompanyIds')) {
      body.includedCompanyIds = feature.includedCompanyIds;
    }

    const url = '/cms/features';

    return this.http.post<any>(url, body);
  }

  deleteFeature(id: number) {
    const url = `/cms/features/${id}`;

    return this.http.delete<any>(url);
  }

  getFeatureStorageAbsolutePath(path, filename) {
    if (path.slice(-1) !== '/') {
      path += '/';
    }
    return `${this.envVariables.aws.awsBucket}${path}${filename}`;
  }

  getImageForFeature(image: string): string {
    if (!image) {
      return null;
    }
    return this.getFeatureStorageAbsolutePath(this.featureImagesPathSegment, image);
  }

  getFeatureTypeOptions() {
    return Object.keys(COMPANIES_CMS_FEATURE_TYPES)
      .filter(val => isNaN(Number(val)) && COMPANIES_CMS_FEATURE_TYPES[val] !== FEATURES.HOME)
      .map(key => ({
        id: COMPANIES_CMS_FEATURE_TYPES[key],
        name: key,
      }));
  }
}
