import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo-preview',
  templateUrl: './logo-preview.component.html',
  styleUrls: ['./logo-preview.component.scss'],
})
export class LogoPreviewComponent implements OnInit {
  @Input() public logoSrc: string;

  constructor() {
  }

  ngOnInit() {
  }
}
