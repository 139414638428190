import { Route, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { RegisterFormComponent } from './forms/register-form/register-form.component';
import { ForgetPasswordFormComponent } from './forms/forget-password-form/forget-password-form.component';
import { LoginFormComponent } from './forms/login-form/login-form.component';
import { ResetPasswordFormComponent } from './forms/reset-password-form/reset-password-form.component';

import { IAuthRouteData } from '../_models/auth';
import { SecureLoginComponent } from './secure-login/secure-login.component';
import { AuthorizeComponent } from './authorize/authorize.component';
import { SsoLoginComponent } from './sso-login/sso-login.component';
import { SsoLogoutComponent } from './sso-logout/sso-logout.component';
import { SSOGuard } from '../guards/sso-guard.service';
import { GuestGuard } from '../guards/guest.guard';
import { AltiusLifeDomainGuard } from '../guards/altiuslifeDomain.guard';
import { CloseAccountComponent } from './close-account/close-account.component';

interface ICustomRoutes extends Route {
  data?: IAuthRouteData;
}

const authRoutes: ICustomRoutes[] = [
  {
    path: 'auth/login',
    component: LoginFormComponent,
    canActivate: [GuestGuard],
    data: {
      isLogin: true,
      isRegister: false,
      isForgetPassword: false,
      isResetPassword: false,
      isSecureLogin: false,
    },
  },
  {
    path: 'auth/register',
    component: RegisterFormComponent,
    canActivate: [AltiusLifeDomainGuard, SSOGuard, GuestGuard,],
    data: {
      isLogin: false,
      isRegister: true,
      isForgetPassword: false,
      isResetPassword: false,
      isSecureLogin: false,
    },
  },
  {
    path: 'auth/forget-password',
    component: ForgetPasswordFormComponent,
    canActivate: [SSOGuard, GuestGuard],
    data: {
      isLogin: false,
      isRegister: false,
      isForgetPassword: true,
      isResetPassword: false,
      isSecureLogin: false,
    },
  },
  {
    path: 'auth/reset-password',
    component: ResetPasswordFormComponent,
    canActivate: [SSOGuard, GuestGuard],
    data: {
      isLogin: false,
      isRegister: false,
      isForgetPassword: false,
      isResetPassword: true,
      isSecureLogin: false,
    },
  },
  {
    path: 'auth/secure-login',
    component: SecureLoginComponent,
    data: {
      isLogin: false,
      isRegister: false,
      isForgetPassword: false,
      isResetPassword: false,
      isSecureLogin: true,
    },
  },
  {
    path: 'auth/verified',
    component: LoginFormComponent,
    canActivate: [AltiusLifeDomainGuard, SSOGuard, GuestGuard],
    data: {
      isLogin: true,
      isRegister: false,
      isForgetPassword: false,
      isResetPassword: false,
      isSecureLogin: false,
    },
  },
  {
    path: 'auth/authorize',
    component: AuthorizeComponent,
    data: {
      isLogin: true,
      isRegister: false,
      isForgetPassword: false,
      isResetPassword: false,
      isSecureLogin: false,
    },
  },
  {
    path: 'auth/sso-login',
    component: SsoLoginComponent,
    data: {
      isLogin: true,
      isRegister: false,
      isForgetPassword: false,
      isResetPassword: false,
      isSecureLogin: false,
    },
  },
  {
    path: 'auth/sso-logout',
    component: SsoLogoutComponent,
    data: {
      isLogin: true,
      isRegister: false,
      isForgetPassword: false,
      isResetPassword: false,
      isSecureLogin: false,
    },
  },
  {
    path: 'auth/close-account',
    component: CloseAccountComponent,
    canActivate: [AltiusLifeDomainGuard],
    data: {
      isLogin: true,
      isRegister: false,
      isForgetPassword: false,
      isResetPassword: false,
      isSecureLogin: false,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
