<div class="modal-header">
  <h4 class="modal-title">Datasource: {{item.name}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div [formGroup]="form" class="modal-body">
  <p *ngIf="message">{{message}}</p>
  <div *ngFor="let source of radios; let i = index" class="row mx-1 mb-4">
    <div class="custom-control custom-radio">
      <input id="{{source.label}}" checked="{{source.checked}}" [attr.disabled]="source.disabled " class="custom-control-input"
        formControlName="datasource" value="{{source.value}}" type="radio">
      <label class="custom-control-label " [attr.disabled]="source.disabled" for="{{source.label}}">
        <div *ngIf="buttons[i].icon">
          <img [src]="buttons[i].icon">
        </div>
        <div *ngIf="!buttons[i].icon">{{source.label}}</div>
      </label>
    </div>
    <div *ngIf="buttons[i].type">
      <button type="button" (click)="connectToDevice(buttons[i].type)" class="btn btn-primary">{{buttons[i].label}}</button>
    </div>
  </div>
  <div class="footerButtons" *ngIf="parentPage != 'team-details'">
    <button type="button" class="btn btn-primary" (click)="save()">Save</button>
    <button type="button" class="btn btn-outline-primary" (click)="close()">Close</button>
  </div>
</div>
