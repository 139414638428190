import { Component, Input, NgZone, OnInit } from '@angular/core';
import { UserService } from '../../../services/user-service';
import { FilestackService } from '../../../services/filestack-service';
import { UtilService } from '../../../services/util-service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-group',
  styleUrls: ['./add-group.component.scss'],
  templateUrl: './add-group.component.html',
})
export class AddGroupComponent implements OnInit {
  @Input() name;
  @Input() description;
  logo: any;
  banner: any;
  error = 0;

  constructor(
    private activeModal: NgbActiveModal,
    public userService: UserService,
    public utilService: UtilService,
    public filestackService: FilestackService,
    public _zone: NgZone,
  ) {
  }

  ngOnInit() {
    this.logo = '1700_logo_vertical.png';
    this.banner = 'default-banner.png';
  }

  save(): void {
    this.error = 0;
    if (this.name.trim() === '') {
      this.error = 1;
    }
    if (this.error === 0 && this.description.trim() === '') {
      this.error = 2;
    }
    if (this.error === 0) {
      const data = {
        name: this.name,
        description: this.description,
        logo: this.logo,
        banner: this.banner,
      };
      this.activeModal.close(data);
    }
  }

  showPicker(type) {
    if (type === 1) {
      this.filestackService.pickGroupLogo().then(
        res => {
          this._zone.run(() => (this.logo = res));
        },
        err => {
          this.utilService.showToast(
            'error',
            'Error while uploading picture',
            err,
          );
        },
      );
    } else {
      this.filestackService.pickGroupBanner().then(
        res => {
          this._zone.run(() => (this.banner = res));
        },
        err => {
          this.utilService.showToast(
            'error',
            'Error while uploading picture',
            err,
          );
        },
      );
    }
  }

  getPhoto(path, photo) {
    return this.userService.envVariables.aws.awsBucket + path + photo;
  }

  close() {
    this.activeModal.dismiss();
  }
}
