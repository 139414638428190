import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from '../../../services/util-service';
import { UserService } from '../../../services/user-service';
import { IQuestionnaire } from '../../_models/questionnaire';

@Component({
  selector: 'app-competition-widget',
  templateUrl: './competition-widget.component.html',
  styleUrls: ['./competition-widget.component.scss']
})
export class CompetitionWidgetComponent {
  @Input() currentCompetition: IQuestionnaire;

  constructor(
    private userService: UserService,
    private router: Router,
    private utilService: UtilService
  ) { }

  getCompetitionSrc(): string {
    if (!this.currentCompetition) { return ''; }

    const src = this.currentCompetition.widgetImage
      ? this.currentCompetition.widgetImage
      : this.currentCompetition.banner;

    return this.userService.getQuestionnaireImage(src);
  }

  onCompetitionClick() {
    const titleAndIdHyphenated = this.utilService.hyphenateUrl(
      this.currentCompetition.name,
      this.currentCompetition.id);
    this.router.navigate(['competition', titleAndIdHyphenated]);
  }
}
