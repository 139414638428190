import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DomainCheckerService {
  constructor() {
    this.hostname = location.hostname;
  }

  private _hostname: string;

  get hostname(): string {
    return this._hostname;
  }

  set hostname(hostname: string) {
    this._hostname = hostname;
  }
}
