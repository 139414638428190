import { UtilService } from '../services/util-service';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { environment } from '../environments/_active-environment/environment';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth-service';
import { UserService } from '../services/user-service';
import { InsightsService } from '../services/insights-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from '../components/alert-modal/alert-modal.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  isFetchingRefreshToken = false;
  accessTokenSubject = new BehaviorSubject<string>(null);
  alertModal: NgbModalRef;

  constructor(
    private utilService: UtilService,
    private authService: AuthService,
    private userService: UserService,
    private modalService: NgbModal,
    private insightsService: InsightsService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isExternalUrl = this.utilService.isExternalUrl(req.url);
    const apiUrl = environment.app.endpoint;

    let apiReq;
    if (!isExternalUrl) {
      apiReq = req.clone({
        url: `${apiUrl}${req.url}`,
      });
    } else {
      apiReq = req;
    }

    return next.handle(apiReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          const isUpdateTimezoneApi = (/\/users\/timezone\/+[^\/]+\/me/gm).test(req.url);
          if (isUpdateTimezoneApi) {
            return this.userService.refreshLocalStorageUser().pipe(
              tap(() => {
                const hostOrigin = location.origin;
                const redirectDomain = this.userService.getUserCompanyDomainOrigin(
                  this.insightsService.isInsightsDomain(),
                );
                if (redirectDomain.includes(hostOrigin)) {
                  return this.handleErrorResponse();
                }
                const errorMessage = '<p>We have detected that you are currently logged in to the wrong domain. In order to ensure a seamless experience, we need to log you out.</p>'
                + `<p>Don't worry, you will be redirected to the correct domain: <a href='${redirectDomain}'>${redirectDomain}</a>. `
                + 'Please log in again using the correct domain to continue accessing your account without any interruption.</p>'
                + '<p>We apologize for this inconvenience. Thank you for your understanding.</p>'
                return this.handleErrorResponse(errorMessage, 'OK');
              }));
          }
          if (this.insightsService.isInsightsDomain()) {
            return this.handleErrorResponse();
          }
          if (this.userService.refreshToken) {
            return this.handleRefreshToken(req, next);
          } else {
            return this.handleErrorResponse();
          }
        }
        return throwError(error);
      }),
    );
  }

  handleRefreshToken(request: HttpRequest<any>, next: HttpHandler) {
    if (this.isFetchingRefreshToken) {
      return this.accessTokenSubject.pipe(
        filter((accessToken) => !!accessToken),
        take(1),
        switchMap(() => next.handle(this.normalizeRequestHeaders(request))),
      );
    } else {
      this.isFetchingRefreshToken = true;
      this.accessTokenSubject.next(null);
      return this.authService.refreshToken(this.userService.refreshToken).pipe(
        map(
          (res) => {
            this.isFetchingRefreshToken = false;
            if (res.access_token) {
              this.accessTokenSubject.next(res.access_token);
              return next.handle(this.normalizeRequestHeaders(request));
            } else {
              return this.handleErrorResponse();
            }
          },
          catchError(() => {
            return this.handleErrorResponse();
          }),
        ),
      );
    }
  }

  handleErrorResponse(customMessage?, textButton?) {
    if (this.alertModal) {
      return of(null);
    }
    this.alertModal = this.modalService.open(AlertComponent, { beforeDismiss: () => false });
    if (textButton) {
      this.alertModal.componentInstance.textButton = textButton;
    }
    this.alertModal.componentInstance.title = 'Attention!';
    this.alertModal.componentInstance.message = customMessage ?
      customMessage : 'Your session has expired and you will need to login again.';
    this.alertModal.componentInstance.type = 1;
    this.alertModal.componentInstance.showCloseIcon = false;
    this.alertModal.result.then(() => {
      const originDomain = this.userService.getUserCompanyDomainOrigin(this.insightsService.isInsightsDomain());
      localStorage.clear();
      this.alertModal = null;
      window.location.href = `${originDomain}/auth/login`;
      return of(null);
    });
  }

  normalizeRequestHeaders(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        Authorization: this.userService.token,
        'Content-Type': 'application/json',
      },
      url: `${environment.app.endpoint}${request.url}`,
    });
  }
}
