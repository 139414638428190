import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageService } from '../../services/language-service';
import { ILanguage } from '../_models/language';
import { LANGUAGES } from '../_shared/enums';

@Component({
  selector: 'app-language-filter',
  templateUrl: './language-filter.component.html',
  styleUrls: ['./language-filter.component.scss'],
})
export class LanguageFilterComponent implements OnInit {
  data: any;
  @Input() defaultLang: string = LANGUAGES.en;
  @Output() languagesLoadedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private languageService: LanguageService,
  ) {
  }

  ngOnInit() {
    this.buildDropDownList();
  }

  buildDropDownList() {
    this.languageService.getAvailableLanguages()
      .subscribe((data: ILanguage[]) => {
        this.data = data;
        this.languagesLoadedEvent.emit(data);
        // this.languages.unshift({ name: '-- Select a language --', code: '' });
      });
  }

  onChange(value) {
    this.filterEvent.emit(value);
  }
}
