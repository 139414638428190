<div *ngIf="activatedRoute.children.length === 0">
  <h2 class="mt-2">Partner Services</h2>
  <div class="row">
    <div *ngFor="let item of partners"
      class="col-12 col-sm-6 d-flex mt-4 mb-2"
      (click)="goToPartnerDetailPage(item)">
      <div class="card w-100 mr-0 ml-0 p-0">
        <div class="card-img-top-div">
          <img class="card-img-top" [src]="formatImagePath(item.image)">
        </div>
        <div class="card-body">
          <h5 class="card-title">{{item.name}}</h5>
          <p class="card-text" [innerHTML]="item.shortText"></p>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="activatedRoute.children.length > 0">
  <router-outlet></router-outlet>
</div>
