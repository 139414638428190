<div class="container">
  <h2>Forgot Password</h2>
  <p>Please provide the email address you used to register - we will send you an email with a link to reset your
    password.</p>
  <form [formGroup]="form" (ngSubmit)="onSubmitForgetPasswordForm()">

    <div class="form-group">
      <app-email-input [parentForm]="form" [inputcss]="setInputOutlineClass(email)"></app-email-input>
    </div>

    <button type="submit" class="btn btn-primary" [disabled]="isFormSubmitDisabled()">Submit</button>



  </form>
</div>
<div *ngIf="isLoading" class="row justify-content-center mb-2">
  <i class="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
</div>