import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user-service';
import { CompanyService } from '../../services/company-service';
import { DomainCheckerService } from '../../services/domain-checker.service';

@Component({
  selector: 'app-qr-page',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss'],
})
export class QRComponent implements OnInit {
  logo: string;
  primaryColor: string;
  registerCode: string;
  homeUrl: string;
  bookingUrl: string;
  hasError: boolean;
  isLoading: boolean;

  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    private domainChecker: DomainCheckerService,
  ) {

  }

  ngOnInit() {
    this.isLoading = true;
    this.hasError = false;
    this.homeUrl = `https://${this.domainChecker.hostname}`;
    this.bookingUrl = `https://${this.domainChecker.hostname}/toolkit/booking`;
    this.companyService.getCompanyInfoByDomain(this.domainChecker.hostname).subscribe(data => {
      this.isLoading = false;
      if (data) {
        this.registerCode = data.registerCode;
        this.logo = this.userService.getLogoForCompany(data.logo);
        if (data.bookingCode) {
          this.bookingUrl = `https://www.peoplesense.au/${data.bookingCode}`;
        }
        // theming
        const style = document.createElement('style');
        style.innerHTML += `
        .qr-button {background: ${data.primaryColor}}
        .qr-register-code {color: ${data.primaryColor} !important}
      `;
        document.getElementsByTagName('head')[0].appendChild(style);
      } else {
        this.hasError = true;
      }
    });
  }
}
