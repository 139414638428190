<div class='form-group'>
  <label class='control-label required'>Language</label>
  <select
    class='form-control custom-select'
    (change)='onChange($event.target.value)'>
    <option
      *ngFor='let item of data'
      [value]='item.code' [selected]='item.code == defaultLang'>{{item.name}}
    </option>
  </select>
</div>
