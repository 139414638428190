import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { UserService } from './user-service';
import { UtilService } from './util-service';
import { DateService } from './date.service';
import { IIndicatorTrackerLogValue } from '../app/_models/indicator-tracker-log-value';
import { IIndicator } from '../app/_models/indicator';
import { FEATURES } from '../app/_shared/enums';

@Injectable({
  providedIn: 'root'
})
export class TMHService {
  readonly TRACKER_LIMIT = 7;
  readonly SLEEP_INDICATOR_ID = 2;

  readonly MORE_THAN_OPERAND = 'moreThan';
  readonly LESS_THAN_OPERAND = 'lessThan';
  readonly BETWEEN_OPERAND = 'between';

  private notTMH = new BehaviorSubject<boolean>(true);
  currentNotTMH$ = this.notTMH.asObservable();

  private activeIndicators = new BehaviorSubject<any>([]);
  currentActiveIndicators$ = this.activeIndicators.asObservable();

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private userService: UserService,
    private dateService: DateService
  ) { }

  changeNotTMH(value) {
    this.notTMH.next(value);
  }

  changeActiveIndicators(activeIndicators: any[]) {
    this.activeIndicators.next(activeIndicators);
  }

  getCurrentActiveIndicators() {
    return this.currentActiveIndicators$;
  }

  upsertIndicatorRule(indicator, operand, lowerValue, upperValue) {
    const type = indicator.dataSource.type;
    const payload = {
      lowerValue: lowerValue,
      upperValue: upperValue,
      operand: operand,
      indicatorId: indicator.id,
      dataSource: type
    };
    const url = `/indicators/rules`;

    return this.http
      .post(url, payload);
  }

  batchSelfTrackers(
    indicatorId: number,
    indicatorName: string,
    batch: IIndicatorTrackerLogValue[]) {

    const url = '/indicators/knex/batchtrackers';
    const body = {
      indicatorId,
      indicatorName,
      batch,
      type: 'self'
    };

    return this.http.post<any>(url, body);
  }

  getAllIndicators() {
    const tmhFeatureId = FEATURES.TRACK_MY_HEALTH;
    const url = `/indicators/all/${tmhFeatureId}`;

    return this.http.get<any>(url);
  }

  getAllIndicatorsForReport() {
    const tmhFeatureId = FEATURES.TRACK_MY_HEALTH;
    const url = `/indicators/all/report/${tmhFeatureId}`;

    return this.http.get<any>(url);
  }

  refreshActiveIndicators() {
    return this.getTrackersWithLimit()
      .pipe(
        map(data => this.filterForDailyRecurrenceIndicatorsWithValue(data.indicators)),
        tap((activeIndicators) => this.changeActiveIndicators(activeIndicators)),
        first()
      ).subscribe();
  }

  refreshActiveIndicatorsForIndicators(indicators: any[]) {
    const activeIndicators = this.filterForDailyRecurrenceIndicatorsWithValue(indicators);
    this.changeActiveIndicators(activeIndicators);
  }

  private filterForDailyRecurrenceIndicatorsWithValue(indicators) {
    const filtered = indicators.filter(i => {
      return i.recurrence === 1
        && i.trackers && i.trackers.length > 0
        && this.dateService.isToday(i.trackers[0].date);
    });

    filtered.forEach(indicator => {
      this.applyIndicatorTrackerValueIfToday(indicator);
      this.applyIndicatorPercent(indicator);
      indicator.strokeColor = (indicator.percent > 99.99)
        ?  '#4caf50'
        : this.userService.company.primaryColor;
    });

    return [...filtered];
  }

  getTrackersWithLimit() {
    const tzOffsetDateTime = this.dateService.getLocalDateTimeTzOffset();
    const url = `/indicators/knex/trackers/${this.TRACKER_LIMIT}/${tzOffsetDateTime}`;

    return this.http.get<any>(url).pipe(
      tap(data => data.indicators.map(tracker => {
        this.setDataSourceType(tracker);
      })));
  }

  getTrackerWithLimit(id: number) {
    const tzOffsetDateTime = this.dateService.getLocalDateTimeTzOffset();
    const url = `/indicators/knex/trackers/${id}/${this.TRACKER_LIMIT}/${tzOffsetDateTime}`;

    return this.http.get<any>(url).pipe(
      tap(indicator => {
        if (indicator.indicator) {
          this.setDataSourceType(indicator.indicator);
        } else {
          this.setDataSourceType(indicator);
        }
      }));
  }

  private setDataSourceType(indicator: any) {
    indicator.dataSource.isSelf = indicator.dataSource.type === 'self';
    indicator.dataSource.isFitbit = indicator.dataSource.type === 'fitbit';
    indicator.dataSource.isGoogleFit = indicator.dataSource.type === 'googlefit';
  }

  getIndicatorsData() {
    const data = {
      indicators: [],
      dailyGoals: [],
      longGoals: [],
      achievedBadges: []
    };

    return this.getTrackersWithLimit().pipe(
      map(res => {
        this.applyIndicatorsValues(res.indicators);

        data.indicators = res.indicators;
        data.achievedBadges = res.achievedBadges;
        data.dailyGoals = res.indicators
          .filter((e) => this.isDailyGoalIndicator(e));
        data.dailyGoals = _.sortBy(data.dailyGoals, 'name');
        data.longGoals = res.indicators
          .filter((e) => this.isLongTermGoalIndicator(e));
        data.longGoals = _.sortBy(data.longGoals, 'name');

        return data;
      }));
  }

  refreshIndicatorsWithUpdatedIndicator(indicators, dailyIndicators,
    longTermIndicators, updatedIndicatorId, pointRadiusValue,
    primaryColor, goalAchievedColor, goalMissedColor) {
    return this.getTrackerWithLimit(updatedIndicatorId).pipe(
      tap(updatedIndicator => {
        if (updatedIndicator.indicator) {
          updatedIndicator = updatedIndicator.indicator;
        }
        this.applyIndicatorValues(updatedIndicator);
        this.applyIndicatorChartingProperties(updatedIndicator,
          pointRadiusValue, primaryColor, goalAchievedColor, goalMissedColor);

        this.replaceUpdatedIndicatorInIndicators(indicators, updatedIndicator);
        this.replaceUpdatedIndicatorInIndicators(dailyIndicators, updatedIndicator);
        this.replaceUpdatedIndicatorInIndicators(longTermIndicators, updatedIndicator);
      }));
  }

  displayRelevantDeviceTrackerValueMessages(indicators) {
    indicators.forEach(indicator => {
      if (!indicator.dataSource.isSelf && indicator.trackedToday) {
        const dataSource = _.startCase(indicator.dataSource.type);
        const message = (indicator.achievedThisUpdate)
          ? `Congratulations! You have achieved your ${indicator.name} goal!`
          : `${indicator.name} data tracked today using ${dataSource}`;
        this.utilService.showToastSuccess(message);

      }
    });
  }
  private replaceUpdatedIndicatorInIndicators(indicators, updatedIndicator) {
    const index = indicators.map(i => i.id).indexOf(updatedIndicator.id);
    if (index > -1) {
      indicators[index] = updatedIndicator;
    }
  }

  private applyIndicatorsValues(indicators) {
    indicators.forEach(indicator => {
      this.applyIndicatorValues(indicator);
    });
  }

  applyIndicatorValues(indicator) {
    this.applyDataSourceInputName(indicator);
    this.applyIndicatorFormattedDate(indicator);
    this.applyIndicatorTrackerValueIfToday(indicator);
    this.applyIndicatorMaxTrackerValue(indicator);
    this.applyIndicatorPercent(indicator);
    this.applyIndicatorDisplayValues(indicator);
    this.applyIndicatorLogValues(indicator);
    this.applyIndicatorGoalValues(indicator);
  }

  private applyDataSourceInputName(indicator) {
    let inputName = '';
    if (indicator.dataSource.isSelf) {
      inputName = 'Self-input';
    }

    if (indicator.dataSource.isFitbit) {
      inputName = 'Fitbit';
    }

    if (indicator.dataSource.isGoogleFit) {
      inputName = 'Google Fit';
    }

    indicator.dataSource.inputName = inputName;
  }

  private applyIndicatorFormattedDate(indicator) {
    indicator.dateOfTracker = (indicator.trackers.length)
      ? this.dateService.formatDD_MMM_YYYY(indicator.trackers[0].date)
      : this.dateService.formatDD_MMM_YYYY();
  }

  private applyIndicatorTrackerValueIfToday(indicator) {
    indicator.trackerValue = (indicator.trackers.length
      && this.dateService.isToday(indicator.trackers[0].date))
      ? indicator.trackers[0].value
      : 0;
  }

  private applyIndicatorMaxTrackerValue(indicator) {
    indicator.maxTrackerValue = 0;
    if (indicator.trackers.length) {
      const trackerValues = indicator.trackers.map(t => t.value);
      indicator.maxTrackerValue = Math.max(...trackerValues);
    }
  }

  private applyIndicatorPercent(indicator) {
    indicator.goalValue = indicator.indicatorRule
      ? indicator.indicatorRule.lowerValue
      : indicator.defaultIndicatorRule.lowerValue;

    indicator.percent = (!!indicator.trackerValue && !!indicator.goalValue)
      ? Math.round(((indicator.trackerValue * 100) / indicator.goalValue) * 100) / 100
      : 0;
  }

  private applyIndicatorDisplayValues(indicator) {
    indicator.goalDisplay = this.getGoalDisplay(indicator);
    indicator.trackerDisplay = this.getTrackerDisplay(indicator);
  }

  private applyIndicatorLogValues(indicator) {
    indicator.logTitle = (indicator.unit)
      ? `Add your ${indicator.name} (${indicator.unit})`
      : `Add your ${indicator.name}`;

    if (this.isSleepIndicator(indicator)) {
      indicator.logValue1 = { label: 'Hours', type: 'number', value: '', required: true };
      indicator.logValue2 = { label: 'Minutes', type: 'number', value: '', required: false };
    } else {
      indicator.logValue1 = { label: 'Value', type: 'number', value: '', required: true };
      indicator.logValue2 = null;
    }
  }

  private applyIndicatorGoalValues(indicator) {
    indicator.goalTitle = (indicator.unit)
      ? `Update your ${indicator.name} goal (${indicator.unit})`
      : `Update your ${indicator.name} goal`;

    if (this.isSleepIndicator(indicator)) {
      indicator.goalValue1 = { label: 'Hours', type: 'number', value: '', required: true };
      indicator.goalValue2 = { label: 'Minutes', type: 'number', value: '', required: false };
    } else {
      indicator.goalValue1 = { label: 'Value', type: 'number', value: '', required: true };
      indicator.goalValue2 = null;
    }
  }

  applyIndicatorsChartingProperties(indicators, pointRadiusValue,
    primaryColor, goalAchievedColor, goalMissedColor): void {
    indicators.map(indicator => this.applyIndicatorChartingProperties(
      indicator, pointRadiusValue, primaryColor, goalAchievedColor, goalMissedColor));
  }

  applyIndicatorChartingProperties(indicator, pointRadiusValue,
    primaryColor, goalAchievedColor, goalMissedColor): void {
    indicator.strokeColor = (indicator.percent > 99.99)
      ? goalAchievedColor
      : primaryColor;

    this.populateIndicatorChartingProperties(indicator, pointRadiusValue, primaryColor, goalMissedColor);
    this.limitIndicatorChartingPropertiesValues(indicator, this.TRACKER_LIMIT);
    this.reverseIndicatorChartingPropertiesValuesOrderForDateAscending(indicator);
  }

  private populateIndicatorChartingProperties(indicator, pointRadiusValue, goalAchievedColor, goalMissedColor) {
    indicator.chartData = this.getTrackersValues(indicator.trackers);
    indicator.chartMaxValue = this.getChartMaxValue(indicator);
    indicator.chartLabels = this.getTrackersDates(indicator.trackers);
    indicator.chartPointRadius = this.getTrackersPointsRadius(indicator.trackers, pointRadiusValue);
    indicator.chartPointBackgroundColors = this.getTrackersGoalAchievedColors(indicator, goalAchievedColor, goalMissedColor);
  }

  private limitIndicatorChartingPropertiesValues(indicator, limit) {
    indicator.chartData = this.limitIndicatorChartingPropertyValues(indicator.chartData, limit);
    indicator.chartLabels = this.limitIndicatorChartingPropertyValues(indicator.chartLabels, limit);
    indicator.chartPointRadius = this.limitIndicatorChartingPropertyValues(indicator.chartPointRadius, limit);
    indicator.chartPointBackgroundColors = this.limitIndicatorChartingPropertyValues(indicator.chartPointBackgroundColors, limit);
  }

  private reverseIndicatorChartingPropertiesValuesOrderForDateAscending(indicator) {
    indicator.chartData.reverse();
    indicator.chartLabels.reverse();
    indicator.chartPointRadius.reverse();
    indicator.chartPointBackgroundColors.reverse();
  }

  private limitIndicatorChartingPropertyValues(values, limit) {
    values = values.slice(0, limit);

    const fillNumber = limit - values.length;
    if (fillNumber <= 0) { return values; }

    values = values.concat(Array(fillNumber).fill(''));

    return values;
  }

  private getTrackersValues(trackers) {
    return trackers.map(tracker => {
      return this.isSleepTracker(tracker)
        ? this.getMinutesFormattedAsDecimal(tracker.value)
        : tracker.value;
    });
  }

  private getTrackerDisplay(indicator) {
    if (this.isSleepIndicator(indicator)) {
      return this.getMinutesFormattedAsHrsMins(indicator.trackerValue);
    }

    return this.getValueAndUnit(indicator.trackerValue, indicator.unit);
  }

  private getGoalDisplay(indicator) {
    const activeIndicatorRule = this.getActiveIndicatorRule(indicator);
    const operandDisplay = this.getOperandDisplay(activeIndicatorRule.operand);

    if (this.isBetweenGoalOperand(activeIndicatorRule.operand)) {
      let displayValue1;
      let displayValue2;

      if (this.isSleepIndicator(indicator)) {
        displayValue1 = this.getMinutesFormattedAsHrsMins(activeIndicatorRule.lowerValue);
        displayValue2 = this.getMinutesFormattedAsHrsMins(activeIndicatorRule.upperValue);
      } else {
        displayValue1 = activeIndicatorRule.lowerValue;
        displayValue2 = this.getValueAndUnit(activeIndicatorRule.upperValue, indicator.unit);
      }

      return `${operandDisplay} ${displayValue1} and ${displayValue2}`;
    } else {
      let displayValue;
      if (this.isSleepIndicator(indicator)) {
        displayValue = this.getMinutesFormattedAsHrsMins(activeIndicatorRule.lowerValue);
      } else {
        displayValue = this.getValueAndUnit(activeIndicatorRule.lowerValue, indicator.unit);
      }

      return `${operandDisplay} ${displayValue}`;
    }
  }

  getActiveIndicatorRule(indicator) {
    return indicator.indicatorRule || indicator.defaultIndicatorRule;
  }

  getAllOperands() {
    return [this.MORE_THAN_OPERAND, this.LESS_THAN_OPERAND, this.BETWEEN_OPERAND];
  }

  private getOperandDisplay(operand) {
    if (!operand) { return ''; }

    return operand.replace(/([A-Z])/g, ' $1').trim().toLowerCase();
  }

  toIndicatorDisplayValue(indicator: IIndicator, value: number): string {
    if (this.isSleepIndicator(indicator)) {
      return this.getMinutesFormattedAsHMM(value);
    }

    return Math.round(value).toLocaleString();
  }

  private getChartMaxValue(indicator) {
    let max = Math.max(indicator.goalValue, indicator.maxTrackerValue);
    if (this.isSleepIndicator(indicator)) {
      max = max / 60;
    }

    const interim = Math.ceil(max * 1.2 / 10);
    return (interim + interim % 2) * 10;
  }

  private getTrackersDates(trackers) {
    return trackers.map(tracker => {
      return this.dateService.formatDDMM(tracker.date);
    });
  }

  private getTrackersPointsRadius(trackers, pointRadiusValue) {
    return trackers.map(tracker => {
      return !!tracker.value
        ? pointRadiusValue
        : 0;
    });
  }

  private getTrackersGoalAchievedColors(indicator, goalAchievedColor, goalMissedColor) {
    return indicator.trackers.map(tracker => tracker.achievedThisInterval ? goalAchievedColor : goalMissedColor);
  }

  getIndicatorDisplayUnit(indicator: IIndicator): string {
    if (this.isSleepIndicator(indicator)) {
      return 'h:mm';
    }

    return indicator.unit;
  }

  isGoalAchievedValue(indicatorRule, value) {
    if (!indicatorRule) { return false; }

    if (this.isMoreThanGoalOperand(indicatorRule.operand)
      && value >= indicatorRule.upperValue) {
      return true;
    }

    if (this.isBetweenGoalOperand(indicatorRule.operand)
      && value >= indicatorRule.lowerValue
      && value <= indicatorRule.upperValue) {
      return true;
    }

    if (this.isLessThanGoalOperand(indicatorRule.operand)
      && value <= indicatorRule.lowerValue) {
      return true;
    }

    return false;
  }

  isMoreThanGoalOperand(operand) {
    return operand === this.MORE_THAN_OPERAND;
  }

  isLessThanGoalOperand(operand) {
    return operand === this.LESS_THAN_OPERAND;
  }

  isBetweenGoalOperand(operand) {
    return operand === this.BETWEEN_OPERAND;
  }

  isSleepIndicator(indicator) {
    return indicator.id === this.SLEEP_INDICATOR_ID;
  }

  isSleepTracker(tracker) {
    return tracker.indicatorId === this.SLEEP_INDICATOR_ID;
  }

  isDailyGoalIndicator(indicator) {
    return indicator.recurrence === 1;
  }

  isLongTermGoalIndicator(indicator) {
    return indicator.recurrence === null;
  }

  getMinutesFormattedAsHrsMins(minutes) {
    const hours = Math.trunc(minutes / 60);
    const mins = minutes % 60;

    return `${hours} hrs ${mins} mins `;
  }

  getMinutesFormattedAsDecimal(minutes) {
    const hours = Math.trunc(minutes / 60);
    const mins = minutes % 60;

    return `${hours}.${mins}`;
  }

  getHoursMinutesFromMinutes(minutes): number[] {
    const hours = Math.trunc(minutes / 60);
    const mins = minutes % 60;

    return [hours, mins];
  }

  getMinutesFormattedAsHMM(minutes): string {
    const values = this.getHoursMinutesFromMinutes(minutes);
    const mm = ('0' + values[1]).slice(-2);

    return `${values[0]}:${mm}`;
  }

  getMinutesFromHMM(hmm: string): number {
    if (hmm.indexOf(':') < 0) {
      return (+hmm * 60);
    }

    const values = hmm.split(':');
    const hours = +values[0];
    const mins = +values[1];

    return hours * 60 + mins;
  }

  getValueAndUnit(value, unit) {
    if (!!unit) {
      return `${value} ${unit}`;
    }

    return `${value}`;
  }

  invokeActiveIndicators(indicators) {
    const activeIndicators = indicators.filter(i => i.percent > 0);
    if (!activeIndicators.length) { return; }

    this.changeActiveIndicators(activeIndicators);
  }

  setFitbitGoal(goal, trackerValue, indicatorName) {
    const url = '/indicators/fitbit/goal';
    const data = {
      trackerValue,
      goal,
      indicatorName
    };
    return this.http.post<any>(url, data);
  }
}
