import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(
    private http: HttpClient) {
  }

  postFeedback(feedbackObj) {
    const url = `/feedback/`;
    return this.http
      .post<any>(url, feedbackObj)
      .pipe(tap(() => {
      }));
  }
}
