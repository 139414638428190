import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';
import { tap, first } from 'rxjs/operators';
import { VideoService } from '../services/video-service';
import { IContentDetail } from '../app/_models/content-detail';
import { ILikeChanged } from '../app/_models/like-changed';
import { IContentFeatureMenuItem } from '../app/_models/content-feature-menu-item';
import { CONTENT_TYPE_IDS, CONTENT_TYPES, CATEGORIES, FEATURES } from './../app/_shared/enums';
import { IBookmarkChanged } from '../app/_models/bookmark-changed';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  whichCurrentTime: any;

  public dataTypeSource = new BehaviorSubject<string>('default');
  currentDataType = this.dataTypeSource.asObservable();
  private currentTimeSource = new BehaviorSubject<string>('default');
  currentTime = this.currentTimeSource.asObservable();

  private viewMoreClicked = new BehaviorSubject<boolean>(false);
  isViewMore = this.viewMoreClicked.asObservable();

  private searchOffset = new BehaviorSubject<number>(0);
  currentSearchOffset = this.searchOffset.asObservable();

  private emitBookmarked = new Subject<IBookmarkChanged>();
  bookmarkEmitted = this.emitBookmarked.asObservable();

  private emitBookmarkedHome = new Subject<IBookmarkChanged>();
  bookmarkEmittedHome = this.emitBookmarkedHome.asObservable();

  private emitLikeChanged$ = new Subject<ILikeChanged>();
  likeChangeEmitted = this.emitLikeChanged$.asObservable();

  private get HealthMenuItems(): IContentFeatureMenuItem {
    const items: IContentFeatureMenuItem = {
      name: 'Well-Body',
      featureId: FEATURES.HEALTH,
      categories: [
        { name: 'Fitness', categoryId: CATEGORIES.FITNESS, featureId: FEATURES.HEALTH },
        { name: 'Nutrition', categoryId: CATEGORIES.FUEL, featureId: FEATURES.HEALTH },
        { name: 'Staying healthy', categoryId: CATEGORIES.STAYING_HEALTHY, featureId: FEATURES.HEALTH },
      ]
    };

    return items;
  }

  private get HappinessMenuItems(): IContentFeatureMenuItem {
    const items: IContentFeatureMenuItem = {
      name: 'Well-Mind',
      featureId: FEATURES.HAPPINESS,
      categories: [
        { name: 'Finding happy', categoryId: CATEGORIES.FINDING_HAPPY, featureId: FEATURES.HAPPINESS },
        { name: 'Building resilience', categoryId: CATEGORIES.BUILDING_RESILIENCE, featureId: FEATURES.HAPPINESS },
        { name: 'Relationships', categoryId: CATEGORIES.RELATIONSHIPS, featureId: FEATURES.HAPPINESS },
      ]
    };

    return items;
  }

  private get FinancialSecurityMenuItems(): IContentFeatureMenuItem {
    const items: IContentFeatureMenuItem = {
      name:  'Financial Security',
      featureId: FEATURES.FINANCIAL_SECURITY,
      categories:      [
        { name: 'Work Life', categoryId: CATEGORIES.WORK_LIFE, featureId: FEATURES.FINANCIAL_SECURITY },
        { name: 'Financial planning', categoryId: CATEGORIES.FINANCIAL_PLANNING, featureId: FEATURES.FINANCIAL_SECURITY },
        { name: 'Career focus', categoryId: CATEGORIES.CAREER_FOCUS, featureId: FEATURES.FINANCIAL_SECURITY },
      ]
    };

    return items;
  }

  private get RecipesMenuItems(): IContentFeatureMenuItem {
    const items: IContentFeatureMenuItem = {
      name: 'Recipes',
      featureId: FEATURES.RECIPES,
      categories: [
        { name: 'Soups, Salads & Sides', categoryId: CATEGORIES.SOUPS_SALADS_AND_SIDES, featureId: FEATURES.RECIPES },
        { name: 'Vegan & Vegetarian', categoryId: CATEGORIES.VEGAN_AND_VEGETARIAN, featureId: FEATURES.RECIPES },
        { name: 'Meat & Fish', categoryId: CATEGORIES.MEAT_AND_FISH, featureId: FEATURES.RECIPES },
        { name: 'Smoothies & Desserts', categoryId: CATEGORIES.SMOOTHIES_AND_DESSERTS, featureId: FEATURES.RECIPES },
      ]
    };

    return items;
  }

  private get ParentPlannerMenuItems(): IContentFeatureMenuItem {
    const items: IContentFeatureMenuItem = {
      name: 'Well-Home',
      featureId: FEATURES.PARENT_PLANNER,
      categories: [
      { name: 'Planning & Pregnancy', categoryId: CATEGORIES.PLANNING_AND_PREGNANCY, featureId: FEATURES.PARENT_PLANNER },
      { name: 'Babies & Kids', categoryId: CATEGORIES.BABIES_AND_KIDS, featureId: FEATURES.PARENT_PLANNER },
      { name: 'Back to Work', categoryId: CATEGORIES.BACK_TO_WORK, featureId: FEATURES.PARENT_PLANNER },
    ]
  };

    return items;
  }

  constructor(
    private http: HttpClient,
    private videoService: VideoService
  ) {
    this.currentTime.subscribe(value => {
      this.whichCurrentTime = value;
    });
  }

  private isInList(numbers: number[], nr: number): boolean {
    return numbers.indexOf(nr) >= 0;
  }

  getContentMenuItems(companyFeatureIds: number[]): IContentFeatureMenuItem[] {
    const items = [];
    if (this.isInList(companyFeatureIds, this.HealthMenuItems.featureId)) {
      items.push(this.HealthMenuItems);
    }

    if (this.isInList(companyFeatureIds, this.HappinessMenuItems.featureId)) {
      items.push(this.HappinessMenuItems);
    }

    if (this.isInList(companyFeatureIds, this.FinancialSecurityMenuItems.featureId)) {
      items.push(this.FinancialSecurityMenuItems);
    }

    if (this.isInList(companyFeatureIds, this.RecipesMenuItems.featureId)) {
      items.push(this.RecipesMenuItems);
    }

    if (this.isInList(companyFeatureIds, this.ParentPlannerMenuItems.featureId)) {
      items.push(this.ParentPlannerMenuItems);
    }

    return items;
  }

  changeDataType(dataType: string) {
    this.dataTypeSource.next(dataType);
  }

  changeCurrentTime(time) {
    this.currentTimeSource.next(time);
  }

  changeViewMore(boolean) {
    this.viewMoreClicked.next(boolean);
  }

  changeSearchOffset(offset) {
    this.searchOffset.next(offset);
  }

  getSearchOffset() {
    return this.currentSearchOffset;
  }

  emitBookmark(bookmark: IBookmarkChanged) {
    this.emitBookmarked.next(bookmark);
  }

  emitBookmarkHome(bookmark: IBookmarkChanged) {
    this.emitBookmarkedHome.next(bookmark);
  }

  emitLikeChanged(item: IContentDetail) {
    const likeChanged: ILikeChanged = {
      isLiked: item.isLiked,
      liked: item.liked,
      likes: item.likes,
      contentId: item.id
    };

    this.emitLikeChanged$.next(likeChanged);
  }

  private extractVideoYoutubeId(items) {
    items.forEach(item => {
      if (item.contentType === CONTENT_TYPE_IDS.VIDEO_ID) {
        this.videoService.extractYoutubeId(item);
      }
    })
  }

  private applyListAdditionalProperties(items: IContentDetail[]) {
    this.extractVideoYoutubeId(items);

    items.forEach(item => this.initialiseContentItemPerContentType(item));
  }

  getContent(isLoggedIn: boolean, categoryId: number, featureId: number, limit: number, ids: any, lang?: string) {
    let url = (isLoggedIn)
      ? `/content/${categoryId}/${featureId}/${limit}`
      : `/content/guests/${categoryId}/${featureId}/${limit}`;
    
    if (lang && lang !== 'en') {
      url = `${url}?lang=${lang}`;
    }

    if (ids === 0) {
      return this.http.get<any>(url)
        .pipe(tap(res => this.applyListAdditionalProperties(res)));
    } else {
      const body = { contentIds: ids };
      return this.http.post<any>(url, body)
        .pipe(tap(res => this.applyListAdditionalProperties(res)));
    }
  }

  searchContent(isLoggedin, value, categoryId, featureId, limit, offset, lang, lifeActionLimit?, lifeActionOffset?) {
    const url =  (isLoggedin)
      ? '/content/search'
      : '/content/guests/search';

    const body = {
      value,
      categoryId,
      featureId,
      offset,
      limit,
      lang,
      lifeActionLimit,
      lifeActionOffset,
    };

    return this.http.post<any>(url, body)
      .pipe(tap(res => this.applyListAdditionalProperties(res)));
  }

  getContentByIdForAnyUser(id, type, isLoggedIn) {
    if (isLoggedIn) {
      return this.getContentById(id, type);
    }

    return this.getGuestContentById(id, type);
  }

  getContentById(id, type) {
    return this.http
      .get<any>(`/content/${type}/${id}`).pipe(
      tap(item => {
        this.initialiseContentItem(item, type);
      }));
  }

  getGuestContentById(id, type) {
    const url = `/content/guests/${type}/${id}`;
    return this.http.get<any>(url).pipe(
      tap(item => {
        this.initialiseContentItem(item, type);
      }));
  }

  private initialiseContentItem(item, type) {
    if (item === 'not available') {
      return item;
    }
    item.contentId = item.id;
    item.type = type;
    item.isArticle = (type === CONTENT_TYPES.ARTICLE);
    item.isRecipe = (type === CONTENT_TYPES.RECIPE);
    item.isVideo = (type === CONTENT_TYPES.VIDEO);

    this.setContentType(item);
    this.setIsLiked(item);
    this.setIsBookmarked(item);
  }

  setContentType(item) {
    if (item.isArticle) {
      item.contentType = CONTENT_TYPE_IDS.ARTICLE_ID;
    }

    if (item.isRecipe) {
      item.contentType = CONTENT_TYPE_IDS.RECIPE_ID;
    }

    if (item.isVideo) {
      item.contentType = CONTENT_TYPE_IDS.VIDEO_ID;
      this.videoService.extractYoutubeId(item);
    }
  }

  setIsLiked(item: IContentDetail) {
    item.isLiked = +item.liked >= 1;
    item.isUnliked = +item.liked === 0;
  }

  private setIsBookmarked(item: IContentDetail) {
    item.isBookmarked = +item.bookmarkId > 0;
  }

  getContentItemUrlPath(item: IContentDetail): string {
    return `/${item.type}/${item.id}`;
  }

  initialiseContentItemPerContentType(item: IContentDetail) {
    switch (+item.contentType) {
      case CONTENT_TYPE_IDS.ARTICLE_ID:
        this.initialiseContentItem(item, CONTENT_TYPES.ARTICLE);
        break;

      case CONTENT_TYPE_IDS.RECIPE_ID:
        this.initialiseContentItem(item, CONTENT_TYPES.RECIPE);
        break;

      case CONTENT_TYPE_IDS.VIDEO_ID:
        this.initialiseContentItem(item, CONTENT_TYPES.VIDEO);
        break;
    }
  }

  likeItem(item: IContentDetail, userProfileId: number) {
    const url = `/likes`;
    const body = {
      contentId: item.id,
      contentType: item.type,
      userProfileId
    };

    return this.http.post<any>(url, body)
    .pipe(
      tap(data => {
        if (data[0] && data[0].contentId) {
          item.liked = 1;
          item.likes = +item.likes + 1;

          this.setIsLiked(item);
          this.emitLikeChanged(item);
        }
      }),
      first()
    )
    .subscribe();
  }

  unlikeItem(item: IContentDetail) {
    const url = `/likes/${item.type}/${item.id}`;

    return this.http.delete<any>(url)
      .pipe(
        tap(data => {
          if (data === 1) {
            item.liked = 0;
            item.likes = +item.likes - 1;

            this.setIsLiked(item);
            this.emitLikeChanged(item);
          }
        }),
        first()
      ).subscribe();
  }

  like(id, type, userProfileId) {
    const url = `/likes`;
    const body = {
      contentId: id,
      contentType: type,
      userProfileId
    };

    return this.http.post<any>(url, body)
    .pipe(
      tap(data => {
        if (data[0] && data[0].contentId) {        
        }
      }),
      first()
    )
    .subscribe();
  }

  unlike(id, type) {
    const url = `/likes/${type}/${id}`;

    return this.http.delete<any>(url)
      .pipe(
        tap(data => {
          if (data === 1) {
          }
        }),
        first()
      ).subscribe();
  }

  bookmarkItem(item: IContentDetail) {
    if (item.isBookmarked) { return; }

    const body = {
      contentId: item.id,
      type: item.contentType
    };
    const url = `/bookmarks`;

    return this.http.post<any>(url, body)
      .pipe(
        tap(data => {
          item.bookmarkId = data.id;
          this.setIsBookmarked(item);
        })
      );
  }

  unbookmarkItem(item: IContentDetail) {
    if (!item.isBookmarked) { return; }

    const url = `/bookmarks/${item.bookmarkId}`;

    return this.http.delete<any>(url)
      .pipe(
        tap(() => {
          item.bookmarkId = null;
          this.setIsBookmarked(item);
        })
      );
  }

  addContentComments(body) {
    const url = `/contentcomments/comment`;
    return this.http.post<any>(url, body);
  }

  getContentComments(contentId, contentType){
    const url = `/contentcomments/comments/${contentId}/${contentType}`;
    return this.http.get<any>(url);
  }

  updateComment(id, body) {
    const url = `/contentcomments/comment/${id}`;
    return this.http.put<any>(url, body);
  }

  deleteComment(id) {
      const url = `/contentcomments/comment/${id}`;
      return this.http.delete<any>(url);
  }
}
