import { Component, OnInit } from '@angular/core';
import { UnSubscribeComponent } from '../../_shared/un-subscribe.component';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../services/user-service';
import { InsightsService } from '../../../services/insights-service';

@Component({
  selector: 'app-sso-logout',
  templateUrl: './sso-logout.component.html',
  styleUrls: ['./sso-logout.component.scss'],
})
export class SsoLogoutComponent extends UnSubscribeComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private insightsService: InsightsService,
  ) {
    super();
  }

  ngOnInit() {
    this.insightsService.getCrossDomainUrl().subscribe(redirectUrl => {
      setTimeout(() => {
        window.localStorage.clear();
        window.location.href = redirectUrl;
      }, 1000);
    });
  }
}
