import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UserService } from '../../../../services/user-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-term',
  templateUrl: './search-term.component.html',
  styleUrls: ['./search-term.component.scss'],
})
export class SearchTermComponent implements OnInit, OnDestroy {
  @Output() searchTermEntered = new EventEmitter<any>();
  searchTermControl = new FormControl();
  @Input() reset: boolean = false;
  @Input() hideLanguageToggle: boolean = false;
  searchTermSubscription: Subscription;
  languagePreference: string

  constructor(private userService: UserService) {
    this.languagePreference = this.userService.getUserLanguagePreference();
  }

  ngOnInit() {
    this.initialiseSearchTerm();
  }

  ngOnChanges() {
    if (this.reset) {
      this.searchTermControl.setValue('');
    }
  }

  ngOnDestroy(): void {
    if (this.searchTermSubscription) {
      this.searchTermSubscription.unsubscribe();
    }
  }

  private initialiseSearchTerm() {
    this.searchTermSubscription = this.searchTermControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe(term => {
        this.searchTermEntered.emit(
          { term, lang: this.languagePreference }
         );
      });
  }

  canShowLanguageToggle() {
    return this.userService.canToggleArticleLanguage() && !this.hideLanguageToggle;
  }

  displayArticleBySelectedlanguage(e) {
    this.languagePreference = e;
    const searchTerm = this.searchTermControl.value;
    this.searchTermEntered.emit({
      term: searchTerm,
      lang: e,
    });
  }
}
