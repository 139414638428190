import { CommonModule } from '@angular/common';
import { BadgesComponent } from './badges/badges.component';
import { NgModule } from '@angular/core';
import { RewardRoutingModule } from './reward-routing.module';
import { MyRewardsComponent } from './my-rewards/my-rewards.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RewardComponent } from './reward.component';
import { SharedModule } from '../_shared/shared.module';

@NgModule({
  declarations: [
    RewardComponent,
    BadgesComponent,
    MyRewardsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RoundProgressModule,
    RewardRoutingModule,
    SharedModule
  ]
})
export class RewardModule {}
