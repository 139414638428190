import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IIdNamePair } from '../../_models/id-name-pair';

@Component({
  selector: 'app-select-companies',
  templateUrl: './select-companies.component.html',
  styleUrls: ['./select-companies.component.scss'],
})
export class SelectCompaniesComponent implements OnInit, OnChanges {
  @Input() public allCompanies: IIdNamePair[] = [];
  @Input() public selectedCompanyIds: number[] = [];
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public addAllOption: boolean = false;
  @Input() public allOptionLabel: string;
  @Output() public companyIdsSelected: EventEmitter<number[]> = new EventEmitter();

  unselectedCompanies: IIdNamePair[];
  allOptionSelected: boolean = false;

  constructor() {
  }

  ngOnInit() {
    this.allCompanies.sort(this.sortAscendingNames);
    this.populateUnselectedCompanies();
  }

  ngOnChanges() {
    this.allCompanies.sort(this.sortAscendingNames);
    this.populateUnselectedCompanies();
  }

  private sortAscendingNames(a: IIdNamePair, b: IIdNamePair) {
    const valueA = a.name.toUpperCase();
    const valueB = b.name.toUpperCase();

    if (valueA > valueB) {
      return 1;
    }
    if (valueA < valueB) {
      return -1;
    }

    return 0;
  }

  private populateUnselectedCompanies() {
    const filterCompanyIds = this.isSelectedCompanyIdsSet()
      ? this.selectedCompanyIds.slice()
      : [];

    this.unselectedCompanies = this.allCompanies
      .filter(c => filterCompanyIds.indexOf(c.id) < 0);
    if (this.addAllOption) {
      this.unselectedCompanies.unshift({ id: null, name: this.allOptionLabel });
    }
  }

  private isSelectedCompanyIdsSet() {
    return this.selectedCompanyIds
      && this.selectedCompanyIds.length;
  }

  companyName(companyId: number) {
    const company = this.allCompanies.find(c => c.id === +companyId);
    if (!company) {
      return `${companyId} - `;
    }

    return company.name;
  }

  onCompanySelected($event) {
    const companyId = +$event.target.value;
    if (!companyId) {
      if (this.addAllOption) {
        this.selectedCompanyIds = [];
        this.companyIdsSelected.emit(this.selectedCompanyIds);
        this.allOptionSelected = true;
        return;
      } else {
        return;
      }
    }

    if (this.selectedCompanyIds.indexOf(companyId) >= 0) {
      return;
    }

    this.allOptionSelected = false;
    this.selectedCompanyIds.push(companyId);
    this.companyIdsSelected.emit(this.selectedCompanyIds);
    this.populateUnselectedCompanies();
  }

  onSelectedCompanyClick(companyId: number) {
    this.selectedCompanyIds = this.selectedCompanyIds.filter(i => {
      return i !== companyId;
    });
    this.companyIdsSelected.emit(this.selectedCompanyIds);
    this.populateUnselectedCompanies();
  }
}
