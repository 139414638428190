<div class="collapse w-100" id="{{id}}">
  <hr />
  <table>
    <tr>
      <td *ngFor="let date of dates">
        {{formatDisplayDate(recurrenceValue, date.startDate)}}
      </td>
    </tr>
    <tr>
      <td *ngFor="let date of dates" class="align-middle">
        <i [class]="setDisplayValue(date.achieved)" aria-hidden="true"></i>
      </td>
    </tr>
  </table>
</div>
