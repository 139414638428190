<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p [innerHTML]="message"></p>
  <a *ngIf="url" (click)="openLink()">{{url}}</a>
  <br/>
  <br/>
  <div class="card">
    <div class="cardDiv">
      <div class="image">
        <img *ngIf="contentImage" [src]="getImage('articles_images/', contentImage)" (click)="goToContent()" />
      </div>
      <div>
        <p *ngIf="contentTitle">{{contentTitle}}</p>
      </div>
    </div>
  </div>

</div>
<div class="footerButtons p-3">
  <button type="button" (click)="onNoClick()" class="btn btn-outline-primary">Close</button>
</div>
