<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div *ngIf="alert.message === null">
    <!-- search block -->
    <div class="search-list mb-2">
      <i class="fa fa-search"></i>
      <input id="search" type="search" [(ngModel)]="search" placeholder="Search" class="form-control">
    </div>
    <!--list -->
    <div class="table-responsive shadow-lg">
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th class="idCol">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" id="selectedAll" class="custom-control-input" [(ngModel)]="selectedAll" (click)="selectAll()" class="custom-control-input">
                <label class="custom-control-label" for="selectedAll">&nbsp;</label>
              </div>
            </th>
            <th *ngFor="let col of columnList" class="text-uppercase">{{col}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of dataList | stringFilter: search : 1 | paginate: config" class="hand">
            <td>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" [id]="item[0]" class="custom-control-input" [(ngModel)]="item.checked" class="custom-control-input">
                <label class="custom-control-label" for="{{item[0]}}">&nbsp;</label>
              </div>
            </td>
            <td *ngFor="let col of item">{{col}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- pagination details: http://michaelbromley.github.io/ngx-pagination/#/-->
    <div class="cms-pagination">
      <pagination-controls autoHide="true" (pageChange)="config.currentPage = $event; setCheckAllButton()"></pagination-controls>
    </div>

  </div>

  <!-- alert messages-->
  <div class="alert w-100" [ngClass]="alert.type === 'alert'? 'alert-danger' : 'alert-info'" *ngIf="alert.message !== null">
    <strong>{{alert.message}}</strong>
  </div>

</div>
<!-- buttons -->
<div class="footerButtons p-3">
  <button type="button" (click)="close()" class="btn btn-outline-primary">
    <i class="fa fa-undo mr-2" aria-hidden="true"></i> CLOSE</button>
  <button type="button" (click)="save()" class="btn btn-primary">
    <i class="fa fa-check-square-o mr-2" aria-hidden="true"></i> SELECT</button>
</div>