import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChallengeService } from '../../services/challenge-service';
import { UtilService } from '../../services/util-service';
import { UserService } from '../../services/user-service';
import { DateService } from '../../services/date.service';
import { TeamService } from '../../services/team.service';
import { IUser } from '../_models/user';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-challenge-details',
  templateUrl: './challenge-details.component.html',
  styleUrls: ['./challenge-details.component.scss'],
})
export class ChallengeDetailsComponent implements OnInit {
  id: number;
  loading = true;
  buttonLoading = false;
  allowJoinTeam = true;
  challengeName: string;
  imagePath: string;
  description: SafeHtml;
  startDate: string;
  endDate: string;
  team: {
    teamImagePath: string;
    teamName: string;
    stepsCompleted: number;
    stepsGoal: number;
  };
  teams: [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private challengeService: ChallengeService,
    private utilService: UtilService,
    private userService: UserService,
    private dateService: DateService,
    private teamService: TeamService,
    private router: Router,
    public domSanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((data) => {
      this.id = this.utilService.getIdFromHyphenatedUrl(data.id);
      this.getChallenge(this.id);
    });
  }

  getChallenge(id) {
    this.challengeService.getAvailableChallengesById(id).subscribe((data) => {

      if (data) {
        this.challengeName = data.name;
        this.imagePath = data.image ? this.userService.envVariables.aws.awsBucket + 'challenges_images/' + data.image : null;
        this.description = this.domSanitizer.bypassSecurityTrustHtml(data.description);
        this.startDate = this.dateService.formatDD_MMM_YYYY_Allow_Null_With_Text(data.startDate);
        this.endDate = this.dateService.formatDD_MMM_YYYY_Allow_Null_With_Text(data.endDate);

        data.teams.forEach(element => {
          element.image = element.image ? this.userService.envVariables.aws.awsBucket + 'teams_images/' + element.image : null;
          element.stepsCompleted = element.stepsCompleted ? element.stepsCompleted.achievedSteps : 0;
          element.stepsGoal = element.stepsGoal ? element.stepsGoal.goals == undefined ? element.stepsGoal : element.stepsGoal.goals : 0;
          element.stepsCompletedInPercentage = (element.stepsCompleted / element.stepsGoal) * 100;
          if (this.allowJoinTeam && this.userService.isNormalUser()) {
            this.allowJoinTeam = !element.isMember;
          }
        });
        this.teams = data.teams;
      }
      this.loading = false;
      this.buttonLoading = false;
    });
  }

  joinTeam(teamId) {
    this.loading = false;
    this.buttonLoading = true;
    let userProfileId = 0;
    this.userService.getMe().subscribe((data: IUser) => {
      userProfileId = data.userProfile.id;
      let body = {
        teamId,
        userProfileId,
      };
      this.teamService.joinTeam(body)
        .subscribe(() => {
            this.teamService.notifyTeamsChanged();
            this.getChallenge(this.id);
          },
          (error) => {
            const errorMsg = `Error while adding item - ${error}`;
            this.utilService.showToastError(errorMsg);
            this.buttonLoading = false;
          });
    });
  }

  viewTeam(id) {
    this.router.navigate([`/act/team-details/${id}`]);
  }
}
