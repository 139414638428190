import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  OnInit,
  OnChanges,
  Renderer,
  AfterViewInit,
  AfterViewChecked,
  AfterContentInit,
  ViewChildren,
  ChangeDetectionStrategy} from '@angular/core';

import { Star } from './star';

@Component({
  selector: 'app-star-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StarRatingComponent
  implements OnInit,
  OnChanges,
  AfterViewInit,
  AfterContentInit,
  AfterViewChecked {
  @Input() ratingvalue: number;
  @Input() touched = false;

  @Output() ratingvalueChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() touchedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() OnRatingChanged = new EventEmitter();
  @Input() max: number;
  @Input() readonly: boolean;
  @Input() starsize: number;
  @Input() color: string;
  @Input() fillcolor: string;
  @Output() OnMouseOver = new EventEmitter();

  @ViewChildren('starfilled') starChildren;

  public stars: Star[];
  private ele: ElementRef;
  private render: Renderer;
  private changeElements: HTMLElement[];

  constructor(e1: ElementRef, render: Renderer) {
    this.ele = e1;
    this.render = render;
    this.fillcolor = 'gold';
  }

  public ngOnInit() { }

  public ngOnChanges() {
    if (this.readonly === undefined) {
      this.readonly = false;
    }
    if (typeof this.readonly === 'string') {
      this.readonly = String(this.readonly) === 'true';
    }
    this.updateStars();
  }

  public ngAfterViewInit() {
    this.starChildren.changes.subscribe(changes => {
      this.changeElements = changes._results.map(
        eleref => eleref.nativeElement
      );

      this.OnRatingChanged.next({
        ratingvalue: this.ratingvalue,
        max: this.max,
        Changedelements: this.changeElements
      });
    });
  }

  public OnMouseenter(evt) {
    this.OnMouseOver.next(evt);
  }

  public toggle(index) {
    this.touched = true;
    this.touchedChange.emit(this.touched);
    if (this.readonly === false) {
      if (this.ratingvalue === 1 && index === 4) {
        this.ratingvalue = 0;
        this.updateStars();
        this.ratingvalueChange.emit(this.ratingvalue);
      } else {
        this.ratingvalue = this.max - index;
        this.updateStars();
        this.ratingvalueChange.emit(this.ratingvalue);
      }
    }
  }

  public ngAfterViewChecked() { }

  public ngAfterContentInit() { }

  private updateStars() {
    this.stars = [];
    const j = this.max - this.ratingvalue;
    for (let i = 1; i <= this.max; i++) {
      this.stars.push({ filled: i > j });
    }
    this.render.setElementStyle(this.ele.nativeElement, 'color', this.color);
  }
}
