import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';
import { DateService } from '../services/date.service';

@Injectable({
  providedIn: 'root',
})
export class DateOfBirthValidator {
  constructor(private dateService: DateService) {
  }

  is16OrMore(): AsyncValidatorFn {
    return (ctrl: AbstractControl): Observable<{ [key: string]: any } | null> => {
      const dateValue = this.dateService.fromYearMonthDayObjectToYmdFormat(ctrl.value);
      const controlValue = moment().diff(dateValue, 'years', true);
      if (controlValue >= 16) {
        return of({});
      }
      return of({
        dateOfBirth16OrMoreError: true,
      });
    };
  }
}
