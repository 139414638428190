import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-info-alert',
  templateUrl: './info-alert.component.html',
  styleUrls: ['./info-alert.component.scss'],
})
export class InfoAlertComponent {
  @Input() message;
  @Input() type;
  @Input() icon;

  iconCss = 'text-danger';

  constructor(
    private activeModal: NgbActiveModal,
  ) {
    if (this.type === 2) {
      this.iconCss = 'text-info';
    }
  }

  close() {
    this.activeModal.dismiss();
  }
}
