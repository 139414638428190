<div *ngIf='isDataAvailable' class="card">
  <div class="img-size-div">
    <img class="card-img-top img-size" [src]="image" alt="Employee Assistance Program">
  </div>

  <div class="card-body">
    <h5 class="card-title">{{ item.title }}</h5>
    <button class='btn btn-dark' (click)='openEAPUrl()'>Book an Appointment</button>
    <div class='fr-view longText mt-4' [innerHTML]='content'></div>
  </div>
</div>
