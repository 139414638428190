
<div *ngIf="!isLoggedIn && !isRegistered && !(usedSSO$ | async)"
  class="container">
  <h2>Login</h2>
  <form [formGroup]="form" (ngSubmit)="onSubmitLoginForm()">

    <div class="form-group">
      <app-email-input [parentForm]="form" [inputcss]="setInputOutlineClass(email)"></app-email-input>
    </div>

    <!-- <div class="form-group">
      <app-msisdn-input [parentForm]="form" [inputcss]="setInputOutlineClass(msisdn)"></app-msisdn-input>
    </div> -->

    <div class="form-group">
      <app-password-input [parentForm]="form" [inputcss]="setInputOutlineClass(password)"
        [showConfirmPassword]="false"></app-password-input>
    </div>


    <button type="submit" class="btn btn-primary btn-block" [disabled]="isFormSubmitDisabled()">Login</button>

  </form>

<!--  <p></p>-->
<!--  <div *ngFor='let connection of companyConnections$ | async'>-->
<!--    <button-->
<!--      class="btn btn-block"-->
<!--      [attr.style]="'background-color: '+connection.buttonColor + ' !important' | safeStyle"-->
<!--      [disabled]="isLoading"-->
<!--      (click)="onConnectionAuthorize(connection)"-->
<!--    >-->
<!--      {{connection.buttonLabel}}-->
<!--    </button>-->
<!--    <p></p>-->
<!--  </div>-->

  <div class="auth-link-text">
    <p>Forgot your password? </p><a [routerLink]="['/auth/forget-password']">Reset it here.</a>
  </div>
  <br/>
  <div class="auth-link-text" *ngIf="!isInsightsDomain()">
    <p>Want to sign up? </p><a [routerLink]="['/auth/register']">Sign up here.</a>
  </div>
</div>
<div *ngIf="isLoading" class="row justify-content-center mb-2">
  <i class="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
</div>

<div class='text-center mb-5' *ngIf='isRegistered'>
  <img class='logo' src='assets/logo/altiuslife_logo.png' alt=''>
  <div class='info-box'>
    <p>Thank you for registering your AltiusLife account.</p>
    <p>Please check your email for a verification link.</p>
  </div>
</div>
