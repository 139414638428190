<section class='navigation-preview d-flex justify-content-center flex-row flex-nowrap'>
  <ul [style.color]='topTextBarColor' class='d-flex flex-row align-items-end text-uppercase'>
    <li>
      <span aria-expanded='false'
         aria-haspopup='true' class='dropdown-toggle'
         data-toggle='dropdown' href='#' role='button'>
        Discover
      </span>
    </li>
    <li>
      <span aria-expanded='false'
         aria-haspopup='true' class='dropdown-toggle'
         data-toggle='dropdown' href='#'
         role='button'>
        My Health
      </span>
    </li>
    <li><span aria-expanded='false'
           aria-haspopup='true' class='dropdown-toggle'
           data-toggle='dropdown' href='#'
           role='button'>
      Community
    </span></li>
  </ul>
</section>
