<div class="widget-card">
  <div class="widget-card-header backPrimary">
    Groups
  </div>
  <div class="w-100 text-center p-2">
    <div>
      <img class="w-20 float-left rounded-circle" [src]="avatarSrc" />
      <div class="w-75 float-right text-left body-text">
        {{ bodyText }}
      </div>
    </div>

    <a
      class="btn btn-link w-100 widget-card-line fs-80-rem"
      [routerLink]="['/share/groups']"
    >
      <i class="fa fa-eye" aria-hidden="true"></i>
      View groups
    </a>
  </div>
</div>
