import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import { UserService } from './user-service';
import { CategoryService } from './category-service';
import { FeatureService } from './feature-service';
import filestack from 'filestack-js';
import { AWS_PATHS } from '../app/_shared/enums';

const defaultMaxImageSize = 1024 * 1024 * 4;// 4MB
const companyMaxImageSize = 1024 * 1024;// 1MB

@Injectable({
  providedIn: 'root',
})
export class FilestackService {
  client: any;
  awsS3Service: any;

  constructor(
    private userService: UserService,
    private categoryService: CategoryService,
    private featureService: FeatureService,
  ) {
    this.client = filestack.init(this.userService.getFilestackApiKey());
    const awsConfig = this.userService.envVariables.aws;
    AWS.config.update({
      accessKeyId: awsConfig.AWSAccessKeyId,
      secretAccessKey: awsConfig.AWSSecretAccessKey,
      region: awsConfig.AWSRegion,
    });

    this.awsS3Service = new AWS.S3();
  }

  async pickSpecifiedPathImage(imagePath: string, maxSize = defaultMaxImageSize) {
    const data = await this.pickImage(imagePath, maxSize);

    return this.uploadedFileKey(data, imagePath);
  }

  async pickImage(path: string, maxSize = defaultMaxImageSize) {
    path = this.applyLeadingSlash(path);

    const pickOptions = {
      accept: 'image/*',
      maxFiles: 1,
      maxSize: maxSize,
      fromSources: ['local_file_system', 'imagesearch'],
      storeTo: {
        location: 's3',
        path: path,
        access: 'public',
      },
      transformations: {
        crop: { circle: false },
      },
    };

    const data = await this.client.pick(pickOptions);
    this.compressImage(data, path);

    return data;
  }

  private applyLeadingSlash(text: string) {
    if (text[0] !== '/') {
      text = '/' + text;
    }
    if (text[text.length - 1] !== '/') {
      text = text + '/';
    }

    return text;
  }

  private removeLeadingSlash(text: string) {
    return text.slice(1, text.length - 1);
  }

  private uploadedFileKey(data, imagePath) {
    if (imagePath.slice(0, 1) === '/') {
      imagePath = imagePath.slice(1);
    }
    return data.filesUploaded[0].key.replace(imagePath, '');
  }

  async pickCompanyImage(maxSize = companyMaxImageSize) {
    const imagePath = this.userService.companyImagesPathSegment;
    return await this.pickSpecifiedPathImage(imagePath, maxSize);
  }

  async pickCategoryImage(maxSize = defaultMaxImageSize) {
    const imagePath = this.categoryService.categoryImagesPathSegment;
    return await this.pickSpecifiedPathImage(imagePath, maxSize);
  }

  async pickFeatureImage(maxSize = defaultMaxImageSize) {
    const imagePath = this.featureService.featureImagesPathSegment;
    return await this.pickSpecifiedPathImage(imagePath, maxSize);
  }

  async pickPostImage() {
    const data = await this.pickImage('/groups/posts/');
    return data.filesUploaded[0].key.replace('groups/posts/', '');
  }

  async pickTeamPostImage() {
    const data = await this.pickImage('/teams_posts_images/');
    return data.filesUploaded[0].key.replace('teams_posts_images/', '');
  }

  async pickEAPImage(maxSize = 1024 * 1024 * 5) {
    const imagePath = 'eap_images/';
    return await this.pickSpecifiedPathImage(imagePath, maxSize);
  }

  async pickInfoPackFile() {
    const path = this.applyLeadingSlash('infopacks/files/');

    const pickOptions = {
      accept: '.pdf',
      maxFiles: 1,
      maxSize: defaultMaxImageSize,
      fromSources: ['local_file_system'],
      storeTo: {
        location: 's3',
        path: path,
        access: 'public',
      },
    };

    const data = await this.client.pick(pickOptions);
    return data.filesUploaded[0].key.replace('infopacks/files/', '');
  }

  async compressImage(data, path) {
    try {
      const imageUploadedData = data.filesUploaded[0];
      if (!imageUploadedData || !path) {
        return imageUploadedData;
      }

      const fileName = imageUploadedData.key.replace(path, '');

      const imageUploadedDataSize = imageUploadedData.size;
      const compressImageUrl = await this.client.transform(imageUploadedData.url, { output: { compress: true, quality: 90 } });
      const compressImage = await this.client.storeURL(compressImageUrl);
      const compressImageSize = compressImage.size;
      if (!compressImageSize || !imageUploadedDataSize || compressImageSize >= imageUploadedDataSize) {
        return imageUploadedData;
      }
      const imageBlob = await this.client.retrieve(compressImage.handle);

      // const reUpload = await this.client.upload(imageBlob, {}, {
      //   location: 's3',
      //   path,
      //   access: 'public',
      //   filename: `compress_${fileName.replace(path, '')}`,
      // });
      // return {...imageUploadedData, ...reUpload };
      this.uploadImageToS3(imageBlob, fileName.replace(path, ''), path, imageUploadedData.mimetype);
      return imageUploadedData;
    } catch (ex) {
      console.log('Compress image', ex);
    }
  }

  async uploadImageToS3(imgBase64, fileName, bucketName, fileType) {
    bucketName = this.removeLeadingSlash(bucketName);
    const params = {
      Bucket: this.userService.envVariables.aws.awsImgBucket + '/' + bucketName, // pass your bucket name
      Key: fileName.replace(bucketName + '/', ''),
      Body: imgBase64,
      ACL: 'public-read',
      ContentEncoding: 'base64', // required
      ContentType: fileType,
    };

    this.awsS3Service.upload(params, function(s3Err, data) {
      if (s3Err) {
        console.log(`error while upload file to s3`, s3Err);
        // throw s3Err;
      }
    });
  }


  async pickProfileImage() {
    const data = await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      maxSize: defaultMaxImageSize,
      fromSources: ['local_file_system', 'imagesearch'],
      storeTo: {
        location: 's3',
        path: AWS_PATHS.PROFILE_IMAGES,
        access: 'public',
      },
      transformations: {
        crop: {
          aspectRatio: 1,
          circle: false,
          force: true,
        },
      },
      uploadInBackground: false,
    });
    this.compressImage(data, AWS_PATHS.PROFILE_IMAGES);

    return data.filesUploaded[0].key.replace('profile_images/', '');
  }

  async pickGroupLogo() {
    const data = await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      maxSize: defaultMaxImageSize,
      fromSources: ['local_file_system', 'imagesearch'],
      storeTo: {
        location: 's3',
        path: '/groups/logo/',
        access: 'public',
      },
      transformations: {
        crop: { aspectRatio: 1, circle: true, force: true },
      },
    });

    return data.filesUploaded[0].key.replace('groups/logo/', '');
  }

  async pickGroupBanner() {
    const path = this.applyLeadingSlash('/groups/banner/');
    const data = await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      maxSize: defaultMaxImageSize,
      fromSources: ['local_file_system', 'imagesearch'],
      storeTo: {
        location: 's3',
        path: path,
        access: 'public',
      },
      transformations: {
        crop: { circle: false, force: true },
      },
      uploadInBackground: false,
    });

    // const data = await this.pickImage('/groups/banner/');
    return data.filesUploaded[0].key.replace('groups/banner/', '');
  }

  async pickMobileCarouselImg() {
    const data = await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      // maxSize: 1024 * 1024 * 2,
      maxSize: defaultMaxImageSize,
      fromSources: 'local_file_system',
      storeTo: {
        location: 's3',
        path: '/carousel/',
        access: 'public',
      },
      transformations: {
        // maxDimensions: [900, 600],
        crop: {
          aspectRatio: 3 / 2,
          // force: true
        },
      },
      uploadInBackground: false,
    });

    return data.filesUploaded[0].key.replace('carousel/', '');
  }

  async pickDesktopCarouselImg() {
    const data = await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      // maxSize: 1024 * 1024 * 2,
      maxSize: defaultMaxImageSize,
      fromSources: 'local_file_system',
      storeTo: {
        location: 's3',
        path: '/carousel/',
        access: 'public',
      },
      transformations: {
        // maxDimensions: [1600, 400],
        crop: {
          aspectRatio: 4,
          // force: true
        },
      },
      uploadInBackground: false,
    });

    return data.filesUploaded[0].key.replace('carousel/', '');
  }


  async pickArticleImg() {
    const data = await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      maxSize: defaultMaxImageSize,
      fromSources: 'local_file_system',
      storeTo: {
        location: 's3',
        path: '/articles_images/',
        access: 'public',
      },
      transformations: {
        maxDimensions: [1000, 666.6],
        crop: { aspectRatio: 1, circle: false },
      },
    });

    return data.filesUploaded[0].key.replace('articles_images/', '');
  }

  async pickAuthorImage() {
    const data = await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      maxSize: defaultMaxImageSize,
      fromSources: ['local_file_system', 'imagesearch'],
      storeTo: {
        location: 's3',
        path: '/authors/',
        access: 'public',
      },
      transformations: {
        crop: {
          aspectRatio: 1,
          circle: false,
          force: true,
        },
      },
      uploadInBackground: false,
    });
    return data.filesUploaded[0].key.replace('authors/', '');
  }


  async pickChallengeImg() {
    const data = await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      // maxSize: 1024 * 1024 * 2,
      maxSize: defaultMaxImageSize,
      fromSources: 'local_file_system',
      storeTo: {
        location: 's3',
        path: '/challenges_images/',
        access: 'public',
      },
      transformations: {
        crop: {
          aspectRatio: 4,
          circle: false,
          force: true,
        },
      },
      uploadInBackground: false,
    });
    return data.filesUploaded[0].key.replace('challenges_images/', '');
  }

  async pickPartnerlImg() {
    const data = await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      // maxSize: 1024 * 1024 * 2,
      maxSize: defaultMaxImageSize,
      fromSources: 'local_file_system',
      storeTo: {
        location: 's3',
        path: '/partner_images/',
        access: 'public',
      },
      transformations: {
        maxDimensions: [900, 600],
        crop: {
          aspectRatio: 3 / 2,
          force: true,
        },
      },
      uploadInBackground: false,
    });

    return data.filesUploaded[0].key.replace('partner_images/', '');
  }


  async pickTeamImg() {
    const data = await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      maxSize: defaultMaxImageSize,
      fromSources: 'local_file_system',
      storeTo: {
        location: 's3',
        path: '/teams_images/',
        access: 'public',
      },
      transformations: {
        maxDimensions: [1000, 666.6],
        crop: { aspectRatio: 1, circle: false, force: true },
      },
      uploadInBackground: false,
    });

    return data.filesUploaded[0].key.replace('teams_images/', '');

  }
}
