import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserService } from '../../../../services/user-service';
import { ContentService } from '../../../../services/content-service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  content = [];
  search = new FormControl();
  totalData = [];
  index = 0;
  currentSearchTerm = '';
  _preferenceLanguage: string;
  // offset from observable in content service
  offset;
  @Input() loading;
  @Input() data = [];
  @Input() category = 0;
  @Input() feature = 0;
  @Input() limit = 6;
  @Input() lifeActionLimit = 0;
  @Input() set preferenceLanguage(value: string) {
    const oldVal = this._preferenceLanguage;
    this._preferenceLanguage = value;
    if (value === oldVal || (!oldVal && this.data.length > 0)) {
      return;
    }
    this.filterContent(true);
  }


  @Output() newContent: EventEmitter<any> = new EventEmitter<any>();
  @Output() noMoreResults: EventEmitter<any> = new EventEmitter<any>();
  @Output() isSearch: EventEmitter<any> = new EventEmitter<any>();

  searchValueSubscription: Subscription;

  constructor(
    private contentService: ContentService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.contentService.isViewMore.subscribe(viewMore => {
      if (viewMore === true) {
        const event = {
          keyCode: 13
        };
        this.handleKeyPress(event, 'viewMore');
      }
    });
    this.initialiseSearchTerm();
  }

  ngOnDestroy(): void {
    if (this.searchValueSubscription) {
      this.searchValueSubscription.unsubscribe();
    }
  }

  private initialiseSearchTerm() {
    this.searchValueSubscription = this.search.valueChanges
      .subscribe(term => {
        if (!term) {
          this.resetSearchResult();
        }
      });
  }

  isLifeAction(content: any) {
    return content.type === 'lifeAction';
  }

  filterContent(clearContent?: boolean) {
    this.contentService.getSearchOffset().subscribe(offset => {
      this.offset = offset;
    });
    if (this.search.value || this._preferenceLanguage) {
      let lifeActionOffset = 0;
      if (this.lifeActionLimit > 0 && this.content.length > 0) {
        lifeActionOffset = this.content.filter(this.isLifeAction).length;
      }
      this.contentService
        .searchContent(
          this.userService.isLoggedIn(),
          this.search.value,
          this.category,
          this.feature,
          this.limit,
          this.offset,
          this._preferenceLanguage !== 'en' && this._preferenceLanguage ? this._preferenceLanguage : undefined,
          this.lifeActionLimit,
          lifeActionOffset,
        )
        .subscribe(data => {
          if (this.content.length === 0 || !!clearContent) {
            this.content = data;
          } else {
            const contentIds = this.content.map(c => c.id);
            const newData = data.filter(d => !_.includes(contentIds, d.id));
            // filter to prevent display duplicated result
            this.content = this.content.concat(...newData);
          }
          this.newContent.emit(this.content);
          if (!data.length) {
            this.noMoreResults.emit(true);
          }
        });
    }
  }

  resetSearchResult() {
    // CLEAR
    // if the keydown is a backspace and input is empty (still registers 1)
    this.isSearch.emit(false);

    // clear index, offset, currentSearchTerm and noMoreResults
    this.index = 0;
    this.contentService.changeSearchOffset(0);
    this.currentSearchTerm = '';
    this.noMoreResults.emit(false);

    // set viewMore observable to false so that it doesn't trigger search anymore
    this.contentService.changeViewMore(false);

    // set the content back to original content before search, which us set above, as 'totalData');
    this.content = this.totalData.slice(0, this.limit);
    this.newContent.emit(this.content);

    // then clear the search component data
    this.content = [];
  }

  handleKeyPress(e, viewMore?) {
    if (this.index === 0 && !this.totalData.length) {
      this.index++;
      // populating local variable 'totalData' with the content-list results BEFORE search
      // (contained in [data] the first time search is touched)

      // WAIT FOR CONTENT LIST / HOME content to be loaded first
      setTimeout(() => {
        this.totalData = this.data;
      }, 100);
    } else {
      this.index++;
    }
    const key = e.keyCode || e.which;
    if (viewMore) {
      // VIEW MORE
      // because it's view more, it retains the offset and will concatenate results
      this.isSearch.emit(true);
      this.filterContent();
    } else if (key === 8 && this.search.value.length === 1) {
      this.resetSearchResult();
    } else if (key === 13 && this.search.value !== this.currentSearchTerm) {
      // NEW SEARCH
      // if the search value doesn't match the currentSearchTerm, clear offset and do a new search
      this.content = [];
      this.contentService.changeSearchOffset(0);
      this.isSearch.emit(true);
      this.noMoreResults.emit(false);
      this.filterContent();
    }
  }
}
