<section *ngIf='company' class='marketing-brochure-section d-flex align-items-center flex-column'>
  <div *ngIf='!previewMode' class='d-flex justify-content-end my-2 marketing-brochure-section__actions '>
    <button (click)='onPreviewPDF()' [disabled]='isProcessing' class='btn btn-primary mx-2'
            data-placement='top' data-toggle='tooltip' title='Preview' type='button'>
      <i aria-hidden='true' class='fa fa-eye'></i>
    </button>

    <button (click)='onGeneratePDF()' [disabled]='isProcessing' class='btn btn-primary' title='Download' type='button'>
      <i aria-hidden='true' class='fa fa-download'></i>
    </button>
  </div>
  <div #marketingBrochure class='marketing-brochure-section__content'>
    <div class='marketing-brochure-section__content__banner d-flex'>
      <img alt='banner' src='assets/img/marketing-brochure/banner.png'>
    </div>
    <div class='marketing-brochure-section__content__main-logo d-flex justify-content-center'></div>
    <div #customerLogo class='marketing-brochure-section__content__customer-logo d-flex justify-content-center'></div>
    <div class='marketing-brochure-section__content__banner-text d-flex'>
      Improve Your Health With Our New Wellbeing Program
    </div>
    <div class='marketing-brochure-section__content__right-side'>
      <div class='title'>Get Started with AltiusLife</div>
      <div class='content d-flex flex-row'>
        <div class='content__icon'>
          <img alt='address' src='assets/img/marketing-brochure/address-url-icon.png' />
        </div>
        <div class='content__detail d-flex flex-column'>
          <div>Access AltiusLife online at</div>
          <a [href]='company.domain' target='_blank'>{{company.domain}}</a>
        </div>
      </div>
      <div class='content d-flex flex-row'>
        <div class='content__icon'>
          <img alt='address' src='assets/img/marketing-brochure/download-app-icon.png' />
        </div>
        <div class='content__detail d-flex flex-column'>
          <div>Download the AltiusLife mobile app</div>
          <div class='flex flex-row'>
            <a [href]='appleHref' target='_blank'>
              <img alt='apple link' src='assets/img/marketing-brochure/apple-store.png' />
            </a>
            <a [href]='androidHref' class='ml-2' target='_blank'>
              <img alt='android link' src='assets/img/marketing-brochure/google-play.png' />
            </a>
          </div>
        </div>
      </div>
      <div class='content d-flex flex-row'>
        <div class='content__icon'>
          <img alt='address' src='assets/img/marketing-brochure/setup-profile-icon.png' />
        </div>
        <div class='content__detail d-flex flex-column'>
          <div>Set up your profile</div>
          <ol>
            <li>Create your account with your organisation’s registration code: <span class='content__detail__code font-weight-bold'>{{company.registerCode}}</span></li>
            <li>Set up your profile</li>
            <li>Discover the content and wellbeing features to start looking after you!</li>
          </ol>
        </div>
      </div>
    </div>
    <div class='marketing-brochure-section__content__main'>
      <div class='title'>You are important</div>
      <div class='desc'>Look after yourself by putting you first</div>
      <div class='detail'>Get access to AltiusLife at <a [href]='company.domain'
                                                         target='_blank'>{{company.hostname}}</a></div>
      <div class='detail'>or download the mobile app today!</div>
      <div class='features'>
        <div class='title'>Features</div>
        <div class='divide'></div>
        <div class='features__list'>
          <div class='features__detail'>
            <img alt='articles' src='assets/img/marketing-brochure/article-icon.png'>
            <div>1000+ Evidence based articles to support your physical, social & mental wellbeing</div>
          </div>
          <div class='features__detail'>
            <img alt='wellbeing' src='assets/img/marketing-brochure/wellbeing-icon.png'>
            <div>AltiusLife empowers self care to prioritise your wellbeing</div>
          </div>
          <div class='features__detail'>
            <img alt='Track Your Health' src='assets/img/marketing-brochure/tyh-icon.png'>
            <div>Measure & track your health with assessments</div>
          </div>
          <div class='features__detail'>
            <img alt='Health' src='assets/img/marketing-brochure/health-icon.png'>
            <div>Access to 1000+ Healthy Recipes</div>
          </div>
          <div *ngIf='hasEAPBookingFeature' class='features__detail'>
            <img alt='EAP' src='assets/img/marketing-brochure/eap-icon.png'>
            <div>Easy access to book in your EAP sessions with Altius Group PeopleSense</div>
          </div>
        </div>
      </div>
    </div>
    <div class='marketing-brochure-section__content__footer d-flex'>
      <img alt='footer' src='assets/img/marketing-brochure/footer.png'>
    </div>
  </div>
</section>
