import { FeatureService } from '../../services/feature-service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TMHService } from '../../services/track-my-health-service';
import { GroupService } from '../../services/group-service';
import { BadgeService } from '../../services/badge-service';
import { UserService } from '../../services/user-service';

@Component({
  selector: 'app-act',
  templateUrl: './act.component.html',
  styleUrls: ['./act.component.scss'],
})
export class ActComponent implements OnInit {
  titles: any;
  routes: any;

  defaultColumns = [];
  subFilterCards = [];
  features = [];
  notTMH: boolean;

  private fiitrMeFeatures = [
    this.feature.LifeActionsFeatureId,
    this.feature.TrackMyHealthFeatureId,
    this.feature.DevicesFeatureId,
    this.feature.ProgramsFeatureId,
    this.feature.ChallengesFeatureId,
    this.feature.TeamsFeatureId,
  ];
  private nonFiitrMeFeatures = [
    this.feature.LifeActionsFeatureId,
    this.feature.TrackMyHealthFeatureId,
    this.feature.DevicesFeatureId,
    this.feature.ProgramsFeatureId,
    this.feature.ChallengesFeatureId,
    this.feature.TeamsFeatureId,
  ];

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private tmhService: TMHService,
    private badgeService: BadgeService,
    private groupService: GroupService,
    private userService: UserService,
    private feature: FeatureService,
  ) {
  }

  ngOnInit() {
    if (!this.userService.isFiitr) {

      this.defaultColumns = [1, 1, 1, 1, 1];
      this.features = this.nonFiitrMeFeatures;
      this.subFilterCards = [
        [{
          title: 'Life Actions',
          img: '../../assets/img/life.actions.wide.png',
          route: '/act/lifeactions',
        }],
        [{
          title: 'Track My Health',
          img: '../../assets/img/track_health.wide.png',
          route: '/act/your-health',
        }],
        [{
          title: 'Devices',
          img: '../../assets/img/devices-625x200.jpg',
          route: '/act/devices',
        }],
        [{
          title: 'Programs',
          img: '../../assets/img/toolkit.programs.png',
          route: '/act/programs',
        }],
        [{
          title: 'Challenges',
          img: '../../assets/img/devices-625x200.jpg',
          route: '/act/challenges',
        }],
        [{
          title: 'Teams',
          img: '../../assets/img/life.actions.wide.png',
          route: '/act/teams',
        }],
      ];
    } else {
      this.defaultColumns = [1, 1, 1, 1, 1, 1];
      this.features = this.fiitrMeFeatures;
      this.subFilterCards = [
        [{
          title: 'Life Actions',
          img: '../../assets/fiitr-assets/life_actions.jpg',
          route: '/act/lifeactions',
        }],
        [{
          title: 'Track My Health',
          img: '../../assets/fiitr-assets/track_your_health.jpg',
          route: '/act/your-health',
        }],
        [{
          title: 'Devices',
          img: '../../assets/fiitr-assets/devices.jpg',
          route: '/act/devices',
        }],
        [{
          title: 'Programs',
          img: '../../assets/img/toolkit.programs.png',
          route: '/act/programs',
        }],
        [{
          title: 'Challenges',
          img: '../../assets/img/devices-625x200.jpg',
          route: '/act/challenges',
        }],
        [{
          title: 'Teams',
          img: '../../assets/img/life.actions.wide.png',
          route: '/act/teams',
        }],
      ];
    }
    this.features.push(this.feature.HealthRiskAssessmentsFeatureId);
    this.defaultColumns.push(1);
    this.subFilterCards.push([{
      title: 'Health Risk Assessments',
      img: '../../assets/img/health_risk_assessment_625x200.jpg',
      route: '/act/healthriskassessments',
    }]);
    this.groupService.refreshLatestGroupActivity();
    this.badgeService.refreshShortLeaderboard(
      this.userService.company.id,
      this.userService.user.userProfile.id,
    );
    this.tmhService.currentNotTMH$.subscribe(value => {
      this.notTMH = value;
    });
  }
}
