import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '../../../services/util-service';
import { CmsListViewService } from '../cms-list-view/cms-list-view.service';
import { EAPService } from '../../../services/eap-service';
import * as _ from 'lodash';

const SORT_NONE = 0;
const SORT_ASC = 1;
const SORT_DESC = 2;

const ID_COL = 0;
const SEARCH_COL = 1;

@Component({
  selector: 'eap-bulk-update',
  templateUrl: './eap-bulk-update.component.html',
  styleUrls: ['./eap-bulk-update.component.scss'],
})
export class EapBulkUpdateComponent implements OnInit {
  columnList: string[] = ['company', 'eap url'];
  dataList: any[];
  hideSearch: boolean = false;
  selectedCompanyId: number;
  noItemSelected = true;
  msg = '';
  selectedIdsCsv = '';
  search = '';
  config = {
    itemsPerPage: 10,
    currentPage: 1,
  };
  isSelectAll = true;
  sortColumnIndex = -1;
  sortColumnType = SORT_NONE;
  filteredList: any[] = [];
  defaultUrl: string;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private utilService: UtilService,
    public cmsListViewService: CmsListViewService,
    private eapService: EAPService,
  ) {
  }

  ngOnInit() {
    this.eapService.getAllData().subscribe(data => {
      const defaultEAP = data.find(i => i.id === 0);
      this.defaultUrl = this.getUrl(defaultEAP.data);
      this.dataList = data
        .filter(i => i.id !== 0)
        .map(i => {
          return [
            i.id,
            i.name,
            this.getUrl(i.data),
          ];
        });
      this.applyFilteredList();
    });
  }

  applyFilteredList(): void {
    this.clearSearch();
    // clear old data
    this.filteredList = [];

    if (!this.dataList || !this.dataList.length) {
      return;
    }

    this.dataList.forEach((d) => (d.checked = false));
    this.applyUserSetValues();
  }

  private clearSearch() {
    if (!this.search) {
      return;
    }

    this.search = '';
    this.onSearchKeyup();
  }

  private applyUserSetValues() {
    this.search = this.cmsListViewService.getSearch();
    this.applySearchFilter();

    this.config.currentPage = this.cmsListViewService.getCurrentPage();

    this.sortColumnIndex = this.cmsListViewService.getSortColumnIndex();
    this.sortColumnType = this.cmsListViewService.getSortColumnType();
    if (this.isColumnSort()) {
      this.applyColumnSort(this.sortColumnIndex);
    }
  }

  private isColumnSort() {
    return this.sortColumnIndex >= 0 && this.sortColumnType !== SORT_NONE;
  }

  private sortByColumn(index: number) {
    this.setColumnSort(index);
    this.setServiceSortValues();
    this.applyColumnSort(index);
  }

  private setColumnSort(index: number) {
    const currentSortColumnIndex = this.sortColumnIndex;
    const currentSortColumnType = this.sortColumnType;
    this.sortColumnIndex = index;

    if (currentSortColumnIndex !== this.sortColumnIndex) {
      this.sortColumnType = SORT_ASC;
    } else {
      this.sortColumnType = currentSortColumnType === SORT_ASC ? SORT_DESC : SORT_ASC;
    }
  }

  private applySearchFilter() {
    if (!this.search) {
      this.filteredList = _.cloneDeep(this.dataList);
      return;
    }

    // const idColResults = this.dataList.filter(
    //   (r) => String(r[ID_COL]).toLowerCase().indexOf(this.search.toLowerCase()) >= 0,
    // );
    const filteredResults = this.dataList.filter(
      (r) => String(r[SEARCH_COL]).toLowerCase().indexOf(this.search.toLowerCase()) >= 0,
    );

    this.filteredList = _.cloneDeep(filteredResults);
  }

  private setServiceSortValues() {
    this.cmsListViewService.setSortColumnIndex(this.sortColumnIndex);
    this.cmsListViewService.setSortColumnType(this.sortColumnType);
  }

  private applyColumnSort(index: number) {
    const sortMultiplier = this.sortColumnType === SORT_ASC ? 1 : -1;
    if (index === ID_COL) {
      this.filteredList.sort((a, b) => {
        if (+a[index] === +b[index]) {
          return 0;
        } else {
          return (+a[index] < +b[index] ? -1 : 1) * sortMultiplier;
        }
      });
    } else {
      this.filteredList.sort((a, b) => {
        if (a[index] === b[index]) {
          return 0;
        } else {
          return (a[index] < b[index] ? -1 : 1) * sortMultiplier;
        }
      });
    }
  }

  isAscendingSortColumn(index: number): boolean {
    return this.sortColumnIndex === index && this.sortColumnType === SORT_ASC;
  }

  isDescendingSortColumn(index: number): boolean {
    return this.sortColumnIndex === index && this.sortColumnType === SORT_DESC;
  }

  searchPlaceholder(): string {
    return `Search Company Name`;
  }

  onSearchKeyup() {
    this.applySearchFilter();
    if (this.isColumnSort()) {
      this.applyColumnSort(this.sortColumnIndex);
    }
    this.cmsListViewService.setSearch(this.search);
  }

  onColClick(index: number) {
    this.sortByColumn(index);
  }

  // delete() {
  //   const idsArray = this.selectedIdsCsv.split(',');
  //   const speltNumber = this.utilService.spellNumber(idsArray.length).toUpperCase();
  //   const deleteModal = this.modalService.open(BootstrapModalComponent);
  //   const title = `Delete ${speltNumber} item(s)`;
  //   const message = `Confirm deleting ${speltNumber} item(s)`;
  //
  //   deleteModal.componentInstance.title = title;
  //   deleteModal.componentInstance.message = message;
  //   deleteModal.componentInstance.confirmButtonLabel = 'Delete';
  //
  //   deleteModal.result.then(res => {
  //     if (!res) {
  //       return;
  //     }
  //     idsArray.forEach(id => this.deleteItemForId(+id));
  //   });
  // }

  // private removeIdFromLists(id: number) {
  //   this.filteredList = this.filteredList.filter(item => +item[0] !== id);
  //   this.dataList = this.dataList.filter(item => +item[0] !== id);
  //
  //   let selectedIds = this.selectedIdsCsv.split(',');
  //   selectedIds = selectedIds.filter(i => +i !== id);
  //   this.selectedIdsCsv = selectedIds.join(',');
  // }

  unselectAll() {
    this.isSelectAll = true;
    this.selectedIdsCsv = '';
    this.dataList.forEach(i => i.checked = false);
    this.filteredList.forEach(i => i.checked = false);
  }

  selectAll() {
    this.isSelectAll = false;
    this.dataList.forEach(i => i.checked = true);
    this.selectedIdsCsv = '';
    this.filteredList.forEach(i => {
      this.selectedIdsCsv += i[0] + ',';
      i.checked = true;
    });
  }

  selectItem(item) {
    if (!item.checked) {
      this.selectedIdsCsv += item[0] + ',';
      this.isSelectAll = false;
    } else {
      this.selectedIdsCsv = this.selectedIdsCsv.replace(item[0] + ',', '');
      const idsArray = this.selectedIdsCsv.split(',').filter(id => id);
      if (idsArray.length == 0) {
        this.isSelectAll = true;
      }
    }
  }

  getUrl(data) {
    if (data) {
      const obj = JSON.parse(data);
      return obj.url || '';
    } else {
      return '';
    }
  }

  onSaveClicked() {
    const idsArray = this.selectedIdsCsv.split(',').filter(id => id !== '');
    if (idsArray.length == 0) {
      alert('Please select at least one to save!');
      return;
    }

    // validate
    let errorMsg = '';
    idsArray.map(id => {
      const item = this.filteredList.find(i => i[0] === +id);
      if (!item.valid) {
        errorMsg += `${item[1]} has invalid url.<br/>`;
      }
    });

    if (errorMsg) {
      this.utilService.showToastError(errorMsg, 'Error', true);
      return;
    }

    let itemsToUpdate = [];
    idsArray.map(id => {
      const item = this.filteredList.find(i => i[0] === +id);
      const oldItem = this.dataList.find(i => i[0] === +id);
      if (item && item[2] !== oldItem[2]) {
        itemsToUpdate.push(item);
      }
    });

    if (itemsToUpdate.length) {
      this.eapService.bulkUrlUpdate(itemsToUpdate).subscribe(() => {
        this.utilService.showToastSuccess(`${itemsToUpdate.length} EAP url${itemsToUpdate.length > 1 ? 's' : ''} updated`);
        this.router.navigate(['/cms/eap']);
      });
    } else {
      alert('Nothing to update!');
    }
  }

  onResetClicked() {
    if (confirm('Do you want to reset all changes?')) {
      this.applyFilteredList();
    }
  }

  onClearClicked(item) {
    item[2] = '';
  }

  getValidationClass(item) {
    const oldUrl = this.dataList.find(i => i[0] === item[0])[2];
    if (oldUrl) {
      if (!item[2]) {
        item.valid = false;
        return 'has-error url-empty';
      } else {
        if (!this.utilService.isValidURL(item[2])) {
          item.valid = false;
          return 'has-error url-invalid';
        }
      }
    }

    item.valid = true;
    return '';
  }
}
