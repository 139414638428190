import { ITutorialPage } from '../../app/_models/tutorial-page';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { TutorialService } from './tutorials.service';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss']
})
export class TutorialsComponent implements OnInit {
  tutorialPages: ITutorialPage[];

  constructor(
    private activeModal: NgbActiveModal,
    private config: NgbCarouselConfig,
    private service: TutorialService
  ) {
    this.config.interval = 30000;
    this.config.wrap = true;
    this.config.keyboard = false;
  }

  ngOnInit() {
    this.tutorialPages = this.service.getTutorialPages();
  }

  closeModal() {
    this.activeModal.close();
  }
}
