import { Component, OnInit } from '@angular/core';
import { TMHService } from '../../../services/track-my-health-service';
import { ReportsService } from '../../../services/reports-service';
import { UserService } from '../../../services/user-service';
import { TrackMyHealthReportingService } from './track-my-health-reporting.service';

@Component({
  selector: 'app-track-my-health-reporting',
  templateUrl: './track-my-health-reporting.component.html',
  styleUrls: ['./track-my-health-reporting.component.scss'],
  providers: [TrackMyHealthReportingService],
})
export class TrackMyHealthReportingComponent implements OnInit {
  startDate: Date;
  endDate: Date;
  canSelectCompany = false;
  companyId: number;

  constructor(
    private reportService: ReportsService,
    private userService: UserService,
    public tmhService: TMHService,
    public service: TrackMyHealthReportingService,
  ) {
  }

  ngOnInit() {
    this.setInitialComponentState();
    this.refreshAllCharts(this.companyId, this.startDate, this.endDate);
  }

  setInitialComponentState() {
    this.getAllIndicators();
    this.canSelectCompany = this.userService.isSuperAdminUser();
    this.companyId = this.reportService.cmsCompanyId;
    this.startDate = this.reportService.cmsStartDate;
    this.endDate = this.reportService.cmsEndDate;
  }

  private getAllIndicators() {
    this.tmhService.getAllIndicatorsForReport()
      .subscribe(indicators => {
        this.service.setIndicators(indicators);
      });
  }

  private refreshAllCharts(companyId: number, startDate: Date, endDate: Date) {
    this.reportService.getTmhCompanyDailySummary(companyId, startDate, endDate)
      .subscribe(data => {
        this.service.setDailyEntries(data);
      });
  }

  onDateRangeSelected($event) {
    this.reportService.cmsCompanyId = $event.companyId;
    this.reportService.cmsStartDate = $event.startDate;
    this.reportService.cmsEndDate = $event.endDate;

    this.refreshAllCharts($event.companyId, $event.startDate, $event.endDate);
  }
}
