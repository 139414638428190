import { UtilService } from '../../../../../services/util-service';
import { DateService } from '../../../../../services/date.service';
import { LifeActionService } from '../../../../../services/life-action-service';
import { IIndicator } from '../../../../_models/indicator';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-active-life-action',
  templateUrl: './active-life-action.component.html',
  styleUrls: ['./active-life-action.component.scss']
})
export class ActiveLifeActionComponent implements OnInit {

  @Input('indicator') indicator: IIndicator;
  @Input('index') index;
  isAchieved: boolean;
  lastCompletedDateLabel: string;
  recurrenceString: string;
  trackersPresent = true;
  isMultipleDaily: boolean;
  historyTrackers = [];
  trackerGoal;
  trackerProgress;

  @Output()
  doLifeAction: EventEmitter<any> = new EventEmitter<{}>();

  constructor(
    private lifeActionService: LifeActionService,
    private dateService: DateService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    if (this.indicator.trackers && this.indicator.trackers.length) {
      this.setIsAchievedPropertyForCurrentPeriod(this.indicator.recurrence, this.indicator.trackers[0]);
      this.historyTrackers = this.indicator.trackers;
    } else {
      this.isAchieved = false;
      this.trackersPresent = false;
      this.formatRecurrenceIntegerToString(this.indicator.recurrence);
    }
  }

  setIsAchievedPropertyForCurrentPeriod(recurrence, tracker) {
    const currentPeriod = this.lifeActionService.getDateByRecurrencePeriod(recurrence);
    this.formatRecurrenceIntegerToString(recurrence);
    if (this.indicator.activeIndicatorRule.lowerValue > 1) {
      this.isMultipleDaily = true;

      this.trackerGoal = this.indicator.activeIndicatorRule.lowerValue;

      const isTrackerInThisPeriod = this.dateService.isBetweenTwoDatesInclusive(tracker.date, currentPeriod.startDate, currentPeriod.endDate);
      if (isTrackerInThisPeriod) {
        this.isAchieved = isTrackerInThisPeriod && tracker.value >= this.indicator.activeIndicatorRule.lowerValue;
        this.trackerProgress = tracker.value;
      } else {
        this.isAchieved = false;
        this.trackerProgress = 0;
      }

      this.formatReadableTimeAlwaysPast(tracker.updatedAt);
    } else {
      this.isMultipleDaily = false;

      this.isAchieved = this.dateService.isBetweenTwoDatesInclusive(tracker.date, currentPeriod.startDate, currentPeriod.endDate);
      this.formatReadableDateDefaultingToToday(tracker.date);
    }
  }

  formatRecurrenceIntegerToString(recurrence) {
    this.recurrenceString = this.lifeActionService.formatRecurrenceIntegersAsStrings(recurrence);
  }

  formatReadableTimeAlwaysPast(dateTime) {
    const now = moment().format();
    const formattedDateTime = moment(dateTime).format();
    const momentToPass = formattedDateTime > now ? now : formattedDateTime;
    this.lastCompletedDateLabel = 'Last done ' + this.dateService.timeFromNow(momentToPass);
  }

  formatReadableDateDefaultingToToday(date) {
    if (!date) {
      this.lastCompletedDateLabel = 'Never done!'
    } else {
      this.lastCompletedDateLabel = 'Last done ' + this.dateService.formatReadableDateDefaultingToToday(date);
    }
  }
  completeLatestLifeAction() {
    this.lifeActionService.logLifeActionProgress(this.indicator.id).subscribe((data) => {
      this.trackersPresent = true;
      this.indicator.trackers.unshift(data.updatedLifeAction[0]);

      this.setIsAchievedPropertyForCurrentPeriod(this.indicator.recurrence, data.updatedLifeAction[0]);

      // assign *new* array to history array so that ngOnChanges is triggered in history component
      const newHistoryArray = _.cloneDeep(this.historyTrackers);
      newHistoryArray.push(data.updatedLifeAction[0]);
      this.historyTrackers = newHistoryArray;

      if (data.updatedLifeAction[0].achievedThisUpdate === true) {
        this.utilService.showToast('success', 'LifeAction completed!');
      }
      this.displayEachAchievedBadgeAlerts(data.achievedBadges);
      const emitBody = {
        indicator: this.indicator,
        index: this.index
      };
      this.doLifeAction.emit(emitBody);
    });
  }

  private displayEachAchievedBadgeAlerts(achievedBadges) {
    if (!achievedBadges.length) { return; }

    achievedBadges.forEach(b => this.utilService.badgeAlert([b]));
  }
}
