<div *ngIf='isDisplayingList && isInitialised'>
  <div class='mb-2 w-50'>
    <app-select-company
      [allCompanies]='allCompanies'
      [selectedCompanyId]='companyId'
      (companyIdSelected)='onCompanySelected($event)'
    ></app-select-company>
  </div>

  <app-cms-list-view
    *ngIf='isInitialised'
    [dataList]='items'
    [isPreventListDelete]='true'
    [isPreventAdd]='false'
    [columnList]='columns'>
  </app-cms-list-view>
</div>

<div *ngIf='(isAddingItem || isEditingItem) && isInitialised'
     class='padding jumbotron'>
  <form
    [formGroup]='itemForm'
    (ngSubmit)='onSaveClick()'>
    <div class='row m-0'>
      <div *ngIf='showConnectionExistedError' class='col-12'>
        <p class='form-input-error-message'>Each company should have only 1 SSO connection!</p>
      </div>
      <div class='col-6'>
        <div class='form-group'>
          <label class='control-label required'>Name</label>
          <input
            type='text'
            formControlName='nameControl'
            [ngClass]="displayFieldCss('nameControl')"
            class='form-control' />
          <span class='invalid-feedback'>Name is required</span>
        </div>
      </div>

      <div class='col-6'>
        <div class='form-group'>
          <label class='control-label required'>Availability</label>
          <select
            class='form-control custom-select'
            formControlName='enableControl'
          >
            <option [value]=true>Enabled</option>
            <option [value]=false>Disabled</option>
          </select>
        </div>
      </div>

      <div class='col-10'>
        <div class='form-group'>
          <label class='control-label required'>Company</label>
          <select
            class='form-control custom-select'
            formControlName='companyIdControl'
            [ngClass]="displayFieldCss('companyIdControl')"
            (change)='onCompanyChange($event.target.value)'>
            <option *ngIf='!item.id'
                    value='null' selected>Select Company IF applicable
            </option>
            <option
              *ngFor='let item of companies'
              [value]='item.id'>{{item.name}}
            </option>
          </select>
          <span class='invalid-feedback'>Company is required</span>
        </div>
      </div>

      <div class='col-4'>
        <div class='form-group'>
          <label for='' class='control-label required'>Button Label</label>
          <input
            type='text'
            formControlName='buttonLabelControl'
            [ngClass]="displayFieldCss('buttonLabelControl')"
            class='form-control' />
          <span class='invalid-feedback'>Button Label is required</span>
        </div>
      </div>

      <div class='col-4'>
        <div class='form-group'>
          <label class='control-label w-100 required'>Button Color</label>
          <span class='colorDiv' [(colorPicker)]='this.itemForm.value.buttonColorControl'
                [style.backgroundColor]='this.itemForm.value.buttonColorControl' [cpOutputFormat]='hex'
                (colorPickerSelect)="changeColor('buttonColorControl', this.itemForm.value.buttonColorControl)"
                [cpOKButton]='true'></span>
          <input
            type='text' maxlength='7'
            formControlName='buttonColorControl'
            [ngClass]="displayFieldCss('buttonColorControl')"
            class='form-control colorTextBox' />
          <span class='invalid-feedback'>Button Color is invalid</span>
        </div>
      </div>

      <div class='col-4'>
        <div class='form-group'>
          <label class='control-label required'>Button Order</label>
          <input
            type='text'
            formControlName='buttonOrderControl'
            [ngClass]="displayFieldCss('buttonOrderControl')"
            class='form-control' />
          <span class='invalid-feedback'>Button Order must be a number</span>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <br />
    <div class='row'>
      <div [ngClass]="{ 'col-6': !canDelete, 'col-4': canDelete }">
        <button
          type='button'
          class='btn btn-outline-primary w-100'
          (click)='onBackClick()'>
          <i class='fa fa-undo mr-2' aria-hidden='true'></i> BACK
        </button>
      </div>

      <div *ngIf='canDelete'
           class='col-4'>
        <button
          type='button'
          class='btn btn-outline-primary w-100'
          [disabled]='!canRespondToButtons'
          (click)='onDeleteClick()'>
          <i class='fa fa-trash mr-2' aria-hidden='true'></i> DELETE
        </button>
      </div>

      <div [ngClass]="{ 'col-6': !canDelete, 'col-4': canDelete }">
        <button
          type='submit'
          class='btn btn-primary float-right w-100'
          [disabled]='!canRespondToButtons'>
          <i class='fa fa-floppy-o mr-2' aria-hidden='true'></i> SAVE
        </button>
      </div>
    </div>
  </form>
</div>
