import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LifeActionDriverFilterComponent } from './life-action-driver-filter/life-action-driver-filter.component';
import { LifeActionCardComponent } from './life-action/life-action.component';
import { ActRoutingModule } from './act-routing.module';
import { DevicesComponent } from './devices/devices.component';
import { ActComponent } from './act.component';
import { LifeActionReportComponent } from './life-action-report/life-action-report.component';
import { TrackMyHealthComponent } from './track-my-health/track-my-health.component';
import { ProgramComponent } from './program/program.component';
import { ProgramDetailComponent } from './program-detail/program-detail.component';
import { SharedModule } from '../_shared/shared.module';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { ChallengesComponent } from '../challenges/challenges.component';
import { ChallengeDetailsComponent } from '../challenge-details/challenge-details.component';
import { TeamsComponent } from '../teams/teams.component';
import { TeamDetailsComponent } from '../team-details/team-details.component';


@NgModule({
  declarations: [
    ActComponent,
    DevicesComponent,
    LifeActionCardComponent,
    LifeActionReportComponent,
    LifeActionDriverFilterComponent,
    TrackMyHealthComponent,
    ProgramComponent,
    ProgramDetailComponent,
    ChallengesComponent,
    ChallengeDetailsComponent,
    TeamsComponent,
    TeamDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RoundProgressModule,
    SharedModule,
    ActRoutingModule
  ]
})
export class ActModule {}
