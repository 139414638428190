import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../../services/user-service';
import { environment } from '../../../environments/_active-environment/environment';

@Component({
  selector: 'app-my-rewards',
  templateUrl: './my-rewards.component.html',
  styleUrls: ['./my-rewards.component.scss'],
})
export class MyRewardsComponent implements OnInit {
  @ViewChild('form') form: ElementRef;
  userEmail: string;
  myRewardsAction: string;
  myRewardsUrl: string;
  myRewardsCode: number;
  companyRewardsText: string;
  hasCompanyRewardsText: Boolean = false;
  companyId: number;

  genericHTML =
    '<p>My Rewards is our exclusive member benefits program that gives you access to cashback, fantastic discounts and offers from a wide range of retail partners. It&#39;s our way of saying thank you, to you!&nbsp;</p><p>Just click through to&nbsp;100s&nbsp;of your&nbsp;favourite stores, shop as you&nbsp;usually would and access the&nbsp;fantastic offers at hundreds&nbsp;of stores!&nbsp;The smart, simple and secure way to&nbsp;earn cashback every time you shop.&nbsp;It&#39;s&nbsp;easy and&nbsp;it&#39;s&nbsp;free!&nbsp;</p>';

  constructor(
    public userService: UserService,
    public domSanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    if (
      this.userService.company.rewardsText !== null &&
      this.userService.company.rewardsText !== ''
    ) {
      this.companyRewardsText = this.userService.company.rewardsText;
      this.hasCompanyRewardsText = true;
    }
    this.userEmail = this.userService.user.email;
    this.myRewardsAction = this.userService.myRewardsAction;
    this.myRewardsUrl = this.userService.myRewardsDomainUrl;
    this.myRewardsCode = this.userService.myRewardsDomainCode;
    this.companyId = this.userService.company.id;
  }

  logNavigation() {
    window.open(
      `${environment.app.endpoint}/my-reward?action=${this.myRewardsAction}&email=${this.userEmail}&domain_url=${this.myRewardsUrl}&domain_code=${this.myRewardsCode}&company_id=${this.companyId}&registered=1`,
    );
  }
}
