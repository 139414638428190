import { FeatureService } from '../../services/feature-service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GroupService } from '../../services/group-service';
import { BadgeService } from '../../services/badge-service';
import { UserService } from '../../services/user-service';

@Component({
  selector: 'app-reward',
  templateUrl: './reward.component.html',
  styleUrls: ['./reward.component.scss']
})
export class RewardComponent implements OnInit {

  defaultColumns = [];
  subFilterCards = [];
  features = [];

  constructor(
    public activatedRoute: ActivatedRoute,
    private badgeService: BadgeService,
    private groupService: GroupService,
    public userService: UserService,
    private featureService: FeatureService
  ) { }

  ngOnInit() {
    if (this.userService.isFiitr) {
      this.defaultColumns = [1, 1],
        this.features = [
          this.featureService.BadgesFeatureId,
          this.featureService.MyRewardsFeatureId
        ];
      this.subFilterCards = [
        [{
          title: 'Badges',
          img: '../../assets/img/badges.wide.png',
          route: '/reward/badges',
        }],
        [{
          title: 'My Rewards',
          img: '../../assets/img/discounts.wide.png',
          route: '/reward/my-rewards',
        }]
      ];
    } else {
      this.defaultColumns = [1, 1],
        this.features = [
          this.featureService.BadgesFeatureId,
          this.featureService.MyRewardsFeatureId
        ];
      this.subFilterCards = [
        [{
          title: 'Badges',
          img: '../../assets/img/badges.wide.png',
          route: '/reward/badges',
        }],
        [{
          title: 'My Rewards',
          img: '../../assets/img/discounts.wide.png',
          route: '/reward/my-rewards',
        }]
      ];
    }

    this.groupService.refreshLatestGroupActivity();
    this.badgeService.refreshShortLeaderboard(
      this.userService.company.id,
      this.userService.user.userProfile.id
    );
  }
}
