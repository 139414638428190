import { Component, OnInit, Inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-badge-modal',
  templateUrl: './badge-modal.component.html',
  styleUrls: ['./badge-modal.component.scss']
})
export class BadgeModalComponent implements OnInit {
  @Input() item;
  @Input() title;
  @Input() message;
  @Input() image;
  @Input() description;

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router
  ) {}

  ngOnInit() {}

  viewBadges() {
    this.activeModal.close('View');
  }

  close() {
    this.activeModal.close();
  }
}
