<div *ngIf="isInitialised" class="jumbotron">
  <form [formGroup]="itemForm" (ngSubmit)="onSaveClick()">
    <div class="row m-0">
      <div class="table-responsive shadow-lg mb-2" *ngIf="isQuestionDisplay">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>Question Number</th>
              <th>Question</th>
              <th>Answer type</th>
              <th>Required?</th>
              <th>'I don't know' option?</th>
              <th>Dependency</th>

            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{item.order}}</td>
              <td>{{item.question}}
                <ul id="{{item.id}}" class="collapse">
                  <li *ngFor="let a of item.liqAnswers">{{a.label}}</li>
                </ul>
              </td>
              <td>{{item.answerType}}</td>
              <td *ngIf="item.required">Yes</td>
              <td *ngIf="!item.required">No</td>
              <td *ngIf="item.unknown">Yes</td>
              <td *ngIf="!item.unknown">No</td>
              <td *ngIf="dependencyParams">"{{dependencyParams.question}}"</td>
              <td *ngIf="!dependencyParams"></td>
            </tr>
          </tbody>
        </table>
      </div>




      <div *ngIf="isQuestionInput">
        <!-- Question -->
        <div class="form-group">
          <h3 *ngIf="isDependentItem">New question: dependent on user answering "{{dependencyParams.question}}"</h3>
          <label *ngIf="dependencyParams">This <b>question</b> will appear as a result of</label>
          <br />
          <div *ngIf="dependencyParams" class="row m-2">
            <div class="col-5">
              <div class="custom-control custom-radio custom-control-inline mt-2">
                <input type="radio" id="any" value="any" class="custom-control-input" formControlName="dependencyLevel"
                  [ngClass]="displayFieldCss('dependencyLevel')" />
                <label class="custom-control-label" for="any">Any answer to "{{dependencyParams.question}}"</label>
              </div>
            </div>
            <div *ngIf="answersDependency.length > 1" class="col-2">OR </div>
            <div class="col-5">
              <div *ngIf="answersDependency.length > 1" class="custom-control custom-radio custom-control-inline mt-2">
                <input type="radio" id="specific" value="specific" class="custom-control-input" formControlName="dependencyLevel"
                  [ngClass]="displayFieldCss('dependencyLevel')" />
                <label class="custom-control-label" for="specific">After specific answers to
                  "{{dependencyParams.question}}"</label>
              </div>
              <div *ngIf="answersDependency.length > 1 && isSpecificDependency()">
                <div *ngIf="answersDependency.length > 1">
                  <div class="col-12 pl-3" *ngFor="let asr of answersDependency">
                    <div class="custom-control custom-checkbox">
                      <input id="{{asr.id}}" type="checkbox" checked="{{asr.checked}}" (click)="selectAnswer(asr.id)"
                        class="custom-control-input">
                      <label class="custom-control-label" for="{{asr.id}}">{{asr.label}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <label class="control-label required">Question</label>
          <input type="text" class="form-control" [ngClass]="displayFieldCss('questionControl')" formControlName="questionControl" />
          <span class="invalid-feedback">Question is required</span>
        </div>
        <div class="row">
          <div class='col-12'>
            <div class='form-group'>
              <label class='control-label required'>Category</label>
              <select class="form-control custom-select" [ngClass]="displayFieldCss('categoryIdControl')"
                      formControlName="categoryIdControl">
                <option
                  value=null selected>Select a category</option>
                <option *ngFor="let item of allCategories" [value]="item.id">{{item.name}}</option>
              </select>
              <span class="invalid-feedback">Category is required</span>
            </div>
          </div>
          <!--- Answers type -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label class="control-label required">Answers Type
                <small *ngIf="item.liqAnswers.length > 0">( to change it, first delete the answers)</small>
              </label>
              <select [ngClass]="displayFieldCss('answerTypeControl')" class="form-control custom-select"
                formControlName="answerTypeControl" (change)="onAnswerTypeSelected($event)">
                <option value=null selected>:: select ::</option>
                <option value="text" [disabled]="item.liqAnswers.length > 0">Text</option>
                <option value="number" [disabled]="item.liqAnswers.length > 0">Number</option>
                <option value="dropdown" [disabled]="item.liqAnswers.length > 0">Dropdown</option>
                <option value="checkbox" [disabled]="item.liqAnswers.length > 0">Checkbox</option>
                <option value="radio" [disabled]="item.liqAnswers.length > 0">Radio</option>
                <option value="range" [disabled]="item.liqAnswers.length > 0">Range</option>
              </select>
              <span class="invalid-feedback">Answers Type is required</span>
            </div>
          </div>
          <!-- Order -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label class="control-label required">Question Number</label>
              <input type="number" class="form-control" [ngClass]="displayFieldCss('orderControl')" formControlName="orderControl" />
              <span class="invalid-feedback">Order is required</span>
            </div>
          </div>
        </div>
        <div class="row">
          <!--- Required -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label class="control-label required">Is the question required?</label>
              <br />
              <div class="custom-control custom-radio custom-control-inline mt-2">
                <input type="radio" id="required1" value="true" class="custom-control-input" formControlName="requiredControl"
                  [ngClass]="displayFieldCss('requiredControl')" />
                <label class="custom-control-label" for="required1">Yes</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline mt-2">
                <input type="radio" id="required2" value="false" class="custom-control-input" formControlName="requiredControl"
                  [ngClass]="displayFieldCss('requiredControl')" />
                <label class="custom-control-label" for="required2">No</label>
              </div>
              <span class="invalid-feedback">
                <br /> Required is required.
              </span>
            </div>
          </div>
          <!--- Required -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label class="control-label required">Can the user select an 'I don't know' checkbox?</label>
              <br />
              <div class="custom-control custom-radio custom-control-inline mt-2">
                <input type="radio" id="unknown1" value="true" class="custom-control-input" formControlName="unknownControl"
                  [ngClass]="displayFieldCss('unknownControl')" />
                <label class="custom-control-label" for="unknown1">Yes</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline mt-2">
                <input type="radio" id="unknown2" value="false" class="custom-control-input" formControlName="unknownControl"
                  [ngClass]="displayFieldCss('unknownControl')" />
                <label class="custom-control-label" for="unknown2">No</label>
              </div>
              <span class="invalid-feedback">
                <br /> Unknown is required.
              </span>
            </div>
            <div class="form-group" *ngIf="isIDontKnow()">
              <label>How many points will the user receive for answering 'I don't know'?</label>
              <input type="number" class="form-control" formControlName="iDontKnowPointsControl" />
            </div>
          </div>
        </div>
        <div class="row">
          <!--- Required -->
          <div *ngIf="!dependencyParams && isSelectRadio" class="col-12 col-sm-6">
            <div class="form-group">
              <label class="control-label">Can display dependency question if user select 'No' option in parent question?</label>
              <br />
              <div class="custom-control custom-radio custom-control-inline mt-2">
                <input type="radio" id="displayDependencyQuestion1" value="true" class="custom-control-input" formControlName="displayDependencyQuestionControl"
                  [ngClass]="displayFieldCss('displayDependencyQuestionControl')" />
                <label class="custom-control-label" for="displayDependencyQuestion1">Yes</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline mt-2">
                <input type="radio" id="displayDependencyQuestion2" value="false" class="custom-control-input" formControlName="displayDependencyQuestionControl"
                  [ngClass]="displayFieldCss('displayDependencyQuestionControl')" />
                <label class="custom-control-label" for="displayDependencyQuestion2">No</label>
              </div>
              <!-- <span class="invalid-feedback">
                <br /> Required is required.
              </span> -->
            </div>
          </div></div>
      </div>
      <div class="col-12 mt-2" *ngIf="isQuestionInput">
        <button type="submit" class="btn btn-primary float-right" [disabled]="!canRespondToButtons">
          <i class="fa fa-floppy-o mr-2" aria-hidden="true"></i> Save question
        </button>
      </div>
      <div *ngIf="isQuestionDisplay" class="col-12 mt-2">
        <button type="submit" class="btn btn-primary float-right" (click)="editQuestion()" [disabled]="!canRespondToButtons">
          <i class="fa fa-floppy-o mr-2" aria-hidden="true"></i> Edit question
        </button>
      </div>
      <!-- answers -->


      <app-answer-management class="col-12" [dependencyParams]="dependencyParams" (finishedAnswerEdit)="finishedAnswerEdit($event, 'add')"
        [questions]="items" [questionId]="item.id" [questionType]="item.answerType" [resultType]="questionnaire.liqResultTypeId"
        *ngIf="isAddingAnswer && !isRangeAnswer()"></app-answer-management>
      <app-answer-management class="col-12" [dependencyParams]="dependencyParams" (finishedAnswerEdit)="finishedAnswerEdit($event, 'edit')"
        [item]="editableAnswer" [questions]="items" [questionId]="item.id" [questionType]="item.answerType"
        [resultType]="questionnaire.liqResultTypeId" *ngIf="isEditingAnswer && !isRangeAnswer()"></app-answer-management>

      <div *ngIf="isRangeAnswer() && isQuestionDisplay">
        <app-range-answers-edit (finishedRangeEdit)="finishedRangeEdit($event)" [hasAnswers]="hasAnswers()"
          [questionId]="item.id"></app-range-answers-edit>
      </div>

      <div class="col-6" *ngIf="!isRangeAnswer()">
        <label class="control-label required">Answers</label>
        <small *ngIf="isAddingItem">
          <br /> To add the answers, first save the question.</small>
      </div>
      <div class="table-responsive shadow-lg mb-3" *ngIf="!isRangeAnswer()">

        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>Answer</th>
              <th>Value</th>
              <th>Points</th>
              <th class="text-center">Edit</th>
              <th class="text-center">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let a of item.liqAnswers">
              <td>{{a.label}}</td>
              <td>{{a.value}}</td>
              <td>{{a.points}}</td>
              <td (click)="openAnswerForm(a)" class="text-center">
                <i class="fa fa-pencil" aria-hidden="true"></i>
              </td>
              <td (click)="openModalDeleteAnswer(a)" class="text-center">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </td>
            </tr>
            <tr *ngIf="!item.liqAnswers.length">
              <th colspan="5" class="text-center">No answers added to the question yet.</th>
            </tr>
          </tbody>
        </table>
      </div>


      <div class="col-12 mt-2">
        <button type="button" class="btn btn-primary float-right" (click)="enableAnswerForm()" [disabled]="isQuestionInput"
          [hidden]="isRangeAnswer() || isTextAnswerLimit() || isNumberAnswerLimit()">
          <i class="fa fa-plus mr-2" aria-hidden="true"></i> Add answer
        </button>
      </div>
      <!-- Buttons -->
      <br />
      <div class="col-12 pt-3">
        <button type="button" class="btn btn-outline-primary float-right" [disabled]="!canRespondToButtons" (click)="done()">
          {{doneButtonLabel}}
        </button>
        <button *ngIf="isQuestionDisplay && !dependencyParams" type="button" class="btn btn-outline-primary float-right mr-2"
          [disabled]="!canRespondToButtons" (click)="createDependent()">
          Create dependent question
        </button>

      </div>
    </div>
  </form>
</div>
