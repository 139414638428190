import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-team-leave',
  templateUrl: './team-leave.component.html',
  styleUrls: ['./team-leave.component.scss']
})
export class TeamLeaveComponent implements OnInit {

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {

  }

  leaveChallenge(){
    this.activeModal.close('leave-challenge');
  }

  joinAnotherTeam(){
    this.activeModal.close('join-another-team');
  }

  close() {
    this.activeModal.dismiss();
  }
}
