<div class="modal-header">
  <h4 class="modal-title">Create a group</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <img class="group-detail-image" [src]="getPhoto('groups/banner/',banner)" />
  <div class="group-detail-logo-wrapper">
    <img class="group-detail-logo" [src]="getPhoto('groups/logo/',logo)" />
  </div>
  <div class="formArea">
    <label>Group Name</label>
    <input type="text" [(ngModel)]="name" />
    <div *ngIf="error==1" class="text-danger"> Group name is required</div>
    <label class="mt-2">Group Description</label>
    <textarea rows="4" [(ngModel)]="description">
      </textarea>
    <div *ngIf="error==2" class="text-danger"> Group description is required</div>
  </div>

</div>
<div class="p-3">
  <div class="footerButtons">
    <button type="button" (click)="showPicker(1)" class="btn btn-primary">
      Change logo
    </button>
    <button type="button" (click)="showPicker(2)" class="btn btn-primary">
      Change Banner
    </button>
  </div>
  <div class="pt-2">
    <button type="buttons" (click)="save()" class="btn btn-primary w-100 pt-1">Save</button>
  </div>
</div>