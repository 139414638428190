<div class='pb-3'>
  <h2 class='mt-2'>{{ companyName }} Information</h2>
  <p *ngIf='infopacks.length > 0'>Click on any of the print-friendly packs below for information and support.</p>
  <p *ngIf='infopacks.length === 0'>No info packs</p>
  <div class='card w-100 p-3 mt-3 cursorHand d-inline-block' *ngFor='let item of infopacks' (click)='openFile(item.file)'>
    <div class='row'>
      <div class='col-12 col-sm-3 text-center'>
        <div class='card-img' [ngStyle]="{'background': 'url('+getPath(item.image)+') center no-repeat', 'background-size': 'cover'}"></div>
      </div>
      <div class='col-12 col-sm-9'>
        <h5 class='card-title primaryColor'>{{item.title}}</h5>
        <p class='card-text' [innerHTML]='item.description'></p>
      </div>
    </div>
  </div>
</div>
