<app-date-selector [inputCompanyId]="companyId" [inputStartDate]="startDate" [inputEndDate]="endDate" [canSelectCompany]="canSelectCompany"
  (dateQuery)="onDateRangeSelected($event)">
</app-date-selector>

<div class="card-columns">
  <div class="card w-100 text-center">
    <div class="card-header">
      <h5>Total Articles Read by Category</h5>
    </div>
    <div class="card-body">
      <div *ngIf="isCategoriesArticlesViewsLoading">
        <i class="fa fa-spinner fa-spin fa-lg" aria-hidden="true"></i>
      </div>
      <div *ngIf="!isCategoriesArticlesViewsLoading && !isCategoriesArticlesViewsLoaded">
        <div>
          <p>No data available</p>
        </div>
      </div>
      <div *ngIf="!isCategoriesArticlesViewsLoading && isCategoriesArticlesViewsLoaded">
        <app-doughnut-graph [labels]="categoriesNames" [data]="categoriesViews" [color]="chartColors" [legend]="true" type="pie"></app-doughnut-graph>
      </div>
    </div>
  </div>
</div>
