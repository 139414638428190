import { Injectable } from '@angular/core';
import { TMHService } from '../../../services/track-my-health-service';
import { IIndicator } from '../../_models/indicator';
import { IIndicatorTrackerDailySummary } from '../../_models/indicator-tracker-daily-summary';

const DISPLAY_INCREMENT = 7;

@Injectable()
export class TrackMyHealthReportingService {
  indicators: IIndicator[] = [];
  dailySummaries: IIndicatorTrackerDailySummary[] = [];

  constructor(private tmhService: TMHService) {}

  setIndicators(indicators: IIndicator[]) {
    this.indicators = indicators;
    this.setIndicatorsDisplayUnit();
  }

  setDailyEntries(entries: IIndicatorTrackerDailySummary[]) {
    this.dailySummaries = entries;
    this.sumDailySummaries();
    this.setDisplayValueTotals();
    this.resetDisplayIncrements();
    this.setDisplayDailySummaries();
  }

  incrementIndicatorDisplayedDailySummaries(indicator: IIndicator) {
    indicator.numberDisplayed = Math.min(indicator.entriesCount, indicator.numberDisplayed + DISPLAY_INCREMENT);
    this.setCanDisplayMore(indicator);

    const dailySummaries = this.dailySummaries.filter(ds => ds.id === indicator.id);
    indicator.displayDailySummaries = dailySummaries.slice(0, indicator.numberDisplayed);
  }

  private setIndicatorsDisplayUnit() {
    this.indicators.forEach(i => {
      i.displayUnit = this.tmhService.getIndicatorDisplayUnit(i);
    });
  }

  private sumDailySummaries() {
    this.indicators.forEach((i: IIndicator) => {
      const summaries = this.dailySummaries.filter(ds => ds.id === i.id);
      i.entriesCount = summaries.length;
      i.hasEntries = i.entriesCount > 0;
      i.trackedTotal = summaries.map(e => +e.count_value).reduce((prev, next) => prev + next, 0);
      i.achievedTotal = summaries.map(e => +e.count_achieved).reduce((prev, next) => prev + next, 0);
      i.valueTotal = summaries.map(e => +e.sum_value).reduce((prev, next) => prev + next, 0);
      i.displayValueTotal = this.tmhService.toIndicatorDisplayValue(i, i.valueTotal);
    });
  }

  private setDisplayValueTotals() {
    this.indicators.forEach((i: IIndicator) => {
      const summaries = this.dailySummaries.filter(ds => ds.id === i.id);
      summaries.forEach(s => s.displaySumValue = this.tmhService.toIndicatorDisplayValue(i, s.sum_value));
    });
  }

  private resetDisplayIncrements() {
    this.indicators.forEach((i: IIndicator) => {
      i.numberDisplayed = Math.min(i.entriesCount, DISPLAY_INCREMENT);
      this.setCanDisplayMore(i);
    });
  }

  private setDisplayDailySummaries() {
    this.indicators.forEach((i: IIndicator) => {
      i.displayDailySummaries = this.dailySummaries
        .filter(ds => ds.id === i.id)
        .slice(0, i.numberDisplayed);
    });
  }

  private setCanDisplayMore(i: IIndicator) {
    i.canDisplayMore = (i.numberDisplayed < i.entriesCount);
  }
}
