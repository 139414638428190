<h4>Suggested Friends</h4>
<div class="row">
  <div *ngFor="let friend of suggestedFriends"
    class="col-lg-3 col-md-6 col-6 suggested-card">
    <div class="content-card card-outline mr-0 ml-0 p-3 mb-3" align="center">
      <app-avatar
        [photoSrc]="friend.photoSrc"
        [levelSrc]="friend.levelSrc"
        (selectAvatar)="onViewFriend(friend)"
      ></app-avatar>
      <div class="center">
        <h6 class="truncate" [ngbTooltip]="friend.username">
          {{ friend.usernameTruncated }}
        </h6>
      </div>
      <div class="w-100">
        <button
          type="button"
          (click)="onAddFriend(friend)"
          class="btn btn-primary w-100">Add
      </button>
      </div>
    </div>
  </div>
</div>
