<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p *ngIf="data.message && data.message != '' && !data.messageWarning" [innerHTML]="data.message"></p>
  <p class="warning" *ngIf="data.message && data.message != '' && data.messageWarning" [innerHTML]="data.message"></p>
  <!-- <mat-form-field *ngIf="data.value1 || data.date" class="w-100"> -->
  <div *ngIf="data.value1" class="mb-3">
    <div> {{data.value1.label}}
      <span *ngIf="data.value1.required" class="text-danger">*</span>
    </div>
    <input id="value1" [(ngModel)]="data.value1.value" type="{{data.value1.type}}">
    <div *ngIf="error==1" class="text-danger"> Please enter a valid value</div>
  </div>
  <div *ngIf="data.value2 && data.value2.label" class="mb-3">
    <div> {{data.value2.label}}
      <span *ngIf="data.value2.required" class="text-danger">*</span>
    </div>
    <input id="value2" [(ngModel)]="data.value2.value" type="{{data.value2.type}}">
    <div *ngIf="error==2" class="text-danger"> Please enter a valid value</div>
  </div>
  <div *ngIf="data.date && data.date.label" class="mb-3">
    <div> {{data.date.label}}
      <span *ngIf="data.date.required" class="text-danger">*</span>
    </div>
    <div class="input-group">
      <input class="form-control" id="dateInput" [(ngModel)]="data.date.value" navigation="arrows" [maxDate]="date" placement="bottom"
        [showWeekdays]="false" ngbDatepicker #d="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
          <i class="fa fa-calendar"></i>
        </button>
      </div>
    </div>
    <div *ngIf="error==3" class="text-danger"> Please enter a valid date</div>
  </div>
  <div *ngIf="data.endDate && data.endDate.label" class="mb-3">
    <div> {{data.endDate.label}}
      <span *ngIf="data.endDate.required" class="text-danger">*</span>
    </div>
    <input id="dateInput2" [(ngModel)]="data.endDate.value" type="date" [max]="day">
    <div *ngIf="error==3" class="text-danger"> Please enter a valid date</div>
  </div>

  <!-- <div *ngIf="data.date && data.date.label" class="mb-3">
        <div> {{data.date.label}}
            <span *ngIf="data.date.required" class="text-danger">*</span>
        </div>
        <div class="form-group col-12">
          <div class="row">
            <input type="number" class="col-3 form-control text-center" [(ngModel)]="data.date.day"  placeholder="Day"/>
            <span style="font-size: 23px">/</span>
            <input type="number" class="col-3 form-control text-center" [(ngModel)]="data.date.month" placeholder="Month"/>
            <span style="font-size: 23px">/</span>
            <input type="number" class="col-4 form-control text-center" [(ngModel)]="data.date.year" placeholder="Year"/>
            <div *ngIf="error==3" class="text-danger"> Please enter a valid date</div>
            <div *ngIf="error==2" class="text-danger"> Please enter a date</div>
          </div>
        </div>
    </div> -->
  <!-- <div *ngIf="data.endDate && data.endDate.label" class="mb-3">
        <div> {{data.endDate.label}}
            <span *ngIf="data.endDate.required" class="text-danger">*</span>
        </div>
        <div class="form-group col-12">
          <div class="row">
            <input type="number" class="col-3 form-control text-center" [(ngModel)]="data.endDate.day"  placeholder="Day"/>
            <span style="font-size: 23px">/</span>
            <input type="number" class="col-3 form-control text-center" [(ngModel)]="data.endDate.month" placeholder="Month"/>
            <span style="font-size: 23px">/</span>
            <input type="number" class="col-4 form-control text-center" [(ngModel)]="data.endDate.year" placeholder="Year"/>
            <div *ngIf="error==3" class="text-danger"> Please enter a valid date</div>
          </div>
        </div>
    </div> -->
  <div *ngIf="data.radios && data.radios.length > 0" class="mb-3 ">
    <div class="custom-control custom-radio">
      <input id="radio1" [attr.disabled]="data.radios[0].disabled" class="custom-control-input" [(ngModel)]="data.radios.value"
        value="{{data.radios[0].value}}" type="radio">
      <label class="custom-control-label" [attr.disabled]="data.radios[0].disabled" for="radio1">{{data.radios[0].label}}</label>
    </div>
    <br />
    <div class="custom-control custom-radio">
      <input id="radio2" [attr.disabled]="data.radios[1].disabled" class="custom-control-input" [(ngModel)]="data.radios.value"
        value="{{data.radios[1].value}}" type="radio">
      <label class="custom-control-label" for="radio2" [attr.disabled]="data.radios[1].disabled">{{data.radios[1].label}}
      </label>
    </div>
  </div>
  <!-- </mat-form-field> -->
</div>
<div mat-dialog-actions>
  <button mat-button (click)="save()" class="btn btn-primary  mr-2 w-48">{{data.confirmButtonLabel}}</button>
  <button mat-button (click)="onNoClick()" class="btn btn-outline-primary w-48">{{data.closeButtonLabel ? data.closeButtonLabel : 'Close'}}</button>
</div>