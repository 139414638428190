<section class='article-preview'>
  <div><img [alt]='sampleArticle.title' [src]='sampleArticle.coverImage' class='w-100'></div>
  <div class='p-2 mb-2'>
    <div class='d-flex justify-content-between px-2 mb-2'>
      <div><i aria-hidden='true' class='fa article-icon fa-thumbs-o-up'></i> &nbsp; <span
        class='article-likes'>&nbsp; {{sampleArticle.numberOfLikes}} likes</span></div>
      <div><i aria-hidden='true' class='fa article-icon fa-bookmark-o'></i></div>
    </div>
    <div [style.color]='bodyTextColor' class='article-title'>{{sampleArticle.title}}</div>
    <div [style.color]='bodyTextColor' class='article-excerpt'>{{sampleArticle.excerpt}}</div>
  </div>
</section>
