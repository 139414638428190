<div *ngIf="activatedRoute?.children?.length">
  <router-outlet></router-outlet>
</div>

<div *ngIf="!activatedRoute?.children?.length">
  <div *ngIf="isShowProgram" class="content-background mb-4">
    <div class="card-img-top-div img-size-div">
      <img class="img-size" [src]="program.imageSource">
    </div>
    <div class="p-3">
      <div class="headerBox">
        <h1>{{program.title}}</h1>
      </div>
      <p>
        <span class="backPrimary progressLabel">{{program.statusName}}</span>
      </p>
      <p>
        {{(program.detailDescription != null && program.detailDescription.length) > 0 ? program.detailDescription : program.description}}
      </p>

      <ngb-progressbar type="success" [value]="program.progressPercent" [showValue]="true" height="20px"></ngb-progressbar>

      <div *ngIf="program.isContent">
        <h4 class="pt-3">Discover</h4>

        <div>
          <p>
            <b>{{program.displayContentFrequency}}</b>
          </p>
          <p>{{program.contentDesc}}</p>

          <div class="row pb-4">
            <div *ngFor="let item of content" class="col-12 col-md-6 col-lg-4 d-flex">
              <a *ngIf="!item.locked" class="contentAnchorTag" [routerLink]="generateGoToDetailLink(item)">

                <app-content-list-card [item]="item" [isLoggedIn]="isLoggedIn" [isProgram]="isProgram" [isLocked]="item.locked"></app-content-list-card>
              </a>
              <app-content-list-card *ngIf="item.locked" [item]="item" [isLoggedIn]="isLoggedIn" [isProgram]="isProgram"
                [isLocked]="item.locked"></app-content-list-card>

            </div>
          </div>
        </div>
      </div>
      <a *ngIf="showViewMoreButton()" (click)="viewMoreContent()" class="btn btn-primary center-button col-12 col-sm-3  mx-auto ml-2 mb-4">
        View more
      </a>

      <div *ngIf="program.isDisplayLifeActions">
        <h4 class="pt-3">Act</h4>

        <p title="The little things we do each day make the biggest difference. Done repeatedly, these become winning habits. Your habits define your future!">
          <b>{{program.displayLifeActionsFrequency}}</b>
        </p>
        <p>{{program.indicatorDesc}}</p>

        <p *ngIf="program.displayLifeActionsMessage">
          {{program.displayLifeActionsMessage}}
        </p>

        <div *ngIf="program.isLifeActionsSent" class="row align-items-stretch">
          <div *ngFor="let indicator of lifeActions; let i = index" class="col-12 col-sm-6 d-flex align-items-stretch mb-3">
            <app-active-life-action class="w-100" *ngIf=indicator.active (doLifeAction)="onDoLifeAction($event)" [indicator]="indicator"
              [index]="i"></app-active-life-action>

            <app-manage-life-action *ngIf="!indicator.active" class="w-100" (changeActivationStatus)="onChangeActivationStatus($event)"
              [indicator]="indicator" [index]="i"></app-manage-life-action>
          </div>
        </div>
      </div>

      <div *ngIf="program.isDisplayGroups && groups.length > 0">
        <h4 class="pt-3">Share</h4>
        <p>{{program.groupDesc}}</p>
        <div class="row align-items-stretch">
          <div *ngFor="let group of groups" class="col-12 col-sm-6 d-flex align-items-stretch">
            <app-group-list-card
              class="groupCard"
              [group]="group"
              [newPostCount]="group.newPostCount"
              [isMember]="group.groupMember"
              (view)="goToGroup(group.id)"
              (join)="joinGroup(group)"></app-group-list-card>
          </div>
        </div>
      </div>

      <div class="row">
        <div  class="col-6"><a (click)="goToGroupsPage()" class="btn btn-primary center-button mx-auto mt-4 ml-2 mb-4">
          View Groups
        </a></div>
        <div class="col-6">
          <a *ngIf="canPauseProgram()" (click)="pauseProgram()" class="btn btn-primary center-button mx-auto mt-4 ml-2 mb-4">
          Pause program
        </a>
        <a *ngIf="canResumeProgram()" (click)="resumeProgram()" class="btn btn-primary center-button mx-auto mt-4 ml-2 mb-4">
          Resume program
        </a></div>
      </div>

      <div *ngIf="canToggleNotifications()" class="notifications pt-2">
        <p class="pb-0 mb-0">Notifications:</p>
        <div class="inner">
          <small class="notifications-label">App</small>
          <br/>
          <small>OFF </small>
          <label class="switch mx-1">
            <input (click)="notificationsAppToggle()" [formControl]="notificationsApp" type="checkbox">
            <span class="slider round"></span>
          </label>
          <small> ON</small>
        </div>

        <div class="inner">
          <small class="notifications-label">Web</small>
          <br/>
          <small>OFF </small>
          <label class="switch mx-1">
            <input (click)="notificationsWebToggle()" [formControl]="notificationsWeb" type="checkbox">
            <span class="slider round"></span>
          </label>
          <small> ON</small>
        </div>

        <div class="inner">
          <small class="notifications-label">Email</small>
          <br/>
          <small>OFF </small>
          <label class="switch mx-1">
            <input (click)="notificationsEmailToggle()" [formControl]="notificationsEmail" type="checkbox">
            <span class="slider round"></span>
          </label>
          <small> ON</small>
        </div>
      </div>
    </div>
  </div>
</div>
