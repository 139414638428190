import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UnSubscribeComponent } from '../../_shared/un-subscribe.component';
import { MarketingBrochureService } from '../../../services/marketing-brochure.service';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../../services/user-service';
import { ICompany } from '../../_models/company';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { FEATURES } from '../../_shared/enums';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageService } from '../../../services/image.service';
import { UtilService } from '../../../services/util-service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-marketing-brochure',
  templateUrl: './marketing-brochure.component.html',
  styleUrls: ['./marketing-brochure.component.scss'],
})
export class MarketingBrochureComponent extends UnSubscribeComponent implements OnInit {
  @ViewChild('marketingBrochure') marketingBrochure: ElementRef;
  @ViewChild('customerLogo') customerLogo: ElementRef;
  company: Partial<ICompany>;
  companyId: number;
  appleHref: string;
  androidHref: string;
  hasEAPBookingFeature: boolean;
  isProcessing: boolean;
  previewMode = false;
  private jsPDF: jsPDF;

  constructor(
    private marketingBrochureService: MarketingBrochureService,
    private userService: UserService,
    private modalService: NgbModal,
    private imageService: ImageService,
    private render2: Renderer2,
    private utilService: UtilService,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.appleHref = this.userService.appDownloadLinks.apple;
    this.androidHref = this.userService.appDownloadLinks.google;
    this.activatedRoute.params.subscribe((route) => {
      this.companyId = route.id ? +route.id : this.userService.company.id;
      this.getData();
    });
  }

  async onGeneratePDF() {
    this.isProcessing = true;
    const currentDate = new Date();
    const fileName = this.company.name.toLowerCase().split(' ').join('_') + `_${+currentDate}`;
    if (!this.jsPDF) {
      await this.createPDFDoc();
    }
    this.jsPDF.save(fileName);
    this.isProcessing = false;
  }

  async onPreviewPDF() {
    const previewModal = this.modalService.open(MarketingBrochureComponent, {
      size: 'lg',
      centered: true,
      windowClass: 'marketing-brochure-preview',
    });
    previewModal.componentInstance.previewMode = true;
  }

  private async createPDFDoc() {
    if (this.company) {
      const canvas = await html2canvas(this.marketingBrochure.nativeElement, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
      });
      this.jsPDF = new jsPDF('l', 'px', [1280, 700], true);
      const image = canvas.toDataURL();
      this.jsPDF.addImage({
        imageData: image,
        x: 0,
        y: 0,
        width: 1280,
        height: 700,
        compression: 'MEDIUM',
      });
      const domainWidth = this.company.domain.length * 7;
      this.jsPDF.link(920, 360, domainWidth, 25, { url: this.company.domain });
      const hostnameWidth = this.company.hostname.length * 12.36;
      if (hostnameWidth < 445) {
        this.jsPDF.link(375, 370, hostnameWidth, 20, { url: this.company.domain });
      } else {
        this.jsPDF.link(55, 410, hostnameWidth, 20, { url: this.company.domain });
      }
      this.jsPDF.link(920, 430, 125, 40, { url: this.appleHref });
      this.jsPDF.link(1050, 430, 140, 40, { url: this.androidHref });
      this.jsPDF.setProperties({
        title: `${this.company.name} - Marketing Brochure`,
        subject: 'Marketing Brochure',
        author: 'Altius',
        creator: 'Altius',
      });
    }
  }

  private getData() {
    this.marketingBrochureService.getData(this.companyId)
      .subscribe((res) => {
        this.company = res;
        this.company.logo = `${this.userService.getLogoForCompany(this.company.logo)}?v=${this.utilService.getRandomNumber()}`;
        this.handleCustomerLogo(this.company.logo);
        this.company.domain = this.company.domain.startsWith('http') ? this.company.domain : `https://${this.company.domain}`;
        this.hasEAPBookingFeature = this.company.features
          .split(',')
          .some((f) => Number(f) === FEATURES.EAP_BOOKING);
      });
  }

  private handleCustomerLogo(logoUrl: string) {
    this.imageService
      .imageUrlToBase64(logoUrl)
      .pipe(takeUntil(this.unSubscribeOnDestroy))
      .subscribe((res) => {
        this.render2.setStyle(this.customerLogo.nativeElement, 'backgroundImage', `url("data:image/png;base64,${res}")`);
      });
  }
}
