<h5 *ngIf="!isInitialised">Loading...</h5>

<app-cms-list-view *ngIf="isDisplayingList && isInitialised" [dataList]="items" [columnList]="columns">
</app-cms-list-view>

<div *ngIf="(isAddingItem || isEditingItem) && isInitialised" class="padding jumbotron">
  <form [formGroup]="itemForm" (ngSubmit)="onSaveClick()">

    <div class="row m-0">
      <div class="col-12">
        <!-- Title -->
        <div class="form-group">
          <label class="control-label required">Title</label>
          <input type="text" class="form-control" [ngClass]="displayFieldCss('title')" formControlName="title" />
          <span class="invalid-feedback">Title is required</span>
        </div>
        <div class="form-group">
          <label class="control-label">Author (*NEW* system - including image and bio)</label>
          <select type="text" class="form-control" formControlName="authorId">
            <option *ngFor="let author of authorOptions" [value]="author.id">
              {{author.name}}
            </option>
          </select>

        </div>
        <!-- Embed Code -->
        <div class="form-group">
          <label class="control-label required">Embed Code</label>
          <textarea class="form-control" [ngClass]="displayFieldCss('embedCode')" formControlName="embedCode">
          </textarea>
          <span class="invalid-feedback">Embed Code is required</span>
        </div>

        <!-- Source Url -->
        <div class="form-group">
          <label class="control-label">Source URL</label>
          <input type="text" class="form-control" formControlName="sourceUrl" />
        </div>

        <!-- Short Text -->
        <div class="form-group">
          <label class="control-label required">Short Text</label>
          <textarea class="form-control" [ngClass]="displayFieldCss('shortText')" formControlName="shortText">
          </textarea>
          <span class="invalid-feedback">Short Text is required</span>
        </div>

        <!-- Long Text -->
        <div class="form-group">
          <label class="control-label">Long Text</label>
          <textarea class="form-control" formControlName="longText">
          </textarea>
        </div>

        <!--- Feature -->
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label class="control-label required">Feature</label>
              <select id="featureId" class="form-control custom-select" (change)="onFeatureIdChange(itemForm.get('featureId').value)"
                formControlName="featureId">
                <option *ngIf="!itemForm.get('featureId').value" value="null" selected>Select a Feature</option>
                <option *ngFor="let item of features" [value]="item.id">{{item.name | featureName}}
                </option>
              </select>
              <span class="invalid-feedback">Feature is required</span>
            </div>
          </div>

          <!--- Visibility -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label class="control-label required">Visibility</label>
              <select class="form-control" [ngClass]="displayFieldCss('visibility')" formControlName="visibility">
                <option *ngFor="let item of visibilities" [value]="item.id">{{item.name}}</option>
              </select>
              <span class="invalid-feedback">a Visibility is required</span>
            </div>
          </div>
        </div>

        <!-- selectable categories -->
        <div class="form-group">
          <label class="control-label">Selectable Categories</label>
          <div class="row">
            <div class="col-6 col-sm-4" *ngFor="let item of selectableCategories">
              <div class="custom-control">
                <input id="{{item.id}}" type="checkbox" (click)="selectSelectableCategory(item.id)" class="custom-control-input">
                <label class="custom-control-label" for="{{item.id}}">{{item.name}}</label>
              </div>
            </div>
          </div>
        </div>

        <!-- selected categories -->
        <div class="form-group">
          <label for="selectedCategories" class="control-label required">Selected Categories</label>
          <div class="row">
            <input id="categories" formControlName="categories" hidden>
          </div>
          <div class="row">
            <div class="col-4" *ngFor="let item of selectedCategories">
              <div class="custom-control custom-checkbox">
                <input id="{{item.id}}" type="checkbox" checked="true" (click)="unselectSelectedCategory(item.id)"
                  [class]="isCategoriesInvalid() ? ' custom-control-input is-invalid' : 'custom-control-input' ">
                <label class="custom-control-label" for="{{item.id}}">{{item.name}}</label>
              </div>
            </div>
            <span *ngIf="!selectedCategories.length" class="col-12 text-danger">
              Select a category.
            </span>
          </div>
        </div>

        <br />

        <!-- Company -->
        <!-- <div class="form-group" *ngIf="canSelectCompany">
          <app-select-company [allCompanies]="allCompanies" [selectedCompanyId]="itemForm.get('companyId').value"
            [label]="'Company'" [placeholder]="'All (default)'" (companyIdSelected)="onCompanySelected($event)"></app-select-company>
        </div> -->

        <!-- Excluded Companies -->
        <!-- <div *ngIf="isAllCompanies()" class="form-group">
          <app-select-companies [allCompanies]="allCompanies" [selectedCompanyIds]="excludedCompanyIds" [label]="'Excluded Companies'"
            [placeholder]="'Select Company to exclude'" (companyIdsSelected)="excludedCompanyIdsSelected($event)"></app-select-companies>
        </div> -->

        <div class='form-group' *ngIf='showIncludedCompanies()'>
          <multiple-select [allOptions]='allCompanies' *ngIf='canSelectCompany' [selectedOptionIds]='includedCompanyIds' [label]="'Companies'"
            [placeholder]="'-- Select company to include --'" (optionIdsSelected)='includedCompanyIdsSelected($event)'
            [addAllOption]='true' [allOptionLabel]='"All (default)"'>
          </multiple-select>
        </div>

        <!-- Excluded Companies -->
        <div *ngIf='showExcludedCompanies()' class='form-group'>
          <multiple-select [allOptions]='allCompanies' *ngIf='canSelectCompany' [selectedOptionIds]='excludedCompanyIds' [label]="'Excluded Companies'"
            [placeholder]="'-- Select company to exclude --'" (optionIdsSelected)='excludedCompanyIdsSelected($event)'>
          </multiple-select>
        </div>
      </div>
    </div>

    <!-- Published -->
    <div class="col-12 col-sm-6">
      <div class="form-group">
        <label class="control-label required">Publish</label>
        <br />
        <div class="custom-control custom-radio custom-control-inline mt-2">
          <input type="radio" id="published1" value="true" class="custom-control-input" formControlName="published" />
          <label class="custom-control-label" for="published1">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="published2" value="false" class="custom-control-input" formControlName="published" />
          <label class="custom-control-label" for="published2">No</label>
        </div>
        <span class="invalid-feedback">
          <br /> Publish is required.
        </span>
      </div>
    </div>

    <!-- Preview -->
    <hr />
    <div class="previewButton pt-1 pb-3">
      <button type="button" data-toggle="tooltip" data-placement="top" title="Preview" class="btn btn-primary btn-circle" [disabled]="!itemForm.valid"
        (click)="showPreview()">
        <div class="text">
          <i class="fa fa-eye fa-2x" aria-hidden="true"></i>
          <p>Preview</p>
        </div>
      </button>
    </div>

    <!-- Buttons -->
    <div class="row">
      <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
        <button type="button" class="btn btn-outline-primary w-100" (click)="onBackClick()">
          <i class="fa fa-undo mr-2" aria-hidden="true"></i> BACK
        </button>
      </div>

      <div *ngIf="isEditingItem" class="col-4">
        <button type="button" class="btn btn-outline-primary w-100" [disabled]="!canRespondToButtons" (click)="onDeleteClick()">
          <i class="fa fa-trash mr-2" aria-hidden="true"></i> DELETE
        </button>
      </div>

      <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
        <button type="submit" class="btn btn-primary float-right w-100" [disabled]="!canRespondToButtons || !itemForm.valid">
          <i class="fa fa-floppy-o mr-2" aria-hidden="true"></i> SAVE
        </button>
      </div>
    </div>
  </form>
</div>
