import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IInfoPack } from '../app/_models/info-pack';

@Injectable({
  providedIn: 'root',
})
export class InfoPacksService {
  constructor(private http: HttpClient) {
  }

  getInfoPacksForMyCompany(lang: string) {
    return this.http.get<any>(`/cms/information-packs/me/${lang}`);
  }

  getInfoPack(id) {
    return this.http.get<any>(`/cms/information-packs/${id}`);
  }

  addInfoPack(body: IInfoPack) {
    return this.http.post<any>(`/cms/information-packs/`, body);
  }

  updateInfoPack(body: IInfoPack) {
    return this.http.put<any>(`/cms/information-packs/${body.id}`, body);
  }

  deleteInfoPack(id) {
    return this.http.delete<any>(`/cms/information-packs/${id}`);
  }

  getInfoPacksByCompany(companyId) {
    return this.http.get<any>(`/cms/information-packs/all/${companyId}`);
  }

  getNewInfoPack(): IInfoPack {
    return {
      title: '',
      description: '',
      image: '',
      file: '',
    };
  }
}
