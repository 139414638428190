import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICompany } from '../app/_models/company';

@Injectable({
  providedIn: 'root',
})
export class BookingPosterService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getData(companyId: number) {
    const url = `/cms/booking-poster/company?companyId=${companyId}`;
    return this.http.get<ICompany>(url);
  }
}
