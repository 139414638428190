import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VideoService } from '../../../services/video-service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UtilService } from '../../../services/util-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IVideo } from '../../_models/video';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { ContentDetailComponent } from '../../_shared/components/content-detail/content-detail.component';
import { IIdNamePair } from '../../_models/id-name-pair';
import { IIdNameSelected } from '../../_models/id-name-selected';
import { CompanyService } from '../../../services/company-service';
import { FeatureService } from '../../../services/feature-service';
import { CategoryService } from '../../../services/category-service';
import { IAuthor } from '../../_models/author';
import { AuthorService } from '../../../services/author-service';
import { COMPANIES_CMS_FEATURE_TYPES } from '../../_shared/enums';
import { UserService } from '../../../services/user-service';

@Component({
  selector: 'app-video-management',
  templateUrl: './video-management.component.html',
  styleUrls: ['./video-management.component.scss'],
})
export class VideoManagementComponent implements OnInit {
  items: IVideo[];
  item: IVideo;
  itemType = 'Video';
  columns = [];
  itemForm: FormGroup;
  features: any;
  allCompanies: IIdNamePair[] = [];
  categories: IIdNameSelected[] = [];
  includedCompanyIds: number[] = [];
  excludedCompanyIds: number[] = [];
  selectedCategories: IIdNameSelected[] = [];
  selectableCategories: IIdNameSelected[] = [];
  visibilities: IIdNamePair[] = [];
  selectedFeatureId = null;
  selectedVisibility = 2;

  isDisplayingList: boolean;
  isInitialised: boolean;
  isEditingItem: boolean;
  isAddingItem: boolean;
  canRespondToButtons: boolean;

  authorOptions: IAuthor[];

  private canSelectCompany = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private itemService: VideoService,
    private companyService: CompanyService,
    private categoryService: CategoryService,
    private featureService: FeatureService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private modalService: NgbModal,
    private authorService: AuthorService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.categoryService.refreshCache(true);
    this.activatedRoute.params.subscribe((route) => {
      this.analyseRoute(route);

      if (this.isDisplayingList) {
        this.getList();
      } else if (this.isEditingItem) {
        this.setUserRoleAuthorisations();
        this.getCompanies();
        this.getFeatures();
        this.initialiseEditItem(+route.id);
      } else if (this.isAddingItem) {
        this.setUserRoleAuthorisations();
        this.getCompanies();
        this.getFeatures();
        this.initialiseAddItem();
      }
    });
  }

  private setUserRoleAuthorisations() {
    this.canSelectCompany = this.userService.isSuperAdminUser();
  }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isEditingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isEditingItem = true;
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  showPreview() {
    if (this.itemForm.valid) {
      const item = this.itemForm.value;
      item.isVideo = true;
      const previewModal = this.modalService.open(ContentDetailComponent, { size: 'lg' });
      previewModal.componentInstance.previewContent = item;
    } else {
      this.validateAllFormFields(this.itemForm);
    }
  }

  private getList() {
    this.itemService.getAllVideos().subscribe(
      (data) => {
        this.items = data.map((i) => {
          return [i.id, i.title, this.utilService.formatAsYesNo(i.published)];
        });

        this.columns = ['id', 'title', 'published'];
        this.isInitialised = true;
        this.canRespondToButtons = true;
      },
      (error) => this.utilService.showToastError('Error while loading items - ' + error),
    );
  }

  private getCompanies() {
    this.companyService.getAllCompanies()
      .subscribe(data => {
          this.allCompanies = data.map(c => {
            return { id: c.id, name: c.name };
          });
        },
        () => this.utilService.showToastError('Error while loading companies list'),
      );
  }

  private initialiseEditItem(id: number): void {
    this.itemService.getVideoById(id)
      .subscribe((data) => {
        this.item = data;
        if (data.categories.length) {
          this.selectedVisibility = data.categories[0].visibility;
          this.selectedFeatureId = data.categories[0].featureId;
          this.setSelectedCategories(data.categories);
          this.setSelectableCategories(this.selectedFeatureId);
        }
        this.initialiseEditableFormGroup(data);
        this.isInitialised = true;
        this.canRespondToButtons = true;
      });
  }

  private initialiseAddItem(): void {
    this.item = this.itemService.getNewVideo();
    if (!this.canSelectCompany) {
      this.item.includedCompanyIds = [this.userService.company.id];
    }
    this.initialiseEditableFormGroup(this.item);
    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  private initialiseEditableFormGroup(item: IVideo) {
    this.visibilities = this.utilService.visibilities();
    this.getAuthors();

    this.itemForm = this.fb.group({
      title: [item.title, Validators.required],
      embedCode: [item.embedCode, Validators.required],
      shortText: [item.shortText, Validators.required],
      longText: item.longText,
      image: item.image,
      sourceUrl: item.sourceUrl,
      published: [this.item.published.toString(), Validators.required],
      companyId: item.companyId,
      featureId: [this.selectedFeatureId, Validators.required],
      visibility: [this.selectedVisibility, Validators.required],
      authorId: item.authorId,
      categories: [this.selectedCategories.join(','), Validators.required],
    });

    this.includedCompanyIds = item.includedCompanyIds;
    this.excludedCompanyIds = item.excludedCompanyIds;
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  getAuthors() {
    this.authorService.getAllAuthors().subscribe(authors => {
      this.authorOptions = authors;
    });
  }

  isAllCompanies() {
    return !this.itemForm.get('companyId').value && this.canSelectCompany;
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  isCategoriesInvalid() {
    return (
      this.itemForm.get('featureId').valid
      && this.itemForm.get('featureId').touched
      && !this.selectedCategories.length
    );
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  private navigateToList() {
    this.router.navigate(['/cms/videos']);
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Delete ' + this.itemType;
    modal.componentInstance.message = 'Confirm deleting this ' + this.itemType;
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(
      (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }

        this.itemService.deleteVideo(this.item.id).subscribe(
          () => {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`${this.itemType} - ${this.item.title} - deleted`);
            this.navigateToList();
          },
          (error) => this.utilService.showToastError('Error while deleting item - ' + error),
        );
      },
      () => {
      },
    );
  }

  private persistItem() {
    this.canRespondToButtons = false;
    let itemToPersist: IVideo;
    let body: any;

    try {
      itemToPersist = {
        title: this.itemForm.get('title').value,
        embedCode: this.itemForm.get('embedCode').value,
        shortText: this.itemForm.get('shortText').value,
        longText: this.itemForm.get('longText').value,
        image: this.itemForm.get('image').value,
        sourceUrl: this.itemForm.get('sourceUrl').value,
        published: this.itemForm.get('published').value,
        // companyId: this.userService.company.id,
        excludedCompanyIds: this.excludedCompanyIds,
        includedCompanyIds: this.includedCompanyIds,
        authorId: this.itemForm.get('authorId').value,
      };
      if (this.item.id !== 0) {
        itemToPersist.id = this.item.id;
      }
      body = {
        video: itemToPersist,
        categories: this.selectedCategories.map((c) => c.id),
        visibility: this.itemForm.get('visibility').value,
      };
    } catch (error) {
      this.utilService.showToastError('Error persisting item - ' + error);
      this.canRespondToButtons = true;
      return;
    }

    if (this.isEditingItem) {
      this.updateItem(this.item.id, body);
    } else if (this.isAddingItem) {
      this.addNewItem(body);
    }
  }

  private updateItem(id, itemToPersist: IVideo) {
    this.itemService.updateVideo(id, itemToPersist).subscribe(
      () => {
        this.itemForm.reset();
        this.canRespondToButtons = true;
        this.utilService.showToastSuccess(`Updated ${this.itemType} - ${itemToPersist.title}`);
        this.navigateToList();
      },
      (error) => {
        this.utilService.showToastError(`Error while saving item - ${error}`);
        this.canRespondToButtons = true;
      },
    );
  }

  private addNewItem(body) {
    this.itemService.addVideo(body).subscribe(
      () => {
        this.itemForm.reset();
        this.canRespondToButtons = true;
        this.utilService.showToastSuccess(`Added new ${this.itemType} - ${body.video.title}`);
        this.navigateToList();
      },
      (error) => {
        this.utilService.showToastError(`Error while adding item - ${error}`);
        this.canRespondToButtons = true;
      },
    );
  }

  getFeatures() {
    this.featureService
      .getAllFeaturesByType(COMPANIES_CMS_FEATURE_TYPES.DISCOVER)
      .subscribe((data) => {
        this.features = (data || []).filter(
          (f) => this.categoryService.getCachedCategoriesForFeatureId(f.id).length > 0,
        );
      }, () => (this.features = []));
  }


  setSelectedCategories(categories) {
    this.selectedCategories = categories.map((c) => {
      c.name = this.categoryService.getCategoriesName(c.name);
      return { id: c.id, name: c.name, selected: true };
    });
  }

  setSelectableCategories(featureId: number) {
    const featureCategories = this.categoryService
      .getCachedCategoriesForFeatureId(+featureId);
    const selectedCategoryIds = this.selectedCategories
      .map((c) => c.id);

    this.selectableCategories = featureCategories
      .filter((c) => selectedCategoryIds.indexOf(c.id) < 0)
      .map((c) => {
        c.name = this.categoryService.getCategoriesName(c.name);
        return { id: c.id, name: c.name, selected: false };
      });
  }

  unselectSelectedCategory(categoryId: number) {
    this.selectedCategories = this.selectedCategories
      .filter((c) => c.id !== +categoryId);

    const featureId = this.itemForm.get('featureId').value;
    this.updateSelectedCategoriesFormValue();
    this.setSelectableCategories(featureId);
  }

  selectSelectableCategory(categoryId: number) {
    const selectedCategory = this.selectableCategories
      .find((c) => c.id === +categoryId);
    if (!selectedCategory) {
      return;
    }

    this.selectedCategories.push(selectedCategory);
    this.updateSelectedCategoriesFormValue();
    this.selectableCategories = this.selectableCategories
      .filter((c) => c.id !== +categoryId);
  }

  onFeatureIdChange(featureId: number) {
    this.setSelectableCategories(+featureId);
  }

  // onCompanySelected(companyId) {
  //   this.itemForm.patchValue({ companyId: companyId });
  // }

  onBackClick() {
    this.navigateToList();
  }

  onDeleteClick() {
    if (!this.isEditingItem) {
      return;
    }

    this.deleteItem();
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }

    this.persistItem();
  }

  showExcludedCompanies() {
    return this.canSelectCompany && this.includedCompanyIds.length == 0;
  }

  showIncludedCompanies() {
    return this.canSelectCompany && this.excludedCompanyIds.length == 0;
  }

  excludedCompanyIdsSelected(selectedCompanyIds: number[]) {
    this.excludedCompanyIds = selectedCompanyIds;
    if (selectedCompanyIds.length > 0) {
      this.includedCompanyIds = [];
    }
  }

  includedCompanyIdsSelected(selectedCompanyIds: number[]) {
    this.includedCompanyIds = selectedCompanyIds;
    if (selectedCompanyIds.length > 0) {
      this.excludedCompanyIds = [];
    }
  }

  updateSelectedCategoriesFormValue() {
    const categoriesFormField = this.itemForm && this.itemForm.get('categories');
    if (!categoriesFormField) {
      return;
    }
    categoriesFormField.setValue(this.selectedCategories.join(','));
  }
}
