import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { LifeActionService } from '../../../services/life-action-service';
import { ILifeAction } from '../../_models/life-action';

@Component({
  selector: 'app-life-action-widget',
  templateUrl: './life-action-widget.component.html',
  styleUrls: ['./life-action-widget.component.scss'],
})
export class LifeActionWidgetComponent implements OnChanges {
  @Input() lifeAction: ILifeAction;
  @Output() addLifeAction: EventEmitter<number> = new EventEmitter<number>();

  lifeActionRecurrenceString: string;

  constructor(private lifeActionService: LifeActionService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setLifeActionRecurrenceString();
  }

  private setLifeActionRecurrenceString() {
    if (!this.lifeAction) {
      return '';
    }

    this.lifeActionRecurrenceString
      = this.lifeActionService.formatRecurrenceIntegersAsStrings(this.lifeAction.recurrence);
  }

  onAddLifeActionClick() {
    this.addLifeAction.emit(this.lifeAction.id);
  }
}
