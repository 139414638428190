import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ICMSPartner } from '../../_models/cms-partner';
import { ICategory } from '../../_models/category';
import { PartnerService } from '../../../services/partner-service';
import { UtilService } from '../../../services/util-service';
import { FilestackService } from '../../../services/filestack-service';
import { CompanyService } from '../../../services/company-service';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { UserService } from '../../../services/user-service';
import { IIdNamePair } from '../../_models/id-name-pair';
import { CategoryService } from '../../../services/category-service';

@Component({
  selector: 'app-partner-management',
  templateUrl: './partner-management.component.html',
  styleUrls: ['./partner-management.component.scss'],
})
export class PartnerManagementComponent implements OnInit {
  items: any[];
  item: ICMSPartner;
  itemType = 'Partner';
  columns = [];
  itemForm: FormGroup;
  companies = [];
  allCompanies: IIdNamePair[] = [];
  excludedCompanyIds: number[] = [];
  additionalNumericColumns = [];
  categories: ICategory[];
  formFieldsItems: { id: number, name: string, type: number, checked: boolean }[] = [];
  private options: Object = {
    events: {
      'froalaEditor.link.beforeInsert': function(e, editor, link, text, attrs) {
        if (link.indexOf('://') < 0) {
          attrs['data-contenttype'] = link.split('/').slice(-2)[0];
          attrs['data-internallink'] = true;
          attrs['data-contentid'] = link.split('/').slice(-1)[0];
        }
      },
    },
    placeholderText: 'Edit Content Here',
    paragraphFormat: {
      N: 'Normal',
      H1: 'Heading 1',
      H2: 'Heading 2',
      H3: 'Heading 3',
      H4: 'Heading 4',
      H5: 'Heading 5',
      H6: 'Heading 6',
    },
    linkList: [
      {
        text: 'Article Link (update)',
        href: `/home/article/:id`,
      },
      {
        text: 'Recipe Link (update)',
        href: `/home/recipe/:id`,
      },
      {
        text: 'Video Link (update)',
        href: `/home/video/:id`,
      },
      {
        text: 'External Link',
        href: 'https://',
      },
    ],
    toolbarSticky: false,
    key: this.userService.getFroalaApiKey(),
  };


  isDisplayingList: boolean;
  isInitialised: boolean;
  isEditingItem: boolean;
  isAddingItem: boolean;
  canRespondToButtons: boolean;
  public initControls: any;

  private canSelectCompany = false;


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private itemService: PartnerService,
    private companyService: CompanyService,
    private utilService: UtilService,
    private fb: FormBuilder,
    private fileStackService: FilestackService,
    private ngZone: NgZone,
    private modalService: NgbModal,
    private userService: UserService,
    private categoryService: CategoryService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(route => {
      this.analyseRoute(route);

      if (this.isDisplayingList) {
        this.getList();
      } else if (this.isEditingItem) {
        this.isInitialised = false;
        this.canRespondToButtons = false;
        this.setUserRoleAuthorisations();
        this.initialiseEditItem(+route.id);
      } else if (this.isAddingItem) {
        this.isInitialised = false;
        this.canRespondToButtons = false;
        this.setUserRoleAuthorisations();
        this.initialiseAddItem();
      }
    });
  }

  private setUserRoleAuthorisations() {
    this.canSelectCompany = this.userService.isSuperAdminUser();
  }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isEditingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isEditingItem = true;
    }
  }

  private getList() {
    this.itemService.getCMSPartners()
      .subscribe(data => {
          this.items = data.map((i: ICMSPartner) => {
            return [
              i.id,
              i.name,
              i.title,
              i.contactEmail,
            ];
          });

          this.columns = ['id', 'name', 'title', 'contact email'];
          this.isInitialised = true;
          this.canRespondToButtons = true;
        },
        (err) => {
          this.utilService.showToastError('Error while loading groups', err);
        },
      );
  }

  getCategories() {
    this.categoryService.getCategories().subscribe(authors => {
      this.categories = authors.map(c => {
        c.name = this.categoryService.getCategoriesName(c.name);
        return c;
      });
    });
  }

  getFormFields() {
    this.formFieldsItems.push({ id: 1, name: 'firstName', type: 1, checked: false });
    this.formFieldsItems.push({ id: 2, name: 'lastName', type: 1, checked: false });
    this.formFieldsItems.push({ id: 3, name: 'email', type: 1, checked: false });
    this.formFieldsItems.push({ id: 4, name: 'postCode', type: 1, checked: false });
    this.formFieldsItems.push({ id: 5, name: 'address', type: 1, checked: false });
    this.formFieldsItems.push({ id: 6, name: 'suburb', type: 1, checked: false });
    this.formFieldsItems.push({ id: 7, name: 'state', type: 1, checked: false });
    this.formFieldsItems.push({ id: 8, name: 'comments', type: 1, checked: false });
    this.formFieldsItems.push({ id: 9, name: 'conditions', type: 1, checked: false });
    this.formFieldsItems.push({ id: 10, name: 'phone', type: 1, checked: false });
  }

  private initialiseAddItem() {
    this.getCompanies();
  }

  private initialiseEditItem(id) {
    this.getCompanies(id);
  }

  private getCompanies(id?: number) {
    this.companyService.getAllCompanies()
      .subscribe(data => {
          this.companies = data;
          this.allCompanies = this.companies.map(c => {
            return { id: c.id, name: c.name };
          });

          if (id) {
            this.getItem(id);
          } else {
            this.getNewItem();
          }
        },
        (err) => {
          this.companies = [];
          this.utilService.showToastError('Error while loading companies', err);
        },
      );
  }

  private getNewItem() {
    this.item = this.itemService.getNewPartner();
    this.initialiseEditableFormGroup(this.item);
    this.isInitialised = true;
  }

  private getItem(id: number) {
    this.itemService.getPartnerById(id)
      .subscribe(data => {
          this.item = data;
          this.initialiseEditableFormGroup(data);
          this.isInitialised = true;
          this.canRespondToButtons = true;
        },
        (err) => {
          this.utilService.showToastError('Error while getting partner for id: ' + id, err);
        },
      );
  }

  private initialiseEditableFormGroup(item) {
    this.getFormFields();
    if (item.formFields) {
      this.checkValues(this.formFieldsItems, item.formFields);
    }
    this.getCategories();
    const urlRegex = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.itemForm = this.fb.group({
      name: [item.name, Validators.required],
      contactEmail: [item.contactEmail, Validators.required],
      contactPhone: item.contactPhone,
      logo: [item.logo, Validators.required],
      image: item.image,
      formFields: item.formFields,
      title: [item.title, Validators.required],
      shortText: [item.shortText, Validators.required],
      longText: [item.longText, Validators.required],
      termsOfService: item.termsOfService,
      privacyPolicy: item.privacyPolicy,
      categoryId: item.categoryId,
      conditionsText: item.conditionsText,
      website: [item.website, Validators.pattern(urlRegex)],
      companyId: item.companyId,
    });

    this.excludedCompanyIds = this.isAllCompanies()
      ? item.excludedCompanyIds
      : [];
    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  isAllCompanies() {
    try {
      return this.itemForm.get('companyId')
        && !this.itemForm.get('companyId').value
        && this.canSelectCompany;
    } catch (error) {
      return false;
    }
  }

  onEmailChange() {
    this.validateEmail();
  }

  private validateEmail() {
    const contactEmail = this.itemForm.get('contactEmail');

    contactEmail.clearValidators();
    if (contactEmail.value) {
      contactEmail.setValidators(Validators.email);
    }

    contactEmail.updateValueAndValidity();
  }

  public initialize(initControls) {
    this.itemService.getS3Hash()
      .subscribe(hash => {
        this.options['imageUploadToS3'] = hash;
        this.initControls = initControls;
        this.initControls.initialize();
      }, error => this.utilService.showToastError('Error while uploading items - ' + error));
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  onCompanySelected(companyId) {
    this.itemForm.patchValue({ companyId: companyId });
  }

  excludedCompanyIdsSelected(selectedCompanyIds: number[]) {
    this.excludedCompanyIds = selectedCompanyIds;
  }

  onBackClick() {
    this.navigateToList();
  }

  private navigateToList() {
    this.router.navigate(['/cms/partners']);
  }

  onDeleteClick() {
    if (!this.isEditingItem) {
      return;
    }

    this.deleteItem();
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Confirm deleting this partner';
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(
      (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }
        this.itemService.deletePartner(this.item.id)
          .subscribe(() => {
              this.itemForm.reset();
              this.utilService.showToastSuccess(`Partner - ${this.item.name} - deleted`);
              this.itemService.notifyPartnersChanged();
              this.navigateToList();
            },
            (error) => this.utilService.showToastError('Error while deleting item - ' + error),
          );
      },
      () => {
      },
    );
  }

  onLogoClick() {
    this.fileStackService
      .pickPartnerlImg()
      .then((result) => this.ngZone.run(() => this.itemForm.patchValue({ logo: result })))
      .catch((err) => this.utilService.showToastError('Error while uploading the logo', err));
  }

  checkValues(items, itemsSelected) {
    if (!Array.isArray(itemsSelected)) {
      itemsSelected = itemsSelected.split(',').map(String);
    }
    items.forEach(f => {
      f.checked = itemsSelected.indexOf(f.name) > -1;
    });
  }

  isValidFormFieldCheckbox(qttMinSelected, control) {
    if (this.itemForm.get(control).value) {
      const controlValues = this.itemForm.get(control).value.filter(i => i);
      return !this.itemForm.get(control).touched ||
        (this.itemForm.get(control).touched && controlValues &&
          controlValues.length > qttMinSelected);
    } else {
      return false;
    }
  }

  setFormFields(item) {
    item.checked = !item.checked;
    this.item.formFields = this.formFieldsItems.filter(p => p.checked).map(c => c.name).join(',');
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }

    this.persistItem();
  }

  private persistItem() {
    this.canRespondToButtons = false;
    let itemToPersist: ICMSPartner;

    try {
      itemToPersist = {
        id: this.item.id,
        name: this.itemForm.get('name').value,
        categoryId:
          +this.itemForm.get('categoryId').value > 0
            ? +this.itemForm.get('categoryId').value
            : null,
        companyId:
          +this.itemForm.get('companyId').value > 0
            ? +this.itemForm.get('companyId').value
            : null,
        logo: this.itemForm.get('logo').value,
        excludedCompanyIds: this.isAllCompanies()
          ? this.excludedCompanyIds
          : [],
        conditionsText: this.itemForm.get('conditionsText').value,
        contactEmail: this.itemForm.get('contactEmail').value,
        contactPhone: 'placeholder.phone',
        formFields: this.item.formFields,
        image: this.itemForm.get('logo').value,
        longText: this.itemForm.get('longText').value,
        privacyPolicy: this.itemForm.get('privacyPolicy').value,
        shortText: this.itemForm.get('shortText').value,
        termsOfService: this.itemForm.get('termsOfService').value,
        title: this.itemForm.get('title').value,
        website: this.itemForm.get('website').value,
      };
      if (!this.canSelectCompany) {
        itemToPersist.companyId = this.userService.company.id;
      }

    } catch (error) {
      this.utilService.showToastError('Error persisting - ' + error);
      this.canRespondToButtons = true;
      return;
    }

    if (this.isEditingItem) {
      this.updateItem(itemToPersist);
    } else if (this.isAddingItem) {
      this.addNewItem(itemToPersist);
    }
  }

  private addNewItem(itemToPersist: ICMSPartner) {
    this.itemService.addPartner(itemToPersist)
      .subscribe(() => {
          this.itemForm.reset();
          this.canRespondToButtons = true;
          this.utilService.showToastSuccess(`Added new partner - ${itemToPersist.name}`);
          this.itemService.notifyPartnersChanged();
          this.navigateToList();
        },
        (error) => {
          this.utilService.showToastError(`Error while saving partner - ${error}`);
          this.canRespondToButtons = true;
        },
      );
  }

  private updateItem(itemToPersist: ICMSPartner) {
    this.itemService.updatePartner(itemToPersist)
      .subscribe(() => {
          this.itemForm.reset();
          this.canRespondToButtons = true;
          this.utilService.showToastSuccess(`Updated partner - ${itemToPersist.name}`);
          this.itemService.notifyPartnersChanged();
          this.navigateToList();
        },
        (error) => {
          this.utilService.showToastError(`Error while updating partner - ${error}`);
          this.canRespondToButtons = true;
        },
      );
  }

  showPreview() {
    // const item = this.itemForm.value;

    // const previewModal = this.modalService.open(PartnerDetailComponent, { size: 'lg' });
    // previewModal.componentInstance.previewContent = item;
  }
}
