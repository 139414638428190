<div *ngIf="activatedRoute.children.length === 0">
  <div class="jumbotron">
    <h5 class="settings-headings">{{message}} {{user.username}}!</h5>
    <hr class="my-4">
    <p>Please use this space to manage account settings such as your profile or subscriptions</p>
  </div>
  <hr />
</div>
<div *ngIf="activatedRoute.children.length > 0">
  <router-outlet></router-outlet>
</div>