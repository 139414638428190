import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateService } from './date.service';
import { UserService } from './user-service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  partner: any;
  private chartColors: string[] = [
    '#16b5c7',
    '#E99550',
    '#C8D92B',
    '#B1DCE8',
    '#DD985D',
    '#E5E9A0',
    '#B2B4B7',
    '#16b5c7',
    '#E99550',
    '#C8D92B',
    '#B1DCE8',
    '#DD985D',
    '#E5E9A0',
    '#B2B4B7'
  ];

  private _companyId: number;
  private _cmsStartDate: Date;
  private _cmsEndDate: Date;

  constructor(
    private http: HttpClient,
    private dateService: DateService,
    private userService: UserService
  ) {
    this._companyId = this.userService.company.id;
    this.initialiseCmsDateRange();
  }

  private initialiseCmsDateRange() {
    this._cmsEndDate = this.dateService.getDate();
    this._cmsStartDate = this.dateService
      .addMonths(this._cmsEndDate, -1);
  }

  get cmsCompanyId(): number {
    return this._companyId;
  }

  set cmsCompanyId(value: number) {
    this._companyId = value;
  }

  get cmsStartDate(): Date {
    return this._cmsStartDate;
  }

  set cmsStartDate(value: Date) {
    this._cmsStartDate = value;
  }

  get cmsEndDate(): Date {
    return this._cmsEndDate;
  }

  set cmsEndDate(value: Date) {
    this._cmsEndDate = value;
  }

  getChartColors(): string[] {
    return this.chartColors.slice();
  }

  getAllUsersCountPerSystemRole(companyId: number, body) {
    if (this.userService.isSuperAdminUser() || this.userService.isAdminUser()) {
      return this.getAllUsersCount(companyId, body);
    }

    return this.getMyUsersCount(body);
  }

  getAllUsersCount(id: number, body) {
    const url = `/adminReports/users/all/${id}`;

    return this.http.post<any>(url, body);
  }

  getMyUsersCount(body) {
    const url = `/adminReports/users/all/me`;

    return this.http.post<any>(url, body);
  }

  getCategoriesArticlesViews(companyId: number, startDate: Date, endDate: Date) {
    const startDateYmd = this.dateService.formatYYYY_MM_DD(startDate);
    const endDateYmd = this.dateService.formatYYYY_MM_DD(endDate);
    const url = '/adminReports/content/categoriesArticlesViews';
    const body = {
      companyId,
      startDate: startDateYmd,
      endDate: endDateYmd
    };

    return this.http.post<any>(url, body);
  }

  getMostViewedPartners(feature) {
    const url = `/adminReports/partners/${feature}`;
    return this.http.get<any>(url);
  }

  getSummary(date) {
    const url = '/reports/summary';
    return this.http.post<any>(url, { date: date });
  }

  private getDateRangeQueryString(startDate: Date, endDate: Date) {
    const startDateYmd = this.dateService.formatYYYY_MM_DD(startDate);
    const endDateYmd = this.dateService.formatYYYY_MM_DD(endDate);
    return `start=${startDateYmd}&end=${endDateYmd}`;
  }

  getTmhCompanyDailySummary(companyId: number, startDate: Date, endDate: Date) {
    const startEndDateQuery = this.getDateRangeQueryString(startDate, endDate);
    const companyIdValue = companyId ? companyId : 0;
    const preQuery = (this.userService.isSuperAdminUser() || this.userService.isAdminUser())
      ? `/indicators/trackers/companydailysummary/${companyIdValue}`
      : `/indicators/trackers/companydailysummary/me`;
    const url = `${preQuery}?${startEndDateQuery}`;

    return this.http.get<any>(url);
  }

  getGoogleUsers(company, body) {
    const url = `/adminReports/googleAnalytics/monthlyUserTraffic/${company}`;
    return this.http.post<any>(url, body);
  }

  getGoogleDeviceSessionData(company, body) {
    const url = `/adminReports/googleAnalytics/deviceSessionData/${company}`;
    return this.http.post<any>(url, body);
  }

  getGooglePopularPageViews(company, body) {
    const url = `/adminReports/googleAnalytics/popularPages/${company}`;
    return this.http.post<any>(url, body);
  }

  getGoogleUniquePageTotal(company, body) {
    const url = `/adminReports/googleAnalytics/pageTotal/${company}`;
    return this.http.post<any>(url, body);
  }

  getGoogleAvgTimeOnPage(company, body) {
    const url = `/adminReports/googleAnalytics/avgTime/${company}`;
    return this.http.post<any>(url, body);
  }

  getGooglePageViewTotal(company, body) {
    const url = `/adminReports/googleAnalytics/totalPageViews/${company}`;
    return this.http.post<any>(url, body);
  }

  getGoogleTopArticleTotal(company, body) {
    const preQuery = (this.userService.isSuperAdminUser() || this.userService.isAdminUser())
      ? `/adminReports/googleAnalytics/topArticles/${company}`
      : '/adminReports/googleAnalytics/topArticles/me';
    const url = `${preQuery}`;
    return this.http.post<any>(url, body);
  }
}
