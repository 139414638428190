import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IChallenge } from '../app/_models/challenge';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChallengeService {

  private challengesChangedSubject = new BehaviorSubject<any>(null);
  challengesChanged$: Observable<null> = this.challengesChangedSubject.asObservable();

  constructor(
    private http: HttpClient,
  ) {
  }

  addChallenge(body) {
    const url = `/cms/challenges`;
    return this.http.post<any>(url, body);
  }

  updateChallenge(body, id) {
    const url = `/cms/challenges/${id}`;
    return this.http.put<any>(url, body);
  }

  getAllChallenges() {
    const url = `/cms/challenges`;
    return this.http.get<any>(url);
  }

  getAllChallengesForReport() {
    const url = `/cms/challenges/report`;
    return this.http.get<any>(url);
  }

  getChallengesById(id) {
    const url = `/cms/challenges/${id}`;
    return this.http.get<any>(url);
  }

  getAvailableChallenges() {
    const url = `/challenge/available`;
    return this.http.get<any>(url);
  }

  getAvailableChallengesById(id) {
    const url = `/challenge/${id}`;
    return this.http.get<any>(url);
  }

  sortChallengesByIdDescending(challenges: IChallenge[]): IChallenge[] {
    const sortedChallenge = challenges.sort((g1: IChallenge, g2: IChallenge) => {
      return g2.id - g1.id;
    });

    return [...sortedChallenge];
  }

  getNewChallenge(): IChallenge {
    return {
      id: 0,
      name: '',
      image: '',
      startDate: null,
      endDate: null,
      displayStartDate: null,
      displayEndDate: null,
      type: 1,
      description: '',
      companyId: undefined,
      excludedCompanyIds: [],
      goalTypeId: undefined,
      challengeGoal: '',
    };
  }

  notifyChallengesChanged() {
    this.challengesChangedSubject.next(null);
  }

  deleteChallenge(id: number) {
    const url = `/cms/challenges/${id}`;
    return this.http.delete<any>(url);
  }

  getS3Hash() {
    return this.http.get<any>('/config/froala/articles');
  }

  getGoalTypes() {
    const url = `/cms/challenges/goaltypes`;
    return this.http.get<any>(url);
  }
}
