import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FriendPresentationComponent } from '../friends-container/friend-presentation/friend-presentation.component';
import { BadgeService } from '../../../services/badge-service';
import { UserService } from '../../../services/user-service';
import { FriendsService } from '../../../services/friends-service';
import { UtilService } from '../../../services/util-service';
import { LogService } from '../../../services/log.service';
import { ILeaderboardItem } from '../../_models/leaderboard-item';
import { IFriend } from '../../_models/friend';
import { IBadgeCount } from '../../_models/badge-count';
import { IFriendActions } from '../../_models/friend-actions';

const IncrementDisplayItemsNumber = 10;
const EveryoneId = 'everyone';

interface IRangeItems {
  items?: ILeaderboardItem[];
  displayItems?: ILeaderboardItem[];
  myPosition?: number;
  itemsCount?: number;
  canViewMore?: boolean;
  isItemsLoaded?: boolean;
}

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {
  @ViewChild('tabset') tabset;

  allItems: IRangeItems = {};
  friendsItems: IRangeItems = {};

  constructor(
    private badgeService: BadgeService,
    private userService: UserService,
    private logService: LogService,
    private modalCtrl: NgbModal,
    private friendsService: FriendsService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.logService.logLeaderboard();
    this.populateAllLeaderboardLists();
  }

  private populateAllLeaderboardLists(): void {
    this.populateAllItems(this.userService.profile.id);
    this.populateFriendItems(this.userService.profile.id);
  }

  private populateAllItems(userProfileId: number) {
    this.badgeService.getMyCompanyLeaderboard()
      .subscribe((data: ILeaderboardItem[]) => {
        this.allItems = this.getRangeItems(data, userProfileId);
      });
  }

  private populateFriendItems(userProfileId: number) {
    this.badgeService.getMyFriendsLeaderboard()
      .subscribe((data: ILeaderboardItem[]) => {
        this.friendsItems = this.getRangeItems(data, userProfileId);
      });
  }

  private getRangeItems(items: ILeaderboardItem[], userProfileId: number): IRangeItems {
    const rangeItems: IRangeItems = {
      items: this.setItemsProperties(items, userProfileId),
      displayItems: [],
      myPosition: this.currentUserItem(items).position,
      itemsCount: items.length,
      isItemsLoaded: true
    };

    this.applyIncrementalDisplayItems(rangeItems, IncrementDisplayItemsNumber);

    return rangeItems;
  }

  private setItemsProperties(inputItems: ILeaderboardItem[], userProfileId: number): ILeaderboardItem[] {
    return inputItems.map((item, index) => {
      item.position = index + 1;
      item.isMe = (item.id === userProfileId);
      item.css = item.isMe
        ? 'currentUser rowItem primaryColor'
        : ' rowItem';
      item.photoSrc = this.userService.getProfilePicture(item.photo);
      item.levelSrc = this.userService.getLevelSrc(item.level);
      item.username ?
        item.usernameTruncated = this.utilService.truncateForXS(item.username, screen.width) :
        item.usernameTruncated = item.username;

      return item;
    });
  }

  private applyIncrementalDisplayItems(rangeItems: IRangeItems, increment: number) {
    const newDisplayCount = rangeItems.displayItems.length + increment;
    if (rangeItems.myPosition <= newDisplayCount) {
      rangeItems.displayItems = rangeItems.items.slice(0, newDisplayCount);
    } else {
      rangeItems.displayItems = rangeItems.items.slice(0, newDisplayCount - 1);
      rangeItems.displayItems.push(this.currentUserItem(rangeItems.items));
    }

    rangeItems.canViewMore = rangeItems.displayItems.length < rangeItems.items.length;
  }

  currentUserItem(items: ILeaderboardItem[]) {
    const currentUser = items.find((item) => item.isMe);
    if (!currentUser) { return items[0]; }

    return currentUser;
  }

  getSelectedRangeItems(): IRangeItems {
    return (this.tabset.activeId === EveryoneId)
      ? this.allItems
      : this.friendsItems;
  }

  onViewItem(item: ILeaderboardItem) {
    this.friendsService.getFriendByUserProfileId(item.id)
      .subscribe((friend: IFriend) => {
        this.setAdditionalFriendProperties(friend, item);
        const badgeCounts = this.getBadgeCounts(item);
        this.openFriendPresentationModal(friend, badgeCounts);
      }, err => {
        console.log('friendsService.getFriendByUserProfileId(item.id)', err);
      });
  }

  private setAdditionalFriendProperties(friend: IFriend, item: ILeaderboardItem) {
    friend.photoSrc = item.photoSrc;
    friend.levelSrc = item.levelSrc;
    friend.usernameTruncated = item.usernameTruncated;
  }

  private getBadgeCounts(item: ILeaderboardItem): IBadgeCount[] {
    return [
      {
        count: +item.gold,
        badgeLevel: 'gold',
        image: this.badgeService.getGoldBadgeSrc(),
        sortOrder: 1,
        imageSrc: this.badgeService.getGoldBadgeSrc()
      },
      {
        count: +item.silver,
        badgeLevel: 'silver',
        image: this.badgeService.getSilverBadgeSrc(),
        sortOrder: 2,
        imageSrc: this.badgeService.getSilverBadgeSrc()
      },
      {
        count: +item.bronze,
        badgeLevel: 'bronze',
        image: this.badgeService.getBronzeBadgeSrc(),
        sortOrder: 3,
        imageSrc: this.badgeService.getBronzeBadgeSrc()
      },
    ];
  }

  private openFriendPresentationModal(friend: IFriend, badgeCounts: IBadgeCount[]) {
    const modal = this.modalCtrl.open(FriendPresentationComponent);
    modal.componentInstance.friend = friend;
    modal.componentInstance.badgeCounts = badgeCounts;

    modal.result.then((selectedAction: IFriendActions) => {
      this.doSelectedAction(selectedAction);
    }, () => {});
  }

  private doSelectedAction(selectedAction: IFriendActions)  {
    if (selectedAction.sendFriendRequest) {
      this.sendFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.cancelSentFriendRequest) {
      this.cancelSentFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.confirmReceivedFriendRequest) {
      this.confirmReceivedFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.declineReceivedFriendRequest) {
      this.declineReceivedFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.removeConfirmedFriend) {
      this.removeConfirmedFriend(selectedAction.actionedFriend);
    }
  }

  private sendFriendRequest(friend: IFriend) {
    this.friendsService.sendFriendRequest(friend.id)
      .subscribe((requestedFriend: IFriend) => {
        const msg = `Successfully sent a friend request for ${requestedFriend.username}`;
        this.utilService.showToastSuccess('Friend Request Sent', msg);
      }, (err) => {
        console.log('sendFriendRequest error', err);
        const msg = `Unable to send a friend request for ${friend.username}`;
        this.utilService.showToastError('Friend Request Error', msg);
      });
  }

  private cancelSentFriendRequest(friend: IFriend) {
    this.friendsService.cancelSentFriendRequest(friend.id)
      .subscribe((cancelledFriend: IFriend) => {
        const msg = `Cancelled your sent friend request to ${cancelledFriend.username}`;
        this.utilService.showToastSuccess('Cancel sent friend request', msg);
      }, (err) => {
        console.log('cancelSentFriendRequest error', err);
        const msg = `Unable to cancel the friend request for ${friend.username}`;
        this.utilService.showToastError('Cancel Friend Request Error', msg);
      });
  }

  private confirmReceivedFriendRequest(friend: IFriend) {
    this.friendsService.confirmReceivedFriendRequest(friend.id)
      .subscribe((confirmedFriend: IFriend) => {
        const msg = `Successfully confirmed friend ${confirmedFriend.username}`;
        this.utilService.showToastSuccess('Confirm Friend', msg);

        this.populateFriendItems(this.userService.profile.id);
      }, (err) => {
        console.log('confirmReceivedFriendRequest error', err);
        const msg = `Unable to confirm the friend request from ${friend.username}`;
        this.utilService.showToastError('Confirm Friend Request Error', msg);
      });
  }

  private declineReceivedFriendRequest(friend: IFriend) {
    this.friendsService.declineReceivedFriendRequest(friend.id)
      .subscribe((declinedFriend: IFriend) => {
        const msg = `Declined the friend rerequest received from ${declinedFriend.username}`;
        this.utilService.showToastSuccess('Decline Friend Request', msg);
      }, (err) => {
        console.log('declineReceivedFriendRequest error', err);
        const msg = `Unable to decline the friend request from ${friend.username}`;
        this.utilService.showToastError('Decline Friend Request Error', msg);
      });
  }

  private removeConfirmedFriend(friend: IFriend) {
    this.friendsService.removeConfirmedFriend(friend.id)
      .subscribe((removedFriend: IFriend) => {
        const msg = `Removed friend ${removedFriend.username}`;
        this.utilService.showToastSuccess('Remove Friend', msg);

        this.populateFriendItems(this.userService.profile.id);
      }, (err) => {
        console.log('removeConfirmedFriend error', err);
        const msg = `Unable to remove the friend ${friend.username}`;
        this.utilService.showToastError('Remove Friend Error', msg);
      });
  }

  onViewMoreAllItems() {
    this.applyIncrementalDisplayItems(this.allItems, IncrementDisplayItemsNumber);
  }

  onViewMoreFriends() {
    this.applyIncrementalDisplayItems(this.friendsItems, IncrementDisplayItemsNumber);
  }
}
