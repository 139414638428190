<div class="widget-card">
  <div class="widget-card-header backPrimary">
    {{widgetTitle}}
  </div>
  <div *ngIf='image'>
    <img [src]="image" alt="Show my rewards" class="w-100">
  </div>
  <div *ngIf='title' class="w-100 text-center">
    <a
      class="btn btn-link w-100 widget-card-line fs-80-rem"
      [routerLink]="['/toolkit/booking']"
    >
      <span [innerText]='title'></span>
    </a>
  </div>
</div>
