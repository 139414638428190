<div class="group-tab p-2">
  <div class="group-content row">
    <div class="col-4">
      <img class="img-avatar" [src]="groupLogoSrc">
    </div>
    <div class="col-8">
      <h4>
        <a (click)="clickView()">{{group.name}}</a>
      </h4>
      <p>
        <span [innerHTML]="pluralizeInnerHTML(groupMembersCount)">
        </span>
        -
        {{group.totPostCount |pluralize:'post':'posts'}}
      </p>
      <p>{{group.description}}</p>
      <div class="joined" *ngIf="isMember">
        <i class="fa fa-check colorPrimary"></i>
        <p>Joined</p>
      </div>
    </div>
  </div>
  <div *ngIf="isMember" class="flexbuttons p-2">
    <div>
      <div *ngIf="newPostCount">
        <p>
          <b>{{newPostCount | pluralize:'new post':'new posts':'No new posts'}} since you last checked!</b>
        </p>
      </div>
    </div>
    <button type="button" class="btn btn-primary w-100" (click)="clickView()">
      View
    </button>
  </div>
  <div *ngIf="!isMember" class="footerButtons p-2">
    <button type="button" class="btn btn-primary" (click)="clickView()">View</button>
    <button type="button" class="btn btn-primary" (click)="clickJoin($event)">Join</button>
  </div>
</div>
