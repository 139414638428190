import { IStripePlan } from '../../../_models/stripe-plan';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StripeService } from '../../../../services/stripe-service';
import { Observable, Subscription } from 'rxjs';
import { UserService } from '../../../../services/user-service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-stripe-plans-input',
  templateUrl: './stripe-plans-input.component.html',
  styleUrls: ['./stripe-plans-input.component.scss']
})
export class StripePlansInputComponent implements OnInit {
  @Input() plans: IStripePlan[] = [];
  @Input() primaryColor: string;
  @Output() planSelected: EventEmitter<IStripePlan> = new EventEmitter();
  chosenPlan: any;
  selectedPlanOnClick = false;

  stripePlans$: Observable<IStripePlan[]>;
  selectedStripePlan$: Observable<IStripePlan>;
  selectedStripePlanSubscription: Subscription;

  customerStripeToken$: Observable<any>;
  customerStripeTokenSubscription: Subscription;

  selectedPlan: any;
  selectedPlanIndex: number;
  stripeToken: any;

  constructor(
    private stripeService: StripeService,
    private userService: UserService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.stripePlans$ = this.stripeService.getCompanyStripePlans();
    this.setSelectedStripePlansObservable();
    this.setCustomerStripeTokenObservable();
  }

  private setSelectedStripePlansObservable() {
    this.selectedStripePlan$ = this.stripeService.selectedStripePlan$;
    this.selectedStripePlanSubscription = this.selectedStripePlan$.subscribe((plan) => {
      this.selectedPlan = plan;
    });
  }

  private setCustomerStripeTokenObservable() {
    this.customerStripeToken$ = this.stripeService.customerStripeToken$;
    this.customerStripeTokenSubscription = this.customerStripeToken$.subscribe(() => {

    });
  }

  onSelectPlan(event, selectedPlan: IStripePlan) {
    event.preventDefault();
    event.stopPropagation();
    this.markSelectedPlan(selectedPlan);
    this.planSelected.emit(selectedPlan);
    this.selectedPlanOnClick = true;
    this.chosenPlan = selectedPlan;
  }

  deselectPlan(event, selectedPlan: IStripePlan) {
    event.preventDefault();
    event.stopPropagation();
    this.planSelected.emit(null);
    this.selectedPlanOnClick = false;
    selectedPlan.isSelected = false;
    if (selectedPlan !== this.chosenPlan) {
      this.chosenPlan = null;
      this.planSelected.emit(selectedPlan);
      this.selectedPlanOnClick = true;
      selectedPlan.isSelected = true;
      this.chosenPlan = selectedPlan;
    }
  }

  onStripePlanClicked(plan: any, i: number) {
    this.selectedPlanIndex = i;
    this.stripeService.setSelectedStripePlan(plan);
  }

  markSelectedPlan(selectedPlan: IStripePlan) {
    this.plans.forEach(p => {
      p.isSelected = (p.planId === selectedPlan.planId);
    });
  }

  getColor(plan: IStripePlan) {
    return (plan && plan.isSelected && plan === this.chosenPlan)
      ? '#fff'
      : '#575b5c';
  }

  getHeaderBackgroundColor(plan: IStripePlan) {
    return (plan && plan.isSelected && plan === this.chosenPlan)
      ? this.primaryColor
      : 'rgba(0,0,0,0.03)';
  }

  getBodyBackgroundColor(plan: IStripePlan) {
    return (plan && plan.isSelected && plan === this.chosenPlan)
      ? this.primaryColor
      : '#fff';
  }

  openStripe() {
    const stripeHandler = (<any>window).StripeCheckout.configure({
      key: this.userService.getStripeToken(),
      locale: 'auto',
      token: token => {
        if (token.id) {
          this.stripeToken = token.id;
          this.stripeService.setCustomerStripeToken(this.stripeToken);
          this.activeModal.close();
        }
      }
    });

    stripeHandler.open({
      name: this.selectedPlan.name,
      description: this.selectedPlan.description,
      allowRememberMe: false,
      amount: this.selectedPlan.amount,
      currency: this.selectedPlan.currency
    });

  }
}
