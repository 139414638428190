import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataSourceService } from '../../../services/dataSource-service';
import { TMHService } from '../../../services/track-my-health-service';
import { UserService } from '../../../services/user-service';
import { UtilService } from '../../../services/util-service';
import { DeviceService } from '../../../services/device-service';
import { ActivatedRoute } from '@angular/router';
import { MyHealthChartComponent } from '../track-my-health-chart/track-my-health-chart.component';
import { DatasourceComponent } from '../../datasource/datasource.component';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LogService } from '../../../services/log.service';
import { ChartingService } from '../../../services/charting.service';
import { TrackMyHealthSetGoalComponent } from '../track-my-health-set-goal/track-my-health-set-goal.component';
import { TrackMyHealthIndicatorValuesComponent } from '../track-my-health-indicator-values/track-my-health-indicator-values.component';
import { IIndicatorTrackerLogValue } from '../../_models/indicator-tracker-log-value';

import { Observable, Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-track-my-health',
  templateUrl: './track-my-health.component.html',
  styleUrls: ['./track-my-health.component.scss'],
})
export class TrackMyHealthComponent implements OnInit, OnDestroy {
  indicatorIdToChangeDataSource = '';

  private subscription: Subscription;
  isLoggedIn = false;
  indicators: any[] = [];
  dailyGoals: any[] = [];
  longGoals: any[] = [];

  constructor(
    private dataSourceService: DataSourceService,
    private tmhService: TMHService,
    public userService: UserService,
    private utilService: UtilService,
    private deviceService: DeviceService,
    private logService: LogService,
    public activatedRoute: ActivatedRoute,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public chartingService: ChartingService) {
    this.tmhService.changeNotTMH(false);
  }

  ngOnInit() {
    this.isLoggedIn = this.userService.isLoggedIn();
    this.logService.logTrackMyHealth();
    this.initialiseOnDeviceCallback();
    this.getIndicatorsData();
  }

  ngOnDestroy() {
    this.tmhService.changeNotTMH(true);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private initialiseOnDeviceCallback() {
    this.activatedRoute.queryParams
      .subscribe((params) => {
        const deviceSummary = this.deviceService.getDeviceSummary(params.device);
        if (deviceSummary) {
          this.notifyDeviceConnectionStatus(params, deviceSummary);
        }
      });
  }

  private notifyDeviceConnectionStatus(queryParams, deviceSummary) {
    if (queryParams.success === 'true') {
      setTimeout(() => {
        const message = `Your account is now connected to ${deviceSummary.name}`;
        this.utilService.showToastSuccess(message);
      }, 100);

      this.userService.getProfile()
        .subscribe(() => {
        });

      if (queryParams.indicator) {
        this.indicatorIdToChangeDataSource = queryParams.indicator;
      }
    } else if (queryParams.error === 'failed') {
      const message = `We were unable to connect to ${deviceSummary.name}. Please try again later.`;
      setTimeout(() => {
        this.utilService.showToastError(message);
      }, 100);
    } else if (queryParams.error === 'exists') {
      const message = `Unable to connect: this ${deviceSummary.name} is already linked to another
        ${this.userService.company.name} account`;
      setTimeout(() => {
        this.utilService.showToastWarning(message);
      }, 100);
    }

    this.changeExistingUrlToYourHealth();
  }

  changeExistingUrlToYourHealth() {
    const currentUrl = window.location.href;
    const newUrl = currentUrl.substring(0, currentUrl.indexOf('?'));
    window.history.replaceState({}, 'your-health', newUrl);
  }

  private getIndicatorsData() {
    this.tmhService.getIndicatorsData()
      .subscribe((trackerData) => {
        this.userService.getProfile()
          .subscribe();
        this.indicators = trackerData.indicators;
        this.dailyGoals = trackerData.dailyGoals;
        this.longGoals = trackerData.longGoals;
        this.displayEachAchievedBadgeAlerts(trackerData.achievedBadges);
        this.tmhService.displayRelevantDeviceTrackerValueMessages(trackerData.indicators);
        this.tmhService.applyIndicatorsChartingProperties(
          this.indicators,
          this.chartingService.pointRadiusValue,
          this.chartingService.chartColor,
          this.chartingService.goalAchievedColor,
          this.chartingService.goalMissedColor,
        );

        if (this.indicatorIdToChangeDataSource) {
          this.changeDataSourceForQueryParamsIndicator();
        }
        this.indicators.forEach((indicator) => {
          if (indicator.message) {
            this.utilService.showToastError(indicator.message);
          }
        });
      });
  }

  private changeDataSourceForQueryParamsIndicator() {
    if (!this.indicatorIdToChangeDataSource) {
      return;
    }

    const indicator = this.indicators.find((i) => i.id === +this.indicatorIdToChangeDataSource);
    if (!indicator) {
      return;
    }

    this.indicatorIdToChangeDataSource = '';
    this.changeIndicatorDataSource(indicator);
  }

  private changeIndicatorDataSource(indicator) {
    const dataSourceModal = this.modalService.open(DatasourceComponent);
    dataSourceModal.componentInstance.item = indicator;
    dataSourceModal.componentInstance.parentPage = 'track-my-health';

    dataSourceModal.result
      .then((result) => {
        this.dataSourceService
          .changeDataSource(result.datasource, indicator.dataSource.id)
          .subscribe((data) => {
            if (data.success === false) {
              this.utilService.showToastError(data.err);
            } else {
              this.refreshIndicatorsWithUpdatedIndicator(indicator.id);

              const message = `${indicator.name} data source was changed to ${result.datasource}`;
              this.utilService.showToastSuccess(message);
            }
          });
      })
      .catch((error) => console.log(error));
  }

  private displayEachAchievedBadgeAlerts(achievedBadges) {
    if (!achievedBadges.length) {
      return;
    }

    achievedBadges.forEach(b => this.utilService.badgeAlert([b]));
  }

  private displayRelevantLoggedTrackerValueMessages(indicatorName, trackers) {
    trackers.forEach(tracker => {
      const message = (tracker && tracker.achievedThisUpdate)
        ? `Congratulations! You have achieved your ${indicatorName} goal!`
        : `${indicatorName} data added`;
      this.utilService.showToastSuccess(message);
    });
  }

  private refreshIndicatorsWithUpdatedIndicator(updatedIndicatorId) {
    this.tmhService
      .refreshIndicatorsWithUpdatedIndicator(
        this.indicators,
        this.dailyGoals,
        this.longGoals,
        updatedIndicatorId,
        this.chartingService.pointRadiusValue,
        this.chartingService.chartColor,
        this.chartingService.goalAchievedColor,
        this.chartingService.goalMissedColor,
      )
      .subscribe(() => {
        this.tmhService.invokeActiveIndicators(this.indicators);
      });
  }

  private updateIndicatorGoal(indicator, operand, lowerValue, upperValue) {
    this.upsertInternalIndicatorRule(indicator, operand, lowerValue, upperValue);
  }

  private upsertInternalIndicatorRule(indicator, operand, lowerValue, upperValue) {
    this.tmhService
      .upsertIndicatorRule(indicator, operand, lowerValue, upperValue)
      .subscribe(() => {
        this.showSuccessfulIpdatedGoalMessage();
        this.refreshIndicatorsWithUpdatedIndicator(indicator.id);
      });
  }

  private showSuccessfulIpdatedGoalMessage() {
    const message = 'Goal updated';
    this.utilService.showToastSuccess(message);
  }

  onLogClick(indicator) {
    this.logBatchValuesForIndicator(indicator);
  }

  private logBatchValuesForIndicator(indicator) {
    const preventClickingOutsideDialogOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    const modal = this.modalService.open(
      TrackMyHealthIndicatorValuesComponent, preventClickingOutsideDialogOptions);
    modal.componentInstance.indicator = indicator;

    modal.result.then((changes: IIndicatorTrackerLogValue[]) => {
      if (changes.length) {
        this.tmhService.batchSelfTrackers(indicator.id, indicator.name, changes)
          .subscribe(data => {
            this.refreshIndicatorsWithUpdatedIndicator(indicator.id);
            this.displayEachAchievedBadgeAlerts(data.achievedBadges);
            this.displayRelevantLoggedTrackerValueMessages(indicator.name, data.trackers);
          }, error => {
            this.displayBatchSelfTrackersError(error);
          });
      }
    })
      .catch(() => {
      });
  }

  private displayBatchSelfTrackersError(error) {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Error occurred saving values';
    modal.componentInstance.message = 'Error: ' + error.statusText;

    modal.result
      .then(() => {
      })
      .catch(() => {
      });
  }


  onHistoryClick(indicator) {
    this.modalService.open(MyHealthChartComponent).componentInstance.data = indicator;
  }

  onGoalClick(indicator) {
    const activeIndicatorRule = this.tmhService.getActiveIndicatorRule(indicator);
    const modal = this.modalService.open(TrackMyHealthSetGoalComponent);
    modal.componentInstance.goalTitle = indicator.goalTitle;
    modal.componentInstance.goalDisplay = indicator.goalDisplay;
    modal.componentInstance.isSleepIndicator = this.tmhService.isSleepIndicator(indicator);
    modal.componentInstance.unit = indicator.unit;
    modal.componentInstance.operand = activeIndicatorRule.operand;
    modal.componentInstance.lowerValue = activeIndicatorRule.lowerValue;
    modal.componentInstance.upperValue = activeIndicatorRule.upperValue;

    modal.result
      .then((result) => {
        const operand = result.operand;
        const lowerValue = result.lowerValue;
        const upperValue = result.upperValue;

        this.updateIndicatorGoal(indicator, operand, lowerValue, upperValue);
      })
      .catch(() => {
      });
  }

  onDataSourceClick(indicator) {
    this.changeIndicatorDataSource(indicator);
  }
}
