import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ITeam } from '../app/_models/team';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TeamService {

  private teamsChangedSubject = new BehaviorSubject<any>(null);
  teamsChanged$: Observable<null> = this.teamsChangedSubject.asObservable();

  constructor(
    private http: HttpClient,
  ) {
  }

  addTeam(body) {
    const url = `/cms/teams`;
    return this.http.post<any>(url, body);
  }

  updateTeam(body, id) {
    const url = `/cms/teams/${id}`;
    return this.http.put<any>(url, body);
  }

  getAllTeams() {
    const url = `/cms/teams`;
    return this.http.get<any>(url);
  }

  getTeamById(id) {
    const url = `/cms/teams/${id}`;
    return this.http.get<any>(url);
  }

  getAvailableTeams() {
    const url = `/team/available`;
    return this.http.get<any>(url);
  }

  getAvailableTeamById(id) {
    const url = `/team/${id}`;
    return this.http.get<any>(url);
  }

  joinTeam(body) {
    const url = `/team/join`;
    return this.http.post<any>(url, body);
  }

  leaveTeam(body) {
    const url = `/team/leave`;
    return this.http.post<any>(url, body);
  }

  addTeamComments(body) {
    const url = `/team/comment`;
    return this.http.post<any>(url, body);
  }

  getTeamComments(teamId) {
    const url = `/team/comments/${teamId}`;
    return this.http.get<any>(url);
  }

  getNewTeam(): ITeam {
    return {
      id: 0,
      name: '',
      image: '',
      challengeId: null,
      cId: null,
    };
  }

  updateComment(id, body) {
    const url = `/team/comment/${id}`;
    return this.http.put<any>(url, body);
  }

  deleteComment(id) {
    const url = `/team/comment/${id}`;
    return this.http.delete<any>(url);
  }

  notifyTeamsChanged() {
    this.teamsChangedSubject.next(null);
  }

  deleteTeam(id: number) {
    const url = `/cms/teams/${id}`;
    return this.http.delete<any>(url);
  }

  getChallengeReports(startDate, endDate, challengeId, indicatorId) {
    const url = `/cms/teams/teamreports/${startDate}/${endDate}/${challengeId}/${indicatorId}`;
    return this.http.get<any>(url);
  }

  getTeamsReports(startDate, endDate, teamId, indicatorId) {
    const url = `/cms/teams/teamdetailsreports/${startDate}/${endDate}/${teamId}/${indicatorId}`;
    return this.http.get<any>(url);
  }
}
