import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '../../../../services/auth-service';
import { AuthRoutePath } from '../../../_models/auth';

import { BootstrapModalComponent } from '../../../../components/bootstrap-modal/bootstrap-modal.component';


@Component({
  selector: 'app-forget-password-form',
  templateUrl: './forget-password-form.component.html',
  styleUrls: ['./forget-password-form.component.scss'],
})
export class ForgetPasswordFormComponent implements OnInit {

  isLoading: boolean;
  form: FormGroup;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private authService: AuthService,
  ) {
  }

  initialiseVariables() {
    this.isLoading = false;
  }

  ngOnInit() {
    this.initialiseVariables();
    this.initialiseForgetPasswordForm();

  }

  disableFormAndEnableLoader() {
    this.form.disable();
    this.isLoading = true;
  }

  enableFormAndDisableLoader() {
    this.form.enable();
    this.isLoading = false;
  }

  isFormSubmitDisabled() {
    return this.form.invalid || this.isLoading;
  }

  setInputOutlineClass(ctrl: AbstractControl) {
    return this.authService.setInputOutlineClass(ctrl);
  }

  get email() {
    return this.form.get('email');
  }

  initialiseForgetPasswordForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmitForgetPasswordForm() {
    if (this.form.invalid) {
      return;
    }
    this.disableFormAndEnableLoader();
    const _body = this.form.value;
    this.authService.forgetPassword(_body).subscribe((res) => {
      this.enableFormAndDisableLoader();
      this.handleForgetPasswordResponse(res);
    });
  }

  showForgetPasswordSuccess() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Request success!';
    modal.componentInstance.message = 'Please check your email to complete the reset password process.';
    modal.componentInstance.singleButton = true;
  }

  showForgetPasswordUserNotFoundError() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'User not found';
    modal.componentInstance.message = `Could not find a user with this email address registered.
      Please register, or try again with a different email address.`;
    modal.componentInstance.singleButton = true;
    modal.result.then(() => this.router.navigateByUrl(AuthRoutePath.login));
  }

  handleForgetPasswordResponse(res) {
    if (res.isUserNotFoundError) {
      this.showForgetPasswordUserNotFoundError();
    } else {
      this.showForgetPasswordSuccess();
    }
  }
}
