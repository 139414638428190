import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  constructor(
    private http: HttpClient,
  ) {
  }

  deleteObject(obj, id) {
    return this.http.delete<any>(`/cms/${obj}/${id}`);
  }
}
