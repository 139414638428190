import { Component, NgZone, OnInit } from '@angular/core';
import { UtilService } from '../../../services/util-service';
import { ICategory } from '../../_models/category';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { FeatureService } from '../../../services/feature-service';
import { IIdNamePair } from '../../_models/id-name-pair';
import { ICompany } from '../../_models/company';
import { FeatureNamePipe } from '../../_shared/pipes/featureName.pipe';
import { FilestackService } from '../../../services/filestack-service';
import { CompanyService } from '../../../services/company-service';
import { IFeature } from '../../_models/feature';
import { FEATURES } from '../../_shared/enums';

const maxCategoryImageSize = 100 * 1500;

@Component({
  selector: 'app-feature-management',
  templateUrl: './feature-management.component.html',
  styleUrls: ['./feature-management.component.scss'],
})
export class FeatureManagementComponent implements OnInit {
  items: ICategory[];
  item: ICategory;
  itemType = 'Feature';
  columns = [];
  itemForm: FormGroup;

  isDisplayingList: boolean;
  isInitialised: boolean;
  isEditingItem: boolean;
  isAddingItem: boolean;
  canRespondToButtons: boolean;

  featureTypes: IIdNamePair[] = [];

  companies: ICompany[] = [];
  allCompanies: IIdNamePair[] = [];
  includedCompanyIds: number[] = [];
  excludedCompanyIds: number[] = [];

  public canSelectCompany = true;
  image = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private featureService: FeatureService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private modalService: NgbModal,
    private featureNamePipe: FeatureNamePipe,
    private fileStackService: FilestackService,
    private ngZone: NgZone,
    private companyService: CompanyService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(route => {
      this.analyseRoute(route);

      if (this.isDisplayingList) {
        this.getList();
      } else {
        this.initializeFormItem(route);
      }
    });
  }

  initializeFormItem(route) {
    this.companyService.getAllCompanies()
      .subscribe((data) => {
        this.companies = data;
        this.allCompanies = this.companies.map(c => {
          return { id: c.id, name: c.name };
        });
        if (this.isEditingItem) {
          this.initialiseEditItem(+route.id);
        } else if (this.isAddingItem) {
          this.initialiseAddItem();
        }
      }, () => (this.companies = []));
  }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isEditingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isEditingItem = true;
    }
  }

  private getList() {
    this.featureService.getAllFeatures()
      .subscribe(data => {
          this.items = data.filter(d => d.type !== FEATURES.HOME).map(i => {
            return [
              i.id,
              this.featureNamePipe.transform(i.name),
              this.featureService.getFeatureTypeNameById(i.type),
              this.featureService.getImageForFeature(i.menuImage),
            ];
          });

          this.columns = ['id', 'name', 'type', 'menu image'];
          this.isInitialised = true;
          this.canRespondToButtons = true;
        },

        error => this.utilService.showToastError('Error while loading features - ' + error));
  }

  private initialiseEditItem(id: number): void {
    this.featureService.getFeatureById(id)
      .subscribe(data => {
        this.item = data;
        this.initialiseEditableFormGroup(data);
        this.isInitialised = true;
        this.canRespondToButtons = true;
      });
  }

  private initialiseAddItem(): void {
    this.item = this.featureService.getNewFeature();
    this.initialiseEditableFormGroup(this.item);
    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  private initialiseEditableFormGroup(item) {
    this.setImage(item.menuImage);
    this.featureTypes = this.featureService.getFeatureTypeOptions();
    this.includedCompanyIds = item.includedCompanyIds;
    this.excludedCompanyIds = item.excludedCompanyIds;

    this.itemForm = this.fb.group({
      nameControl: [item.name, Validators.required],
      typeIdControl: [this.getType(item.type), Validators.required],
      menuImageControl: [item.menuImage, Validators.required],
    });
  }

  private getType(type: number) {
    return type === 0 ? type : type || '';
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach(field => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  private navigateToList() {
    this.router.navigate(['/cms/feature']);
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Delete ' + this.itemType;
    modal.componentInstance.message = 'Confirm deleting this ' + this.itemType;
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(isConfirmed => {
      if (!isConfirmed) {
        return;
      }

      this.featureService.deleteFeature(this.item.id)
        .subscribe(() => {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`${this.itemType} - ${this.item.name} - deleted`);
            this.navigateToList();
          },
          error => this.utilService.showToastError('Error while deleting item - ' + error));
    }, () => {
    });
  }

  private persistItem() {
    this.canRespondToButtons = false;
    let itemToPersist: IFeature;

    try {
      itemToPersist = {
        id: this.item.id,
        name: this.itemForm.get('nameControl').value,
        type: this.itemForm.get('typeIdControl').value,
        menuImage: this.itemForm.get('menuImageControl').value,
        ...this.calculateAccessCompanyIds(),
      };
    } catch (error) {
      this.utilService.showToastError('Error persisting item - ' + error);
      this.canRespondToButtons = true;
      return;
    }

    if (this.isEditingItem) {
      this.updateItem(itemToPersist);
    } else if (this.isAddingItem) {
      this.addNewItem(itemToPersist);
    }
  }

  private updateItem(itemToPersist: IFeature) {
    this.featureService.updateFeature(itemToPersist)
      .subscribe(() => {
          this.itemForm.reset();
          this.canRespondToButtons = true;
          this.utilService.showToastSuccess(`Updated ${this.itemType} - ${itemToPersist.name}`);
          this.navigateToList();
        },
        error => {
          this.utilService.showToastError(`Error while saving item - ${error}`);
          this.canRespondToButtons = true;
        });
  }

  private addNewItem(itemToPersist: IFeature) {
    this.featureService.addFeature(itemToPersist)
      .subscribe(() => {
          this.itemForm.reset();
          this.canRespondToButtons = true;
          this.utilService.showToastSuccess(`Added new ${this.itemType} - ${itemToPersist.name}`);
          this.navigateToList();
        },
        error => {
          this.utilService.showToastError(`Error while adding item - ${error}`);
          this.canRespondToButtons = true;
        });
  }

  onBackClick() {
    this.navigateToList();
  }

  onDeleteClick() {
    if (!this.isEditingItem) {
      return;
    }

    this.deleteItem();
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }

    this.persistItem();
  }

  setImage(image?) {
    if (!image || image.trim().length === 0) {
      return;
    }

    this.image = this.featureService.getImageForFeature(image);
  }

  openFilestack() {
    this.fileStackService.pickFeatureImage(maxCategoryImageSize)
      .then(res => {
          this.ngZone.run(() => {
            this.itemForm.patchValue({ menuImageControl: res });
            this.setImage(res);
          });
        },
        err => {
          this.utilService.showToastError('Error while uploading picture', err);
        },
      );
  }

  excludedCompanyIdsSelected(selectedCompanyIds: number[]) {
    this.excludedCompanyIds = selectedCompanyIds;
    if (selectedCompanyIds.length > 0) {
      this.includedCompanyIds = [];
    }
  }

  includedCompanyIdsSelected(selectedCompanyIds: number[]) {
    this.includedCompanyIds = selectedCompanyIds;
    if (selectedCompanyIds.length == 0) {
      this.excludedCompanyIds = [];
    }
  }

  showExcludedCompanies() {
    return this.includedCompanyIds.length == 0;
  }

  calculateAccessCompanyIds(): { includedCompanyIds: number[], excludedCompanyIds: number[] } {
    if (this.includedCompanyIds.length > 0) {
      return {
        includedCompanyIds: this.includedCompanyIds,
        excludedCompanyIds: [],
      };
    }
    return {
      includedCompanyIds: [],
      excludedCompanyIds: this.excludedCompanyIds,
    };
  }
}
