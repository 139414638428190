<div>
  <div *ngIf="canDisplayItems()"
    class="px-0">
    <h2 class="mt-2">{{questionnairesName}}</h2>
    <div class="row">
      <div *ngFor="let questionnaire of questionnaires"
        class="col-12 col-sm-6 d-flex mt-4 mb-2">
        <div class="card w-100 mr-0 ml-0 p-0">
          <div *ngIf="questionnaire.banner"
            class="card-img-top-div">
            <img class="card-img-top"
              [src]="questionnaire.bannerSrc">
          </div>
          <div class="card-body">
            <h5 class="card-title">{{questionnaire.name}}</h5>
            <p class="card-text"
              [ngClass]="{'pb-5 mb-5':
                questionnaire.canSeePastResults}"
              [innerHTML]="questionnaire.summary"></p>
            <br />
            <br />
            <div class="buttonsBottom">
              <a *ngIf="questionnaire.canTakeTheQuiz"
                class="btn btn-outline-primary btn-md w-100"
                (click)="onTakeTheQuiz(questionnaire)">
                Take the Quiz
              </a>

              <a *ngIf="questionnaire.canSeePastResults"
                class="btn btn-outline-primary btn-md w-100 mt-3"
                (click)="onSeePastResults(questionnaire)">
                See Past Results
              </a>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="canDisplayNoActiveQuestionnaires"
        class="col-12 col-sm-6 d-flex mt-4 mb-2">
        <p>No active questionnaires</p>
      </div>
    </div>

  </div>
  <div *ngIf="canDisplayRoutedToItem()">
    <router-outlet></router-outlet>
  </div>
</div>
