import { LocatorService } from '../../../services/locator-service';
import { ApplicantData, CareData, ServiceData } from './aged-care-formData.model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from '../../../services/util-service';
import { UserService } from '../../../services/user-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrivacyComponent } from '../../_shared/components/privacy/privacy.component';
import { PrivacyCollectionStatementComponent } from '../../_shared/components/privacy-collection-statement/privacy-collection-statement.component';

@Component({
  selector: 'app-aged-care-locator',
  templateUrl: './aged-care-locator.component.html',
  styleUrls: ['./aged-care-locator.component.scss'],
})
export class AgedCareLocatorComponent implements OnInit {
  helpEmail: string;
  applicantData = {};
  careData = {};
  serviceData = {};
  isSame = false;
  isOnAppicantDetails = true;
  isApplicantDetailsDone = false;
  isOnCareDetails: boolean;
  isCareDetailsDone = false;
  isOnService: boolean;
  isServiceDone = false;
  isOnReview: boolean;
  isReviewDone = false;

  constructor(
    private router: Router,
    private locatorService: LocatorService,
    private utilService: UtilService,
    private modalService: NgbModal,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.applicantData = new ApplicantData();
    this.careData = new CareData();
    this.serviceData = new ServiceData();
    this.helpEmail = this.userService.envVariables.contactInfo.helpEmail;
  }

  applySelf(value) {
    if (value === 'Yes') {
      this.careData['personal_details'] = this.applicantData[
        'personal_details'
        ];
    } else {
      this.careData['personal_details'] = {
        firstname: '',
        surname: '',
        address: '',
        suburb: '',
        state: 'State*',
        post_code: '',
        home_phone: '',
        mobile: '',
        email: '',
      };
    }
  }

  sameClick() {
    this.isSame = !this.isSame;
    if (!this.isSame) {
      this.applicantData['emergency_contacts'] = {
        firstname: '',
        surname: '',
        address: '',
        suburb: '',
        state: 'State*',
        post_code: '',
        home_phone: '',
        mobile: '',
        email: '',
      };
    } else {
      this.applicantData['emergency_contacts'] = this.applicantData[
        'personal_details'
        ];
    }
  }

  onReturn(page, elem) {
    switch (page) {
      case 'careData': {
        this.isOnAppicantDetails = true;
        this.isOnCareDetails = false;
        elem.scrollIntoView({ behavior: 'smooth' });
        break;
      }
      case 'serviceData': {
        this.isOnService = false;
        this.isOnCareDetails = true;
        elem.scrollIntoView({ behavior: 'smooth' });
        break;
      }
      case 'review': {
        this.isOnReview = false;
        this.isOnService = true;
        elem.scrollIntoView({ behavior: 'smooth' });
        break;
      }
    }
  }

  onNext(type, elem) {
    switch (type) {
      case 'applicantData': {
        this.isApplicantDetailsDone = true;
        this.isOnAppicantDetails = false;
        this.isOnCareDetails = true;
        elem.scrollIntoView({ behavior: 'smooth' });
        break;
      }
      case 'careData': {
        this.isCareDetailsDone = true;
        this.isOnCareDetails = false;
        this.isOnService = true;
        elem.scrollIntoView({ behavior: 'smooth' });
        break;
      }
      case 'serviceData': {
        this.isServiceDone = true;
        this.isOnService = false;
        this.isOnReview = true;
        elem.scrollIntoView({ behavior: 'smooth' });
        break;
      }
    }
  }

  clearData() {
    this.applicantData = new ApplicantData();
    this.careData = new CareData();
    this.serviceData = new ServiceData();
  }

  onSubmit() {
    const formValue = {
      applicantData: this.applicantData,
      careData: this.careData,
      serviceData: this.serviceData,
      type: 'agedcare',
      company: this.userService.company.id,
    };
    this.isReviewDone = true;
    this.locatorService
      .sendLocatorEnquiry('agedcare', formValue)
      .subscribe(res => {
        if (res.success === true) {
          this.utilService.showInfo(
            'Thank you for your enquiry. Someone from the Seventeenhundred team will be in touch within 48 hours.',
            2,
            'check',
          );
          this.clearData();
          this.router.navigate(['/toolkit/locators']);
        } else {
          this.utilService.showInfo(
            'Something went wrong. Please try to submit your enquiry again later.',
            1,
            'exclamation',
          );
          this.clearData();
          this.router.navigate(['/toolkit/locators']);
        }
      });
  }

  openPolicy(policy) {
    if (policy === 'privacy') {
      this.modalService.open(PrivacyComponent);
    } else {
      this.modalService.open(PrivacyCollectionStatementComponent);
    }
  }
}
