import { FEATURES } from '../../../enums';
import { IQuestionnaire } from '../../../../_models/questionnaire';
import { BreadcrumbService } from '../../../../../services/breadcrumb.service';
import { FeatureService } from '../../../../../services/feature-service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { QuestionnaireService } from '../../../../../services/questionnaire-service';
import { UserService } from '../../../../../services/user-service';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {
  questionnaires = [];
  questionnairesName = 'Questionnaires';
  featureId = 0;
  path: any;
  canDisplayNoActiveQuestionnaires = false;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private userService: UserService,
    private questionnaireService: QuestionnaireService,
    private breadcrumbService: BreadcrumbService,
    private featureService: FeatureService
  ) { }

  ngOnInit() {
    this.activatedRoute.url.subscribe((data) => {
      if (!data || !data.length) { return; }

      if (data[0].path.indexOf('healthriskassessments') > -1) {
        this.path = 'act/healthriskassessments';
        this.featureId = FEATURES.HEALTH_RISK_ASSESSMENTS;
      } else if (data[0].path.indexOf('competition') > -1) {
        this.path = data[0].path;
        this.featureId = FEATURES.CHANCE_TO_WIN;
      }

      this.questionnairesName = this.getFeatureName(this.featureId);
      this.setBreadcrumbExtraName(this.featureId);

      this.questionnaireService.getQuestionnaires(this.featureId)
        .subscribe((questionnaires: IQuestionnaire[]) => {
          this.questionnaires = questionnaires;
          this.setQuestionnairesUIProperties(this.questionnaires);
          this.canDisplayNoActiveQuestionnaires = questionnaires.length === 0;
        });
    });
  }

  private setQuestionnairesUIProperties(questionnaires: IQuestionnaire[]) {
    this.setQuestionnairesBannerSrce(questionnaires);
  }

  private setQuestionnairesBannerSrce(questionnaires: IQuestionnaire[]) {
    questionnaires.forEach((q: IQuestionnaire) => {
      q.bannerSrc = this.userService.getQuestionnaireImage(q.banner);
    });
  }

  canDisplayItems(): boolean {
    return this.activatedRoute.children.length === 0;
  }

  canDisplayRoutedToItem(): boolean {
    return this.activatedRoute.children.length > 0;
  }

  onTakeTheQuiz(questionnaire: IQuestionnaire) {
    this.router.navigateByUrl(questionnaire.urlPath);
    window.scroll(0, 0);
  }

  onSeePastResults(questionnaire: IQuestionnaire) {
    const resultsUrl = this.questionnaireService.getResultUrl(questionnaire.urlPath);
    this.router.navigateByUrl(resultsUrl);
    window.scroll(0, 0);
  }

  private getFeatureName(featureId: number): string {
    switch (featureId) {
      case FEATURES.CHANCE_TO_WIN:
        return 'Competition';

      case FEATURES.HEALTH_RISK_ASSESSMENTS:
        return 'Health Risk Assessments';

      default:
        return 'Questionnaires';
    }
  }

  private setBreadcrumbExtraName(featureId: number) {
    let name = 'Questionnaires';
    if (featureId === FEATURES.HEALTH_RISK_ASSESSMENTS
        || featureId === FEATURES.CHANCE_TO_WIN) {
      name = this.featureService.featureName(featureId);
    }

    this.breadcrumbService.setBreadcrumbExtraName(name);
  }

  getImage(image: string) {
    return this.userService.getQuestionnaireImage(image);
  }
}
