import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProgramService } from '../../../services/program-service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../services/util-service';
import { IUserProgram } from '../../_models/user-program';
import { UserService } from '../../../services/user-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { Observable, Subscription } from 'rxjs';
import { DateService } from '../../../services/date.service';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss']
})
export class ProgramComponent implements OnInit, OnDestroy {
  programs$: Observable<IUserProgram[]>;
  programsSubscription: Subscription;
  programs = [];

  constructor(
    public programService: ProgramService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public utilService: UtilService,
    private userService: UserService,
    private modalService: NgbModal,
    private dateService: DateService
  ) { }

  ngOnInit() {
    this.programService.getUserPrograms().subscribe(() => { });
    this.programs$ = this.programService.getUserPrograms();
    this.programsSubscription = this.programs$.subscribe(() => {
      this.openOrJoinProgramIfFromMegaMenu();
    });
  }

  ngOnDestroy() {
    if (this.programsSubscription) {
      this.programsSubscription.unsubscribe();
    }
  }

  private openOrJoinProgramIfFromMegaMenu() {
    this.activatedRoute.data.subscribe(data => {
      if (!data.isFromMegaMenu) { return; }

      this.activatedRoute.params
        .subscribe(params => {
          const id = +params.id;
          this.programService.getProgramById(id)
            .subscribe((resData: any) => {
              const program = resData.program;
              if (program.isUserInProgram) {
                this.openProgram(program);
              } else {
                this.joinProgram(program, null);
              }
            })
        });
    });
  }

  openProgram(program) {
    const titleAndIdHyphenated = this.utilService.hyphenateUrl(program.title, program.id);
    this.router.navigate(['act/programs', titleAndIdHyphenated]);
  }

  joinProgram(program, event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!this.programService.canUserJoinProgram(program)) {
      return;
    }

    this.displayPersonalisationQuestionnaire(program);
  }

  createDateObj(program) {
    let dateLabel;
    let startDate;
    let endDate;
    let showDatepicker;
    if (!program.personalisationQuestionnaire) {
      dateLabel = null;
      startDate = true;
      endDate = false;
      showDatepicker = false;
    } else if (program.personalisationEndDate && !program.personalisationStartDate) {
      dateLabel = program.personalisationEndDateText;
      startDate = false;
      endDate = true;
      showDatepicker = true;
    } else if (program.personalisationStartDate && !program.personalisationEndDate) {
      dateLabel = program.personalisationStartDateText;
      startDate = true;
      endDate = false;
      showDatepicker = true;
    }
    const ymd = this.dateService.getYearMonthDayObject();
    return {
      showDatepicker: showDatepicker,
      label: dateLabel,
      type: 'date',
      value: ymd,
      startDate: startDate,
      endDate: endDate,
      fixedStartDayOfWeek: program.fixedStartDayOfWeek,
      startDayOfWeek: program.startDayOfWeek,
      todayIsMax: false
    };
  }

  createProgramDataObj(program) {
    let programDayOrWeekNo;
    let programUnit;
    if (program.duration >= 7) {
      programDayOrWeekNo = Math.floor(program.duration / 7);
      programUnit = 'week';
    } else {
      programDayOrWeekNo = program.duration;
      programUnit = program.duration > 1 ? 'days' : 'day';
    }
    return {
      programUnit,
      programDayOrWeekNo,
      programDuration: program.duration
    };
  }

  displayPersonalisationQuestionnaire(program) {
    const notificationSubtitle =
      'Please select which notifications you would like to receive for this program. You can change these settings at any time.';
    const date = this.createDateObj(program);
    const toggles = ['App', 'Web', 'Email'];

    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.confirmButtonLabel = date.showDatepicker
      ? 'Set preferences'
      : 'Join program';
    modal.componentInstance.title = program.title;
    if (program.personalisationQuestionnaire) {
      modal.componentInstance.date = date;
      modal.componentInstance.programData = this.createProgramDataObj(program);
    }
    if (this.userService.profile.secondaryEmail) {
      modal.componentInstance.existingSecondaryEmail = this.userService.profile.secondaryEmail;
    } else {
      modal.componentInstance.existingSecondaryEmail = null;
    }
    modal.componentInstance.isProgram = true;
    modal.componentInstance.closeButtonLabel = 'Cancel';
    modal.componentInstance.toggles = toggles;
    modal.componentInstance.toggleMessage = notificationSubtitle;

    modal.result
      .then((result) => {
        const toggleResult = {};
        Object.assign({}, result.toggleForm, {
          togglesOn: result.toggleForm.controls.togglesOn.value.map((selected, i) => {
            const toggleVar = toggles[i];
            toggleResult[toggleVar] = selected;
          })
        });
        let useSecondaryEmail = false;
        if (result.emailSelection.value === 'secondary') {
          useSecondaryEmail = true;
        }
        let dateValue;
        const dateNow = new Date();
        if (result.date.startDate) {
          // if the program lets the user set a date
          dateValue = result.date.value;
        } else {
          // otherwise the (start)date is today
          dateValue = dateNow;
        }
        const dateResult = {
          startDate: date.startDate,
          endDate: date.endDate,
          date: dateValue,
          dateNow: dateNow
        };
        const body = {
          toggleResult,
          dateResult,
          program,
          useSecondaryEmail
        };
        this.programService.createUserProgram(body).subscribe((res) => {
          this.displayModalWelcome(res.program);
        });
      })
      .catch((error) => {
        // quit, so don't join program;
        console.log(error);
      });
  }
  private displayModalWelcome(program) {
    const welcomeMsg = this.programService.getProgramWelcomeMessage(program);

    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Congratulations!';
    modal.componentInstance.singleButton = true;
    modal.componentInstance.message = welcomeMsg;
    modal.componentInstance.closeButtonLabel = 'Close';

    this.openProgram(program);
  }

  canJoinProgram(program: IUserProgram) {
    return this.programService.canUserJoinProgram(program);
  }
}
