<div *ngIf="canShowCurrentUnreadCount">
  <div class="fabTop">{{ numberUnread }}</div>
</div>

<div ngbDropdown class="d-inline-block"
  #myDropdown="ngbDropdown"
  [placement]="['bottom-right']"
  [autoClose]="'outside'"
  (mouseenter)="onMouseEnter($event)"
  (mouseleave)="onMouseLeave($event)"
>
  <a class="nav-link nav-menu" ngbDropdownToggle>
    <i class="fa fa-bell" aria-hidden="true"></i>
  </a>
  <div ngbDropdownMenu>
    <div class="notification-div p-2">
      <p>Notifications
        <a class="float-right" (click)="markAllRead()">Mark all read</a>
      </p>
    </div>
    <div *ngFor="let notification of notifications"
      class="notification-div p-2"
      (click)="openPage(notification)">
      <div class="circle-container">
        <div class="flex">
          <p>{{notification.notificationCategory.message}}</p>
          <small> {{notification.createdAt | timeFromNow}}
          </small>
        </div>
        <div *ngIf="!notification.read"
          class="primaryColor circle">
        </div>
      </div>
      <p><b>
          {{notification.message}}</b>
      </p>
    </div>
    <div class="view-all">
      <a class="mx-auto center-button text-center"
        (click)="viewAll()">View All</a>
    </div>
  </div>
</div>
