import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFriend } from '../../../_models/friend';

@Component({
  selector: 'app-suggested-friends',
  templateUrl: './suggested-friends.component.html',
  styleUrls: ['./suggested-friends.component.scss']
})
export class SuggestedFriendsComponent {
  @Input() suggestedFriends: IFriend[] = [];
  @Output() viewFriend = new EventEmitter<IFriend>();
  @Output() addFriend = new EventEmitter<IFriend>();

  constructor() { }

  onViewFriend(suggested: IFriend) {
    this.viewFriend.emit(suggested);
  }

  onAddFriend(suggested: IFriend) {
    this.addFriend.emit(suggested);
  }
}
