<div class="container p-0">
  <div class="row">
    <div *ngIf="activatedRoute.children.length === 0" class="col-12">
      <app-menu-card [features]="features" [defaultColumns]="defaultColumns" [subFilterCards]="subFilterCards"></app-menu-card>
    </div>
    <div *ngIf="activatedRoute.children.length > 0" class="col-12 pt-2">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
