<app-cms-list-view [dataList]="items" [columnList]="columns" *ngIf="columns.length > 0">
</app-cms-list-view>

<div *ngIf="(isAddingItem || isEditingItem) && isInitialised" class="padding jumbotron">

  <form [formGroup]="itemForm" (ngSubmit)="onSaveClick()">

    <div class="row m-0">
      <div class="col-12">
        <!-- Name -->
        <div class="form-group">
          <label class="control-label required">Name</label>
          <input type="text" class="form-control" [ngClass]="displayFieldCss('nameControl')" formControlName="nameControl" />
          <span class="invalid-feedback">Name is required</span>
        </div>

        <!-- Description -->
        <div class="form-group">
          <label class="control-label required">Description</label>
          <textarea class="form-control" [ngClass]="displayFieldCss('descriptionControl')" formControlName="descriptionControl"></textarea>
          <span class="invalid-feedback">Description is required</span>
        </div>

        <!--- Level -->
        <div class="row">
          <div class="col-12 col-sm-5">
            <div class="form-group">
              <label class="control-label required">Level</label>
              <select id="badgeLevel" class="form-control custom-select" formControlName="badgeLevelControl" [ngClass]="displayFieldCss('badgeLevelControl')">
                <option *ngIf="!itemForm.get('badgeLevelControl')?.value" value="null" selected>Select a Level</option>
                <option *ngFor="let item of badgeLevels" [value]="item.value">{{item.name}}
                </option>
              </select>
              <span class="invalid-feedback">Level is required</span>
            </div>
          </div>

          <!--- Goal -->
          <div class="col-12 col-sm-2">
            <div class="form-group">
              <label class="control-label required">Goal</label>
              <input type="number" class="form-control" formControlName="goalControl" [ngClass]="displayFieldCss('goalControl')" />
              <span class="invalid-feedback">Goal is required</span>
            </div>
          </div>

          <!-- domain-->
          <!-- <div class="col-12 col-sm-5">
            <div class="form-group">
              <label class="control-label">Domain</label>
              <select id="domain" class="form-control custom-select" formControlName="domainIdControl">
                <option value=null selected>All domains</option>
                <option *ngFor="let item of domains" [value]="item.id">{{item.name}} ({{item.driver}})
                </option>
              </select>
            </div>
          </div> -->
        </div>

        <!-- type  -->
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label class="control-label required">Type</label>
              <select class="form-control custom-select" formControlName="typeControl" [ngClass]="displayFieldCss('typeControl')" (change)="typeSelected(null)">
                <option value=null selected> :: select a type :: </option>
                <option *ngFor="let item of types" [value]="item.value">{{item.label | startCase }}

                </option>
              </select>
            </div>
          </div>

          <!--- Type Id -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label class="control-label" [ngClass]="isTypeIdRequired() ? 'required' : ''">Type Id</label>
              <div *ngIf="type && type === 'feature' || type === 'questionnaire' || type === 'indicatorDriver' || type === 'achievedIndicator' || type === 'trackedIndicator' || type === 'program'">
                <select class="form-control custom-select" type="number" class="form-control" formControlName="typeIdControl" [ngClass]="isTypeIdRequired() ? displayFieldCss('typeIdControl') : ''">
                  <option value=null selected> :: select a typeId :: </option>
                  <option *ngFor="let item of typeId" [value]="item.id">{{item.name}}
                </select>
              </div>
              <div *ngIf="type && type === 'article' || type === 'recipe' || type === 'video'">
                <input formControlName="typeIdControl" placeholder="id" />
              </div>
              <div *ngIf="type && type === 'questionnaireResult'">
                <div *ngFor="let item of typeId" class="custom-control custom-radio custom-control-inline mt-2">
                  <input type="radio" id="{{item.id}}" [value]="item.id" ng-value="true" formControlName="typeIdControl" class="custom-control-input mr-2"
                  />
                  <label class="custom-control-label" for="{{item.id}}">Title:
                    <b>{{item.name}}</b>
                    <br /> Operand: {{item.operand}}
                    <br /> {{item.description}}
                  </label>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- Buttons -->
        <br />
        <div class="row pt-3">

          <div class="col-8" *ngIf="!item.userEdit">
            <span class="text-danger">*** System badge not editable.</span>
          </div>

          <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
            <button type="button" class="btn btn-outline-primary w-100" (click)="onBackClick()">
              <i class="fa fa-undo mr-2" aria-hidden="true"></i> BACK
            </button>
          </div>

          <div *ngIf="isEditingItem && item.userEdit" class="col-4">
            <button type="button" class="btn btn-outline-primary w-100" [disabled]="!canRespondToButtons" (click)="onDeleteClick()">
              <i class="fa fa-trash mr-2" aria-hidden="true"></i> DELETE
            </button>
          </div>

          <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }" *ngIf="item.userEdit">
            <button type="submit" class="btn btn-primary float-right w-100" [disabled]="!canRespondToButtons">
              <i class="fa fa-floppy-o mr-2" aria-hidden="true"></i> SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
