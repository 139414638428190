<div [ngClass]="cardClass">
  <i *ngIf="item.locked"
    class="fa fa-lock fa-5x lock" aria-hidden="true"></i>
  <div class="img-size-div">
    <img [ngClass]="item.locked
      ?'card-img-top img-size opacity'
      :'card-img-top img-size'"
      [src]="itemImageSrc" />
  </div>
  <div class="row m-0 p-2 mb-2">
    <div class="col-12 p-0">
      <div *ngIf="!isLocked"
        class="float-left" >
        <app-like-indicator
          [liked]="item.isLiked"
          [likes]="item.likes"
          [isLoggedIn]="isLoggedIn"
          (click)="onLikeClick($event)"
        ></app-like-indicator>
      </div>
      <div *ngIf="isLoggedIn"
        class="float-right">
        <app-bookmark-indicator
          [bookmarked]="item.isBookmarked"
          (click)="onBookmarkClick($event, item)"
        ></app-bookmark-indicator>
      </div>
    </div>
  <div class="content-card-title">
    {{ item.title }}
  </div>
  <div *ngIf="!isProgram"
    class="content-card-body">
    <p class="card-text">{{ getItemText(item) }}</p>
  </div>
</div>
