import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { Chart } from 'chart.js';

@Component({
  selector: 'app-bar-graph',
  templateUrl: 'bar-graph.html',
})

export class BarGraphComponent implements OnInit {
  @Input('data') data: any;
  @Input('labels') labels: any;
  @Input('color') color: any;
  @Input('legendLabel') legendLabel: any;
  @Input('legend') legend: any;
  @Input('xTitle') xTitle: string = '';
  @Input('yTitle') yTitle: string = '';
  @Input('hideGrids') hideGrids: boolean = false;
  @Input('yStepSize') yStepSize: number;

  @ViewChild('barCanvas') barCanvas;
  barChart: any;

  constructor() {
  }

  ngOnInit() {
    this.createBarChart();
  }

  createBarChart() {
    let params: any = {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: [{
          label: this.legendLabel,
          data: this.data,
          backgroundColor: this.color,
          borderColor: this.color,
          borderWidth: 1,
        }],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              // stepSize: 0.1
            },
            gridLines: {
              drawOnChartArea: !this.hideGrids,
            },
            scaleLabel: {
              display: !!this.yTitle,
              labelString: this.yTitle,
              fontColor: '#000',
            },
          }],
          xAxes: [{
            barPercentage: 0.4,
            // barThickness: 100, // number (pixels)
            // maxBarThickness: 8, // number (pixels)
            gridLines: {
              drawOnChartArea: !this.hideGrids,
            },
            scaleLabel: {
              display: !!this.xTitle,
              labelString: this.xTitle,
              fontColor: '#000',
            },
          }],
        },
      },
    };

    if (this.yStepSize) {
      params.options.scales.yAxes[0].ticks.stepSize = this.yStepSize;
    }

    this.barChart = new Chart(this.barCanvas.nativeElement, params);
  }
}
