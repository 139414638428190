<div class="modal-header">
  <h4 class="modal-title">
    Close My AltiusLife Account
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="!(usedSSO$ | async)">
  <p>Please provide your login details to confirm the closing of your AltiusLife account</p>
  <span class="invalid-message" *ngIf="closeAccountError">{{closeAccountError}}</span>
  <form [formGroup]="closeAccountForm" (ngSubmit)="processCloseAccount()" autocomplete="off">
    <div class='mb-3 form-group'>
      <small><label class='control-label required'>Email</label></small>
      <input class='form-control form-control-sm' type='text' formControlName='email' name="email" placeholder="Email" autocomplete="off" />
      <span class="invalid-message" *ngIf="showError && !isFormFieldValid('email')">Please enter a valid email!</span>
    </div>
    <div class="form-group">
      <app-password-input [parentForm]="closeAccountForm" [inputcss]="setInputOutlineClass(password)"
        [showConfirmPassword]="false"></app-password-input>
    </div>
    <div class="form-buttons">
      <button type="submit" class="btn btn-outline-dark">Close My Account</button>
    </div>
  </form>
</div>
<div class="modal-body" *ngIf="(usedSSO$ | async)">
  <p>Please authorize to confirm the closing of your AltiusLife account</p>
  <div class="form-buttons">
    <button (click)="ssoAuthorize()" class="btn btn-outline-dark">Authorize</button>
  </div>
</div>
<!-- <div *ngIf="isLoading" class="row justify-content-center mb-2">
  <i class="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
</div> -->
