<div *ngIf="isInitialised">
  <div class="modal-header">
    <h4 class="modal-title">{{data.name}} history</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p class="text-muted w-100 text-center">Goal:</p>
    <h5 class="primaryColor w-100 text-center"> {{data.goalDisplay}}</h5>
    <div *ngIf="!hasData" align="center"><p>{{noData}}</p></div>
    <app-line-graph *ngIf="hasData"
      class="chart"
      [data]="data.chartData" [labels]="data.chartLabels"
      [legend]="false"  [color]="chartingService.chartColor"
      [pointRadius]="data.chartPointRadius"
      [pointColor]="data.chartPointBackgroundColors || chartingService.chartColor"
      [pointColor]="chartingService.dotColor || chartingService.chartColor"
      [maxValue]="data.chartMaxValue"
      [fill]="true" [minLabelRot]="30"
      [dataValue]="chartingService.drawLineGraphValues"
      [lineTension]="0">
    </app-line-graph>
  </div>
</div>
