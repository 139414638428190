<div>
    <label *ngIf="label"
      for="company-id">{{label}}</label>
    <select
      id="company-id"
      class="form-control custom-select"
      [(ngModel)]="selectedCompanyId"
      (change)="onCompanySelected($event)">
      <option *ngIf="placeholder"
        value="0" selected>{{placeholder}}</option>
      <option *ngFor="let company of allCompanies"
        [value]="company.id">
        {{company.name}}
      </option>
    </select>
  </div>
