import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import { UtilService } from '../../services/util-service';
import { UserService } from '../../services/user-service';
import { LogService } from '../../services/log.service';
import { CategoryService } from '../../services/category-service';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit {
  preferenceHeadings: any;
  preferences: any;

  constructor(
    private userService: UserService,
    private utilService: UtilService,
    private activeModal: NgbActiveModal,
    private logService: LogService,
    private categoryService: CategoryService
  ) {}

  ngOnInit() {
    this.logService.logPreferences();
    this.getPreferences();
  }

  getPreferencesByHeading(heading: string) {
    return this.preferences.filter((i) => i.featureName = heading);
  }

  getPreferences() {
    this.userService.getPreferences()
      .subscribe(returnedData => {
        returnedData = returnedData.map(c => {
          c.categoryName = this.categoryService.getCategoriesName(c.categoryName);
          return c;
        });
        this.preferences = _.groupBy(returnedData, 'featureName');
        this.preferenceHeadings = Object.keys(this.preferences);
        this.preferenceHeadings.map((group) => {
          return this.preferences[group].sort((a, b) => {
            return a.categoryName.toLowerCase().localeCompare(b.categoryName.toLowerCase());
          });
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  updatePreferences() {
    const ids = this.preferenceHeadings.map(heading => {
      return this.preferences[heading].filter(e => e.activated)
        .map(e => e.id);
    });

    const flattenedPreferences = _.flattenDeep(ids) as [];
    this.userService.updatePreferences(flattenedPreferences)
      .subscribe(() => {
        this.activeModal.close();
        this.utilService.showToastSuccess('Update successful');
      },
      err => {
        console.log(err);
        this.activeModal.close();
        this.utilService.showToastError('Update failed');
      }
    );
  }

  closeModal() {
    this.activeModal.close();
  }
}
