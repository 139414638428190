import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth-service';
import { UserService } from '../../../services/user-service';

import { environment } from '../../../environments/_active-environment/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-secure-login',
  templateUrl: './secure-login.component.html',
  styleUrls: ['./secure-login.component.scss']
})
export class SecureLoginComponent implements OnInit {

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public route: ActivatedRoute,
    public router: Router,
    public modalService: NgbModal
  ) {

  }

  ngOnInit() {
    this.parseQueryParams();
  }

  parseQueryParams() {
    this.route.queryParams.subscribe((params) => {
      this.userService.token = params.token;
      this.userService.temporaryToken = params.token;
      this.userService.getLocalStorageUser().subscribe((res) => {
        if (this.isUserProfileRequired(res)) {
          this.userService.user = res;
          this.userService.updateTimezoneAndLastlogin();
          if (res.company) {
            this.userService.company = res.company;
          }
          this.userService.changeProfilePicObs();
          this.commenceNewUserProfileModalChain(res.id, res.company);
        } else {
          this.userService.userId = res.id;
          this.userService.user = res;
          this.userService.profile = res.userProfile;
          this.userService.envVariables = environment;
          this.userService.updateTimezoneAndLastlogin();
          this.userService.changeProfilePicObs();
          this.router.navigateByUrl('/home').then(() => {
            location.reload();
          });
        }
      });
    });
  }

  private showBackgroundForUserProfileModal(company) {
    this.router.navigateByUrl('/create');
    this.userService.loadTheming(company);
  }

  commenceNewUserProfileModalChain(userId: string, company: any) {
    this.showBackgroundForUserProfileModal(company);
  }

  private isUserProfileRequired(res): boolean {
    return res.userProfile === null;
  }
}


