import { Component, OnInit } from '@angular/core';
import { LogService } from '../../../services/log.service';
import { UserService } from '../../../services/user-service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '../../../environments/_active-environment/environment';

@Component({
  selector: 'app-my-rewards-widget',
  templateUrl: './my-rewards-widget.component.html',
  styleUrls: ['./my-rewards-widget.component.scss'],
})
export class MyRewardsWidgetComponent implements OnInit {
  showMyRewardsSrc: string;
  brand: string;
  myRewardsAction: string;
  userEmail: string;
  myRewardsDomainUrl: string;
  myRewardsDomainCode: number;
  companyId: number;
  companyRewardsWidgetText: SafeHtml;
  hasCompanyRewardsWidgetText: Boolean = false;
  genericHTML = `<p>From discounted flights to weekly cinema tickets, ${this.brand} rewards you for being healthy.</p>`;

  constructor(
    private userService: UserService,
    private logService: LogService,
    private domSanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    if (
      this.userService.company.rewardsWidgetText !== null &&
      this.userService.company.rewardsWidgetText !== ''
    ) {
      this.companyRewardsWidgetText = this.domSanitizer.bypassSecurityTrustHtml(
        this.userService.company.rewardsWidgetText,
      );
      this.hasCompanyRewardsWidgetText = true;
    }
    this.brand = this.userService.brand;
    this.showMyRewardsSrc = this.userService.getMyRewardsSpinnerSrc();
    this.myRewardsAction = this.userService.myRewardsAction;
    this.userEmail = this.userService.user.email;
    this.myRewardsDomainUrl = this.userService.myRewardsDomainUrl;
    this.myRewardsDomainCode = this.userService.myRewardsDomainCode;
    this.companyId = this.userService.company.id;
  }

  onViewRewardsClick() {
    window.open(
      `${environment.app.endpoint}/my-reward?action=${this.myRewardsAction}&email=${this.userEmail}&domain_url=${this.myRewardsDomainUrl}&domain_code=${this.myRewardsDomainCode}&company_id=${this.companyId}&registered=1`,
    );
  }
}
