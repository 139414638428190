<div *ngIf="isInitialised" class="jumbotron">
  <form [formGroup]="itemForm" (ngSubmit)="isVariableFieldsValid() && onSaveClick()">
    <div class="row m-0">
      <div class="col-12">
        <!-- Title -->
        <div class="form-group">
          <label class="control-label required">Title</label>
          <input type="text" class="form-control" [ngClass]="displayFieldCss('titleControl')" formControlName="titleControl" />
          <span class="invalid-feedback">Title is required</span>
        </div>
        <!-- Description -->
        <div class="form-group">
          <label class="control-label required">Description</label>
          <div [froalaEditor]="options" formControlName="descriptionControl"></div>
          <span class="invalid-feedback">Description is required.</span>
        </div>

        <!-- Principal Result -->
        <div class="form-group" *ngIf="questionnaire.liqResults.length > 0 && questionnaire.liqResultTypeId > 2">
          <label class="control-label">Result related to
            <small>(Select one principal result if that result is part of a group of results)</small>
          </label>
          <select class="form-control custom-select" formControlName="principalResultIdControl">
            <option value=null selected>None - This result is independent</option>
            <option *ngFor="let item of questionnaire.liqResults" [value]="item.id">{{item.title}}
            </option>
          </select>
        </div>

        <div class="row" *ngIf="questionnaire.liqResultTypeId < 3">
          <!--- Redirect Page -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label class="control-label">Redirect to
                <small>( NONE will redirect to Home Page)</small>
              </label>
              <select class="form-control custom-select" formControlName="redirectPageControl">
                <option value="" selected>NONE</option>
                <option value="QuestionnairesPage">Questionnaires list</option>
                <option value="QuestionnairePage">Questionnaire Detail</option>
                <option value="PartnersPage">Partners list</option>
                <option value="PartnerPage">Partner Detail</option>
                <option value="ContentDetailPage">Content Detail</option>
              </select>
            </div>
          </div>
          <!-- Redirect button -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label class="control-label">Redirect button label</label>
              <input type="text" class="form-control" formControlName="redirectButtonNameControl" />
            </div>
          </div>

          <!-- redirect params -->
          <div class="col-12" *ngIf="redirectParamsAreRequired()">
            <div class="form-group">
              <select class="form-control custom-select" formControlName="redirectContentType" *ngIf="isContentRedirect()">
                <option value="" selected disabled>Select</option>
                <option value="article">Article
                </option>
                <option value="recipe">Recipe
                </option>
                <option value="video">Article
                </option>
              </select>
              <div class="form-group">
                <label class="control-label">Redirect page ID (e.g. partner id, content id, questionnaire id)</label>
                <input type="number" class="form-control" formControlName="redirectId" />
              </div>

            </div>
          </div>
        </div>

        <!-- Answer Dependency -->
        <div class="form-group" *ngIf="!isActiveResultType()">
          <label class="control-label">Answer Dependency
            <small>(Select one if that result depends on the answer be selected)</small>
          </label>
          <select class="form-control custom-select" formControlName="questionDependencyIdControl" (change)="setQuestionAnswers(this.itemForm.value.questionDependencyIdControl)">
            <option value="" selected>None</option>
            <option *ngFor="let item of questionnaire.liqQuestions" [value]="item.id">{{item.question}}
            </option>
          </select>

          <div class="row mt-2" *ngIf="answersDependency.length > 1">
            <div class="col-12 pl-3" *ngFor="let asr of answersDependency">
              <div class="custom-control custom-radio custom-control-inline mt-2">
                <input value="{{asr.id}}" id="ad{{asr.id}}" type="radio" formControlName="answerDependencyIdControl"
                  class="custom-control-input">
                <label class="custom-control-label" for="ad{{asr.id}}">{{asr.label}}</label>
              </div>
            </div>
          </div>

        </div>

        <div class="row mt-2" *ngIf="questionnaire.liqResultTypeId > 1">
          <!-- calculation questions -->
          <div class="col-12 col-sm-6">
            <div class="form-group">

              <label class="control-label required">Calculation questions
                <small>(Select the question will be part of the calculation)</small>
              </label>
              <div class="custom-control custom-checkbox" *ngFor="let q of questions; let i = index">
                <input id="{{q.id}}" type="checkbox" checked="{{q.checked}}" class="custom-control-input" (click)="changeStatusQuestions(i)">
                <label class="custom-control-label" for="{{q.id}}">{{q.question}}</label>
                <p><small *ngIf="q.dependencyParams && q.checked"><b>Dependent upon: "{{q.dependencyParams.question}}"</b></small></p>
                <p><small *ngIf="!q.dependencyParams"></small></p>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <!-- Calculus-->
            <div class="form-group">
              <label class="control-label required">Calculus
                <small>(E.g 'points' or 'value' if the calculation will be the sum of the answers values)</small>
              </label>
              <select class="custom-select form-control" formControlName="calculusControl" [ngClass]="isValidControl('calculusControl', this.questionnaire.liqResultTypeId > 1) ? '' : 'is-invalid'">
                <option value="" selected disabled>Select</option>
                <option value="points">Sum of question points</option>
                <option value="values">Sum of question input values</option>
              </select>
              <span class="invalid-feedback">Calculus is required</span>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="this.questionnaire.liqResultTypeId > 1">
          <!--- operand -->
          <div class="col-6 col-sm-4">
            <div class="form-group">
              <label class="control-label required">Operand</label>
              <select class="form-control custom-select" formControlName="operandControl" [ngClass]="isValidControl('operandControl', this.questionnaire.liqResultTypeId > 1) ? '' : 'is-invalid'">
                <option value="" selected disabled>Select</option>
                <option value="equal">Equal to</option>
                <option value="less">Less than</option>
                <option value="more">More than</option>
                <option value="between">Between</option>
                <option value="notIn">Not between</option>
              </select>
              <span class="invalid-feedback">Operand is required</span>
            </div>
          </div>
          <!--- Value -->
          <div class="col-6 col-sm-4">
            <div class="form-group">
              <label class="control-label" [ngClass]=" isValue1Required() ? 'required' : ''">Value</label>
              <input type="number" class="form-control" formControlName="value1Control" [ngClass]="isValidControl('value1Control', isValue1Required()) ? '' : 'is-invalid'" />
              <span class="invalid-feedback">Value is required if a operand is selected</span>
            </div>
          </div>
          <!--- Value 2 -->
          <div class="col-6 col-sm-4" *ngIf="this.itemForm.value.operandControl === 'between' || this.itemForm.value.operandControl === 'notIn'">
            <div class="form-group">
              <label class="control-label required">Value
                2
              </label>
              <input type="number" class="form-control" formControlName="value2Control" [readonly]="this.itemForm.value.operandControl !== 'between' && this.itemForm.value.operandControl !== 'notIn'"
                [ngClass]="isValidControl('value2Control', this.questionnaire.liqResultTypeId > 1 && (
                  this.itemForm.value.operandControl === 'between' || this.itemForm.value.operandControl === 'notIn')) ? '' : 'is-invalid'" />
              <span class="invalid-feedback">Value 2 is required if any operand between is selected</span>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="questionnaire.liqResultTypeId > 2">
          <!-- color -->
          <div class="col-6 col-sm-4">
            <div class="form-group">
              <label class="control-label required w-100">Card Color </label>
              <span class="colorDiv" [(colorPicker)]="this.itemForm.value.colorControl" [style.backgroundColor]="this.itemForm.value.colorControl"
                [cpOutputFormat]="hex" (colorPickerSelect)="changeColor(this.itemForm.value.colorControl)" [cpOKButton]="true"></span>
              <input type="text" maxlength="9" class="form-control colorTextBox" formControlName="colorControl"
                [ngClass]="isValidControl('colorControl', this.questionnaire.liqResultTypeId > 2) ? '' : 'is-invalid'" />
              <span class="invalid-feedback">Card Color is required</span>
            </div>
          </div>
          <!-- Unit -->
          <div class="col-6 col-sm-4">
            <div class="form-group">
              <label class="control-label">Unit </label>
              <input type="text" class="form-control" formControlName="unitControl" />
            </div>
          </div>

          <!-- Answer Label -->
          <div class="col-6 col-sm-4">
            <div class="form-group">
              <label class="control-label">Card Answer Label</label>
              <input type="text" class="form-control" formControlName="userAnswerLabelControl" />
            </div>
          </div>

          <!-- Minimun chart value -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label class="control-label">Minimun chart value</label>
              <input type="number" class="form-control" formControlName="minValueChartControl" />
            </div>
          </div>

          <!-- Maximun  chart value -->
          <div class="col-12  col-sm-6">
            <div class="form-group">
              <label class="control-label">Maximum chart value</label>
              <input type="number" class="form-control" formControlName="maxValueChartControl" />
            </div>
          </div>
        </div>

        <!-- Buttons -->
        <br />
        <div class="py-3">
          <button type="submit" class="btn btn-primary float-right" [disabled]="!canRespondToButtons">
            <i class="fa fa-floppy-o mr-2" aria-hidden="true"></i> SAVE
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
