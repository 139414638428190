import { IStripePlan } from '../../_models/stripe-plan';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-stripe-plans',
  templateUrl: './stripe-plans.component.html',
  styleUrls: ['./stripe-plans.component.scss']
})
export class StripePlansComponent implements OnInit {
  @Input() plans: IStripePlan[] = [];
  @Input() primaryColor: string;
  @Output() planSelected: EventEmitter<IStripePlan> = new EventEmitter();
  selectedPlanOnClick = false;
  chosenPlan: any;


  constructor(

  ) { }

  ngOnInit() {
  }

  onSelectPlan(event, selectedPlan: IStripePlan) {
    event.preventDefault();
    event.stopPropagation();
    this.markSelectedPlan(selectedPlan);
    this.planSelected.emit(selectedPlan);
    this.selectedPlanOnClick = true;
    this.chosenPlan = selectedPlan;
  }

  deselectPlan(event, selectedPlan: IStripePlan) {
    event.preventDefault();
    event.stopPropagation();
    this.planSelected.emit(null);
    this.selectedPlanOnClick = false;
    selectedPlan.isSelected = false;
    if (selectedPlan !== this.chosenPlan) {
      this.chosenPlan = null;
      this.planSelected.emit(selectedPlan);
      this.selectedPlanOnClick = true;
      selectedPlan.isSelected = true;
      this.chosenPlan = selectedPlan;
    }
  }

  markSelectedPlan(selectedPlan: IStripePlan) {
    this.plans.forEach(p => {
      p.isSelected = (p.planId === selectedPlan.planId);
    });
  }

  getColor(plan: IStripePlan) {
    return (plan && plan.isSelected && plan === this.chosenPlan)
      ? '#fff'
      : '#575b5c';
  }

  getHeaderBackgroundColor(plan: IStripePlan) {
    return (plan && plan.isSelected && plan === this.chosenPlan)
      ? this.primaryColor
      : 'rgba(0,0,0,0.03)';
  }

  getBodyBackgroundColor(plan: IStripePlan) {
    return (plan && plan.isSelected && plan === this.chosenPlan)
      ? this.primaryColor
      : '#fff';
  }
}
