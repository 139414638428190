<div class="container">
  <h2>Reset Password</h2>
  <form [formGroup]="form" (ngSubmit)="onSubmitResetPasswordForm()">


    <div class="form-group">
      <app-password-input [parentForm]="form" [inputcss]="setInputOutlineClass(confirmPassword)"
        [showConfirmPassword]="true"></app-password-input>
    </div>

    <button type="submit" class="btn btn-primary" [disabled]="isFormSubmitDisabled()">Reset Password</button>

  </form>
</div>