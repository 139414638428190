<div *ngIf='isInitialised' class='button-container'>
  <div class='row'>
    <div class='col-4 offset-8 text-right'>
      <button class='btn btn-primary mr-3' (click)='exportData()'>
        <i class='fa fa-download' aria-hidden='true'></i>
      </button>
    </div>
  </div>
</div>
<app-cms-list-view *ngIf='isInitialised' [dataList]='items' [columnList]='columns'
                   [isPreventListDelete]='true' [isPreventAdd]='true' [hideSearch]='true'>
</app-cms-list-view>
