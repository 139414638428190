import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogService } from '../../../services/log.service';
import { FriendsService } from '../../../services/friends-service';
import { UserService } from '../../../services/user-service';
import { IFriend } from '../../_models/friend';
import { IFriendsTypes } from '../../_models/friends-types';
import { FriendPresentationComponent } from './friend-presentation/friend-presentation.component';
import { BadgeService } from '../../../services/badge-service';
import { IBadgeCount } from '../../_models/badge-count';
import { ISearchTerm } from '../../_models/searchTerm';
import { IFriendActions } from '../../_models/friend-actions';
import { UtilService } from '../../../services/util-service';

@Component({
  selector: 'app-friends-container',
  templateUrl: './friends-container.component.html',
  styleUrls: ['./friends-container.component.scss']
})
export class FriendsContainerComponent implements OnInit {
  @ViewChild('tabset') tabs;

  suggestedFriends: IFriend[];
  searchedFriends: IFriend[] = [];
  friends: IFriend[];
  requestsReceived: IFriend[];
  requestsSent: IFriend[];
  declined: IFriend[];

  canShowSuggestedFriends = false;
  canShowSearchResults = false;
  canShowEmptySearchResult = false;

  constructor(
    private friendsService: FriendsService,
    private logService: LogService,
    private userService: UserService,
    private badgeService: BadgeService,
    private utilService: UtilService,
    private modalCtrl: NgbModal
  ) { }

  ngOnInit() {
    this.logService.logFriends();
    this.getMySuggestedFriends();
    this.getAllMyActiveFriends();
  }

  private getMySuggestedFriends() {
    this.friendsService.getMySuggestedFriends()
      .subscribe((suggestedFriends: IFriend[]) => {
        this.suggestedFriends = [...this.setFriendsProperties(suggestedFriends)];
        this.canShowSuggestedFriends = true;
      });
  }

  private getAllMyActiveFriends() {
    this.friendsService.getAllMyActiveFriendsTypes()
      .subscribe((allFriendsTypes: IFriendsTypes) => {
        this.friends = [...this.setFriendsProperties(allFriendsTypes.friends)];
        this.requestsReceived = [...this.setFriendsProperties(allFriendsTypes.requestsReceived)];
        this.requestsSent = [...this.setFriendsProperties(allFriendsTypes.requestsSent)];
        this.declined = [...this.setFriendsProperties(allFriendsTypes.declined)];
      });
  }

  private setFriendsProperties(friends: IFriend[]): IFriend[] {
    friends.forEach((friend: IFriend) => {
      friend.photoSrc = this.userService.getProfilePicture(friend.photo);
      friend.levelSrc = this.userService.getLevelSrc(friend.level);
      friend.username ?
      friend.usernameTruncated = this.utilService.truncateForXS(friend.username, screen.width) :
      friend.usernameTruncated = friend.username;
    });

    return [...friends];
  }

  private setFriendProperties(friend: IFriend): IFriend {
    friend.photoSrc = this.userService.getProfilePicture(friend.photo);
    friend.levelSrc = this.userService.getLevelSrc(friend.level);
    friend.username ?
    friend.usernameTruncated = this.utilService.truncateForXS(friend.username, screen.width) :
    friend.usernameTruncated = friend.username;

    return {...friend};
  }

  onViewFriend(friend: IFriend) {
    this.badgeService
      .getUserBadgesSummary(friend.id)
      .subscribe((badgeCounts: IBadgeCount[]) => {
        this.openFriendPresentationModal(friend, badgeCounts);
      });
  }

  private openFriendPresentationModal(friend: IFriend, badgeCounts: IBadgeCount[]) {
    const modal = this.modalCtrl.open(FriendPresentationComponent);
    modal.componentInstance.friend = friend;
    modal.componentInstance.badgeCounts = badgeCounts;

    modal.result.then((selectedAction: IFriendActions) => {
      this.doSelectedAction(selectedAction);
    }, () => {});
  }

  private doSelectedAction(selectedAction: IFriendActions) {
    if (selectedAction.sendFriendRequest) {
      this.sendFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.cancelSentFriendRequest) {
      this.cancelSentFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.confirmReceivedFriendRequest) {
      this.confirmReceivedFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.declineReceivedFriendRequest) {
      this.declineReceivedFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.removeConfirmedFriend) {
      this.removeConfirmedFriend(selectedAction.actionedFriend);
    }
  }

  private isSuggestedFriend(friend: IFriend): boolean {
    const index = this.suggestedFriends.findIndex(f => f.id === friend.id);

    return index >= 0;
  }

  private isSearchedFriend(friend: IFriend): boolean {
    const index = this.searchedFriends.findIndex(f => f.id === friend.id);

    return index >= 0;
  }

  private replaceSearchedFriendsItem(friend: IFriend) {
    const index = this.searchedFriends.findIndex(f => f.id === friend.id);

    this.searchedFriends[index] = this.setFriendProperties(friend);
  }

  private activateOutgoingTab() {
    this.tabs.select('outgoing');
  }

  private activateRequestsTab() {
    this.tabs.select('requests');
  }

  private activateConnectedTab() {
    this.tabs.select('connected');
  }

  private sendFriendRequest(friend: IFriend) {
    this.friendsService.sendFriendRequest(friend.id)
      .subscribe((requestedFriend: IFriend) => {
        const msg = `Successfully sent a friend request for ${requestedFriend.username}`;
        this.utilService.showToastSuccess('Friend Request Sent', msg);

        this.getAllMyActiveFriends();
        this.activateOutgoingTab();

        if (this.isSuggestedFriend(friend)) {
          this.getMySuggestedFriends();
        }

        if (this.isSearchedFriend(friend)) {
          this.replaceSearchedFriendsItem(requestedFriend);
        }
      }, (err) => {
        console.log('sendFriendRequest error', err);
        const msg = `Unable to send a friend request for ${friend.username}`;
        this.utilService.showToastError('Friend Request Error', msg);
      });
  }

  private cancelSentFriendRequest(friend: IFriend) {
    this.friendsService.cancelSentFriendRequest(friend.id)
      .subscribe((cancelledFriend: IFriend) => {
        const msg = `Cancelled your sent friend request to ${cancelledFriend.username}`;
        this.utilService.showToastSuccess('Cancel sent friend request', msg);

        this.getAllMyActiveFriends();
        this.activateOutgoingTab();

        if (this.isSearchedFriend(friend)) {
          this.replaceSearchedFriendsItem(cancelledFriend);
        }
      }, (err) => {
        console.log('cancelSentFriendRequest error', err);
        const msg = `Unable to cancel the friend request for ${friend.username}`;
        this.utilService.showToastError('Cancel Friend Request Error', msg);
      });
  }

  private confirmReceivedFriendRequest(friend: IFriend) {
    this.friendsService.confirmReceivedFriendRequest(friend.id)
      .subscribe((confirmedFriend: IFriend) => {
        const msg = `Successfully confirmed friend ${confirmedFriend.username}`;
        this.utilService.showToastSuccess('Confirm Friend', msg);

        this.getAllMyActiveFriends();
        this.activateConnectedTab();

        if (this.isSearchedFriend(friend)) {
          this.replaceSearchedFriendsItem(confirmedFriend);
        }
      }, (err) => {
        console.log('confirmReceivedFriendRequest error', err);
        const msg = `Unable to confirm the friend request from ${friend.username}`;
        this.utilService.showToastError('Confirm Friend Request Error', msg);
      });
  }

  private declineReceivedFriendRequest(friend: IFriend) {
    this.friendsService.declineReceivedFriendRequest(friend.id)
      .subscribe((declinedFriend: IFriend) => {
        const msg = `Declined the friend rerequest received from ${declinedFriend.username}`;
        this.utilService.showToastSuccess('Decline Friend Request', msg);

        this.getAllMyActiveFriends();
        this.activateRequestsTab();

        if (this.isSearchedFriend(friend)) {
          this.replaceSearchedFriendsItem(declinedFriend);
        }
      }, (err) => {
        console.log('declineReceivedFriendRequest error', err);
        const msg = `Unable to decline the friend request from ${friend.username}`;
        this.utilService.showToastError('Decline Friend Request Error', msg);
      });
  }

  private removeConfirmedFriend(friend: IFriend) {
    this.friendsService.removeConfirmedFriend(friend.id)
      .subscribe((removedFriend: IFriend) => {
        const msg = `Removed friend ${removedFriend.username}`;
        this.utilService.showToastSuccess('Remove Friend', msg);

        this.getAllMyActiveFriends();
        this.activateConnectedTab();

        if (this.isSearchedFriend(friend)) {
          this.replaceSearchedFriendsItem(removedFriend);
        }
      }, (err) => {
        console.log('removeConfirmedFriend error', err);
        const msg = `Unable to remove the friend ${friend.username}`;
        this.utilService.showToastError('Remove Friend Error', msg);
      });
  }

  onAddFriend(friend: IFriend) {
    this.sendFriendRequest(friend);
  }

  onSearchTermEntered(searchTermObject: ISearchTerm) {
    this.canShowSearchResults = false;
    const searchTerm = searchTermObject.term;
    if (!searchTerm.trim().length) {
      this.searchedFriends = [];
      this.canShowEmptySearchResult = false;
      return;
    }

    this.friendsService.getSearchedFriends(searchTerm)
      .subscribe(searchResults => {
        this.searchedFriends = [...this.setFriendsProperties(searchResults)];
        this.canShowSearchResults = !!this.searchedFriends.length;
        this.canShowEmptySearchResult = !this.searchedFriends.length;
      });
  }

  onActionSelected(selectedAction: IFriendActions) {
    this.doSelectedAction(selectedAction);
  }
}
