import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ToolkitComponent } from './toolkit.component';
import { InfoPacksComponent } from './infopacks/infopacks.component';
import { AuthGuard } from '../guards/auth.guard';
import { ResourcesComponent } from './resources/resources.component';
import { PartnersComponent } from './partners/partners.component';
import { PartnerDetailComponent } from './partner-detail/partner-detail.component';
import { LocatorComponent } from './locator/locator.component';
import { ChildCareLocatorComponent } from './child-care-locator/child-care-locator.component';
import { AgedCareLocatorComponent } from './aged-care-locator/aged-care-locator.component';
import { SchoolLocatorComponent } from './school-locator/school-locator.component';
import { FEATURES } from '../_shared/enums';
import { EapBookingDetailComponent } from './eap-booking-detail/eap-booking-detail.component';

const toolkitRoutes: Routes = [
  {
    path: '',
    component: ToolkitComponent,
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Toolkit', featureId: FEATURES.TOOLKIT },
    children: [
      {
        path: 'infopacks',
        component: InfoPacksComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Info Packs', featureId: FEATURES.INFO_PACKS },
      },
      {
        path: 'resources',
        component: ResourcesComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Resources', featureId: FEATURES.RESOURCES },
      },
      {
        path: 'partners',
        component: PartnersComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Partner Services', featureId: FEATURES.PARTNERS },
        children: [
          {
            path: ':id',
            component: PartnerDetailComponent,
            canActivate: [AuthGuard],
            data: { breadcrumb: 'Partner' },
          },
        ],
      },
      // {
      //   path: 'support',
      //   component: ContentListComponent,
      //   canActivate: [AuthGuard],
      //   data: { breadcrumb: 'Support at Work', featureId: FEATURES.SUPPORT_AT_COMPANY },
      //   children: [
      //     {
      //       path: ':type/:id',
      //       component: ContentDetailComponent,
      //       canActivate: [AuthGuard],
      //       data: { breadcrumb: 'type' }
      //     }
      //   ]
      // },
      {
        path: 'locators',
        component: LocatorComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Locators', featureId: FEATURES.LOCATOR },
        children: [
          {
            path: 'childcare',
            component: ChildCareLocatorComponent,
            data: { breadcrumb: 'Childcare' },
          },
          {
            path: 'agedcare',
            component: AgedCareLocatorComponent,
            data: { breadcrumb: 'Aged Care' },
          },
          {
            path: 'school',
            component: SchoolLocatorComponent,
            data: { breadcrumb: 'School' },
          },
        ],
      },
      {
        path: 'booking',
        component: EapBookingDetailComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Booking', featureId: FEATURES.EAP_BOOKING },
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(toolkitRoutes),
  ],
  exports: [
    RouterModule,
  ],
})
export class ToolkitRoutingModule {
}
