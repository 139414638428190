import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user-service';
import { DateService } from '../../../services/date.service';
import { ReportsService } from '../../../services/reports-service';

interface INotifyeesCount {
  companyId: number;
  name: string;
  oneSignalUsersCount: number;
  webOneSignalUsersCount: number;
}

interface IProfilesUpdatedCount {
  companyId: number;
  name: string;
  count: number;
}

interface IPlatformsCount {
  companyId: number;
  name: string;
  count: number;
  web: number;
  ios: number;
  ipad: number;
  android: number;
  other: number;
}

@Component({
  selector: 'app-users-reporting',
  templateUrl: './users-reporting.component.html',
  styleUrls: ['./users-reporting.component.scss'],
})
export class UsersReportingComponent implements OnInit {
  canShowTotalUsersChart = false;
  canShowUserReports = false;

  companyId: number;
  startDate: Date;
  endDate: Date;
  isShowChart = true;
  chartData: number[] = [];
  labels = ['New Users', 'Active Users', 'All Users'];
  userChart = 'doughnut';
  color: string[];
  reportDescription: string;
  canSelectCompany = false;
  lastQueriedCompanyId = -1;

  notifyeesCounts: INotifyeesCount[] = [];
  totalNotifyeesCount: INotifyeesCount;
  isNotifyeesCountTotals = false;

  profilesUpdatedCounts: IProfilesUpdatedCount[] = [];
  totalProfilesUpdatedCount = 0;
  isProfilesUpdatedCountTotal = false;

  platformCounts: IPlatformsCount[] = [];
  totalPlatformsCount: IPlatformsCount;
  isPlatformsCountTotal = false;

  constructor(
    private userService: UserService,
    private dateService: DateService,
    private reportService: ReportsService,
  ) {
  }

  ngOnInit() {
    this.setUserRoleAuthorisations();
    this.color = this.reportService.getChartColors();
    this.reportDescription =
      `Total number of users in your company,
       number of users that have signed up
       and the number logged in
        within the selected date range`;
    this.showResultsWithInitialDefaults();
  }

  private setUserRoleAuthorisations() {
    this.canSelectCompany = this.userService.isSuperAdminUser() || this.userService.isAdminUser();
    this.canShowTotalUsersChart = this.userService.isReportsSuperAdminUser();
    this.canShowUserReports = this.userService.isSuperAdminUser() || this.userService.isAdminUser();
  }

  private showResultsWithInitialDefaults() {
    this.companyId = (this.userService.isSuperAdminUser() || this.userService.isAdminUser())
      ? this.reportService.cmsCompanyId
      : this.userService.company.id;
    this.startDate = this.reportService.cmsStartDate;
    this.endDate = this.reportService.cmsEndDate;

    this.refreshChart(this.companyId, this.startDate, this.endDate);
    this.refreshReports(this.companyId);
  }

  private refreshChart(companyId: number, startDate: Date, endDate: Date) {
    if (!this.canShowTotalUsersChart) {
      return;
    }

    if (companyId === null) {
      companyId = 0;
    }
    this.isShowChart = false;
    this.chartData = [];
    const body = {
      startDate: this.dateService.formatYYYY_MM_DD(startDate),
      endDate: this.dateService.formatYYYY_MM_DD(endDate),
    };

    this.reportService.getAllUsersCountPerSystemRole(companyId, body)
      .subscribe(data => {
        const allUsers = data.totUsers;
        const newUsers = data.newUsers;
        const activeUsers = data.activeUsers;
        this.chartData.push(newUsers, activeUsers, allUsers);

        this.isShowChart = !!(allUsers + newUsers + activeUsers);
      });
  }

  private refreshReports(companyId: number) {
    if (!this.canShowUserReports) {
      return;
    }

    if (companyId === null) {
      companyId = 0;
    }
    if (companyId === this.lastQueriedCompanyId) {
      return;
    }

    this.refreshNotifyeesReport(companyId);
    this.refreshProfilesUpdatedReport(companyId);
    this.refreshPlatformsReport(companyId);

    this.lastQueriedCompanyId = companyId;
  }

  private refreshNotifyeesReport(companyId: number) {
    this.notifyeesCounts = [];
    this.isNotifyeesCountTotals = false;

    this.userService.getCompanyNotifyeesCountPerSystemRole(companyId)
      .subscribe(data => {
        this.notifyeesCounts = data;
        this.isNotifyeesCountTotals = this.notifyeesCounts.length > 1;
        this.totalNotifyeesCount = this.getTotalNotifyeesCounts(this.notifyeesCounts);
      });
  }

  private getTotalNotifyeesCounts(counts: INotifyeesCount[]): INotifyeesCount {
    let total: INotifyeesCount = null;

    if (counts.length > 1) {
      total = {
        companyId: -1,
        name: '',
        oneSignalUsersCount: counts.map(i => +i.oneSignalUsersCount).reduce((prev, next) => prev + next),
        webOneSignalUsersCount: counts.map(i => +i.webOneSignalUsersCount).reduce((prev, next) => prev + next),
      };
    }

    return total;
  }

  private refreshProfilesUpdatedReport(companyId: number) {
    this.profilesUpdatedCounts = [];
    this.isProfilesUpdatedCountTotal = false;

    this.userService.getCompanyProfilesUpdatedCountPerSystemRole(companyId)
      .subscribe(data => {
        this.profilesUpdatedCounts = data;
        this.isProfilesUpdatedCountTotal = this.profilesUpdatedCounts.length > 1;
        this.totalProfilesUpdatedCount = this.getTotalProfilesCounts(this.profilesUpdatedCounts);
      });
  }

  private getTotalProfilesCounts(counts: IProfilesUpdatedCount[]): number {
    return counts.map(i => +i.count).reduce((prev, next) => prev + next);
  }

  private refreshPlatformsReport(companyId: number) {
    this.platformCounts = [];
    this.isPlatformsCountTotal = false;

    this.userService.getCompanyPlatformsCountPerSystemRole(companyId)
      .subscribe(data => {
        this.platformCounts = data;
        this.isPlatformsCountTotal = this.platformCounts.length > 1;
        this.totalPlatformsCount = this.getTotalPlatformsCounts(this.platformCounts);
      });
  }

  private getTotalPlatformsCounts(counts: IPlatformsCount[]): IPlatformsCount {
    let total: IPlatformsCount = null;

    if (counts.length > 1) {
      total = {
        companyId: -1,
        name: '',
        count: counts.map(i => +i.count).reduce((prev, next) => prev + next),
        web: counts.map(i => +i.web).reduce((prev, next) => prev + next),
        ios: counts.map(i => +i.ios).reduce((prev, next) => prev + next),
        ipad: counts.map(i => +i.ipad).reduce((prev, next) => prev + next),
        android: counts.map(i => +i.android).reduce((prev, next) => prev + next),
        other: counts.map(i => +i.other).reduce((prev, next) => prev + next),
      };
    }

    return total;
  }

  onDateRangeSelected($event) {
    this.reportService.cmsCompanyId = $event.companyId;
    this.reportService.cmsStartDate = $event.startDate;
    this.reportService.cmsEndDate = $event.endDate;

    this.refreshChart($event.companyId, $event.startDate, $event.endDate);
    this.refreshReports($event.companyId);
  }
}
