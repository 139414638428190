<h2 class="mobile-heading">
  Fitness devices and apps</h2>
<div *ngIf="hasFeature(14)" class="card p-4 mt-3">
  <div class="row">
    <div class="col-6">
      <img class="deviceImg" src="assets/img/devices-and-apps/fitbit-logo.svg">
    </div>
    <div class="col-6 text-center">
      <div *ngIf="!userService.hasFitbit()">
        <button (click)="connectToFitbit()" type="button" class="btn btn-primary w-100 btn-sm mt-2"> Connect</button>
      </div>
      <div *ngIf="userService.hasFitbit()">
        <button (click)="disconnectToFitbit()" class="btn btn-outline-primary w-100 btn-sm mt-2">
          Disconnect
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="hasFeature(14)" class="card p-4 mt-3">
  <div class="row">
    <div class="col-6">
      <img src="assets/img/devices-and-apps/google_fit_logo-01.svg">
    </div>
    <div class="col-6 text-center">
      <div *ngIf="!userService.hasGoogleFit()">
        <button (click)="connectToGoogleFit()" type="button" class="btn btn-primary w-100 btn-sm mt-2"> Connect</button>
      </div>
      <div *ngIf="userService.hasGoogleFit()">
        <button (click)="disconnectFromGoogleFit()" class="btn btn-outline-primary w-100 btn-sm mt-2">
          Disconnect
        </button>
      </div>
    </div>
  </div>
</div>
