<div>
  <label *ngIf='label'>{{label}}</label>
  <mat-select class='custom-select form-control' panelClass="multiple-select"
    (selectionChange)='onOptionsSelected($event)' multiple placeholder="{{placeholder}}" [ngModel]="selectedOptionIds">
    <mat-select-trigger>
      {{getSelectedOptionDisplayText()}}
    </mat-select-trigger>
    <mat-option *ngIf='addAllOption' [value]='allOptionId'>{{allOptionLabel}}</mat-option>
    <mat-option *ngFor='let option of allOptions' [value]='option.id'>{{option.name}}</mat-option>
  </mat-select>

  <div class='form-group'>
    <div class='row' style='margin-top: 15px;' *ngIf="!allOptionSelected">
      <div *ngFor='let optionId of selectedOptionIds' class='col-3'>
        <button type='button'
            class='btn btn-danger btn-sm btn-select'
            (click)='onSelectedOptionClick(optionId)'>
          <i class='fa fa-undo'></i>
        </button>
        {{optionName(optionId)}}
      </div>
    </div>
  </div>
</div>
