import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { includes } from 'lodash';
import { IIdNamePair } from '../../_models/id-name-pair';

@Component({
  selector: 'multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss'],
})
export class MultipleSelectComponent implements OnInit, OnChanges {
  @Input() public allOptions: IIdNamePair[] = [];
  @Input() public selectedOptionIds: number[] = [];
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public addAllOption: boolean = false;
  @Input() public allOptionLabel: string;
  @Input() public selectFormControl: string;
  @Input() public maxDisplayOption: number = 2;
  @Output() public optionIdsSelected: EventEmitter<number[]> = new EventEmitter();
  allOptionSelected: boolean = false;
  public readonly allOptionId = 0;

  constructor() {
  }

  refineMaxDisplayOption() {
    this.maxDisplayOption = Math.round(this.maxDisplayOption);
    if (this.maxDisplayOption <= 1) {
      this.maxDisplayOption = 1;
    }
  }

  ngOnInit() {
    this.refineMaxDisplayOption();
    this.allOptions.sort(this.sortAscendingNames);
  }

  ngOnChanges() {
    this.allOptions.sort(this.sortAscendingNames);
    this.updateSelectedOptionIds(this.selectedOptionIds);
  }

  private sortAscendingNames(a: IIdNamePair, b: IIdNamePair) {
    const valueA = a.name.toUpperCase();
    const valueB = b.name.toUpperCase();

    if (valueA > valueB) {
      return 1;
    }
    if (valueA < valueB) {
      return -1;
    }

    return 0;
  }

  private isSelectedCompanyIdsSet() {
    return this.selectedOptionIds
      && this.selectedOptionIds.length;
  }

  optionName(optionId: number) {
    if (optionId == this.allOptionId) {
      return this.allOptionLabel;
    }
    const option = this.allOptions.find(c => c.id === +optionId);
    if (!option) {
      return `${optionId} - `;
    }

    return option.name;
  }

  onOptionsSelected($event) {
    const optionIds = $event.value || [];
    if (optionIds.length <= 0) {
      if (this.addAllOption) {
        this.selectedOptionIds = [];
        this.emitEventOptionUpdated();
        this.allOptionSelected = true;
        return;
      }
    }

    this.allOptionSelected = false;
    this.updateSelectedOptionIds(optionIds);
    this.emitEventOptionUpdated();
  }

  updateSelectedOptionIds(updatedValue: number[]) {
    const updatedIncludeAllOption = includes(updatedValue, this.allOptionId);
    const oldSelectedIncludeAllOption = includes(this.selectedOptionIds, this.allOptionId);
    this.allOptionSelected = updatedIncludeAllOption;

    if (!oldSelectedIncludeAllOption && !updatedIncludeAllOption) {
      if (updatedValue.length == this.allOptions.length && updatedValue.length > 0) {
        this.selectedOptionIds = [this.allOptionId].concat(this.allOptions.map(o => o.id));
        this.allOptionSelected = true;
      } else {
        this.selectedOptionIds = updatedValue;
      }
      return;
    }

    if ((!oldSelectedIncludeAllOption || updatedValue.length == 1) && updatedIncludeAllOption) {
      this.selectedOptionIds = [this.allOptionId].concat(this.allOptions.map(o => o.id));
      return;
    }

    if (oldSelectedIncludeAllOption && !updatedIncludeAllOption) {
      this.selectedOptionIds = [];
      return;
    }

    if (updatedValue.length < this.selectedOptionIds.length) {
      this.selectedOptionIds = updatedValue.filter(id => id != this.allOptionId);
      this.allOptionSelected = false;
    }
  }

  emitEventOptionUpdated() {
    if (this.selectedOptionIds.includes(this.allOptionId)) {
      this.optionIdsSelected.emit([0]);
      return;
    }
    this.optionIdsSelected.emit(this.selectedOptionIds.sort((a, b) => a - b));
  }

  onSelectedOptionClick(optionId: number) {
    this.selectedOptionIds = this.selectedOptionIds.filter(i => {
      return i !== optionId;
    });
    this.emitEventOptionUpdated();
  }

  findOptionById(optionId: number) {
    if (optionId == this.allOptionId) {
      return {
        id: this.allOptionId,
        name: this.allOptionLabel,
      };
    }
    return this.allOptions.find(c => c.id === +optionId);
  }

  getSelectedOptionDisplayText() {
    if (this.selectedOptionIds.length <= 0) {
      return '';
    }
    if (includes(this.selectedOptionIds, this.allOptionId)) {
      return this.allOptionLabel;
    }
    let displayText = '';
    for (let i = 0; i < this.maxDisplayOption && this.findOptionById(this.selectedOptionIds[i]); i++) {
      displayText = `${displayText}${!displayText ? '' : ','} ${this.optionName(this.selectedOptionIds[i])}`;
    }

    if (this.selectedOptionIds.length > this.maxDisplayOption) {
      displayText = `${displayText} and ${this.selectedOptionIds.length - this.maxDisplayOption} others`;
    }

    return displayText;
  }
}
