<div class="text-right">
  <a class="dots" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <i class="fa fa-ellipsis-v"></i>
  </a>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item" (click)=onReportSpamClick()>Report as spam</a>
    <a class="dropdown-item" (click)=onReportOffensiveClick()>Report as offensive</a>
    <a class="dropdown-item" (click)=onReportOtherClick()>Report as other</a>

    <a *ngIf="canUserDeleteItem" class="dropdown-item separator" (click)="onDeleteItemClick()">Delete {{itemType}}</a>
    <a *ngIf="canUserEditItem" class="dropdown-item" (click)="onEditItemClick()">Edit {{itemType}}</a>
  </div>
</div>
