<div class="content">
  <h3 class="text-center">{{title}}</h3>
  <div class="profileImage">
    <img id="profilePic" src="{{profilePic$ | async}}" />
    <a id="editLogo" class="hyperlink primaryColor"
      (click)="onChangeProfilePictureClick()" *ngIf="!isInsightsDomain">
      <i class="fa fa-camera" aria-hidden="true">
      </i>
      Change Profile Picture
    </a>
  </div>

  <form *ngIf="itemForm"
    [formGroup]="itemForm"
    (ngSubmit)="onFormSubmit()" novalidate>
    <div class="middleContent">
      <div class="form-group">
        <label class="control-label required">
          Username
        </label>
        <input type="text" class="form-control"
          formControlName="username"
          [ngClass]="displayFieldCss('username')" />
        <span class="invalid-feedback">
          Username is required (minimum 2 characters)</span>
      </div>

      <div class="form-group">
        <label class="control-label">
          Gender
        </label>
        <input type="text" class="form-control"
               formControlName="gender"
               [ngClass]="displayFieldCss('gender')" />
      </div>

      <!-- <div class="form-group">
        <label class="control-label required">Date of Birth</label>
        <div class="input-group">
          <input placeholder="yyyy-mm-dd"
            [ngClass]="displayFieldCss('dateOfBirth')" type="text"
            class="form-control" formControlName="dateOfBirth"
            (blur)="dp.close()"
            [showWeekdays]="false" [minDate]="{year: 1930, month: 1, day: 1}"
            #dp="ngbDatepicker" ngbDatepicker />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" tabindex="-1"
              (click)="dp.toggle();" type="button">
              <i class="fa fa-calendar"></i>
            </button>
          </div>
        </div>
        <div *ngIf="canShowInvalidDateOfBirth()" class="invalid-message">
          Please enter a valid date of birth (YYYY-MM-DD)
        </div>
      </div> -->

      <div class="form-group">
        <app-language-filter [defaultLang]='lang' (filterEvent)='changeLanguage($event)' *ngIf="this.showLanguageOption"></app-language-filter>
      </div>
    </div>

    <p class='note' *ngIf="!isInsightsDomain">
      We collect this information to understand who is using AltiusLife as part of our work to deliver an inclusive platform that appeals to everyone. Your personal details and privacy are maintained as part of our Privacy Protection policy.
    </p>

    <div class="buttons">
      <button *ngIf="isExistingUserProfile"
        type="button"
        class="btn btn-outline-primary float-left"
        [ngClass]="!isInsightsDomain ? 'w-48' : 'w-100'"
        (click)="onClose()"
        [disabled]="isLoading">
        Close
      </button>
      <button type="submit" class="btn btn-primary float-right"
        [ngClass]="isExistingUserProfile ? 'w-48' : 'w-100'" *ngIf="!isInsightsDomain">
        Save
      </button>
    </div>
    <div class='form-group text-center closeAccount mt-4' *ngIf="isExistingUserProfile && !isInsightsDomain">
      <a href='#' (click)='closeAccount($event)'>Close My AltiusLife account</a>
    </div>
  </form>
  <br />
  <br />
</div>
