<div class='w-100 pb-2 d-inline-block'>
  <div class='row m-0'>
    <div class='col-8 col-sm-9 pl-0'>
      <div *ngIf='!hideSearch' class='search-list mb-2'>
        <i class='fa fa-search'></i>
        <input id='search' type='search' [(ngModel)]='search' (keyup)='onSearchKeyup()' (search)='onSearchKeyup()' placeholder='{{searchPlaceholder()}}'
               class='form-control'>
      </div>
    </div>
    <div class='col-4 col-sm-3 text-right pr-0'>
      <button type='button' class='btn btn-primary mr-3' (click)='onResetClicked()'>Reset</button>
      <button type='button' class='btn btn-primary mr-3' (click)='onSaveClicked()'>Save</button>
    </div>
  </div>
  <div class='table-responsive shadow-lg'>
    <table class='table table-bordered table-striped table-hover'>
      <thead>
      <tr>
        <th class='idCol'>
          <button *ngIf='!isSelectAll' class='btn' (click)='unselectAll()'>
            <i class='fa fa-minus-circle' aria-hidden='true'></i>
          </button>
          <button *ngIf='isSelectAll' class='btn' (click)='selectAll()'>
            <i class='fa fa-check-circle' aria-hidden='true'></i>
          </button>
        </th>
        <th *ngFor='let col of columnList; let i = index;' (click)='onColClick(i)' class='nameCol text-uppercase'>
          <i *ngIf='isAscendingSortColumn(i)' class='fa fa-caret-up' aria-hidden='true'></i>
          <i *ngIf='isDescendingSortColumn(i)' class='fa fa-caret-down' aria-hidden='true'></i>
          {{col}}
        </th>
      </tr>
      </thead>
      <tbody *ngIf='filteredList.length'>
      <tr *ngFor='let item of filteredList | paginate: config'>
        <td class='idCol'>
          <div class='custom-control custom-checkbox'>
            <input type='checkbox' [id]='item[0]' class='custom-control-input' [(ngModel)]='item.checked' (click)='selectItem(item)'>
            <label [class]="'custom-control-label'" for='{{item[0]}}'>&nbsp;</label>
          </div>
        </td>
        <td class='nameCol'>
          <span>{{item[1]}}</span>
        </td>
        <td>
          <div class='url-container position-relative' [ngClass]='getValidationClass(item)'>
            <input type='text' class='form-control txt-url' [(ngModel)]='item[2]' [placeholder]='defaultUrl'>
            <i *ngIf='item[2]' (click)='onClearClicked(item)' class='fa fa-times'></i>
            <span class='url-empty-feedback invalid-feedback'>Url is empty</span>
            <span class='url-invalid-feedback invalid-feedback'>Url is invalid format</span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <!-- details: http://michaelbromley.github.io/ngx-pagination/#/-->
  <div class='cms-pagination' *ngIf='filteredList.length'>
    <pagination-controls autoHide='true' (pageChange)='config.currentPage = $event'></pagination-controls>
  </div>
</div>
