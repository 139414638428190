<div class="widget-card">
  <div class="widget-card-header backPrimary">
    My Health
  </div>
  <div class="w-100 text-center">
    <div *ngIf="!isActiveIndicators">
      <p class="fs-80-rem mt-3">
        You have not entered any data today
      </p>
    </div>
    <div *ngFor="let item of activeIndicators">
      <p class="m-0 pt-2 fs-80-rem">{{ item.name }}</p>
      <round-progress
        [current]="item.trackerValue"
        [max]="item.goalValue"
        semicircle="true"
        stroke="30"
        [color]="item.strokeColor"
      ></round-progress>
      <div class="chart-label">
        <span>{{ item.percent }}%</span>
      </div>
    </div>

    <a
      class="btn btn-link w-100 widget-card-line fs-80-rem"
      [routerLink]="['/act/your-health']"
    >
      <i class="fa fa-plus-circle" aria-hidden="true"></i>
      {{ anchorText }}
    </a>
  </div>
</div>
