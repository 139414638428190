<div class="widget-card">
  <div class="widget-card-header backPrimary">
    Log a Peer Support Interaction
  </div>
  <div>
    <img src="../../../assets/img/widgets/log_widget_image.png" alt="Show my rewards" class="w-100">
  </div>
  <div class="w-100 text-center">
    <a
      class="btn btn-link w-100 widget-card-line fs-80-rem"
      href="https://forms.office.com/r/a1PzcX2aLj"
      target="_blank"
    >
      All information is de-identified and managed confidentiality
    </a>
  </div>
</div>
