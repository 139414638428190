import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RewardComponent } from './reward.component';
import { AuthGuard } from '../guards/auth.guard';
import { BadgesComponent } from './badges/badges.component';
import { MyRewardsComponent } from './my-rewards/my-rewards.component';

const rewardRoutes: Routes = [
  {
    path: '',
    component: RewardComponent,
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Reward', featureId: 5 },
    children: [
      {
        path: 'badges',
        component: BadgesComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Badges', featureId: 16 }
      },
      {
        path: 'my-rewards',
        component: MyRewardsComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'My Rewards', featureId: 66 }
      },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(rewardRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class RewardRoutingModule {}
