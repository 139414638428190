import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IChallenge } from '../_models/challenge';
import { ChallengeService } from '../../services/challenge-service';
import { DateService } from '../../services/date.service';
import { UserService } from '../../services/user-service';
import { UtilService } from '../../services/util-service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-challenges',
  templateUrl: './challenges.component.html',
  styleUrls: ['./challenges.component.scss'],
})
export class ChallengesComponent implements OnInit {
  items: IChallenge[];
  loading: Boolean;

  constructor(
    private challengeService: ChallengeService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private dateService: DateService,
    private userService: UserService,
    private utilService: UtilService,
    public domSanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    this.loading = true;
    this.getList();
  }

  getList() {
    this.challengeService.getAvailableChallenges().subscribe(
      (data) => {
        this.loading = false;
        this.items = data;
        this.items.forEach(element => {
          element.image = element.image ? this.userService.envVariables.aws.awsBucket + 'challenges_images/' + element.image : null;
          element.descHtml = this.domSanitizer.bypassSecurityTrustHtml(element.description);
        });
      },
      (error) => {
        this.loading = false;
        this.utilService.showToastError('Error while loading items - ' + error);
      },
    );
  }

  goToDetailLink(id) {
    //this.router.navigate([`/act/challenge-details/${id}`]);
    this.router.navigate([`/act/challenge-details`, id]);
  }
}
