import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { CategoryService } from '../../../services/category-service';
import * as _ from 'lodash';

@Component({
  selector: 'app-life-action-driver-filter',
  templateUrl: './life-action-driver-filter.component.html',
  styleUrls: ['./life-action-driver-filter.component.scss'],
})
export class LifeActionDriverFilterComponent implements OnInit {
  driverOptions: any;
  filterForm: any;
  filterFormIndices: any;
  @Output() filter: EventEmitter<any> = new EventEmitter<any>();
  @Output() inactive: EventEmitter<any> = new EventEmitter<any>();
  @Output() clear: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private categoryService: CategoryService,
  ) {
  }

  ngOnInit() {
    this.buildFilterForm();
  }

  buildFilterForm() {
    this.driverOptions = this.categoryService.getCachedDrivers();
    this.filterForm = this.toFormGroup(this.driverOptions);
  }

  toFormGroup(drivers) {
    const filters: any = {};

    drivers.forEach(driver => {
      filters[driver.name] = new FormControl(false);
    });
    filters.Inactive = new FormControl(false);

    const formGroup = new FormGroup(filters);
    this.filterFormIndices = Object.keys(filters);
    return formGroup;
  }

  clearFilterSelections(event) {
    this.buildFilterForm();
    this.clear.emit(event);
  }

  isInputTypeInactive(filter) {
    return filter === 'Inactive';
  }

  emitDriverFilters() {
    const values = this.filterForm.value;
    let searchTermArray = [];
    const keys = Object.keys(values);
    keys.map(function(key) {
      if (values[key] === true) {
        searchTermArray.push(_.lowerCase(key));
      }
    });
    searchTermArray = searchTermArray.filter(item => item !== 'inactive');
    this.filter.emit(_.compact(searchTermArray));
  }

  emitInactiveFilter() {
    this.inactive.emit(this.filterForm.get('Inactive').value);
  }
}
