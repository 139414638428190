import { BootstrapModalComponent } from '../../../../components/bootstrap-modal/bootstrap-modal.component';
import { UtilService } from '../../../../services/util-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IIngredient } from '../../../_models/ingredient';
import { Component, OnInit, Input, ViewChild, ElementRef, Renderer } from '@angular/core';
import { RecipeService } from '../../../../services/recipe.service';

@Component({
  selector: 'app-recipe-ingredient-management',
  templateUrl: './recipe-ingredient-management.component.html',
  styleUrls: ['./recipe-ingredient-management.component.scss']
})
export class RecipeIngredientManagementComponent implements OnInit {
  @Input() recipeId: number;
  @Input() items: IIngredient[];
  @Input() isUpdated: boolean;

  @ViewChild('editableFocus') editableFocusElement: ElementRef;

  itemType: string;
  title: string;
  itemForm: FormGroup;
  selectedItem: IIngredient;
  ingredientName: string;

  isAddingItem = false;
  isEditingItem = false;
  isItemsUpdated = false;
  canRespondToButtons: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private utilService: UtilService,
    private itemService: RecipeService,
    private modalService: NgbModal,
    private renderer: Renderer
  ) { }

  ngOnInit() {
    this.itemType = 'Ingredient';
    this.refreshTitle();
    this.createFormGroup();
    this.canRespondToButtons = true;
  }

  private refreshTitle() {
    this.title = `Recipe ${this.itemType}s (${this.items.length})`;
  }

  private createFormGroup() {
    this.itemForm = this.fb.group({
      selectedItemControl: this.selectedItem,
      ingredientNameControl: [this.ingredientName, Validators.required]
    });
  }

  private selectItem(item: IIngredient) {
    if (!this.items.length || this.isAddingItem || this.isEditingItem) { return; }

    this.selectedItem = item;
    this.patchItemForm(this.selectedItem);
  }

  private selectLastItem() {
    if (!this.items.length) { return; }

    this.selectedItem = this.items[this.items.length - 1];
    this.patchItemForm(this.selectedItem);
  }

  private patchItemForm(source: IIngredient) {
    this.itemForm.patchValue({
      ingredientNameControl: source.ingredientName
    });
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field)
    };
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach(field => {
      const control = this.itemForm.get(field);
      control.markAsTouched({onlySelf: true});
    });
  }

  private persistItem() {
    this.canRespondToButtons = false;
    let itemToPersist: IIngredient;

    try {
      itemToPersist = {
        id: this.isEditingItem ? this.selectedItem.id : 0,
        recipeId: this.recipeId,
        ingredientName: this.itemForm.get('ingredientNameControl').value
      };
    } catch (error) {
      this.utilService.showToastError('Error persisting item - ' + error);
      this.canRespondToButtons = true;
      return;
    }

    if (this.isAddingItem) {
      this.addNewItem(itemToPersist);
    } else if (this.isEditingItem) {
      this.updateItem(itemToPersist);
    }
  }

  private updateItem(itemToPersist: IIngredient) {
    this.itemService.updateRecipientIngredient(itemToPersist)
      .subscribe(data => {
        this.utilService.showToastSuccess(`Updated ${this.itemType} - ${itemToPersist.ingredientName}`);
        this.isItemsUpdated = true;
        this.canRespondToButtons = true;
        this.isEditingItem = false;
        this.refreshTitle();
        this.updateItems(data);
        this.selectItem(itemToPersist);
        this.itemForm.reset();
      },
      error => {
        this.utilService.showToastError(`Error while updating item - ${error}`);
        this.canRespondToButtons = true;
      });
  }

  private updateItems(item: IIngredient) {
    const itemIndex = this.items.findIndex(i => i.id === item.id);
    if (itemIndex < 0 ) { return; }

    this.items[itemIndex].ingredientName = item.ingredientName;
  }

  private addNewItem(itemToPersist: IIngredient) {
    this.itemService.addRecipeIngredient(itemToPersist)
      .subscribe(data => {
        this.items.push(data);
        this.utilService.showToastSuccess(`Added new ${this.itemType} - ${itemToPersist.ingredientName}`);
        this.itemForm.reset();
        this.isItemsUpdated = true;
        this.canRespondToButtons = true;
        this.isAddingItem = false;
        this.refreshTitle();
        this.selectLastItem();
      },
    error => {
      this.utilService.showToastError(`Error while adding item - ${error}`);
      this.canRespondToButtons = true;
    });
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Delete ' + this.itemType;
    modal.componentInstance.message = 'Delete - ' + this.selectedItem.ingredientName;
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(isConfirmed => {
      if (!isConfirmed) { return; }

      this.itemService.deleteRecipeIngredient(this.recipeId, this.selectedItem.id)
        .subscribe(() => {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`Deleted ${this.itemType} - ${this.selectedItem.ingredientName}`);
            this.canRespondToButtons = true;
            this.removeFromItems(this.selectedItem);
            this.refreshTitle();
          },
          error => this.utilService.showToastError('Error while deleting item - ' + error));
      }, () => {});
  }

  private removeFromItems(itemToRemove: IIngredient) {
    const itemIndex = this.items.indexOf(itemToRemove);
    if (itemIndex < 0 ) { return; }

    this.items.splice(itemIndex, 1);
  }

  private setFocusToFirstEditableField() {
    this.renderer.invokeElementMethod(this.editableFocusElement.nativeElement, 'focus');
  }

  isReadOnly() {
    return !this.isAddingItem && !this.isEditingItem;
  }

  canEdit() {
    return this.selectedItem && this.isReadOnly();
  }

  canDelete() {
    return this.selectedItem && this.isReadOnly();
  }

  canAdd() {
    return this.isReadOnly();
  }

  canCancel() {
    return this.isAddingItem || this.isEditingItem;
  }

  canSave() {
    return this.isAddingItem || this.isEditingItem;
  }

  onClickItem(item) {
    if (this.isAddingItem || this.isEditingItem) { return; }

    this.selectItem(item);
  }

  onEditClick() {
    if (!this.selectedItem) { return; }

    this.isEditingItem = true;
    this.isAddingItem = false;
    this.setFocusToFirstEditableField();
  }

  onDeleteClick() {
    if (!this.selectedItem) { return; }

    this.deleteItem();
  }

  onAddClick() {
    this.selectedItem = null;
    const newItem = this.itemService.getNewRecipeIngredient(this.recipeId);
    this.patchItemForm(newItem);
    this.itemForm.reset();

    this.isAddingItem = true;
    this.isEditingItem = false;
    this.setFocusToFirstEditableField();
  }

  onCancelClick() {
    this.itemForm.reset();

    if (this.selectedItem && this.isEditingItem) {
      this.patchItemForm(this.selectedItem);
    } else {
      const newItem = this.itemService.getNewRecipeIngredient(this.recipeId);
      this.patchItemForm(newItem);
      }

    this.isAddingItem = false;
    this.isEditingItem = false;
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();

      return;
    }

  this.persistItem();
}

  onCloseClick() {
    this.activeModal.close(this);
  }
}
