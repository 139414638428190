import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IFriendsTypes } from '../app/_models/friends-types';
import { IFriend } from '../app/_models/friend';

@Injectable({
  providedIn: 'root'
})
export class FriendsService {
  result: any;
  constructor(private http: HttpClient) {}

  getAllMyActiveFriendsTypes(): Observable<IFriendsTypes> {
    const url = '/friends/me';

    return this.http.get<IFriendsTypes>(url);
  }

  getFriendByUserProfileId(friendUserProfileId) {
    const url = `/friends/single/${friendUserProfileId}`;

    return this.http.get<IFriend>(url);
  }

  getSearchedFriends(term) {
    const trimmedTerm = term.trim();
    if (!trimmedTerm.length) {
      return of([]);
    }

    const url = `/friends/search/${trimmedTerm}`;
    return this.http.get<IFriend[]>(url);
}

  getMySuggestedFriends() {
    const url = `/friends/suggested/me`;

    return this.http.get<any>(url);
  }

  sendFriendRequest(friendId: number) {
    const url = `/friend-requests/send/${friendId}`;

    return this.http.post<any>(url, null);
  }

  cancelSentFriendRequest(friendId: number) {
    const url = `/friend-requests/cancel/${friendId}`;

    return this.http.post<any>(url, null);
  }

  confirmReceivedFriendRequest(friendId: number) {
    const url = `/friend-requests/confirm/${friendId}`;

    return this.http.post<any>(url, null);
  }

  declineReceivedFriendRequest(friendId: number) {
    const url = `/friend-requests/decline/${friendId}`;

    return this.http.post<any>(url, null);
  }

  removeConfirmedFriend(friendId: number) {
    const url = `/friend-requests/remove/${friendId}`;

    return this.http.post<any>(url, null);
  }
}
