import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  @Input() photoSrc: string;
  @Input() levelSrc: string;
  @Input() isLargerAvatar = false;
  @Output() selectAvatar = new EventEmitter<boolean>();

  isLevelSrc = false;

  constructor() { }

  ngOnInit() {
    this.isLevelSrc = !!this.levelSrc;
  }

  onSelectAvatar() {
    this.selectAvatar.emit(true);
  }
}
