import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VideoService } from './video-service';

@Injectable({
  providedIn: 'root'
})
export class BookmarkService {
  constructor(
    private http: HttpClient,
    private videoService: VideoService
  ) { }

  addBookmark(id, type) {
    const url = `/bookmarks`;
    const body = {
      contentId: id,
      type: type,
    };

    return this.http.post<any>(url, body);
  }

  getBookmarks() {
    const url = `/bookmarks`;

    return this.http.get<any>(url).pipe(tap(res => {
      if (res.videos) {
        res.videos.map(item => {
          this.videoService.extractYoutubeId(item);
        });
      }
    }));
  }

  deleteBookmark(id) {
    const url = `/bookmarks/${id}`;

    return this.http.delete<any>(url);
  }
}
