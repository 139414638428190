import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, ILoginResponse } from '../../../services/auth-service';
import { environment } from '../../../environments/_active-environment/environment';
import { UserService } from '../../../services/user-service';
import { LogService } from '../../../services/log.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '../../../services/util-service';
import { UnSubscribeComponent } from '../../_shared/un-subscribe.component';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AlertComponent } from '../../../components/alert-modal/alert-modal.component';
import { DomainCheckerService } from '../../../services/domain-checker.service';
import { InsightsService } from '../../../services/insights-service';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { of } from 'rxjs';

@Component({
  selector: 'app-auth-authorize',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.scss'],
})
export class AuthorizeComponent extends UnSubscribeComponent implements OnInit {
  isLoading: boolean;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private logService: LogService,
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private domainChecker: DomainCheckerService,
    private insightsService: InsightsService,
  ) {
    super();
  }

  ngOnInit() {
    this.isLoading = true;
    this.activatedRoute.queryParamMap
      .pipe(
        switchMap((params) => {
          if (params.has('code') && !this.userService.token) {
            const authorizationCode = params.get('code');
            const companyId = params.get('companyId');
            let redirectUri;
            if (environment.name === 'altiuslife-dev') {
              redirectUri = `http://${this.domainChecker.hostname}:4200/auth/authorize`;
            } else {
              redirectUri = `https://${this.domainChecker.hostname}/auth/authorize`;
            }
            return this.authService.authorize({ authorizationCode, companyId, redirectUri });
          } else if (params.has('error') || params.has('error_description')) {
            this.isLoading = false;
            this.handleErrorResponse();
          } else {
            return of();
          }
        }),
        takeUntil(this.unSubscribeOnDestroy),
      )
      .subscribe((res) => {
        this.isLoading = false;
        this.handleAuthorizedResponse(res);
      });
  }

  handleErrorResponse() {
    let alertModal = this.modalService.open(AlertComponent, { beforeDismiss: () => false });
    alertModal.componentInstance.title = 'Error!';
    alertModal.componentInstance.message = 'Something went wrong. Please try again later.';
    alertModal.componentInstance.type = 1;
    alertModal.componentInstance.showCloseIcon = false;
    alertModal.result.then(() => {
      window.location.href = `${window.location.origin}/auth/login`;
    });
  }

  handleAuthorizedResponse(res: ILoginResponse) {
    let { user } = res;
    if (!user) {
      user = res;
    }
    if (res.success === false) {
      this.utilService.showToastError('Unsuccessful', res.err);
    }
    if (this.isUserProfileRequired(user)) {
      this.handleLoginCreateUserProfileResponse(res);
    } else {
      this.handleLoginSuccessResponse(res);
    }
  }

  handleLoginSuccessResponse(res: ILoginResponse) {
    let { user } = res;
    const { token, refreshToken } = res;
    if (!user) {
      user = res;
    }

    if (this.insightsService.isInsightsDomain() && !user.hasInsights) {
      this.showAccountNotAuthorizeForAltiusInsights();
      return;
    }

    if (token) {
      this.userService.token = token;
    }
    if (refreshToken) {
      this.userService.refreshToken = refreshToken;
    }
    this.userService.userId = user.id;
    this.userService.user = user;
    this.userService.profile = user.userProfile;
    this.userService.envVariables = environment;
    this.userService.updateTimezoneAndLastlogin();
    this.userService.changeProfilePicObs();
    this.logService.logLogin();

    if (!this.userService.company) {
      this.userService.company = user.userProfile.company;
    }

    this.userService
      .sendTagsToOneSignal(
        user.userProfile.id,
        user.userProfile.username,
        user.userProfile.company.id,
      );

    if (user.hasInsights) {
      this.insightsService.handleSSOLogin(token, refreshToken);
    } else {
      this.router.navigateByUrl('home')
        .then(() => {
          location.reload();
        });
    }
  }

  refreshAuthorizeState() {
    this.isLoading = false;
  }

  showAccountNotAuthorizeForAltiusInsights() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Error!';
    modal.componentInstance.message = 'Cannot access Altius Insights. Please contact administrator for more detail!';
    modal.componentInstance.singleButton = true;
    modal.result.then(() => this.refreshAuthorizeState(), () => this.refreshAuthorizeState());
  }

  handleLoginCreateUserProfileResponse(res: ILoginResponse) {
    let { token, user } = res;
    if (!user) {
      user = res;
    }
    this.userService.token = token;
    this.userService.user = user;
    this.commenceNewUserProfileModalChain(user.id, user.company);
  }

  private isUserProfileRequired(user): boolean {
    return user.userProfile === null;
  }

  commenceNewUserProfileModalChain(userId: string, company: any) {
    this.showBackgroundForUserProfileModal(company);
  }

  private showBackgroundForUserProfileModal(company) {
    this.router.navigateByUrl('/create');
    this.userService.loadTheming(company);
  }
}
