import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'featureFilter'
})
export class FeatureFilterPipe implements PipeTransform {

    transform(value: any[], type: number, excludeIds: any[]) {
        return value.filter(item => item.type === type && (!excludeIds || !excludeIds.includes(item.id)));
    }
}
