import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerService } from '../../../services/partner-service';
import { LogService } from '../../../services/log.service';
import { UserService } from '../../../services/user-service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  partners: any[] = [];
  constructor(
    private partnerService: PartnerService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private userService: UserService,
    private logService: LogService
  ) { }

  ngOnInit() {
    this.getPartners();
    this.logService.logPartners();
  }

  getPartners() {
    this.partnerService
      .getPartners()
      .subscribe(data => {
          this.partners = data;
        },
        err => {
          console.log(err);
        }
      );
  }

  formatImagePath(filename) {
    return this.userService.getPartnerImage(filename);
  }

  goToPartnerDetailPage(item) {
    this.router.navigate(['toolkit/partners', item.id]);
  }
}
