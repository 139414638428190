<div class="row">
  <div class="results" [class]="canShowLanguageToggle() ? 'col-8' : 'col-12'">
    <form class="search-term">
      <i class="fa fa-search"></i>
      <input
        type="search"
        [formControl]="searchTermControl"
        placeholder="Search"
        class="form-control text-sm-80">
    </form>
  </div>
  <div *ngIf="canShowLanguageToggle()" class="col-4">
    <app-language-toggle-button (languagesToggleEvent)='displayArticleBySelectedlanguage($event)'></app-language-toggle-button>
  </div>
</div>

