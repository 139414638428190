import { LifeActionService } from '../../../../../services/life-action-service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-select-life-action-recurrence',
  templateUrl: './select-life-action-recurrence.component.html',
  styleUrls: ['./select-life-action-recurrence.component.scss'],
})
export class SelectLifeActionRecurrenceComponent implements OnInit {

  allRecurrences: any[];
  recurrence;
  @Input('id') id;
  @Input('defaultRecurrence') defaultRecurrence;
  @Output()
  selection: EventEmitter<any> = new EventEmitter<any>();
  isCollapsed = true;

  constructor(private lifeActionService: LifeActionService) {
  }

  ngOnInit() {
    this.allRecurrences = this.lifeActionService.allStaticRecurrences();
    this.allRecurrences.map(recurrence => {
      if (recurrence.value === this.defaultRecurrence) {
        recurrence.name = recurrence.name + ' (recommended)';
        this.recurrence = recurrence.value;
      }
    });
  }

  makeSelection() {
    const body = {
      recurrence: this.recurrence,
    };
    this.selection.emit(body);
    this.collapse();
  }

  collapse() {
    this.isCollapsed = true;
  }
}
