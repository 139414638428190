import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { UtilService } from '../../services/util-service';
import { UserService } from '../../services/user-service';
import { ConnectionService } from '../../services/connection-service';
import { AuthService } from '../../services/auth-service';

@Component({
  selector: 'app-navbar-insights',
  templateUrl: './navbar-insights.component.html',
  styleUrls: ['./navbar-insights.component.scss'],
})
export class InsightsNavbarComponent implements OnInit, OnDestroy {
  @ViewChild('navbarToggler') navbarToggler: ElementRef;
  @ViewChild('navbarTarget') navbarTarget: ElementRef;

  isLoggedIn = false;
  photoSrc: string;
  levelSrc: string;
  companyName: string;

  photoSrcSubscription: Subscription;

  @Input() logoSrc: string;

  isDevice: boolean;

  constructor(
    private userService: UserService,
    private router: Router,
    private utilService: UtilService,
    private connectionService: ConnectionService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.isLoggedIn = this.userService.isLoggedIn();
    this.isDevice = this.utilService.isMobileDeviceScreenWidth(screen.width);
    if (!this.isLoggedIn || !this.userService.hasProfile()) {
      return;
    }

    this.initialiseFromUserService();
  }

  isConnectionLoaded(): Observable<boolean> {
    return this.connectionService.isCompleted;
  }

  ngOnDestroy() {
    if (this.photoSrcSubscription) {
      this.photoSrcSubscription.unsubscribe();
    }
  }

  onLogoSelected() {
    const url = '/home';
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
    this.hideNavbarDropdownMenu();
  }

  private hideNavbarDropdownMenu() {
    if (!this.isShowingNavbarToggler()) {
      return;
    }
    this.navbarToggler.nativeElement.click();
  }

  private isShowingNavbarToggler(): boolean {
    if (!this.navbarToggler) {
      return false;
    }

    const domElement = this.navbarToggler.nativeElement;
    return (domElement.offsetHeight > 0 && domElement.offsetWidth > 0);
  }

  private initialiseFromUserService() {

    this.companyName = this.userService.company
      ? this.userService.company.name
      : this.userService.getPlatformTitle();
    this.userService.loadCompanyTheming();
    this.userService.refreshCurrentProfileSrc();
    this.logoSrc = this.userService.getLogoSrc();
    this.photoSrc = this.userService.getMyPhotoSrc();
    this.levelSrc = this.userService.getMyLevelSrc();

    this.photoSrcSubscription = this.userService.profilePic$
      .subscribe(src => this.photoSrc = src);
  }

  onMainMenuClick(event: Event, page) {
    if (event && !this.utilService.isMobileDeviceScreenWidth(screen.width)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onLoginClick() {
    this.authService.handleLoginProcess();
  }
}
