import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation-preview',
  templateUrl: './navigation-preview.component.html',
  styleUrls: ['./navigation-preview.component.scss'],
})
export class NavigationPreviewComponent implements OnInit {
  @Input() public topTextBarColor: string;

  constructor() {
  }

  ngOnInit() {
  }
}
