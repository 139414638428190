<div class="modal-header">
  <h4 class="modal-title">Share</h4>
  <button class="close" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="!hasNoGroups" class="form-group">
    <label class="control-label required">Choose a group to share to</label>
    <select type="text" class="form-control" [formControl]="groupControl">
      <option *ngFor="let group of memberGroups" [value]="group.name">
        {{group.name}}
      </option>
    </select>
  </div>
  <div *ngIf="hasNoGroups">
    <label>You must be a member of a group to share this content</label>
    <button class="btn btn-outline-primary btn-sm ml-10 post-button mt-2" (click)="goToGroups()">Join a group</button>
  </div>

  <app-text-area [type]="'post'" [disableButtons]="disableSharingButtons" [fromShare]="true" [actionButtonText]="actionButtonText"
    [placeholder]="postPlaceholder" [path]="postImagePath" (editCancelled)="onCancel()" [inputPreview]="preview" class="post-input p-2"
    (data)="onShareClick($event)"></app-text-area>
</div>
