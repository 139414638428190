<div *ngIf='isInitialised' class='padding jumbotron'>

  <form [formGroup]='itemForm' (ngSubmit)='onSaveClick()'>

    <div class='row m-0'>
      <div class='col-12'>
        <!-- Google Play URL -->
        <div class="form-group">
          <label class="control-label required">Google Play URL</label>
          <input type="text" class="form-control" [ngClass]="displayFieldCss('googlePlayURL')" formControlName='googlePlayURL' />
          <span class='invalid-feedback'>Google Play URL is required.</span>
        </div>

        <!-- App Store URL -->
        <div class="form-group">
          <label class="control-label required">App Store URL</label>
          <input type="text" class="form-control" [ngClass]="displayFieldCss('appStoreURL')" formControlName='appStoreURL' />
          <span class='invalid-feedback'>App Store URL is required.</span>
        </div>

        <!-- Contact Us URL -->
        <div class="form-group">
          <label class="control-label required">Contact Us URL</label>
          <input type="text" class="form-control" [ngClass]="displayFieldCss('contactUsURL')" formControlName='contactUsURL' />
          <span class='invalid-feedback'>Contact Us URL is required.</span>
        </div>

        <br/>

        <!-- Buttons -->
        <div class='row'>
          <div class='col-4'>
          </div>

          <div class='col-4'>
          </div>

          <div class='col-4'>
            <button type='submit' class='btn btn-primary float-right w-100' [disabled]='!canRespondToButtons'>
              <i class='fa fa-floppy-o mr-2' aria-hidden='true'></i> SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>



