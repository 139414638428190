<div [formGroup]="parentForm">
  <small><label for="#emailInput">Email address</label></small>
  <input 
    #emailInput
    (keyup)="onInputEmail(emailInput.value)"
    [formControlName]="'email'"
    type="email"
    name="email"
    class="form-control form-control-sm"
    [ngClass]="inputcss"
    placeholder="Enter email" />
    
</div>
<div *ngIf="isEmailInputError()">
  <div class="alert alert-danger p-1"><small>{{errorMessage}}</small></div>
</div>
