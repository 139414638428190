
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { UserService } from './user-service';
import { DateService } from './date.service';
import { FeatureService } from './feature-service';

const LoginId = -1;

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private dateService: DateService,
    private feature: FeatureService
  ) { }

  logLogin() {
    this.firstSubscribeToAddLogWithoutContentId(LoginId);
  }

  logMyProfile() {
    this.firstSubscribeToAddLogWithoutContentId(this.feature.MyProfileFeatureId);
  }

  logDevices() {
    this.firstSubscribeToAddLogWithoutContentId(this.feature.DevicesFeatureId);
  }

  logLifeActions() {
    this.firstSubscribeToAddLogWithoutContentId(this.feature.LifeActionsFeatureId);
  }

  logTrackMyHealth() {
    this.firstSubscribeToAddLogWithoutContentId(this.feature.TrackMyHealthFeatureId);
  }

  logBadges() {
    this.firstSubscribeToAddLogWithoutContentId(this.feature.BadgesFeatureId);
  }

  logMyRewards() {
    this.firstSubscribeToAddLogWithoutContentId(this.feature.MyRewardsFeatureId);
  }

  logFriends() {
    this.firstSubscribeToAddLogWithoutContentId(this.feature.FriendsFeatureId);
  }

  logGroups() {
    this.firstSubscribeToAddLogWithoutContentId(this.feature.GroupsFeatureId);
  }

  logLeaderboard() {
    this.firstSubscribeToAddLogWithoutContentId(this.feature.LeaderboardFeatureId);
  }

  logToolkit() {
    this.firstSubscribeToAddLogWithoutContentId(this.feature.ToolkitFeatureId);
  }

  logPartners() {
    this.firstSubscribeToAddLogWithoutContentId(this.feature.PartnersFeatureId);
  }

  logInformationPacks() {
    this.firstSubscribeToAddLogWithoutContentId(this.feature.InformationPacksFeatureId);
  }

  logPreferences() {
    this.firstSubscribeToAddLogWithoutContentId(this.feature.PreferencesFeatureId);
  }

  logCarousel(contentId) {
    this.firstSubscribeToAddLogWithContentId(contentId, this.feature.CarouselFeatureId);
  }

  logFriendship(userProfileId) {
    this.firstSubscribeToAddLogWithContentId(userProfileId, this.feature.FriendsFeatureId);
  }

  logGroup(groupId) {
    return this.firstSubscribeToAddLogWithContentId(groupId, this.feature.GroupsFeatureId);
  }

  logPartner(partnerId) {
    this.firstSubscribeToAddLogWithContentId(partnerId, this.feature.PartnersFeatureId);
  }

  logContentItem(item) {
    if (item.isArticle) {
      return this.logArticle(item.id);
    }

    if (item.isRecipe) {
      return this.logRecipe(item.id);
    }

    if (item.isVideo) {
      return this.logVideo(item.id);
    }
  }

  logArticle(articleId) {
    return this.addLogWithContentId(articleId, this.feature.ArticlesFeatureId)
      .pipe(take(1));
  }

  logRecipe(recipeId) {
    return this.addLogWithContentId(recipeId, this.feature.RecipesFeatureId)
      .pipe(take(1));
  }

  logVideo(videoId) {
    return this.addLogWithContentId(videoId, this.feature.VideosHealthFeatureId)
      .pipe(take(1));
  }

  private firstSubscribeToAddLogWithoutContentId(featureId) {
    if (!this.userService.isLoggedIn()) { return; }

    this.addLogWithoutContentId(featureId).pipe(
      take(1))
      .subscribe();
  }

  private firstSubscribeToAddLogWithContentId(contentId, featureId) {
    if (!this.userService.isLoggedIn()) { return; }

    return this.addLogWithContentId(contentId, featureId).pipe(
      take(1));
  }

  private addLogWithoutContentId(featureId) {
    const platform = this.userService.platform;
    const date = this.dateService.formatYYYY_MM_DD();
    const url = '/logs';

    const body = {
      featureId,
      feature: featureId,
      date,
      platform
    };

    return this.http.post<any>(url, body);
  }

  private addLogWithContentId(contentId, featureId) {
    const platform = this.userService.platform;
    const date = this.dateService.formatYYYY_MM_DD();
    const url = '/logs';

    const body = {
      contentId: +contentId,
      featureId,
      feature: featureId,
      date,
      platform
    };

    return this.http.post<any>(url, body);
  }

  updateLog(id) {
    const url = `/logs/${id}`;
    return this.http.put<any>(url, { id: id });
  }
}
