<form class="form-inline mb-3">
  <label class="sr-only">Start Date</label>
  <div class="input-group mr-sm-2">
    <input class="form-control" placeholder="Start Date" name="startDate" [(ngModel)]="startDate" ngbDatepicker #start="ngbDatepicker">
    <div class="input-group-append">
      <button class="btn btn-outline-secondary" (click)="start.toggle()" type="button">
        <i class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></i>
      </button>
    </div>
  </div>
  <label class="sr-only">End Date</label>
  <div class="input-group mr-sm-2">
    <input class="form-control" placeholder="End Date" name="endDate" [(ngModel)]="endDate" ngbDatepicker #end="ngbDatepicker">
    <div class="input-group-append">
      <button class="btn btn-outline-secondary" (click)="end.toggle()" type="button">
        <i class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></i>
      </button>
    </div>
  </div>
  <div class="input-group mr-sm-2">
      <app-select-company *ngIf="canSelectCompany"
      [allCompanies]="allCompanies"
      [selectedCompanyId]="selectedCompanyId"
      [placeholder]="'All (default)'"
      (companyIdSelected)="onCompanySelected($event)"
    ></app-select-company>
    <!-- Challenge Sellection -->
    <div *ngIf="canSelectChallenge">
      <select type="text" class="custom-select form-control"
        (change)="onChallengeSelected($event)">
        <option *ngFor="let item of allChallenges; let i = index;" [value]="item.id">
          {{item.name}}
        </option>
      </select>
      <span class="invalid-feedback">a Challenge is required</span>
    </div>
  </div>
  <button type="submit" class="btn btn-outline-secondary" (click)="onDateRangeSelected($event)">Submit</button>
  <div *ngIf="!isValidDateRange" style="margin-left: 10px;"
    class="text-danger">The start date must be less than or equal to the end date</div>
</form>
