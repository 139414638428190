import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { IConnection } from '../app/_models/connection';
import { environment } from '../environments/_active-environment/environment';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { InsightsService } from './insights-service';

export interface IConnectionResponse {
  success?: boolean;
  connectionExistedError?: boolean;
  err?: any;
}

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  connectionByHostname: IConnection = null;
  private readonly httpWithoutInterceptor: HttpClient;
  private readonly apiUrl: string;
  private _isLoaded = false;
  private _isLoading = false;

  constructor(private http: HttpClient, private httpBackend: HttpBackend, private insightsService: InsightsService) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
    this.apiUrl = environment.app.endpoint;
  }

  private _usedSSO = new BehaviorSubject<boolean>(false);
  private _hideIfUsedSSO = new BehaviorSubject<boolean>(true);
  private _isCompleted = new BehaviorSubject<boolean>(false);

  get usedSSO(): Observable<boolean> {
    return this._usedSSO.asObservable();
  }

  get hideIfUsedSSO(): Observable<boolean> {
    return this._hideIfUsedSSO.asObservable();
  }

  get isCompleted(): Observable<boolean> {
    return this._isCompleted;
  }

  getConnectionByCompanyId(companyId: number) {
    const url = `/cms/connections/company/${companyId}`;

    return this.http.get<any>(url);
  }

  getConnectionById(id: number) {
    const url = `/cms/connections/${id}`;

    return this.http.get<any>(url);
  }

  getNewConnection(): IConnection {
    return {
      id: 0,
      name: '',
      companyId: 1,
      buttonLabel: 'Login',
      buttonColor: '#000000',
      buttonOrder: 1,
      enabled: true,
    };
  }

  updateConnection(connection: IConnection) {
    const body = {
      name: connection.name,
      companyId: connection.companyId,
      buttonLabel: connection.buttonLabel,
      buttonColor: connection.buttonColor,
      buttonOrder: connection.buttonOrder,
      enabled: connection.enabled,
    };
    const url = `/cms/connections/${connection.id}`;

    return this.http.put<any>(url, body);
  }

  addConnection(connection: IConnection) {
    const body = {
      name: connection.name,
      companyId: connection.companyId,
      buttonLabel: connection.buttonLabel,
      buttonColor: connection.buttonColor,
      buttonOrder: connection.buttonOrder,
      enabled: connection.enabled,
    };
    const url = '/cms/connections';

    return this.http.post<any>(url, body);
  }

  deleteConnection(id: number) {
    const url = `/cms/connections/${id}`;

    return this.http.delete<any>(url);
  }

  getCompanyConnectionByHostname(hostname: string) {
    if (this._isLoading) {
      return;
    }
    if (!this._isLoaded) {
      this._isLoading = true;
      const path = '/cms/connections/company-hostname';
      this.httpWithoutInterceptor
        .get(`${this.apiUrl}${path}`, {
          params: { query: hostname },
        })
        .pipe(take(1))
        .subscribe((res: IConnection) => {
          this._isLoaded = true;
          this._isLoading = false;
          this.connectionByHostname = res;
          if (res) {
            this._usedSSO.next(true);
            this._hideIfUsedSSO.next(true);
          } else {
            this._hideIfUsedSSO.next(false);
          }
          this._isCompleted.next(true);
        });
    }
  }

  handleSSOLogin() {
    const domain = environment.auth0.domain;
    const clientId = environment.auth0.clientId;
    const redirectUri = this.getSSORedirectURL();
    const audience = environment.auth0.audience;
    window.location.href = `${domain}/authorize?client_id=${clientId}&prompt=login&response_type=code&connection=${this.connectionByHostname.name}&scope=openid+profile+offline_access&redirect_uri=${redirectUri}&audience=${audience}`;
  }

  getSSORedirectURL() {
    if (environment.name !== 'altiuslife-dev') {
      return `https://${location.hostname}/auth/authorize?companyId=${this.connectionByHostname.companyId}`;
    }

    if (this.insightsService.isInsightsDomain()) {
      return `${environment.auth0.insightsRedirectUri}?companyId=${this.connectionByHostname.companyId}`
    }
    return `${environment.auth0.redirectUri}?companyId=${this.connectionByHostname.companyId}`;
  }

  getBaseRedirectUrl() {
    return environment.name === 'altiuslife-dev' ? environment.app.url :`https://${location.hostname}`;
  }

  getSSOLoginUrl(redirectUri: string) {
    const domain = environment.auth0.domain;
    const clientId = environment.auth0.clientId;
    const audience = environment.auth0.audience;
    return `${domain}/authorize?client_id=${clientId}&prompt=login&response_type=code&connection=${this.connectionByHostname.name}&scope=openid+profile+offline_access&redirect_uri=${redirectUri}&audience=${audience}`;
  }
}
