import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() inputcss: any;
  @Input() showConfirmPassword: boolean;
  @Output() passwordInputted = new EventEmitter<string>();
  @Output() confirmPasswordInputted = new EventEmitter<string>();

  showPassword: boolean;
  showPasswordIcon: string;
  passwordInputType: string;

  constructor() {
    this.showPassword = false;
    this.showPasswordIcon = 'fa-eye';
    this.passwordInputType = 'password';
  }

  ngOnInit() {

  }

  public toggleShowPassword() {
    this.showPassword = !this.showPassword;
    this.showPassword ? this.showPasswordIcon = 'fa-eye-slash' : this.showPasswordIcon = 'fa-eye';
    this.showPassword ? this.passwordInputType = 'text' : this.passwordInputType = 'password';
  }

  public onInputPassword(value) {
    this.passwordInputted.emit(value);
  }

  public onInputConfirmPassword(value) {
    this.confirmPasswordInputted.emit(value);
  }

  get password() { return this.parentForm.get('password'); }

  get confirmPassword() { return this.parentForm.get('confirmPassword'); }

  showIsPasswordNotMatchingError() {
    return this.parentForm.errors &&
      this.parentForm.errors.passwordsDoNotMatch &&
        (this.confirmPassword.dirty ||
          (this.confirmPassword.touched &&
            this.password.touched
          )
        );
  }

  showIsPasswordRequiredError() {
    return this.password.errors &&
      this.password.errors.required &&
      this.password.touched;
  }

  showIsPasswordNotMatchedPatternError() {
    return this.parentForm.errors &&
      this.parentForm.errors.passwordNotMatchedPattern &&
      this.password.touched &&
      this.password.dirty;
  }
}
