import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAuthor } from '../app/_models/author';

export interface IAuthorResponse {
  success?: boolean;
  authorDeletionError?: boolean;
  err?: any;
}

@Injectable({
    providedIn: 'root'
})
export class AuthorService {
    constructor(
        private http: HttpClient,
    ) {
    }

    addAuthor(body) {
        const url = `/cms/author`;
        return this.http.post<any>(url, body);
    }

    getAuthorById(id) {
        const url = `/cms/author/${id}`;
        return this.http.get<any>(url);
    }

    updateAuthor(body, id) {
        const url = `/cms/author/${id}`
        return this.http.put<any>(url, body);
    }

    getAllAuthors() {
        const url = `/cms/author`;
        return this.http.get<any>(url);
    }

    deleteAuthor(id) {
        const url = `/cms/author/${id}`;
        return this.http.delete<any>(url);
    }

    getNewAuthor(): IAuthor {
      return {
          id: 0,
          name: '',
          image: '',
          bio: '',
        };
    }
}
