<div *ngIf="isDisplayingList && isInitialised">
  <div class="row mr-5 mb-2">
    <div class="col-10 col-sm-6">
      &nbsp;
    </div>
    <div class="col-2 col-sm-6 text-right pr-0 w-25">
      <button type="button" class="btn btn-primary" (click)="onMultiDeleteClick()" [disabled]="selectItem.length == 0">
        <i class="fa fa-trash" aria-hidden="true"></i><br>
      </button>
      &nbsp;
      <button type="button" class="btn btn-primary" (click)="goToDetailLink('new')">
        <i class="fa fa-plus" aria-hidden="true"></i><br>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-3 mb-2" *ngFor="let i of items">

      <div class="card" style="width: auto;">
        <div class="custom-control custom-checkbox" style="position: absolute; left: 5px;">
          <input type="checkbox" class="custom-control-input" id="i{{i.id}}" (click)="onCheckClick(i.id)">
          <label class="custom-control-label" for="i{{i.id}}"></label>
        </div>
        <a class="contentAnchorTag" (click)="goToDetailLink(i.id)">
          <img class="card-img-top" src="{{i.image}}" alt="Card image cap">
          <div class="card-body">
            <p class="card-text text-center">{{i.name}}</p>
          </div>
        </a>
      </div>

    </div>
  </div>
</div>

<div *ngIf="(isAddingItem || isEditingItem) && isInitialised" class="container">
  <h5 class="card-title text-center">Create Challenges</h5>
  <ul class="progressbar">
    <li class="{{p_One}}"></li>
    <li class="{{p_Two}}"></li>
    <li class="{{p_Three}}"></li>
  </ul>
</div>
<div *ngIf="(isAddingItem || isEditingItem) && isInitialised" class="padding">
  <form [formGroup]="itemForm">
    <!-- Section One -->
    <div *ngIf="createSectionOne" class="row m-0">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Challenge Name -->
            <div class="form-group">
              <label class="control-label required">Name of the Challenge</label>
              <input type="text" class="form-control" [ngClass]="displayFieldCss('nameControl')"
                formControlName="nameControl" />
              <span class="invalid-feedback">Challenge Name is required</span>
            </div>
            <!-- Start and End Date -->
            <div class="row">
              <!-- Start Date -->
              <div class="col-6">
                <div class="form-group">
                  <label for="startDate" class="control-label required">Start Date</label>
                  <div class="input-group">
                    <input placeholder="yyyy-mm-dd" class="form-control" [ngClass]="displayFieldCss('startTimeControl')"
                      formControlName="startTimeControl" (blur)="sd.close()" [showWeekdays]="false" #sd="ngbDatepicker"
                      ngbDatepicker />
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" (click)="sd.toggle();ed.close();" type="button">
                        <i class="fa fa-calendar"></i>
                      </button>
                    </div>
                    <div class="invalid-feedback">
                      Start Date invalid.
                    </div>
                  </div>
                </div>
              </div>
              <!--- End Date Block -->
              <div class="col-6">
                <div class="form-group">
                  <label for="startDate" class="control-label required">End Date</label>
                  <div class="input-group">
                    <input placeholder="yyyy-mm-dd" class="form-control" [ngClass]="displayFieldCss('endTimeControl')"
                      formControlName="endTimeControl" (blur)="ed.close()" [showWeekdays]="false" #ed="ngbDatepicker"
                      ngbDatepicker />
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" (click)="ed.toggle();sd.close()" type="button">
                        <i class="fa fa-calendar"></i>
                      </button>
                    </div>
                    <div class="invalid-feedback">
                      Invalid End Date (Must NOT be earlier than Start Date)
                    </div>
                  </div>
                </div>
              </div>
            </div>
             <!-- Display Start and End Date -->
             <div class="row">
              <!-- Display Start Date -->
              <div class="col-6">
                <div class="form-group">
                  <label for="displayStartDate" class="control-label required">Display Start Date</label>
                  <div class="input-group">
                    <input placeholder="yyyy-mm-dd" class="form-control" [ngClass]="displayFieldCss('displayStartTimeControl')"
                      formControlName="displayStartTimeControl" (blur)="dsd.close()" [showWeekdays]="false" #dsd="ngbDatepicker"
                      ngbDatepicker />
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" (click)="dsd.toggle();ded.close();" type="button">
                        <i class="fa fa-calendar"></i>
                      </button>
                    </div>
                    <div class="invalid-feedback">
                      Display Start Date invalid.
                    </div>
                  </div>
                </div>
              </div>
              <!--- Display End Date Block -->
              <div class="col-6">
                <div class="form-group">
                  <label for="displayStartDate" class="control-label required">Display End Date</label>
                  <div class="input-group">
                    <input placeholder="yyyy-mm-dd" class="form-control" [ngClass]="displayFieldCss('displayEndTimeControl')"
                      formControlName="displayEndTimeControl" (blur)="ded.close()" [showWeekdays]="false" #ded="ngbDatepicker"
                      ngbDatepicker />
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" (click)="ded.toggle();dsd.close()" type="button">
                        <i class="fa fa-calendar"></i>
                      </button>
                    </div>
                    <div class="invalid-feedback">
                      Invalid Display End Date (Must NOT be earlier than Start Date)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Challenge Type -->
            <div class="row pt-4">
              <div class="col-12 col-sm-6">
                <a class="contentAnchorTag" (click)="typeClick(1)">
                  <div class="form-group">
                    <div class='{{teamTypeClass}}'>
                      <div class="card-body" style="min-height: 8rem;">
                        <h6> Team Challenge</h6>
                        <p class="card-text">Add teams in your challenge and compete amongst them to win</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-12 col-sm-6">
                <a class="contentAnchorTag" (click)="typeClick(2)">
                  <div class="form-group">
                    <div class='{{individualTypeClass}}'>
                      <div class="card-body" style="min-height: 8rem;">
                        <h6> Individual Challenge</h6>
                        <p class="card-text">Compete Individually amongst other members</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <!--- Buttons Block -->
            <div class="row">
              <div class="col-12 col-sm-4">
                <button type="button" class="btn btn-outline-primary w-100" (click)="navigateToList()">
                  <i class="fa fa-ban mr-2" aria-hidden="true"></i> CANCEL
                </button>
              </div>
              <div class="col-12 col-sm-4">
                <button type="button" class="btn btn-outline-primary w-100" (click)="onDeleteClick()"
                  [disabled]="isAddingItem">
                  <i class="fa fa-trash-o mr-2" aria-hidden="true"></i> DELETE
                </button>
              </div>
              <div class="col-12 col-sm-4">
                <button type="button" class="btn btn-primary float-right w-100"
                  [disabled]="!canRespondToButtons || !itemForm.valid" (click)="goToSectionTwo()">
                  <i class="fa fa-arrow-right mr-2" aria-hidden="true"></i> NEXT</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Section Two -->
    <div *ngIf="createSectionTwo" class="row m-0">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- image -->
            <!-- <div class="form-group">
                <label for="image" class="control-label required">
                  Upload Image for the challenge
                </label>
                <div class="input-group">
                  <input type="text" name="challengeImage" id="challengeImage" class="form-control"
                    [ngClass]="displayFieldCss('image')" placeholder="Choose the image" formControlName="image"
                    readonly />
                  <div class="input-group-prepend">
                    <button type="button" class="input-group-text btn btn-primary" (click)="openfilestack()">
                      Browse
                    </button>
                  </div>
                  <span class="invalid-feedback">Image is required</span>
                </div>
              </div> -->
            <div class="form-group">
              <label for="image" class="control-label required">
                Upload image for the challenge
              </label>
              <div class="input-group">
                <input type="text" name="logo" id="logo" class="form-control" [ngClass]="displayFieldCss('image')"
                  placeholder="Choose the image" formControlName="image" readonly />
                <div class="input-group-prepend">
                  <button type="button" class="input-group-text btn btn-primary" (click)="openfilestack()">
                    Browse
                  </button>
                </div>
                <span class="invalid-feedback">Image is required</span>
              </div>
            </div>

            <!-- Text area -->
            <!-- <div class="form-group">
              <label class="control-label">Description (Allow 250 characters only)</label>
              <textarea type="text" class="form-control" [ngClass]="displayFieldCss('descriptionControl')"
                formControlName="descriptionControl" style="min-height: 12rem;" maxlength="250"></textarea>
            </div> -->
            <div class="form-group">
              <label class="control-label">Description</label>
              <div [froalaEditor]="options" (froalaInit)="initialize($event)" formControlName="descriptionControl"></div>
            </div>

            <!-- Companies -->
            <div class="form-group" *ngIf="canSelectCompany">
              <app-select-company [allCompanies]="allCompanies" [selectedCompanyId]="itemForm.get('companyId').value"
                [label]="'Company'" [placeholder]="'All (default)'" (companyIdSelected)="onCompanySelected($event)">
              </app-select-company>
            </div>
            <!-- Excluded Companies -->
            <div *ngIf="isAllCompanies()" class="form-group">
              <app-select-companies [allCompanies]="allCompanies" [selectedCompanyIds]="excludedCompanyIds"
                [label]="'Excluded Companies'" [placeholder]="'Select Company to exclude'"
                (companyIdsSelected)="excludedCompanyIdsSelected($event)"></app-select-companies>
            </div>

            <div class="row">
              <div class="col-6 col-sm-6 form-group">
                <label class="control-label required">Goal Type</label>
                <select id="goalTypeId" class="form-control custom-select" 
                (change)="onGoalTypeIdChange(itemForm.get('goalTypeId').value)"
                formControlName="goalTypeId">
                  <option *ngFor="let item of allGoalTypes" [value]="item.id">{{item.name}}
                  </option>
                </select>
                <span class="invalid-feedback">Goal Type is required</span>
              </div>  
              <div class="col-6 col-sm-6 form-group">
                  <label class="control-label required">Goal</label>
                  <input type="number" class="form-control" [ngClass]="displayFieldCss('challengeGoalControl')"
                    formControlName="challengeGoalControl" placeholder="Challenge Goals in Steps" />
              </div> 
            </div>
            <!--- Buttons Block -->
            <div class="row">
              <div class="col-12 col-sm-6">
                <button type="button" class="btn btn-outline-primary w-100" (click)="goToSectionOne()">
                  <i class="fa fa-arrow-left mr-2" aria-hidden="true"></i> BACK
                </button>
              </div>
              <div class="col-12 col-sm-6">
                <button type="button" class="btn btn-primary float-right w-100"
                  [disabled]="!canRespondToButtons || !itemForm.valid" (click)="onSaveClick()">
                  <i class="fa fa-floppy-o mr-2" aria-hidden="true"></i> SAVE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Section Three -->
    <div *ngIf="createSectionThree" class="row m-0">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <h4>Great Job</h4>
              <div class="card-body">
                <p class="card-text text-center">Your challenge is {{action}} successfully</p>
              </div>
            </div>
            <!--- Buttons Block -->
            <div class="row">
              <div class="col-12 col-sm-6">
                <button type="button" class="btn btn-primary w-100" (click)="navigateToList()" [disabled]="loading">
                  <i class="fa fa-list mr-2" aria-hidden="true"></i> Navigate to Challenges
                </button>
              </div>
              <div class="col-12 col-sm-6">
                <button type="button" class="btn btn-outline-primary float-right w-100" [disabled]="loading"
                  (click)="navigateTeams()">
                  <i class="fa fa-arrow-right mr-2" aria-hidden="true"></i> Add teams to challenge</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
