import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../../services/util-service';
import { DateService } from '../../../services/date.service';
import { IInsightsLog } from '../../_models/insights-log';
import { InsightsService } from '../../../services/insights-service';
import { CompanyService } from '../../../services/company-service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-insights-reporting',
  templateUrl: './insights-reporting.component.html',
  styleUrls: ['./insights-reporting.component.scss'],
})
export class InsightsReportingComponent implements OnInit {
  isInitialised: boolean;
  items: IInsightsLog[];
  columns = [];

  constructor(
    private utilService: UtilService,
    private dateService: DateService,
    private insightsService: InsightsService,
    private companyService: CompanyService,
  ) {
  }

  ngOnInit() {
    this.getList();
  }

  private getList() {
    this.isInitialised = false;
    this.companyService.refreshCacheWithCallback(() => {
      this.insightsService.getLogs()
        .subscribe((data: any) => {
            this.items = data.map(i => {
              return [
                i.email,
                this.companyService.companyName(i.companyId),
                this.dateService.formatDD_MMM_YYYY_HH_mm_ss_z(i.createdAt),
              ];
            });
            this.columns = ['Email', 'Company', 'View Date'];
            this.isInitialised = true;
          },
          error => this.utilService.showToastError('Error while loading items - ' + error));
    });
  }

  exportData() {
    this.insightsService.getLogs()
      .subscribe(data => {
          let headerTitle = ['Email', 'Company', 'View Date']; // CSV file Heading
          const header = ['email', 'companyId', 'createdAt']; // API Heading
          let csv = data.map(row => {
            const array = header.map(field => {
              if (field === 'companyId') {
                return this.companyService.companyName(row.companyId);
              } else if (field === 'createdAt') {
                return this.dateService.formatDD_MMM_YYYY_HH_mm_ss_z(row.createdAt);
              } else {
                return row[field];
              }
            });
            return array.join(',');
          });
          csv.unshift(headerTitle.join(','));
          let csvArray = csv.join('\r\n');
          let blob = new Blob([csvArray], { type: 'text/csv' });
          let fileName = 'InsightsLogs_' + new Date().toISOString();
          fileName = fileName + '.csv';
          saveAs(blob, fileName);
        },
        err => this.utilService.showToastError('Download Failed ', err),
      );
  }
}
