import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IAuthor } from '../../_models/author';
import { AuthorService, IAuthorResponse } from '../../../services/author-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilestackService } from '../../../services/filestack-service';
import { UtilService } from '../../../services/util-service';
import { UserService } from '../../../services/user-service';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-author-management',
  templateUrl: './author-management.component.html',
  styleUrls: ['./author-management.component.scss'],
})
export class AuthorManagementComponent implements OnInit {
  itemType = 'Author';
  authors: IAuthor[];
  author: IAuthor;
  authorForm: FormGroup;
  columns = [];
  additionalNumericColumns;

  isDisplayingList: boolean;
  isInitialised: boolean;
  isEditingItem: boolean;
  isAddingItem: boolean;
  canRespondToButtons: boolean;

  maxBioLength = 320;

  constructor(private activatedRoute: ActivatedRoute,
              private authorService: AuthorService,
              private fb: FormBuilder,
              private filestackService: FilestackService,
              private ngZone: NgZone,
              private modalService: NgbModal,
              private utilService: UtilService,
              private userService: UserService,
              private router: Router,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params
      .subscribe(route => {
        this.analyseRoute(route);

        if (this.isDisplayingList) {
          this.getList();
        } else {
          this.isInitialised = false;
          this.canRespondToButtons = false;

          if (this.isEditingItem) {
            this.initialiseEditItem(+route.id);
          } else if (this.isAddingItem) {
            this.initialiseAddItem();
          }
        }
      });
  }

  getList() {
    this.authorService.getAllAuthors().subscribe(
      (data) => {
        this.authors = data.map((i) => {
          return [
            i.id,
            i.name,
            i.bio,
            i.image ? this.userService.envVariables.aws.awsAuthorBucket + i.image : null,
          ];
        });
        this.columns = ['Id', 'Name', 'Bio', 'Image'];
        this.additionalNumericColumns = [4];
        this.isInitialised = true;
        this.canRespondToButtons = true;
      },
      (error) => this.utilService.showToastError('Error while loading items - ' + error),
    );
  }

  initialiseAddItem(): void {
    this.author = this.authorService.getNewAuthor();
    this.initialiseEditableFormGroup(this.author);
  }

  initialiseEditItem(id) {
    this.authorService.getAuthorById(id).subscribe((data) => {

      this.author = data;
      this.initialiseEditableFormGroup(data);
    });
  }

  initialiseEditableFormGroup(author) {
    this.authorForm = this.fb.group({
      name: [author.name, Validators.required],
      bio: [author.bio, Validators.maxLength(this.maxBioLength)],
      image: [author.image],
    });
    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  private markAllControlsAsTouched() {
    Object.keys(this.authorForm.controls).forEach((field) => {
      const control = this.authorForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isEditingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isEditingItem = true;
    }
  }

  onDeleteClick() {
    if (!this.isEditingItem) {
      return;
    }

    this.deleteItem();
  }

  onSaveClick() {
    if (!this.authorForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }
    this.persistItem();
  }

  onBackClick() {
    this.navigateToList();
  }

  private navigateToList() {
    this.router.navigate(['/cms/authors']);
  }


  displayFieldCss(field: string) {
    if (field === 'bio') {
      return {
        'is-invalid': this.hasReachedBioLimit(),
      };
    }
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  isFieldInvalid(field: string) {
    return !this.authorForm.get(field).valid && this.authorForm.get(field).touched;
  }

  openfilestack() {
    this.filestackService.pickAuthorImage().then(
      (res) => {
        this.ngZone.run(() => this.authorForm.patchValue({ image: res }));
      },
      (err) => {
        this.utilService.showToastError('Error while uploading picture', err);
      },
    );
  }


  hasReachedBioLimit() {
    return this.authorForm.get('bio').value.length >= this.maxBioLength;
  }

  private persistItem() {
    this.canRespondToButtons = false;
    let author: IAuthor;
    try {

      author = {
        name: this.authorForm.get('name').value,
        image: this.authorForm.get('image').value,
        bio: this.authorForm.get('bio').value,
      };
      if (this.author.id !== 0) {
        author.id = this.author.id;
      }
    } catch (error) {
      const errorMsg = 'Error persisting item - ' + error;
      this.utilService.showToastError(errorMsg);
      this.canRespondToButtons = true;
      return;
    }

    if (this.isEditingItem) {
      this.updateItem(author);
    } else if (this.isAddingItem) {
      this.addNewItem(author);
    }
  }

  private updateItem(body) {
    this.authorService.updateAuthor(body, body.id)
      .subscribe(() => {
          this.authorForm.reset();
          this.canRespondToButtons = true;
          const msg = `Updated author - ${body.name}`;
          this.utilService.showToastSuccess(msg);
          this.navigateToList();
        },
        (error) => {
          const errorMsg = `Error while saving item - ${error}`;
          this.utilService.showToastError(errorMsg);
          this.canRespondToButtons = true;
        },
      );
  }

  private addNewItem(body) {
    this.authorService.addAuthor(body)
      .subscribe(() => {
          this.authorForm.reset();
          this.canRespondToButtons = true;
          const msg = `Added new author - ${body.name}`;
          this.utilService.showToastSuccess(msg);
          this.navigateToList();
        },
        (error) => {
          const errorMsg = `Error while adding item - ${error}`;
          this.utilService.showToastError(errorMsg);
          this.canRespondToButtons = true;
        },
      );
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Delete ' + this.itemType;
    modal.componentInstance.message = `Confirm deleting this ${this.itemType.toLowerCase()}?`;
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(isConfirmed => {
        if (!isConfirmed) {
          return;
        }

        this.authorService.deleteAuthor(this.author.id).subscribe(
          (res: IAuthorResponse) => {
            if (res.authorDeletionError) {
              this.utilService.showToastError('', res.err);
            } else {
              this.utilService.showToastSuccess(`${this.itemType} ${this.author.name} - deleted`);
            }
            this.navigateToList();
          },
          (error) => this.utilService.showToastError('Error while deleting item - ' + error),
        );
      },
      () => {
      },
    );
  }
}
