import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-locator',
  templateUrl: './locator.component.html',
  styleUrls: ['./locator.component.scss'],
})
export class LocatorComponent implements OnInit {
  defaultColumns = [1, 1, 1];
  subFilterCards = [[{
    title: 'Childcare Locator',
    img: '../../assets/img/childcare-locator-625x200.jpg',
    route: '/toolkit/locators/childcare',
  }], [{
    title: 'School Locator',
    img: '../../assets/img/school-locator-625x200.jpg',
    route: '/toolkit/locators/school',
  }], [{
    title: 'Aged Care Locator',
    img: '../../assets/img/support_for_carers.narrow.png',
    route: '/toolkit/locators/agedcare',
  }],
  ];

  features;

  constructor(public activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.features = [61, 61, 61];
  }
}
