<div class="card search-card pt-2">
  <div *ngFor="let friend of friends"
    class="row friend-line m-0 p1-1">
    <div class="col-3 col-md-2 p-2 text-center">
      <app-avatar
        [photoSrc]="friend.photoSrc"
        [levelSrc]="friend.levelSrc"
        (selectAvatar)="onViewFriend(friend)"
      ></app-avatar>
    </div>
    <div class="col-4 col-md-6 text-left p-2">
      <p class="name" (click)="onViewFriend(friend)">{{ friend.username }}</p>
    </div>
    <div class="col-5 col-md-4 p-2 align-right text-right">
      <a
        (click)="onAddOrViewFriend(friend)"
        class="btn btn-outline-primary mt-3 mr-2">
          <i class="fa mr-1"
            [ngClass]="friend.requestActions.canSendFriendRequest ? 'fa-plus-circle' : 'fa-eye'"
          ></i>
          {{ getFriendActionText(friend) }}
      </a>
    </div>
  </div>
</div>
