import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GroupService } from '../../services/group-service';
import { BadgeService } from '../../services/badge-service';
import { UserService } from '../../services/user-service';

@Component({
  selector: 'app-toolkit',
  templateUrl: './toolkit.component.html',
  styleUrls: ['./toolkit.component.scss'],
})
export class ToolkitComponent implements OnInit {
  defaultColumns = [1, 1, 1, 1, 1, 1];
  subFilterCards = [
    [
      {
        title: 'Booking',
        img: '../../assets/img/EAP_Booking.jpg',
        route: '/toolkit/booking',
      },
    ],
    [
      {
        title: 'Partner Services',
        img: '../../assets/img/partners.toolkit.png',
        route: '/toolkit/partners',
      },
    ],
    [
      {
        title: 'Infopacks',
        img: '../../assets/img/infopacks.toolkit.png',
        route: '/toolkit/infopacks',
      },
    ],
    [
      {
        title: 'Locator Services',
        img: '../../assets/img/support_for_parents.wide.png',
        route: '/toolkit/locators',
      },
    ],
    [
      {
        title: 'Support at ' + (this.userService.company.name || 'No name when logout.'),
        img: '../../assets/img/support-at-company.625x200.jpg',
        route: '/toolkit/support',
      },
    ],
    [
      {
        title: 'Resources',
        img: '../../assets/img/resources-625x200.jpg',
        route: '/toolkit/resources',
      },
    ],
  ];
  features = [89, 52, 58, 61, 60, 68];

  constructor(
    public activatedRoute: ActivatedRoute,
    private badgeService: BadgeService,
    private groupService: GroupService,
    public userService: UserService,
  ) {
  }

  ngOnInit() {
    this.groupService.refreshLatestGroupActivity();
    this.badgeService.refreshShortLeaderboard(
      this.userService.company.id,
      this.userService.user.userProfile.id,
    );
  }
}
