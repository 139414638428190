<nav class="navbar navbar-light sticky-bottom text-muted"
  [ngClass]="getNavClasses()">
  <div class="mx-auto">
    <ul class="list-inline">
      <li *ngFor="let item of footerItems"
        class="list-inline-item"
        [ngClass]="getUlClasses()"
        (click)="open(item)">
        {{item.title}}
      </li>
    </ul>
  </div>
</nav>