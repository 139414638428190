import { IGroup } from '../../app/_models/group';
import { GroupService } from '../../services/group-service';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { QuestionnaireService } from '../../services/questionnaire-service';
import { ContentService } from '../../services/content-service';
import { UtilService } from '../../services/util-service';
import { FeatureService } from '../../services/feature-service';
import { PartnerService } from '../../services/partner-service';
import { UserService } from '../../services/user-service';
import { InfoPacksService } from '../../services/infopacks-service';
import { ProgramService } from '../../services/program-service';
import { IInfoPack } from '../../app/_models/info-pack';
import { IPartner } from '../../app/_models/partner';
import { IUserProgram } from '../../app/_models/user-program';
import { IQuestionnaire } from '../../app/_models/questionnaire';
import { CATEGORIES, FEATURES, LANGUAGES } from '../../app/_shared/enums';
import { ChallengeService } from '../../services/challenge-service';
import { IChallenge } from '../../app/_models/challenge';
import { TeamService } from '../../services/team.service';
import { ITeam } from '../../app/_models/team';
import { ConnectionService } from '../../services/connection-service';
import { DomainCheckerService } from '../../services/domain-checker.service';
import { AuthService } from '../../services/auth-service';
import { MenuService } from '../../services/menu-service';
import { IMenuObject } from '../../app/_models/menu-object';
import { CompanyService } from '../../services/company-service';

interface IMenuItem {
  title: string;
  route: string;
}

const maxGroupsInList = 5;
const maxSupportFromCompanyList = 5;
const maxChallengesInList = 3;
const maxTeamsInList = 3;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChild('navbarToggler') navbarToggler: ElementRef;
  @ViewChild('navbarTarget') navbarTarget: ElementRef;

  isLoggedIn = false;
  photoSrc: string;
  levelSrc: string;
  companyName: string;
  companyLogo: string;

  photoSrcSubscription: Subscription;
  userProgramSubscription: Subscription;
  questionnairesChangedSubscription: Subscription;
  groupsChangedSubscription: Subscription;
  partnersChangedSubscription: Subscription;
  challengesChangedSubscription: Subscription;
  teamsChangedSubscription: Subscription;

  isDiscoverMenuItemClicked = false;
  isMyHealthMenuItemClicked = false;
  isCommunityMenutItemClicked = false;
  isHamburgerExpanded = false;

  canShowDiscoverMenu = false;
  canShowMyHealthMenu = false;
  cnaShowCommunityMenu = false;
  canShowNotifications = false;
  canShowContent = false;
  canShowSupportFromCompany = false;
  canShowInfoPacks = false;
  canshowEapBooking = false;
  canShowPeerSupport = false;
  canShowLocators = false;
  canShowPartners = false;
  canShowMyRewards = false;
  canShowCompetition = false;
  canShowMyActivity = false;
  canShowMyHabits = false;
  canShowPrograms = false;
  canShowFriends = false;
  canShowGroups = false;
  canShowViewMoreSupportFromCompany = false;
  canShowViewMoreMyGroups = false;
  canShowViewMoreOtherGroups = false;
  canShowBadges = false;
  canShowHealthAssessments = false;
  canShowSettings = false;
  canShowCms = false;
  canShowSearch = false;
  canShowChallenges = false;
  canShowViewMoreOtherChallenges = false;
  canShowTeams = false;
  canShowViewMoreOtherTeams = false;
  canShowLeaderBoard = false;
  canShowCreateNewGroup = false;
  canShowInsights = false;

  @Input() logoSrc: string;
  contentMenuItems: IMenuObject;
  supportFromCompany: IMenuItem[] = [];
  infoPacks: IInfoPack[] = [];
  locatorReports: IMenuItem[] = [];
  partners: IPartner[] = [];
  myActivities: IMenuItem[] = [];
  myHabits: IMenuItem[] = [];
  programs: IUserProgram[] = [];
  healthAssessments: IQuestionnaire[] = [];
  competitions: IQuestionnaire[] = [];
  myGroups: IGroup[] = [];
  otherGroups: IGroup[] = [];
  otherChallenges: IChallenge[] = [];
  otherTeams: ITeam[] = [];

  isMyHealthMissing1Column = false;
  isMyHealthMissing2Columns = false;
  isCommunityMissing1Column = false;
  isCommunityMissing2Columns = false;

  private userCompanyFeatureIds: number[] = [];

  isDevice: boolean;

  constructor(
    private userService: UserService,
    private featureService: FeatureService,
    private contentService: ContentService,
    private questionnaireService: QuestionnaireService,
    private groupService: GroupService,
    private router: Router,
    private infoPacksService: InfoPacksService,
    private partnerService: PartnerService,
    private programService: ProgramService,
    private utilService: UtilService,
    private challengeService: ChallengeService,
    private teamService: TeamService,
    private domainChecker: DomainCheckerService,
    private connectionService: ConnectionService,
    private authService: AuthService,
    private menuService: MenuService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
  ) {
  }

  ngOnInit() {
    this.isLoggedIn = this.userService.isLoggedIn();
    this.isDevice = this.utilService.isMobileDeviceScreenWidth(screen.width);
    if (!this.isLoggedIn || !this.userService.hasProfile()) {
      if (this.router.url.indexOf('/auth') > -1) {
        this.companyService.getCompanyInfoByDomain(this.domainChecker.hostname).subscribe(data => {
          this.companyLogo = `${this.userService.envVariables.aws.awsBucket}company_images/${data.logo}`;
        });
      }
      return;
    }

    this.initialiseFromUserService();
    this.setCanShowMenus();
    this.populateMenuItems();
    this.calculateMissingColumns();
  }

  isConnectionLoaded(): Observable<boolean> {
    return this.connectionService.isCompleted;
  }

  ngOnDestroy() {
    if (this.photoSrcSubscription) {
      this.photoSrcSubscription.unsubscribe();
    }

    if (this.userProgramSubscription) {
      this.userProgramSubscription.unsubscribe();
    }

    if (this.questionnairesChangedSubscription) {
      this.questionnairesChangedSubscription.unsubscribe();
    }

    if (this.groupsChangedSubscription) {
      this.groupsChangedSubscription.unsubscribe();
    }

    if (this.partnersChangedSubscription) {
      this.partnersChangedSubscription.unsubscribe();
    }
  }

  private initialiseFromUserService() {
    this.userCompanyFeatureIds = this.userService.getUserCompanyFeatures();

    this.companyName = this.userService.company
      ? this.userService.company.name
      : this.userService.getPlatformTitle();
    this.userService.loadCompanyTheming();
    this.userService.refreshCurrentProfileSrc();
    this.logoSrc = this.userService.getLogoSrc();
    this.photoSrc = this.userService.getMyPhotoSrc();
    this.levelSrc = this.userService.getMyLevelSrc();

    this.photoSrcSubscription = this.userService.profilePic$
      .subscribe(src => this.photoSrc = src);
  }

  private setCanShowMenus() {
    this.canShowSearch = false;  // TODO: Set to true when global search implemented
    this.canShowSupportFromCompany = this.featureService.isIncludeSupportAtCompanyFeature(this.userCompanyFeatureIds);
    this.canShowInfoPacks = this.featureService.isIncludeInfoPackFeature(this.userCompanyFeatureIds);
    this.canShowLocators = this.featureService.isIncludeLocatorFeature(this.userCompanyFeatureIds);
    this.canShowPartners = this.featureService.isIncludePartnersFeature(this.userCompanyFeatureIds);
    this.canShowMyRewards = this.featureService.isIncludeMyRewardsFeature(this.userCompanyFeatureIds);
    this.canShowCompetition = this.featureService.isIncludeChanceToWinFeature(this.userCompanyFeatureIds);
    this.canShowPeerSupport = this.featureService.isIncludePeerSupportFeature(this.userCompanyFeatureIds) && this.userService.isInpexCompanyUsers();
    this.canshowEapBooking = this.featureService.isIncludeEapBookingFeature(this.userCompanyFeatureIds);
    this.canShowDiscoverMenu = this.utilService.isAnyTrue(
      [this.canShowContent, this.canShowSupportFromCompany,
        this.canShowInfoPacks, this.canShowLocators,
        this.canShowPartners, this.canShowMyRewards,
        this.canShowCompetition,
      ]);

    this.canShowMyActivity = this.featureService.isIncludeTrackMyHealthFeature(this.userCompanyFeatureIds);
    this.canShowMyHabits = this.featureService.isIncludeLifeActionsFeature(this.userCompanyFeatureIds);
    this.canShowPrograms = this.featureService.isIncludeProgramsFeature(this.userCompanyFeatureIds);
    this.canShowHealthAssessments = this.featureService.isIncludeHealthRiskAssessmentsFeature(this.userCompanyFeatureIds);
    this.canShowMyHealthMenu = this.utilService.isAnyTrue(
      [this.canShowMyActivity, this.canShowMyHabits, this.canShowPrograms,
        this.canShowHealthAssessments]);

    this.canShowGroups = this.featureService.isIncludeGroupsFeature(this.userCompanyFeatureIds);
    this.canShowFriends = this.featureService.isIncludeFriendsFeature(this.userCompanyFeatureIds);
    this.canShowBadges = this.featureService.isIncludeBadgesFeature(this.userCompanyFeatureIds);
    this.canShowChallenges = this.featureService.isIncludeChallengesFeature(this.userCompanyFeatureIds);
    this.canShowTeams = this.featureService.isIncludeTeamsFeature(this.userCompanyFeatureIds);
    this.cnaShowCommunityMenu = this.utilService.isAnyTrue(
      [this.canShowGroups, this.canShowFriends, this.canShowBadges, this.canShowChallenges, this.canShowTeams]);

    this.canShowSettings = this.featureService.isIncludeStripeFeature(this.userCompanyFeatureIds);
    this.canShowNotifications = this.userService.hasProfile();
    this.canShowCms = this.userService.isReportsSuperAdminUser();
    this.canShowLeaderBoard = this.featureService.isIncludeLeaderboardFeature(this.userCompanyFeatureIds);
    this.canShowCreateNewGroup = this.featureService.isIncludeCreateNewGroup(this.userCompanyFeatureIds);
    this.canShowInsights = this.userService.hasInsightsAccess();
  }

  private calculateMissingColumns() {
    this.calculateMissingMyHealthColumns();
    this.calculateMissingCommunityColumns();
  }

  private calculateMissingMyHealthColumns() {
    let count = 0;
    if (!this.canShowMyActivity) {
      count++;
    }
    if (!this.canShowMyHabits) {
      count++;
    }
    if (!this.canShowPrograms) {
      count++;
    }
    if (!this.canShowHealthAssessments) {
      count++;
    }

    this.isMyHealthMissing1Column = (count === 1);
    this.isMyHealthMissing2Columns = (count === 2);
  }

  private calculateMissingCommunityColumns() {
    let count = 0;
    if (!this.canShowGroups) {
      count += 2;
    }
    if (!this.canShowFriends) {
      count++;
    }
    if (!this.canShowBadges) {
      count++;
    }
    if (!this.canShowChallenges) {
      count++;
    }
    if (!this.canShowTeams) {
      count++;
    }

    this.isCommunityMissing1Column = (count === 1);
    this.isCommunityMissing2Columns = (count === 2);
  }

  private populateMenuItems() {
    if (this.canShowLocators) {
      this.locatorReports = [
        { title: 'Childcare locator', route: '/toolkit/locators/childcare' },
        { title: 'School locator', route: '/toolkit/locators/school' },
        { title: 'Aged care locator', route: '/toolkit/locators/agedcare' },
      ];
    }

    if (this.canShowMyActivity) {
      this.myActivities = [
        { title: 'Track my health', route: '/act/your-health' },
        { title: 'Connect a device', route: '/act/devices' },
      ];
    }

    if (this.canShowMyHabits) {
      this.myHabits = [
        { title: 'LifeActions', route: '/act/lifeactions' },
      ];
    }

    this.canShowContent = false;
    this.menuService.getMenu().subscribe((data: IMenuObject[]) => {
      this.contentMenuItems = data[0];
      this.canShowContent = true;
    });
    this.getSupportFromCompanyItems();
    this.getInfoPacks();
    this.getUserPrograms();
    this.getCompetitions();
    this.subscribeToQuestionnairesChanges();
    this.subscribeToGroupsChanges();
    this.subscribeToPartnersChanges();
    this.subscribeToChallengesChanges();
    this.subscribeToTeamsChanges();
  }

  private subscribeToQuestionnairesChanges() {
    this.questionnairesChangedSubscription = this.questionnaireService.questionnairesChanged$
      .subscribe(() => {
        this.getHealthAssessments();
      });
  }

  private subscribeToGroupsChanges() {
    this.groupsChangedSubscription = this.groupService.groupsChanged$
      .subscribe(() => {
        this.getGroups();
      });
  }

  private getSupportFromCompanyItems() {
    if (!this.canShowSupportFromCompany) {
      return;
    }

    const pageSize = 30;
    this.contentService.getContent(this.isLoggedIn, CATEGORIES.ALL,
      FEATURES.SUPPORT_AT_COMPANY, pageSize, 0)
      .subscribe(items => {
        const idDescendingList = items
          .sort((a, b) => (a.id > b.id) ? -1 : 1)  // descending id order
          .map(i => {
            return {
              title: i.title,
              route: '/discover/support' + this.contentService.getContentItemUrlPath(i),
            };
          });

        if (this.canshowEapBooking) {
          const eapBooking: IMenuItem = {
            title: 'Booking',
            route: '/toolkit/booking',
          };

          idDescendingList.unshift(eapBooking);
        }

        if (this.canShowPeerSupport) {
          const peerSupportInteraction: IMenuItem = {
            title: 'Log A Peer Support Interaction',
            route: 'https://forms.office.com/r/a1PzcX2aLj',
          };

          idDescendingList.unshift(peerSupportInteraction);
        }

        this.canShowViewMoreSupportFromCompany
          = idDescendingList.length > maxSupportFromCompanyList;
        this.supportFromCompany = idDescendingList.slice(0, maxSupportFromCompanyList);
      });
  }

  private getInfoPacks() {
    if (!this.canShowInfoPacks) {
      return;
    }

    this.infoPacksService.getInfoPacksForMyCompany(LANGUAGES.en)// todo: change to this.userService.profile.lang
      .subscribe((infoPacks: IInfoPack[]) => {
        this.infoPacks = infoPacks;
      }, err => console.log('Error obtaining Info Packs data', err));
  }

  private getPartners() {
    if (!this.canShowPartners) {
      return;
    }

    this.partnerService.getPartners()
      .subscribe((partners: IPartner[]) => {
        this.partners = partners;
      });
  }

  private getUserPrograms() {
    if (!this.canShowPrograms) {
      return;
    }

    this.programs = [];
    this.programService.getUserPrograms().subscribe();
    this.userProgramSubscription = this.programService.userPrograms$
      .subscribe((userPrograms: IUserProgram[]) => {
        this.programs = userPrograms;
      });
  }

  private getHealthAssessments() {
    if (!this.canShowHealthAssessments) {
      return;
    }

    this.questionnaireService.getQuestionnaires(FEATURES.HEALTH_RISK_ASSESSMENTS)
      .subscribe((questionnaires: IQuestionnaire[]) => {
        this.healthAssessments = questionnaires;
      });
  }

  private getCompetitions() {
    if (!this.canShowCompetition) {
      return;
    }

    this.questionnaireService.getAllcompetitions()
      .subscribe((competitions: IQuestionnaire[]) => {
        this.competitions = competitions;

        if (!competitions || competitions.length === 0) {
          this.canShowCompetition = false;
        }
      });
  }

  private getGroups() {
    if (!this.canShowGroups) {
      return;
    }

    this.groupService.getUserGroups()
      .subscribe((groups: IGroup[]) => {
        const myGroupsSorted = this.groupService.sortGroupsByLastViewedDescending(groups.filter(g => g.groupMember));
        const otherGroupsSorted = this.groupService.sortGroupsByIdDescending(groups.filter(g => !g.groupMember));

        this.myGroups = myGroupsSorted.slice(0, maxGroupsInList);
        this.otherGroups = otherGroupsSorted.slice(0, maxGroupsInList);

        this.canShowViewMoreMyGroups = myGroupsSorted.length > maxGroupsInList;
        this.canShowViewMoreOtherGroups = otherGroupsSorted.length > maxGroupsInList;
      });
  }

  private getChallenges() {
    if (!this.canShowChallenges) {
      return;
    }

    this.challengeService.getAvailableChallenges()
      .subscribe((challenges: IChallenge[]) => {

        this.otherChallenges = challenges.slice(0, maxChallengesInList);

        this.canShowViewMoreOtherChallenges = challenges.length > maxChallengesInList;
      });
  }

  private getTeams() {
    if (!this.canShowTeams) {
      return;
    }

    this.teamService.getAvailableTeams()
      .subscribe((teams: ITeam[]) => {

        this.otherTeams = teams.slice(0, maxTeamsInList);

        this.canShowViewMoreOtherTeams = teams.length > maxTeamsInList;
      });
  }

  private hideNavbarDropdownMenu() {
    if (!this.isShowingNavbarToggler()) {
      return;
    }
    this.navbarToggler.nativeElement.click();
  }

  private hideDiscoverDropdownMenu() {
    this.isDiscoverMenuItemClicked = true;
    setTimeout(() => {
      this.isDiscoverMenuItemClicked = false;
      this.hideNavbarDropdownMenu();
    }, 500);
  }

  private hideMyHealthDropdownMenu() {
    this.isMyHealthMenuItemClicked = true;
    setTimeout(() => {
      this.isMyHealthMenuItemClicked = false;
      this.hideNavbarDropdownMenu();
    }, 500);
  }

  private hideCommunityDropdownMenu() {
    this.isCommunityMenutItemClicked = true;
    setTimeout(() => {
      this.isCommunityMenutItemClicked = false;
      this.hideNavbarDropdownMenu();
    }, 500);
  }

  private handleDiscoverMenuItemSelected(url: string, event: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    try {
      if (url.startsWith('http')) {
        window.open(url, '_blank');
      } else {
        this.router.navigateByUrl(url);
      }
    } catch (e) {
      console.error(e);
    }
    this.hideDiscoverDropdownMenu();
  }

  private isShowingNavbarToggler(): boolean {
    if (!this.navbarToggler) {
      return false;
    }

    const domElement = this.navbarToggler.nativeElement;
    return (domElement.offsetHeight > 0 && domElement.offsetWidth > 0);
  }

  private handleMyHealthItemSelected(url: string, event: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.router.navigateByUrl(url);
    this.hideMyHealthDropdownMenu();
  }

  private handleCommunityItemSelected(url: string, event: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.router.navigateByUrl(url);
    this.hideCommunityDropdownMenu();
  }

  onMainMenuClick(event: Event, page) {
    // if (event && !this.utilService.isMobileDeviceScreenWidth(screen.width)) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }
  }

  isExpandedNavbarToggler(): boolean {
    if (!this.isShowingNavbarToggler()) {
      return false;
    }
    if (!this.navbarTarget) {
      return false;
    }

    return this.navbarTarget.nativeElement.classList.contains('show');
  }

  onLogoSelected() {
    const url = '/home';
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
    this.hideNavbarDropdownMenu();
  }

  onContentFeatureSelected(level2: IMenuObject) {
    const url = `discover/content/${level2.sourceId}/${CATEGORIES.ALL}`;
    this.router.navigateByUrl(url);
    this.hideDiscoverDropdownMenu();
  }

  onContentCategorySelected(level2: IMenuObject, level3: IMenuObject) {
    const url = `discover/content/${level2.sourceId}/${level3.sourceId}`;
    this.router.navigateByUrl(url);
    this.hideDiscoverDropdownMenu();
  }

  onToggleNavbarTarget() {
    // const wasExpandedPreToggle = this.isExpandedNavbarToggler();
    // const timeout = (wasExpandedPreToggle)
    //   ? 400
    //   : 0;
    //
    // setTimeout(() => {
    //   this.isHamburgerExpanded = !wasExpandedPreToggle;
    // }, timeout);
  }

  onDiscoverMenuItem(route: string, event: Event) {
    this.handleDiscoverMenuItemSelected(route, event);
  }

  onAllSupportSelected(event: Event) {
    // TODO: handle click "SUPPORT FROM"
    const url = '/discover/support';
    this.handleDiscoverMenuItemSelected(url, event);
  }

  onAllInfoPacksSelected(event: Event) {
    const url = '/toolkit/infopacks';
    this.handleDiscoverMenuItemSelected(url, event);
  }

  onInfoPackSelected(infoPack: IInfoPack, event: Event) {
    const url = this.userService.getInfoPackFile(infoPack.file);
    this.handleDiscoverMenuItemSelected(url, event);
    window.open(url, '_blank');
  }

  onAllLocatorsSelected(event: Event) {
    const url = '/toolkit/locators';
    this.handleDiscoverMenuItemSelected(url, event);
  }

  onAllPartnersSelected(event: Event) {
    const url = '/toolkit/partners';
    this.handleDiscoverMenuItemSelected(url, event);
  }

  onPartnerSelected(partner: IPartner, event: Event) {
    const url = `/toolkit/partners/${partner.id}`;
    this.handleDiscoverMenuItemSelected(url, event);
  }

  onMyHealthMenuItem(route: string, event: Event) {
    this.handleMyHealthItemSelected(route, event);
  }

  onMyActivity() {
    const url = '/act';
    this.router.navigateByUrl(url);
    this.hideMyHealthDropdownMenu();
  }

  onMyHabits() {
    const url = '/act/lifeactions';
    this.router.navigateByUrl(url);
    this.hideMyHealthDropdownMenu();
  }

  onAllHealthAssessmentsSelected(event: Event) {
    const url = '/act/healthriskassessments';
    this.handleMyHealthItemSelected(url, event);
  }

  onHealthAssessmentSelected(healthAssessment: IQuestionnaire, event: Event) {
    this.handleMyHealthItemSelected(healthAssessment.urlPath, event);
  }

  onMyRewards(event: Event) {
    const url = '/reward/my-rewards';
    this.handleDiscoverMenuItemSelected(url, event);
  }

  onCompetitionSelected(competition: IQuestionnaire, event: Event) {
    this.handleDiscoverMenuItemSelected(competition.urlPath, event);
  }

  onSelectSupportFromCompany(event: Event) {
    const url = '/discover/support';
    this.handleDiscoverMenuItemSelected(url, event);
  }

  onNotificationsClosed() {
    this.hideNavbarDropdownMenu();
  }

  onAllProgramsSelected(event: Event) {
    const url = '/act/programs';
    this.handleMyHealthItemSelected(url, event);
  }

  onProgramSelected(program: IUserProgram, event: Event) {
    const url = `/act/programs-mega/${program.id}`;
    this.handleMyHealthItemSelected(url, event);
  }

  onCreateGroup() {
    const createGroupRoute = '/share/groups-new';
    this.router.navigateByUrl(createGroupRoute);
    this.hideCommunityDropdownMenu();
  }

  onSelectMyGroups(event: Event) {
    const url = '/share/groups';
    this.handleCommunityItemSelected(url, event);
  }

  onSelectCreateNewGroup(event: Event) {
    const url = '/share/groups/new';
    this.handleCommunityItemSelected(url, event);
  }

  onSelectOtherGroups(event: Event) {
    const url = '/share/groups?other=true';
    this.handleCommunityItemSelected(url, event);
  }

  onOpenGroup(group: IGroup, event: Event) {
    const url = `/share/groups/${group.id}?reset=true`;
    this.handleCommunityItemSelected(url, event);
  }

  onFriendsSelected() {
    const url = '/share/friends';
    this.router.navigateByUrl(url);
    this.hideCommunityDropdownMenu();
  }

  onMyBadges() {
    const url = '/reward/badges';
    this.router.navigateByUrl(url);
    this.hideMyHealthDropdownMenu();
  }

  onSearchTermEntered(searchTerm: string) {

  }

  onMobileShowNotifications() {
    const url = '/notifications';
    this.router.navigateByUrl(url);
    this.hideNavbarDropdownMenu();
  }

  onChallengesSelected() {
    const url = '/act/challenges';
    this.router.navigateByUrl(url);
    this.hideCommunityDropdownMenu();
  }

  onTeamsSelected() {
    const url = '/act/teams';
    this.router.navigateByUrl(url);
    this.hideCommunityDropdownMenu();
  }

  onOpenChallenge(challenge: IChallenge, event: Event) {
    const url = `/act/challenge-details/${challenge.id}`;
    this.handleCommunityItemSelected(url, event);
  }

  onOpenTeam(team: ITeam, event: Event) {
    const url = `/act/team-details/${team.id}`;
    this.handleCommunityItemSelected(url, event);
  }

  onLeaderboard() {
    const url = `/share/leaderboard`;
    this.router.navigateByUrl(url);
    this.hideCommunityDropdownMenu();
  }


  private subscribeToPartnersChanges() {
    this.partnersChangedSubscription = this.partnerService.partnersChanged$
      .subscribe(() => {
        this.getPartners();
      });
  }

  private subscribeToChallengesChanges() {
    this.challengesChangedSubscription = this.challengeService.challengesChanged$
      .subscribe(() => {
        this.getChallenges();
      });
  }

  private subscribeToTeamsChanges() {
    this.teamsChangedSubscription = this.teamService.teamsChanged$
      .subscribe(() => {
        this.getTeams();
      });
  }

  onLoginClick() {
    this.authService.handleLoginProcess();
  }
}
