import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-like-indicator',
  templateUrl: 'like-indicator.html',
  styleUrls: ['./like-indicator.scss']
})
export class LikeIndicatorComponent implements OnInit {
  @Input('liked') liked: boolean;
  @Input('likes') likes: number;
  @Input('isLoggedIn') isLoggedIn: boolean;
  @Input('bigger') bigger: boolean;
  @Input('primaryColor') primaryColor: boolean;
  css: string;

  constructor() {}

  ngOnInit() {
    this.css = this.getCss();
  }

  private getCss(): string {
    const classes: string[] = [];

    if (this.bigger) {
      classes.push('likes-lg');
    } else {
      classes.push('likes-sm');
    }

    if (!this.primaryColor) {
      classes.push('grey');
    }

    return classes.join(' ');
  }
}
