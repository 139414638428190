<app-navbar *ngIf='!isMarketingPage && (!isInsightsDomain || !isUserLoggedId())' [logoSrc]='isInsightsDomain? "assets/logo/altiusinsights_logo.png" : ""' class='fixed-top'></app-navbar>

<div *ngIf='isInsightsDomain' class='insights-landing'>
  <div class='header-container'>
    <div class='header-background' [style.background-image]='"url(../../assets/altiuslife/landing_insights.jpg)"'>
    </div>
    <a href='https://altius-group.com.au/'>
      <img *ngIf='isMarketingPage' class='altiusgroup-logo' src='assets/logo/altiusgroup_logo.png' alt='AltiusGroup' />
    </a>
    <div class='promo-container'>
      <div class='promo-text'>{{config.landingPageText.insightsWelcomeText}}</div>
      <button class='promo-button' (click)='onLoginClick()'>LOGIN</button>
    </div>
  </div>
  <div class='promo-description'>
    <div class='container'>
      <div class='row'>
        <div class='col-sm-12'>
          <div class='home-page-text primaryColor'>
            <h2>{{config.landingPageText.insightsDescription}}</h2>
            <!--<h3>{{config.landingPageText.insightsContactText}}</h3>-->
          </div>
          <!-- <button class='btn-contact' (click)='onContactButtonClick()'>Contact Us</button> -->
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf='!isInsightsDomain'>
  <div class='header-container'>
    <div class='header-background' [style.background-image]='getLandingImage()'>
    </div>
    <a href='https://altius-group.com.au/'>
      <img *ngIf='isMarketingPage' class='altiusgroup-logo' src='assets/logo/altiusgroup_logo.png' alt='AltiusGroup' />
    </a>
    <div class='welcome-content'>
      <div class='welcome-title'>
        {{config.landingPageText.homePageSloganText}}
      </div>
      <div class='button-wrapper'>
        <a *ngIf='!usedSSO' class='signup-button backPrimary' (click)='onSignUpClick()'>SIGN UP</a>&nbsp;
        <a *ngIf='!isMarketingPage' class='login-button primaryColor' (click)='onLoginClick()'>LOGIN</a>&nbsp;
      </div>
    </div>
    <div *ngIf='!isMarketingPage && !usedSSO' class='promo-container'>
      <div class='promo-text'>{{config.landingPageText.homePageWelcomeText}}</div>
      <button class='promo-button' (click)='onSignUpClick()'>Sign Up</button>
    </div>
    <div *ngIf='isMarketingPage' class='promo-container login-box'>
      <img class='altiuslife-logo' src='assets/logo/altiuslife_word_only.png' alt='AltiusLife' />
      <div class='login-text'>{{config.landingPageText.marketingPageLoginText}}</div>
    </div>
  </div>
  <div class='promo-description'>
    <div class='container'>
      <div class='row'>
        <div class='col-sm-6'>
          <div class='home-page-text primaryColor'>
            <h3 *ngIf='!isMarketingPage'>{{config.landingPageText.homePageTitle}}</h3>
            <h2>{{config.landingPageText.homePageRegisterText}}</h2>
          </div>
          <div class='divider-one primaryColor'></div>
          <div class='divider-two'></div>
          <div class='summary-desc'>
            {{config.landingPageText.homePageSubText}}
          </div>
          <div *ngIf='isMarketingPage && marketingPageData' class='app-container'>
            <a class='app-store-icon' [href]='marketingPageData.appStoreURL'><img src='assets/logo/btn_app_store.png' alt='App Store' /></a>
            <a class='google-play-icon' [href]='marketingPageData.googlePlayURL'><img src='assets/logo/btn_google_play.png' alt='Google Play' /></a>
          </div>
          <button *ngIf='isMarketingPage' class='btn-contact' (click)='onContactButtonClick()'>Contact Us</button>
        </div>
        <div class='col-sm-6'>
          <div class='row text-center' [class.m-t-n15]='isMarketingPage'>
            <div *ngFor='let feature of features'
                 [class]="features.length === 4 ? 'col-12 col-sm-6 col-md-3 pb-3 pt-3' : 'col p-3 features_col'">
                  <span class='circle backPrimary'>
                    <span class='circle-icon'>
                    <i [class]='feature.icon'></i>
                  </span>
                </span>
              <h2 class='feature-title'>{{feature.name}}</h2>
              <p class='feature-desc'>{{feature.text}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

