<app-cms-list-view
  *ngIf="isDisplayingList && isInitialised"
  [dataList]="items"
  [additionalNumericColumns]="additionalNumericColumns"
  [columnList]="columns">
</app-cms-list-view>

<div
  *ngIf="(isAddingItem || isEditingItem) && isInitialised"
  class="padding jumbotron">
  <form
    [formGroup]="itemForm"
    (ngSubmit)="onSaveClick()" >

    <div class="row m-0">
      <div class="col-12">
        <!-- Name -->
        <div class="form-group">
          <label for="name" class="control-label required">
            Name
          </label>
          <input
            type="text"
            class="form-control"
            [ngClass]="displayFieldCss('name')"
            formControlName="name">
          <span class="invalid-feedback">Name is required</span>
        </div>

         <!-- Email -->
         <div class="form-group">
          <label for="contactEmail" class="control-label required">
            Contact Email
          </label>
          <input
            type="text"
            class="form-control"
            [ngClass]="displayFieldCss('contactEmail')"
            (change)="onEmailChange()"
            formControlName="contactEmail">
          <span class="invalid-feedback">Email is required and valid</span>
        </div>       

        <!-- Logo -->
        <div class="form-group">
          <label for="logo" class="control-label required">
            Logo
          </label>
          <div class="input-group">
            <input
              type="text"
              name="logo" id="logo"
              class="form-control"
              [ngClass]="displayFieldCss('logo')"
              placeholder="Choose the logo"
              formControlName="logo"
              readonly />
            <div class="input-group-prepend">
              <button
                type="button"
                class="input-group-text btn btn-primary"
                (click)="onLogoClick()">
                  Browse
              </button>
            </div>
            <span class="invalid-feedback">Logo is required</span>
          </div>
        </div>

         <!-- Title -->
         <div class="form-group">
          <label for="title" class="control-label required">
            TItle
          </label>
          <input
            type="text"
            class="form-control"
            [ngClass]="displayFieldCss('title')"
            formControlName="title">
          <span class="invalid-feedback">Title is required</span>
        </div>  

         <!--- shortText -->
         <div class="form-group">
          <label class="control-label required">Short Text</label>
          <input
          type="text"
          class="form-control"
          [ngClass]="displayFieldCss('shortText')"
          formControlName="shortText">
          <span class="invalid-feedback">Short Text is required.</span>
        </div>

        <!--- longText -->
        <div class="form-group">
          <label class="control-label required">Long Text</label>
          <div [froalaEditor]="options" (froalaInit)="initialize($event)" formControlName="longText"></div>
          <span class="invalid-feedback">Long Text is required.</span>
        </div>

        <!-- Form Fields  -->
        <label class="control-label required mt-4">Form Fields</label>
        <div class="block">
          <div class="form-group">           
            <div class="row">
              <div class="col-6 col-sm-4" *ngFor="let f of formFieldsItems; let i = index">
                <div class="custom-control custom-checkbox">
                  <input id="f{{f.id}}" type="checkbox"  [checked]="f.checked"
                    (click)="setFormFields(f)" class="custom-control-input">
                  <label class="custom-control-label" for="f{{f.id}}">{{f.name}}</label>
                </div>
              </div>
            </div>
          </div></div>

        <!-- Terms of Service -->
        <div class="form-group">
          <label for="termsOfService" class="control-label">
            Terms of Service
          </label>
          <input
            type="text"
            class="form-control"
            [ngClass]="displayFieldCss('termsOfService')"
            formControlName="termsOfService">
        </div> 

        <!-- Privacy Policy -->
        <div class="form-group">
          <label for="privacyPolicy" class="control-label">
            Privacy Policy
          </label>
          <input
            type="text"
            class="form-control"
            [ngClass]="displayFieldCss('privacyPolicy')"
            formControlName="privacyPolicy">
        </div> 

        <!-- Categories -->
        <div class="form-group">
          <label class="control-label">Categories</label>
          <select type="text" class="form-control" formControlName="categoryId">
            <option *ngFor="let category of categories" [value]="category.id">
              {{category.name}}
            </option>
          </select>
        </div>

        <!-- Conditions Text -->
        <div class="form-group">
          <label for="privacyPolicy" class="control-label">
            Conditions Text
          </label>
          <input
            type="text"
            class="form-control"
            [ngClass]="displayFieldCss('conditionsText')"
            formControlName="conditionsText">
        </div> 

        <!-- Website -->
        <div class="form-group">
          <label for="privacyPolicy" class="control-label">
            Website
          </label>
          <input
            type="text"
            class="form-control"
            [ngClass]="displayFieldCss('website')"
            formControlName="website">
            <span class="invalid-feedback">Invalid URL.</span>
        </div> 

        <!-- Companies -->
        <div class="form-group" *ngIf="canSelectCompany">
            <app-select-company [allCompanies]="allCompanies" [selectedCompanyId]="itemForm.get('companyId').value"
              [label]="'Company'" [placeholder]="'All (default)'" (companyIdSelected)="onCompanySelected($event)"></app-select-company>
        </div>

        <!-- Excluded Companies -->
        <div *ngIf="isAllCompanies()" class="form-group">
          <app-select-companies [allCompanies]="allCompanies" [selectedCompanyIds]="excludedCompanyIds" [label]="'Excluded Companies'"
            [placeholder]="'Select Company to exclude'" (companyIdsSelected)="excludedCompanyIdsSelected($event)"></app-select-companies>
        </div>
      </div>
    </div>
    <!-- <div class="previewButton py-3">
      <button type="button" data-toggle="tooltip" data-placement="top" title="Preview" class="btn btn-primary btn-circle"
        (click)="showPreview()">
        <div class="text">
          <i class="fa fa-eye fa-2x" aria-hidden="true"></i>
          <p>Preview</p>
        </div>
      </button>
    </div> -->
    <!-- Buttons -->
    <br />
    <div class="row">
      <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
        <button
          type="button"
          class="btn btn-outline-primary w-100"
          (click)="onBackClick()">
          <i class="fa fa-undo mr-2" aria-hidden="true"></i> BACK
        </button>
      </div>

      <div *ngIf="isEditingItem"
        class="col-4">
        <button
          type="button"
          class="btn btn-outline-primary w-100"
          [disabled]="!canRespondToButtons"
          (click)="onDeleteClick()">
          <i class="fa fa-trash mr-2" aria-hidden="true"></i> DELETE
        </button>
      </div>

      <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
        <button
          type="submit"
          class="btn btn-primary float-right w-100"
          [disabled]="!canRespondToButtons || !this.itemForm.valid">
          <i class="fa fa-floppy-o mr-2" aria-hidden="true"></i> SAVE
        </button>
      </div>
    </div>
  </form>
</div>
