<div class="card h-100">
  <div class="card-body">
    <div>
      <div class="row">
        <div class="col-8">
          <h5>{{indicator.name}}</h5>
          <div class="mb-2">
            <span class="badge badge-primary mr-1">{{recurrenceString | titlecase}}</span>
            <span class="badge badge-success">{{indicator.driverName | titlecase}}</span>
          </div>
        </div>
        <div class="col-4 text-right">
          <div *ngIf="isMultipleDaily">
            <div *ngIf="!isAchieved">
              <button type="button" class="btn btn-primary btn-sm" (click)="completeLatestLifeAction()">{{trackerProgress}}/{{trackerGoal}}</button>
            </div>
            <div *ngIf="isAchieved">
              <p class="primaryColor">
                <b>Done
                  <i class="fa fa-check" aria-hidden="true"></i>
                </b>
              </p>
            </div>
          </div>
          <div *ngIf="!isMultipleDaily">
            <div *ngIf="!isAchieved">
              <button type="button" class="btn btn-primary btn-sm" (click)="completeLatestLifeAction()">Do It!</button>
            </div>
            <div *ngIf="isAchieved">
              <p class="primaryColor">
                <b>Done
                  <i class="fa fa-check" aria-hidden="true"></i>
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <p>{{indicator.description}}</p>


    </div>
    <div *ngIf="trackersPresent" class="mt-3">
      <a class="accordion-toggle" data-toggle="collapse" [attr.data-target]="'#collapsibleActive' + index"
        aria-expanded="false">{{lastCompletedDateLabel}}
        <i class="fa fa-caret-down"></i>
        <i class="fa fa-caret-up"></i>
      </a>
      <app-active-life-action-history [trackers]="historyTrackers" [recurrenceValue]="indicator.recurrence" [id]="'collapsibleActive' + index"></app-active-life-action-history>
    </div>
  </div>
</div>
