import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  EventEmitter,
  Output
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LikeService } from '../../../../services/like-service';
import { BadgeService } from '../../../../services/badge-service';
import { UserService } from '../../../../services/user-service';
import { ContentService } from '../../../../services/content-service';
import { IContentDetail } from '../../../_models/content-detail';
import { ILikeChanged } from '../../../_models/like-changed';

@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContentListComponent implements OnInit, OnDestroy {
  contentIds: any;
  showMore = true;
  articlesIds = [];
  recipesIds = [];
  videosIds = [];
  articleFeatureId = 3;
  recipeFeatureId = 4;
  videoFeatureId = 5;
  contentType = '0';
  limit = 12;
  offset = 0;
  categoryId = 0;
  featureId = 0;
  noMoreResults: Boolean = false;
  preferenceLanguage: string;

  totalContent = [];
  loading = true;
  @Output() event = new EventEmitter<string>();
  isSearching: Boolean = false;
  isLoggedIn = false;
  bookmarkSubscription: Subscription;
  likeSubscription: Subscription;

  constructor(
    private contentService: ContentService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    public badgeService: BadgeService,
    public activeModal: NgbActiveModal) {
  }

  isSearch(isSearching: boolean) {
    // boolean is set in search component typeScript
    this.isSearching = isSearching;
  }

  ngOnInit() {
    this.isLoggedIn = this.userService.isLoggedIn();
    this.activatedRoute.data.subscribe(data => {
      if (data.featureIdCL) {
        this.featureId = data.featureIdCL;
      }
    });
    this.activatedRoute.params.subscribe((params) => {
      let cat = params.category;
      if (!cat) {
        cat = this.router.url;
      }
      switch (cat) {
        case 'all': {
          this.categoryId = 0;
          break;
        }
        case '/discover/support': {
          this.featureId = 60;
          break;
        }
        case 'fitness': {
          this.categoryId = 93;
          break;
        }
        case 'fuel': {
          this.categoryId = 94;
          break;
        }
        case 'staying_healthy': {
          this.categoryId = 95;
          break;
        }
        case 'finding_happy': {
          this.categoryId = 96;
          break;
        }
        case 'building_resilience': {
          this.categoryId = 97;
          break;
        }
        case 'relationships': {
          this.categoryId = 98;
          break;
        }
        case 'financial_planning': {
          this.categoryId = 99;
          break;
        }
        case 'career_focus': {
          this.categoryId = 100;
          break;
        }
        case 'work_life': {
          this.categoryId = 101;
          break;
        }
        case 'soups_salads_and_sides': {
          this.categoryId = 89;
          break;
        }
        case 'vegan_and_vegetarian': {
          this.categoryId = 90;
          break;
        }
        case 'meat_and_fish': {
          this.categoryId = 91;
          break;
        }
        case 'smoothies_and_desserts': {
          this.categoryId = 92;
          break;
        }
        case 'planning_and_pregnancy': {
          this.categoryId = 102;
          break;
        }
        case 'babies_and_kids': {
          this.categoryId = 103;
          break;
        }
        case 'back_to_work': {
          this.categoryId = 104;
          break;
        }
        case 'feel': {
          this.categoryId = 105;
          break;
        }
        case 'nourish': {
          this.categoryId = 107;
          break;
        }
        case 'exercise': {
          this.categoryId = 108;
          break;
        } case 'mindset': {
          this.categoryId = 109;
          break;
        }
        case 'health': {
          this.categoryId = 110;
          break;
        }
        case 'nutrition': {
          this.categoryId = 111;
          break;
        }
      }

      this.activatedRoute.data.subscribe(() => {
        this.getContent(0);
      });

      this.bookmarkSubscription = this.contentService.bookmarkEmitted
        .subscribe((event) => {
          if (this.totalContent.length) {
            if (event.newBookmark) {
              const contentMatch = this.totalContent.find(
                (c) => Number.parseInt(c.id) === event.contentId
              );
              contentMatch.bookmarkId = event.bookmarkId;
            } else {
              const contentMatch = this.totalContent.find((c) => c.bookmarkId === event.bookmarkId);
              contentMatch.bookmarkId = null;
            }
          }
        });
      });

    this.likeSubscription = this.contentService.likeChangeEmitted
      .subscribe((event: ILikeChanged) => {
        const item: IContentDetail = this.totalContent.find(c => +c.id === event.contentId);
        if (item) {
          item.isLiked = event.isLiked;
          item.liked = event.liked;
          item.likes = event.likes;
        }
      });
  }

  ngOnDestroy() {
    this.totalContent = [];

    if (this.bookmarkSubscription) {
      this.bookmarkSubscription.unsubscribe();
    }

    if (this.likeSubscription) {
      this.likeSubscription.unsubscribe();
    }
  }

  getContent(ids) {
    this.contentService
      .getContent(this.isLoggedIn, this.categoryId, this.featureId, this.limit, ids, this.preferenceLanguage)
      .subscribe((items) => {
        if (this.totalContent.length === 0) {
          this.totalContent = items;
        } else {
          this.totalContent = this.totalContent.concat(...items);
        }

        items.map((item) => {
          switch (item.contentType) {
            case 1:
              this.articlesIds.push(item.id);
              break;
            case 2:
              this.recipesIds.push(Number.parseInt(item.id));
              break;
            case 3:
              this.videosIds.push(item.id);
              break;
          }
        });
        this.contentIds = {
          articles: this.articlesIds,
          recipes: this.recipesIds,
          videos: this.videosIds
        };
        if (items.length < this.limit) {
          this.showMore = false;
        }
        this.loading = false;
      });
  }

  isNoResultsFound(): boolean {
    return !this.totalContent.length && !this.loading;
  }

  canViewMore(): boolean {
    return this.totalContent.length > 0  && this.showMore;
  }

  viewMore() {
    if (this.isLoggedIn) {
      if (this.isSearching) {
        // get the current offset from the content service observable and add limit of 6
        let newOffset;
        this.contentService.getSearchOffset().subscribe((data) => {
          newOffset = data + this.limit;
        });
        this.contentService.changeSearchOffset(newOffset);
        // change viewMore observable to true in order to trigger the search function again
        this.contentService.changeViewMore(true);
      } else {
        this.getContent(this.contentIds);
      }
    } else {
      if (this.isSearching) {
        // get the current offset from the content service observable and add limit of 6
        let newOffset;
        this.contentService.getSearchOffset().subscribe((data) => {
          newOffset = data + this.limit;
        });
        this.contentService.changeSearchOffset(newOffset);
        // change viewMore observable to true in order to trigger the search function again
        this.contentService.changeViewMore(true);
      } else {
        this.getContent(this.contentIds);
      }
    }
  }

  generateGoToDetailLink(item) {
    let type;
    switch (item.contentType) {
      case 1:
        type = 'article';
        break;
      case 2:
        type = 'recipe';
        break;
      case 3:
        type = 'video';
        break;
    }
    return [`${this.router.url}`, type, item.id];
  }

  getContentSearch(totalContent: any): void {
    this.totalContent = totalContent;
    this.showMore = !(this.totalContent.length < this.limit || this.totalContent.length % this.limit > 0);
  }

  toggleShowMore(e) {
    if (e === true) {
      this.showMore = false;
      if (this.totalContent.length) {
        this.noMoreResults = true;
      }
    } else {
      this.showMore = true;
      this.noMoreResults = false;
    }
  }

  canToggleLanguagePreference() {
    return this.userService.canToggleArticleLanguage();
  }

  displayArticleBySelectedlanguage(e) {
    this.preferenceLanguage = e;
    this.offset = 0;
  }
}
