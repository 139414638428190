<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-center">
  <h5 [innerHTML]="message"></h5>
  <img class="dialog-image" src="{{image}}" height="100px">
  <p class="bold">{{description}}</p>
  <div class="footerButtons">
    <button type="button" class="btn btn-primary" (click)="viewBadges()">View Badges</button>
    <button type="button" class="btn btn-outline-primary" (click)="close()">Close</button>
  </div>
</div>
