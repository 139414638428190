<div>
  <label *ngIf='label'>{{label}}</label>
  <select class='custom-select form-control'
          (change)='onCompanySelected($event)'>
    <option *ngIf='(placeholder && !allOptionSelected) || (addAllOption && selectedCompanyIds.length)' value='0' selected>{{placeholder}}</option>
    <option *ngFor='let company of unselectedCompanies' [value]='company.id' [selected]='(allOptionSelected && !company.id) || (addAllOption && !selectedCompanyIds.length && !company.id)'>{{company.name}}</option>
  </select>

  <div class='form-group'>
    <div class='row' style='margin-top: 15px;'>
      <div *ngFor='let companyId of selectedCompanyIds' class='col-3'>
        <button type='button'
                class='btn btn-danger btn-sm'
                (click)='onSelectedCompanyClick(companyId)'>
          <i class='fa fa-undo'></i>
        </button>
        {{companyName(companyId)}}
      </div>
    </div>
  </div>
</div>
