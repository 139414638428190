import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ChildCareLocatorComponent } from './child-care-locator/child-care-locator.component';
import { InfoPacksComponent } from './infopacks/infopacks.component';
import { AgedCareLocatorComponent } from './aged-care-locator/aged-care-locator.component';
import { SharedModule } from '../_shared/shared.module';
import { ToolkitRoutingModule } from './toolkit-routing.module';
import { ToolkitComponent } from './toolkit.component';
import { ResourcesComponent } from './resources/resources.component';
import { PartnersComponent } from './partners/partners.component';
import { PartnerDetailComponent } from './partner-detail/partner-detail.component';
import { LocatorComponent } from './locator/locator.component';
import { SchoolLocatorComponent } from './school-locator/school-locator.component';
import { EapBookingDetailComponent } from './eap-booking-detail/eap-booking-detail.component';

@NgModule({
  declarations: [
    ToolkitComponent,
    InfoPacksComponent,
    ResourcesComponent,
    PartnersComponent,
    PartnerDetailComponent,
    LocatorComponent,
    ChildCareLocatorComponent,
    AgedCareLocatorComponent,
    SchoolLocatorComponent,
    EapBookingDetailComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModule,
    ToolkitRoutingModule
  ]
})
export class ToolkitModule {}
