import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../services/user-service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  model: any = {};
  brand: any;
  externalContacts: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    public userService: UserService,
  ) { }

  ngOnInit() {
    this.brand = this.userService.brand;
    this.externalContacts = this.userService.externalContacts;
  }

  openLink(url) {
    window.open(url, '_blank');
  }

  // isEmailContact(contact): boolean {
  //   return contact.paragraph.indexOf('@') > 0;
  // }

  isLinkContact(contact): boolean {
    return contact.link;
  }

  isNormalContact(contact): boolean {
    return !this.isLinkContact(contact);
  }
}
