<nav *ngIf='!isLoggedIn' class='navbar navbar-expand-lg py-2 show-login'>
  <img class='top-logo altius-life' src='assets/logo/altiuslife_logo.png' alt=''>
  <div class='company-logo'>
    <img *ngIf='companyLogo' class='top-logo' [src]='companyLogo' alt='Logo'/>
  </div>
  <button type='button' class='login' [disabled]='!(isConnectionLoaded() | async)' (click)='onLoginClick()'>
    Login
  </button>
</nav>

<nav *ngIf='isLoggedIn && logoSrc' class='navbar navbar-expand-lg'>
  <button #navbarToggler
          class='navbar-toggler navbar-dark' type='button'
          (click)='onToggleNavbarTarget()'
          data-toggle='collapse' data-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent' aria-expanded='Toggle navigation'>
    <span class='navbar-toggler-icon mt-1 mb-1'></span>
  </button>
  <a class='navbar-brand mr-auto' (click)='onLogoSelected()'>
    <img *ngIf='logoSrc' [src]='logoSrc' alt='logo' class='brand-logo' />
  </a>

  <div #navbarTarget id='navbarSupportedContent' class='collapse navbar-collapse'>
    <ul class='navbar-nav ml-md-auto list-unstyled'>
      <!-- Discover -->
      <li *ngIf='canShowDiscoverMenu' class='nav-item dropdown discover-menu'>
        <a class='nav-link dropdown-toggle'
           href='#' id='navbarDropdown'
           role='button' data-toggle='dropdown'
           (click)="onMainMenuClick($event,'discover')"
           aria-haspopup='true' aria-expanded='false' #discoverMenu>
          Discover
        </a>
        <div class='dropdown-menu' [ngClass]='{ hide: isDiscoverMenuItemClicked }' aria-labelledby='navbarDropdown' #discoverDropdownMenu>
          <div class='container'>
            <div *ngIf='canShowContent && !isDevice' class='row justify-content-center'>
              <div *ngFor='let level2 of contentMenuItems.children' class='col col-max-250px'>
                <ul class='nav flex-column '>
                  <a class='nav-link' (click)='onContentFeatureSelected(level2)'>
                    {{ level2.name }}
                  </a>
                  <li *ngFor='let level3 of level2.children' class='nav-item d-none d-md-none d-lg-block'>
                    <a (click)='onContentCategorySelected(level2, level3)'>
                      {{ level3.name }}
                    </a>
                  </li>
                </ul>
              </div>
              <hr class='content-divider' />
            </div>

            <div *ngIf='!isDevice' class='row justify-content-center'>
              <div *ngIf='canShowMyRewards || canShowCompetition' class='col col-max-250px'>
                <a *ngIf='canShowMyRewards' class='nav-link text-uppercase pt-0 px-0' (click)='onMyRewards($event)'>
                  My Rewards
                </a>
                <li *ngIf='canShowMyRewards' class='nav-item d-none d-md-none d-lg-block'>
                  <a (click)='onMyRewards($event)'>
                    Member benefits
                  </a>
                </li>
                <li *ngFor='let item of competitions' class='nav-item d-none d-md-none d-lg-block'>
                  <a class='nav-item' (click)='onCompetitionSelected(item, $event)'>
                    {{ item.name }}
                  </a>
                </li>
              </div>

              <div *ngIf='canShowPartners' class='col col-max-250px'>
                <ul *ngIf='canShowPartners' class='nav flex-column menu-items mb-0 mb-sm-0 mb-md-0 mb-lg-4'>
                  <a class='nav-link text-uppercase pt-0' (click)='onAllPartnersSelected($event)'>
                    Partners
                  </a>
                  <li *ngFor='let item of partners' class='nav-item d-none d-md-none d-lg-block'>
                    <a (click)='onPartnerSelected(item, $event)'>
                      {{ item.partnerName }}
                    </a>
                  </li>
                </ul>
              </div>

              <div *ngIf='canShowSupportFromCompany' class='col col-max-250px'>
                <ul class='nav flex-column menu-items'>
                  <a class='nav-link text-uppercase pt-0' (click)='onAllSupportSelected($event)'>
                    Support from {{companyName}}
                  </a>
                  <li *ngFor='let item of supportFromCompany' class='nav-item d-none d-md-none d-lg-block'>
                    <a (click)='onDiscoverMenuItem(item.route, $event)'>
                      {{ item.title }}
                    </a>
                  </li>
                  <li *ngIf='canShowViewMoreSupportFromCompany' class='nav-item d-none d-md-none d-lg-block'>
                    <a (click)='onSelectSupportFromCompany($event)' class='ml-4'>
                      view more...
                    </a>
                  </li>
                </ul>
              </div>

              <div *ngIf='canShowInfoPacks' class='col col-max-250px'>
                <ul class='nav flex-column menu-items mb-0 mb-sm-0 mb-md-0 mb-lg-4'>
                  <a class='nav-link text-uppercase pt-0' (click)='onAllInfoPacksSelected($event)'>
                    {{companyName}} Information
                  </a>
                  <li *ngFor='let item of infoPacks' class='nav-item d-none d-md-none d-lg-block'>
                    <a (click)='onInfoPackSelected(item, $event)'>
                      {{ item.title }}
                    </a>
                  </li>
                </ul>
              </div>

              <div *ngIf='canShowLocators' class='col col-max-250px'>
                <ul class='nav flex-column menu-items'>
                  <a class='nav-link text-uppercase pt-0' (click)='onAllLocatorsSelected($event)'>
                    Locator Reports
                  </a>
                  <li *ngFor='let item of locatorReports' class='nav-item d-none d-md-none d-lg-block'>
                    <a (click)='onDiscoverMenuItem(item.route, $event)'>
                      {{ item.title }}
                    </a>
                  </li>
                </ul>
              </div>

  </div>

  <div *ngIf='isDevice' class='row'>
    <div *ngIf='canShowContent'>

      <div *ngFor='let level2 of contentMenuItems.children' class='col'>
        <ul class='nav flex-column'>
          <a class='nav-link' (click)='onContentFeatureSelected(level2)'>
            {{ level2.name }}
          </a>
          <li *ngFor='let level3 of level2.children' class='nav-item d-none d-md-none d-lg-block'>
            <a (click)='onContentCategorySelected(level2, level3)'>
              {{ level3.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf='canShowMyRewards || canShowCompetition' class='col'>
      <a *ngIf='canShowMyRewards' class='nav-link text-uppercase pt-0 px-0' (click)='onMyRewards($event)'>
        My Rewards
      </a>
      <li *ngIf='canShowMyRewards' class='nav-item'>
        <a class='nav-item d-none d-md-none d-lg-block' (click)='onMyRewards($event)'>
          Member benefits
        </a>
      </li>
      <li *ngFor='let item of competitions' class='nav-item'>
        <a class='nav-item d-none d-md-none d-lg-block' (click)='onCompetitionSelected(item, $event)'>
          {{ item.name }}
        </a>
      </li>
    </div>

    <div *ngIf='canShowPartners' class='col'>
      <ul *ngIf='canShowPartners' class='nav flex-column menu-items mb-0 mb-md-4'>
        <a class='nav-link text-uppercase pt-0' (click)='onAllPartnersSelected($event)'>
          Partners
        </a>
        <li *ngFor='let item of partners' class='nav-item d-none d-md-none d-lg-block'>
          <a (click)='onPartnerSelected(item, $event)'>
            {{ item.partnerName }}
          </a>
        </li>
      </ul>
    </div>

    <div *ngIf='canShowSupportFromCompany' class='col'>
      <ul class='nav flex-column menu-items'>
        <a class='nav-link text-uppercase pt-0' (click)='onAllSupportSelected($event)'>
          Support from {{companyName}}
        </a>
        <li *ngFor='let item of supportFromCompany' class='nav-item d-none d-md-none d-lg-block'>
          <a (click)='onDiscoverMenuItem(item.route, $event)'>
            {{ item.title }}
          </a>
        </li>
        <li *ngIf='canShowViewMoreSupportFromCompany' class='nav-item d-none d-md-none d-lg-block'>
          <a (click)='onSelectSupportFromCompany($event)' class='ml-4'>
            view more...
          </a>
        </li>
      </ul>
    </div>

    <div *ngIf='canShowInfoPacks' class='col'>
      <ul class='nav flex-column menu-items mb-0 mb-md-4'>
        <a class='nav-link text-uppercase pt-0' (click)='onAllInfoPacksSelected($event)'>
          {{companyName}} Information
        </a>
        <li *ngFor='let item of infoPacks' class='nav-item d-none d-md-none d-lg-block'>
          <a (click)='onInfoPackSelected(item, $event)'>
            {{ item.title }}
          </a>
        </li>
      </ul>
    </div>

    <div *ngIf='canShowLocators' class='col'>
      <ul class='nav flex-column menu-items'>
        <a class='nav-link text-uppercase pt-0' (click)='onAllLocatorsSelected($event)'>
          Locator Reports
        </a>
        <li *ngFor='let item of locatorReports' class='nav-item d-none d-md-none d-lg-block'>
          <a (click)='onDiscoverMenuItem(item.route, $event)'>
            {{ item.title }}
          </a>
        </li>
      </ul>
    </div>
  </div>

  </div>
  </div>
  </li>

  <!-- My Health -->
  <li *ngIf='canShowMyHealthMenu' class='nav-item dropdown'>
    <a href='#' class='nav-link dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true'
       (click)="onMainMenuClick($event,'myhealth')" aria-expanded='false'>
      My Health
    </a>
    <div class='dropdown-menu' [ngClass]='{ hide: isMyHealthMenuItemClicked }' aria-labelledby='navbarDropdown'>
      <div class='container'>
        <div class='row'>
          <div [ngClass]="{
                  'offset-md-3': isMyHealthMissing1Column,
                  'offset-md-7': isMyHealthMissing2Columns
                }">
          </div>

          <div *ngIf='canShowMyActivity' class='col-12 col-lg-2'>
            <ul class='nav flex-column menu-items mb-0 mb-md-0'>
              <a class='nav-link text-uppercase pt-0' (click)='onMyActivity()'>
                My Activity
              </a>
              <li *ngFor='let item of myActivities' class='nav-item d-none d-md-none d-lg-block'>
                <a (click)='onMyHealthMenuItem(item.route, $event)'>
                  {{ item.title }}
                </a>
              </li>
            </ul>
          </div>

          <div *ngIf='canShowMyHabits' class='col-12 col-lg-2'>
            <ul class='nav flex-column menu-items mb-0 mb-md-0'>
              <a class='nav-link text-uppercase pt-0' (click)='onMyHabits()'>
                My Habits
              </a>
              <li *ngFor='let item of myHabits' class='nav-item d-none d-md-none d-lg-block'>
                <a (click)='onMyHealthMenuItem(item.route, $event)'>
                  {{ item.title }}
                </a>
              </li>
            </ul>
          </div>

          <div *ngIf='canShowPrograms' class='col-12 col-lg-4'>
            <ul class='nav flex-column menu-items mb-0 mb-md-0'>
              <a class='nav-link text-uppercase pt-0' (click)='onAllProgramsSelected($event)'>
                Programs
              </a>
              <li *ngFor='let item of programs' class='nav-item d-none d-md-none d-lg-block'>
                <a (click)='onProgramSelected(item, $event)'>
                  <i *ngIf='item.completed' class='fa fa-check' aria-hidden='true'></i>
                  <i *ngIf='!item.completed && item.isUserInProgram' class='fa fa-spinner' aria-hidden='true'></i>
                  {{ item.title }}
                </a>
              </li>
            </ul>
          </div>

          <div *ngIf='canShowHealthAssessments' class='col-12 col-lg-4'>
            <ul class='nav flex-column menu-items'>
              <a class='nav-link text-uppercase pt-0' (click)='onAllHealthAssessmentsSelected($event)'>
                Health Assessments
              </a>
              <span *ngIf='healthAssessments.length === 0'>
                No current Health Assessments
              </span>
              <li *ngFor='let item of healthAssessments' class='nav-item d-none d-md-none d-lg-block'>
                <a (click)='onHealthAssessmentSelected(item, $event)'>
                  <i *ngIf='item.isAnswered' class='fa fa-check' aria-hidden='true'></i>
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </div>

          <div *ngIf='canShowBadges' class='col-12 col-lg-3'>
            <ul class='nav flex-column menu-items mb-0 mb-md-0'>
              <a class='nav-link text-uppercase pt-0' (click)='onMyBadges()'>
                My Badges
              </a>
              <li class='nav-item d-none d-md-none d-lg-block'>
                <a (click)='onMyBadges()'>
                  Achieved badges
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </li>

  <!-- Community -->
  <li *ngIf='cnaShowCommunityMenu' class='nav-item dropdown'>
    <a href='#' class='nav-link dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true'
       (click)="onMainMenuClick($event, 'community')" aria-expanded='false'>
      Community
    </a>
    <div class='dropdown-menu' [ngClass]='{ hide: isCommunityMenutItemClicked }' aria-labelledby='navbarDropdown'>
      <div class='container'>
        <div class='row'>
          <div [ngClass]="{
                'offset-md-0': isCommunityMissing1Column,
                'offset-md-0': isCommunityMissing2Columns
              }">
          </div>

          <div *ngIf='canShowGroups' class='col-12 col-lg-3'>
            <ul class='nav flex-column menu-items mb-0 mb-md-0'>
              <a class='nav-link text-uppercase pt-0' (click)='onSelectMyGroups($event)'>
                My Groups
              </a>
              <li *ngIf='canShowCreateNewGroup' class='nav-item d-none d-md-none d-lg-block'>
                <a (click)='onSelectCreateNewGroup($event)'>
                  Create New Group
                </a>
              </li>
              <li *ngFor='let item of myGroups' class='nav-item d-none d-md-none d-lg-block'>
                <a (click)='onOpenGroup(item, $event)'>
                  {{ item.name }}
                </a>
              </li>
              <span *ngIf='myGroups.length === 0'>
                I do not belong to any groups
              </span>
              <li *ngIf='canShowViewMoreMyGroups' class='nav-item d-none d-md-none d-lg-block'>
                <a (click)='onSelectMyGroups($event)' class='ml-4'>
                  view more...
                </a>
              </li>
            </ul>
          </div>

          <div *ngIf='canShowGroups' class='col-12 col-lg-3'>
            <ul class='nav flex-column menu-items mb-0 mb-md-0'>
              <a class='nav-link text-uppercase pt-0' (click)='onSelectOtherGroups($event)'>
                Other Groups
              </a>
              <li *ngFor='let item of otherGroups' class='nav-item d-none d-md-none d-lg-block'>
                <a (click)='onOpenGroup(item, $event)'>
                  {{ item.name }}
                </a>
              </li>
              <li *ngIf='canShowViewMoreOtherGroups' class='nav-item d-none d-md-none d-lg-block'>
                <a (click)='onSelectOtherGroups($event)' class='ml-4'>
                  view more...
                </a>
              </li>
              <span *ngIf='otherGroups.length === 0'>
                No other groups to join
              </span>
            </ul>
          </div>

          <div *ngIf='canShowFriends' class='col-12 col-lg-3'>
            <ul class='nav flex-column menu-items mb-0 mb-md-0'>
              <a class='nav-link text-uppercase pt-0' (click)='onFriendsSelected()'>
                Friends
              </a>
              <li class='nav-item d-none d-md-none d-lg-block'>
                <a (click)='onFriendsSelected()'>
                  Connect with a colleague
                </a>
              </li>
            </ul>
          </div>

          <div *ngIf='canShowChallenges' class='col-12 col-lg-3'>
            <ul class='nav flex-column menu-items mb-0 mb-md-0'>
              <a class='nav-link text-uppercase pt-0' (click)='onChallengesSelected()'>
                Challenges
              </a>
              <li *ngFor='let item of otherChallenges' class='nav-item d-none d-md-none d-lg-block'>
                <a (click)='onOpenChallenge(item, $event)'>
                  {{ item.name }}
                </a>
              </li>
              <li *ngIf='canShowViewMoreOtherChallenges' class='nav-item d-none d-md-none d-lg-block'>
                <a (click)='onChallengesSelected()' class='ml-4'>
                  view more...
                </a>
              </li>
              <span *ngIf='otherChallenges.length === 0'>
                No challenges to join
              </span>
            </ul>
          </div>

          <div *ngIf='canShowTeams' class='col-12 col-lg-3'>
            <ul class='nav flex-column menu-items mb-0 mb-md-0'>
              <a class='nav-link text-uppercase pt-0' (click)='onTeamsSelected()'>
                Teams
              </a>
              <li *ngFor='let item of otherTeams' class='nav-item d-none d-md-none d-lg-block'>
                <a (click)='onOpenTeam(item, $event)'>
                  {{ item.name }}
                </a>
              </li>
              <li *ngIf='canShowViewMoreOtherTeams' class='nav-item d-none d-md-none d-lg-block'>
                <a (click)='onTeamsSelected()' class='ml-4'>
                  view more...
                </a>
              </li>
              <span *ngIf='otherTeams.length === 0'>
                You haven't joined a team yet
              </span>
            </ul>
          </div>

          <div class='col-12 col-lg-3' *ngIf='canShowLeaderBoard'>
            <ul class='nav flex-column menu-items mb-0 mb-md-0'>
              <a class='nav-link text-uppercase pt-0' (click)='onLeaderboard()'>
                Leaderboard
              </a>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </li>

  <li *ngIf='canShowSearch'>
    <app-search-term (searchTermEntered)='onSearchTermEntered($event)'></app-search-term>
  </li>

  </ul>
  </div>

  <div *ngIf='canShowNotifications'>
    <app-notifications-dropdown
      class='d-none d-md-none d-lg-block' [isHoverDropdown]='true'
      (dropdownClosed)='onNotificationsClosed()'
    ></app-notifications-dropdown>

    <app-notifications-dropdown
      class='d-md-block d-lg-none' [isHoverDropdown]='true'
      (dropdownClosed)='onNotificationsClosed()' (click)='onMobileShowNotifications()'
    ></app-notifications-dropdown>

    <!--    <a class='nav-link d-md-block d-lg-none notifications' (click)='onMobileShowNotifications()'>-->
    <!--      <i class='fa fa-bell'></i>-->
    <!--    </a>-->
  </div>

  <app-profile-dropdown *ngIf='photoSrc'
    [ngClass]="{'hide': isHamburgerExpanded}"
    [isHoverDropdown]='true'
    [photoSrc]='photoSrc'
    [levelSrc]='levelSrc'
    [canShowSettings]='canShowSettings'
    [canShowNotifications] = 'true'
    [canShowPreferences] = 'true'
    [canShowTutorials] = 'true'
    [canShowBookmarks] = 'true'
    [canShowCms]='canShowCms'
    [canShowInsights]='canShowInsights'
  >
  </app-profile-dropdown>
</nav>
