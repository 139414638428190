<div class="widget-card">
    <div class="widget-card-header backPrimary">
      HCF, Uncommon Care
    </div>
    <div class="w-100 text-center">
        <div>
          <img src="../../../assets/img/widgets/hcf_widget.jpeg" alt="Show my rewards" class="w-100">
        </div>
        <div class="text-left backPrimary">
          <p class="p-2">We’re Australia’s largest not-for-profit health fund because more than 1.5 million members trust us with their health. Find health cover that’s right for you.</p>
          <div>
            <a class="btn btn-line btn-link" href="https://corporate.hcf.com.au/raywhite" target="_blank">Start your quote today</a> 
          </div>
        </div>
      </div>
  </div>