import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hcf-widget',
  templateUrl: './hcf-widget.component.html',
  styleUrls: ['./hcf-widget.component.scss']
})
export class HcfWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
