import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IValueNamePair } from '../app/_models/value-name-pair';
import { ILifeAction } from '../app/_models/life-action';
import { UtilService } from './util-service';
import { DateService } from './date.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { IIndicatorTracker } from '../app/_models/indicator-tracker';

@Injectable({
  providedIn: 'root',
})
export class LifeActionService {
  RecurrenceDaily = 1;
  RecurrenceTwiceWeekly = 4;
  RecurrenceWeekly = 7;
  RecurrenceFortnightly = 14;
  RecurrenceMonthly = 30;
  RecurrenceQuarterly = 91;
  RecurrenceHalfYearly = 182;
  RecurrenceYearly = 365;

  RecurrenceDailyName = 'Daily';
  RecurrenceTwiceWeeklyName = 'Twice Weekly';
  RecurrenceWeeklyName = 'Weekly';
  RecurrenceFortnightlyName = 'Fortnightly';
  RecurrenceMonthlyName = 'Monthly';
  RecurrenceQuarterlyName = 'Quarterly';
  RecurrenceHalfYearlyName = 'Half-Yearly';
  RecurrenceYearlyName = 'Yearly';

  private allRecurrences: IValueNamePair[] = [
    { value: this.RecurrenceDaily, name: this.RecurrenceDailyName },
    { value: this.RecurrenceTwiceWeekly, name: this.RecurrenceTwiceWeeklyName },
    { value: this.RecurrenceWeekly, name: this.RecurrenceWeeklyName },
    { value: this.RecurrenceFortnightly, name: this.RecurrenceFortnightlyName },
    { value: this.RecurrenceMonthly, name: this.RecurrenceMonthlyName },
    { value: this.RecurrenceQuarterly, name: this.RecurrenceQuarterlyName },
    { value: this.RecurrenceHalfYearly, name: this.RecurrenceHalfYearlyName },
    { value: this.RecurrenceYearly, name: this.RecurrenceYearlyName },
  ];

  CategoryHabit = 'Habit';
  CategoryMindset = 'Mindset';
  CategoryMovement = 'Movement';
  CategoryNutrition = 'Nutrition';

  private allLifeActionCategories: string[] = [
    this.CategoryHabit,
    this.CategoryMindset,
    this.CategoryMovement,
    this.CategoryNutrition,
  ];

  ComplexityLow = 'low';
  ComplexityMedium = 'medium';
  ComplexityHigh = 'high';

  private allComplexityLevels: string[] = [
    this.ComplexityLow,
    this.ComplexityMedium,
    this.ComplexityHigh,
  ];

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private dateService: DateService,
  ) {
  }

  getAllStaticRecurrences() {
    return this.allRecurrences;
  }

  allStaticRecurrences(): IValueNamePair[] {
    return _.cloneDeep(this.allRecurrences);
  }

  allStaticLifeActionCategories(): string[] {
    return [...this.allLifeActionCategories];
  }

  allStaticLifeActionComplexityLevels(): string[] {
    return this.allComplexityLevels.slice();
  }

  recurrenceName(value: number): string {
    const recurrence = this.allRecurrences.find((r) => r.value === value);
    if (!recurrence) {
      return null;
    }
    return recurrence.name;
  }

  getTrackLifeActionsNew() {
    const url = `/indicators/trackers/actions/new/byuserprofileid`;
    return this.http.get<any>(url);
  }

  addTrackLifeAction(indicator: any, trackValue: number, achieved: boolean) {
    const currentDate = new Date();
    const url = `/indicators/trackers/actions/addtrack/new`;
    const body = {
      indicatorId: indicator.id,
      value: trackValue,
      type: 'self',
      date: currentDate,
      achievedThisInterval: achieved,
      category: indicator.category,
    };
    return this.http.post<any>(url, body);
  }

  updateTrackLifeAction(idTrack, trackValue, achieved, xcategory) {
    const url = `/indicators/trackers/actions/updatetrack/new`;
    const body = {
      id: idTrack,
      value: trackValue,
      achievedThisInterval: achieved,
      category: xcategory,
    };
    return this.http.post<any>(url, body);
  }

  deleteTrackLifeAction(indicatorId: number) {
    const url = `/indicators/trackers/actions/${indicatorId}`;
    return this.http.delete<any>(url);
  }

  getToleranceHours(recurrence: number, isProgressive) {
    switch (recurrence) {
      case 1:
        return 0;
      case 4:
        // more than 1 time a week
        if (isProgressive !== null) {
          return 168;
        } else {
          return 24;
        }
      case 7:
        // 2 days
        return 48;
      case 14:
        // 4 days
        if (isProgressive !== null) {
          return 792;
        } else {
          return 96;
        }
      case 30:
        // 6 days
        return 144;
      case 91:
        // 15 days
        return 360;
      case 182:
        return 720;
      case 365:
        return 1440;
    }
  }

  getSuggestedLifeAction() {
    const url = '/indicators/life-actions/suggestion/me';
    return this.http.get<any>(url);
  }

  getAllLifeActions() {
    const lifeActionsFeatureId = 12;
    const url = `/indicators/all/${lifeActionsFeatureId}`;
    return this.http.get<any>(url);
  }

  getLifeActionsReporting(companyId, body) {
    return this.http.post<any>(`/indicators/life-actions/reporting/${companyId}`, body);
  }

  getAllIndicatorsForAllCategories() {
    const url = `/indicators`;

    return this.http.get<any>(url);
  }

  getLifeActionById(id: number) {
    const url = `/indicators/${id}`;

    return this.http.get<any>(url);
  }

  getNewLifeAction(): ILifeAction {
    return {
      id: 0,
      name: null,
      description: null,
      category: null,
      recurrence: this.RecurrenceDaily,
      newUserDefault: false,
      complexity: 'low',
      featureId: 12,
      categoryId: null,
      domainId: null,
      companyId: undefined,
      excludedCompanyIds: [],
    };
  }

  convertRecurrence(lifeAction: any) {
    let txtRecurrence = '';
    switch (lifeAction.recurrence) {
      case 1:
        txtRecurrence = 'Occurs Daily';
        break;
      case 4:
        txtRecurrence = 'Occurs Twice a Week';
        break;
      case 7:
        txtRecurrence = 'Occurs Every Week';
        break;
      case 14:
        txtRecurrence = 'Occurs Every 2 Weeks';
        break;
      case 30:
        txtRecurrence = 'Occurs Every Month';
        break;
      case 91:
        txtRecurrence = 'Occurs Every 3 Months';
        break;
      case 182:
        txtRecurrence = 'Occurs Every 6 Months';
        break;
      case 365:
        txtRecurrence = 'Occurs Every Year';
        break;
    }
    return txtRecurrence;
  }

  setCategoryName(data: any) {
    let categoryLabel = '';
    if (typeof data.category === 'string') {
      categoryLabel = data.category;
      return categoryLabel;
    } else {
      categoryLabel = data.domain.driver.name;
      return categoryLabel;
    }
  }

  updateLifeAction(lifeAction: ILifeAction) {
    const url = `/indicators/${lifeAction.id}`;
    return this.http.put<any>(url, lifeAction);
  }

  addLifeAction(lifeAction: ILifeAction) {
    const url = `/indicators/`;
    return this.http.post<any>(url, lifeAction);
  }

  deleteLifeAction(id: number) {
    const url = `/indicators/${id}`;

    return this.http.delete<any>(url);
  }

  getLifeActionsToday(item, getDone?) {
    if (item.recurrence === this.RecurrenceDaily) {
      return true;
    } else {
      const diffHours = this.dateService.hoursDifference(item.indicatorTrackers[0].date);
      const recurrenceHours = item.recurrence * 24;
      if (diffHours >= recurrenceHours) {
        return true;
      }

      let toleranceHours: number;

      if (
        item.indicatorTrackers.length !== 1 ||
        item.indicatorTrackers[0].achievedThisInterval === true
      ) {
        toleranceHours = this.getToleranceHours(item.recurrence, item.defaultIndicatorRule);
      } else {
        toleranceHours = item.recurrence * 24 - 24;
      }
      const resultCalc = diffHours % recurrenceHours;

      return resultCalc <= toleranceHours;
    }
  }

  checkDueAction(item) {

    if (item.recurrence === this.RecurrenceDaily) {
      if (
        this.dateService.isToday(item.indicatorTrackers[0].date) &&
        item.indicatorTrackers[0].achievedThisInterval === true
      ) {
        return false;
      }
      return true;
    } else {
      const daysDifference = this.dateService.daysDifference(item.indicatorTrackers[0].date, new Date());
      return item.recurrence < daysDifference;

    }
  }

  addZero(value) {
    if (Number.parseInt(value) < 10) {
      return '0' + value.toString();
    } else {
      return value;
    }
  }

  findMatchingTracker(indicator) {
    return indicator.indicatorTrackers.find((tracker) => {
      return this.dateService.isToday(tracker['date']) ? tracker : null;
    });
  }

  isAchieved(indicator, qtt) {
    return !indicator.defaultIndicatorRule ||
      (indicator.defaultIndicatorRule && indicator.defaultIndicatorRule.upperValue === qtt);
  }

  handleUpdateRes(tracker, isAchieved) {
    // if (tracker.badgesToAward && tracker.badgesToAward.length > 0) {
    //   this.utilService.badgeAlert(tracker.badgesToAward);
    // }
    if (isAchieved === true) {
      this.utilService.showToast('success', 'LifeAction marked as done');
    }
  }


  formatRecurrenceIntegersAsStrings(recurrenceValue?) {
    switch (recurrenceValue) {
      case 365:
        return 'annually';
      case 182:
        return 'every 6 months';
      case 91:
        return 'quarterly';
      case 30:
        return 'monthly';
      case 14:
        return 'fortnightly';
      case 7:
        return 'weekly';
      case 4:
        return 'twice weekly';
      case 1:
        return 'daily';
      default:
        return 'daily';
    }
  }

  getDatesByRecurrencePeriod(recurrenceValue?) {
    let dates;
    switch (recurrenceValue) {
      case 365:
        dates = this.dateService.getStartAndEndOfPeriodPairsTo('years');
        break;
      case 182:
        dates = this.dateService.getStartAndEndOfDaysPairsTo(182);
        break;
      case 91:
        dates = this.dateService.getStartAndEndOfPeriodPairsTo('quarter');
        break;
      case 30:
        dates = this.dateService.getStartAndEndOfPeriodPairsTo('months');
        break;
      case 14:
        dates = this.dateService.getStartAndEndOfDaysPairsTo(14);
        break;
      case 7:
        dates = this.dateService.getStartAndEndOfPeriodPairsTo('weeks');
        break;
      case 4:
        dates = this.dateService.getStartAndEndOfDaysPairsTo(4);
        break;
      case 1:
        dates = this.dateService.getStartAndEndOfPeriodPairsTo('days');
        break;
      default:
        dates = this.dateService.getStartAndEndOfPeriodPairsTo('days');
        break;
    }
    return dates;
  }

  getDateByRecurrencePeriod(recurrenceValue?, date = new Date()) {
    let dates;
    switch (recurrenceValue) {
      case 365:
        dates = this.dateService.getStartAndEndOfPeriodPairs(date, 'years');
        break;
      case 182:
        dates = this.dateService.getStartAndEndOfDaysPairs(182, date);
        break;
      case 91:
        dates = this.dateService.getStartAndEndOfPeriodPairs(date, 'quarter');
        break;
      case 30:
        dates = this.dateService.getStartAndEndOfPeriodPairs(date, 'months');
        break;
      case 14:
        dates = this.dateService.getStartAndEndOfDaysPairs(14, date);
        break;
      case 7:
        dates = this.dateService.getStartAndEndOfPeriodPairs(date, 'weeks');
        break;
      case 4:
        dates = this.dateService.getStartAndEndOfDaysPairs(4, date);
        break;
      case 1:
        dates = this.dateService.getStartAndEndOfPeriodPairs(date, 'days');
        break;
      default:
        dates = this.dateService.getStartAndEndOfPeriodPairs(date, 'days');
        break;
    }
    return dates;
  }

  mapUserValuesToHistoryDateRange(trackers, dates) {
    return _.map(dates, (date) => {
      const matchedTracker = _.find(trackers, (tracker: IIndicatorTracker) => {
        return this.dateService.isBetweenTwoDatesInclusive(moment(tracker.date), date.startDate, date.endDate);
      });
      if (matchedTracker) {
        date.tracker = matchedTracker;
        date.achieved = true;
      } else {
        date.tracker = null;
        date.achieved = false;
      }
    });
  }

  logLifeActionProgress(indicatorId) {
    const date = moment().format();
    const url = `/indicators/life-actions/update/me`;
    const item = {
      indicatorId,
      date,
    };
    return this.http.put<any>(url, item);
  }

  activateLifeAction(body) {
    const url = `/indicators/life-actions/activate/me`;
    return this.http.post<any>(url, body);
  }

  activateLifeActionForId(lifeActionId: number) {
    const body = {
      indicatorId: lifeActionId,
      active: true,
    };
    const url = `/indicators/life-actions/activate/me`;

    return this.http.post<any>(url, body);
  }

  deactivateLifeAction(id) {
    const url = `/indicators/life-actions/deactivate/me/${id}`;
    return this.http.put<any>(url, {});
  }

  getAllActiveAndInactiveLifeActions() {
    const url = `/indicators/life-actions/me`;
    return this.http.get<any>(url);
  }
}
