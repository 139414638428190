<div class="widget-card">
  <div class="widget-card-header backPrimary">
    Last badge earned
  </div>
  <div class="w-100 text-center">
    <div *ngIf="isCompanyUsingUserLevels"
      class="px-3">
      <img class="w-100 mt-3 pointer-cursor"
        (click)="onUserLevelClick()"
        placement="bottom"
        ngbTooltip="Find out how to level up!"
        role="button"
        [src]="userLevelSrc"
      >
    </div>
    <div class="w-100 p-2">
      <div>
        <img class="w-20 float-left"
          [src]="badgImageSrc">
      </div>
      <div class="w-75 float-right text-left">
        <b>{{ lastBadgeEarned.name}}</b>
        <p class="badge-description">{{ lastBadgeEarned.description }}</p>
      </div>

      <a class="btn btn-link w-100 widget-card-line fs-80-rem"
        [routerLink]="['/reward/badges']">
        <i class="fa fa-eye" aria-hidden="true"></i>
        View All
      </a>
    </div>
  </div>
</div>
