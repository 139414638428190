<h1>Aged Care Locator Service</h1>
<div class="card px-4 py-3 mb-3">
  <p>Finding an aged care facility that matches your wishes and needs can be time consuming and difficult. Considerations include
    location, proximity to friends and family, costs, availability, provision of services and accessibility.</p>
  <p>With your company's support, SeventeenHundred can assist you finding the right aged care facility for your loved ones -
    we will research and find the best-matched aged care facility. All costs will be covered by your employer.</p>
  <p>Please complete the form below and someone from the SeventeenHundred team will be in touch, either by email or phone to
    confirm your details. Once we have confirmed all of your details, you will receive a detailed report on aged care facilities
    available in your chosen area within 7 days. Whilst we will endeavour to find you suitable aged care in your requested
    timeframe, it is not always possible, as availability of spaces are dependent upon individual providers.</p>
  <p>The report will include the following information: </p>
  <ol>
    <li>Name and contact details of the facility.</li>
    <li>A summary of the facility's services, availability of places and waitlist process.</li>
    <li>Schedule of fees.</li>
    <li>An overview of the facility's philosophy and approach to aged care.</li>
  </ol>
</div>
<div class="mb-4" #progBar>
  <ol class="FormStepList">
    <li class="FormStepList-item">
      <span class="FormStepIcon" [ngClass]="{'is-active': isOnAppicantDetails, 'is-passed': isApplicantDetailsDone}">
        <span *ngIf="isApplicantDetailsDone == false" class="FormStepIcon-circle">1</span>
        <span *ngIf="isApplicantDetailsDone == true" class="FormStepIcon-circle">
          <i class="fa fa-check"></i>
        </span>
        <span class="FormStepIcon-title">Applicant's details</span>
      </span>
    </li>
    <li class="FormStepList-item">
      <span class="FormStepIcon" [ngClass]="{'is-active': isOnCareDetails, 'is-passed': isCareDetailsDone}">
        <span *ngIf="isCareDetailsDone == false" class="FormStepIcon-circle">2</span>
        <span *ngIf="isCareDetailsDone == true" class="FormStepIcon-circle">
          <i class="fa fa-check"></i>
        </span>
        <span class="FormStepIcon-title">Person requiring care</span>
      </span>
    </li>
    <li class="FormStepList-item">
      <span class="FormStepIcon" [ngClass]="{'is-active': isOnService, 'is-passed': isServiceDone}">
        <span *ngIf="isServiceDone == false" class="FormStepIcon-circle">3</span>
        <span *ngIf="isServiceDone == true" class="FormStepIcon-circle">
          <i class="fa fa-check"></i>
        </span>
        <span class="FormStepIcon-title">Service Requirement</span>
      </span>
    </li>
    <li class="FormStepList-item">
      <span class="FormStepIcon FormStepIcon--last" [ngClass]="{'is-active': isOnReview, 'is-passed': isReviewDone}">
        <span *ngIf="isReviewDone == false" class="FormStepIcon-circle">4</span>
        <span *ngIf="isReviewDone == true" class="FormStepIcon-circle">
          <i class="fa fa-check"></i>
        </span>
        <span class="FormStepIcon-title">Review inputs</span>
      </span>
    </li>
  </ol>
</div>
<div>
  <!-- Forms start -->
  <form *ngIf="isOnAppicantDetails" (ngSubmit)="onNext('applicantData', progBar)" #applicantDetailForm="ngForm">
    <h3>Applicant's Details</h3>
    <!--Applicant's Details Start -->
    <div class="class card px-2 py-3 mb-3">
      <div>
        <div class="form-inline">
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-user"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="applicantData.personal_details.firstname" name="firstname" placeholder="First name*"
              required>
          </div>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-user"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="applicantData.personal_details.surname" name="surname" placeholder="Surname*"
              required>
          </div>
        </div>
        <div>
          <div class="form-inline">
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="applicantData.personal_details.address" name="address" placeholder="Address*"
                required>
            </div>
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="applicantData.personal_details.suburb" name="suburb" placeholder="Suburb*"
                required>
            </div>
          </div>
          <div class="form-inline">
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <select class="custom-select form-control" [(ngModel)]="applicantData.personal_details.state" name="state" required>
                <option disabled selected>State*</option>
                <option value="ACT">Australian Capital Territory</option>
                <option value="NSW">New South Wales</option>
                <option value="NT">Northern Territory</option>
                <option value="QLD">Queensland</option>
                <option value="SA">South Australia</option>
                <option value="TAS">Tasmania</option>
                <option value="VIC">Victoria</option>
                <option value="WA">Western Australia</option>
              </select>
            </div>
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <input type="number" class="form-control" [(ngModel)]="applicantData.personal_details.post_code" name="post_code" placeholder="Post code*"
                required>
            </div>
          </div>
        </div>
        <div class="form-inline">
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-phone"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="applicantData.personal_details.home_phone" name="home_phone" placeholder="Phone no (home)*"
              required>
          </div>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-phone"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="applicantData.personal_details.work_phone" name="work_phone" placeholder="Phone no (work)">
          </div>
        </div>
        <div class="form-inline">
          <div class="input-group col-md-6 last-items">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-phone"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="applicantData.personal_details.mobile" name="mobile" placeholder="Mobile no">
          </div>
          <div class="input-group col-md-6 last-items last-item">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-envelope"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="applicantData.personal_details.email" name="email" placeholder="Email address*"
              required>
          </div>
        </div>
      </div>
      <div class="form-horizontal">
        <div class="form-inline mt-3">
          <label class="control-label col-md-6">Are you applying for yourself?</label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select #applySelfInput class="custom-select form-control" (click)="applySelf(applySelfInput.value)" [(ngModel)]="applicantData.apply_self"
              name="apply_self">
              <option disabled selected>Select</option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">If No, who are you filling this application on behalf of?
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select [(ngModel)]="applicantData.apply_person" name="apply_person" class="custom-select form-control">
              <option disabled selected>Select</option>
              <option>Relative</option>
              <option>Friend</option>
              <option>Other</option>
            </select>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">If other, please specify:</label>
          <div class="input-group col-md-6 last-item">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="applicantData.apply_others" name="apply_others" placeholder="Applying for others">
          </div>
        </div>
      </div>
    </div>
    <!--Emergency Contacts Start -->
    <h3>* Emergency Contacts</h3>
    <div class="class card px-2 py-3 mb-3">
      <div class="custom-control custom-checkbox ml-3">
        <input id="isSameCheck" type="checkbox" class="custom-control-input" (click)="sameClick()" [(ngModel)]="isSame" name="isSame">
        <label class="custom-control-label" for="isSameCheck">Tick if same as applicant's details</label>
      </div>
      <p class="ml-3">If other, please specify contact details:</p>
      <div>
        <div class="form-inline">
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-user"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="applicantData.emergency_contacts.firstname" name="firstname" placeholder="First name*"
              required>
          </div>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-user"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="applicantData.emergency_contacts.surname" name="surname" placeholder="Surname*"
              required>
          </div>
        </div>
        <div>
          <div class="form-inline">
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="applicantData.emergency_contacts.address" name="address" placeholder="Address*"
                required>
            </div>
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="applicantData.emergency_contacts.suburb" name="suburb" placeholder="Suburb*"
                required>
            </div>
          </div>
          <div class="form-inline">
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <select class="custom-select form-control" [(ngModel)]="applicantData.emergency_contacts.state" name="state" required>
                <option disabled selected>State*</option>
                <option value="ACT">Australian Capital Territory</option>
                <option value="NSW">New South Wales</option>
                <option value="NT">Northern Territory</option>
                <option value="QLD">Queensland</option>
                <option value="SA">South Australia</option>
                <option value="TAS">Tasmania</option>
                <option value="VIC">Victoria</option>
                <option value="WA">Western Australia</option>
              </select>
            </div>
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="applicantData.emergency_contacts.post_code" name="post_code" placeholder="Post code*"
                required>
            </div>
          </div>
        </div>
        <div class="form-inline">
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-phone"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="applicantData.emergency_contacts.home_phone" name="home_phone" placeholder="Phone no (home)*"
              required>
          </div>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-phone"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="applicantData.emergency_contacts.work_phone" name="work_phone" placeholder="Phone no (work)">
          </div>
        </div>
        <div class="form-inline">
          <div class="input-group col-md-6 last-items">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-phone"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="applicantData.emergency_contacts.mobile" name="mobile" placeholder="Mobile no">
          </div>
          <div class="input-group col-md-6 last-items last-item">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-envelope"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="applicantData.emergency_contacts.email" name="email" placeholder="Email address*"
              required>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3">
      <button type="submit" [disabled]="!applicantDetailForm.valid" class="btn mb-3 col-md-2 offset-md-10 col-6 offset-6">Next</button>
    </div>
  </form>
  <!-- CareDetails start -->
  <form *ngIf="isOnCareDetails" (ngSubmit)="onNext('careData', progBar)" #careDetailForm="ngForm">
    <!--Person Requiring Cares Start -->
    <h3>Person Requiring Care</h3>
    <div class="class card px-2 py-3 mb-3">
      <div>
        <div class="form-inline">
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-user"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="careData.personal_details.firstname" name="firstname" placeholder="First name*"
              required>
          </div>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-user"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="careData.personal_details.surname" name="surname" placeholder="Surname*"
              required>
          </div>
        </div>
        <div>
          <div class="form-inline">
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="careData.personal_details.address" name="address" placeholder="Address*"
                required>
            </div>
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="careData.personal_details.suburb" name="suburb" placeholder="Suburb*"
                required>
            </div>
          </div>
          <div class="form-inline">
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <select class="custom-select form-control" [(ngModel)]="careData.personal_details.state" name="state" required>
                <option disabled selected>State*</option>
                <option value="ACT">Australian Capital Territory</option>
                <option value="NSW">New South Wales</option>
                <option value="NT">Northern Territory</option>
                <option value="QLD">Queensland</option>
                <option value="SA">South Australia</option>
                <option value="TAS">Tasmania</option>
                <option value="VIC">Victoria</option>
                <option value="WA">Western Australia</option>
              </select>
            </div>
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="careData.personal_details.post_code" name="post_code" placeholder="Post code*"
                required>
            </div>
          </div>
        </div>
        <div class="form-inline">
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-phone"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="careData.personal_details.home_phone" name="home_phone" placeholder="Phone no (home)*"
              required>
          </div>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-phone"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="careData.personal_details.work_phone" name="work_phone" placeholder="Phone no (work)">
          </div>
        </div>
        <div class="form-inline">
          <div class="input-group col-md-6 last-items">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-phone"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="careData.personal_details.mobile" name="mobile" placeholder="Mobile no">
          </div>
          <div class="input-group col-md-6 last-items last-item">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-envelope"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="careData.personal_details.email" name="email" placeholder="Email address*"
              required>
          </div>
        </div>
      </div>
      <div class="form-horizontal">
        <p class="mt-3 mb-2 mx-3">
          <strong>Please provide more details for each of the following (each field MUST BE completed, therefore if the question
            does not apply then state NA or NOT APPLICABLE).</strong>
        </p>
        <div class="form-inline">
          <label class="control-label col-md-6 mt-3">Has an Aged care assessment team performed an assessment to determine the level of care required ie High-level
            care or Low-level care?
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select class="custom-select form-control" [(ngModel)]="careData.assessment_status" name="assessment_status" required>
              <option disabled selected>Select</option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
        </div>
        <p class="my-3 mx-3">
          <strong>If an Aged care assessment team has not performed the assessment, please
            <a href="https://www.myagedcare.gov.au/acat-assessments" target="_blank">click here</a> to arrange assessment before being able to enrol in a government funded home</strong>
        </p>
        <div class="form-inline">
          <label class="control-label col-md-6">If an Aged care assessment team has performed the assessment, what was the result of the assessment?
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="careData.assessment_result" name="assessment_result" placeholder="Your notes"
              required>
          </div>
        </div>
        <p class="my-3 mx-3">
          <strong>You may scan a copy of the assessment to us at
              <a [href]="'mailto:' + helpEmail" >{{helpEmail}}</a>, or send by fax to 1300 364 844.</strong>
        </p>
      </div>
      <div class="form-inline">
        <label class="control-label col-md-6">Marital status:
          <span class="required">*</span>
        </label>
        <div class="input-group col-md-6">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fa fa-info-circle"></i>
            </span>
          </div>
          <select class="custom-select form-control" [(ngModel)]="careData.marital_status" name="marital_status" required>
            <option disabled selected>Select</option>
            <option>Married</option>
            <option>Single</option>
            <option>Divorced</option>
            <option>Widowed</option>
            <option>Other</option>
          </select>
        </div>
      </div>
      <div class="form-inline">
        <label class="control-label col-md-6">If other, please specify marital status:
        </label>
        <div class="input-group col-md-6">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fa fa-info-circle"></i>
            </span>
          </div>
          <input type="text" class="form-control" [(ngModel)]="careData.marital_status_other" name="marital_status_other" placeholder="Marital status other">
        </div>
      </div>
      <div class="form-inline">
        <label class="control-label col-md-6">Living status:
          <span class="required">*</span>
        </label>
        <div class="input-group col-md-6 last-item">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fa fa-info-circle"></i>
            </span>
          </div>
          <select class="custom-select form-control" [(ngModel)]="careData.living_status" name="living_status" required>
            <option disabled selected>Select</option>
            <option>Live alone</option>
            <option>With someone</option>
          </select>
        </div>
      </div>
    </div>
    <!--Current Care Status Start -->
    <h3>Current Care Status</h3>
    <div class="class card px-2 py-3 mb-3">
      <div class="form-horizontal">
        <p class="mx-3">Is assistance required with any of the following?</p>
        <p class="my-3 mx-3">
          <strong>Please provide more details for each of the following questions, if it does not apply then state N/A or Not applicable</strong>
        </p>
        <div class="form-inline">
          <label class="control-label col-md-6">House care (Inc. cooking, cleaning):
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select class="custom-select form-control" [(ngModel)]="careData.house_care" name="house_care" required>
              <option disabled selected>Select</option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">Health:
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select class="custom-select form-control" [(ngModel)]="careData.health" name="health" required>
              <option disabled selected>Select</option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">Any special needs? Please specify:
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <textarea class="form-control" [(ngModel)]="careData.special_needs" name="special_needs" placeholder="Special Needs" required></textarea>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">Any dietary requirements? Please specify:
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <textarea class="form-control" [(ngModel)]="careData.dietary_requirements" name="dietary_requirements" placeholder="Dietary requirements"
              required></textarea>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">Main languages spoken:
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="careData.languages" name="languages" placeholder="Languages" required>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">Personal/hygiene care:
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select class="custom-select form-control" [(ngModel)]="careData.hygiene" name="hygiene" required>
              <option disabled selected>Select</option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">Mobility:
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select class="custom-select form-control" [(ngModel)]="careData.mobility" name="mobility" required>
              <option disabled selected>Select</option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">Any cultural/religious needs? Please specify:
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <textarea class="form-control" [(ngModel)]="careData.cultural_needs" name="cultural_needs" placeholder="Cultural/religious needs"
              required></textarea>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">Any allergies? Please specify:
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6 last-item">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <textarea class="form-control" [(ngModel)]="careData.allergies" name="allergies" placeholder="Allergies" required></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- Cards and Records start -->
    <h3>Does the person requiring care have any of the following?</h3>
    <div class="class card px-2 py-3 mb-3">
      <div class="form-horizontal">
        <div class="form-inline">
          <label class="control-label col-md-6">Medicare card:
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select class="custom-select form-control" [(ngModel)]="careData.medicare" name="medicare" required>
              <option disabled selected>Select</option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">Current ACAT report:
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select class="custom-select form-control" [(ngModel)]="careData.acat" name="acat" required>
              <option disabled selected>Select</option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">Current pension card:
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select class="custom-select form-control" [(ngModel)]="careData.pension" name="pension" required>
              <option disabled selected>Select</option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">Current aged care client record(ACCR):
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6 last-item">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select class="custom-select form-control" [(ngModel)]="careData.accr" name="accr" required>
              <option disabled selected>Select</option>
              <option>Yes</option>
              <option>No</option>
            </select>
            <option *ngFor="let state of states" [ngValue]="careData.personal_details.code">{{state.state}}
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3">
      <button type="submit" (click)="onReturn('careData', progBar)" [disabled]="!careDetailForm.valid" class="btn btn-outline-primary mb-3 col-md-2 col-5">Back</button>
      <button type="submit" [disabled]="!careDetailForm.valid" class="btn mb-3 col-md-2 offset-md-8 col-5 offset-2">Next</button>
    </div>
  </form>
  <!-- serviceData start -->
  <form *ngIf="isOnService" (ngSubmit)="onNext('serviceData', progBar)" #serviceDetailForm="ngForm">
    <!--Facility Preferences Start -->
    <h3>Facility Preferences</h3>
    <div class="class card px-2 py-3 mb-3">
      <div class="form-horizontal">
        <div class="form-inline">
          <label class="control-label col-md-6">What facility do they require?
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select class="custom-select form-control" [(ngModel)]="serviceData.facility" name="facility" required>
              <option disabled selected>Select</option>
              <option>Extra-service</option>
              <option>Short-term (respite) care</option>
              <option>After-hospital care</option>
              <option>Ageing in place</option>
              <option>Focus on people in or from rural or regional areas</option>
              <option>Specific focus on people with a terminal illness</option>
              <option>Particular health needs</option>
              <option>Other</option>
            </select>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">If other, please specify:
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6 last-item">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="serviceData.facility_other" name="facililty_other" placeholder="Facility other"
              required>
          </div>
        </div>
      </div>
    </div>
    <!--Options Start -->
    <h3>Options</h3>
    <div class="class card px-2 py-3 mb-3">
      <div class="form-horizontal">
        <div class="form-inline">
          <label class="control-label col-md-6">Single room:
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select class="custom-select form-control" [(ngModel)]="serviceData.single_room" name="single_room" required>
              <option disabled selected>Select</option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">Bathroom:
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select class="custom-select form-control" [(ngModel)]="serviceData.bathroom" name="bathroom" required>
              <option disabled selected>Select</option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">Outdoor access:
            <span class="required">*</span>
          </label>
          <div class="input-group col-md-6 last-item">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select class="custom-select form-control" [(ngModel)]="serviceData.outdoor" name="outdoor" required>
              <option disabled selected>Select</option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <!--Additional Information Start -->
    <h3>Additional Information</h3>
    <div class="class card px-2 py-3 mb-3">
      <div class="form-horizontal">
        <div class="form-inline">
          <label class="control-label col-md-6">Please add any other information you feel would assist us in finding the most appropriate facility.
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <textarea class="form-control" [(ngModel)]="serviceData.additional_information" name="additional_information" placeholder="Additional information"></textarea>
          </div>
        </div>
      </div>
      <p class="my-3 mx-3">
        <strong>SeventeenHundred when providing assistance with locating aged care follow the terms and conditions below -</strong>
      </p>
      <ol class="mb-0 mx-3">
        <li>SeventeenHundred has no agreements with any aged care providers and at all times acts as an objective third party.</li>
        <li>The final report will include a minimum of three (3) aged care providers in your chosen areas.</li>
        <li>The final report will be provided within 7 days of receipt of all relevant information.</li>
        <li>It will be your responsibility to waitlist the person requiring care if necessary. SeventeenHundred cannot place
          any family members on an individual waiting list as part of the service.</li>
        <li>SeventeenHundred takes no responsibility if circumstances or ownership changes after the report has been provided
          and before you may enrol your family member.</li>
      </ol>
      <!-- <div class="form-check mt-3 mx-3">
        <input type="checkbox" class="form-check-input" [(ngModel)]="serviceData.terms_and_conditions" name="terms_and_conditions"
          required>
        <label class="form-check-label">
          Please click to accept the terms and conditions
          <span class="required">*</span>
        </label>
      </div> -->
      <div class="custom-control custom-checkbox mt-3 mb-2 mx-3">
        <input type="checkbox" id="checkbox" class="custom-control-input" [(ngModel)]="serviceData.terms_and_conditions" />
        <label class="custom-control-label" for="checkbox">Please click to accept the terms and conditions</label>
        <span class="text-danger">*</span>
      </div>
    </div>
    <div class="row px-3">
      <button type="submit" (click)="onReturn('serviceData', progBar)" [disabled]="!serviceDetailForm.valid" class="btn btn-outline-primary mb-3 col-md-2 col-5">Back</button>
      <button type="submit" [disabled]="!serviceDetailForm.valid" class="btn mb-3 col-md-2 offset-md-8 col-5 offset-2">Next</button>
    </div>
  </form>
  <!-- Review Start -->
  <div *ngIf="isOnReview">
    <h3>Applicant's Details</h3>
    <div class="card px-4 py-3 mb-3">
      <p class="row">
        <span class="col md-2 text-right">First name:</span>
        <span class="col md-4 text-left">{{applicantData.personal_details.firstname}}</span>
        <span class="col md-2 text-right">Surname:</span>
        <span class="col md-4 text-left">{{applicantData.personal_details.surname}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">Address:</span>
        <span class="col md-4 text-left">{{applicantData.personal_details.address}}</span>
        <span class="col md-2 text-right">Suburb:</span>
        <span class="col md-4 text-left">{{applicantData.personal_details.suburb}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">State:</span>
        <span class="col md-4 text-left">{{applicantData.personal_details.state}}</span>
        <span class="col md-2 text-right">Postcode:</span>
        <span class="col md-4 text-left">{{applicantData.personal_details.post_code}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">Phone No (Home):</span>
        <span class="col md-4 text-left">{{applicantData.personal_details.home_phone}}</span>
        <span class="col md-2 text-right">Phone No (Work):</span>
        <span class="col md-4 text-left">{{applicantData.personal_details.work_phone}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">Mobile No:</span>
        <span class="col md-4 text-left">{{applicantData.personal_details.mobile}}</span>
        <span class="col md-2 text-right">E-mail Address:</span>
        <span class="col md-4 text-left">{{applicantData.personal_details.email}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">Are you applying for yourself?</span>
        <span class="col md-4 text-left">{{applicantData.apply_self}}</span>
        <span class="col md-2 text-right">If No, who are you filling this application on behalf of?</span>
        <span class="col md-4 text-left">{{applicantData.apply_person}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right offset-md-6">If other, please specify:</span>
        <span class="col md-4 text-left">{{applicantData.apply_others}}</span>
      </p>
    </div>
    <h3>Emergency Contacts</h3>
    <div class="card px-4 py-3 mb-3">
      <p class="row">
        <span class="col md-2 text-right">First name:</span>
        <span class="col md-4 text-left">{{applicantData.emergency_contacts.firstname}}</span>
        <span class="col md-2 text-right">Surname:</span>
        <span class="col md-4 text-left">{{applicantData.emergency_contacts.surname}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">Address:</span>
        <span class="col md-4 text-left">{{applicantData.emergency_contacts.address}}</span>
        <span class="col md-2 text-right">Suburb:</span>
        <span class="col md-4 text-left">{{applicantData.emergency_contacts.suburb}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">State:</span>
        <span class="col md-4 text-left">{{applicantData.emergency_contacts.state}}</span>
        <span class="col md-2 text-right">Postcode:</span>
        <span class="col md-4 text-left">{{applicantData.emergency_contacts.post_code}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">Phone No (Home):</span>
        <span class="col md-4 text-left">{{applicantData.emergency_contacts.home_phone}}</span>
        <span class="col md-2 text-right">Phone No (Work):</span>
        <span class="col md-4 text-left">{{applicantData.emergency_contacts.work_phone}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">Mobile No:</span>
        <span class="col md-4 text-left">{{applicantData.emergency_contacts.mobile}}</span>
        <span class="col md-2 text-right">E-mail Address:</span>
        <span class="col md-4 text-left">{{applicantData.emergency_contacts.email}}</span>
      </p>
    </div>
    <h3>Person Requiring Care</h3>
    <div class="card px-4 py-3 mb-3">
      <p class="row">
        <span class="col md-2 text-right">First name:</span>
        <span class="col md-4 text-left">{{careData.personal_details.firstname}}</span>
        <span class="col md-2 text-right">Surname:</span>
        <span class="col md-4 text-left">{{careData.personal_details.surname}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">Address:</span>
        <span class="col md-4 text-left">{{careData.personal_details.address}}</span>
        <span class="col md-2 text-right">Suburb:</span>
        <span class="col md-4 text-left">{{careData.personal_details.suburb}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">State:</span>
        <span class="col md-4 text-left">{{careData.personal_details.state}}</span>
        <span class="col md-2 text-right">Postcode:</span>
        <span class="col md-4 text-left">{{careData.personal_details.post_code}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">Phone No (Home):</span>
        <span class="col md-4 text-left">{{careData.personal_details.home_phone}}</span>
        <span class="col md-2 text-right">Phone No (Work):</span>
        <span class="col md-4 text-left">{{careData.personal_details.work_phone}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">Mobile No:</span>
        <span class="col md-4 text-left">{{careData.personal_details.mobile}}</span>
        <span class="col md-2 text-right">E-mail Address:</span>
        <span class="col md-4 text-left">{{careData.personal_details.email}}</span>
      </p>
      <hr>
      <p class="row">
        <span class="col-md-9 text-right">Has an Aged care assessment team performed an assessment to determine the level of care required ie High-level care
          or Low-level care?</span>
        <span class="col-md-3 text-left">{{careData.assessment_status}}</span>
      </p>
      <p class="row">
        <span class="col-md-9 text-right">If an Aged Care assessment Team has performed the assessment, what was the result of the assessment?</span>
        <span class="col-md-3 text-left">{{careData.assessment_result}}</span>
      </p>
      <hr>
      <p class="row">
        <span class="col md-2 text-right">Marital Status:</span>
        <span class="col md-4 text-left">{{careData.marital_status}}</span>
        <span class="col md-2 text-right">If other, please specify marital status:</span>
        <span class="col md-4 text-left">{{careData.marital_status_other}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">Living Status:</span>
        <span class="col md-4 text-left">{{careData.living_status}}</span>
        <span class="col md-2 text-right"></span>
        <span class="col md-4 text-left"></span>
      </p>
    </div>

    <h3>Current Care Status</h3>
    <div class="card px-4 py-3 mb-3">
      <p class="row">Do they require assistance with any of the followings:</p>
      <p class="row">
        <span class="col md-2 text-right">House Care:</span>
        <span class="col md-4 text-left">{{careData.house_care}}</span>
        <span class="col md-2 text-right">Personal/hygiene care:</span>
        <span class="col md-4 text-left">{{careData.hygiene}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">Health:</span>
        <span class="col md-4 text-left">{{careData.health}}</span>
        <span class="col md-2 text-right">Mobility:</span>
        <span class="col md-4 text-left">{{careData.mobility}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">Do they have any special needs? Please specify:</span>
        <span class="col md-4 text-left">{{careData.special_needs}}</span>
        <span class="col md-2 text-right">Do they have any cultural/religious needs? Please specify:</span>
        <span class="col md-4 text-left">{{careData.cultural_needs}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">Do they have any dietary requirement? Please specify:</span>
        <span class="col md-4 text-left">{{careData.dietary_requirements}}</span>
        <span class="col md-2 text-right">Any allergies? Please specify:</span>
        <span class="col md-4 text-left">{{careData.allergies}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">Main languages spoken:</span>
        <span class="col md-4 text-left">{{careData.languages}}</span>
        <span class="col md-2 text-right"></span>
        <span class="col md-4 text-left"></span>
      </p>
    </div>

    <h3>Do they have any of the followings?</h3>
    <div class="card px-4 py-3 mb-3">
      <p class="row">
        <span class="col md-2 text-right">Medicare card:</span>
        <span class="col md-4 text-left">{{careData.medicare}}</span>
        <span class="col md-2 text-right">Current pension card:</span>
        <span class="col md-4 text-left">{{careData.pension}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">Current ACAT report:</span>
        <span class="col md-4 text-left">{{careData.acat}}</span>
        <span class="col md-2 text-right">Current aged care client record (ACCR):</span>
        <span class="col md-4 text-left">{{careData.accr}}</span>
      </p>
    </div>

    <h3>Facility Preferences</h3>
    <div class="card px-4 py-3 mb-3">
      <p class="row">
        <span class="col md-2 text-right">What facility do they require?</span>
        <span class="col md-4 text-left">{{serviceData.facility}}</span>
        <span class="col md-2 text-right">If other, please specify:</span>
        <span class="col md-4 text-left">{{serviceData.facility_other}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">Single room:</span>
        <span class="col md-4 text-left">{{serviceData.single_room}}</span>
        <span class="col md-2 text-right">Bathroom:</span>
        <span class="col md-4 text-left">{{serviceData.bathroom}}</span>
      </p>
      <p class="row">
        <span class="col md-2 text-right">Outdoor access:</span>
        <span class="col md-4 text-left">{{serviceData.outdoor}}</span>
        <span class="col md-2 text-right">Other information:</span>
        <span class="col md-4 text-left">{{serviceData.additional_information}}</span>
      </p>
    </div>
    <h3>Aged Care Locator - Confirmation</h3>
    <div class="card px-4 py-3 mb-3">
      <p>Thank you for your submission to allow SeventeenHundred to assist you in locating an aged care facility for your loved
        one. We will commence work on finding appropriate care for your loved one, as soon as we have confirmed we have all
        the relevant information. If we have any additional questions we will give you a call, otherwise we will commence
        work on the report and you can expect to receive a copy within 7 business days. Should you have any questions before
        this time, please do not hesitate to contact our office via email at:
        <a [href]="'mailto:' + helpEmail" >{{helpEmail}}</a>
      </p>
      <p>Kind regards,</p>
      <p>SeventeenHundred</p>
      <p>
        <strong>
          <em>Discalimer: SeventeenHundred cannot guarantee availability at aged care facilities.</em>
        </strong>
      </p>

    </div>
    <div class="row px-3">
      <button class="btn btn-outline-primary col-md-2 col-5 mb-3" (click)="onReturn('review', progBar)">Fix Errors</button>

      <button type="submit" class="btn btn-primary col-md-2 col-5 mb-3 offset-md-8 offset-2" (click)="onSubmit()">Submit</button>
    </div>
  </div>
</div>
<!-- Forms End -->
<div class="mt-3">
  <p>To read our Privacy Policy
    <a class="hyperlink" (click)="openPolicy('privacy')">click here</a>
  </p>
  <p>To read our Privacy Collection statement
    <a class="hyperlink" (click)="openPolicy('collection')">click here</a>
  </p>
</div>
