import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthRoutePath, ILogin, IRegister } from '../app/_models/auth';
import { UserService } from './user-service';
import { UtilService } from './util-service';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import jwtDecode from 'jwt-decode';
import { delay, map, tap } from 'rxjs/operators';
import { DomainCheckerService } from './domain-checker.service';
import { ConnectionService } from './connection-service';
import { InsightsService } from './insights-service';
import { Router } from '@angular/router';

const DAY_TO_MILLISECONDS = 86400000;

export interface ILoginResponse {
  success?: boolean;
  isAccountNotVerifiedError?: boolean;
  isNewSubscriptionRequired?: boolean;
  isSubscriptionReactivationRequired?: boolean;
  isUnsuccessfulThirdPartyPaymentSubscription?: boolean;
  isPasswordStrengthError?: boolean;
  data?: {
    mobileBillingPartner: string;
  };
  err?: any;
  user?: any;
  token?: string;
  refreshToken?: string;
}

export interface ITokenResponse {
  err: string;
  success: boolean;
  access_token: string;
  refresh_token: string;
  scope: string;
  expires_in: number;
  token_type: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  mobile_billing_partner: any;
  phone: any;
  public msisdn = new BehaviorSubject<string>('');
  public mobileBillingPartner = new BehaviorSubject<any>({});
  mobileBillingPartner$ = this.mobileBillingPartner.asObservable();
  emailPattern = /(?:[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9]{1,2})\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9]{1,2}|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)]/;
  private passwordPattern = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})',
  );

  constructor(
    private router: Router,
    private http: HttpClient,
    private userService: UserService,
    private utilService: UtilService,
    private domainChecker: DomainCheckerService,
    private connectionService: ConnectionService,
    private insightsService: InsightsService,
  ) {
  }

  setJwt(token) {
    if (token) {
      window.localStorage.setItem('TOKEN', token);
    }
  }

  getAuthToken() {
    return localStorage.getItem('TOKEN');
  }

  register(data: IRegister) {
    const url = `/auth/register`;
    return this.http.post<any>(url, data);
  }

  login(data: ILogin) {
    const url = `/auth/login`;
    return this.http.post<ILoginResponse>(url, data);
  }

  authorize(body) {
    const url = `/auth/authorize`;
    return this.http.post<ILoginResponse>(url, body);
  }

  handleLoginProcess() {
    if (this.userService.isLoggedIn()) {
      this.navigateToBasePage();
      return;
    }
    if (this.connectionService.connectionByHostname) {
      this.connectionService.handleSSOLogin();
    } else {
      this.router.navigateByUrl(AuthRoutePath.login);
    }
  }

  handleSignUpProcess() {
    if (this.userService.isLoggedIn()) {
      this.navigateToBasePage();
      return;
    }
    if (this.connectionService.connectionByHostname) {
      this.connectionService.handleSSOLogin();
    } else {
      this.router.navigateByUrl(AuthRoutePath.register);
    }
  }

  refreshTokenBeforeExpired() {
    const token = this.userService.token;
    if (!token) {
      return;
    }
    const jwtToken = this.userService.token.split(' ')[1];
    const { exp } = jwtDecode(jwtToken);
    if (exp) {
      const remainingTime = exp * 1000 - Date.now();
      if (remainingTime > 0 && remainingTime <= DAY_TO_MILLISECONDS) {
        this.refreshToken(this.userService.refreshToken).subscribe((res) => {
          if (res.refresh_token) {
            this.refreshTokenBeforeExpired();
          }
        });
      } else {
        const timeout = remainingTime - DAY_TO_MILLISECONDS;
        of(true)
          .pipe(
            delay(timeout),
            tap(() => {
              this.refreshToken(this.userService.refreshToken).subscribe((res) => {
                if (res.refresh_token) {
                  this.refreshTokenBeforeExpired();
                }
              });
            }),
          )
          .subscribe();
      }
    }
  }

  refreshToken(refreshToken: string) {
    const url = '/auth/refresh-token';
    return this.http.post<Partial<ITokenResponse>>(url, { refreshToken }).pipe(
      map((res) => {
        if (res.access_token) {
          this.userService.token = `Bearer ${res.access_token}`;
          this.userService.refreshToken = res.refresh_token;
        } else {
          this.userService.refreshToken = null;
        }
        return res;
      }),
    );
  }

  logout() {
    if (this.userService.hasInsights) {
      // sso logout redirect
      this.insightsService.handleSSOLogout();
    } else {
      window.localStorage.clear();
      window.location.href = window.location.origin;
    }
  }

  forgetPassword(data: any) {
    const url = `/auth/forget-password`;
    return this.http.post<any>(url, data);
  }

  generateChangePasswordToken(email) {
    const url = `/auth/generate-change-password`;
    return this.http.post<any>(url, { email: email });
  }

  changePassword(data: any) {
    const url = `/auth/change-password`;
    return this.http.post<any>(url, data);
  }

  getCompany(companyCode) {
    const url = `/companies/companyCode/verifyCode`;
    const body = { type: 'keyword', value: companyCode };

    return this.http.post<any>(url, body);
  }

  silentLogin(data: any) {
    const url = `/auth/silent-login`;
    return this.http.post<any>(url, data);
  }

  sendVerifyEmail(email) {
    const body = { email: email };
    const url = `/auth/resend-verify`;
    return this.http.post<any>(url, body);
  }

  getIsUserCurrentlySubscribed(): Observable<any> {
    const url = '/auth/subscription-current/me';

    return this.http.get<any>(url);
  }

  setMobileBillingPartner(mobileBillingPartner) {
    this.mobileBillingPartner.next(mobileBillingPartner);
    this.mobileBillingPartner.subscribe((data) => {
      this.mobile_billing_partner = data;
    });
    return this.mobile_billing_partner;
  }

  setMsisdn(msisdn) {
    this.msisdn.next(msisdn);
    this.msisdn.subscribe((data) => {
      this.phone = data;
    });
    return this.phone;
  }

  // getGoogleAuthorization(companyId?) {
  //   const { authUri, clientId, redirectUri } = this.userService.envVariables.oauth.google;
  //   const scopes = 'email+profile';
  //   const stateObject = {
  //     companyId,
  //     token: this.userService.token,
  //     platform: 'web',
  //   };
  //   const state = this.utilService.convertObjectToUriEncodedComponent(stateObject);
  //   const url = `${authUri}?redirect_uri=${redirectUri}&response_type=code&access_type=offline&client_id=${clientId}&scope=${scopes}&state=${state}`;
  //
  //   window.location.href = url;
  // }

  secureLoginWithBearerToken() {
    const url = `/auth/secure-login`;
    return this.http.get(url);
  }

  setInputOutlineClass(ctrl: AbstractControl) {
    if (!ctrl.errors && ctrl.touched) {
      return 'form-input-valid';
    } else if (ctrl.errors && ctrl.touched) {
      return 'form-input-invalid';
    } else {
      return;
    }
  }

  passwordMatchesValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');
    return password && confirmPassword && password.value !== confirmPassword.value
      ? { passwordsDoNotMatch: true }
      : null;
  };

  passwordPatternValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
    const password = control.get('password');
    return !this.passwordPattern.test(password.value) ? { passwordNotMatchedPattern: true } : null;
  };

  closeAccount(body) {
    const url = `/auth/close-account`;
    return this.http.put<ILoginResponse>(url, body);
  }

  navigateToBasePage() {
    if (!this.insightsService.isInsightsDomain()) {
      this.router.navigate(['/home']);
    }

    if (this.userService.hasInsightsAccess()) {
      this.router.navigate(['/insights']);
    }

    this.router.navigate(['']);
  }
}
