import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertComponent implements OnInit {

  public title;
  public message;
  public type; // 1- alert 2 - notifications
  public showCloseIcon = true;
  public textButton = 'Close';
  iconCss = 'text-danger';

  constructor(public activeModal: NgbActiveModal, public domSanitizer: DomSanitizer) {
  }

  ngOnInit() {
    if (this.type === 2) {
      this.iconCss = 'text-info';
    }
  }

}
