import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IStripePlan } from '../app/_models/stripe-plan';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  private stripePlans = new BehaviorSubject<any[]>([]);
  stripePlans$ = this.stripePlans.asObservable();

  private selectedStripePlan = new BehaviorSubject<any>({});
  selectedStripePlan$ = this.selectedStripePlan.asObservable();

  private customerStripeToken = new BehaviorSubject<any>({});
  customerStripeToken$ = this.customerStripeToken.asObservable();

  constructor(private http: HttpClient) { }

  setCompanyStripePlans(companyStripePlans: IStripePlan[]) {
    this.stripePlans.next(companyStripePlans);
  }

  clearCompanyStripePlans() {
    this.stripePlans.next([]);
  }

  getCompanyStripePlans(): Observable<any> {
    return this.stripePlans$;
  }

  setSelectedStripePlan(stripePlan: IStripePlan) {
    this.selectedStripePlan.next(stripePlan);
  }

  getSelectedStripePlan(): Observable<any> {
    return this.selectedStripePlan$;
  }

  setCustomerStripeToken(token: any) {
    this.customerStripeToken.next(token);
  }

  getCustomerStripeToken(): Observable<any> {
    return this.customerStripeToken$;
  }

  getStripePlanById(planId) {
    const url = `/stripe/plans/${planId}`;

    return this.http.get<any>(url);
  }

  deleteStripeSubscription(customerId) {
    const url = `/stripe/subscription/${customerId}`;

    return this.http.delete<any>(url);
  }

  updateCardDetails(token, stripeId) {
    const url = `/stripe/card-details`;
    const body = { 'token': token, 'customerId': stripeId };

    return this.http.put<any>(url, body);
  }

  getCustomerPaymentHistory(customerId) {
    const url = `/stripe/payment-history/${customerId}`;

    return this.http.get<any>(url);
  }

  addStripeSubscribeToUser(body) {
    const url = `/stripe/subscription`;

    return this.http.put<any>(url, body);
  }

  checkStripeStatus(customerId) {
    const url = `/stripe/status/${customerId}`;

    return this.http.get<any>(url);
  }

  getStripePlans(): Observable<IStripePlan[]> {
    const url = '/stripe/plans';

    return this.http.get<any>(url);
  }

  getAllCompanyStripePlans(companyId): Observable<IStripePlan[]> {
    const url = `/stripe/company-plans/${companyId}`;

    return this.http.get<any>(url);
  }

  getUserEmailCompanyStripePlans(email): Observable<IStripePlan[]> | any {
    const url = `/stripe/company-plans/user-email/${email}`;

    return this.http.get<any>(url);
  }

  getIsNewStripePlanSubscriptionRequired(userId): Observable<any> {
    const url = `/stripe/subscription-required/${userId}`;

    return this.http.get<any>(url);
  }

  openStripe(key: string, selectedPlan: IStripePlan, email?: string) {
    const stripeHandler = (<any>window).StripeCheckout.configure({
      key,
      locale: 'auto',
      token: token => {
        this.setCustomerStripeToken(token);
        return;
      }
    });

    stripeHandler.open({
      name: selectedPlan.name,
      description: selectedPlan.description,
      email,
      allowRememberMe: false,
      amount: selectedPlan.amount,
      currency: selectedPlan.currency
    });

  }
}

