import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../../services/user-service';
import { DeviceService } from '../../services/device-service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-datasource',
  templateUrl: './datasource.component.html',
  styleUrls: ['./datasource.component.scss']
})
export class DatasourceComponent implements OnInit {
  @Input() item;
  @Input() parentPage;
  radios = [];
  message: string;
  buttons = [];
  form;
  constructor(
    private userService: UserService,
    private deviceService: DeviceService,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private router: Router
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      datasource: ['']
    });
    const newRadios = [];
    const newButtons = [];
    if (this.item.id === 6) {
      newRadios.push({ label: 'Self-input', value: 'self' });
      newButtons.push({});
      this.radios = newRadios;
      this.buttons = newButtons;
      this.message =
        'No devices available for waist circumference tracking';
    } else {
      newRadios.push({ label: 'Self-input', value: 'self' });
      if(this.parentPage == 'team-details'){
        newButtons.push({
          icon: '',
          label: 'Update manually',
          type: 'self'
        });
      }else{
        newButtons.push({});
      }
      if (this.hasFeature(14)) {
        newRadios.push({ label: 'Fitbit', value: 'fitbit' });
        if (!this.userService.hasFitbit()) {
          newButtons.push({
            icon: '../../assets/img/devices-and-apps/fitbit-logo.svg',
            label: 'Connect',
            type: 'fitbit'
          });
          newRadios.map(radio => {
            if (radio.label === 'Fitbit') {
              radio.disabled = true;
            }
          });
        } else {
          if(this.parentPage == 'team-details'){
            newButtons.push({
              icon: '',
              label: 'Update manually',
              type: 'self'
            });
          }else{
            newButtons.push({});
          }
        }
      }
      if (this.hasFeature(14) && (this.item.id === 1 || this.item.id === 3)) {
        newRadios.push({
          label: 'Google Fit',
          value: 'googlefit'
        });
        if (!this.userService.hasGoogleFit()) {
          newRadios.map(radio => {
            if (radio.label === 'Google Fit') {
              radio.disabled = true;
            }
          });
          newButtons.push({
            icon: '../../assets/img/devices-and-apps/googlefit-logo.png',
            label: 'Connect',
            type: 'google'
          });
        } else {
          if(this.parentPage == 'team-details'){
            newButtons.push({
              icon: '',
              label: 'Update manually',
              type: 'self'
            });
          }else{
            newButtons.push({});
          }
        }
      }
      const dataSourceType = _.get(this.item, 'dataSource.type', null) || _.get(this.item, 'indicator.dataSource.type', null);
      newRadios.map(radio => {
        if (radio.value === dataSourceType) {
          radio.checked = 'checked';
          this.form.controls.datasource.setValue(radio.value);
        }
      });
      this.radios = newRadios;
      this.buttons = newButtons;

      if (this.radios.length === 1) {
        this.message = 'No other datasources currently available';
      }
    }
  }

  connectToDevice(device) {
    if (device === 'fitbit') {
      this.deviceService.getFitbitAuthorization(
        '/act/your-health',
        this.item.id
      );
    } else if (device === 'google') {
      this.deviceService.getGoogleFitAuthorization(
        '/act/your-health',
        this.item.id
      );
    }else if (device === 'self') {
      this.activeModal.dismiss();
      this.router.navigate([`act/your-health`]);
    }
  }

  hasFeature(num) {
    if (this.userService.company) {
      return this.userService.showFeature(num, this.userService);
    } else {
      return true;
    }
  }

  save() {
    this.activeModal.close(this.form.value);
  }

  close() {
    this.activeModal.dismiss();
  }


}
