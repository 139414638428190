import { Injectable } from '@angular/core';
import { UserService } from './user-service';
import { environment } from '../environments/_active-environment/environment';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { IIdNamePair } from '../app/_models/id-name-pair';

@Injectable({
  providedIn: 'root',
})
export class InsightsService {
  private insightsVersions: IIdNamePair[] = [
    { id: 1, name: 'Large Organization (Monthly)' },
    { id: 2, name: 'Medium Organization (Quarterly)' },
  ];

  constructor(
    public http: HttpClient,
    private userService: UserService,
  ) {

  }

  isInsightsDomain() {
    return window.location.href.indexOf(this.userService.envVariables.app.insightsDomain) !== -1;
  }

  handleSSOLogin(token, refreshToken) {
    this.getCrossDomainUrl().subscribe(redirectUrl => {
      window.location.href = `${redirectUrl}/auth/sso-login?token=${token}&refresh_token=${refreshToken}&redirect_url=${encodeURI(window.location.origin)}`;
    });
  }

  handleSSOLogout() {
    this.getCrossDomainUrl().subscribe(redirectUrl => {
      window.localStorage.clear();
      window.location.href = `${redirectUrl}/auth/sso-logout`;
    });
  }

  getCrossDomainUrl() {
    const schema = environment.name === 'altiuslife-dev' ? 'http://' : 'https://';
    const isInsightDomain = this.isInsightsDomain();
    if (environment.name === 'altiuslife-dev') {
      return of(isInsightDomain ? `${schema}localhost:4200` : `${schema}${this.userService.envVariables.app.insightsDomain}`);
    }

    if (!this.userService.isLoggedIn()) {
      return of(`${schema}${isInsightDomain ? this.userService.envVariables.app.mainDomain : this.userService.envVariables.app.insightsDomain}`);
    }

    if (!this.userService.company) {
      this.userService.refreshLocalStorageUser().subscribe(user => {
        return of(this.getCompanyCrossDomainUrl(user && user.company));
      });
    } else {
      return of(this.getCompanyCrossDomainUrl(this.userService.company));
    }
  }

  getCompanyCrossDomainUrl(company) {
    const schema = 'https://';
    const isInsightDomain = this.isInsightsDomain();
    const defaultCrossDomain = `${schema}${isInsightDomain ? this.userService.envVariables.app.mainDomain : this.userService.envVariables.app.insightsDomain}`;

    if (!company) {
      return defaultCrossDomain;
    }

    if (isInsightDomain && company.hostname) {
      return `${schema}${company.hostname}`;
    }

    if (!isInsightDomain) {
      return `${schema}${company.insightsDomain ? company.insightsDomain : `${company.registerCode}.${this.userService.envVariables.app.insightsDomain}`}`;
    }

    return defaultCrossDomain;
  };

  getEmbedData(log) {
    return this.http.get<any>(`/insights/embed-data?log=${log}`);
  }

  getLogs() {
    return this.http.get<any>(`/insights/logs`);
  }

  getInsightsVersions() {
    return this.insightsVersions.slice();
  }

  getDefaultInsightsVersion() {
    return this.insightsVersions[0].id;
  }
}
