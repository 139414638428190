import { Component, OnInit } from '@angular/core';
import { ResourcesService } from '../../../services/resources-service';
import { ActivatedRoute } from '@angular/router';
import { LogService } from '../../../services/log.service';
import { UserService } from '../../../services/user-service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  resources: any[] = [];
  constructor(
    private resourcesService: ResourcesService,
    public activatedRoute: ActivatedRoute,
    private userService: UserService,
    private logService: LogService
  ) { }

  ngOnInit() {
    this.getResources();
    this.logService.logInformationPacks();
  }

  getResources() {
    this.resourcesService.getUserResources().subscribe(
      data => {
        if (Array.isArray(data)) {
          this.resources = data;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  openFile(file) {
    const url = this.userService.envVariables.aws.awsBucket + 'resources/files/' + file;
    window.open(url, '_blank');
  }

  getPath(image) {
    return this.userService.envVariables.aws.awsBucket + 'resources/images/' + image;
  }
}
