<section *ngIf='!isLoading && bookingCode' class='booking-poster-section d-flex align-items-center flex-column'>
  <div *ngIf='!previewMode' class='d-flex justify-content-end my-2 booking-poster-section__actions '>
    <!--    <button (click)='onPreviewPDF()' [disabled]='isProcessing' class='btn btn-primary mx-2'-->
    <!--            data-placement='top' data-toggle='tooltip' title='Preview' type='button'>-->
    <!--      <i aria-hidden='true' class='fa fa-eye'></i>-->
    <!--    </button>-->
    <button (click)='onGeneratePDF()' [disabled]='isProcessing' class='btn btn-primary' title='Download' type='button'>
      <i aria-hidden='true' class='fa fa-download'></i>
    </button>
  </div>
  <div #bookingPoster class='booking-poster-section__content' (click)='onGeneratePDF()'>
    <div class='booking-poster-section__content__design'>
      <img alt='poster' src='assets/img/booking-poster/design.jpg'>
    </div>
    <qrcode [qrdata]="bookingUrl" [size]='144' [level]="'M'" [className]="'booking-poster-section__content__qrcode'"></qrcode>
    <div *ngIf='bookingCode' class='booking-poster-section__content__labels'>
      <span class='bold color-blue size-22'>Booking Code</span>
      <span class='bold color-dark-blue size-34 mt-n2'>{{bookingCode}}</span>
      <span class='bold color-blue size-19 mt-n2'>PeopleSense.au</span>
    </div>
    <div class='booking-poster-section__content__link'>
      <span class='bold color-blue size-24'>www.peoplesense.au/{{bookingCode}}</span>
      <span class='color-grey size-12'>Scan the QR code or use our link to make a booking</span>
      <span class='color-grey size-8 mt-3'>The link above is unique to {{companyName}}.<br />Appointments can always be made by phone if you prefer.</span>
    </div>
  </div>
</section>
