import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPartner } from '../app/_models/partner';
import { ICMSPartner } from '../app/_models/cms-partner';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  private partnersChangedSubject = new BehaviorSubject<any>(null);
  partnersChanged$: Observable<null> = this.partnersChangedSubject.asObservable();

  constructor(private http: HttpClient) { }

  getPartners() {
    const url = `/partners`;

    return this.http.get<IPartner[]>(url);
  }

  getPartnerById(id: number) {
    const url = `/partners/${id}`;
    return this.http.get<any>(url);
  }

  sendPartnerEnquiry(body: any) {
    const url = `/partners/enquiry`;
    return this.http.post<any>(url, body);
  }

  /* CMS */

  getCMSPartners() {
    const url = `/cms/partners`;
    return this.http.get<ICMSPartner[]>(url);
  }

  /* CMS */

  getNewPartner(): ICMSPartner {
    return {
      id: 0,
      name: '',
      contactEmail: '',
      contactPhone: '',
      logo: '',
      image: '',
      formFields: '',
      title: '',
      shortText: '',
      longText: '',
      termsOfService: '',
      privacyPolicy: '',
      categoryId: undefined,
      conditionsText: '',
      website: '',
      companyId: undefined,
      excludedCompanyIds: []
    };
  }

  getS3Hash() {
    return this.http.get<any>('/config/froala/articles');
  }

  addPartner(body) {
    let today = new Date();
    body.createdAt = today;
    body.updatedAt = today;
    const url = `/cms/partners`;
    return this.http.post<any>(url, body);
  }

  updatePartner(body) {
    body.updatedAt = new Date();
    const url = `/cms/partners/${body.id}`;
    return this.http.put<any>(url, body);
  }

  deletePartner(id: number) {
    const url = `/cms/partners/${id}`;
    return this.http.delete<any>(url);
  }

  notifyPartnersChanged() {
    this.partnersChangedSubject.next(null);
  }
}
