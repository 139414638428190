import { FeatureService } from '../../../services/feature-service';
import { DateService } from '../../../services/date.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';

import { UserService } from '../../../services/user-service';
import { StripeService } from '../../../services/stripe-service';
import { UtilService } from '../../../services/util-service';
import { IUser } from '../../_models/user';

@Component({
  selector: 'app-subscription',
  templateUrl: 'subscription.component.html',
  styleUrls: ['subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  isCancelledSubscription = false;
  endDate: any;
  stripeToken: any;
  customerInvoices: any;
  loading = false;
  cardExpiryMonth: any;
  cardExpiryYear: any;
  lastCardDigits: any;
  companyName: string;

  constructor(
    private userService: UserService,
    private stripeService: StripeService,
    public utilService: UtilService,
    private modalService: NgbModal,
    private dateService: DateService,
    private feature: FeatureService,
  ) {
  }

  ngOnInit() {
    this.checkUserSubscriptionStatus();
    this.getCustomerAndPaymentHistory();
    this.companyName = this.userService.company.name;
  }

  canViewCurrentCardDetails(): boolean {
    return !!this.cardExpiryMonth && !!this.cardExpiryYear && !!this.cardExpiryYear;
  }

  formattedDmy(timestamp) {
    const date = this.dateService.fromUnixTimestamp(timestamp);

    return this.dateService.formatDD_MMM_YYYY(date);
  }

  openInvoice(pdf) {
    window.open(pdf, '_blank');
  }

  checkUserSubscriptionStatus() {
    this.userService
      .getMe()
      .subscribe((data: IUser) => {
        if (data.subscriptionEndedAt) {
          this.isCancelledSubscription = true;
          this.endDate = this.dateService.formatDD_MMM_YYYY(data.subscriptionEndedAt);
        }
      });
  }

  cancelStripeSubscription() {
    const user = this.userService.user.stripeCustomerId;
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.confirmButtonLabel = 'Yes, please cancel';
    modal.componentInstance.title = 'Cancel Subscription';
    modal.componentInstance.message = 'Are you sure you want to cancel your subscription?';
    modal.result.then((result) => {
      if (result) {
        this.stripeService.deleteStripeSubscription(user)
          .subscribe((data) => {
            if (data.success) {
              this.isCancelledSubscription = true;
              this.utilService.showToastSuccess('',
                'You have now cancelled your subscription');
            }
            this.checkUserSubscriptionStatus();
          });
      }
    });
  }

  canShow(): boolean {
    const defaultValue = true;
    return this.userService.hasCompanyFeatureOrDefault(this.feature.StripeFeatureId, defaultValue);
  }

  updatePaymentDetailsModal() {
    const stripeHandler = (<any>window).StripeCheckout.configure({
      key: this.userService.getStripeToken(),
      locale: 'auto',
      panelLabel: 'Update Card Details',
      label: 'Update Card Details',
      token: token => {
        if (token.id) {
          this.updatePaymentDetails(token);
        }
      },
    });
    stripeHandler.open({
      name: this.companyName,
      description: 'Update card details',
      email: this.userService.user.email,
      'allow-remember-me': false,
    });
  }

  updatePaymentDetails(token) {
    const stripeCustomerId = this.userService.user.stripeCustomerId;
    this.stripeService.updateCardDetails(token.id, stripeCustomerId)
      .subscribe((data) => {
        if (data.success) {
          this.utilService.showToastSuccess('',
            'Your card details were updated!');
        }

        this.getCustomerAndPaymentHistory();
      });
  }

  getCustomerAndPaymentHistory() {
    this.loading = true;
    const stripeId = this.userService.user.stripeCustomerId;
    this.stripeService.getCustomerPaymentHistory(stripeId)
      .subscribe((data) => {
        this.customerInvoices = data.invoices;
        this.loading = false;
        this.cardExpiryMonth = data.customer.sources.data[0].exp_month;
        this.cardExpiryYear = data.customer.sources.data[0].exp_year;
        this.lastCardDigits = data.customer.sources.data[0].last4;
      });
  }

  viewCard() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Card Details';
    modal.componentInstance.message = `Card number: **** **** **** ${this.lastCardDigits} <br />
    Card expiry: ${this.cardExpiryMonth}/${this.cardExpiryYear}`;
    modal.componentInstance.singleButton = true;
  }
}
