import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { DateService } from './date.service';
import { IGroup } from '../app/_models/group';
import { ILatestGroupsActivity } from '../app/_models/latest-groups-activity';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  INACTIVE_STATUS_ID = 0;
  ACTIVE_STATUS_ID = 1;

  INACTIVE_STATUS_NAME = 'Inactive';
  ACTIVE_STATUS_NAME = 'Active';

  private groupActivity = new BehaviorSubject<ILatestGroupsActivity>(null);
  currentGroupActivity$ = this.groupActivity.asObservable();

  private groupsChangedSubject = new BehaviorSubject<any>(null);
  groupsChanged$: Observable<null> = this.groupsChangedSubject.asObservable();

  constructor(
    private http: HttpClient,
    private dateService: DateService) { }

  changeGroupActivity(latestGroupActivity: ILatestGroupsActivity) {
    this.groupActivity.next(latestGroupActivity);
  }

  getCurrentGroupActivity(): Observable<ILatestGroupsActivity> {
    return this.currentGroupActivity$;
  }

  notifyGroupsChanged() {
    this.groupsChangedSubject.next(null);
  }

  sortGroupsByLastViewedDescending(groups: IGroup[]): IGroup[] {
    const sortedGroup = groups.sort((g1: IGroup, g2: IGroup) => {
      if (g1.lastViewed === g2.lastViewed) { return 0; }

      return (g1.lastViewed > g2.lastViewed)
        ? -1
        : 1;
    });

    return [...sortedGroup];
  }

  sortGroupsByIdDescending(groups: IGroup[]): IGroup[] {
    const sortedGroup = groups.sort((g1: IGroup, g2: IGroup) => {
      return g2.id - g1.id;
    });

    return [...sortedGroup];
  }

  createGroup(data) {
    const body = data;
    const url = `/groups/`;

    return this.http.post<any>(url, body)
      .pipe(tap(() => {
        this.groupsChangedSubject.next(null);
      }));
  }

  addGroup(group: IGroup) {
    const body = {
      name: group.name,
      description: group.description,
      companyId: group.companyId,
      excludedCompanyIds: group.excludedCompanyIds,
      logo: group.logo,
      banner: group.banner,
      status: group.status
    };
    const url = `/groups/`;

    return this.http.post<any>(url, body)
      .pipe(tap(() => {
        this.groupsChangedSubject.next(null);
      }));
  }

  updateGroup(group: IGroup) {
    const body = {
      id: group.id,
      name: group.name,
      description: group.description,
      companyId: group.companyId,
      excludedCompanyIds: group.excludedCompanyIds,
      logo: group.logo,
      banner: group.banner,
      status: group.status
    };
    const url = `/cms/groups/${group.id}`;

    return this.http.put<any>(url, body);
  }

  joinGroup(groupId) {
    const body = { groupId };
    const url = `/groups/members/join`;

    return this.http.post<any>(url, body)
      .pipe(tap(() => {
        this.groupsChangedSubject.next(null);
      }));
  }

  leaveGroup(groupId) {
    const body = { groupId };
    const url = `/groups/members/leave`;

    return this.http.post<any>(url, body)
      .pipe(tap(() => {
        this.groupsChangedSubject.next(null);
      }));
  }

  getGroupById(groupId) {
    const url = `/groups/${groupId}`;

    return this.http.get<any>(url);
  }

  getNewGroup(): IGroup {
    return {
      id: 0,
      name: '',
      companyId: undefined,
      excludedCompanyIds: [],
      description: '',
      status: this.ACTIVE_STATUS_ID,
      logo: '',
      banner: '',
      groupMembers: [],
      posts: []
    };
  }

  getGroupsWhereMember() {
    const url = `/groups/member`;

    return this.http.get<any>(url);
  }

  getGroupsCMS(companyId) {
    const url = `/cms/groups/${companyId}`;

    return this.http.get<any>(url);
  }

  getUserGroups() {
    const url = `/groups`;

    return this.http.get<any>(url);
  }

  deleteGroup(groupId) {
    const url = `/cms/groups/${groupId}`;

    return this.http.delete<any>(url)
      .pipe(tap(() => {
        this.groupsChangedSubject.next(null);
      }));
  }

  deletePost(postId) {
    const url = `/posts/admin/${postId}`;

    return this.http.delete<any>(url);
  }

  deleteComment(commentId) {
    const url = `/posts/comments/${commentId}`;

    return this.http.delete<any>(url);
  }

  createPost(groupId, data) {
    const body = { groupId, ...data };
    const url = `/posts/knex`;
    return this.http.post<any>(url, body);
  }

  updatetPost(id, body) {
    body.updatedAt = this.dateService.getLocalDateTimeTzOffset();
    const url = `/posts/knex/${id}`;

    return this.http.put<any>(url, body);
  }

  getPaginatedPosts(groupId, offset, limit) {
    const _offset = offset || 0;
    const _limit = limit || 10;
    const url = `/posts/knex/posts/${groupId}/${_offset}/${_limit}`;

    return this.http.get<any>(url);
  }

  createComment(groupId, postUserIds, body) {
    const data = { groupId, postUserIds, body };
    const url = `/posts/knex/comments`;

    return this.http.post<any>(url, data);
  }

  updateComment(id, body) {
    body.updatedAt = this.dateService.getLocalDateTimeTzOffset();
    const url = `/posts/knex/comments/${id}`;

    return this.http.put<any>(url, body);
  }

  getLatestGroupActivity() {
    const url = `/groups/latestActivity`;
    return this.http.get<any>(url);
  }

  refreshLatestGroupActivity() {
    this.getLatestGroupActivity()
      .pipe(take(1))
      .subscribe((data: ILatestGroupsActivity) => {
        this.changeGroupActivity(data);
      });
  }

  statusName(status) {
    if (status === this.INACTIVE_STATUS_ID) {
      return this.INACTIVE_STATUS_NAME;
    }
    if (status === this.ACTIVE_STATUS_ID) {
      return this.ACTIVE_STATUS_NAME;
    }

    return 'Unknown!';
  }

  getAllStatuses() {
    return [
      { id: this.INACTIVE_STATUS_ID, name: this.INACTIVE_STATUS_NAME },
      { id: this.ACTIVE_STATUS_ID, name: this.ACTIVE_STATUS_NAME }
    ];
  }
}
