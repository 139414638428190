export class ApplicantData {
    personal_details = {
      firstname: '',
      surname: '',
      address: '',
      suburb: '',
      state: 'State*',
      post_code: '',
      home_phone: '',
      mobile: '',
      email: ''
    };
    apply_self = 'Select';
    apply_person = 'Select';
    apply_others = '';
    emergency_contacts = {
      firstname: '',
      surname: '',
      address: '',
      suburb: '',
      state: 'State*',
      post_code: '',
      home_phone: '',
      mobile: '',
      email: ''
    };
  }
  export class CareData {
    personal_details = {
      firstname: '',
      surname: '',
      address: '',
      suburb: '',
      state: 'State*',
      post_code: '',
      home_phone: '',
      mobile: '',
      email: ''
    };
    assessment_status = 'Select';
    assessment_result = '';
    marital_status = 'Select';
    marital_status_other = '';
    living_status = 'Select';
    house_care = 'Select';
    health = 'Select';
    special_needs = '';
    dietary_requirements = '';
    languages = '';
    hygiene = 'Select';
    mobility = 'Select';
    cultural_needs = '';
    allergies = '';
    medicare = 'Select';
    acat = 'Select';
    pension = 'Select';
    accr = 'Select';
  }

  export class ServiceData {
    facility = 'Select';
    facility_other = '';
    single_room = 'Select';
    bathroom = 'Select';
    outdoor = 'Select';
    additional_information: '';
    terms_and_conditions = '';
  }
