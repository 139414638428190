import { UtilService } from '../../../../services/util-service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IAnswer } from '../../../_models/answer';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output, Input, NgZone } from '@angular/core';
import { FilestackService } from '../../../../services/filestack-service';
import { QuestionnaireService } from '../../../../services/questionnaire-service';

@Component({
  selector: 'app-answer-management',
  templateUrl: './answer-management.component.html',
  styleUrls: ['./answer-management.component.scss']
})
export class AnswerManagementComponent implements OnInit {

  @Input() questions: any[];
  @Input() questionType: string;
  @Input() questionId: number;
  @Input() resultType: number;
  @Input() dependencyParams;
  @Input() item: IAnswer;

  isNumberType: Boolean = false;
  isTextType: Boolean = false;
  itemType: string;
  itemForm: FormGroup;
  newItem;
  labelText = 'Label'
  canRespondToButtons: boolean;
  answersDependency = [];
  @Output() finishedAnswerEdit: EventEmitter<any> = new EventEmitter<any>();
  isAddingAnswer: Boolean = false;
  constructor(
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private utilService: UtilService,
    private ngZone: NgZone,
    private fileStackService: FilestackService,
    private questionnaireService: QuestionnaireService
  ) { }

  ngOnInit() {
    if (!this.item) {
      this.isAddingAnswer = true;
      this.item = this.questionnaireService.getNewAnswer(this.questionId);
    }
    if (this.dependencyParams) {
      this.item.questionDependencyId = this.dependencyParams.id;

    } else {
      const foundDependencyParams = this.questions.find(question => question.id === this.item.questionDependencyId);
      if (foundDependencyParams) {
        this.dependencyParams = foundDependencyParams;
        this.item.questionDependencyId = this.dependencyParams.id;
      }
    }
    this.updateQuestionAnswers(this.item.questionDependencyId);
    if (this.questionType === 'number') {
      this.isNumberType = true;
      this.labelText = 'Placeholder for number field (optional)';
    } else if (this.questionType === 'text') {
      this.isTextType = true;
      this.labelText = 'Placeholder for text field (optional)';
    }
    this.itemType = 'Answer';
    this.createFormGroup();
    this.canRespondToButtons = true;
  }

  private createFormGroup() {
    this.itemForm = this.fb.group({
      dependencyLevel: this.item.answersDependency ? 'specific' : 'any',
      labelControl: [this.item.label, Validators.required],
      valueControl: this.item.value,
      value2Control: this.item.value2,
      pointsControl: this.item.points,
      iconControl: this.item.icon,
      operandControl: this.item.operand,
      questionDependencyControl: this.item.questionDependencyId,
    });

  }

  isSpecificDependency() {
    return this.itemForm.get('dependencyLevel').value === 'specific';
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field)
    };
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach(field => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  private persistItem() {
    this.canRespondToButtons = false;

    try {
      this.newItem = {
        label: this.itemForm.get('labelControl').value,
        value: +this.itemForm.get('valueControl').value,
        value2: +this.itemForm.get('value2Control').value,
        points: +this.itemForm.get('pointsControl').value,
        icon: this.itemForm.get('iconControl').value,
        operand: this.itemForm.get('operandControl').value,
        liqQuestionId: this.item.liqQuestionId,
        answersDependency: this.answersDependency.filter(a => a.checked).map(a => a.id).toString(),
        questionDependencyId: this.itemForm.get('questionDependencyControl').value
      };
    } catch (error) {
      this.utilService.showToastError('Error persisting item - ' + error);
      this.canRespondToButtons = true;
      return;
    }
    if (!this.isAddingAnswer) {
      this.newItem.id = this.item.id;
    }
    this.finishedAnswerEdit.emit(this.newItem);
  }

  pointsIsInvalid() {
    return this.itemForm.get('pointsControl').touched &&
      ((!this.itemForm.get('pointsControl').value && (this.resultType === 2 || this.resultType === 4))
        || (this.itemForm.get('operandControl').value &&
          !this.itemForm.get('valueControl').value && !this.itemForm.get('pointsControl').value));
  }

  valueIsInvalid() {
    return this.itemForm.get('valueControl').touched &&
      !this.itemForm.get('valueControl').value && !this.itemForm.get('pointsControl').value
      && this.itemForm.get('operandControl').value;
  }

  value2IsInvalid() {
    return this.itemForm.get('valueControl').touched && !this.itemForm.get('value2Control').value &&
      (this.itemForm.get('operandControl').value === 'between' || this.itemForm.get('operandControl').value === 'notIn');
  }

  updateQuestionAnswers(questionId: Number) {
    const question = this.questions.find(q => q.id === +questionId);
    if (question) { this.answersDependency = question.liqAnswers; }
    const selectedAnswers = this.item.answersDependency ? this.item.answersDependency.split(',').map(Number) : [];
    this.answersDependency.map(a => {
      a.checked = selectedAnswers.indexOf(a.id) > -1 ? true : null;
    });
  }

  selectAnswer(answerIndex: number) {
    this.answersDependency[answerIndex].checked = this.answersDependency[answerIndex].checked ? null : true;
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();

      return;
    }

    this.persistItem();
  }

  onCloseClick() {
    this.activeModal.close(null);
  }

  isQuestionsType(type1, type2) {
    return this.questionType === type1 || this.questionType === type2;
  }

  validateOperandValues() {
    this.markAllControlsAsTouched();
    if (this.pointsIsInvalid()) { return false; }
    if (this.valueIsInvalid()) { return false; }
    return !this.value2IsInvalid();


  }

  openfilestack() {
    this.fileStackService.pickSpecifiedPathImage('/questionnaires/', 100 * 100)
      .then(result => this.ngZone.run(() => this.itemForm.patchValue({ iconControl: result })))
      .catch(err => this.utilService.showToastError('Error while uploading the icon', err));
  }
}
