import { Component, OnInit, ViewChild } from '@angular/core';
import { LifeActionService } from '../../../services/life-action-service';
import { UserService } from '../../../services/user-service';
import { NgbActiveModal, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import { LogService } from '../../../services/log.service';
import { DateService } from '../../../services/date.service';
import { ChartingService } from '../../../services/charting.service';
import { CategoryService } from '../../../services/category-service';

import { ISearchTerm } from '../../_models/searchTerm';

@Component({
  selector: 'app-life-action',
  templateUrl: './life-action.component.html',
  styleUrls: ['./life-action.component.scss'],
})
export class LifeActionCardComponent implements OnInit {
  allLifeActions: any[] = [];
  displayActiveActions: any[] = [];
  filteredAllActions: any[] = [];
  displayAllActions: any[] = [];
  driverFilters: any[] = [];
  inactiveFilter: boolean;
  searchTerm: string = '';
  resetSearch: boolean = false;
  @ViewChild('tabs')
  private tabs: NgbTabset;

  constructor(
    public lifeActionService: LifeActionService,
    public userService: UserService,
    private logService: LogService,
    public activeModal: NgbActiveModal,
    private dateService: DateService,
    public chartingService: ChartingService,
    public categoryService: CategoryService,
  ) {
  }

  ngOnInit() {
    this.categoryService.refreshDriverCache();
    this.getAllActiveAndInactiveLifeActions();
    this.logService.logLifeActions();
  }

  getAllActiveAndInactiveLifeActions() {
    this.lifeActionService.getAllActiveAndInactiveLifeActions().subscribe((data) => {
      this.displayActiveActions = _.cloneDeep(data.allUserLifeActions.filter(li => li.active));
      this.allLifeActions = data.allUserLifeActions;
      this.displayAllActions = this.allLifeActions;

      if (!this.displayActiveActions.length) {
        this.tabs.select('all');
      }
    });
  }

  changeActivationStatus(item) {
    const index = this.allLifeActions.findIndex(i => i.id === +item.indicatorId);
    if (item.active === true) {
      return this.lifeActionService.activateLifeAction(item).subscribe(data => {
        this.allLifeActions[index].active = true;
        this.allLifeActions[index].userLifeActionsActivationId = data.userLifeActionsActivationId;
        this.setDriverFilters(this.driverFilters);
        this.setInactiveFilter(this.inactiveFilter);
        this.displayActiveActions.push(data);
      });
    } else {
      return this.lifeActionService.deactivateLifeAction(item.userLifeActionsActivationId).subscribe(() => {
        this.allLifeActions[index].active = false;
        this.allLifeActions[index].userLifeActionsActivationId = null;
        this.setDriverFilters(this.driverFilters);
        this.setInactiveFilter(this.inactiveFilter);
        this.displayActiveActions = this.displayActiveActions.filter(action => {
          return action.userLifeActionsActivationId !== item.userLifeActionsActivationId;
        });
      });
    }
  }

  setDriverFilters(drivers) {
    this.driverFilters = drivers;
    if (!drivers || drivers.length === 0) {
      this.filteredAllActions = this.allLifeActions;
      this.displayAllActions = this.filteredAllActions;
      this.setInactiveFilter(this.inactiveFilter);
    } else {
      this.filteredAllActions = _.filter(this.allLifeActions, function(action) {
        return (drivers.includes(_.lowerCase(action.driverName)));
      });
      this.displayAllActions = this.filteredAllActions;
      this.setInactiveFilter(this.inactiveFilter);
    }
    this.applySearchTermToDisplayAllActions();
  }

  setInactiveFilter(inactive) {
    this.inactiveFilter = inactive;
    if (inactive) {
      if (this.filteredAllActions.length) {
        this.displayAllActions = _.filter(this.filteredAllActions, function(action) {
          return !action.active;
        });
      } else {
        this.displayAllActions = _.filter(this.displayAllActions, function(action) {
          return !action.active;
        });
      }
    } else {
      if (this.filteredAllActions.length) {
        this.displayAllActions = this.filteredAllActions;
      } else {
        this.displayAllActions = this.allLifeActions;
      }
    }
    this.applySearchTermToDisplayAllActions();
  }

  clearAllFilters(event) {
    this.resetSearch = true;
    this.filteredAllActions = null;
    this.displayAllActions = this.allLifeActions;
  }

  completeLifeAction(indicatorBody) {
    this.displayActiveActions[indicatorBody.index] = indicatorBody.indicator;
  }

  applySearchTermToDisplayAllActions() {
    if (this.searchTerm) {
      this.displayAllActions = _.filter(this.displayAllActions, (action) => {
        return _.lowerCase(action.name).includes(this.searchTerm) || _.lowerCase(action.description).includes(this.searchTerm);
      });
    }
  }

  onSearchAll(searchTerm: ISearchTerm) {
    this.resetSearch = false;
    this.searchTerm = _.lowerCase(searchTerm.term);
    if (this.searchTerm) {
      this.displayAllActions = _.filter(this.displayAllActions, (action) => {
        return _.lowerCase(action.name).includes(this.searchTerm) || _.lowerCase(action.description).includes(this.searchTerm);
      });
    } else {
      if (this.filteredAllActions.length) {
        this.displayAllActions = this.filteredAllActions;
      } else {
        this.displayAllActions = this.allLifeActions;
      }
    }
  }

  onSearchActive(searchTermObject: ISearchTerm) {
    if (searchTermObject && !!searchTermObject.term) {
      const searchTerm = _.lowerCase(searchTermObject.term);
      this.displayActiveActions = _.filter(this.displayActiveActions, function(action) {
        return _.lowerCase(action.name).includes(searchTerm) || _.lowerCase(action.description).includes(searchTerm);
      });
    } else {
      this.displayActiveActions = _.cloneDeep(this.allLifeActions.filter(li => li.active));
    }
  }

  changeLifeActionsTab(event) {
    if (event.nextId == 'active') {
      this.displayActiveActions = _.cloneDeep(this.allLifeActions.filter(li => li.active));
    } else if (event.nextId == 'all') {
      this.displayAllActions = this.allLifeActions;
    }
  }
}
