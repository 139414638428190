<div *ngIf='activatedRoute.children.length === 0 && dataLoaded'>
  <h3 class='mt-2'>{{questionnaire.name}}</h3>
  <div class='row mt-3'>
    <div [class]="questionnaire.liqResultTypeId == 3 ? 'col-12 col-sm-6 d-flex mb-4' : 'col-12 mb-4'" *ngFor='let item of latestResult; let resultIndex = index;'>
      <div class='card w-100 mr-0 ml-0 p-0'>
        <div [class]="questionnaire.liqResultTypeId == 3 ? 'result3' : 'result4'" [style.background-color]='item.color'>
          <h4 class='align-middle' *ngIf='questionnaire.liqResultTypeId == 3'>{{item.title}}
          </h4>
          <h5 class='align-middle' *ngIf='questionnaire.liqResultTypeId == 3'>
            <b>{{item.userAnswer}}</b>
          </h5>
          <h3 class='title-dra' *ngIf='questionnaire.liqResultTypeId == 4' [innerHTML]='domSanitizer.bypassSecurityTrustHtml(item.title)'></h3>
        </div>
        <div class='card-body'>
          <p [class]="questionnaire.liqResultTypeId == 3 ? 'card-text card-description-small' : 'card-text card-description-big'"
             [innerHTML]='item.description'></p>

<!--          <app-line-graph *ngIf='questionnaire.showHistory && item.hasHistoryChart' [data]='item.chartData.chartValues'-->
<!--                          [labels]='item.chartData.chartDates' [legend]='false' [color]='chartColor' [pointRadius]='item.chartData.chartPoints'-->
<!--                          [pointColor]='dotColor' [maxValue]='item.chartData.maxValueChart' [hideGrids]='true'-->
<!--                          [title]='questionnaire.liqResultTypeId == 4? "Score Result" : ""'-->
<!--                          [xTitle]='questionnaire.liqResultTypeId == 4? "Date" : ""'-->
<!--                          [yTitle]='questionnaire.liqResultTypeId == 4? "Score" : ""'-->
<!--                          [minValue]='item.chartData.minValueChart' [fill]='true' [minLabelRot]='30' [dataValue]='drawLineValues'-->
<!--                          [lineTension]='0' [class]="questionnaire.liqResultTypeId == 4 ? 'bigChart' : 'smallChart'"></app-line-graph>-->

          <app-bar-graph *ngIf='questionnaire.showHistory && item.hasHistoryChart'
                         [data]='item.chartData.chartValues' [labels]='item.chartData.chartDates'
                         [color]='chartColor' [hideGrids]='true'
                         [xTitle]='questionnaire.liqResultTypeId == 4? "Date" : ""'
                         [yTitle]='questionnaire.liqResultTypeId == 4? "Score" : ""'
                         [class]="questionnaire.liqResultTypeId == 4 ? 'bigChart' : 'smallChart'"></app-bar-graph>

          <a class='btn btn-link w-100 learnmore' *ngIf='item.chartData.articles.length > 0 && questionnaire.liqResultTypeId == 3'
             (click)='goToDetail(item.chartData.articles[0])'>
            <i class='fa fa-info-circle' aria-hidden='true'></i>
            LEARN MORE
          </a>
          <div *ngIf='questionnaire.liqResultTypeId == 4 && dataLoaded && item.chartData.articles.length > 0'>
            <h4 class='mt-2'>Suggested Articles</h4>
            <div class='row'>
              <div *ngFor='let article of item.chartData.articles' class='col-12 col-md-6 col-lg-4 d-flex'>
                <a class='contentAnchorTag' [routerLink]='generateGoToDetailLink(article)'>
                  <app-content-list-card [item]='article' class='mt-0' [isLoggedIn]='isLoggedIn'
                                         [isProgram]='isProgram' [isLocked]='item.locked'></app-content-list-card>
                </a>
              </div>
            </div>
          </div>

          <div *ngIf='questionnaire.liqResultTypeId == 4 && dataLoaded && lifeActions[resultIndex].length > 0'>
            <h4 class='mt-2'>Suggested LifeActions</h4>
            <div class='row mt-1'>
              <div *ngFor='let lifeAction of lifeActions[resultIndex]; let lifeActionIndex = index' class='col-12 col-sm-6 d-flex align-items-stretch'>
                <app-manage-life-action *ngIf='!lifeAction.active' class='w-100' (changeActivationStatus)='onChangeActivationStatus($event, resultIndex, lifeActionIndex)'
                                        [indicator]='lifeAction' [index]='resultIndex + lifeActionIndex'></app-manage-life-action>
                <app-active-life-action class='w-100' *ngIf=lifeAction.active (doLifeAction)='onDoLifeAction($event, resultIndex, lifeActionIndex)'
                                        [indicator]='lifeAction' [index]='resultIndex + lifeActionIndex'></app-active-life-action>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <br />
  <br />
</div>
<div *ngIf='activatedRoute?.children?.length'>
  <router-outlet></router-outlet>
</div>
