import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  constructor(private http: HttpClient) {}

  getUserResources() {
    const url = `/resources/user`;
    return this.http.get<any>(url);
  }

  getResourcesByFeature(featureId) {
    const url = `/resources//byfeature/${featureId}`;
    return this.http.get<any>(url);
  }

  getResourceById(id) {
    const url = `/resources/${id}`;
    return this.http.get<any>(url);
  }
}
