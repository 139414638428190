import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportsService } from '../../../services/reports-service';
import { UserService } from '../../../services/user-service';
import { DateService } from '../../../services/date.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-analytics-reporting',
  templateUrl: './analytics-reporting.component.html',
  styleUrls: ['./analytics-reporting.component.scss']
})
export class AnalyticsReportsComponent implements OnInit {
  canShowDailySiteVisits = false;
  canShowTopArticles = false;
  canShowTopPages = false;
  canShowDeviceSummary = false;
  canShowPageViewsAndTimes = false;

  // API query variables
  company: any;
  user: any;

  // chart data variables
  color: any = [];
  betterDates: any = [];
  deviceSessionsLabels: any = [];
  deviceSessionsData: any = [];
  totalUsersLabels: any = [];
  totalUsersData: any = [];
  popularPagesData: any = [];
  popularPagesLabels: any = [];
  totalPageViewsData: any;
  uniquePageViewsData: any;
  topTenArticles: any;
  averageTimeData: any;

  // html rendering conditionals
  deviceSessionsDataAvailable: boolean;
  deviceSessionsDataLoading: boolean;
  totalUsersDataAvailable: boolean;
  totalUsersDataLoading: boolean;
  popularPagesDataLoading: boolean;
  popularPagesDataAvailable: boolean;
  pageViewsAndTimeDataLoading: boolean;
  pageViewsAndTimeDataAvailable: boolean;
  topTenArticlesDataLoading: boolean;
  topTenArticlesDataAvailable: boolean;

  startDate: Date;
  endDate: Date;
  canSelectCompany = false;
  companyId: number;

  constructor(
    private reportService: ReportsService,
    private userService: UserService,
    private dateService: DateService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.setUserRoleAuthorisations();
    this.color = this.reportService.getChartColors();
    this.setInitialComponentState();
    this.refreshAllCharts(this.companyId, this.startDate, this.endDate);
  }

  private setUserRoleAuthorisations() {
    this.canSelectCompany = this.userService.isSuperAdminUser() || this.userService.isAdminUser();
    this.canShowDailySiteVisits = false;
    this.canShowTopArticles = this.userService.isReportsSuperAdminUser();
    this.canShowTopPages = this.userService.isReportsSuperAdminUser();
    this.canShowDeviceSummary = this.userService.isReportsSuperAdminUser();
    this.canShowPageViewsAndTimes = this.userService.isReportsSuperAdminUser();
  }

  private setInitialComponentState() {
    this.user = this.userService.user;
    this.company = this.userService.company;
    this.companyId = this.reportService.cmsCompanyId;
    this.startDate = this.reportService.cmsStartDate;
    this.endDate = this.reportService.cmsEndDate;
  }

  private refreshAllCharts(companyId, startDate: Date, endDate: Date) {
    if (companyId === null) {
      companyId = 0;
    }
    this.resetData();
    const queryBody = this.getFormattedQueryBody(startDate, endDate);
    this.getGoogleDeviceData(companyId, queryBody);
    this.getGoogleUserData(companyId, queryBody);
    this.getGooglePopularPages(companyId, queryBody);
    this.getAllPageViewAnalytics(companyId, queryBody);
    this.getGoogleTopArticles(companyId, queryBody);
  }

  private getFormattedQueryBody(startDate: Date, endDate: Date) {
    return {
      startDate: this.dateService.formatYYYY_MM_DD(startDate),
      endDate: this.dateService.formatYYYY_MM_DD(endDate)
    };
  }

  private totalUsersDataLoaded() {
    this.totalUsersDataAvailable = true;
    this.totalUsersDataLoading = false;
  }

  private totalUsersDataUnavailable() {
    this.totalUsersDataAvailable = false;
    this.totalUsersDataLoading = false;
  }

  private topTenArticlesDataLoaded() {
    this.topTenArticlesDataAvailable = true;
    this.topTenArticlesDataLoading = false;
  }

  private topTenArticlesDataUnavailable() {
    this.topTenArticlesDataAvailable = false;
    this.topTenArticlesDataLoading = false;
  }

  private deviceSessionsDataLoaded() {
    this.deviceSessionsDataAvailable = true;
    this.deviceSessionsDataLoading = false;
  }

  private deviceSessionsDataUnavailable() {
    this.deviceSessionsDataAvailable = false;
    this.deviceSessionsDataLoading = false;
  }

  private popularPagesDataLoaded() {
    this.popularPagesDataAvailable = true;
    this.popularPagesDataLoading = false;
  }

  private popularPagesDataUnavailable() {
    this.popularPagesDataAvailable = false;
    this.popularPagesDataLoading = false;
  }

  private pageViewsAndTimeDataLoaded() {
    this.pageViewsAndTimeDataAvailable = true;
    this.pageViewsAndTimeDataLoading = false;
  }

  private getGoogleDeviceData(companyId, body) {
    if (!this.canShowDeviceSummary) { return; }

    this.reportService.getGoogleDeviceSessionData(companyId, body).subscribe(data => {
      if (data.totalResults >= 1) {
        data.rows.map(value => {
          if (value[0]) {
            this.deviceSessionsLabels.push(value[0]);
            this.deviceSessionsData.push(value[1]);
          }
        });
        this.deviceSessionsDataLoaded();
      } else {
        this.deviceSessionsDataUnavailable();
      }
    }, () => {
        this.deviceSessionsDataUnavailable();
      });
  }

  private getGoogleUserData(companyId, body) {
    if (!this.canShowDailySiteVisits) { return; }

    this.reportService.getGoogleUsers(companyId, body)
      .subscribe(data => {
        if (data.totalResults >= 1) {
          data.rows.map(value => {
            if (value[0]) {
              this.totalUsersLabels.push(value[0]);
              this.totalUsersData.push(value[1]);
            }
          });
          this.totalUsersLabels.forEach(val => {
            const date = this.dateService.formatDDMM(val);
            this.betterDates.push(date);
          });
          this.totalUsersDataLoaded();
        } else {
          this.totalUsersDataUnavailable();
        }
      }, () => {
          this.totalUsersDataUnavailable();
        });
    }

  private getGooglePopularPages(companyId, body) {
    if (!this.canShowTopPages) { return; }

    this.reportService.getGooglePopularPageViews(companyId, body).subscribe(data => {
      if (data.totalResults >= 1) {
        data.rows.map(value => {
          if (value[0] && value[0] !== '/' && value[0] !== '/undefined') {
            this.popularPagesData.push(value);
          }
          this.popularPagesDataLoaded();
        });
      } else {
        this.popularPagesDataUnavailable();
      }
    }, () => {
        this.popularPagesDataUnavailable();
      });
  }

  private getGooglePageViewData(companyId, body) {
    this.reportService.getGooglePageViewTotal(companyId, body).subscribe(data => {
      if (data.totalResults >= 1) {
        this.totalPageViewsData = data.totalsForAllResults['ga:pageviews'];
      }
    });
  }

  private getGoogleAvgTimeOnPage(companyId, body) {
    this.reportService.getGoogleAvgTimeOnPage(companyId, body).subscribe(data => {
      if (data.totalResults >= 1) {
        const date = new Date(null);
        date.setSeconds(data.totalsForAllResults['ga:avgSessionDuration']);
        this.averageTimeData = date.toISOString().substr(11, 8);
      }
    });
  }

  private getGoogleUniquePageViewData(companyId, body) {
    this.reportService.getGoogleUniquePageTotal(companyId, body).subscribe(data => {
      if (data.totalResults >= 1) {
        this.uniquePageViewsData = data.totalsForAllResults['ga:uniquePageViews'];
      }
    });
  }

  private getGoogleTopArticles(companyId, body) {
    if (!this.canShowTopArticles) { return; }

    this.reportService.getGoogleTopArticleTotal(companyId, body).subscribe(data => {
      if (data) {
        this.topTenArticles = _.remove(data, function(article) {
          return article !== null;
        });
        this.topTenArticlesDataLoaded();
      } else {
        this.topTenArticlesDataUnavailable();
      }
    }, () => {
        this.topTenArticlesDataUnavailable();
    });
  }

  goToArticle(route) {
    this.router.navigate([route]);
  }

  private async getAllPageViewAnalytics(companyId, body) {
    if (!this.canShowPageViewsAndTimes) { return; }

    await this.getGooglePageViewData(companyId, body);
    await this.getGoogleAvgTimeOnPage(companyId, body);
    await this.getGoogleUniquePageViewData(companyId, body);

    this.pageViewsAndTimeDataLoaded();
  }

  onDateRangeSelected($event) {
    this.reportService.cmsCompanyId = $event.companyId;
    this.reportService.cmsStartDate = $event.startDate;
    this.reportService.cmsEndDate = $event.endDate;

    this.refreshAllCharts($event.companyId, $event.startDate, $event.endDate);
  }

  private resetData() {
    this.totalUsersDataAvailable = false;
    this.totalUsersDataLoading = true;
    this.deviceSessionsDataAvailable = false;
    this.deviceSessionsDataLoading = true;
    this.popularPagesDataAvailable = false;
    this.popularPagesDataLoading = true;
    this.pageViewsAndTimeDataAvailable = false;
    this.pageViewsAndTimeDataLoading = true;
    this.betterDates = [];
    this.deviceSessionsLabels = [];
    this.deviceSessionsData = [];
    this.totalUsersLabels = [];
    this.totalUsersData = [];
    this.popularPagesData = [];
    this.popularPagesLabels = [];
    this.totalPageViewsData = {};
    this.totalPageViewsData = '';
    this.averageTimeData = '';
    this.uniquePageViewsData = '';
  }
}
