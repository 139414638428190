<div class="modal-body">
  <div class="user-row">
    <app-avatar
      [photoSrc]="friend.photoSrc"
      [levelSrc]="friend.levelSrc"
      [isLargerAvatar]="true"
    ></app-avatar>
  <div class="userInfo pl-3">
      <h3 class="primaryColor">{{ friend.username }}</h3>
      <span *ngIf="friend.createdAt">
        Member since: {{friend.createdAt | date:' MMM dd, yyyy'}}
      </span>
    </div>
  </div>
  <hr>
  <h5 class="mt-3 pt-3">Badges {{ friend.username }} has earned</h5>
  <hr>
  <p *ngIf="isNoBadgesEarned">No badges earned</p>
  <div class="badge-col">
    <div *ngFor="let badgeCount of badgeCounts">
      <div align="center">
        <img [src]="badgeCount.imageSrc"
          alt="badgeCount.badgeLevel"
          class="badgeIcon">
          <p>{{ badgeCount.count }}</p>
      </div>
  </div>
</div>
<hr>
<div class="footerButtons p-3">
  <button *ngIf="canClose()"
    type="button"
    class="btn btn-primary"
    [ngClass]="{ 'w-100': canPerformNoAction() }"
    (click)="onClose()"
  >
  Close
  </button>

  <button *ngIf="friend.requestActions.canSendFriendRequest"
    type="button"
    class="btn btn-primary"
    (click)="onSendFriendRequest()"
  >
  Add Friend
  </button>

  <button *ngIf="friend.requestActions.canCancelSentFriendRequest"
    type="button"
    class="btn btn-primary"
    (click)="onCancelSentFriendRequest()"
  >
  Cancel Request
  </button>

  <button *ngIf="friend.requestActions.canConfirmReceivedFriendRequest"
    type="button"
    class="btn btn-primary"
    (click)="onConfirmReceivedFriendRequest()"
  >
  Confirm
  </button>

  <button *ngIf="friend.requestActions.canDeclineReceivedFriendRequest"
    type="button"
    class="btn btn-primary"
    (click)="onDeclineReceivedFriendRequest()"
  >
  Decline
  </button>

  <button *ngIf="friend.requestActions.canRemoveConfirmedFriend"
    type="button"
    class="btn btn-primary"
    (click)="onRemoveConfirmedFriend()"
  >
  Remove
  </button>
</div>

