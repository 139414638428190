<div class="form-group input-group" [formGroup]="parentForm">
    <small><label for="password">Password</label></small>
    <div class="input-group mb-1">
      <input
      #passwordInput
      [formControlName]="'password'"
      (keyup)="onInputPassword(passwordInput.value)"
      type="{{passwordInputType}}"
      name="password"
      id="password"
      placeholder="Password"
      class="form-control form-control-sm"
      [ngClass]="inputcss"/>
    <div class="input-group-append">
      <button
      #showPasswordInput
      (click)="toggleShowPassword()"
      type="button"
      tabindex="-1"
      class="btn btn-primary btn-sm">
      <i class="fa {{showPasswordIcon}}"></i>
      </button>
    </div>
    </div>
</div>
<div *ngIf="showIsPasswordRequiredError()" class="cross-validation-error-message alert alert-danger p-1">
  <small>Password required.</small>
</div>
<div *ngIf="showIsPasswordNotMatchedPatternError()" class="cross-validation-error-message alert alert-danger p-1">
  <small>For security reasons, your password must:</small>
  <ul>
    <li>Be at least 8 characters in length.</li>
    <li>Contain a mix of lower case (a-z), upper case (A-Z) and numbers (0-9).</li>
  </ul>
</div>
<div class="form-group" [formGroup]="parentForm" *ngIf="showConfirmPassword">
    <small><label for="#confirmPasswordInput">Confirm Password</label></small>

  <input
    #confirmPasswordInput
    [formControlName]="'confirmPassword'"
    (keyup)="onInputConfirmPassword(confirmPasswordInput.value)"
    type="{{passwordInputType}}"
    name="password"
    placeholder="Confirm password"
    class="form-control form-control-sm"
    [ngClass]="inputcss" />
</div>
<div *ngIf="showIsPasswordNotMatchingError()" class="cross-validation-error-message alert alert-danger p-1">
  <small>Passwords do not match.</small>
</div>

