import { UtilService } from '../../../../services/util-service';
import { Component, Input, OnInit } from '@angular/core';
import { IFriend } from '../../../_models/friend';
import { IBadgeCount } from '../../../_models/badge-count';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LogService } from '../../../../services/log.service';
import { IFriendActions } from '../../../_models/friend-actions';

@Component({
  selector: 'app-friend-presentation',
  templateUrl: './friend-presentation.component.html',
  styleUrls: ['./friend-presentation.component.scss'],
})
export class FriendPresentationComponent implements OnInit {
  @Input() friend: IFriend;
  @Input() badgeCounts: IBadgeCount[] = [];
  selectedAction: IFriendActions;
  isNoBadgesEarned = false;

  constructor(
    private logService: LogService,
    private activeModal: NgbActiveModal,
    private utilService: UtilService,
  ) {
  }

  ngOnInit() {
    this.logService.logFriendship(this.friend.id);
    this.selectedAction = {};
    this.isNoBadgesEarned = (this.badgeCounts.filter(b => b.count > 0).length === 0);
  }

  canPerformNoAction(): boolean {
    return this.utilService.isAllFalse([
      this.friend.requestActions.canSendFriendRequest,
      this.friend.requestActions.canCancelSentFriendRequest,
      this.friend.requestActions.canConfirmReceivedFriendRequest,
      this.friend.requestActions.canDeclineReceivedFriendRequest,
      this.friend.requestActions.canRemoveConfirmedFriend,
    ]);
  }

  canClose(): boolean {
    return !this.friend.requestState.isMyReqestReceivedFriend;
  }

  onSendFriendRequest() {
    this.selectedAction.sendFriendRequest = true;
    this.onClose();
  }

  onCancelSentFriendRequest() {
    this.selectedAction.cancelSentFriendRequest = true;
    this.onClose();
  }

  onConfirmReceivedFriendRequest() {
    this.selectedAction.confirmReceivedFriendRequest = true;
    this.onClose();
  }

  onDeclineReceivedFriendRequest() {
    this.selectedAction.declineReceivedFriendRequest = true;
    this.onClose();
  }

  onRemoveConfirmedFriend() {
    this.selectedAction.removeConfirmedFriend = true;
    this.onClose();
  }

  onClose() {
    this.selectedAction.actionedFriend = this.friend;
    this.activeModal.close(this.selectedAction);
  }
}
