<h1>School Locator Form</h1>
<div class="card px-4 py-3 mb-3">
  <p>
    SeventeenHundred can provide assistance when you are looking at school
    options - we will research and find the best matched school for your family
    and in particular your child.
  </p>
  <br />
  <p>
    Please complete the form below and someone from the SeventeenHundred team
    will be in touch either by email or phone to confirm your details. Once we
    have confirmed all of your details you will receive a detailed report on the
    schools available in your chosen area within 7 business days.
  </p>
  <br />
  <p>The report will include the following information:</p>
  <ol>
    <li>Name and contact details of the schools.</li>
    <li>A summary of the schools including enrolment process.</li>
    <li>Schedule of fees (where applicable).</li>
    <li>
      An overview of the curriculum and an explanation of the rating of the
      school.
    </li>
  </ol>
</div>
<div class="mb-4" #progBar>
  <ol class="FormStepList">
    <li class="FormStepList-item">
      <span
        class="FormStepIcon"
        [ngClass]="{
          'is-active': isOnAppicantDetails,
          'is-passed': isApplicantDetailsDone
        }"
      >
        <span
          *ngIf="isApplicantDetailsDone == false"
          class="FormStepIcon-circle"
          >1</span
        >
        <span
          *ngIf="isApplicantDetailsDone == true"
          class="FormStepIcon-circle"
        >
          <i class="fa fa-check"></i>
        </span>
        <span class="FormStepIcon-title">Applicant's details</span>
      </span>
    </li>
    <li class="FormStepList-item">
      <span
        class="FormStepIcon"
        [ngClass]="{
          'is-active': isOnChildDetails,
          'is-passed': isChildDetailsDone
        }"
      >
        <span *ngIf="isChildDetailsDone == false" class="FormStepIcon-circle"
          >2</span
        >
        <span *ngIf="isChildDetailsDone == true" class="FormStepIcon-circle">
          <i class="fa fa-check"></i>
        </span>
        <span class="FormStepIcon-title">Child's details</span>
      </span>
    </li>
    <li class="FormStepList-item">
      <span
        class="FormStepIcon"
        [ngClass]="{ 'is-active': isOnService, 'is-passed': isServiceDone }"
      >
        <span *ngIf="isServiceDone == false" class="FormStepIcon-circle"
          >3</span
        >
        <span *ngIf="isServiceDone == true" class="FormStepIcon-circle">
          <i class="fa fa-check"></i>
        </span>
        <span class="FormStepIcon-title">School requirements</span>
      </span>
    </li>
    <li class="FormStepList-item">
      <span
        class="FormStepIcon FormStepIcon--last"
        [ngClass]="{ 'is-active': isOnReview, 'is-passed': isReviewDone }"
      >
        <span *ngIf="isReviewDone == false" class="FormStepIcon-circle">4</span>
        <span *ngIf="isReviewDone == true" class="FormStepIcon-circle">
          <i class="fa fa-check"></i>
        </span>
        <span class="FormStepIcon-title">Review inputs</span>
      </span>
    </li>
  </ol>
</div>
<div>
  <!-- Forms start -->
  <form
    *ngIf="isOnAppicantDetails"
    (ngSubmit)="onNext('applicantData', progBar)"
    #applicantDetailForm="ngForm"
  >
    <h3>Parent / Guardian - Contact Details</h3>
    <!--Applicant's Details Start -->
    <div class="class card px-2 py-3 mb-3">
      <div>
        <div class="form-inline">
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-user"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="applicantData.personal_details.firstname"
              name="firstname"
              placeholder="First name*"
              required
            />
          </div>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-user"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="applicantData.personal_details.surname"
              name="surname"
              placeholder="Surname*"
              required
            />
          </div>
        </div>
        <div>
          <div class="form-inline">
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="applicantData.personal_details.address"
                name="address"
                placeholder="Address*"
                required
              />
            </div>
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="applicantData.personal_details.suburb"
                name="suburb"
                placeholder="Suburb*"
                required
              />
            </div>
          </div>
          <div class="form-inline">
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <select
                class="custom-select form-control"
                [(ngModel)]="applicantData.personal_details.state"
                name="state"
                required
              >
                <option disabled selected>State*</option>
                <option value="ACT">Australian Capital Territory</option>
                <option value="NSW">New South Wales</option>
                <option value="NT">Northern Territory</option>
                <option value="QLD">Queensland</option>
                <option value="SA">South Australia</option>
                <option value="TAS">Tasmania</option>
                <option value="VIC">Victoria</option>
                <option value="WA">Western Australia</option>
              </select>
            </div>
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <input
                type="number"
                class="form-control"
                maxlength="4"
                [(ngModel)]="applicantData.personal_details.post_code"
                name="post_code"
                placeholder="Post code*"
                required
              />
            </div>
          </div>
        </div>
        <div class="form-inline">
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-phone"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="applicantData.personal_details.phone"
              name="phone"
              placeholder="Preferred phone number*"
              required
            />
          </div>
          <div class="input-group col-md-6 last-items last-item">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-envelope"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="applicantData.personal_details.email"
              name="email"
              placeholder="Email address*"
              required
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3">
      <button
        type="submit"
        [disabled]="!applicantDetailForm.valid"
        class="btn mb-3 offset-7 col-5 col-md-2 offset-md-10"
      >
        Next
      </button>
    </div>
  </form>
  <!-- Child's Details start -->
  <form
    *ngIf="isOnChildDetails"
    (ngSubmit)="onNext('childData', progBar)"
    #childDetailForm="ngForm"
  >
    <h3>Child's Details</h3>
    <div class="class card px-2 py-3 mb-3">
      <div>
        <div class="form-inline">
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-user"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="childData.personal_details.firstname"
              name="firstname"
              placeholder="First name"
            />
          </div>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-user"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="childData.personal_details.surname"
              name="surname"
              placeholder="Surname"
            />
          </div>
        </div>
        <div>
          <div class="form-inline">
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="childData.personal_details.address"
                name="address"
                placeholder="Address"
              />
            </div>
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="childData.personal_details.suburb"
                name="suburb"
                placeholder="Suburb"
              />
            </div>
          </div>
          <div class="form-inline">
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <select
                class="custom-select form-control"
                [(ngModel)]="childData.personal_details.state"
                name="state"
                required
              >
                <option disabled selected>State*</option>
                <option value="ACT">Australian Capital Territory</option>
                <option value="NSW">New South Wales</option>
                <option value="NT">Northern Territory</option>
                <option value="QLD">Queensland</option>
                <option value="SA">South Australia</option>
                <option value="TAS">Tasmania</option>
                <option value="VIC">Victoria</option>
                <option value="WA">Western Australia</option>
              </select>
            </div>
            <div class="input-group col-md-6">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-map-marker"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="childData.personal_details.post_code"
                name="post_code"
                placeholder="Post code"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="form-horizontal">
        <div class="form-inline">
          <label class="control-label col-md-6">Nationality </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="childData.nationality"
              name="nationality"
            />
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">Child's DOB </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <input
              tabindex="15"
              class="form-control"
              [minDate]="{ year: 1950, month: 1, day: 1 }"
              name="childDOB"
              [(ngModel)]="childData.childDOB"
              placeholder="yyyy-mm-dd"
              ngbDatepicker
              #b="ngbDatepicker"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                (click)="b.toggle()"
                type="button"
              >
                <i class="fa fa-calendar"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">Religion </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="childData.religion"
              name="religion"
            />
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6 mt-3"
            >Calendar year of entry
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select
              class="custom-select form-control"
              [(ngModel)]="childData.entryYear"
              name="entryYear"
            >
              <option disabled selected>Select</option>
              <option
                *ngFor="let year of years; let i = index"
                value="{{ year }}"
              >
                {{ year }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6 mt-3"
            >Year / Grade of entry
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select
              class="custom-select form-control"
              [(ngModel)]="childData.gradeEntry"
              name="gradeEntry"
            >
              <option disabled selected>Select</option>
              <option>Kindergarten</option>
              <option>Year 1</option>
              <option>Year 2</option>
              <option>Year 3</option>
              <option>Year 4</option>
              <option>Year 5</option>
              <option>Year 6</option>
              <option>Year 7</option>
              <option>Year 8</option>
              <option>Year 9</option>
              <option>Year 10</option>
              <option>Year 11</option>
              <option>Year 12</option>
            </select>
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6"
            >Current School and year level (if applicable)</label
          >
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="childData.currentSchoolAndGrade"
              name="currentSchoolAndGrade"
            />
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6"
            >Previous School attended within Australia (if applicable)</label
          >
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="childData.prevSchool"
              name="prevSchool"
            />
          </div>
        </div>
        <br />
        <div class="form-inline">
          <label class="control-label col-md-6"
            >Background of student (Australian citzen, Aboriginal or Torres
            Strait Islander, Permanent Resident, Temporary Resident or Full fee
            Paying Overseas Student)</label
          >
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="childData.studentBg"
              name="studentBg"
            />
          </div>
        </div>
        <div class="form-inline">
          <label class="control-label col-md-6">Language spoken at Home</label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="childData.langSpoken"
              name="langSpoken"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3">
      <button
        type="submit"
        (click)="onReturn('childData', progBar)"
        [disabled]="!childDetailForm.valid"
        class="btn btn-outline-primary mb-3 col-5 col-md-2"
      >
        Back
      </button>
      <button
        type="submit"
        [disabled]="!childDetailForm.valid"
        class="btn mb-3 col-5 offset-2 col-md-2 offset-md-8"
      >
        Next
      </button>
    </div>
  </form>
  <!-- schoolReqData start -->
  <form
    *ngIf="isOnService"
    (ngSubmit)="onNext('schoolReqData', progBar)"
    #serviceDetailForm="ngForm"
  >
    <!--Facility Preferences Start -->
    <h3>School Requirements</h3>
    <div class="class card px-2 py-3 mb-3">
      <div class="form-inline">
        <label class="control-label col-md-6"
          >Preferred location of School (suburb, state)
        </label>
        <div class="input-group col-md-6 last-item">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fa fa-info-circle"></i>
            </span>
          </div>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="schoolReqData.location"
            name="location"
          />
        </div>
      </div>
      <div class="form-horizontal">
        <div class="form-inline">
          <label class="control-label col-md-6"
            >Acceptable distance from preferred location
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select
              class="custom-select form-control"
              [(ngModel)]="schoolReqData.distance"
              name="distance"
            >
              <option disabled selected>Select</option>
              <option>2 km</option>
              <option>5 km</option>
              <option>10 km</option>
              <option>Greater than 10 km</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-horizontal">
        <div class="form-inline">
          <label class="control-label col-md-6"
            >Education Level of School
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select
              class="custom-select form-control"
              [(ngModel)]="schoolReqData.educationLevel"
              name="educationLevel"
            >
              <option disabled selected>Select</option>
              <option>Primary</option>
              <option>Secondary</option>
              <option>Combined (primary and secondary program)</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-horizontal">
        <div class="form-inline">
          <label class="control-label col-md-6">Type of School </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select
              class="custom-select form-control"
              [(ngModel)]="schoolReqData.schoolType"
              name="schoolType"
            >
              <option disabled selected>Select</option>
              <option>Public</option>
              <option>Independent</option>
              <option>Catcholic</option>
              <option>
                Special interest high schools (sports, creative and performing
                arts, techonology or language)
              </option>
              <option>Special needs</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-horizontal">
        <div class="form-inline">
          <label class="control-label col-md-6"
            >Any specific requirements of School
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select
              class="custom-select form-control"
              [(ngModel)]="schoolReqData.schoolReq"
              name="schoolReq"
            >
              <option disabled selected>Select</option>
              <option>Gifted program</option>
              <option>Support program in literacy</option>
              <option>Support program in numeracy</option>
              <option>English as a second language</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-inline">
        <label class="control-label col-md-6"
          >Any specific requirements of School (other)
        </label>
        <div class="input-group col-md-6 last-item">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fa fa-info-circle"></i>
            </span>
          </div>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="schoolReqData.otherSchoolReq"
            name="otherSchoolReq"
          />
        </div>
      </div>
      <div class="form-inline">
        <label class="control-label col-md-6"
          >Does your child need any special provisions to enable them to over
          come any physical or intellectual learning difficulties or to cater
          for any special abilities they may have
        </label>
        <div class="input-group col-md-6 last-item">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fa fa-info-circle"></i>
            </span>
          </div>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="schoolReqData.specialProvisions"
            name="specialProvisions"
          />
        </div>
      </div>
      <br />
      <div class="form-inline">
        <label class="control-label col-md-6"
          >Do you have a preference or any schools in mind
        </label>
        <div class="input-group col-md-6 last-item">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fa fa-info-circle"></i>
            </span>
          </div>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="schoolReqData.schoolPreference"
            name="schoolPreference"
          />
        </div>
      </div>
      <div class="form-horizontal">
        <div class="form-inline">
          <label class="control-label col-md-6"
            >Any additional School requirements
          </label>
          <div class="input-group col-md-6">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-info-circle"></i>
              </span>
            </div>
            <select
              class="custom-select form-control"
              [(ngModel)]="schoolReqData.additionalSchoolReq"
              name="additionalSchoolReq"
            >
              <option disabled selected>Select</option>
              <option>Single sex (male / female)</option>
              <option>Co-educational</option>
              <option>Boarding school</option>
              <option>Sports facilities</option>
              <option>Language facilities</option>
              <option>Music program</option>
              <option>Creative and performance arts program</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-inline">
        <label class="control-label col-md-6"
          >Any additional School requirements (other)
        </label>
        <div class="input-group col-md-6 last-item">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fa fa-info-circle"></i>
            </span>
          </div>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="schoolReqData.otherAdditionalSchoolReq"
            name="otherAdditionalSchoolReq"
          />
        </div>
      </div>
    </div>
    <!--Additional Information Start -->
    <h3>Additional Information</h3>
    <div class="class card px-2 py-3 mb-3">
      <p class="my-3 mx-3">
        <strong
          >The following terms and conditions apply when providing assistance
          with locating a school:</strong
        >
      </p>
      <ol class="mb-0 mx-3">
        <li>
          SeventeenHundred has no agreements with any schools and at all times
          acts as an objective party.
        </li>
        <li>
          The final report will include a minimum of three (3) schools in your
          chosen areas.
        </li>
        <li>
          The final report will be provided within 7 days of receipt of all
          required information.
        </li>
        <li>
          It will be your responsibility to enrol if required. SeventeenHundred
          will not as part of the service enrol any children at any of the
          schools listed.
        </li>
        <li>
          SeventeenHundred cannot guarantee an available place for your child at
          any school.
        </li>
        <li>
          SeventeenHundred takes no responsibility if circumstances or ownership
          changes after the report has been provided and before you may enrol
          your child/children.
        </li>
      </ol>
      <!-- <div class="form-check mt-3 mx-3">
        <input type="checkbox" class="form-check-input" [(ngModel)]="schoolReqData.terms_and_conditions" name="terms_and_conditions"
          required>
        <label class="form-check-label">
          Please click to accept the terms and conditions
          <span class="required">*</span>
        </label>
      </div> -->
      <div class="custom-control custom-checkbox mt-3 mb-2 mx-3">
        <input
          type="checkbox"
          id="checkbox"
          class="custom-control-input"
          [(ngModel)]="schoolReqData.terms_and_conditions"
        />
        <label class="custom-control-label" for="checkbox"
          >Please click to accept the terms and conditions</label
        >
        <span class="text-danger">*</span>
      </div>
    </div>
    <div class="row px-3">
      <button
        type="submit"
        (click)="onReturn('schoolReqData', progBar)"
        [disabled]="!serviceDetailForm.valid"
        class="btn btn-outline-primary mb-3 col-5 col-md-2"
      >
        Back
      </button>
      <button
        type="submit"
        [disabled]="!serviceDetailForm.valid"
        class="btn mb-3 col-5 offset-2 col-md-2 offset-md-8"
      >
        Next
      </button>
    </div>
  </form>
  <!-- Review Start -->
  <div *ngIf="isOnReview">
    <h3>Applicant's Details</h3>
    <div class="card px-4 py-3 mb-3">
      <p class="row">
        <span class="col md-6 text-right">First name:</span>
        <span class="col md-6 text-left">{{
          applicantData.personal_details.firstname
        }}</span>
      </p>
      <p class="row">
        <span class="col md-6 text-right">Surname:</span>
        <span class="col md-6 text-left">{{
          applicantData.personal_details.surname
        }}</span>
      </p>
      <p class="row">
        <span class="col md-6 text-right">Address:</span>
        <span class="col md-6 text-left">{{
          applicantData.personal_details.address
        }}</span>
      </p>
      <p class="row">
        <span class="col md-6 text-right">Suburb:</span>
        <span class="col md-6 text-left">{{
          applicantData.personal_details.suburb
        }}</span>
      </p>
      <p class="row">
        <span class="col md-6 text-right">State:</span>
        <span class="col md-6 text-left">{{
          applicantData.personal_details.state
        }}</span>
      </p>
      <p class="row">
        <span class="col md-6 text-right">Postcode:</span>
        <span class="col md-6 text-left">{{
          applicantData.personal_details.post_code
        }}</span>
      </p>
      <p class="row">
        <span class="col md-6 text-right">Preferred phone number:</span>
        <span class="col md-6 text-left">{{
          applicantData.personal_details.phone
        }}</span>
      </p>
      <p class="row">
        <span class="col md-6 text-right">E-mail Address:</span>
        <span class="col md-6 text-left">{{
          applicantData.personal_details.email
        }}</span>
      </p>
    </div>
    <h3>Child's Details</h3>
    <div class="card px-4 py-3 mb-3" *ngIf="childData != null">
      <p class="row" *ngIf="childData.personal_details.firstname">
        <span class="col md-6 text-right">First name:</span>
        <span class="col md-6 text-left">{{
          childData.personal_details.firstname
        }}</span>
      </p>
      <p class="row" *ngIf="childData.personal_details.surname">
        <span class="col md-6 text-right">Surname:</span>
        <span class="col md-6 text-left">{{
          childData.personal_details.surname
        }}</span>
      </p>
      <p class="row" *ngIf="childData.personal_details.address">
        <span class="col md-6 text-right">Address:</span>
        <span class="col md-6 text-left">{{
          childData.personal_details.address
        }}</span>
      </p>
      <p class="row" *ngIf="childData.personal_details.suburb">
        <span class="col md-6 text-right">Suburb:</span>
        <span class="col md-6 text-left">{{
          childData.personal_details.suburb
        }}</span>
      </p>
      <p
        class="row"
        *ngIf="
          childData.personal_details.state != 'State*' &&
          childData.personal_details.state != ''
        "
      >
        <span class="col md-6 text-right">State:</span>
        <span class="col md-6 text-left">{{
          childData.personal_details.state
        }}</span>
      </p>
      <p class="row" *ngIf="childData.personal_details.post_code">
        <span class="col md-6 text-right">Postcode:</span>
        <span class="col md-6 text-left">{{
          childData.personal_details.post_code
        }}</span>
      </p>
      <p class="row" *ngIf="childData.nationality">
        <span class="col-md-6 text-right">Nationality:</span>
        <span class="col-md-6 text-left">{{ childData.nationality }}</span>
      </p>
      <p class="row" *ngIf="childData.childDOB">
        <span class="col-md-6 text-right">Child DOB:</span>
        <span class="col-md-6 text-left">{{
          formatDateDisplay(childData.childDOB)
        }}</span>
      </p>
      <p class="row" *ngIf="childData.religion">
        <span class="col-md-6 text-right">Religion:</span>
        <span class="col-md-6 text-left">{{ childData.religion }}</span>
      </p>
      <p class="row" *ngIf="childData.entryYear != 'Select'">
        <span class="col-md-6 text-right">Calendar year of entry:</span>
        <span class="col-md-6 text-left">{{ childData.entryYear }}</span>
      </p>
      <p class="row" *ngIf="childData.gradeEntry != 'Select'">
        <span class="col md-6 text-right">Year / Grade of entry:</span>
        <span class="col md-6 text-left">{{ childData.gradeEntry }}</span>
      </p>
      <p class="row" *ngIf="childData.currentSchoolAndGrade">
        <span class="col md-6 text-right"
          >Current School and year level (if applicable):</span
        >
        <span class="col md-6 text-left">{{
          childData.currentSchoolAndGrade
        }}</span>
      </p>
      <p class="row" *ngIf="childData.prevSchool">
        <span class="col md-6 text-right"
          >Previous School attended within Australia (if applicable):</span
        >
        <span class="col md-6 text-left">{{ childData.prevSchool }}</span>
      </p>
      <p class="row" *ngIf="childData.studentBg">
        <span class="col md-6 text-right"
          >Background of student (Australian citzen, Aboriginal or Torres Strait
          Islander, Permanent Resident, Temporary Resident or Full fee Paying
          Overseas Student):</span
        >
        <span class="col md-6 text-left">{{ childData.studentBg }}</span>
      </p>
      <p class="row" *ngIf="childData.langSpoken">
        <span class="col md-6 text-right">Language spoken at Home:</span>
        <span class="col md-6 text-left">{{ childData.langSpoken }}</span>
      </p>
    </div>

    <h3>School Requirements</h3>
    <div class="card px-4 py-3 mb-3">
      <p class="row" *ngIf="schoolReqData.location">
        <span class="col md-6 text-right"
          >Preferred location of School (suburb, state):</span
        >
        <span class="col md-6 text-left">{{ schoolReqData.location }}</span>
      </p>
      <p class="row" *ngIf="schoolReqData.distance != 'Select'">
        <span class="col md-6 text-right"
          >Acceptable distance from preferred location:</span
        >
        <span class="col md-6 text-left">{{ schoolReqData.distance }}</span>
      </p>
      <p class="row" *ngIf="schoolReqData.educationLevel != 'Select'">
        <span class="col md-6 text-right">Education Level of School:</span>
        <span class="col md-6 text-left">{{
          schoolReqData.educationLevel
        }}</span>
      </p>
      <p class="row" *ngIf="schoolReqData.schoolType != 'Select'">
        <span class="col md-6 text-right">Type of School:</span>
        <span class="col md-6 text-left">{{ schoolReqData.schoolType }}</span>
      </p>
      <p class="row" *ngIf="schoolReqData.schoolReq != 'Select'">
        <span class="col md-6 text-right"
          >Any specific requirements of School:</span
        >
        <span class="col md-6 text-left">{{ schoolReqData.schoolReq }}</span>
      </p>
      <p class="row" *ngIf="schoolReqData.otherSchoolReq">
        <span class="col md-6 text-right"
          >Any specific requirements of School (other)</span
        >
        <span class="col md-6 text-left">{{
          schoolReqData.otherSchoolReq
        }}</span>
      </p>
      <p class="row" *ngIf="schoolReqData.specialProvisions">
        <span class="col md-6 text-right"
          >Does your child need any special provisions to enable them to over
          come any physical or intellectual learning difficulties or to cater
          for any special abilities they may have:</span
        >
        <span class="col md-6 text-left">{{
          schoolReqData.specialProvisions
        }}</span>
      </p>
      <p class="row" *ngIf="schoolReqData.schoolPreference">
        <span class="col md-6 text-right"
          >Do you have a preference or any schools in mind:</span
        >
        <span class="col md-6 text-left">{{
          schoolReqData.schoolPreference
        }}</span>
      </p>
      <p class="row" *ngIf="schoolReqData.additionalSchoolReq != 'Select'">
        <span class="col md-6 text-right"
          >Any additional School requirements:</span
        >
        <span class="col md-6 text-left">{{
          schoolReqData.additionalSchoolReq
        }}</span>
      </p>
      <p class="row">
        <span
          class="col md-6 text-right"
          *ngIf="schoolReqData.otherAdditionalSchoolReq"
          >Any additional School requirements (other):</span
        >
        <span class="col md-6 text-left">{{
          schoolReqData.otherAdditionalSchoolReq
        }}</span>
      </p>
    </div>
    <h3>School Locator - Confirmation</h3>
    <div class="card px-4 py-3 mb-3">
      <p>
        Thank you for your submission to allow SeventeenHundred to assist you in
        locating a school for your child/ren. <br />
        We will commence work on finding appropriate schools, as soon as we have
        confirmed we have all the relevant information. <br />
        Should you have any questions before this time, please do not hesitate
        to contact our office on <a href="tel:1800258487">1800 258 487</a> or
        via email at:
        <a [href]="'mailto:' + helpEmail">{{ helpEmail }}</a>
      </p>
      <p>Kind regards,</p>
      <p>SeventeenHundred</p>
      <p>
        <strong>
          <em
            >Discalimer: SeventeenHundred cannot guarantee that the school of
            your choice has any availability.</em
          >
        </strong>
      </p>
    </div>
    <div class="row px-3">
      <button
        class="btn btn-outline-primary col-md-2 col-5 mb-3"
        (click)="onReturn('review', progBar)"
      >
        Fix Errors
      </button>
      <button
        type="submit"
        class="btn btn-primary col-md-2 col-5 mb-3 offset-md-8 offset-2"
        (click)="onSubmit()"
      >
        Submit
      </button>
    </div>
  </div>
</div>

<!-- Forms End -->
<div class="mt-3">
  <p>
    To read our Privacy Policy
    <a class="hyperlink" (click)="openPolicy('privacy')">click here</a>
  </p>
  <p>
    To read our Privacy Collection statement
    <a class="hyperlink" (click)="openPolicy('collection')">click here</a>
  </p>
</div>
