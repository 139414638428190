<app-cms-list-view [dataList]="items" [columnList]="columns" [isPreventListDelete] = "true" *ngIf="columns.length > 0"></app-cms-list-view>
<div *ngIf="items.length === 0 && error !== ''" class="alert alert-danger alert-dismissible fade show" role="alert">
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <strong>{{error}}</strong>
</div>
<div *ngIf="id > -1" class="padding jumbotron">
  <form name="form" class="form-horizontal" (ngSubmit)="f.form.valid && save()" #f="ngForm" autocomplete="off" novalidate>
    <div class="row m-0">
      <div class="col-12">
        <!--- title Block -->
        <div class="form-group">
          <label for="title">
            Title
            <span class="text-danger">*</span>
          </label>
          <input type="text" name="title" #title="ngModel" [(ngModel)]="fields.title" [ngClass]="(title.value && title.value.length < 2) || (title.invalid && f.submitted) ? 'form-control is-invalid' : 'form-control'"
            required />
          <div class="invalid-feedback">
            Title is required.
          </div>
        </div>
        <!--- Description Block -->
        <div class="form-group">
          <label for="description">
            Description
          </label>
          <textarea name="description" id="description" class="form-control" [(ngModel)]="fields.description"></textarea>
        </div>
        <!--- URL Block -->
        <div class="form-group">
          <label for="linkUrl">
            URL
            <span class="text-danger">*</span>
          </label>
          <input type="text" name="linkUrl" #linkUrl="ngModel" [(ngModel)]="fields.linkUrl" [ngClass]="(linkUrl.value && linkUrl.value.length < 4) || (linkUrl.invalid && f.submitted) ? 'form-control is-invalid' : 'form-control'"
            required />
          <div class="invalid-feedback">
            URL required.
          </div>
        </div>

        <!--- Company Block -->
        <!-- <div class="form-group" *ngIf="canSelectCompany">
          <app-select-company
            [allCompanies]="allCompanies"
            [selectedCompanyId]="fields.companyId"
            [label]="'Company'"
            [placeholder]="'All (default)'"
            (companyIdSelected)="onCompanySelected($event)"
          ></app-select-company>
        </div> -->

        <!-- Excluded Companies -->
        <!-- <div *ngIf="isAllCompanies()"
          class="form-group">
          <app-select-companies
            [allCompanies]="allCompanies"
            [selectedCompanyIds]="excludedCompanyIds"
            [label]="'Excluded Companies'"
            [placeholder]="'Select Company to exclude'"
            (companyIdsSelected)="excludedCompanyIdsSelected($event)"
          ></app-select-companies>
        </div> -->

        <div class='form-group' *ngIf='showIncludedCompanies()'>
          <multiple-select [allOptions]='allCompanies' *ngIf='canSelectCompany'
            [selectedOptionIds]='includedCompanyIds' [label]="'Companies'"
            [placeholder]="'-- Select company to include --'"
            (optionIdsSelected)='includedCompanyIdsSelected($event)'
            [addAllOption]='true' [allOptionLabel]='"All (default)"'>
          </multiple-select>        
        </div>

        <!-- Excluded Companies -->
        <div *ngIf='showExcludedCompanies()' class='form-group'>
          <multiple-select [allOptions]='allCompanies' *ngIf='canSelectCompany'
            [selectedOptionIds]='excludedCompanyIds' [label]="'Excluded Companies'"
            [placeholder]="'-- Select company to exclude --'"
            (optionIdsSelected)='excludedCompanyIdsSelected($event)'>
          </multiple-select> 
        </div>

        <!--- Visibility Block -->
        <div class="form-group">
          <label for="visibility">
            Visibility
            <span class="text-danger">*</span>
          </label>
          <br />
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="customRadioInline1" name="visibility" value="0" #visibility="ngModel" [ngClass]="visibility.invalid && f.submitted ? 'custom-control-input is-invalid' : 'custom-control-input'"
              [(ngModel)]="fields.visibility" required />
            <label class="custom-control-label" for="customRadioInline1">Guest</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="customRadioInline2" name="visibility" value="1" #visibility="ngModel" [ngClass]="visibility.invalid && f.submitted ? 'custom-control-input is-invalid' : 'custom-control-input'"
              [(ngModel)]="fields.visibility" required />
            <label class="custom-control-label" for="customRadioInline2">User</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="customRadioInline3" name="visibility" value="2" #visibility="ngModel" [ngClass]="visibility.invalid && f.submitted ? 'custom-control-input is-invalid' : 'custom-control-input'"
              [(ngModel)]="fields.visibility" required />
            <label class="custom-control-label" for="customRadioInline3">Both</label>
          </div>

          <div [class]="visibility.invalid && f.submitted ? 'invalid-feedback d-inline' : 'invalid-feedback'">
            <br /> Visibility is required.
          </div>
        </div>

        <!--- Image Block -->
        <div class="form-group">
          <label for="image">
            Image for desktop - upload as 1600x400 .jpg format. Image can be cropped in picker.
            <span class="text-danger">*</span>
          </label>
          <div class="input-group">
            <input type="text" placeholder="choose image" name="image" #image="ngModel" [(ngModel)]="fields.image" [ngClass]="(image.value && image.value.length < 5) || (image.invalid && f.submitted) ? 'form-control is-invalid' : 'form-control'"
              required readonly />
            <div class="input-group-prepend">
              <button class="input-group-text btn-primary" type="button" (click)="openFilestackForDesktopImage('image')">Browse</button>
            </div>
            <div class="invalid-feedback">
              Image is required.
            </div>
          </div>
        </div>
        <!-- mobile image -->
        <div class="form-group">
          <label for="mobileImage">
            Image for small screens (mobile) - upload as 900x600 .jpg format. Image can be cropped in picker.
            <span class="text-danger">*</span>
          </label>
          <div class="input-group">
            <input type="text" placeholder="choose image" name="mobileImage" #mobileImage="ngModel" [(ngModel)]="fields.mobileImage"
              [ngClass]="(mobileImage.value && mobileImage.value.length < 5) || (mobileImage.invalid && f.submitted) ? 'form-control is-invalid' : 'form-control'"
              required readonly />
            <div class="input-group-prepend">
              <button class="input-group-text btn-primary" type="button" (click)="openFilestackForMobileImage('mobileImage')">Browse</button>
            </div>
            <div class="invalid-feedback">
              Mobile image is required.
            </div>
          </div>
        </div>

        <div class="row">
          <!--- Start Date Block -->
          <div class="col-12 col-sm-6">
            <label for="startDate">Start Date
              <span class="text-danger">*</span>
            </label>
            <div class="input-group">
              <input name="startDate" placeholder="yyyy-mm-dd" #startDate="ngModel" [(ngModel)]="fields.startDate" [ngClass]="startDate.invalid && f.submitted? 'form-control is-invalid' : 'form-control'"
                [showWeekdays]="false" [minDate]="minDate" #sd="ngbDatepicker" ngbDatepicker required/>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="sd.toggle()" type="button">
                  <i class="fa fa-calendar"></i>
                </button>
              </div>
              <div class="invalid-feedback">
                Start Date invalid.
              </div>
            </div>
          </div>
          <!--- End Date Block -->
          <div class="col-12 col-sm-6">
            <label for="endDate">End Date
              <span class="text-danger">*</span>
            </label>
            <div class="input-group">
              <input name="endDate" #endDate="ngModel" [(ngModel)]="fields.endDate" [ngClass]="f.submitted && endDate.invalid ? 'form-control is-invalid' : 'form-control'"
                placeholder="yyyy-mm-dd" [showWeekdays]="false" [minDate]="startDate.value" #ed="ngbDatepicker" ngbDatepicker
                required />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="ed.toggle()" type="button">
                  <i class="fa fa-calendar"></i>
                </button>
              </div>
              <div class="invalid-feedback">
                End Data invalid.
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <!--- Order Block -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="order">
                Order
                <span class="text-danger">*</span>
              </label>
              <input type="number" name="order" min="1" #order="ngModel" [(ngModel)]="fields.order" [ngClass]="order.invalid && f.submitted ? 'form-control is-invalid' : 'form-control'"
                required />
              <div class="invalid-feedback">
                Order is required
              </div>
            </div>
          </div>
          <!--- Active Block -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="active">
                Display
                <span class="text-danger">*</span>
              </label>
              <br />
              <div class="custom-control custom-radio custom-control-inline mt-2">
                <input type="radio" id="active1" name="active" value="true" #active="ngModel" [ngClass]="active.invalid && f.submitted ? 'custom-control-input is-invalid' : 'custom-control-input'"
                  [(ngModel)]="fields.active" required />
                <label class="custom-control-label" for="active1">Yes</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="active2" name="active" value="false" #active="ngModel" [ngClass]="active.invalid && f.submitted ? 'custom-control-input is-invalid' : 'custom-control-input'"
                  [(ngModel)]="fields.active" required />
                <label class="custom-control-label" for="active2">No</label>
              </div>
              <div [class]="active.invalid && f.submitted ? 'invalid-feedback d-inline' : 'invalid-feedback'">
                <br /> Display is required.
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <hr />
    <div class="previewButton pb-3 pt-1">
      <button type="button" class="btn btn-primary btn-circle" data-toggle="tooltip" data-placement="top" title="Preview" [disabled]="!f.form.valid" (click)="showPreview(f)">
        <div class="text">
          <i class="fa fa-eye fa-2x" aria-hidden="true"></i>
          <p>Preview</p>
        </div>
      </button>
    </div>
    <!--- Buttons Block -->
    <div class="row">
      <div [class]="id !== 0 ? 'col-4' : 'col-6'">
        <button type="button" class="btn btn-outline-primary w-100" (click)="goToList()" [disabled]="loading">
          <i class="fa fa-undo mr-2" aria-hidden="true"></i> BACK
        </button>
      </div>
      <div class="col-4" *ngIf="id !== 0">
        <button type="button" class="btn btn-outline-primary w-100" (click)="delete()" [disabled]="loading">
          <i class="fa fa-trash mr-2" aria-hidden="true"></i> DELETE
        </button>
      </div>
      <div [class]="id !== 0 ? 'col-4' : 'col-6'">
        <button type="submit" class="btn btn-primary float-right w-100" [disabled]="loading">
          <i class="fa fa-floppy-o mr-2" aria-hidden="true"></i> SAVE</button>
      </div>

    </div>
  </form>

</div>
