import { Component, EventEmitter, OnInit, Output, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../../../../services/user-service';
import { UtilService } from '../../../../services/util-service';

@Component({
  selector: 'app-group-list-card',
  templateUrl: './group-list-card.component.html',
  styleUrls: ['./group-list-card.component.scss']
})
export class GroupListCardComponent implements OnInit, OnChanges {
  @Input() group;
  @Input() isMember;
  @Input() newPostCount;

  @Output() view: EventEmitter<any> = new EventEmitter<any>();
  @Output() join: EventEmitter<any> = new EventEmitter<any>();

  groupLogoSrc: string;
  groupMembersCount: number;

  constructor(
    private userService: UserService,
    private utilService: UtilService,
    public router: Router
  ) {}

  ngOnInit() {
    this.groupLogoSrc = this.userService.getGroupsLogo(this.group.logo);
    this.groupMembersCount = +this.group.membersCount;
  }

  ngOnChanges(changes: SimpleChanges) {
    const changedIsMember: SimpleChange = changes.isMember;
    if (changedIsMember && changedIsMember.currentValue && !changedIsMember.firstChange) {
      this.groupMembersCount++;
    }
  }

  pluralizeInnerHTML(count: number): string {
    return this.utilService.pluralizeInnerHTML(
      'font-weight-bold', count, 'member', 'members');
  }

  clickView() {
    this.view.emit(true);
  }

  clickJoin(e) {
    e.preventDefault();
    e.stopPropagation();

    this.join.emit(true);
  }
}
