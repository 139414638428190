<div class="modal-header">
  <h4 class="modal-title">{{goalTitle}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="goalForm">
  <div class="modal-body">
    <p> Current: {{goalDisplay}}</p>
    <div class="row">
      <label class="col-sm-6">{{newValueTitle}}
      </label>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <select class="form-control m-t-1rem" (click)="onOperandClick()" formControlName="operandControl">
          <option *ngFor="let operand of operands" [ngValue]="operand">{{tmhService.getOperandDisplay(operand)}}</option>
        </select>
      </div>

      <div *ngIf="!isSleepIndicator" class="col-sm-6">
        <input
          [ngClass]="{'has-error': !goalForm.get('lowerValueControl').valid || goalForm.errors?.range }"
          class="form-control m-t-1rem"
          type="number"
          autofocus
          formControlName="lowerValueControl">
      </div>

      <div *ngIf="isSleepIndicator" class="col-sm-6 m-t-1rem">
          <input
            class="form-control hours-minutes-input"
            [ngClass]="{'has-error': !goalForm.get('lowerValueHoursControl').valid || goalForm.errors?.range }"
            type="number"
            (keyup)="onLowerValueHoursControlKeyUp()"
            formControlName="lowerValueHoursControl"
          />
          <span class="hrs-min-separator">:</span>
          <input
            class="form-control hours-minutes-input"
            [ngClass]="{'has-error': goalForm.errors?.range }"
            type="number"
            (keyup)="onLowerValueMinutesControlKeyUp()"
            formControlName="lowerValueMinutesControl"
          />

      </div>
    </div>

    <!-- Upper Values -->
      <div *ngIf="isBetweenOperand(goalForm.get('operandControl').value)" class="row m-t-1rem">
        <label class="col-sm-6 text-center">and</label>
        <div *ngIf="isBetweenOperand(goalForm.get('operandControl').value) && !isSleepIndicator" class="col-sm-6">
          <input class="form-control" [ngClass]="{'has-error': !goalForm.get('upperValueControl').valid || goalForm.errors?.range }"
            type="number" formControlName="upperValueControl">
         </div>

         <div *ngIf="isBetweenOperand(goalForm.get('operandControl').value) && isSleepIndicator" class="col-sm-6">
            <input
              class="form-control hours-minutes-input"
              [ngClass]="{'has-error': !goalForm.get('upperValueHoursControl').valid ||  goalForm.errors?.range }"
              type="number"
              (keyup)="onUpperValueHoursControlKeyUp()"
              formControlName="upperValueHoursControl"
            />
            <span class="hrs-min-separator">:</span>
            <input
              class="form-control hours-minutes-input"
              [ngClass]="{'has-error': goalForm.errors?.range }"
              type="number"
              (keyup)="onUpperValueMinutesControlKeyUp()"
              formControlName="upperValueMinutesControl"
            />
              </div>
          </div>
  </div>

    <div class="footerButtons p-3">
      <button type="button" [disabled]="!goalForm.valid" (click)="save()" class="btn btn-primary">Save</button>
      <button type="button" (click)="close()" class="btn btn-outline-primary">Close</button>
    </div>
</form>
