import { Injectable } from '@angular/core';
import ISO6391 from 'iso-639-1';

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_SORT_INDEX = -1;
const DEFAULT_SORT_TYPE = 0;

@Injectable({
  providedIn: 'root',
})
export class CmsListViewService {
  private currentUrl = '';
  private searches: { key: string; value: string }[] = [];
  private currentPages: { key: string; value: number }[] = [];
  private sortColumnIndexes: { key: string; value: number }[] = [];
  private sortColumnTypes: { key: string; value: number }[] = [];

  constructor() {
  }

  setCurrentUrl(url: string) {
    this.currentUrl = url;
  }

  getSearch(): string {
    if (!this.currentUrl) {
      return null;
    }

    const search = this.searches.find((i) => i.key === this.currentUrl);
    if (!search) {
      return null;
    }

    return search.value;
  }

  setSearch(value: string) {
    let search = this.searches.find((i) => i.key === this.currentUrl);

    if (!search) {
      search = { key: this.currentUrl, value: value };
      this.searches.push(search);
    } else {
      search.value = value;
    }
  }

  getCurrentPage(): number {
    if (!this.currentUrl) {
      return DEFAULT_PAGE_NUMBER;
    }

    const currentPage = this.currentPages.find((i) => i.key === this.currentUrl);
    if (!currentPage) {
      return DEFAULT_PAGE_NUMBER;
    }

    return currentPage.value;
  }

  setCurrentPage(value: number) {
    let currentPage = this.currentPages.find((i) => i.key === this.currentUrl);

    if (!currentPage) {
      currentPage = { key: this.currentUrl, value: value };
      this.currentPages.push(currentPage);
    } else {
      currentPage.value = value;
    }
  }

  getSortColumnIndex(): number {
    if (!this.currentUrl) {
      return DEFAULT_SORT_INDEX;
    }

    const sortColumnIndex = this.sortColumnIndexes.find((i) => i.key === this.currentUrl);
    if (!sortColumnIndex) {
      return DEFAULT_SORT_INDEX;
    }

    return sortColumnIndex.value;
  }

  setSortColumnIndex(value: number) {
    let sortColumnIndex = this.sortColumnIndexes.find((i) => i.key === this.currentUrl);
    if (!sortColumnIndex) {
      sortColumnIndex = { key: this.currentUrl, value: value };
      this.sortColumnIndexes.push(sortColumnIndex);
    } else {
      sortColumnIndex.value = value;
    }
  }

  getSortColumnType(): number {
    if (!this.currentUrl) {
      return DEFAULT_SORT_TYPE;
    }

    const sortColumnType = this.sortColumnTypes.find((i) => i.key === this.currentUrl);
    if (!sortColumnType) {
      return DEFAULT_SORT_TYPE;
    }

    return sortColumnType.value;
  }

  setSortColumnType(value: number) {
    let sortColumnType = this.sortColumnTypes.find((i) => i.key === this.currentUrl);
    if (!sortColumnType) {
      sortColumnType = { key: this.currentUrl, value: value };
      this.sortColumnTypes.push(sortColumnType);
    } else {
      sortColumnType.value = value;
    }
  }

  getLanguagesColumn(item): string {
    let array = [];
    if (item.languageVersion) {
      const languageData = JSON.parse(item.languageVersion);
      array = languageData.map(o => ISO6391.getName(o.code));
    }
    if (item.title) {
      array.unshift('English');
    }

    return array.join(', ');
  }

  getTitleColumn(item): string {
    if (item.title) {
      return item.title;
    } else {
      try {
        const languageData = JSON.parse(item.languageVersion);
        return languageData[0].obj.title;
      } catch (e) {
        console.log(e);
        return '';
      }
    }
  }
}
