<div class='widget-card p-0 m-0'>
  <div class='widget-card-header' [style.backgroundColor]='primaryColor'>
    Download the app
  </div>
  <div class='w-100 text-center'>
    <div class='p-2'>
      <img
        alt='download app links'
        class='w-100'
        src='assets/img/widgets/download_altiuslife.png'
      />
    </div>
    <div class='row'>
      <div class='col-6 pb-2'>
        <a [href]='appleHref' class='btn btn-link-lg pl-2' target='_blank'>
          <img [src]='appleImageSrc' alt='apple link' class='img-fluid' />
        </a>
      </div>
      <div class='col-6'>
        <a [href]='androidHref' class='btn btn-link-lg pr-2' target='_blank'>
          <img [src]='androidImageSrc' alt='android link' class='img-fluid' />
        </a>
      </div>
    </div>
  </div>
</div>
