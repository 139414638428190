import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../../services/util-service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { FeatureService } from '../../../services/feature-service';
import { IIdNamePair } from '../../_models/id-name-pair';
import { IConnection } from '../../_models/connection';
import { ConnectionService, IConnectionResponse } from '../../../services/connection-service';
import { CompanyService } from '../../../services/company-service';
import { ICompany } from '../../_models/company';
import { UserService } from '../../../services/user-service';

@Component({
  selector: 'app-company-connection-management',
  templateUrl: './connection-management.component.html',
  styleUrls: ['./connection-management.component.scss'],
})
export class ConnectionManagementComponent implements OnInit {
  items: IConnection[];
  item: IConnection;
  columns = [];
  itemForm: FormGroup;
  isInitialised: boolean;
  isPreventListDelete: boolean;
  isDisplayingList: boolean;
  isEditingItem: boolean;
  isAddingItem: boolean;
  canRespondToButtons: boolean;
  companies: ICompany[];
  allCompanies: IIdNamePair[] = [];
  companyId: number;
  canDelete = false;
  showConnectionExistedError: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private itemService: ConnectionService,
    private featureService: FeatureService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private modalService: NgbModal,
    private companyService: CompanyService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.companyService.forceRefreshCache();

    this.activatedRoute.params.subscribe((route) => {
      this.analyseRoute(route);

      if (this.isDisplayingList) {
        this.getList();
      } else if (this.isEditingItem) {
        this.initialiseEditItem(route.id);
      } else if (this.isAddingItem) {
        this.initialiseAddItem();
      }
    });
  }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isEditingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isEditingItem = true;
    }
  }

  private getList() {
    let companyId = this.companyService.getCompanyId();
    this.companyId = companyId ? parseInt(companyId) : this.userService.company.id;
    this.getConnectionList(this.companyId);

    this.companyService.getAllCompanies()
      .subscribe((data: ICompany[]) => {
        let item: any = { id: 0, name: 'All', isActive: true };
        data.push(item);
        this.companies = data;
        this.allCompanies = this.companies.map(c => {
          return { id: c.id, name: c.name + this.getCompanyNameSuffix(c) };
        });
        this.companyService.removeCompanyId();
      });
  }

  private getConnectionList(companyId: number) {
    this.itemService.getConnectionByCompanyId(companyId)
      .subscribe(data => {
          this.items = data.map(i => {
            return [i.id, i.name, i.buttonLabel, i.buttonColor, i.buttonOrder, i.enabled];
          });
          this.columns = ['id', 'name', 'buttonLabel', 'buttonColor', 'buttonOrder', 'enabled'];
          this.isInitialised = true;
          this.canRespondToButtons = true;
        },
        error => this.utilService.showToastError('Error while loading company connections - ' + error));
  }

  private getCompanyNameSuffix(company: ICompany): string {
    if (company.isActive) {
      return '';
    }

    return ' ---- (INACTIVE)';
  }

  private initialiseAddItem(): void {
    this.item = this.itemService.getNewConnection();
    this.initialiseEditableFormGroup(this.item);
    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  private initialiseEditItem(id: number): void {
    this.itemService
      .getConnectionById(id)
      .subscribe((data) => {
        this.item = data;
        this.initialiseEditableFormGroup(data);
        this.isInitialised = true;
        this.canRespondToButtons = true;
      });
  }

  private initialiseEditableFormGroup(item) {
    this.companies = this.companyService.getAllCachedCompanies();
    if (!this.companies.length) {
      this.companyService.getAllCompanies().subscribe(data => this.companies = data);
    }
    this.canDelete = true;
    this.companyService.setCompanyId(item.companyId);

    this.itemForm = this.fb.group({
      nameControl: [item.name, Validators.required],
      companyIdControl: [item.companyId, Validators.required],
      buttonLabelControl: [item.buttonLabel, Validators.required],
      buttonColorControl: [item.buttonColor, [Validators.required, this.setHexColorControlValidator()]],
      buttonOrderControl: [item.buttonOrder, Validators.compose([Validators.required, Validators.pattern(new RegExp('^[1-9]\\d*$'))])],
      enableControl: [item.enabled, Validators.required],
    });
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach(field => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  changeColor(field, color) {
    let patchValueObject = {};
    patchValueObject[field] = color;
    return this.itemForm.patchValue(patchValueObject);
  }

  private setHexColorControlValidator() {
    return Validators.pattern(this.utilService.validateHexColor());
  }

  private navigateToList() {
    this.router.navigate(['/cms/connections']);
  }

  onCompanySelected(companyId: number) {
    this.companyId = companyId;
    this.getConnectionList(companyId);
  }

  onCompanyChange(companyId: any) {
    if (companyId == 'null') {
      this.itemForm.get('companyIdControl').setErrors({ invalidCompanySelected: true });
    } else {
      const company = this.companyService.getCompanyFromCache(companyId);
      if (company) {
        this.itemForm.get('buttonColorControl').setValue(company.primaryColor);
      }
    }
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Delete connection';
    modal.componentInstance.message = 'Confirm deleting this connection';
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(isConfirmed => {
      if (!isConfirmed) {
        return;
      }

      this.itemService.deleteConnection(this.item.id)
        .subscribe(() => {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`Connection ${this.item.name} - deleted`);
            this.navigateToList();
          },
          error => this.utilService.showToastError('Error while deleting item - ' + error));
    }, () => {
    });
  }

  private persistItem() {
    this.showConnectionExistedError = false;
    this.canRespondToButtons = false;
    let companyId = this.itemForm.get('companyIdControl').value;
    this.companyService.setCompanyId(companyId);
    let itemToPersist: IConnection;

    try {
      itemToPersist = {
        id: this.item.id,
        name: this.itemForm.get('nameControl').value.trim(),
        companyId: companyId,
        buttonLabel: this.itemForm.get('buttonLabelControl').value,
        buttonColor: this.itemForm.get('buttonColorControl').value,
        buttonOrder: this.itemForm.get('buttonOrderControl').value,
        enabled: this.itemForm.get('enableControl').value,
      };
    } catch (error) {
      this.utilService.showToastError('Error persisting item - ' + error);
      this.canRespondToButtons = true;
      return;
    }

    if (this.isEditingItem) {
      this.updateItem(itemToPersist);
    } else if (this.isAddingItem) {
      this.addNewItem(itemToPersist);
    }
  }

  private updateItem(itemToPersist: IConnection) {
    this.itemService.updateConnection(itemToPersist)
      .subscribe(() => {
          this.itemForm.reset();
          this.canRespondToButtons = true;
          this.utilService.showToastSuccess(`Updated connection - ${itemToPersist.name}`);
          this.navigateToList();
        },
        error => {
          this.utilService.showToastError(`Error while saving item - ${error}`);
          this.canRespondToButtons = true;
        });
  }

  private addNewItem(itemToPersist: IConnection) {
    this.itemService.addConnection(itemToPersist)
      .subscribe((res: IConnectionResponse) => {
          this.canRespondToButtons = true;
          if (res.connectionExistedError) {
            this.showConnectionExistedError = true;
          } else {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`Added new connection - ${itemToPersist.name}`);
            this.navigateToList();
          }
        },
        error => {
          this.utilService.showToastError(`Error while adding item - ${error}`);
          this.canRespondToButtons = true;
        });
  }

  onBackClick() {
    this.navigateToList();
  }

  onDeleteClick() {
    if (!this.isEditingItem) {
      return;
    }

    this.deleteItem();
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }

    this.persistItem();
  }
}
