<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div *ngIf="!isContent" class="modal-body">
  <p *ngIf="message && message != '' && !messageWarning" [innerHTML]="message" class="mb-2"></p>
  <p class="warning" *ngIf="message && message != '' && messageWarning" [innerHTML]="message" class="mb-2"></p>
  <div *ngIf="badgeName" class="text-center">
    <h5>{{badgeName}}</h5>
    <img class="dialog-image" src="{{badgeImage}}" height="100px">
    <p class="bold">{{badgeDescription}}</p>
  </div>
  <div *ngIf="nextProgram">
    <p>{{preNextProgramText}}</p>
    <div *ngIf="nextProgram.isUserInProgram" class="card w-100 p-3 mt-2 cursorHand d-inline-block" (click)="openProgram(nextProgram)">
      <div class="progress-label backPrimary">
        {{nextProgram.statusName}}
      </div>
      <div class="row">
        <div class="col-12 col-sm-3 text-center">
          <img class="packImg" [src]="nextProgram.imageSource" />
        </div>
        <div class="col-12 col-sm-9">
          <div class="titleAndProgress">
            <h5 class="card-title primaryColor">{{nextProgram.title}}</h5>
            <ngb-progressbar type="success" [value]="nextProgram.progressPercent" [showValue]="true" height="20px" class="mt-4">
            </ngb-progressbar>
          </div>
          <div *ngIf="nextProgram.statusName === 'Scheduled'">
            <p padding-horizontal class="scheduledText">Program will start on: {{nextProgram.userProgramStartDate | date: 'dd/MM/yyyy'}}
          </div>
          <p class="card-text" [innerHTML]="nextProgram.description"></p>
        </div>
      </div>
    </div>
    <div *ngIf="!nextProgram.isUserInProgram" class="card w-100 p-3 mt-2 cursorHand d-inline-block">
      <div class="row">
        <div class="col-12 col-sm-3 text-center">
          <img class="packImg" [src]="nextProgram.imageSource" />
        </div>
        <div class="col-12 col-sm-9">
          <div class="titleAndProgress">
            <h5 class="card-title primaryColor">{{nextProgram.title}}</h5>
          </div>
          <p class="card-text" [innerHTML]="nextProgram.description"></p>
          <button *ngIf="canJoinProgram(nextProgram)" type="button" class="btn btn-primary w-100" (click)="joinProgram(nextProgram, $event)">Join Program</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="value1" class="mb-3">
    <div> {{value1.label}}
      <span *ngIf="value1.required" class="text-danger">*</span>
    </div>
    <input id="value1" class="form-control" [(ngModel)]="value1.value" type="{{value1.type}}">
    <div *ngIf="error==1" class="text-danger"> Please enter a valid value</div>
  </div>
  <div *ngIf="value2 && value2.label" class="mb-3">
    <div> {{value2.label}}
      <span *ngIf="value2.required" class="text-danger">*</span>
    </div>
    <input id="value2" class="form-control" [(ngModel)]="value2.value" type="{{value2.type}}">
    <div *ngIf="error==2" class="text-danger"> Please enter a valid value</div>
  </div>
  <div *ngIf="date && date.label" class="mb-3">
    <div> {{date.label}}
      <span *ngIf="date.required" class="text-danger">*</span>
    </div>
    <div class="input-group" *ngIf="dateNow && todayIsMax">
      <input class="form-control" id="dateInput" [(ngModel)]="date.value" navigation="arrows" [maxDate]="dateNow" placement="bottom"
        [showWeekdays]="false" ngbDatepicker #d="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
          <i class="fa fa-calendar"></i>
        </button>
      </div>
    </div>
    <div class="input-group mt-2" *ngIf="dateNow && !todayIsMax && startDayOfWeek === 0">
      <input class="form-control" id="dateInput" [(ngModel)]="date.value" (ngModelChange)="changeProgramDateInfo($event)" navigation="arrows"
        placement="bottom" [showWeekdays]="false" ngbDatepicker #d="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
          <i class="fa fa-calendar"></i>
        </button>
      </div>
    </div>
    <div class="input-group mt-2" *ngIf="dateNow && !todayIsMax && startDayOfWeek === 1">
      <input class="form-control" id="dateInput" [(ngModel)]="date.value" (ngModelChange)="changeProgramDateInfo($event)" navigation="arrows"
        placement="bottom" [markDisabled]="isDisabled1" [showWeekdays]="false" ngbDatepicker #d="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
          <i class="fa fa-calendar"></i>
        </button>
      </div>
    </div>
    <div class="input-group mt-2" *ngIf="dateNow && !todayIsMax && startDayOfWeek === 2">
      <input class="form-control" id="dateInput" [(ngModel)]="date.value" (ngModelChange)="changeProgramDateInfo($event)" navigation="arrows"
        placement="bottom" [markDisabled]="isDisabled2" [showWeekdays]="false" ngbDatepicker #d="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
          <i class="fa fa-calendar"></i>
        </button>
      </div>
    </div>
    <div class="input-group mt-2" *ngIf="dateNow && !todayIsMax && startDayOfWeek === 3">
      <input class="form-control" id="dateInput" [(ngModel)]="date.value" (ngModelChange)="changeProgramDateInfo($event)" navigation="arrows"
        placement="bottom" [markDisabled]="isDisabled1" [showWeekdays]="false" ngbDatepicker #d="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
          <i class="fa fa-calendar"></i>
        </button>
      </div>
    </div>
    <div class="input-group mt-2" *ngIf="dateNow && !todayIsMax && startDayOfWeek === 4">
      <input class="form-control" id="dateInput" [(ngModel)]="date.value" (ngModelChange)="changeProgramDateInfo($event)" navigation="arrows"
        placement="bottom" [markDisabled]="isDisabled4" [showWeekdays]="false" ngbDatepicker #d="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
          <i class="fa fa-calendar"></i>
        </button>
      </div>
    </div>
    <div class="input-group mt-2" *ngIf="dateNow && !todayIsMax && startDayOfWeek === 5">
      <input class="form-control" id="dateInput" [(ngModel)]="date.value" (ngModelChange)="changeProgramDateInfo($event)" navigation="arrows"
        placement="bottom" [markDisabled]="isDisabled5" [showWeekdays]="false" ngbDatepicker #d="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
          <i class="fa fa-calendar"></i>
        </button>
      </div>
    </div>
    <div class="input-group mt-2" *ngIf="dateNow && !todayIsMax && startDayOfWeek === 6">
      <input class="form-control" id="dateInput" [(ngModel)]="date.value" (ngModelChange)="changeProgramDateInfo($event)" navigation="arrows"
        placement="bottom" [markDisabled]="isDisabled6" [showWeekdays]="false" ngbDatepicker #d="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
          <i class="fa fa-calendar"></i>
        </button>
      </div>
    </div>
    <div class="input-group mt-2" *ngIf="dateNow && !todayIsMax && startDayOfWeek === 7">
      <input class="form-control" id="dateInput" [(ngModel)]="date.value" (ngModelChange)="changeProgramDateInfo($event)" navigation="arrows"
        placement="bottom" [markDisabled]="isDisabled7" [showWeekdays]="false" ngbDatepicker #d="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
          <i class="fa fa-calendar"></i>
        </button>
      </div>
    </div>
    <p *ngIf="programDateInfoText" class="pt-2">
      <i class="fa fa-info-circle primaryColor pr-2"></i>{{programDateInfoText}}</p>
    <div *ngIf="error==3" class="text-danger"> Please enter a valid date</div>
  </div>
  <div *ngIf="endDate && endDate.label" class="mb-3">
    <div> {{endDate.label}}
      <span *ngIf="endDate.required" class="text-danger">*</span>
    </div>
    <input id="dateInput2" [(ngModel)]="endDate.value" type="date" [max]="day">
    <div *ngIf="error==3" class="text-danger"> Please enter a valid date</div>
  </div>
  <!-- toggles are for join program modal -->
  <div *ngIf="toggles">
    <p [innerHTML]="toggleMessage" class="mb-2"></p>
    <form class="notifications mb-3" [formGroup]="toggleForm">
      <div *ngFor="let toggle of togglesOn.controls; let i = index" class="columns">
        <div>
          <p> {{toggles[i]}}
          </p>
        </div>
        <div class="inner">
          <small>OFF </small>
          <label class="switch mx-1" for="{{toggles[i]}}">
            <input id="{{toggles[i]}}" type="checkbox" [formControl]="toggle" />
            <span class="slider round"></span>
          </label>
          <small> ON</small>
        </div>
      </div>
    </form>
  </div>
  <!-- secondary email option is for join program modal -->
  <div *ngIf="isProgram">
    <p>Please select an email contact: </p>
    <div class="email">
      <div class="custom-control custom-radio">
        <input type="radio" id="primaryRadio" class="custom-control-input" [formControl]="emailSelection" value="primary" checked="true">
        <label class="custom-control-label" for="primaryRadio">
          <p>Primary email:
            <b>{{userService.user.email}}</b>
          </p>
        </label>
      </div>
      <div *ngIf="secondaryEmail" class="custom-control custom-radio">
        <input id="secondaryRadio" type="radio" class="custom-control-input" [formControl]="emailSelection" value="secondary">
        <label class="custom-control-label" for="secondaryRadio">
          <p>Secondary email:
            <b>{{secondaryEmail}}</b>
          </p>
        </label>
      </div>
    </div>
    <input [formControl]="secondaryEmailFormControl" class="form-control" *ngIf="showSecondaryEmailInput" name="secondaryEmail">
    <div *ngIf="secondaryEmailFormControl?.dirty && secondaryEmailFormControl?.errors">
      Email address is not valid.
    </div>
    <button [disabled]="secondaryEmailFormControl?.dirty && secondaryEmailFormControl?.errors" *ngIf="showSecondaryEmailButton"
      type="button" class="btn btn-primary btn-sm mt-2" (click)="secondaryEmailInput()">
      <i class="fa fa-envelope fa-sm" aria-hidden="true"></i>
      {{secondaryEmailButtonText}} </button>
  </div>

  <div *ngIf="radios && radios.length > 0" class="mb-3 ">
    <div class="custom-control custom-radio">
      <input id="radio1" [attr.disabled]="radios[0].disabled" class="custom-control-input" [(ngModel)]="radios.value" value="{{radios[0].value}}"
        type="radio">
      <label class="custom-control-label" [attr.disabled]="radios[0].disabled" for="radio1">{{radios[0].label}}</label>
    </div>
    <br />
    <div class="custom-control custom-radio">
      <input id="radio2" [attr.disabled]="radios[1].disabled" class="custom-control-input" [(ngModel)]="radios.value" value="{{radios[1].value}}"
        type="radio">
      <label class="custom-control-label" for="radio2" [attr.disabled]="radios[1].disabled">{{radios[1].label}}
      </label>
    </div>
  </div>
</div>

<div class="footerButtons p-3" *ngIf="!singleButton">
  <button type="button" (click)="onNoClick()" class="btn btn-outline-primary">
    {{closeButtonLabel ? closeButtonLabel : 'Close'}}
  </button>
  <button type="button" (click)="save()" class="btn btn-primary">{{confirmButtonLabel}}</button>

</div>
<div class="footerButtons p-3" *ngIf="singleButton">
  <button type="button" (click)="onNoClick()" class="btn btn-outline-primary w-100">
    {{closeButtonLabel ? closeButtonLabel : 'Close'}}
  </button>
</div>
