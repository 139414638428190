<div class="widget-card">
  <div class="widget-card-header backPrimary">
    Download the app
  </div>
  <div class="w-100 text-center">
    <div class="p-2">
      <img *ngIf="is1700"
        src="assets/img/widgets/download.png"
        alt="download app links"
        class="w-100"
      />
      <img *ngIf="isAltiusLife"
        src="assets/img/widgets/download_altiuslife.png"
        alt="download app links"
        class="w-100"
      />
      <img *ngIf="isBFFLife"
        src="assets/img/widgets/download.png"
        alt="download app links"
        class="w-100"
      />
    </div>
    <div class="row">
      <div class="col-6 pb-2">
        <a [href]="appleHref" target="_blank" class="btn btn-link-lg pl-2">
          <img [src]="appleImageSrc" alt="apple link" class="img-fluid" />
        </a>
      </div>
      <div class="col-6">
        <a [href]="androidHref" target="_blank" class="btn btn-link-lg pr-2">
          <img [src]="androidImageSrc" alt="android link" class="img-fluid" />
        </a>
      </div>
    </div>
  </div>
</div>
