<div class="container">
  <h2>Subscription Options</h2>
  <p>Please choose from one of the following subscription plans to access the platform.</p>
  <div class="list-group">
    <li *ngFor="let plan of stripePlans$ | async; let i = index"
      class="list-group-item list-group-item-action flex-column align-items-start"
      (click)="onStripePlanClicked(plan, i)" [class.active]="selectedPlanIndex === i">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="plan-type">{{plan.type}}</h5>
        <h5 class="price-details">{{plan.priceDetails}}</h5>
      </div>

      <div *ngFor="let benefit of plan.benefits">
        + {{benefit}}
      </div>
    </li>
  </div>
  <div>
    <button type="submit" class="btn btn-primary btn-block" (click)="openStripe()">Complete Payment</button>
  </div>
</div>
