import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { UserService } from '../../services/user-service';
import { AuthService } from '../../services/auth-service';

import { environment } from '../../environments/_active-environment/environment';

import { AccountComponent } from '../../app/account/account.component';
import { PreferencesComponent } from '../../components/preferences/preferences.component';
import { NotificationManageComponent } from '../../app/_shared/components/notification-manage/notification-manage.component';
import { WelcomeMessageComponent } from '../../app/welcome-message/welcome-message.component';
import { TutorialsComponent } from '../../components/tutorials/tutorials.component';

@Component({
  selector: 'app-profile-creation-background',
  templateUrl: './profile-creation-background.component.html',
  styleUrls: ['./profile-creation-background.component.scss'],
})
export class ProfileCreationBackgroundComponent {
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.checkUserProfileCreationRequired();
  }

  checkUserProfileCreationRequired(): void {
    // this.showBackgroundForUserProfileModal(company);
    const { user } = this.userService;

    if (!user) {
      this.authService.logout();
      return;
    }

    if (this.userService.hasProfile()) {
      this.router.navigateByUrl('/home');
      return;
    }

    const profileModal = this.modalService.open(AccountComponent, {
      backdrop: 'static',
      keyboard: false,
    });
    profileModal.componentInstance.temporaryToken = this.userService.temporaryToken;
    profileModal.result.then(() => {
      this.userService.userId = user && user.id;
      this.userService.envVariables = environment;

      const preferencesModal = this.modalService.open(PreferencesComponent, {
        backdrop: 'static',
        keyboard: false,
      });
      preferencesModal.result.then(() => {
        const notificationsMgtModal = this.modalService.open(NotificationManageComponent, {
          backdrop: 'static',
          keyboard: false,
        });
        notificationsMgtModal.result.then(() => {
          this.launchWelcomeMessage();

          this.router.navigateByUrl('/home');
        });
      })
        .catch(err => {
          console.log('preferencesModal catch(): ' + err);
        });
    })
      .catch(err => {
        console.log('profileModal catch():', err);
      });
  }

  private launchWelcomeMessage() {
    const welcomeModal = this.modalService.open(WelcomeMessageComponent, {
      backdrop: 'static',
      keyboard: false,
    });
    welcomeModal.componentInstance.companyWelcomeText = this.userService.company.welcomeText;
    welcomeModal.componentInstance.companyWelcomeTextLink = this.userService.company.welcomeTextLink;
    welcomeModal.result
      .then(($event) => {
        if ($event === 'showTutorial') {
          const modal = this.modalService.open(TutorialsComponent);
          modal.result.then(() => {
            location.reload();
          })
            .catch(() => {
              location.reload();
            });
        }
      })
      .catch(() => {
        console.log('Clicked outside of modal');
      });
  }
  
}
