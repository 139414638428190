import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-terms-privacy-input',
  templateUrl: './terms-privacy-input.component.html',
  styleUrls: ['./terms-privacy-input.component.scss']
})
export class TermsPrivacyInputComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Output() termsPrivacyInputted = new EventEmitter<boolean>();
  @Output() termsOfServiceRequested = new EventEmitter<void>();
  @Output() privacyPolicyRequested = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  get isAcceptTermsAndConditions() { return this.parentForm.get('isAcceptTermsAndConditions'); }

  onAgreeInputChanged(value: boolean) {
    this.termsPrivacyInputted.emit(value);
  }

  onTermsOfServiceClicked() {
    this.termsOfServiceRequested.emit();
  }

  onPrivacyPolicyClicked() {
    this.privacyPolicyRequested.emit();
  }

  showIsAcceptTermsAndConditionsRequiredError(){
    return this.isAcceptTermsAndConditions.errors &&
      this.isAcceptTermsAndConditions.errors.required &&
      this.isAcceptTermsAndConditions.touched;
  }
}
