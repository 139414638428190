import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bookmark-indicator',
  templateUrl: 'bookmark-indicator.html',
  styleUrls: ['./bookmark-indicator.scss'],
})
export class BookmarkIndicatorComponent implements OnInit {
  @Input('bookmarked') bookmarked: any;
  @Input('bigger') bigger: boolean;
  @Input('primaryColor') primaryColor: boolean;
  css: string;

  constructor() {
  }

  ngOnInit() {
    this.css = this.getCss();
  }

  private getCss(): string {
    const classes: string[] = [];

    if (this.bigger) {
      classes.push('bookmark-lg');
    } else {
      classes.push('bookmark-sm');
    }

    if (!this.primaryColor) {
      classes.push('grey');
    }

    return classes.join(' ');
  }
}

